import {AssetsSummary} from "../models/Assets";
import DataEntrySideDrawer from "../../components/DataEntry/DataEntrySideDrawer";
import {Route, Switch} from "react-router-dom";
import AddStandaloneAccount from "../StandaloneAccount/AddStandaloneAccount";
import EditStandaloneAccount from "../StandaloneAccount/EditStandaloneAccount";
import AddPersonalAsset from "../PersonalAsset/AddPersonalAsset";
import EditPersonalAsset from "../PersonalAsset/EditPersonalAsset";
import AddPersonalLiability from "../PersonalLiability/AddPersonalLiability";
import EditPersonalLiability from "../PersonalLiability/EditPersonalLiability";
import AddGeneralInflow from "../GeneralInflow/AddGeneralInflow";
import EditGeneralInflow from "../GeneralInflow/EditGeneralInflow";
import AddInvestmentProgramContainer from "../InvestmentProgram/AddInvestmentProgram";
import AddSocialSecurity from "../SocialSecurity/AddSocialSecurity";
import EditSocialSecurity from "../SocialSecurity/EditSocialSecurity";
import AddLifeInsurance from "../LifeInsurance/AddLifeInsurance";
import EditLifeInsurance from "../LifeInsurance/EditLifeInsurance";
import AddEquityCompensation from "../EquityCompensation/AddEquityCompensation";
import EditLegalAgreement from "../InvestmentProgram/EditLegalAgreement";
import React, {useContext} from "react";
import AssetsViewContext from "./AssetsViewContext";
import EditEquityCompensation from "../EquityCompensation/EditEquityCompensation";
import EditVestingSchedule from "../EquityCompensation/EditVestingSchedule";
import ArrangeAssets from "../AssetSummary/ArrangeAssets";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";

type AssetAddEditSideDrawerProps = {
    open: boolean,
    profileId: string,
    clientAssets: AssetsSummary | undefined,
    investorGroup?: InvestorGroupType,
    memberGroup: MemberGroup
}
export const AssetAddEditSideDrawer = (props: AssetAddEditSideDrawerProps) => {
    const viewType = useContext(AssetsViewContext);

    return <DataEntrySideDrawer isOpen={props.open} size="full" formLayout="split">
        <Switch>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/AddStandaloneAccount`}
                   component={AddStandaloneAccount}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/EditStandaloneAccount/:assetId`}
                   component={EditStandaloneAccount}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/AddPersonalAsset`}
                   component={AddPersonalAsset}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/EditPersonalAsset/:assetId`}
                   component={EditPersonalAsset}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/AddPersonalLiability`}
                   component={AddPersonalLiability}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/EditPersonalLiability/:assetId`}
                   component={EditPersonalLiability}/>
            <Route path={"/Profile/:profileId/ClientProfile/AssetSummary/AddGeneralInflow"}
                   component={AddGeneralInflow}/>
            <Route path={"/Profile/:profileId/ClientProfile/AssetSummary/EditGeneralInflow/:assetId"}
                   component={EditGeneralInflow}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/AddInvestmentProgram`}
                   component={AddInvestmentProgramContainer}/>
            <Route path={"/Profile/:profileId/ClientProfile/AssetSummary/AddSocialSecurity"}
                   component={AddSocialSecurity}/>
            <Route path={"/Profile/:profileId/ClientProfile/AssetSummary/EditSocialSecurity/:assetId"}
                   component={EditSocialSecurity}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/AddLifeInsurance`}
                   component={AddLifeInsurance}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/EditLifeInsurance/:assetId`}
                   component={EditLifeInsurance}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/AddEquityCompensation`}
                   component={AddEquityCompensation}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/EditEquityCompensation/:assetId`}
                   component={EditEquityCompensation}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/EquityCompensation/:assetId/:stockType/:stockId`}
                   component={EditVestingSchedule}/>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/EditLegalAgreement/:assetId`}>
                {props.clientAssets && props.clientAssets.investmentProgram &&
                    <EditLegalAgreement investmentProgramName={props.clientAssets.investmentProgram.name}/>
                }
            </Route>
            <Route path={`/Profile/:profileId/ClientProfile/${viewType}/ArrangeAssets`}>
                <ArrangeAssets
                    profileId={props.profileId}
                    assetsSummary={props.clientAssets}
                    investorGroup={props.investorGroup}
                    memberGroup={props.memberGroup}
                />
            </Route>
        </Switch>
    </DataEntrySideDrawer>;
}
