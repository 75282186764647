import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Search} from 'xps-react';

type TypeaheadSearchBarProps<Type> = {
    performSearch: (searchTerm: string) => Promise<Type[]>,
    minSearchTermLength?: number,
    placeholder: string,
    onSelect?: (result: Type) => void,
    displayResult: (result: Type) => ReactNode,
    resultHeader?: ReactNode,
    searchType?: string
};

export default function TypeaheadSearchBar<Type>({
                                                     performSearch,
                                                     minSearchTermLength = 1,
                                                     placeholder,
                                                     onSelect,
                                                     displayResult,
                                                     resultHeader,
                                                     searchType = ""
                                                 }: TypeaheadSearchBarProps<Type>) {
    const [results, setResults] = useState<Type[]>([]);
    const [searchResultsType, setSearchResultsType] = useState<string>(searchType);

    let componentUnmounted = useRef(false);
    useEffect(() => {
        return () => {
            componentUnmounted.current = true;
        }
    }, []);

    useEffect(() => {
        if(searchResultsType !== searchType){
            setResults([]);
            setSearchResultsType(searchType)
        }
    }, [searchType])

    return <div className="typeahead">
        <Search placeholder={placeholder}
                name={placeholder}
                handleChangeVal={async (searchTerm: any) => {
                    if (searchTerm.target.value.length == 0) {
                        setResults([]);
                    } else if (searchTerm.target.value.length >= minSearchTermLength) {
                        performSearch(searchTerm.target.value).then((result) => {
                            if (componentUnmounted.current) return;
                            setResults(result);
                        });
                    }
                }}
                onClearListener={() => {
                    setResults([]);
                }}
        />
        <div className="dropdown__panel">
            {(results.length > 0) && resultHeader}
            {results.map((result, index) => {
                return <div
                    className="dropdown__item"
                    key={`dropdown-item-${index}`}
                    onClick={() => {
                        onSelect && onSelect(result);
                        setResults([]);
                    }}
                >
                    {displayResult && displayResult(result)}
                </div>
            })}
        </div>
    </div>
}
