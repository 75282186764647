import {DocumentDetails, LinkProps, LocationProps, SubMenuProps,} from "../../models/routeData/RouteParamTypes";
import {ProfileResponse} from "../models/ProfileResponse";
import {AdvisorDocumentMenus} from "../../Resources/models/AdviceLibrary";
import {ReleaseTogglesType} from "../../ReleaseToggles/ReleaseToggles";
import {ClientMeetingDocumentsResponse} from "../../Resources/models/ClientDocuments";


function getGoalsLinks(id: string, releaseToggles: ReleaseTogglesType) {
    const goalsSummaryLink = {
        id: "GoalsSummary",
        path: `/Profile/${id}/ClientProfile/Goals/Summary`,
        name: "Goals Summary",
        enabled: true,
    };
    const goalsPrioritizationLink = {
        id: "GoalsPrioritization",
        path: `/Profile/${id}/ClientProfile/Goals/Prioritization`,
        name: "Prioritize Goals",
        enabled: true,

        hidden: !releaseToggles.enableGoalsPrioritization
    };

    return {
        id: "Goals",
        name: "Goals",
        enabled: true,
        children: [goalsSummaryLink, goalsPrioritizationLink],
    }
}

function getPortfolioReserveLinks(id: string, profile: ProfileResponse){
    const setPRLink = {
        id: "Portfolio Reserve",
        name: "Set Portfolio Reserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve`,
        enabled: true,
        hidden: profile.portfolioReserveTargetLength != undefined
    };
    const reviewPRLink = {
        id: "SetPortfolioReserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/ReviewPortfolioReserve`,
        name: "Review Portfolio Reserve",
        enabled: true,
        hidden: !(profile.portfolioReserveTargetLength != undefined)
    }
    const monitorPRLink = {
        id: "SetPortfolioReserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/MonitorPortfolioReserve`,
        name: "Monitor Portfolio Reserve",
        enabled: true,
        hidden: !profile.isActivePortfolioReserve
    }

    return {
        id: "Portfolio Reserve",
        name: "Portfolio Reserve",
        enabled: true,
        children: [setPRLink, reviewPRLink, monitorPRLink],
    }
}

function getWealthTransferLinks(profileId: String, releaseToggles: ReleaseTogglesType) {
    const hiddenFlag = !releaseToggles.enableHolisticAdvice
    return {
        id: "WealthTransfer",
        name: "Wealth Transfer",
        hidden: hiddenFlag,
        enabled: true,
        path: `/Profile/${profileId}/ClientProfile/WealthTransferPlanning`
    };
}

function getAdvisorDocumentsSubMenu(advisorDocumentsMenu: AdvisorDocumentMenus) {
    const menuLink: SubMenuProps[] | undefined = [];
    advisorDocumentsMenu.advisorDocumentSubMenus.forEach(submenu=>{

        let children : DocumentDetails[] = [];
        let subMenuItem = {
            id: submenu.subMenuLabel.replaceAll(' ', ''),
            title: submenu.subMenuLabel,
            children
        }

        submenu.subMenuItems.forEach(item => {
            children.push({
                label: item.documentLabel,
                link: item.documentLabel
            });
        })
        subMenuItem.children = children;
        menuLink.push(subMenuItem);
    })
    return menuLink;
}

export function getClientMeetingDocumentsSubMenu(clientMeetingDocumentSubMenus: ClientMeetingDocumentsResponse) {
    const menuLink: SubMenuProps[] = [];
    let children : DocumentDetails[] = [];

    let subMenuItem: SubMenuProps = {
        id: clientMeetingDocumentSubMenus.folderItem ? clientMeetingDocumentSubMenus.folderItem?.name : "",
        title: clientMeetingDocumentSubMenus.folderItem ? clientMeetingDocumentSubMenus.folderItem?.name : undefined,
        children,
        subMenuFolders: []
    }

    clientMeetingDocumentSubMenus.documentItems.forEach(item => {
        children.push({
            label: item.name,
            link: item.id
        });
    })

    subMenuItem.children = children;
    if (clientMeetingDocumentSubMenus.folderItems && clientMeetingDocumentSubMenus.folderItems.length > 0) {

        clientMeetingDocumentSubMenus.folderItems.forEach(folderItem => {
            const clientMeetingDocumentSubMenusList: ClientMeetingDocumentsResponse = {
                documentItems: folderItem.driveItems,
                folderItems: folderItem.folderItems,
                folderItem: folderItem
            }
            let subMenuLinkItemArray = getClientMeetingDocumentsSubMenu(clientMeetingDocumentSubMenusList);
            subMenuItem.subMenuFolders?.push(subMenuLinkItemArray[0]);
        })
    }
    menuLink.push(subMenuItem);
    return menuLink;
}

export const links = (
    id: string,
    profile: ProfileResponse,
    adviceLibraryMenu: AdvisorDocumentMenus,
    practiceDocumentsSubMenus: AdvisorDocumentMenus,
    clientDocumentsSubMenus: ClientMeetingDocumentsResponse,
    myDocumentsSubMenus: ClientMeetingDocumentsResponse,
    releaseToggles: ReleaseTogglesType,
): Array<LinkProps> => {


    return [
        {
            id: "agenda",
            name: "Agenda",
            enabled: true,
            path: `/Profile/${id}/ClientProfile/Agenda`
        },
        {
            id: "familyTree",
            name: "Family Tree",
            enabled: true,
            path: `/Profile/${id}/ClientProfile/FamilyTree`,
        },
        {
            id: "Assets",
            name: "Assets",
            enabled: true,
            children: [
                {
                    id: "AssetSummary",
                    path: `/Profile/${id}/ClientProfile/AssetSummary`,
                    name: "Asset Summary",
                    enabled: true,
                },
                {
                    id: "CurrentNetWorth",
                    name: "Current Net Worth",
                    path: `/Profile/${id}/ClientProfile/CurrentNetWorth`,
                    enabled: true,
                    hidden: !releaseToggles.enableCurrentNetWorth
                },
            ],
        },
        getGoalsLinks(id, releaseToggles),
        getPortfolioReserveLinks(id, profile),
        {
            id: "PlanSummary",
            path: `/Profile/${id}/ClientProfile/PlanSummary`,
            name: "Plan Summary",
            enabled: true,
        },
        {
            id: "AssetReliance",
            path: `/Profile/${id}/ClientProfile/AssetReliance`,
            name: "Asset Reliance",
            enabled: true,
        },
        {
            id: "NetWorthOverTime",
            path: `/Profile/${id}/ClientProfile/NetWorthOverTime`,
            name: "Net Worth Over Time",
            enabled: true,
            hidden: !releaseToggles.enabledNetWorthOverTime
        },
        {
            id: "AssetAllocation",
            name: "Asset Allocation",
            enabled: true,
            children: [
                {
                    id: "CurrentVsProposed",
                    path: `/Profile/${id}/ClientProfile/AssetAllocation/CurrentVsProposed`,
                    name: "Current vs. Proposed",
                    enabled: true,
                },
            ],
        },
        getWealthTransferLinks(id, releaseToggles),
        {
            id: "Reporting",
            path: `/Profile/${id}/ClientProfile/CreateReport`,
            name: "Reporting",
            enabled: true,
        },
        {
            id: "Resources",
            name: "Resources",
            enabled: true,
            children: [
                {
                    id: "GDWMIntro",
                    name: "GDWM Intro",
                    path: `/Profile/${id}/ClientProfile/GDWMIntro`,
                    enabled: true,
                    hidden: true,
                },
                {
                    id: "RiskManagementIntro",
                    name: "Risk Management Intro",
                    path: `/Profile/${id}/ClientProfile/RiskManagementIntro`,
                    enabled: false,
                    hidden: true,
                },
                {
                    id: "AdviceLibrary",
                    name: "Advice Library",
                    path: `/Profile/${id}/ClientProfile/Resources/AdviceLibrary`,
                    enabled: true,
                    subMenuType: "right",
                    subMenuTitle: "Advice Library",
                    subMenuOptions: getAdvisorDocumentsSubMenu(adviceLibraryMenu),
                },
                {
                    id: "PracticeDocuments",
                    name: "Practice Documents",
                    path: `/Profile/${id}/ClientProfile/Resources/PracticeDocuments`,
                    enabled: true,
                    subMenuType: "right",
                    subMenuTitle: "Practice Documents",
                    subMenuOptions: getAdvisorDocumentsSubMenu(practiceDocumentsSubMenus),
                },
                {
                    id: "ClientDocuments",
                    name: "Client Documents",
                    path: `/Profile/${id}/ClientProfile/Resources/ClientDocuments`,
                    enabled: true,
                    hidden: !releaseToggles.enableClientDocument,
                    subMenuType: "right",
                    subMenuTitle: "Client Documents",
                    subMenuOptions: (clientDocumentsSubMenus.documentItems.length > 0 || clientDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(clientDocumentsSubMenus) : [],
                },
                {
                    id: "MyDocuments",
                    name: "My Documents",
                    path: `/Profile/${id}/ClientProfile/Resources/MyDocuments`,
                    enabled: true,
                    hidden: !releaseToggles.enableClientDocument,
                    subMenuType: "right",
                    subMenuTitle: "My Documents",
                    subMenuOptions: (myDocumentsSubMenus.documentItems.length > 0 || myDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(myDocumentsSubMenus) : [],
                },
                {
                    id: "ExternalDocuments",
                    name: "External Documents",
                    path: `/Profile/${id}/ClientProfile/ExternalDocuments`,
                    enabled: true,
                    hidden: true,
                },
                {
                    id: "QuickSlides",
                    name: "Quick Slides",
                    path: `/Profile/${id}/ClientProfile/QuickSlides`,
                    enabled: false,
                    hidden: true,
                },
                {
                    id: "TrainingMode",
                    name: "Training Mode",
                    path: `/Profile/${id}/ClientProfile/TrainingMode`,
                    enabled: false,
                    hidden: true,
                },
            ],
        },
    ];
};
