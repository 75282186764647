import React, {useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom';
import {assetsApiClient} from 'src/Assets/AssetsApiClient';
import {
    EquityCompensationStockType,
    StockGrantType,
    StockOptionType,
    VestingPeriod,
    VestingPeriodWriteModel,
    VestingScheduleResponse
} from 'src/Assets/models/EquityCompensation';
import {UnderlinedHeader} from 'src/components';
import DataEntryHeader from 'src/components/DataEntry/DataEntryHeader';
import DataEntrySummary, {DataEntrySummaryItem} from 'src/components/DataEntry/DataEntrySummary';
import LoadingIndicator from 'src/pages/LoadingIndicator';
import {RouteWithProfileIdAndAssetIdAndStockTypeAndStockId} from 'src/routes/types';
import {toDisplayDateFormat} from 'src/utils/dateUtils';
import {formatCurrency, formatNumberRoundedToWholeNumber} from 'src/utils/format';
import AssetsViewContext from 'src/Assets/common/AssetsViewContext';
import CustomizeVestingEvents from './CustomizeVestingEvents';
import {EMPTY_VALUE} from '../AddOptionsAndGrants';
import {v4 as UUIDV4} from "uuid";
import DiscardAssetModal from "../../DiscardAssetModal";
import deepEquals from "fast-deep-equal";

const EditVestingSchedule = () => {
    const history = useHistory();
    const {
        profileId,
        assetId: equityCompId,
        stockType,
        stockId
    } = useParams<RouteWithProfileIdAndAssetIdAndStockTypeAndStockId>();
    const [vestingSchedule, setVestingSchedule] = useState<VestingScheduleResponse>();
    const [vestingPeriods, setVestingPeriods] = useState<VestingPeriod[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [summaryItems, setSummaryItems] = useState<DataEntrySummaryItem[]>();
    const viewType = useContext(AssetsViewContext);
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);


    useEffect(() => {
        setSummaryItemsForStockType(vestingSchedule)
    }, [vestingSchedule]);

    useEffect(() => {
        if (stockType === EquityCompensationStockType.STOCK_OPTION || stockType === EquityCompensationStockType.STOCK_GRANT) {
            assetsApiClient.getVestingSchedule(profileId, equityCompId, stockType, stockId).then((res) => {
                setVestingSchedule(res);
                setVestingPeriods(res.vestingPeriods || []);

            }).catch(error => console.error('Could not fetch vesting schedule', error.message));
        }
    }, [profileId, equityCompId, stockId, stockType]);

    useEffect(() => {
        showAlert && validateVestingPeriods();

        if (vestingPeriods.length > 0 && stockType === EquityCompensationStockType.STOCK_GRANT) {
            if (calculateTotalSharesVesting(vestingPeriods) <= vestingSchedule?.sharesGranted!) {

                assetsApiClient.calculateVestingInfoForStockGrantOrOption(profileId, {
                    equityCompensationId: equityCompId,
                    stockType,
                    vestingPeriods: mapVestingPeriodToVestingPeriodWriteModel(vestingPeriods),
                    stockId
                }).then(res => {
                    setSummaryItemsForStockType(res);
                });
            } else {
                setSummaryItemsForStockType({sharesGranted: vestingSchedule?.sharesGranted})
            }
        }
    }, [vestingPeriods]);

    const onCancelClick = () => {
       const isFormChanged = !deepEquals(vestingSchedule?.vestingPeriods, vestingPeriods);
        setShowDiscardChangesModal(isFormChanged);
        if(!isFormChanged)
            navigateToOptionsAndGrants();
    }

    const navigateToOptionsAndGrants = () => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EquityCompensation/${equityCompId}/AddOptionsAndGrants`);
    }

    const validateVestingPeriods = () => {
        const isInvalidVestingPeriods = (vestingPeriods.length > 0 && calculateTotalSharesVesting(vestingPeriods) !== vestingSchedule?.sharesGranted);
        setShowAlert(isInvalidVestingPeriods);
        return isInvalidVestingPeriods;
    }

    const handleAddVestingPeriod = () => {
        if (!vestingSchedule) return;
        const newVestingPeriod: VestingPeriod = {
            id: Date.now().toString(),
            nextVestingDate: vestingSchedule.grantDate,
            percentage: 0,
            sharesVestingPerPeriod: 0,
            isVested: false
        };
        setVestingPeriods([
            ...vestingPeriods,
            newVestingPeriod
        ])
    }

    const handleUpdateVestingPeriods = (updatedVestingPeriod: VestingPeriod) => {
        const updatedVestingPeriodIndex = vestingPeriods.findIndex(vestingPeriod => vestingPeriod.id === updatedVestingPeriod.id);
        if (updatedVestingPeriodIndex > -1) {
            const updatedVestingPeriods = [...vestingPeriods];
            updatedVestingPeriods[updatedVestingPeriodIndex] = updatedVestingPeriod;
            setVestingPeriods(updatedVestingPeriods);
        }
    }

    const handleDeleteVestingPeriod = (vestingPeriodId: string) => {
        const updatedVestingPeriods = vestingPeriods.filter(vestingPeriod => vestingPeriod.id !== vestingPeriodId)
        setVestingPeriods(updatedVestingPeriods);
    }

    const saveVestingPeriods = async () => {
        const vestingPeriodsRequest = mapVestingPeriodToVestingPeriodWriteModel(vestingPeriods);
        return await assetsApiClient.postVestingPeriods(profileId, equityCompId, stockType, stockId, vestingPeriodsRequest);
    }

    const handleSave = async () => {
        const isInvalidVestingPeriods = validateVestingPeriods();
        if (isInvalidVestingPeriods) return;

        saveVestingPeriods().then(() => {
            navigateToOptionsAndGrants();
        })
    }

    const clearVestingPeriods = () => {
        setVestingPeriods([]);
    }

    const handleCalculateVestingPeriods = (frequency: string) => {
        if (vestingSchedule) {
            assetsApiClient.autoCalculateVestingPeriods(profileId, {
                frequency,
                sharesGranted: vestingSchedule.sharesGranted,
                expirationDate: vestingSchedule.expirationDate,
                grantDate: vestingSchedule.grantDate
            }).then(res => {
                setVestingPeriods([...res.vestingPeriods.map(vestingPeriod => {
                    return {...vestingPeriod, id: UUIDV4()}
                })]);
            })
        }

    }

    const setSummaryItemsForStockType = (vestingData: any) => {
        if (stockType === EquityCompensationStockType.STOCK_OPTION) {
            setSummaryItems([
                {
                    label: 'Total Shares',
                    value: vestingData?.sharesGranted ? formatNumberRoundedToWholeNumber(vestingData.sharesGranted) : ''
                },
                {
                    label: 'Pre-Tax Potential Value',
                    value: vestingData?.grossPotentialValue ? formatCurrency(vestingData.grossPotentialValue) : ''
                },
                {
                    label: 'After-Tax Potential Value',
                    value: vestingData?.afterTaxPotentialValue ? formatCurrency(vestingData.afterTaxPotentialValue) : ''
                }
            ]);
        } else {
            setSummaryItems([
                {
                    label: 'Total Shares',
                    value: vestingData?.sharesGranted ? formatNumberRoundedToWholeNumber(vestingData.sharesGranted) : ''
                },
                {
                    label: 'Unvested Market Value',
                    value: vestingData?.unvestedMarketValue ? formatCurrency(vestingData.unvestedMarketValue) : ''
                },
                {
                    label: 'After Tax Unvested Market Value',
                    value: vestingData?.afterTaxUnvestedMarketValue ? formatCurrency(vestingData.afterTaxUnvestedMarketValue) : ''
                }
            ]);
        }
    }

    if (!vestingSchedule) {
        return <LoadingIndicator/>
    }

    return (
        <div className='edit-vesting'>
            <DataEntryHeader
                className='dataEntryHeader'
                title={formatTitle(vestingSchedule?.grantDescription || "")}
                onPrimaryButtonClick={handleSave}
                onSecondaryButtonClick={onCancelClick}
                primaryButtonText='Save'
                secondaryButtonText='Cancel'
            />
            <div className="edit-vesting__form layout-data-entry-form">
                <article>
                    <VestingScheduleInfo vestingSchedule={vestingSchedule}/>
                    <CustomizeVestingEvents
                        vestingPeriods={vestingPeriods}
                        updateVestingPeriod={handleUpdateVestingPeriods}
                        addVestingPeriod={handleAddVestingPeriod}
                        deleteVestingPeriod={handleDeleteVestingPeriod}
                        totalShares={vestingSchedule.sharesGranted}
                        grantDate={vestingSchedule.grantDate}
                        expirationDate={vestingSchedule.expirationDate}
                        showAlert={showAlert}
                        clearVestingPeriods={clearVestingPeriods}
                        autoCalculateVestingPeriods={handleCalculateVestingPeriods}
                    />
                </article>
                <aside aria-label="Vesting Information">
                    <DataEntrySummary
                        items={summaryItems}
                        title="Vesting Information"
                    />
                </aside>
            </div>

            <DiscardAssetModal
                isOpen={showDiscardChangesModal}
                title={`Discard changes?`}
                content={`Any data entered in this vesting will not be saved.`}
                onClickKeepEditing={() => setShowDiscardChangesModal(false)}
                onClickDiscardChanges={navigateToOptionsAndGrants}
            />


        </div>
    )
}

type VestingScheduleProps = {
    vestingSchedule: VestingScheduleResponse
}

function VestingScheduleInfo({vestingSchedule}: VestingScheduleProps) {
    return <section>
        <UnderlinedHeader
            className="vesting-information-section-header"
            primaryText="Vesting Schedule"/>
        <div className="layout-data-entry-form__field">
            <label id="grantType">
                <b>Grant Type</b>
            </label>
            <span aria-labelledby='grantType'>{formatType(vestingSchedule.type)}</span>
        </div>
        <div className="layout-data-entry-form__field">
            <label id="grantDescription">
                <b>Grant Description / ID</b>
            </label>
            <span aria-labelledby='grantDescription'>{vestingSchedule.grantDescription || EMPTY_VALUE}</span>
        </div>
        <div className="layout-data-entry-form__field">
            <label id="grantDate">
                <b>Grant Date</b>
            </label>
            <span
                aria-labelledby='grantDate'>{vestingSchedule.grantDate ? toDisplayDateFormat(vestingSchedule.grantDate) : EMPTY_VALUE}</span>
        </div>
        <div className="layout-data-entry-form__field">
            <label id="expireDate">
                <b>Expire Date</b>
            </label>
            <span
                aria-labelledby='expireDate'>{vestingSchedule.expirationDate ? toDisplayDateFormat(vestingSchedule.expirationDate) : EMPTY_VALUE}</span>
        </div>
    </section>;
}

const formatTitle = (description: string) => {
    if (description) {
        return `Edit Vesting - ${description}`
    } else {
        return "Edit Vesting"
    }
}

function mapVestingPeriodToVestingPeriodWriteModel(vestingPeriods: VestingPeriod[]): VestingPeriodWriteModel[] {
    return vestingPeriods.map(({
                                   nextVestingDate,
                                   percentage,
                                   sharesVestingPerPeriod
                               }: VestingPeriod) => ({
        nextVestingDate,
        percentage,
        sharesVestingPerPeriod
    }));
}

function calculateTotalSharesVesting(vestingPeriods: VestingPeriod[]): number {
    return Math.round(vestingPeriods.reduce((accumulator, vestingPeriod) => {
        const value = vestingPeriod.sharesVestingPerPeriod || 0;
        return accumulator + value;
    }, 0));
}

function formatType(type: StockOptionType | StockGrantType): string {
    switch (type) {
        case StockOptionType.NQSO:
            return 'Non-Qualified Stock Option (NQSO)'
        case StockOptionType.ISO:
            return 'Incentive Stock Option (ISO)'
        case StockGrantType.RSU:
            return 'Restricted Stock Unit (RSU)'
        case StockGrantType.PSU:
            return 'Performance Stock Unit (PSU)'
        default:
            return ''
    }
}

export default EditVestingSchedule;
