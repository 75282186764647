import {appURL, loginRequest, msalConfig} from "./authConfig";
import {InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {
    AuthCodeMSALBrowserAuthenticationProvider
} from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";

let sharedMsalInstance: PublicClientApplication;
export const initializeMsalInstance = () => {
    if (!sharedMsalInstance) sharedMsalInstance = new PublicClientApplication(msalConfig);
    return sharedMsalInstance;
}

const getActiveAccountName = () => {
    return initializeMsalInstance().getAllAccounts()[0].name
}

export function handleLogin(msal: any) {
    msal.instance.loginRedirect(loginRequest).then(() => {
            acquireToken(msal);
        }
    ).catch((e: any) => {
        console.error(e);
    });

}

export function handleLogout(msal: any) {
    const logoutRequest = {
        account: msal.instance.getActiveAccount(),
        postLogoutRedirectUri: appURL,
    };

    msal.instance.logoutRedirect(logoutRequest).catch((e: any) => {
        console.error(e);
    });
}

export const acquireToken = (msal?: any) => {
    const msalInstance = msal ? msal.instance : sharedMsalInstance;
    const request = {
        ...loginRequest,
        account: msal ? msal.accounts[0] : sharedMsalInstance?.getAllAccounts()[0]
    };

    return msalInstance?.acquireTokenSilent(request)
        .then((response: { accessToken: string }) => response.accessToken)
}

export const acquireAADTokenForTeamsUser = (msal?: any) => {
    const msalInstance = msal ? msal.instance : sharedMsalInstance;
    const request = {
        ...loginRequest,
        scopes:
            [
                "https://auth.msft.communication.azure.com/Teams.ManageCalls",
                "https://auth.msft.communication.azure.com/Teams.ManageChats"
            ],
        account: msal ? msal.accounts[0] : sharedMsalInstance?.getAllAccounts()[0]
    };

    return msalInstance?.acquireTokenSilent(request)
        .then((response: { accessToken: string }) => response.accessToken);
}

export const getAuthProvider = (msal: any) => {
    return new AuthCodeMSALBrowserAuthenticationProvider(sharedMsalInstance, {
        account: msal.accounts[0],
        interactionType: InteractionType.Silent,
        scopes: ["OnlineMeetings.ReadWrite"]
    });
}

export const getAccountName = (msal: any) => {
    return msal.accounts[0].name
}

export const getLanId = (msal: any) => {
    return msal.accounts[0].username.split('@')[0];
}

export const getOid = (msal: any)=>{
    return msal.accounts[0].idTokenClaims.oid;
}

export const getAccountEmail = (msal: any) => {
    return msal.accounts[0].idTokenClaims.emailAddress;
}

export const getAccountPhoneNumber = (msal: any) => {
    return msal.accounts[0].idTokenClaims.workPhone;
}

export const getAccountTitle = (msal: any) => {
    return msal.accounts[0].idTokenClaims.title;
}

export const getAccountUserObjectId = (msal: any) => {
    return msal.accounts[0].localAccountId;
}

export const msalBrowserUtils = {
    getActiveAccountName: getActiveAccountName,
}

export const msalUtils = {
    handleLogin: handleLogin,
    handleLogout: handleLogout,
    acquireToken: acquireToken,
    acquireAADTokenForTeamsUser: acquireAADTokenForTeamsUser,
    getAccountName: getAccountName,
    getLanId: getLanId,
    getAccountEmail: getAccountEmail,
    getAccountPhoneNumber: getAccountPhoneNumber,
    getAccountTitle: getAccountTitle,
    getAccountUserObjectId: getAccountUserObjectId,
    getAuthProvider: getAuthProvider,
    getOid: getOid,
};
