import React from 'react';
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {GoalModelType} from "../models/GoalModelType";
import SummaryFamilyGoalList from "./SummaryFamilyGoalList";
import SummaryLifestyleGoalList from "./SummaryLifestyleGoalList";
import SummaryTaxLiabilitiesList from "./SummaryTaxLiabilitiesList";
import NonLifestyleGoalList from "../components/NonLifestyleGoalList";
import {GoalType} from "../models/GoalType";
import {NonLifestyleCumulativePresentValue} from "./NonLifestyleCumulativePresentValue";

const GoalList = () => {
    const {familyGoals, discretionaryGoals, philanthropicGoals, taxLiabilities} = useAppSelector<GoalModelType>(selectGoalModel);
    const clientHasFamilyGoals = familyGoals && Object.values(familyGoals).length > 0
    const clientHasTaxLiabilities = taxLiabilities.totalTaxLiabilities > 0;
    const displayHeaderDiscretionaryGoals = discretionaryGoals && Object.values(discretionaryGoals).length > 0
    const displayHeaderPhilanthropicGoals = !displayHeaderDiscretionaryGoals && philanthropicGoals && Object.values(philanthropicGoals).length > 0

    return (
            <article>
                    <SummaryLifestyleGoalList/>
                    <div className="goal-list">
                        <NonLifestyleCumulativePresentValue/>
                        <NonLifestyleGoalList label={"Discretionary Goals"} type={GoalType.DISCRETIONARY} displayHeader={displayHeaderDiscretionaryGoals}/>
                        <NonLifestyleGoalList label={"Philanthropic Goals"} type={GoalType.PHILANTHROPIC} displayHeader={displayHeaderPhilanthropicGoals}/>
                        {clientHasFamilyGoals && <SummaryFamilyGoalList/>}
                    </div>
                    {clientHasTaxLiabilities && <SummaryTaxLiabilitiesList/>}
            </article>
)
}


export default GoalList;
