import {useAppDispatch} from "../../../store/hooks";
import {setAssetRelianceResponse} from "../../../ClientManagement/AssetReliance/AssetRelianceSlice";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import React, {useEffect, useState} from "react";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {resetAccordionTableState} from "../../../components/AccordionTable/AccordionTableSlice";

import {AssetRelianceResponse} from "../../../ClientManagement/models/AssetRelianceResponse";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import AssetRelianceContentReport from "./AssetRelianceContentReport";

interface AssetRelianceWrapperProps {
    assetResponse: AssetRelianceResponse
    displayName: string,
    pageLoaded: boolean,
    id: string
}


const AssetRelianceWrapper = ({assetResponse, displayName, pageLoaded, id}: AssetRelianceWrapperProps) => {

    return (<>
            <div className="asset-reliance">
                <>
                    {pageLoaded ?
                        <AssetRelianceContentReport
                            assetRelianceData={assetResponse}
                            id={id}
                            displayName={displayName}
                        />
                        : <LoadingIndicator displayMessage="Page loading"/>
                    }
                </>
            </div>
        </>
    )
}

type assetRelianceContainerProps = {
    displayName: string
}

const AssetRelianceContainer = ({displayName}: assetRelianceContainerProps) => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const [assetResponse, setAssetResponse] = useState<any>({});
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            setPageLoaded(false);
            wealthManagementApiClient.getAssetReliance(id)
                .then(assetRelianceResponse => {
                    setAssetResponse(assetRelianceResponse);
                    dispatch(setAssetRelianceResponse(assetRelianceResponse));
                    setPageLoaded(true);
                    setIsLoading(false);
                }).catch(error => {
                console.error('Could not fetch asset reliance', error.message)
            })
        }
        return () => {
            dispatch(resetAccordionTableState());
        };
    }, [id])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <AssetRelianceWrapper assetResponse={assetResponse} displayName={displayName} pageLoaded={pageLoaded}
                                 id={id}/>
}

export default AssetRelianceContainer;