import {Icon, UnderlinedHeader} from "../../components";
import React from "react";

const NonLifestyleGoalListHeader = () => {
    return (
        <div className="sticky-header">
            <UnderlinedHeader
                leftAlignedContent={
                    <span className="display-flex align-items-center">
                        <Icon name="chevron_double_right"/>
                        <span className="condensed-subtitle paddingleft-md">Goal Type</span>
                    </span>
                }
                rightAlignedContent={
                    (
                        <>
                            <span className="condensed-subtitle value-label marginright-sm" >
                                Present Value
                            </span>
                        </>
                    )
                }
            />
        </div>
    )
}

export default NonLifestyleGoalListHeader