import {RadioYesNoOptional} from "./models/Common";
import {OwnershipDetailsFormData} from "./models/Ownership";

export function booleanToRadioButton(value: boolean | null): RadioYesNoOptional {
    switch (value) {
        case true: return 'Yes';
        case false: return 'No';
        default: return value;
    }
}

export function radioButtonToBoolean(value : RadioYesNoOptional) {
    return value === "Yes";
}

export function getDefaultOwnershipDetails(primaryMemberId: string): OwnershipDetailsFormData {
    return {
        ownershipCategory: "Sole",
        soleOwnerType: "Individual",
        memberOwnerships: [{
            memberId: primaryMemberId,
            percentage: "100",
            isRevocableTrust: false,
        }],
        legalEntityOwnerships: [],
        nonClientMemberOwnerships: [],
    };
}
