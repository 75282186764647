import React, {useEffect, useState} from 'react';
import {Route, Switch, useParams} from "react-router-dom";
import EditPortfolioReserve from "./EditPortfolioReserve";
import SetPortfolioReserve from "./SetPortfolioReserve";
import ReviewPortfolioReserve from "./ReviewPortfolioReserve";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    resetPortfolioReserveButtonStates,
    selectPortfolioReserveUserPreferences,
    setPortfolioReserveModel,
    setPortfolioReserveUserPreferences,
    setPortfolioReserveYears
} from "./PortfolioReserveSlice";
import {emptyPortfolioReserveUserPreferences} from "./models/PortfolioReserveOptions";
import PortfolioReserveService from "./PortfolioReserveService";
import LoadingIndicator from "../../pages/LoadingIndicator";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {genericEmptyErrorModalData, GenericErrorModalData} from "../../components/Modal/Error/GenericErrorModal";
import MonitorPortfolioReserve from "./MonitorPortfolioReserve";

const PortfolioReserve = () => {
    const {id} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);

    usePageViewTimer('Portfolio Reserve Page Load Timer (milliseconds)', isLoading);

    const clearPortfolioReserveOptions = () => {
        if (id !== portfolioReserveUserPreferences.profileId) {
            dispatch(setPortfolioReserveUserPreferences({...emptyPortfolioReserveUserPreferences, profileId: id}));
        }
    }

    const handleError = ((traceId: string) => {
        setGenericError({
            isOpen: true,
            header: 'Communication Failure',
            message: 'There has been a communication failure. The data you see may not be accurate, please refresh your browser. If this error continues, please contact the support team.',
            operationId: traceId
        });
    })
    useEffect(() => {
        dispatch(setPortfolioReserveUserPreferences({...portfolioReserveUserPreferences, profileId: id}))
    }, [id])

    useEffect(() => {
        setIsLoading(true);
        PortfolioReserveService.getPortfolioReserveModel(id, handleError)
            .then(portfolioReserveModel => {
                dispatch(setPortfolioReserveUserPreferences({
                    ...portfolioReserveUserPreferences,
                    clickedInputYear: false
                }));
                dispatch(setPortfolioReserveModel(portfolioReserveModel));
                dispatch(setPortfolioReserveYears({
                    prTargetInputYears: portfolioReserveModel.portfolioReserve.portfolioReserveTargetLength,
                    minimumRiskInputYears: portfolioReserveModel.portfolioReserve.minimumRiskReserveLength
                }))
                setIsLoading(false);
            });
        return () => {
            clearPortfolioReserveOptions();
            dispatch(resetPortfolioReserveButtonStates());
        }
    }, [id]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <Switch>
            <Route exact path="/Profile/:id/ClientProfile/PortfolioReserve/SetPortfolioReserve"
                   render={() => <SetPortfolioReserve errorModelData={genericError}/>}/>
            <Route path="/Profile/:id/ClientProfile/PortfolioReserve/SetPortfolioReserve/EditPortfolioReserve"
                   component={EditPortfolioReserve}/>
            <Route path="/Profile/:id/ClientProfile/PortfolioReserve/MonitorPortfolioReserve"
                   component={MonitorPortfolioReserve}/>
            <Route render={() => <ReviewPortfolioReserve errorModelData={genericError}/>}/>

        </Switch>)
}

export default PortfolioReserve;
