import React, {useEffect, useMemo, useState} from "react";
import {useHistory, useParams,} from "react-router-dom";
import {RouteWithId} from "src/routes/types";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../activeProfileSlice";
import useProfileAndProposals from "../../../hooks/useProfileAndProposals";
import {
    resetAudience,
    resetMeeting,
    selectActiveMeeting,
    selectParticipatingInMeeting,
    selectShowMeetingControls,
    setShowMeetingControls,
} from "../../Meeting/meetingSlice";
import {MeetingStatus} from "../../Meeting/Meeting";
import {useRelayContext} from "../../Meeting/Relay/types/RelayContext";
import {links} from "../ClientProfileLinks";
import {selectDocumentInfo} from "../../../Resources/resourcesSlice";
import {LinkProps} from "../../../models/routeData/RouteParamTypes";
import {selectReleaseToggles, setReleaseToggles} from "src/ReleaseToggles/releaseTogglesSlice";
import * as releaseToggleClientApi from "src/ReleaseToggles/ReleaseToggleApiClient";
import {ReleaseTogglesType} from "src/ReleaseToggles/ReleaseToggles";
import ClientProfile from "./ClientProfile";
import {
    resetReportingState,
    setSelectedAdviceLibraryDocuments,
    setSelectedPagesForAnnualReport,
    setShowDrawerForAdviceLibrary
} from "../../../Reporting/CreateReport/ReportingSlice";
import {initialSelectedPagesForAnnualReport} from "../../../Reporting/ReportingConstants";
import {
    selectAdviceLibrarySubMenus,
    selectPracticeDocumentsSubMenus,
    setAdviceLibraryMenu,
    setPracticeDocumentsMenu
} from "../../../Resources/menuStructureSlice";
import {resourcesApiClient} from "../../../Resources/ResourcesApiClient";
import {MenuStructureResponse} from "../../../Resources/models/AdviceLibrary";
import {ClientMeetingDocumentsResponse} from "../../../Resources/models/ClientDocuments";
import {
    selectClientDocuments,
    selectMyDocuments, setApprovedProfileFromApiInStore,
    setClientDocumentsResponse,
    setMyDocumentsResponse
} from "../../../Resources/clientMeetingDocumentSlice";
import {msalUtils} from "../../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {ProfileResponse} from "../../models/ProfileResponse";
import {clientManagementApiClient} from "../../ClientManagementApiClient";
import {isProposal} from "../../models/isProposal";
import {emptyClientMeetingDocumentsSubMenusData} from "src/Resources/ResourcesConstant";
import {useCommunicationsContext} from "../../Meeting/CommunicationsContext";
import useMeetingUtils from "../../Meeting/useMeetingUtils";

const ClientProfileContainer = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();
    const {profile, approvedProfile} = useProfileAndProposals(id);
    const meeting = useAppSelector(selectActiveMeeting)!;
    const showMeetingControls = useAppSelector(selectShowMeetingControls)!;
    const {unloadMeetingContainer} = useRelayContext();
    const communicationsContext = useCommunicationsContext();
    const {isCurrentUserPresenting} = useMeetingUtils();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting);
    const [isConfirmExitMeetingModalOpen, setConfirmExitMeetingModalOpen] =
        useState(false);
    const documentInfo = useAppSelector(selectDocumentInfo);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const adviceLibrarySubMenus = useAppSelector(selectAdviceLibrarySubMenus);
    const practiceDocumentsSubMenus = useAppSelector(selectPracticeDocumentsSubMenus);
    const clientDocumentsSubMenus = useAppSelector(selectClientDocuments);
    const myDocumentsSubMenus = useAppSelector(selectMyDocuments);
    const msal = useMsal();
    const currentUserId = msalUtils.getLanId(msal);
    const navigationLinks: LinkProps[] = useMemo(() => {
        return links(id, profile, adviceLibrarySubMenus, practiceDocumentsSubMenus, clientDocumentsSubMenus, myDocumentsSubMenus, releaseToggles!)
    }, [id, profile, adviceLibrarySubMenus, practiceDocumentsSubMenus, clientDocumentsSubMenus, myDocumentsSubMenus, releaseToggles]) as LinkProps[];

    const [approvedProfileFromApi, setApprovedProfileFromApi] = useState<ProfileResponse>();

    const navigateHome = () => {
        history.push({pathname: "/"});
    };

    useEffect(() => {
        return () => {
            unloadMeetingContainer();
            communicationsContext.disconnect(isCurrentUserPresenting).then();
            dispatch(setShowMeetingControls(false));
            dispatch(resetMeeting());
            dispatch(resetAudience());
        };
    }, []);

    useEffect(() => {
        releaseToggleClientApi.getReleaseToggles()
            .then((updatedReleaseToggles: ReleaseTogglesType) => {
                dispatch(setReleaseToggles(updatedReleaseToggles))
            })
            .catch(console.error);

        resourcesApiClient.getMenuStructure().then((menuStructureResponse: MenuStructureResponse) => {
            dispatch(setAdviceLibraryMenu(menuStructureResponse.menu["AdviceLibrary"]));
            dispatch(setPracticeDocumentsMenu(menuStructureResponse.menu["PracticeDocuments"]));
        }).catch(console.error);

        return () => {
            dispatch(setClientDocumentsResponse(emptyClientMeetingDocumentsSubMenusData));
            dispatch(resetReportingState());
        }
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(setSelectedAdviceLibraryDocuments([]));
            dispatch(setSelectedPagesForAnnualReport(JSON.parse(JSON.stringify(initialSelectedPagesForAnnualReport))));
            dispatch(setShowDrawerForAdviceLibrary(false));
        }
    }, [id]);

    useEffect(() => {
        if (releaseToggles?.enableClientDocument === true) {
            if (approvedProfileFromApi && approvedProfileFromApi.id) {

                dispatch(setApprovedProfileFromApiInStore(approvedProfileFromApi));

                const clientName = (approvedProfileFromApi.primaryContact.firstName).concat(" ", approvedProfileFromApi.primaryContact.lastName);
                resourcesApiClient.getClientMeetingDocuments(approvedProfileFromApi.id, clientName, currentUserId).then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
                    dispatch(setClientDocumentsResponse(clientMeetingDocumentsResponse));
                }).catch(console.error);

                resourcesApiClient.getMyDocuments(currentUserId).then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
                    dispatch(setMyDocumentsResponse(clientMeetingDocumentsResponse));
                }).catch(console.error);

            }
        }
    }, [approvedProfileFromApi]);

    useEffect(() => {
        if (!approvedProfile?.id) {
            clientManagementApiClient.getProfile(id)
                .then(newProfile => {
                    if (isProposal(newProfile)) {
                        clientManagementApiClient.getProfile(newProfile.profileIdForProposal)
                            .then(newApprovedProfile => setApprovedProfileFromApi(newApprovedProfile))
                            .catch(error => {
                                console.error('Could not fetch profile for proposal', error.message)
                            });
                    } else {
                        setApprovedProfileFromApi(newProfile);
                    }
                }).catch((error) => {
                console.error('Could not fetch profile', error.message);
            })
        } else {
            setApprovedProfileFromApi(approvedProfile);
        }
    }, [id, approvedProfile?.id])


    const isMeetingActive = meeting?.id;

    return (
        <ClientProfile
            history={history}
            onLogoClick={() => {
                participatingInMeeting && (meeting?.status !== MeetingStatus.ENDED)
                    ? setConfirmExitMeetingModalOpen(true)
                    : navigateHome();
            }}
            navigationLinks={navigationLinks}
            documentInfo={documentInfo}
            showMeetingControls={showMeetingControls}
            isMeetingActive={isMeetingActive}
            meeting={meeting}
            isLoading={!isProfileLoaded()}
            isConfirmExitMeetingModalOpen={isConfirmExitMeetingModalOpen}
            handleCancel={() => {
                setConfirmExitMeetingModalOpen(false);
            }}

        />
    )
};

function isProfileLoaded() {
    return useAppSelector(selectProfile) !== null;
}

export default ClientProfileContainer;
