import React, {useMemo, useState} from 'react'
import {FamilyGoalType} from "../models/FamilyGoalType";
import {Table} from "xps-react";
import {formatCurrency} from "../../utils/format";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {Icon} from "../../components";
import {goalsApiClient} from "../GoalsApiClient";
import {deleteFamilyGoal} from "../controller/GoalsModelSlice";
import {useAppDispatch} from "../../store/hooks";
import DeleteGoalModal from "./DeleteGoalModal";
import {GoalType} from "../models/GoalType";
import GoalUtils from "../Summary/GoalUtils";
import ApplyFamilyAssetsModal from "../Family/ApplyFamilyAssetsModal";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";
import BeneficiaryGoalActionMenu from "./BeneficiaryGoalActionMenu";

type BeneficiaryGoalsTableProps = {
    beneficiaryGoals: FamilyGoalType[],
    investorGroup: InvestorGroupType,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onApplyFamilyAssets?: (goal: FamilyGoalType, assetsAmountToApply: number) => void,
    showApplyFamilyAssets?: boolean,
    hasFamilyAssets?: boolean,
    familyAssets?: FamilyAssets,
    availableAssets?: number
}

const BeneficiaryGoalsTable = (props: BeneficiaryGoalsTableProps) => {
    interface BeneficiaryTableRow {
        description: JSX.Element,
        yearsOfFlow: number,
        yearsUntilFlow: number,
        familyAssets: string,
        myResponsibility: string
        presentValue: string,
        selectedGoalSubType: string,
        savedGoal: FamilyGoalType,
    }

    const memoizedTable = useMemo(() => {
        const transformBeneficiaryTableRow = (goal: FamilyGoalType): BeneficiaryTableRow => {
            return {
                description: <div>
                    <span className='marginright-sm'>{goal.userInputs.description}</span>
                    {goal.userInputs.isFundedByNonInvestableAssets && <Icon name='link' size="large" color="#000000"/>}
                </div>,
                selectedGoalSubType: goal.userInputs.selectedGoalSubType,
                presentValue: formatCurrency(goal.calculatedFields?.presentValue),
                ...GoalUtils.getYearsOfFlowAndYearsUntilFlow(goal, props.investorGroup),
                savedGoal: goal,
                familyAssets: goal.familyAssetsAmount ? formatCurrency(goal.familyAssetsAmount) : "-",
                myResponsibility: formatCurrency(goal.calculatedFields?.presentValue - (goal.familyAssetsAmount || 0) > 0 ?
                    goal.calculatedFields?.presentValue - (goal.familyAssetsAmount || 0) : 0),
            }
        };

        const rows = [...props.beneficiaryGoals]
            .sort(((fg1, fg2) => Date.parse(fg1.createdTimestamp!).valueOf() - Date.parse(fg2.createdTimestamp!).valueOf()))
            .map(transformBeneficiaryTableRow);

        return <Table
            caption="Beneficiary Goals Table"
            columns={[
                {
                    minWidth: 0,
                    maxWidth: 0,
                },
                {
                    Header: 'NAME',
                    accessor: 'description',
                    width: 200,
                    className: 'justify-content-center height-100p',
                    maxWidth: 200
                },
                {
                    Header: 'YRS UNTIL FLOW',
                    headerClassName: 'display-flex justify-content-end',
                    className: "beneficiary-goal-table-numeric-columns",
                    accessor: 'yearsUntilFlow',
                    maxWidth: 100
                },
                {
                    Header: 'YRS OF FLOW',
                    headerClassName: 'display-flex justify-content-end',
                    className: "beneficiary-goal-table-numeric-columns",
                    accessor: 'yearsOfFlow',
                    maxWidth: 100,
                },
                {
                    Header: 'PRESENT VALUE',
                    accessor: 'presentValue',
                    headerClassName: 'display-flex justify-content-end',
                    className: "beneficiary-goal-table-numeric-columns",
                },
                {
                    Header: 'FAMILY ASSETS',
                    headerClassName: 'display-flex justify-content-end',
                    className: "beneficiary-goal-table-numeric-columns",
                    accessor: 'familyAssets',
                },
                {
                    Header: 'MY RESPONSIBILITY',
                    headerClassName: 'display-flex justify-content-end',
                    className: "beneficiary-goal-table-numeric-columns",
                    accessor: 'myResponsibility',
                },
                {
                    accessor: 'id',
                    className: "overflow-visible",
                    minWidth: 32,
                    maxWidth: 32,
                    Cell: ({original}: { original: BeneficiaryTableRow }) => {
                        const dispatch = useAppDispatch();
                        const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
                        const [showApplyFamilyAssetsModal, setShowApplyFamilyAssetsModal] = useState(false);

                        const handleDeleteGoal = (familyGoal: FamilyGoalType) => {
                            goalsApiClient.deleteFamilyGoal(familyGoal.id!)
                                .then(response => {
                                    if (response.status === 204) {
                                        dispatch(deleteFamilyGoal({goal: familyGoal}))
                                    }
                                })
                        }

                        return (
                            <span className="table-action-menu">
                                <BeneficiaryGoalActionMenu
                                    onEditGoal={() => props.onEditGoal(original.selectedGoalSubType, original.savedGoal)}
                                    onDeleteGoal={() => setShowDeleteGoalModal(true)}
                                    onApplyFamilyAsset={() => setShowApplyFamilyAssetsModal(true)}
                                    showApplyFamilyAssets={props.showApplyFamilyAssets}
                                    hasFamilyAssets={props.hasFamilyAssets}
                                />
                                <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                                                 onCancel={() => setShowDeleteGoalModal(false)}
                                                 onDelete={() => {
                                                     handleDeleteGoal(original.savedGoal);
                                                     setShowDeleteGoalModal(false);
                                                 }}
                                                 goalType={GoalType.FAMILY}
                                                 description={original.savedGoal.userInputs.description}
                                                 cancelButtonText={'CANCEL'}/>
                                {
                                    props.familyAssets && <ApplyFamilyAssetsModal
                                        isOpen={showApplyFamilyAssetsModal}
                                        onCancel={() => setShowApplyFamilyAssetsModal(false)}
                                        onApply={(familyGoal: FamilyGoalType, assetAmountToApply: number) => {
                                            if (props.onApplyFamilyAssets) {
                                                props.onApplyFamilyAssets(familyGoal, assetAmountToApply);
                                                setShowApplyFamilyAssetsModal(false);
                                            }
                                        }}
                                        familyGoal={original.savedGoal}
                                        familyAssets={props.familyAssets}
                                        availableAssets={props.availableAssets || 0}
                                    />
                                }
                            </span>
                        )
                    }
                }
            ]}
            showPagination={false}
            defaultPageSize={20}
            data={rows}
        />;
    }, [props.beneficiaryGoals, props.investorGroup, props.hasFamilyAssets]);
    return <div data-testid="beneficiary-goals-table">{memoizedTable}</div>;
};
export default BeneficiaryGoalsTable;
