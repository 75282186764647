import {AdvisorDocumentMenus, SubMenuItem} from "./models/AdviceLibrary";
import {ClientMeetingDocumentsResponse} from "./models/ClientDocuments";

export const DEFAULT_MENU_STRUCTURE_RESPONSE: AdvisorDocumentMenus = {
    advisorDocumentSubMenus: []
}
export const emptyMenuStructure: AdvisorDocumentMenus = {
    advisorDocumentSubMenus: []
};

export const emptyClientMeetingDocumentsSubMenusData: ClientMeetingDocumentsResponse = {
    documentItems: [],
    folderItems: [],
    folderItem: undefined
};
