import {HoldingAssetSubclassDetails} from "../../models/Holding";
import {Accordion} from "../../../components";
import HoldingSummaryTable from "./HoldingSummaryTable";
import React, {useState} from "react";
import {AssetClass, AssetSubclass} from "../../models/AssetClassifications";
import {NO_OP} from '../../../constants/common';
import {allAssetClassNames, allAssetSubclassNames, filterAssetClassesWithNoSelectedHoldings} from './holdingUtils';

type Props = {
    riskAssetClasses: AssetClass[],
    riskControlAssetClasses: AssetClass[],
    selectedHoldings: HoldingAssetSubclassDetails[],
    assetSubclassRowRenderer: (
        assetSubclass: AssetSubclass,
        holdingsWithIndex: { holding: HoldingAssetSubclassDetails; index: number }[],
        subclassIndex: number,
    ) => React.ReactNode,
    expanded?: string[],
    onChange?: (items?: string[]) => void;
};

function extractDetailsIdsFromClasses(assetClasses: AssetClass[]): string[] {
    return assetClasses
        .flatMap(assetClass => assetClass.assetSubclasses)
        .flatMap(assetSubclass => assetSubclass.assetSubclassDetails)
        .map(assetSubclassDetails => assetSubclassDetails.assetSubclassDetailsId);
}

function filterIndexedHoldings(
    holdingsWithIndex: { holding: HoldingAssetSubclassDetails, index: number }[],
    assetClasses: AssetClass[]
) {
    const ids = extractDetailsIdsFromClasses(assetClasses);

    return holdingsWithIndex
        .filter(({holding}) => ids.includes(holding.assetSubclassDetailsId));
}

export function HoldingSummaryAccordion({
                                            selectedHoldings,
                                            assetSubclassRowRenderer,
                                            riskAssetClasses,
                                            riskControlAssetClasses,
                                            expanded,
                                            onChange = NO_OP
                                        }: Props) {
    const selectedRiskAssetClasses = filterAssetClassesWithNoSelectedHoldings(
        riskAssetClasses,
        selectedHoldings
    );

    const selectedRiskControlAssetClasses = filterAssetClassesWithNoSelectedHoldings(
        riskControlAssetClasses,
        selectedHoldings
    );

    const holdingsWithIndex = selectedHoldings.map((holding, index) => ({
        holding: holding,
        index: index,
    }));

    const [initialExpandedAssetClasses] = useState(allAssetClassNames(selectedRiskAssetClasses, selectedRiskControlAssetClasses));
    const [initialExpandedAssetSubclasses] = useState(allAssetSubclassNames(selectedRiskAssetClasses, selectedRiskControlAssetClasses));

    return <Accordion
        expanded={expanded ?? initialExpandedAssetClasses.concat(initialExpandedAssetSubclasses)}
        className="grid-display-contents"
        onChange={onChange}
    >
        {selectedRiskAssetClasses.length > 0 &&
            <HoldingSummaryTable
                assetClasses={selectedRiskAssetClasses}
                assetClassificationName="Risk Assets"
                accentColor="#00a0aa"
                assetSubclassRowRenderer={assetSubclassRowRenderer}
                holdingsWithIndex={filterIndexedHoldings(holdingsWithIndex, selectedRiskAssetClasses)}/>
        }
        {selectedRiskControlAssetClasses.length > 0 &&
            <HoldingSummaryTable
                assetClasses={selectedRiskControlAssetClasses}
                assetClassificationName="Risk Control Assets"
                accentColor="#104866"
                assetSubclassRowRenderer={assetSubclassRowRenderer}
                holdingsWithIndex={filterIndexedHoldings(holdingsWithIndex, selectedRiskControlAssetClasses)}/>
        }
    </Accordion>;
}
