import React, {ChangeEvent, KeyboardEvent} from "react";
import {Button, Input} from "../components";
import {Textarea} from "xps-react";
import {AgendaItem} from "./models/AgendaDetails";

type AgendaEntryFormProps = {
    agendaEntryData: AgendaItem,
    dataIndex: number,
    onUpdateAgendaEntryItems: (dataIndex: number, agendaEntry: AgendaItem) => void
    setAgendaEntryFormData: (agendaItem: AgendaItem) => void
    onDeleteEntryFormObject: (dataIndex: number, agendaEntry: AgendaItem) => void
}

const AgendaEntryForm: React.FC<AgendaEntryFormProps> = ({
                                                             agendaEntryData,
                                                             dataIndex,
                                                             onUpdateAgendaEntryItems,
                                                             setAgendaEntryFormData,
                                                             onDeleteEntryFormObject
                                                         }: AgendaEntryFormProps) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setAgendaEntryFormData({
            ...agendaEntryData,
            [name]: value
        })
    };

    const handleSaveDiscussionTopic = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            delete agendaEntryData['mode'];
            const updatedAgendaEntryData = {
                ...agendaEntryData,
                startTime: validateStartTime(agendaEntryData.startTime)
            }
            onUpdateAgendaEntryItems(dataIndex, updatedAgendaEntryData)
        }
    }

    const validateStartTime = (startTime?: string): string | undefined => {
        const matchStr = startTime?.match(/^(1[0-2]|0?[1-9]):[0-5][0-9](AM|PM|am|pm)/) || false;
        if (!matchStr) {
            return '';
        } else {
            return startTime;
        }
    }

    const handleStartTimeValidation = () => {
        setAgendaEntryFormData({
            ...agendaEntryData,
            startTime: validateStartTime(agendaEntryData.startTime)
        })
    }

    return (
        <div className='agenda-header marginbottom-sm'>
            <div className="agenda-entry-form__field">
                <div className="agenda-header display-flex justify-content-between align-items-start">
                        <span className="agenda-title display-flex justify-content-start align-items-start">
                            <Input
                                name="startTime"
                                aria-labelledby="startTime"
                                onChange={handleInputChange}
                                size="small"
                                type="text"
                                value={agendaEntryData.startTime}
                                placeholder={"00:00AM"}
                                removeMarginTop={true}
                                maxLength={20}
                                onKeyDown={handleSaveDiscussionTopic}
                                onBlur={handleStartTimeValidation}
                            />
                            <span className="title-separator">|</span>
                            <Input
                                name="presenterName"
                                aria-labelledby="presenterName"
                                onChange={handleInputChange}
                                size="small"
                                type="text"
                                value={agendaEntryData.presenterName}
                                placeholder={"PRESENTER"}
                                maxLength={100}
                                onKeyDown={handleSaveDiscussionTopic}

                            />
                        </span>
                    <span className='align'>
                                <Button
                                    icon="only"
                                    ariaLabel={`Delete`}
                                    iconName='delete'
                                    size="small"
                                    kind="borderless"
                                    onClick={() => onDeleteEntryFormObject(dataIndex, agendaEntryData)}
                                    className="delete-agenda-entry"/>
                            </span>
                </div>
            </div>
            <div className="agenda-entry-form__field">
                <Input
                    name="title"
                    className="item-title"
                    aria-labelledby="title"
                    onChange={handleInputChange}
                    removeMarginTop
                    size="small"
                    type="text"
                    value={agendaEntryData.title}
                    placeholder={"Item Title"}
                    maxLength={100}
                    onKeyDown={handleSaveDiscussionTopic}

                />
            </div>
            <div className="agenda-entry-form__field">
                <Textarea
                    name="notes"
                    aria-labelledby="notes"
                    description=""
                    height=""
                    onChange={handleInputChange}
                    rows={5}
                    value={agendaEntryData.notes}
                    placeholder={"Additional Notes"}
                    maxLength={250}
                    onKeyDown={handleSaveDiscussionTopic}
                    size="large"
                />
            </div>
        </div>

    );
}

export default AgendaEntryForm;