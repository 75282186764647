import {GoalType} from "../../Goals/models/GoalType";
import {StackedBarChartItemType} from "../../components/StackedBarChart/StackedBarChartItem";
import {LiabilityType} from "../../Assets/models/PersonalLiability";

export enum AssetRelianceAssetType {
    PERSONAL_ASSET = 'PERSONAL_ASSET',
    GENERAL_INFLOWS = 'GENERAL_INFLOWS',
    SOCIAL_SECURITY = 'SOCIAL_SECURITY',
    LIFE_INSURANCE = 'LIFE_INSURANCE',
    RISK_CONTROL = 'RISK_CONTROL',
    PERSONAL_LIABILITY = 'PERSONAL_LIABILITY',
    CONCENTRATED_INVESTMENTS = 'CONCENTRATED_INVESTMENTS',
    LIQUID_RISK_ASSET = 'LIQUID_RISK_ASSET',
    SEMI_LIQUID_RISK_ASSET = 'SEMI_LIQUID_RISK_ASSET',
    IL_LIQUID_RISK_ASSET = 'IL_LIQUID_RISK_ASSET',
    INVESTABLE_PORTFOLIO_ASSET = 'INVESTABLE_PORTFOLIO_ASSET',
    EQUITY_DERIVATIVES = 'EQUITY_DERIVATIVES',
    OTHER_ASSET = 'OTHER_ASSET'
}

export interface AssetRelianceResponse {
    profileId: string;
    goals: AssetRelianceGoalSummaryDTO;
    allAssetsStack: AssetRelianceStack;
    assetStacks: AssetRelianceStack[];
    excludedAssetStacks: AssetRelianceStack[];
    investablePortfolioAssetsStack: AssetRelianceStack | null;
}

export enum GoalAmountType {
    RequiredPortfolioSpending = 'Required Portfolio Spending',
    PresentValue = 'Present Value',
    InvestableValue = 'Investable Value',
}

export type GoalAmountTypes = {
    goalType: GoalType,
    goalAmountType: GoalAmountType
};

export type AssetRelianceStack = {
    assets: AssetRelianceStackAsset[];
    goalAmountTypes: GoalAmountTypes[],
    name: string;
    description: string;
    excluded: boolean;
    netAssets: number;
    excessAssets: number;
    presentValue: number;
    investableValue: number;
    stackAssetId: string | null;
    stackAssetType: AssetRelianceAssetType | null;
    stackSequenceNumber: number;
    totalExcludedAssets: number;
    collateral?: AssetRelianceStackCollateral;
    associatedLiabilities?: AssociatedLiabilities[];
    futureValueOfExcessAssets: number;
}

export type AssociatedLiabilities = {
    id: string;
    liabilityType: LiabilityType;
    description: string;
    liabilityValue: number;
}

export type AssetRelianceStackCollateral = {
    id: string;
    type: LiabilityType;
    description: string;
    presentValue: number;
};

export const EMPTY_ASSET_STACK: AssetRelianceStack = {
    name: '',
    excluded: false,
    netAssets: 0,
    excessAssets: 0,
    stackAssetId: '',
    stackSequenceNumber: 0,
    totalExcludedAssets: 0,
    stackAssetType: null,
    description: '',
    presentValue: 0,
    investableValue: 0,
    assets: [],
    goalAmountTypes: [],
    futureValueOfExcessAssets: 0,
}

export type AssetRelianceStackAsset = {
    description: string;
    presentValue: number;
    investableValue: number;
    assetId: string;
};

export type AssetRelianceBarChartData = {
    chartItems: StackedBarChartItemType[];
    totalGoals: number;
    stack?: AssetRelianceStack;
}

export const EMPTY_BAR_CHART_DATA: AssetRelianceBarChartData = {
    chartItems: [],
    totalGoals: 0,
}

export type AssetRelianceGoalSummaryDTO = {
    totalPresentValue: number;
    goalTypes: AssetRelianceGoalTypeDTO[];
}

export type GoalAmounts = {
    goalAmountType: GoalAmountType,
    amount: number
};

export type AssetRelianceGoalTypeDTO = {
    type: GoalType;
    totalAmounts: GoalAmounts[];
    description: string;
    data: AssetRelianceDescribedValueDTO[];
}

const EMPTY_ASSET_RELIANCE_GOAL_SUMMARY_DTO: AssetRelianceGoalSummaryDTO = {
    goalTypes: [],
    totalPresentValue: 0
}

export const EMPTY_ASSET_RELIANCE_RESPONSE: AssetRelianceResponse = {
    profileId: "",
    goals: EMPTY_ASSET_RELIANCE_GOAL_SUMMARY_DTO,
    allAssetsStack: {
        assets: [],
        name: '',
        description: '',
        excluded: true,
        totalExcludedAssets: 0,
        netAssets: 0,
        presentValue: 0,
        investableValue: 0,
        excessAssets: 0,
        stackAssetId: null,
        stackAssetType: null,
        stackSequenceNumber: 0,
        goalAmountTypes: [],
        futureValueOfExcessAssets: 0,
    },
    assetStacks: [],
    excludedAssetStacks: [],
    investablePortfolioAssetsStack: null
}

export type AssetRelianceDescribedValueDTO = {
    id?: string;
    description: string;
    amounts: GoalAmounts[];
    isFundedByNonInvestableAssets: boolean;
    firstName?: string | null;
    lastName?: string | null;
    age?: number | null;
}