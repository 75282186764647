import {LegalAgreement} from './models/InvestmentProgram';
import {
    LegalEntityReadModel,
    LegalEntityType,
    legalEntityTypes,
    MemberOwnership,
    OwnershipReadModel
} from "./models/Ownership";
import {LifeInsurance} from "./models/LifeInsurance";
import {GroupedTableData, GroupedTableItem} from "../components/Table/GroupedTable";
import {AssetsSummary} from "./models/Assets";
import {PersonalAsset} from "./models/PersonalAsset";
import moment from "moment/moment";
import {DISPLAY_DATE_FORMAT} from "../constants/common";
import {StandaloneAccount} from "./models/StandaloneAccount";
import {Member} from "../ClientManagement/models/InvestorGroupType";
import {AssetsViewContextValue} from "./common/AssetsViewContext";
import {PersonalLiability} from "./models/PersonalLiability";

type MemberID = string;

export type LegalEntityTypeSummary = {
    entityType: LegalEntityType,
    totalValue: number,
    entities: GroupedTableData[]
};

export type NonClientMemberSummary = {
    memberName: string,
    totalValue: number,
    assets: GroupedTableData[]
};

interface NonClientMemberAsset {
    profileId: string,
    assetId: string,
    assetName: string,
    assetDetails?: string
    assetType: 'StandaloneAccount' | 'PersonalAsset' | 'LifeInsurance' | 'LegalAgreement' | 'PersonalLiability',
    value: number,
    member: Member
}

export function createLegalEntityTypeSummaries(
    viewType: AssetsViewContextValue,
    assets: AssetsSummary,
    legalEntities: LegalEntityReadModel[],
    getPersonalAssetDetails?: (asset: PersonalAsset) => string | undefined,
    getStandaloneAccountDetails?: (account: StandaloneAccount) => string,
    useDeathBenefitForLifeInsurance: boolean = false
): Array<LegalEntityTypeSummary> {
    const legalEntityTypeSummaries = legalEntities.reduce((resultingSummaries: LegalEntityTypeSummary[], legalEntity) => {
        let typeSummary = resultingSummaries.find(summary => summary.entityType === legalEntity.type);
        if (!typeSummary) {
            typeSummary = {entityType: legalEntity.type, totalValue: 0, entities: []};
            resultingSummaries.push(typeSummary);
        }
        const allAssetsForEntity = getAllAssetsForEntity(viewType, assets, legalEntity, getPersonalAssetDetails, getStandaloneAccountDetails, useDeathBenefitForLifeInsurance);
        typeSummary.entities.push({groupName: legalEntity.name, groupId: legalEntity.id, items: allAssetsForEntity});
        typeSummary.totalValue += allAssetsForEntity.reduce((total, groupedItem) => total + groupedItem.itemValue, 0)
        return resultingSummaries;
    }, []);

    return legalEntityTypeSummaries.sort(byCustomOrder);
}

export function createNonClientMemberSummaries(
    viewType: AssetsViewContextValue,
    assetsSummary: AssetsSummary,
    getPersonalAssetDetailsFn?: (asset: PersonalAsset) => string,
    getStandaloneAccountDetailsFn?: (asset: StandaloneAccount) => string,
    useDeathBenefitForLifeInsurance: boolean = false
): NonClientMemberSummary[] {
    const groupedSummaries = new Map<MemberID, NonClientMemberSummary>();
    const nonClientMemberAssets = mapToNonClientMemberAssets(assetsSummary, getPersonalAssetDetailsFn, getStandaloneAccountDetailsFn, useDeathBenefitForLifeInsurance);

    for (const memberAsset of nonClientMemberAssets) {
        const memberId = memberAsset.member.id;
        let memberSummary = groupedSummaries.get(memberId)
        if (!memberSummary) {
            const initialMemberSummary = {
                memberName: memberAsset.member.name,
                totalValue: 0,
                assets: [{groupId: memberId, items: []}]
            };
            groupedSummaries.set(memberId, initialMemberSummary);
            memberSummary = initialMemberSummary;
        }

        memberSummary.totalValue += memberAsset.value;
        memberSummary.assets[0].items.push({
            itemName: memberAsset.assetName,
            itemNameSubtext: memberAsset.assetDetails,
            itemValue: memberAsset.value,
            menuOptions: getMenuOptions(viewType, memberAsset)
        });
    }

    return Array.from(groupedSummaries.values());
}

function mapToNonClientMemberAssets(
    assetsSummary: AssetsSummary,
    getPersonalAssetDetailsFn?: (asset: PersonalAsset) => string,
    getStandaloneAccountDetailsFn?: (asset: StandaloneAccount) => string,
    useDeathBenefitForLifeInsurance: boolean = false
): NonClientMemberAsset[] {
    const personalAssets = assetsSummary.personalAssets.data.flatMap((asset) => {
        return getNonClientMemberOwnerships(asset)
            .map(ownership => mapPersonalAssetToNonClientMemberAsset(ownership, asset, getPersonalAssetDetailsFn));
    });

    const legalAgreements = assetsSummary.investmentProgram
        ? assetsSummary.investmentProgram.legalAgreements.flatMap(asset => {
            return getNonClientMemberOwnerships(asset)
                .map(ownership => mapLegalAgreementToNonClientMemberAsset(ownership, asset))
        })
        : [];

    const standaloneAccounts = assetsSummary.accounts.data.flatMap((asset) => {
        return getNonClientMemberOwnerships(asset)
            .map(ownership => mapStandaloneAccountToNonClientMemberAsset(ownership, asset, getStandaloneAccountDetailsFn));
    });

    const lifeInsurances = assetsSummary.lifeInsurances.data.flatMap(asset => {
        return getNonClientMemberOwnerships(asset)
            .map(ownership => mapLifeInsuranceToNonClientMemberAsset(ownership, asset, useDeathBenefitForLifeInsurance))
    });

    const personalLiabilities = assetsSummary.personalLiabilities.flatMap((liability) => {
        return getNonClientMemberOwnerships(liability)
            .map(ownership => mapPersonalLiabilityToNonClientMemberAsset(ownership, liability));
    });

    return [
        ...personalAssets,
        ...legalAgreements,
        ...standaloneAccounts,
        ...lifeInsurances,
        ...personalLiabilities
    ];
}

function mapPersonalAssetToNonClientMemberAsset(
    ownership: MemberOwnership,
    asset: PersonalAsset,
    getAssetDetailsFn: (asset: PersonalAsset) => string | undefined = () => undefined
): NonClientMemberAsset {
    return {
        profileId: asset.profileId,
        assetId: asset.id,
        assetName: asset.description,
        assetDetails: getAssetDetailsFn(asset),
        assetType: 'PersonalAsset',
        value: calculateValue(asset.presentValue, ownership),
        member: ownership.member
    };
}

function mapStandaloneAccountToNonClientMemberAsset(
    ownership: MemberOwnership,
    asset: StandaloneAccount,
    getAssetDetailsFn: (asset: StandaloneAccount) => string | undefined = () => formatAsOfDate(asset.asOfDate)
): NonClientMemberAsset {
    return {
        profileId: asset.profileId,
        assetId: asset.id,
        assetName: asset.name,
        assetDetails: getAssetDetailsFn(asset),
        assetType: 'StandaloneAccount',
        value: calculateValue(asset.holdings.totalMarketValue, ownership),
        member: ownership.member
    };
}

function mapLifeInsuranceToNonClientMemberAsset(
    ownership: MemberOwnership,
    asset: LifeInsurance,
    useDeathBenefitForLifeInsurance: boolean
): NonClientMemberAsset {

    const assetValue = useDeathBenefitForLifeInsurance ? asset.deathBenefitValue : asset.cashValue;

    return {
        profileId: asset.profileId,
        assetId: asset.id,
        assetName: asset.description,
        assetType: 'LifeInsurance',
        value: calculateValue(assetValue, ownership),
        member: ownership.member
    };
}

function mapLegalAgreementToNonClientMemberAsset(
    ownership: MemberOwnership,
    asset: LegalAgreement
): NonClientMemberAsset {

    return {
        profileId: asset.profileId,
        assetId: asset.id,
        assetName: asset.name,
        assetType: 'LegalAgreement',
        value: calculateValue(asset.marketValue, ownership),
        member: ownership.member
    };
}

function mapPersonalLiabilityToNonClientMemberAsset(
    ownership: MemberOwnership,
    liability: PersonalLiability
): NonClientMemberAsset {
    const loanBalance = -1 * liability.loanBalanceEstateValue.totalValue;

    return {
        profileId: liability.profileId,
        assetId: liability.id,
        assetName: liability.description,
        assetType: 'PersonalLiability',
        value: calculateValue(loanBalance, ownership),
        member: ownership.member
    };
}

function getNonClientMemberOwnerships(asset: OwnershipReadModel) {
    return asset.memberOwnerships.filter(ownership => !ownership.isInEstateMember);
}

function getAllAssetsForEntity(
    viewType: AssetsViewContextValue,
    assetsSummary: AssetsSummary,
    legalEntity: LegalEntityReadModel,
    getPersonalAssetDetails?: (asset: PersonalAsset) => string | undefined,
    getStandaloneAccountDetails?: (account: StandaloneAccount) => string,
    useDeathBenefitForLifeInsurance: boolean = false
): Array<GroupedTableItem> {
    const personalEntityAssets = assetsSummary.personalAssets.data
        .filter(asset => isOwnedByEntity(asset, legalEntity.id))
        .map(asset => mapPersonalAssetToGroupTableItem(viewType, asset, legalEntity, getPersonalAssetDetails));

    const legalAgreementEntityAssets = assetsSummary.investmentProgram
        ? assetsSummary.investmentProgram.legalAgreements
            .filter(asset => isOwnedByEntity(asset, legalEntity.id))
            .map(asset => mapLegalAgreementToGroupTableItem(viewType, asset, legalEntity))
        : [];

    const accountEntityAssets = assetsSummary.accounts.data
        .filter(asset => isOwnedByEntity(asset, legalEntity.id))
        .map(asset => mapStandaloneAccountToGroupTableItem(viewType, asset, legalEntity, getStandaloneAccountDetails));

    const lifeInsuranceEntityAssets = assetsSummary.lifeInsurances.data
        .filter(asset => isOwnedByEntity(asset, legalEntity.id))
        .map(asset => mapLifeInsuranceToGroupTableItem(viewType, asset, legalEntity, useDeathBenefitForLifeInsurance));

    const personalLiabilityEntityAssets = assetsSummary.personalLiabilities
        .filter(asset => isOwnedByEntity(asset, legalEntity.id))
        .map(liability => mapPersonalLiabilityToGroupTableItem(viewType, liability, legalEntity));

    return [
        ...legalAgreementEntityAssets,
        ...accountEntityAssets,
        ...personalEntityAssets,
        ...lifeInsuranceEntityAssets,
        ...personalLiabilityEntityAssets
    ];
}

function isOwnedByEntity(asset: OwnershipReadModel, entityId: string): boolean {
    return asset.legalEntityOwnerships.some(ownership => ownership.legalEntity.id === entityId);
}

function mapPersonalAssetToGroupTableItem(
    viewType: AssetsViewContextValue,
    asset: PersonalAsset,
    entity: LegalEntityReadModel,
    getItemNameSubText: (asset: PersonalAsset) => string | undefined = () => undefined,
): GroupedTableItem {

    const entityOwnership = asset.legalEntityOwnerships.find(ownership => ownership.legalEntity.id === entity.id)!;
    const menuOptions = [{
        itemText: 'Edit Asset',
        value: 'Edit Asset',
        route: `/Profile/${asset.profileId}/ClientProfile/${viewType}/EditPersonalAsset/${asset.id}`,
    }];
    return {
        itemName: asset.description,
        itemValue: calculateValue(asset.presentValue, entityOwnership),
        itemNameSubtext: getItemNameSubText(asset),
        menuOptions
    }
}

function mapStandaloneAccountToGroupTableItem(
    viewType: AssetsViewContextValue,
    asset: StandaloneAccount,
    entity: LegalEntityReadModel,
    getItemNameSubText: (account: StandaloneAccount) => string = (account) => formatAsOfDate(account.asOfDate)
): GroupedTableItem {

    const entityOwnership = asset.legalEntityOwnerships.find(ownership => ownership.legalEntity.id === entity.id)!;
    const menuRouteType = 'EditStandaloneAccount';
    const menuOptions = [{
        itemText: 'Edit Account',
        value: 'Edit Account',
        route: `/Profile/${asset.profileId}/ClientProfile/${viewType}/${menuRouteType}/${asset.id}`,
    }];
    return {
        itemName: asset.name,
        itemNameSubtext: getItemNameSubText(asset),
        itemValue: ((asset.holdings.totalMarketValue ? asset.holdings.totalMarketValue : 0) * entityOwnership.percentage) / 100,
        menuOptions
    };
}

function mapLifeInsuranceToGroupTableItem(viewType: AssetsViewContextValue,
                                          asset: LifeInsurance,
                                          entity: LegalEntityReadModel,
                                          useDeathBenefitForLifeInsurance = false): GroupedTableItem {

    const entityOwnership = asset.legalEntityOwnerships.find(ownership => ownership.legalEntity.id === entity.id)!;
    const menuOptions = [{
        itemText: 'Edit Asset',
        value: 'Edit Asset',
        route: `/Profile/${asset.profileId}/ClientProfile/${viewType}/EditLifeInsurance/${asset.id}`,
    }];

    const assetValue = useDeathBenefitForLifeInsurance ? asset.deathBenefitValue : asset.cashValue;

    return {
        itemName: asset.description,
        itemValue: calculateValue(assetValue, entityOwnership),
        menuOptions
    };
}

function mapPersonalLiabilityToGroupTableItem(viewType: AssetsViewContextValue,
                                          liability: PersonalLiability,
                                          entity: LegalEntityReadModel): GroupedTableItem {

    const entityOwnership = liability.legalEntityOwnerships.find(ownership => ownership.legalEntity.id === entity.id)!;
    const menuOptions = [{
        itemText: 'Edit Asset',
        value: 'Edit Asset',
        route: `/Profile/${liability.profileId}/ClientProfile/${viewType}/EditPersonalLiability/${liability.id}`,
    }];

    const loanBalance = -1 * liability.loanBalanceEstateValue.totalValue;

    return {
        itemName: liability.description,
        itemValue: calculateValue(loanBalance, entityOwnership),
        menuOptions
    };
}

function mapLegalAgreementToGroupTableItem(viewType: AssetsViewContextValue,
                                          asset: LegalAgreement,
                                          entity: LegalEntityReadModel): GroupedTableItem {

    const entityOwnership = asset.legalEntityOwnerships.find(ownership => ownership.legalEntity.id === entity.id)!;
    const menuOptions = [{
        itemText: 'Edit Account',
        value: 'Edit Account',
        route: `/Profile/${asset.profileId}/ClientProfile/${viewType}/EditLegalAgreement/${asset.id}`
    }];

    return {
        itemName: asset.name,
        itemValue: calculateValue(asset.marketValue, entityOwnership),
        menuOptions
    };
}

function formatAsOfDate(asOfDate?: string) {
    return asOfDate ? `As of ${moment(asOfDate).format(DISPLAY_DATE_FORMAT)}` : '--';
}

function byCustomOrder(a: LegalEntityTypeSummary, b: LegalEntityTypeSummary): number {
    const aOrdinal = legalEntityTypes.indexOf(a.entityType);
    const bOrdinal = legalEntityTypes.indexOf(b.entityType);
    return aOrdinal - bOrdinal;
}

function calculateValue(presentValue: number | null, ownership: { percentage: number }) {
    const value = presentValue ?? 0;
    return (value * ownership.percentage) / 100;
}

function getMenuOptions(viewType: AssetsViewContextValue,
                        memberAsset: NonClientMemberAsset) {

    const menuText = memberAsset.assetType === "StandaloneAccount" ? 'Edit Account' : 'Edit Asset';
    return [{
        itemText: menuText,
        value: menuText,
        route: `/Profile/${memberAsset.profileId}/ClientProfile/${viewType}/Edit${memberAsset.assetType}/${memberAsset.assetId}`,
    }];
}
