import {YearsInput} from "./YearsInput"
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import RangeInputs from "../../components/RangeInputs/RangeInputs";
import PlanningPeriod from "../../components/PlanningPeriod/PlanningPeriod";
import {NonLifestyleGoalType} from "../models/GoalType";
import {useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import GoalUtils from "../Summary/GoalUtils";
import {ISO8601_DATE_FORMAT} from "../../constants/common";
import {toDisplayDateFormat} from "../../utils/dateUtils";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {InfoPopover, UnderlinedHeader} from "../../components";
import {InvestorGroupMemberType} from "../../ClientManagement/models/InvestorGroupType";

type OptionalGoalTimeFrameFormInputNames =
    "beneficiaryAgeFrom"
    | "beneficiaryAgeTo";
type OptionalTimeFrameFormInputs = {
    [InputName in OptionalGoalTimeFrameFormInputNames]?: string | number;
}

export type RequiredGoalTimeFrameFormInputNames =
    "yearsAchieved"
    | "yearsUntilFlow"
    | "yearsOfFlow"
    | "goalYearFrom"
    | "goalYearTo"
    | "clientAgeFrom"
    | "clientAgeTo";
type RequiredGoalTimeFrameFormInputs = {
    [InputName in RequiredGoalTimeFrameFormInputNames]: string | number
}

export type GoalTimeFrameFormInputNames = RequiredGoalTimeFrameFormInputNames | OptionalGoalTimeFrameFormInputNames;
export type GoalTimeFrameFormInputs = RequiredGoalTimeFrameFormInputs & OptionalTimeFrameFormInputs;

type GoalTimeFrameFormProps = {
    nonLifestyleGoal: NonLifestyleGoalType,
    updateNonLifestyleGoal: (updatedNonLifestyleGoal: Partial<NonLifestyleGoalType['userInputs']>) => void,
    updatePresentValue: (overrideNonLifestyleGoal?: NonLifestyleGoalType) => void,
}

const GoalTimeFrameForm = ({
                               nonLifestyleGoal,
                               updateNonLifestyleGoal,
                               updatePresentValue,
                           }: GoalTimeFrameFormProps
) => {
    const {
        getInvestorGroupAgeRangeForFamilyGoal,
        getMemberAgeRangeForFamilyGoal,
        getHasGoalAlreadyStarted,
        getHasGoalAlreadyCompleted,
        getYearsOfFlowAndYearsUntilFlow,
        getBeneficiaryFromProfile
    } = GoalUtils
    const {investorGroup, proposal} = useAppSelector<GoalModelType>(selectGoalModel)
    let memberSelected: MemberType;
    if (nonLifestyleGoal.beneficiaryId) {
        memberSelected = getBeneficiaryFromProfile(proposal, nonLifestyleGoal.beneficiaryId);
    }

    const [inputValues, setInputValue] = useState<GoalTimeFrameFormInputs>(computeInputValues())

    const isEditing = nonLifestyleGoal.id !== undefined
    const goalHasStarted = (getHasGoalAlreadyStarted(nonLifestyleGoal) && isEditing)
    const goalHasCompleted = (getHasGoalAlreadyCompleted(nonLifestyleGoal) && isEditing)
    const [invalidFieldNameThatTriggersGoalTimeFrameAdjustment, setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment] = useState<string>("");

    useEffect(() => {
        setInputValue(computeInputValues())
    }, [nonLifestyleGoal.userInputs.startDate, nonLifestyleGoal.userInputs.endDate])

    function computeInputValues(): GoalTimeFrameFormInputs {
        const {
            startAge: beneficiaryAgeFrom,
            endAge: beneficiaryAgeTo
        } = memberSelected
            ? getMemberAgeRangeForFamilyGoal(memberSelected, nonLifestyleGoal)
            : {startAge: undefined, endAge: undefined};
        const {
            startAge: clientAgeFrom,
            endAge: clientAgeTo
        } = getInvestorGroupAgeRangeForFamilyGoal(investorGroup, nonLifestyleGoal)

        return {
            ...getYearsOfFlowAndYearsUntilFlow(nonLifestyleGoal, investorGroup),
            beneficiaryAgeFrom, beneficiaryAgeTo,
            clientAgeFrom, clientAgeTo,
            goalYearFrom: moment(nonLifestyleGoal.userInputs.startDate).year(),
            goalYearTo: moment(nonLifestyleGoal.userInputs.endDate).year()
        }
    }

    type Timeframe = { startDate: string, endDate: string }

    function getTimeframeFromUserInputs(userInputs: NonLifestyleGoalType['userInputs']): Timeframe {
        const {startDate, endDate} = userInputs
        return {startDate, endDate}
    }

    function getNewTimeframeFromAgeFrom(newAgeFrom: string | number, oldTimeframe: Timeframe, oldStartAge: number): Timeframe {
        const yearsOffset = Number(newAgeFrom) - oldStartAge;
        const startDate = moment(oldTimeframe.startDate).add(yearsOffset, 'years').format(ISO8601_DATE_FORMAT)
        const endDate = moment(startDate).utc().add(inputValues.yearsOfFlow, "years").format(ISO8601_DATE_FORMAT)
        return {startDate, endDate};
    }

    function getNewTimeframeFromAgeTo(newAgeTo: string | number, oldTimeframe: Timeframe, oldEndAge: number): Timeframe {
        const yearsOffset = Number(newAgeTo) - oldEndAge;
        const endDate = moment(oldTimeframe.endDate).add(yearsOffset, 'years').format(ISO8601_DATE_FORMAT)
        return {startDate: oldTimeframe.startDate, endDate};
    }

    function calculateDefaultStartEndDate() {
        let initialStartDate = moment(nonLifestyleGoal.userInputs.startDate).utc().clone();
        if (!goalHasStarted) {
            initialStartDate = initialStartDate.clone().year(investorGroup.planningPeriod.startYear);
        }
        return {
            startDate: initialStartDate.format(ISO8601_DATE_FORMAT),
            endDate: initialStartDate.clone().year(investorGroup.planningPeriod.startYear + 1).format(ISO8601_DATE_FORMAT)
        }
    }

    function calculateStartEndDate(fieldName: GoalTimeFrameFormInputNames, newValue: number) {
        let newStartDate = nonLifestyleGoal.userInputs.startDate
        let newEndDate = nonLifestyleGoal.userInputs.endDate
        const yearsFlown = Math.max(investorGroup.planningPeriod.startYear - moment(nonLifestyleGoal.userInputs.startDate).year(), 0)

        switch (fieldName) {
            case "yearsUntilFlow":
                const initialStartDate = GoalUtils.getDefaultTimeframe(investorGroup).startDate;
                newStartDate = moment(initialStartDate).add(newValue, 'years').format(ISO8601_DATE_FORMAT);
                newEndDate = moment(newStartDate).utc().add(inputValues.yearsOfFlow, "years").format(ISO8601_DATE_FORMAT)
                break;
            case "yearsOfFlow":
                newEndDate = moment(newStartDate).utc().add(newValue + yearsFlown, "years").format(ISO8601_DATE_FORMAT)
                break;
            case "goalYearFrom":
                newStartDate = moment(newStartDate).utc().year(Number(newValue)).format(ISO8601_DATE_FORMAT)
                newEndDate = moment(newStartDate).utc().add(inputValues.yearsOfFlow, "years").format(ISO8601_DATE_FORMAT)
                break;
            case "goalYearTo":
                newEndDate = moment(newEndDate).utc().year(Number(newValue)).format(ISO8601_DATE_FORMAT)
                break;
            case "beneficiaryAgeFrom":
                ({
                    startDate: newStartDate,
                    endDate: newEndDate
                } = getNewTimeframeFromAgeFrom(newValue, getTimeframeFromUserInputs(nonLifestyleGoal.userInputs), getMemberAgeRangeForFamilyGoal(memberSelected, nonLifestyleGoal).startAge))
                break;
            case "beneficiaryAgeTo":
                ({
                    startDate: newStartDate,
                    endDate: newEndDate
                } = getNewTimeframeFromAgeTo(newValue, getTimeframeFromUserInputs(nonLifestyleGoal.userInputs), getMemberAgeRangeForFamilyGoal(memberSelected, nonLifestyleGoal).endAge))
                break;
            case "clientAgeFrom":
                ({
                    startDate: newStartDate,
                    endDate: newEndDate
                } = getNewTimeframeFromAgeFrom(newValue, getTimeframeFromUserInputs(nonLifestyleGoal.userInputs), getInvestorGroupAgeRangeForFamilyGoal(investorGroup, nonLifestyleGoal).startAge))
                break;
            case "clientAgeTo":
                ({
                    startDate: newStartDate,
                    endDate: newEndDate
                } = getNewTimeframeFromAgeTo(newValue, getTimeframeFromUserInputs(nonLifestyleGoal.userInputs), getInvestorGroupAgeRangeForFamilyGoal(investorGroup, nonLifestyleGoal).endAge))
                break;
            default:
                break;
        }

        return {startDate: newStartDate, endDate: newEndDate}
    }

    function updateNonLifestyleGoalAndPresentValue(newStartDate: string, newEndDate: string) {
        updateNonLifestyleGoal({startDate: newStartDate, endDate: newEndDate})
        const newNonLifestyleGoal: NonLifestyleGoalType = {
            ...nonLifestyleGoal,
            userInputs: {
                ...nonLifestyleGoal.userInputs,
                startDate: newStartDate,
                endDate: newEndDate,
            }
        };
        updatePresentValue(newNonLifestyleGoal);
    }

    function defaultNonLifestyleGoalAndPresentValue(fieldName: GoalTimeFrameFormInputNames) {
        let {startDate: defaultStart, endDate: defaultEnd} = calculateDefaultStartEndDate()
        updateNonLifestyleGoal({startDate: defaultStart, endDate: defaultEnd})
        const defaultTimeframeNonLifestyleGoal: NonLifestyleGoalType = {
            ...nonLifestyleGoal,
            userInputs: {
                ...nonLifestyleGoal.userInputs,
                startDate: defaultStart,
                endDate: defaultEnd
            }
        };
        updatePresentValue(defaultTimeframeNonLifestyleGoal);
        setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment(fieldName);
    }

    function isNewTimeframeValid(newStartDate: string, newEndDate: string, hasStarted: boolean) {
        const yearsOfSpending = moment(newEndDate).year() - moment(newStartDate).year();
        const yearsFlown = Math.max(investorGroup.planningPeriod.startYear - moment(newStartDate).year(), 0);
        const yearsOfFlow = yearsOfSpending - yearsFlown;
        const yearsUntilFlow = moment(newStartDate).year() - investorGroup.planningPeriod.startYear;

        return (hasStarted || yearsUntilFlow >= 0) && yearsOfFlow >= 1;
    }

    function validateYearsUntilFlow(newValue: number) {
        const goalFundingWillEndAfterPlanningPeriod: boolean = newValue + Number(inputValues.yearsOfFlow) > investorGroup.planningPeriod.numberOfYears;
        if (goalFundingWillEndAfterPlanningPeriod) {
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("yearsUntilFlow");
            const {yearsUntilFlow: originalYearsUntilFlow} = getYearsOfFlowAndYearsUntilFlow(nonLifestyleGoal, investorGroup);
            newValue = originalYearsUntilFlow;
            return newValue;
        }

        setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("");
        return newValue;
    }

    function validateYearsOfFlow(newValue: number) {
        const goalFundingWillEndAfterPlanningPeriod: boolean = Number(inputValues.yearsUntilFlow) + newValue > investorGroup.planningPeriod.numberOfYears;
        if (goalFundingWillEndAfterPlanningPeriod) {
            newValue = investorGroup.planningPeriod.numberOfYears - Number(inputValues.yearsUntilFlow);
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("yearsOfFlow");
            return newValue;
        }

        setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("");
        return newValue;
    }

    function validateGoalYearFrom(newValue: number) {
        if (newValue < moment().year()) {
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("goalYearFrom");
            newValue = investorGroup.planningPeriod.startYear;
            return newValue;
        }

        const latestYearToStartGoal = (investorGroup.planningPeriod.startYear + investorGroup.planningPeriod.numberOfYears) - Number(inputValues.yearsOfFlow);
        if (newValue > latestYearToStartGoal) {
            newValue = moment(nonLifestyleGoal.userInputs.startDate).year();
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("goalYearFrom");
            return newValue;
        }

        setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("");
        return newValue;
    }

    function validateGoalYearTo(newValue: number) {
        if (newValue > investorGroup.planningPeriod.startYear + investorGroup.planningPeriod.numberOfYears) {
            newValue = investorGroup.planningPeriod.startYear + investorGroup.planningPeriod.numberOfYears;
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("goalYearTo");
            return newValue;
        }

        if (newValue <= Number(inputValues.goalYearFrom)) {
            newValue = Number(inputValues.goalYearFrom) + 1;
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("goalYearTo");
            return newValue;
        }

        setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("");
        return newValue;
    }

    function validateClientAgeFrom(newValue: number) {
        let clientAge: number = 0;
        if (investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER) {
            clientAge = (moment().diff(investorGroup.partnerMember?.birthdate, 'years'))
        } else {
            clientAge = (moment().diff(investorGroup.primaryMember?.birthdate, 'years'))
        }

        if (newValue < clientAge) {
            newValue = clientAge;
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("clientAgeFrom")
            return newValue;
        }

        if (newValue >= Number(inputValues.clientAgeTo)) {
            newValue = Number(inputValues.clientAgeTo) - Number(inputValues.yearsOfFlow);
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("clientAgeFrom")
            return newValue;
        }

        setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("")
        return newValue;
    }

    function validateClientAgeTo(newValue: number) {
        if (newValue < Number(inputValues.clientAgeFrom)) {
            newValue = Number(inputValues.clientAgeFrom) + 1;
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("clientAgeTo");
            return newValue;
        }

        const maxAge: number = Number(inputValues.clientAgeFrom) +
            Number(inputValues.yearsAchieved) +
            investorGroup.planningPeriod.numberOfYears -
            Number(inputValues.yearsUntilFlow);
        if (newValue > maxAge) {
            newValue = maxAge;
            setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("clientAgeTo");
            return newValue;
        }

        setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("");
        return newValue;
    }

    function validateAndUpdatePresentValue(fieldName: GoalTimeFrameFormInputNames, hasStarted: boolean, hasCompleted: boolean) {
        if (hasCompleted) {
            return;
        }

        let newInput = inputValues[fieldName]
        if ((newInput === "") || isNaN(Number(newInput))) {
            setInputValue(computeInputValues())
            return
        }
        let newValue: number = Number(newInput)
        switch (fieldName) {
            case "yearsUntilFlow":
                newValue = validateYearsUntilFlow(newValue);
                break;
            case "yearsOfFlow":
                newValue = validateYearsOfFlow(newValue);
                break;
            case "goalYearFrom":
                newValue = validateGoalYearFrom(newValue);
                break;
            case "goalYearTo":
                newValue = validateGoalYearTo(newValue);
                break;
            case "clientAgeFrom":
                newValue = validateClientAgeFrom(newValue);
                break;
            case "clientAgeTo":
                newValue = validateClientAgeTo(newValue);
                break;
            default:
                setInvalidFieldNameThatTriggersGoalTimeFrameAdjustment("");
                break;
        }
        const {startDate: newStartDate, endDate: newEndDate} = calculateStartEndDate(fieldName, newValue);

        if (isNewTimeframeValid(newStartDate, newEndDate, hasStarted)) {
            updateNonLifestyleGoalAndPresentValue(newStartDate, newEndDate);
        } else {
            defaultNonLifestyleGoalAndPresentValue(fieldName);
        }
        setInputValue(computeInputValues());
    }

    function getInvalidTimeframeErrorDiv() {
        return <>
            <div></div>
            <div className="goal-time-frame-error-msg">
                Goal Time Frame was adjusted to match Proposal Planning Period.
            </div>
        </>;
    }

    return (
        <section aria-label="Goal Time Frame">
            <UnderlinedHeader
                primaryText='Goal Time Frame'
                secondaryContent={
                    <InfoPopover
                        content={<div>Goal roll down dates are calculated based on the date of birth of the
                            primary or secondary client contact with the longest planning horizon.</div>}
                        direction="top"
                        width="288px"
                    />
                }
                className="goal-timeframe-underlined-header"
            />
            <div className="family-goal-time-frame-table">
                <label>Years until Flow</label>
                <div className="display-flex">
                    <YearsInput
                        style={{width: "134px"}}
                        aria-label="yearsUntilFlow"
                        name="yearsUntilFlow"
                        value={goalHasStarted ? 0 : inputValues.yearsUntilFlow}
                        readonly={goalHasStarted}
                        onChangeValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInputValue({
                                ...inputValues,
                                yearsUntilFlow: isNaN(Number(e.target.value)) ? "" : e.target.value
                            })
                        }}
                        onBlur={() => {
                            validateAndUpdatePresentValue("yearsUntilFlow", goalHasStarted, goalHasCompleted)
                        }}
                    />
                    <div className="family-goal-date-cell">
                        <label>
                            <b>Start Date</b>
                        </label>
                        <span role="startDate"
                              aria-label="start-date">{toDisplayDateFormat(nonLifestyleGoal.userInputs.startDate)}</span>
                    </div>
                </div>
                {invalidFieldNameThatTriggersGoalTimeFrameAdjustment === "yearsUntilFlow" && getInvalidTimeframeErrorDiv()}

                <label>Years of Flow</label>
                <div className="display-flex">
                    <YearsInput
                        style={{width: "134px"}}
                        aria-label="yearsOfFlow"
                        name="yearsOfFlow"
                        value={inputValues.yearsOfFlow}
                        readonly={goalHasCompleted}
                        onChangeValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInputValue({
                                ...inputValues,
                                yearsOfFlow: isNaN(Number(e.target.value)) ? "" : e.target.value
                            })
                        }}
                        onBlur={() => {
                            validateAndUpdatePresentValue("yearsOfFlow", goalHasStarted, goalHasCompleted)
                        }}
                    />
                    <div className="family-goal-date-cell">
                        <label>
                            <b>End Date</b>
                        </label>
                        <span role="endDate"
                              aria-label="end-date">{toDisplayDateFormat(nonLifestyleGoal.userInputs.endDate)}</span>
                    </div>
                </div>
                {invalidFieldNameThatTriggersGoalTimeFrameAdjustment === "yearsOfFlow" && getInvalidTimeframeErrorDiv()}

                <label>
                    <b>Goal Years</b>
                </label>
                <RangeInputs
                    left={{
                        ariaLabel: "goalYearFrom",
                        value: inputValues.goalYearFrom,
                        readOnly: goalHasStarted,
                        onChange: (e) => {
                            setInputValue({
                                ...inputValues,
                                goalYearFrom: isNaN(Number(e.target.value)) ? "" : e.target.value
                            })
                        },
                        onBlur: () => {
                            validateAndUpdatePresentValue("goalYearFrom", goalHasStarted, goalHasCompleted)
                        }
                    }}
                    right={{
                        ariaLabel: "goalYearTo",
                        value: inputValues.goalYearTo,
                        onChange: (e) => {
                            setInputValue({
                                ...inputValues,
                                goalYearTo: isNaN(Number(e.target.value)) ? "" : e.target.value
                            })
                        },
                        onBlur: () => {
                            validateAndUpdatePresentValue("goalYearTo", goalHasStarted, goalHasCompleted)
                        }
                    }}
                    hasStarted={goalHasStarted}
                    hasEnded={goalHasCompleted}
                />
                {(invalidFieldNameThatTriggersGoalTimeFrameAdjustment === "goalYearFrom" || invalidFieldNameThatTriggersGoalTimeFrameAdjustment === "goalYearTo") && getInvalidTimeframeErrorDiv()}
                {inputValues.beneficiaryAgeFrom !== undefined && inputValues.beneficiaryAgeTo !== undefined &&
                    <>
                        <label>
                            <b>Beneficiary’s Age Range</b>
                        </label>
                        <RangeInputs
                            left={{
                                ariaLabel: "beneficiaryAgeFrom",
                                value: inputValues.beneficiaryAgeFrom,
                                onChange: (e) => {
                                    setInputValue({
                                        ...inputValues,
                                        beneficiaryAgeFrom: isNaN(Number(e.target.value)) ? "" : e.target.value
                                    })
                                },
                                onBlur: () => {
                                    validateAndUpdatePresentValue("beneficiaryAgeFrom", goalHasStarted, goalHasCompleted)
                                }
                            }}
                            right={{
                                ariaLabel: "beneficiaryAgeTo",
                                value: inputValues.beneficiaryAgeTo,
                                onChange: (e) => {
                                    setInputValue({
                                        ...inputValues,
                                        beneficiaryAgeTo: isNaN(Number(e.target.value)) ? "" : e.target.value
                                    })
                                },
                                onBlur: () => {
                                    validateAndUpdatePresentValue("beneficiaryAgeTo", goalHasStarted, goalHasCompleted)
                                }
                            }}
                            hasStarted={goalHasStarted}
                            hasEnded={goalHasCompleted}
                        />
                    </>
                }
                <label>
                    <b>Client’s Age Range</b>
                </label>
                <RangeInputs
                    left={{
                        ariaLabel: "clientAgeFrom",
                        value: inputValues.clientAgeFrom,
                        readOnly: goalHasStarted,
                        onChange: (e) => {
                            setInputValue({
                                ...inputValues,
                                clientAgeFrom: isNaN(Number(e.target.value)) ? "" : e.target.value
                            })
                        },
                        onBlur: () => {
                            validateAndUpdatePresentValue("clientAgeFrom", goalHasStarted, goalHasCompleted)
                        }
                    }}
                    right={{
                        ariaLabel: "clientAgeTo",
                        value: inputValues.clientAgeTo,
                        onChange: (e) => {
                            setInputValue({
                                ...inputValues,
                                clientAgeTo: isNaN(Number(e.target.value)) ? "" : e.target.value
                            })
                        },
                        onBlur: () => {
                            validateAndUpdatePresentValue("clientAgeTo", goalHasStarted, goalHasCompleted)
                        }
                    }}
                    hasStarted={goalHasStarted}
                    hasEnded={goalHasCompleted}
                />
                {(invalidFieldNameThatTriggersGoalTimeFrameAdjustment === "clientAgeFrom" || invalidFieldNameThatTriggersGoalTimeFrameAdjustment === "clientAgeTo") && getInvalidTimeframeErrorDiv()}
                <PlanningPeriod wasGoalTimeFrameAdjusted={invalidFieldNameThatTriggersGoalTimeFrameAdjustment !== ""}/>
            </div>
        </section>
    )
}

export default GoalTimeFrameForm;
