import {DropdownItem, Icon, TableActionMenu} from "../../components";
import React from "react";

type BeneficiaryGoalActionMenuProps = {
    onEditGoal: () => void,
    onDeleteGoal: () => void,
    onApplyFamilyAsset: () => void,
    showApplyFamilyAssets?: boolean,
    hasFamilyAssets?: boolean
}

const BeneficiaryGoalActionMenu = ({
                                       onEditGoal,
                                       onDeleteGoal,
                                       onApplyFamilyAsset,
                                       showApplyFamilyAssets,
                                       hasFamilyAssets
                                   }: BeneficiaryGoalActionMenuProps) => {
    return (
        <TableActionMenu className="paddingright-0" panelWidth={240}>
            <DropdownItem
                id="editBeneficiaryGoal"
                onClick={onEditGoal}
                itemText={`Edit Goal`}
                value={'Edit Goal'}
            />
            {showApplyFamilyAssets && <DropdownItem
                id="applyFamilyAssets"
                onClick={onApplyFamilyAsset}
                itemText={`Apply Family Assets`}
                disabled={!hasFamilyAssets}
                value={'Apply Family Assets'}
            />}
            <DropdownItem
                id="deleteBeneficiaryGoal"
                onClick={onDeleteGoal}
                itemText={`Delete Goal`}
                value={'Delete Goal'}
            >
                <Icon name="delete" className="goals-table-delete-icon"/>
            </DropdownItem>
        </TableActionMenu>
    )
};

export default BeneficiaryGoalActionMenu;