import React, {useContext, useEffect, useState} from "react";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory, useParams} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {LegalAgreement} from "../models/InvestmentProgram";
import {RouteWithAssetId} from "../../routes/types";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {HoldingAssetSubclassDetails} from "../models/Holding";
import {LoadingIndicator} from "../../pages/LoadingIndicator";
import {Icon} from "../../components";
import {HoldingsScrollableContainer} from '../StandaloneAccount/Holdings/HoldingsScrollableContainer';
import {HoldingSummaryAccordion} from "../StandaloneAccount/Holdings/HoldingSummaryAccordion";
import {
    LegalAgreementHoldingSummarySubclassDetailsRow
} from "../StandaloneAccount/Holdings/LegalAgreementHoldingSummarySubclassDetailsRow";
import {useAppInsights} from "../../AppInsights";
import HoldingsInfo from "../StandaloneAccount/Holdings/HoldingsInfo";
import {HoldingSummaryAssetSubclass} from "../StandaloneAccount/Holdings/HoldingSummaryAssetSubclass";
import {AssetClassifications} from "../models/AssetClassifications";
import AssetsViewContext from "../common/AssetsViewContext";

export default function LegalAgreementHoldings() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const appInsights = useAppInsights();
    const [legalAgreement, setLegalAgreement] = useState<LegalAgreement>();
    const [domesticTrustHoldings, setDomesticTrustHoldings] = useState<HoldingAssetSubclassDetails[]>();
    const {assetId: legalAgreementId} = useParams<RouteWithAssetId>();
    const [classifications, setClassifications] = useState<AssetClassifications>();

    useEffect(() => {
        appInsights.startTrackPage("ViewLegalAgreementHoldings")
        Promise.all([
            assetsApiClient.getDomesticTrustHoldings(profile.id, legalAgreementId),
            assetsApiClient.getLegalAgreement(profile.id, legalAgreementId),
            assetsApiClient.getAssetClassifications()
        ])
            .then(([holdingsResponse, legalAgreementResponse, assetClassificationResponse]) => {
                setDomesticTrustHoldings(holdingsResponse.allHoldings);
                setLegalAgreement(legalAgreementResponse);
                setClassifications(assetClassificationResponse);
            })
            .catch(reason => console.log(reason))
            .finally(() => {
                appInsights.stopTrackPage("ViewLegalAgreementHoldings", window.location.href, {
                    profileId: profile.id,
                    legalAgreementId: legalAgreementId
                });
            });
    }, [profile.id, legalAgreementId]);

    const handleDone = async () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/EditLegalAgreement/${legalAgreementId}`);
    }

    if (!domesticTrustHoldings || !legalAgreement || !classifications) {
        return <LoadingIndicator/>
    }

    const assetSubclassDetailsRowRenderer = (assetSubclassDetail: HoldingAssetSubclassDetails) =>
        <LegalAgreementHoldingSummarySubclassDetailsRow
            key={`asset-subclass-details-${assetSubclassDetail.id}-${assetSubclassDetail.productName}`}
            assetSubclassDetail={assetSubclassDetail}/>;

    return (
        <div className="legal-agreement-holdings asset-form holdings-container">
            <div className="layout-data-entry-form">
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={legalAgreement.name}
                    onPrimaryButtonClick={handleDone}
                    primaryButtonText="Done"
                    hideSecondaryButton={true}
                />
            </div>
            <HoldingsInfo
                account={{
                    institution: legalAgreement.institution,
                    accountNumber: legalAgreement.legalAgreementNumber,
                    taxStatus: legalAgreement.taxStatus,
                    memberOwnerships: [],
                    legalEntityOwnerships: [],
                    ownershipCategory: "Sole"
                }}
                allHoldings={domesticTrustHoldings}
                classifications={classifications}
            />
            <div className="holding-grid holding-grid-container">
                <div className="grid-display-contents" role="row">
                        <span className="display-flex align-items-center paddingbottom-md paddingleft-xl"
                              role="cell">
                            <Icon name="chevron_double_right"/>
                            <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                        </span>
                    <span className="condensed-subtitle" role="cell">Product</span>
                    <span className="textalign-right condensed-subtitle" role="cell">Account</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Tax Cost</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Market Value</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Investable Value</span>
                </div>
                <div className="empty-grid-row"/>
                <HoldingsScrollableContainer>
                    <HoldingSummaryAccordion
                        riskAssetClasses={classifications?.riskAssetClasses}
                        riskControlAssetClasses={classifications?.riskControlAssetClasses}
                        assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                            return <HoldingSummaryAssetSubclass
                                key={subclassIndex}
                                assetSubclass={assetSubclass}
                                holdingsWithIndex={holdingsWithIndex}
                                subclassIndex={subclassIndex}
                                assetSubclassDetailsRowRenderer={assetSubclassDetailsRowRenderer}/>
                        }}
                        selectedHoldings={domesticTrustHoldings}
                    />
                </HoldingsScrollableContainer>
            </div>
        </div>
    )
}
