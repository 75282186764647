import React, {useEffect} from "react";
import SetPortfolioReserveHeader from "./SetPortfolioReserveHeader";
import {formatCurrency, formatYear} from "../../utils/format";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveModel,
    setPortfolioReserveButtonStates
} from "./PortfolioReserveSlice";
import {COLOR_PORTFOLIO_RESERVE, COLOR_PROJECTED_ASSETS, COLOR_TRANSPARENT} from "../../constants/colors";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {Donut} from "xps-react";
import {portfolioReserveIsInvestablySufficient} from "../../Portfolio/portfolioUtils";
import {Button, InfoPopover} from "../../components";
import AlignedInflowsTable from "./AlignedInflowsTable";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import {setHeaderTickerTabsProposedAllocation} from "../../ClientManagement/ClientProfile/HeaderTickerTabsSlice";
import {selectParticipatingInMeeting} from "../../ClientManagement/Meeting/meetingSlice";
import {useAppInsights} from "../../AppInsights";
import {MeetingParticipation} from "../../ClientManagement/Meeting/Meeting";
import {roundPercentagesToHundred} from "../../utils/roundPercentagesToHundred";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import {LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import classNames from "classnames";

type ReviewPortfolioReserveProps = {
    errorModelData?: GenericErrorModalData
}

const ReviewPortfolioReserve = ({errorModelData}: ReviewPortfolioReserveProps) => {
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;
    const appInsights = useAppInsights();
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const handleErrorCloseButton = () => {
        errorModelData!.isOpen = false;
        setGenericError({...genericError, isOpen: false});
    }

    useEffect(() => {
        // Anything in here is fired on component mount.
        const startTime: number = new Date().valueOf();
        return () => {
            // Anything in here is fired on component unmount.
            const endTime: number = new Date().valueOf();
            appInsights.trackEvent({
                name: 'ReviewPortfolioReserve',
                properties:
                    {
                        screen: 'Review Portfolio Reserve',
                        action: 'Time Spent',
                        meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                        data: {
                            timeSpent: endTime - startTime // Gives the time Spent in milliseconds
                        }
                    }
            })
        }
    }, [])

    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {
        portfolioReserve,
        profile,
        lifestyleSpendingGoal,
        assets,
        normalizedNonLifestyleGoals,
    } = portfolioReserveModel;
    const dispatch = useAppDispatch();
    const portfolioReserveButtons = useAppSelector(selectPortfolioReserveButtonStates);

    const reserveTargetAssetAllocation = {
        totalRiskControlAssetsPercent: portfolioReserve.targetLengthAllocation.riskControlPercent,
        totalRiskAssetsPercent: portfolioReserve.targetLengthAllocation.riskAssetPercent,
    }

    useEffect(() => {
        dispatch(setHeaderTickerTabsProposedAllocation(reserveTargetAssetAllocation));
    }, [reserveTargetAssetAllocation]);

    const portfolioReserveTargetLength = profile.portfolioReserveTargetLength ?? portfolioReserve.portfolioReserveTargetLength;
    const portfolioSize = Math.max(assets.totalInvestableValue, (lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue + normalizedNonLifestyleGoals.nonLifestyleTotalPresentValue))
    const {
        portfolioReservePresentValue,
        portfolioReserveAlignedInflowPresentValue,
        reserveTargetAmount,
        inflowPresentValues
    } = lifestyleSpendingGoal.calculatedFields
    const portfolioIsInvestablySufficient = portfolioReserveIsInvestablySufficient({
        ...portfolioReserveModel,
        lifestylePresentValue: portfolioReserveModel.lifestyleSpendingGoal.calculatedFields,
        totalInvestableValue: assets.totalInvestableValue
    });

    const {
        percentage1: roundedRiskAssetsPercent,
        percentage2: roundedRiskControlAssetsPercent
    } = roundPercentagesToHundred(reserveTargetAssetAllocation.totalRiskAssetsPercent, reserveTargetAssetAllocation.totalRiskControlAssetsPercent);

    return (
        <div className="set-portfolio-reserve-page">
            <div>
                {errorModelData?.isOpen ?
                    <GenericErrorModal
                        errorModalData={errorModelData}
                        onClickClose={() => handleErrorCloseButton()}
                        closeButtonText={'Close'}/> : <React.Fragment/>
                }
            </div>
            <ScrollableContainer className="display-flex flex-column">
                <SetPortfolioReserveHeader displaySetReserveTargetButton={true} isSetReserve={false}/>
                <div className="set-portfolio-reserve-page__body">
                    <div className="set-portfolio-reserve-page__body__chart">
                        <div className="review-portfolio-reserve-page__body__customized-allocation__header-container">
                            <h2 className="review-portfolio-reserve-page__body__customized-allocation__sub-header"
                                text-align="left">
                                Proposed Asset Allocation
                            </h2>
                            <h3 className="review-portfolio-reserve-page__body__customized-allocation__sub-header-text"
                                style={{display: "block"}}> Investable Assets Only</h3>
                        </div>
                        <div className="set-portfolio-reserve-page__donut-container">
                            <div className="set-portfolio-reserve-page__donut-container__solid">
                                <RiskDonut
                                    donutSize={'lg'}
                                    data={reserveTargetAssetAllocation}
                                    testId="RiskDonut"
                                />
                            </div>
                            {portfolioIsInvestablySufficient &&
                                <div className="set-portfolio-reserve-page__donut-container__shaded">
                                    <div className={`donut-container donut-container__xl`}>
                                        <Donut
                                            role="risk-donut"
                                            className="donut"
                                            data-testid="PortfolioReserveDonut"
                                            data={[
                                                {value: (portfolioSize - portfolioReservePresentValue)},
                                                {value: portfolioReservePresentValue},
                                            ]}
                                            colors={[COLOR_TRANSPARENT, COLOR_PORTFOLIO_RESERVE]}
                                            outerRadius={60}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={"risk-assets-and-risk-control"} data-testid="RiskDonutLegend">
                            <div className={"risk"}>
                                <div className={"risk-legend-label"} data-testid="RiskLegendForRA">
                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS}>
                                        <span className={"risk-label-bold"}>Risk Assets</span>
                                    </RiskLegend>
                                    <span
                                        className={"risk-percentage"}>{` ${roundedRiskAssetsPercent}%`}</span>
                                </div>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"} data-testid="RiskLegendForRC">
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}>
                                        <span className={"risk-label-bold"}>Risk Control</span>
                                    </RiskLegend>
                                    <span
                                        className={"risk-percentage"}>{` ${roundedRiskControlAssetsPercent}%`}</span>
                                </div>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"} data-testid="RiskLegendForPortfolioReserve">
                                    <RiskLegend legendColor={riskLegendColor.PORTFOLIO_RESERVE}
                                                label={"Portfolio Reserve"}/>
                                </div>
                            </div>
                            {
                                !portfolioIsInvestablySufficient &&
                                <div data-testid="InvestableInsufficiencyWarning">
                                    <div style={{marginTop: '4px'}}>
                                        <InfoPopover
                                            content={<div>Consider reducing your portfolio reserve target or aligning
                                                goals
                                                to non-investable assets in order to achieve investable
                                                sufficiency.</div>}
                                            direction="bottom"
                                            width="288px"
                                        />
                                        <span
                                            style={{color: COLOR_PROJECTED_ASSETS}}>
                                        Investable Asset Shortfall
                                    </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="review-portfolio-reserve-page__body__customized-allocation">
                        <h2 className="review-portfolio-reserve-page__body__customized-allocation__sub-header">
                            Review your current Portfolio Reserve
                        </h2>
                        <CustomizedAllocationTextForMLS text="Your annual lifestyle spending"
                                                        periods={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                                                        portfolioReserveTargetLength={portfolioReserveTargetLength}/>
                        <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                             src="/Rectangle679.svg"/>
                        <CustomizedAllocationText text="The years of lifestyle spending you want to protect"
                                                  quantity={formatYear(portfolioReserveTargetLength)}/>
                        <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">close</i>
                        <div className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Your
                            Reserve
                            Target Length
                        </div>
                        <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                             src="/Rectangle677.svg"/>
                        <CustomizedAllocationText text="Your Reserve Target Amount"
                                                  quantity={formatCurrency(reserveTargetAmount)}/>
                        <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                        <span className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Calculated in Present Value</span>
                        <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                             src="/Rectangle679.svg"/>
                        <CustomizedAllocationText text="Future inflows you will receive over those years"
                                                  quantity={formatCurrency(portfolioReserveAlignedInflowPresentValue)}>
                            <Button kind="borderless"
                                    size="small"
                                    type="button"
                                    onClick={() => {
                                        dispatch(setPortfolioReserveButtonStates({
                                            ...portfolioReserveButtons,
                                            futureInflow: !portfolioReserveButtons.futureInflow
                                        }));
                                    }}>{portfolioReserveButtons.futureInflow ? "Hide" : "Show"}</Button>
                        </CustomizedAllocationText>
                        <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon review-portfolio-reserve-page__body__customized-allocation__minus-icon">–</i>
                        {portfolioReserveButtons.futureInflow &&
                            <AlignedInflowsTable
                                portfolioReserveTargetLength={portfolioReserveTargetLength}
                                inflowPresentValues={inflowPresentValues}
                            />}
                        <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                             src="/Rectangle677.svg"/>
                        <CustomizedAllocationText text="Your Portfolio Reserve"
                                                  quantity={formatCurrency(portfolioReservePresentValue)}/>
                        <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                        <span className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Allocated to Risk Control Assets</span>

                    </div>
                </div>
            </ScrollableContainer>
        </div>
    );
};

const CustomizedAllocationText = ({children, quantity, text}: {
    text: string,
    quantity: string,
    children?: React.ReactNode
}) => {
    return <div className="review-portfolio-reserve-page__body__customized-allocation__text">
        <div>{text}</div>
        <div className="quantity">{quantity}</div>
        {children ? children : <></>}
    </div>;
}

const CustomizedAllocationTextForMLS = ({periods, portfolioReserveTargetLength, text}: {
    text: string,
    periods: LifestyleSpendingPeriodInputs[],
    portfolioReserveTargetLength: number
}) => {
    const protectedPeriodEndYear = periods[0].startYear + portfolioReserveTargetLength;
    const protectedPeriods = periods
        .filter((period) => period.startYear < protectedPeriodEndYear)
        .map((period) => {
            return {
                ...period,
                endYear: Math.min(period.endYear, protectedPeriodEndYear)
            }
        });

    if (protectedPeriods.length < 2) {
        return <CustomizedAllocationText text={text}
                                         quantity={formatCurrency(protectedPeriods[0]?.annualSpend)}/>
    }
    return <div className="review-portfolio-reserve-page__body__customized-allocation__text">
        <div>{text}</div>
        <div>
            {protectedPeriods.map((item: LifestyleSpendingPeriodInputs, index: number) => {
                    const lastItem = index === protectedPeriods.length - 1;
                    return <div
                        className="review-portfolio-reserve-page__body__customized-allocation__text__spending-period"
                        key={`${index}-${item.id}`}>
                        <div className="quantity">{formatCurrency(item.annualSpend)}</div>
                        <div
                            className={classNames(
                                "review-portfolio-reserve-page__body__customized-allocation__sub-text",
                                {
                                    "paddingbottom-12": !lastItem
                                }
                            )}>
                            {`${(item.endYear - item.startYear)} years`}
                        </div>
                    </div>;
                }
            )}
        </div>
    </div>
}

export default ReviewPortfolioReserve;
