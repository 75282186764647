import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import React from "react";
import {SimplifiedProposedAllocationResponse} from "../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {roundPercentagesToHundred} from "../../utils/roundPercentagesToHundred";

export const ReserveTargetAllocationDonut = (
    {reserveTargetAssetAllocation}: { reserveTargetAssetAllocation: SimplifiedProposedAllocationResponse }
) => {

    const {
        percentage1: roundedRiskAssetsPercent,
        percentage2: roundedRiskControlAssetsPercent
    } = roundPercentagesToHundred(reserveTargetAssetAllocation.totalRiskAssetsPercent, reserveTargetAssetAllocation.totalRiskControlAssetsPercent);

    return (
        <div className={`edit-portfolio-reserve-page__table__asset-allocation`}>
            <RiskDonut
                data={reserveTargetAssetAllocation}
                donutSize={`xsm`}
            />
            <div className={"risk-legend-label"} data-testid="RiskLegendForRA">
                <RiskLegend legendColor={riskLegendColor.RISK_ASSETS}/>
                <div className={'risk-label-centered'}>
                    <span className={"risk-label-margin-right"}>RA</span>
                    <span className="edit-portfolio-reserve-page__table__excess-assets__amount font-xl color--primary">
                        {roundedRiskAssetsPercent}%
                    </span>
                </div>
            </div>
            <div className={"risk-legend-label"} data-testid="RiskLegendForRC">
                <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}/>
                <span className={'risk-label-centered'}>
                    <span className={"risk-label-margin-right"}>RC</span>
                    <span className="edit-portfolio-reserve-page__table__excess-assets__amount font-xl color--primary">
                        {roundedRiskControlAssetsPercent}%
                    </span>
                </span>
            </div>
        </div>
    );
}