import React from "react";
import {
    AssetSubclassSummaries, AssetSubclassSummary,
    CurrentVsProposedTableHeader
} from "../../../ClientManagement/AssetAllocation/AssetHoldings";
import {Row} from "react-grid-system";
import {Accordion, AccordionItem} from "../../../components";
import AssetHoldingHeader from "../../../ClientManagement/AssetAllocation/AssetHoldingHeader";

type AssetHoldingsReportProps = {
    id: string
    assetClassName: string
    total: number
    totalProposed?: number
    totalPercentage: number
    totalProposedPercentage?: number
    accentColor: string
    assetSubclassSummaries: AssetSubclassSummary[],
    continueLabel: string
}
const AssetHoldingsReport: React.FC<AssetHoldingsReportProps> = ({
                                                                     id,
                                                                     assetClassName,
                                                                     total,
                                                                     totalProposed,
                                                                     totalPercentage,
                                                                     totalProposedPercentage,
                                                                     assetSubclassSummaries,
                                                                     accentColor,
                                                                     continueLabel
                                                                 }) => {
return (
    <>
        <Row className={"row"} data-testid="asset-header-text">
        <CurrentVsProposedTableHeader allocationType={assetClassName.toUpperCase()}/>
        </Row>
        <Row className={"row"}>
            <Accordion accordionId={id}
                       className="asset-stack-table-accordion"
                       expanded={[assetClassName]}>
                <AccordionItem
                    uuid={assetClassName}
                    primaryText={assetClassName}
                    accentColor={accentColor}
                    HeaderComponent={({expanded}) => <AssetHoldingHeader
                        expanded={expanded}
                        title={continueLabel}
                        total={total}
                        totalProposed={totalProposed}
                        totalPercentage={totalPercentage}
                        totalProposedPercentage={totalProposedPercentage}
                        showChevron={true}/>}>
                    <AssetSubclassSummaries assetSubclassSummaries={assetSubclassSummaries}/>
                </AccordionItem>
            </Accordion>
        </Row>
    </>);
}

export default AssetHoldingsReport;