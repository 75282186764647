import React from "react";
import {Dropdown, DropdownItem, Label} from "../../../components";

type MemberOwnershipDropdownProps = {
    owner: string;
    owners: { label: string, value: string }[];
    onChange: (value: string) => void;
}
const OwnerDropdown = ({
                                     owner,
                                     owners,
                                     onChange
                                 }: MemberOwnershipDropdownProps) => {

    return (
        <div className="layout-data-entry-form__field">
            <Label labelId="owner" label="Ownership"/>
            <Dropdown
                aria-labelledby="owner"
                size="medium"
                id="ownershipDropdown"
                value={owner}
                onChange={({value}) => onChange(value)}
            >
                {
                    owners.map(({label, value}) => {
                        return (
                            <DropdownItem
                                itemText={label}
                                value={value}
                                key={value}
                            />
                        )
                    })
                }
            </Dropdown>
        </div>
    )
}

export default OwnerDropdown;