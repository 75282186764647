import React, {MouseEventHandler, PropsWithChildren, ReactNode} from 'react';
import classNames from 'classnames';
import {Dropdown as XpsDropdown, DropdownItem as XpsDropdownItem} from 'xps-react';

interface DropdownProps<T extends string> {
    value?: T | null,
    onChange?: (data: DropdownOnChangeData<T>) => void,
    onBlur?: () => void,
    onKeyPress?: (event: any) => void,
    children: ReactNode,
    className?: string,
    alignRight?: boolean,
    buttonIcon?: 'right' | 'left' | 'none' | 'only',
    buttonKind?: 'primary' | 'secondary' | 'tertiary' | 'borderless',
    buttonSize?: 'large' | 'medium' | 'small',
    buttonAriaLabel?: string,
    defaultPageSize?: number,
    defaultText?: string | number,
    description?: string,
    disabled?: boolean,
    dropdownKind?: 'select' | 'menu',
    dropUp?: boolean,
    dynamicDropAlign?: boolean,
    dynamicDropDirection?: boolean,
    error?: string,
    iconNameClose?: string,
    iconNameOpen?: string,
    id?: string,
    inlineLabel?: boolean,
    label?: string,
    labelWidth?: string | number,
    name?: string,
    nativeOnMobile?: boolean,
    open?: boolean,
    optional?: boolean,
    panelHeight?: string | number,
    panelWidth?: string | number,
    removeMarginTop?: boolean,
    required?: boolean,
    rounded?: boolean,
    searchable?: boolean,
    size?: 'small' | 'medium' | 'large',
    virtualScroll?: boolean,
    onClick?: MouseEventHandler,
    drop?: string,
}

function addPropsToChildToPreventWarnings(children: ReactNode): ReactNode {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return child;
        }
        return React.cloneElement(child, {value: "", itemText: "", ...child.props});
    });
}

export default function Dropdown<T extends string>({
                                                       children,
                                                       className,
                                                       iconNameClose = 'menu_up',
                                                       iconNameOpen = 'menu_down',
                                                       rounded = false,
                                                       size = 'medium',
                                                       ...rest
                                                   }: PropsWithChildren<DropdownProps<T>>) {
    return <XpsDropdown
        className={classNames({'dropdown--rounded': rounded}, className)}
        iconNameClose={iconNameClose}
        iconNameOpen={iconNameOpen}
        size={size}
        {...rest}
    >
        {addPropsToChildToPreventWarnings(children)}
    </XpsDropdown>
}

export interface DropdownOnChangeData<T> {
    value: T,
    itemText: string,
    index: number,
    fieldID?: string,
    iconName?: string,
    iconType?: string,
}

type DropdownItemProps = {
    itemText?: string,
    value?: string | number,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void,
    id?: string,
    className?: string,
    children?: ReactNode,
    style?: React.CSSProperties,
    disabled?: boolean,
};

export function DropdownItem({
                                 itemText = "",
                                 disabled = false,
                                 value = "",
                                 ...rest
                             }: DropdownItemProps) {
    return <XpsDropdownItem itemText={itemText} disabled={disabled} value={value} {...rest}/>
}
