import {get, post} from "../core/api";
import {MenuStructureResponse} from "./models/AdviceLibrary";
import {ClientMeetingDocumentsResponse, CreateClientFolderRequest} from "./models/ClientDocuments";

export const getAdviceLibraryDocument = async (menuTitle: string, documentLabel: string, subMenuLabel: string): Promise<Blob> => {
    const response = await get(`/advisory/adviceLibrary/getDocumentFromCache`, {
        queryParams: {
            menuTitle,
            documentLabel,
            subMenuLabel
        }
    });
    return response.blob();
}

export const getMenuStructure = async (): Promise<MenuStructureResponse> => {
    const response = await get(`/advisory/adviceLibrary/menu`);
    return response.json();
}

export const createClientFolder = async (request: CreateClientFolderRequest) : Promise<Response> => {
    return post('/advisory/client/documents/create', request);
}

export const getClientMeetingDocuments = async (profileId: string, clientName: string, ownerId:string) : Promise<ClientMeetingDocumentsResponse> => {
    const response = await get(`/advisory/client/documents`, {
        queryParams: {
            profileId,
            clientName,
            ownerId
        }
    });
    return response.json();
}

export const getMyDocuments = async (lanId:string) : Promise<ClientMeetingDocumentsResponse> => {
    const response = await get(`/advisory/my/documents`, {
        queryParams: {
            lanId
        }
    });
    return response.json();
}

export const getClientMeetingDocumentBlob = async (itemId: string, documentType: string) : Promise<Blob> => {
    const response = await get(`/advisory/client/documents/getDocumentBlob`, {
        queryParams: {
            itemId,
            documentType
        }
    });
    return response.blob();
}

export const resourcesApiClient = {
    getAdviceLibraryDocument,
    getMenuStructure,
    createClientFolder,
    getClientMeetingDocuments,
    getMyDocuments,
    getClientMeetingDocumentBlob
};