import {ColumnCounter} from "./ColumnCounter";
import {ReactElement} from 'react';

export interface TableRow<ValueType> {
    accentColor: string;
    children: TableRow<ValueType>[];
    label: string | ReactElement;
    labelSuffix?: string;
    subtitle?: string;
    uniqueIdentifier: string;
    values: TableCell<ValueType>[];
}

export interface TableHeader<ValueType> {
    label: string;
    subtitle?: string;
    values: TableCell<ValueType>[];
}

export interface TableCell<ValueType> {
    originalValue: ValueType;
    renderedValue: string | ReactElement;
    isBlank: boolean;
    className?: string;
}

interface CreateTableCellParams<ValueType> {
    value: ValueType;
    className?: string;
}

export abstract class TableDisplay<ValueType, TR extends TableRow<ValueType> = TableRow<ValueType>> {
    protected constructor(private readonly columnCounter: ColumnCounter,
                          private readonly renderValueFn: (value: ValueType) => string | ReactElement = (value => String(value))) {
    }

    get header(): TableHeader<ValueType> {
        return {
            label: this.headerLabel,
            values: this.headerValues
        }
    }

    abstract get rows(): TR[]

    protected abstract get headerLabel(): string

    protected abstract get headerValues(): TableCell<ValueType>[]

    protected get columnCount(): number {
        return this.columnCounter.count;
    }

    protected createTableCell({value, className}: CreateTableCellParams<ValueType>): TableCell<ValueType> {
        const isBlank = value === null;
        return {
            originalValue: value,
            renderedValue: isBlank ? '' : this.renderValueFn(value),
            isBlank: isBlank,
            className: className,
        }
    }
}