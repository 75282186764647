import {StandaloneAccount} from "../../../models/StandaloneAccount";
import {LegalAgreement} from "../../../models/InvestmentProgram";
import AccountsTable from "../../InEstate/AccountsTable";
import React from "react";
import {NO_OP} from "../../../../constants/common";
import {AccordionItemWithActionMenu} from "../../../../components";
import {COLOR_ASSETS_ACCOUNTS} from "../../../../constants/colors";
import {
    calculateInEstateInvestableTotalValueForAccounts,
    calculateInEstateTotalMarketValueForAccounts
} from "../../common/AssetSummaryCalculator";
import {AssetAccordionHeader} from "../../common/AssetAccordionHeader";
import {formatCurrency} from "../../../../utils/format";
import {AccordionItemIds} from "../constants";
import {DropResult} from "react-beautiful-dnd";

type AccountsProps = {
    profileId: string,
    accounts: StandaloneAccount[],
    legalAgreements: LegalAgreement[],
    captureAccountsOrder: ({source, destination}: DropResult) => void
}

const Accounts = ({
                      profileId,
                      accounts,
                      legalAgreements,
                      captureAccountsOrder
                  }: AccountsProps) => {

    if (accounts.length === 0 && legalAgreements.length === 0) {
        return <></>;
    }

    return (
        <AccordionItemWithActionMenu
            uuid={AccordionItemIds.accounts}
            expandable={false}
            accentColor={COLOR_ASSETS_ACCOUNTS}
            HeaderComponent={({expanded}) => {
                const totalInEstateMarketValue = calculateInEstateTotalMarketValueForAccounts(accounts, legalAgreements);
                const totalInEstateInvestableValue = calculateInEstateInvestableTotalValueForAccounts(accounts, legalAgreements);
                return <AssetAccordionHeader
                    expanded={expanded}
                    title="Accounts"
                    formattedTotalInvestableValue={formatCurrency(totalInEstateInvestableValue)}
                    formattedTotalPresentValue={formatCurrency(totalInEstateMarketValue)}
                    gridClassName={"assets-grid"}
                    showChevron={false}
                />;
            }}
        >
            <AccountsTable
                profileId={profileId}
                onDeleteAssetClick={NO_OP}
                standaloneAccounts={accounts}
                legalAgreements={legalAgreements}
                hideActionMenu={true}
                isDraggable={true}
                captureAccountsOrder={captureAccountsOrder}
            />
        </AccordionItemWithActionMenu>
    )
}

export default Accounts;