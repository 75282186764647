import {emptyLifestylePresentValueResponse, LifestylePresentValueResponse} from "./PresentValue";

export type PortfolioReserveResponse = {
    portfolioReserveTargetLength: number;
    initialPortfolioReserveTargetLength: number;
    minimumRiskReserveLength: number;
    tripleNetDiscountRate: number;
    monteCarlo: {
        monteCarloMinimumRisk: {
            bottomPercentile: number;
            topPercentile: number;
        },
        monteCarloPortfolioSupports: {
            bottomPercentile: number;
            topPercentile: number;
        }
    },
    cvar: {
        cvarPortfolioSupports: number;
        cvarMinimumRisk: number;
    },
    lifestylePresentValues: {
        reserveTarget: LifestylePresentValueResponse;
        minimumRisk: LifestylePresentValueResponse;
    },
    targetLengthAllocation: {
        riskAssetPercent: number;
        riskControlPercent: number;
    },
    minimumRiskAllocation: {
        riskAssetPercent: number;
        riskControlPercent: number;
    }
}

export const emptyPortfolioReserveResponse : PortfolioReserveResponse = {
    portfolioReserveTargetLength: 0,
    initialPortfolioReserveTargetLength: 0,
    minimumRiskReserveLength: 0,
    tripleNetDiscountRate: 0,
    monteCarlo: {
        monteCarloPortfolioSupports: {
            bottomPercentile: 0,
            topPercentile: 0
        },
        monteCarloMinimumRisk: {
            bottomPercentile: 0,
            topPercentile: 0
        }
    },
    cvar: {
        cvarPortfolioSupports: 0,
        cvarMinimumRisk: 0
    },
    lifestylePresentValues: {
        reserveTarget: emptyLifestylePresentValueResponse,
        minimumRisk: emptyLifestylePresentValueResponse
    },
    targetLengthAllocation: {
        riskAssetPercent: 0,
        riskControlPercent: 100,
    },
    minimumRiskAllocation: {
        riskAssetPercent: 0,
        riskControlPercent: 100,
    }
}
