import ReviseAllocationInput from "./ReviseAllocationInput";
import {
    AlertMessageForRevisedAllocation,
    DataDisplayView,
    ProposedAllocationEntry,
    ReviseAssetAllocationResponse,
    ReviseAssetSubclassesSummary
} from "../ReviseAssetAllocation";
import {AlertBanner, Button} from "../../../components";
import {formatAllocationAmount, formatAllocationPercentage, isDollarView} from "./reviseAssetAllocationUtils";
import React from "react";
import classNames from "classnames";
import {formatNumberRoundedToTwoDecimals} from "../../../utils/format";
import ReviseAllocationSubclassMenu from "./ReviseAllocationSubclassMenu";
import {RiskControlClass} from "../AssetClasses";

type ReviseAssetClassTableProps = {
    assetClassificationName: string;
    assetSubclasses: string[];
    reviseAssetAllocationAssets: ReviseAssetSubclassesSummary[];
    accentColor: string;
    totalCurrentAllocation: number;
    totalRecommendedAllocation: number;
    totalProposedAllocation: number;
    totalProposedAllocationOfTotalPortfolio: number;
    totalDifferenceAmount: number;
    updateProposedAllocationEntry: (value: ProposedAllocationEntry) => void;
    totalRemainingAllocation: AlertMessageForRevisedAllocation | undefined;
    displayView: DataDisplayView;
    showIPCRange: boolean,
    areInputsDisabled: boolean,
    isCompletelyAllocated: boolean,
    toggleExcludeSubclass: (subclassName: string, excludeSubclass: boolean) => Promise<void>;
    allocateRemainingAssetsProRata: () => Promise<ReviseAssetAllocationResponse>;
    showAssetsNotAllocatedWarning: boolean;
    setShowAssetsNotAllocatedWarning: (showAssetsNotAllocatedWarning: boolean) => void;
};

const ReviseAssetClassTable = (props: ReviseAssetClassTableProps) => {
    const {
        assetClassificationName,
        assetSubclasses,
        reviseAssetAllocationAssets,
        accentColor,
        totalCurrentAllocation,
        totalRecommendedAllocation,
        totalProposedAllocation,
        totalProposedAllocationOfTotalPortfolio,
        totalDifferenceAmount,
        updateProposedAllocationEntry,
        totalRemainingAllocation,
        displayView,
        showIPCRange = false,
        areInputsDisabled,
        isCompletelyAllocated,
        toggleExcludeSubclass,
        allocateRemainingAssetsProRata,
        showAssetsNotAllocatedWarning,
        setShowAssetsNotAllocatedWarning
    } = props;
    const dollarView = isDollarView(displayView);

    const gridViewClasses = {
        'revise-allocation-dollar-view': dollarView && showIPCRange,
        'revise-allocation-dollar-view-without-ipc': dollarView && !showIPCRange,
        'revise-allocation-percentage-view': !dollarView && showIPCRange,
        'revise-allocation-percentage-view-without-ipc': !dollarView && !showIPCRange,
    };

    reviseAssetAllocationAssets.sort((asset1, asset2) => {
        return assetSubclasses.indexOf(asset1.subclassName) - assetSubclasses.indexOf(asset2.subclassName);
    });

    return (
        <div className="revise-asset-allocation__table" role="table"
             aria-label={`${assetClassificationName} ${displayView}`}>
            <ReviseAssetClassTableHeader
                assetClassificationName={assetClassificationName}
                isAllocationError={!!totalRemainingAllocation}
                dollarView={dollarView}
                showIPCRange={showIPCRange}
                gridViewClasses={gridViewClasses}
                allocateRemainingAssetsProRata={allocateRemainingAssetsProRata}
                disableProRataButton={isCompletelyAllocated}
                showAssetsNotAllocatedWarning={showAssetsNotAllocatedWarning}
                setShowAssetsNotAllocatedWarning={setShowAssetsNotAllocatedWarning}
            />
            <div
                className="revise-asset-allocation__table__content"
                style={{borderLeft: `12px solid ${accentColor}`}}
            >
                {reviseAssetAllocationAssets.map(({
                                                      subclassName,
                                                      currentInvestablePercent,
                                                      currentInvestableAmount,
                                                      proposedInvestablePercent,
                                                      proposedInvestableAmount,
                                                      proposedInvestablePercentOfTotalPortfolio,
                                                      differenceAmount,
                                                      recommendedProposedTargetPercent,
                                                      recommendedProposedTargetAmount,
                                                      minimumProposedInvestablePercent,
                                                      minimumProposedInvestableAmount,
                                                      maximumProposedInvestablePercent,
                                                      maximumProposedInvestableAmount,
                                                      excludeSubclass,
                                                      isDisableAsset
                                                  }) => {
                    const isOutOfIPCRange = (proposedInvestablePercent > maximumProposedInvestablePercent) || (proposedInvestablePercent < minimumProposedInvestablePercent);
                    return (
                        <div
                            key={subclassName}
                            className={classNames(
                                gridViewClasses,
                                {'excluded-subclass': excludeSubclass || isDisableAsset},
                                'revise-allocation-subclass-grid revise-allocation-subclass-row'
                            )}
                            role="row"
                            tabIndex={0}
                            aria-label={`${subclassName} row`}

                        >
                            <div className="grid-left-contents">
                                <span role="gridcell" aria-label={`${subclassName} Name`}><span>{subclassName}</span>
                                    {subclassName === RiskControlClass.CONVENTIONAL_FIXED_INCOME &&
                                        <i className='marginleft-12'>1 year average target maturity</i>}
                                    {subclassName === RiskControlClass.CASH_AND_CASH_EQUIVALENTS &&
                                        <i className='marginleft-12'>1 year of cash</i>}
                                </span>
                                <span role="gridcell" className="textalign-right"
                                      aria-label={`${subclassName} Current ${dollarView ? "$" : "%"}`}>
                                    {dollarView ? formatAllocationAmount(currentInvestableAmount) : formatAllocationPercentage(currentInvestablePercent)}
                                </span>
                                <span role="gridcell" className="textalign-right aqua-bg"
                                      aria-label={`${subclassName} Recommended Target ${dollarView ? "$" : "%"}`}>
                                    {dollarView ? formatAllocationAmount(recommendedProposedTargetAmount) : formatAllocationPercentage(recommendedProposedTargetPercent)}
                                </span>
                                {showIPCRange &&
                                    <>
                                        <span role="gridcell" className="textalign-right grey-bg"
                                              aria-label={`${subclassName} IPC Range Minimum ${dollarView ? "$" : "%"}`}>
                                            {dollarView ? formatAllocationAmount(minimumProposedInvestableAmount) : formatAllocationPercentage(minimumProposedInvestablePercent)}
                                        </span>
                                        <span role="gridcell" className="textalign-right grey-bg"
                                              aria-label={`${subclassName} IPC Range Maximum ${dollarView ? "$" : "%"}`}>
                                            {dollarView ? formatAllocationAmount(maximumProposedInvestableAmount) : formatAllocationPercentage(maximumProposedInvestablePercent)}
                                        </span>
                                    </>
                                }
                                <span role="gridcell">
                                    {dollarView ?
                                        <ReviseAllocationInput
                                            overrideValue={proposedInvestableAmount}
                                            assetSubclassName={subclassName}
                                            updateProposedAllocationEntry={updateProposedAllocationEntry}
                                            showIpcRangeWarning={isOutOfIPCRange}
                                            disabled={areInputsDisabled || excludeSubclass || isDisableAsset}
                                            displayView={displayView}
                                            totalProposedAmount={totalProposedAllocation}
                                        /> :
                                        <ReviseAllocationInput
                                            overrideValue={proposedInvestablePercent}
                                            assetSubclassName={subclassName}
                                            updateProposedAllocationEntry={updateProposedAllocationEntry}
                                            showIpcRangeWarning={isOutOfIPCRange}
                                            disabled={areInputsDisabled || excludeSubclass || isDisableAsset}
                                            displayView={displayView}
                                            totalProposedAmount={totalProposedAllocation}
                                        />}
                                </span>
                            </div>
                            <span className="grid-divider"></span>
                            <div className="grid-right-contents">
                                <span role="gridcell" className="textalign-right"
                                      aria-label={`${subclassName} ${dollarView ? "Difference $" : "% of Total Portfolio"}`}>
                                    {dollarView ? formatAllocationAmount(differenceAmount) : formatAllocationPercentage(proposedInvestablePercentOfTotalPortfolio)}
                                </span>
                                <ReviseAllocationSubclassMenu
                                    subclassName={subclassName}
                                    toggleExcludeSubclass={toggleExcludeSubclass}
                                    excludeSubclass={excludeSubclass}
                                    isDisableMenu={isDisableAsset}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <ReviseAssetClassTableFooter
                assetClassificationName={assetClassificationName}
                totalRecommendedAllocation={totalRecommendedAllocation}
                totalCurrentAllocation={totalCurrentAllocation}
                totalProposedAllocation={totalProposedAllocation}
                totalProposedAllocationOfTotalPortfolio={totalProposedAllocationOfTotalPortfolio}
                totalDifferenceAmount={totalDifferenceAmount}
                totalRemainingAllocation={totalRemainingAllocation}
                dollarView={dollarView}
                showIPCRange={showIPCRange}
                gridViewClasses={gridViewClasses}
            />
        </div>
    );
};

const ReviseAssetClassTableHeader: React.FC<{
    assetClassificationName: string;
    isAllocationError: boolean;
    dollarView: boolean;
    showIPCRange: boolean;
    gridViewClasses: any;
    allocateRemainingAssetsProRata: () => Promise<ReviseAssetAllocationResponse>;
    disableProRataButton: boolean;
    showAssetsNotAllocatedWarning: boolean;
    setShowAssetsNotAllocatedWarning: (showAssetsNotAllocatedWarning: boolean) => void;
}> = ({
          assetClassificationName,
          isAllocationError,
          dollarView,
          showIPCRange,
          gridViewClasses,
          allocateRemainingAssetsProRata,
          disableProRataButton,
          showAssetsNotAllocatedWarning,
          setShowAssetsNotAllocatedWarning
      }) => {
    return (
        <>
            <AlertBanner
                className="marginbottom-xl"
                showAlert={showAssetsNotAllocatedWarning}
                showCloseBtn={true}
                icon="info"
                type="warning"
                onClose={() => setShowAssetsNotAllocatedWarning(false)}
            >
                {`${assetClassificationName} not allocated pro-rata due to manually editing all subclasses. Manually edit values to total 100% or reset values.`}
            </AlertBanner>
            <div className="revise-asset-allocation__table__header__buttons marginbottom-lg textalign-right">
                <Button
                    icon="none"
                    kind="borderless"
                    size="medium"
                    onClick={allocateRemainingAssetsProRata}
                    disabled={disableProRataButton}
                >
                    {`Allocate Remaining ${assetClassificationName} Pro-Rata`}
                </Button>
            </div>
            <div
                className={classNames(gridViewClasses, 'revise-allocation-subclass-grid revise-asset-allocation__table__header revise-allocation-subclass-row')}>
                <div className="grid-left-contents">
                <span role="columnheader" className="condensed-subtitle grid-row-span-2">
                    {assetClassificationName}
                </span>
                    <span
                        role="columnheader"
                        className="condensed-subtitle textalign-right grid-row-span-2"
                    >
                    {`Current ${dollarView ? "$" : "%"}`}
                </span>
                    <span
                        role="columnheader"
                        className="condensed-subtitle textalign-right grid-row-span-2"
                    >
                    {`Recommended Target ${dollarView ? "$" : "%"}`}
                </span>
                    {showIPCRange && <span
                        role="columnheader"
                        className="condensed-subtitle textalign-center ipc-range"
                    >
                    IPC Range
                </span>}
                    <div
                        className={`${isAllocationError ? "grid-row-span-2-error-bg" : "condensed-subtitle grid-row-span-2"} textalign-right paddingright-xl`}>
                    <span role="columnheader">
                        {`Proposed ${dollarView ? "$" : "%"}`}
                    </span>
                    </div>
                    {showIPCRange &&
                        <>
                        <span
                            role="columnheader"
                            className="condensed-subtitle textalign-right grid-col-span-4-5 margintop-sm marginbottom-sm"
                        >
                            {`Minimum ${dollarView ? "$" : "%"}`}
                        </span>
                            <span
                                role="columnheader"
                                className="condensed-subtitle textalign-right grid-col-span-5-6 margintop-sm marginbottom-sm"
                            >
                            {`Maximum ${dollarView ? "$" : "%"}`}
                        </span>
                        </>
                    }
                </div>
                <span className="grid-divider grid-row-span-2"></span>
                <div className="grid-right-contents">
                <span
                    role="columnheader"
                    className="condensed-subtitle textalign-right grid-row-span-2"
                >
                    {`${dollarView ? "Difference $" : "% of Total \n" + "Portfolio"}`}
                </span>
                    <span className="empty-grid-column grid-row-span-2"/>
                </div>
            </div>
        </>

    );
};

const ReviseAssetClassTableFooter: React.FC<{
    assetClassificationName: string;
    totalCurrentAllocation: number;
    totalRecommendedAllocation: number;
    totalProposedAllocation: number;
    totalProposedAllocationOfTotalPortfolio: number;
    totalDifferenceAmount: number;
    totalRemainingAllocation: AlertMessageForRevisedAllocation | undefined;
    dollarView: boolean;
    showIPCRange: boolean;
    gridViewClasses: any;
}> = ({
          assetClassificationName,
          totalRecommendedAllocation,
          totalCurrentAllocation,
          totalProposedAllocation,
          totalProposedAllocationOfTotalPortfolio,
          totalDifferenceAmount,
          totalRemainingAllocation,
          dollarView,
          showIPCRange,
          gridViewClasses
      }) => (
    <div
        className={classNames(gridViewClasses, 'revise-asset-allocation__table__footer revise-allocation-subclass-grid')}>
        <div className="grid-left-contents">
            <span role="gridcell">Total {assetClassificationName}</span>
            <span role="gridcell" className="textalign-right"
                  aria-label={`Total ${assetClassificationName} Current ${dollarView ? "$" : "%"}`}>
                {dollarView ? formatAllocationAmount(totalCurrentAllocation) : formatAllocationPercentage(totalCurrentAllocation)}
            </span>
            <span role="gridcell" className="textalign-right"
                  aria-label={`Total ${assetClassificationName} Recommended Target ${dollarView ? "$" : "%"}`}>
                {dollarView ? formatAllocationAmount(totalRecommendedAllocation) : formatAllocationPercentage(totalRecommendedAllocation)}
            </span>
            {showIPCRange &&
                <>
                    <span className="empty-grid-column"/>
                    <span className="empty-grid-column"/>
                </>
            }
            <span role="gridcell"
                  className={`${totalRemainingAllocation ? "error-bg" : ""} textalign-right paddingright-xl`}
                  aria-label={`Total ${assetClassificationName} Proposed ${dollarView ? "$" : "%"}`}>
                {dollarView ? formatNumberRoundedToTwoDecimals(totalProposedAllocation) : formatAllocationPercentage(totalProposedAllocation)}
                {totalRemainingAllocation &&
                    <div aria-label={`${assetClassificationName} Error Difference`}>
                        {dollarView ?
                            formatNumberRoundedToTwoDecimals(totalRemainingAllocation.differenceInTotalProposedAllocationAmount) :
                            formatAllocationPercentage(totalRemainingAllocation.differenceInTotalProposedAllocationPercent)}
                    </div>
                }
            </span>
        </div>
        <span className="grid-divider"></span>
        <div className="grid-right-contents">
            <span role="gridcell" className="textalign-right"
                  aria-label={`Total ${assetClassificationName} ${dollarView ? "Difference $" : "% of Total Portfolio"}`}>
                {dollarView ? formatAllocationAmount(totalDifferenceAmount) : formatAllocationPercentage(totalProposedAllocationOfTotalPortfolio)}
            </span>
            <span className="empty-grid-column"/>
        </div>
    </div>
);

export default ReviseAssetClassTable;
