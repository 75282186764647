import React, {useEffect, useState} from 'react';
import {formatCurrency} from "../../utils/format";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Loader} from "xps-react";
import {
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveModel,
    selectPortfolioReserveUserPreferences,
    setPortfolioReserveButtonStates,
    setPortfolioReserveUserPreferences
} from "./PortfolioReserveSlice";
import {Button, Icon} from "../../components";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";

export type ExcessAssetsProps = {
    presentValueType: 'portfolioReserve' | 'minimumRisk';
    minRiskDescriptionText?: string;
    editPortfolioReserveData: PortfolioReserveResponse
}

const ExcessAssets: React.FC<ExcessAssetsProps> = ({
                                                       presentValueType,
                                                       minRiskDescriptionText,
                                                       editPortfolioReserveData
                                                   }) => {
    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {
        assets,
        normalizedNonLifestyleGoals,
        taxLiabilities
    } = portfolioReserveModel;
    const {profile} = useAppSelector(selectPortfolioReserveModel);

    const portfolioReserveButtonStates = useAppSelector(selectPortfolioReserveButtonStates);
    const {
        excessAssets
    } = portfolioReserveButtonStates;

    const dispatch = useAppDispatch();
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences);
    const {portfolioReserveOptions} = portfolioReserveUserPreferences;
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(false);
    }, [excessAssets])

    const excessAssetsClickHandler = async () => {
        dispatch(setPortfolioReserveUserPreferences({
            ...portfolioReserveUserPreferences,
            portfolioReserveOptions,
            editablePortfolioReserve: portfolioReserveButtonStates.portfolioRisk,
            clickedInputYear: false
        }));


        dispatch(setPortfolioReserveButtonStates({
            ...portfolioReserveButtonStates,
            excessAssets: portfolioReserveButtonStates.portfolioRisk
        }));

        setLoading(false);
    }

    const renderExcessAssetsAmount = () => {
        if (loading) {
            return <Loader ariaLabel="Loading" size="md"/>
        } else {
            const presentValueResponse = presentValueType === 'portfolioReserve' ? editPortfolioReserveData.lifestylePresentValues.reserveTarget : editPortfolioReserveData.lifestylePresentValues.minimumRisk;
            return <span className="edit-portfolio-reserve-page__table__excess-assets__amount font-xl color--primary">
                {formatCurrency(assets.netValue.inEstateValue - presentValueResponse.presentValue - normalizedNonLifestyleGoals.nonLifestyleTotalPresentValue - taxLiabilities.totalTaxLiabilities)}</span>
        }
    }

    const monteCarloBottomPercentile = () => {
        return presentValueType === 'minimumRisk'
            ? editPortfolioReserveData.monteCarlo.monteCarloMinimumRisk.bottomPercentile
            : editPortfolioReserveData.monteCarlo.monteCarloPortfolioSupports.bottomPercentile;
    }

    const monteCarloTopPercentile = () => {
        return presentValueType == 'minimumRisk'
            ? editPortfolioReserveData.monteCarlo.monteCarloMinimumRisk.topPercentile
            : editPortfolioReserveData.monteCarlo.monteCarloPortfolioSupports.topPercentile;
    }

    if (excessAssets || profile.portfolioReserveTargetLength) {
        return (
            <div>
                {portfolioReserveOptions.showOutcomeRange && <div
                    className="edit-portfolio-reserve-page__table__returns edit-portfolio-reserve-page__table__returns__worse">
                    <div className="edit-portfolio-reserve-page__table__returns__label">
                        <div>
                            <div className="font-md fontweight-400">Returns worse than expected</div>
                            <div className="edit-portfolio-reserve-page__table__returns__percentile-text">
                                (10th percentile)
                            </div>
                        </div>
                        <Icon className="trending-down" name="trending_down" size="large"/>
                    </div>
                    <div className="edit-portfolio-reserve-page__table__returns__amount">
                        {formatCurrency(monteCarloBottomPercentile())}
                    </div>
                </div>
                }
                <div className="edit-portfolio-reserve-page__table__excess-assets-total">
                    <span className="font-md fontweight-500">Excess Assets (All)</span>
                    {renderExcessAssetsAmount()}
                </div>
                {portfolioReserveOptions.showOutcomeRange && <div
                    className="edit-portfolio-reserve-page__table__returns edit-portfolio-reserve-page__table__returns__better">
                    <div className="edit-portfolio-reserve-page__table__returns__label">
                        <div>
                            <div className="fond-md fontweight-400">Returns better than expected</div>
                            <div className="edit-portfolio-reserve-page__table__returns__percentile-text">
                                (90th percentile)
                            </div>
                        </div>
                        <Icon className="trending-up" name="trending_up" size="small"/>
                    </div>
                    <div className="edit-portfolio-reserve-page__table__returns__amount">
                        {formatCurrency(monteCarloTopPercentile())}
                    </div>
                    <div
                        className="edit-portfolio-reserve-page__table__returns__info-text">{presentValueType == 'minimumRisk' && minRiskDescriptionText}</div>
                </div>
                }
            </div>
        );
    } else {
        return (
            <Button
                icon="only"
                kind="borderless"
                iconName="add_circle_outline"
                size="large"
                type="button"
                className="edit-portfolio-reserve-page__button"
                onClick={excessAssetsClickHandler}
            />
        );
    }
}

export default ExcessAssets;
