import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {formatCurrency} from "../../../utils/format";
import {AssetTableDisplay} from "./AssetTableDisplay";
import {GoalTableDisplay} from "./GoalTableDisplay";
import {ReviewAssetTableDisplay} from "./ReviewAssetTableDisplay";
import {AssetRelianceStack} from "../../models/AssetRelianceResponse";
import {hasPositiveExcessAssets} from "../AssetRelianceUtil";
import {COLOR_ASSET_SHORTFALL_ACCENT, COLOR_EXCESS_ASSETS_ACCENT} from "../../../constants/colors";
import {PrimaryContact} from "../../models/PrimaryContact";
import {InvestorGroupType} from "../../models/InvestorGroupType";

export class ExcessAssetsTableDisplay extends TableDisplay<number> {
    constructor(
        columnCounter: ColumnCounter,
        private readonly assetTableDisplay: AssetTableDisplay | ReviewAssetTableDisplay,
        private readonly goalTableDisplay: GoalTableDisplay,
        private readonly allAssetsStack: AssetRelianceStack,
        private readonly showExpectedExcessAssets: boolean,
        private readonly investorGroup?: InvestorGroupType
    ) {
        super(columnCounter, (value) => formatCurrency(value));
    }

    get rows(): TableRow<number>[] {
        const excessAssetsHeaderValues = Array(1).fill(0);

        return this.showExpectedExcessAssets ? excessAssetsHeaderValues.map((): TableRow<number> => ({
            accentColor: hasPositiveExcessAssets(this.allAssetsStack.excessAssets) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT,
            uniqueIdentifier: "",
            label: ExcessAssetsTableDisplay.createLabel(this.investorGroup!),
            subtitle: ExcessAssetsTableDisplay.createSubtitle(this.investorGroup!),
            values: this.createFutureValueOfExcessAssetsTableCells(this.allAssetsStack),
            children: []
        })) : [];
    }

    protected get headerLabel(): string {
        return hasPositiveExcessAssets(this.allAssetsStack.excessAssets) ? "Excess Assets" : "Asset Shortfall";
    }

    protected get headerValues(): TableCell<number>[] {
        const assetHeaderValues = this.assetTableDisplay.header.values;
        const goalHeaderValues = this.goalTableDisplay.header.values;
        const excessAssetsHeaderValues = Array(this.columnCount).fill(0);

        if (assetHeaderValues.length !== this.columnCount || goalHeaderValues.length !== this.columnCount) {
            console.error(`Mismatched column counts: Excess Assets = ${this.columnCount}, Assets = ${assetHeaderValues.length}, Goals = ${goalHeaderValues.length}`);
            return [];
        }

        for (let i = 0, l = this.columnCount; i < l; i++) {
            excessAssetsHeaderValues[i] = assetHeaderValues[i].originalValue - goalHeaderValues[i].originalValue;
        }
        return excessAssetsHeaderValues.map(value => {
            const tableCellClassName = value < 0 ? 'negative-difference' : undefined;
            return this.createTableCell({value, className: tableCellClassName});
        });
    }

    private static createLabel(investorGroup: InvestorGroupType): string {
        const {ageFrom, numberOfYears} = investorGroup.planningPeriod;
        return `Excess Assets at Age ${ageFrom + numberOfYears}`;
    }

    private static createSubtitle(investorGroup: InvestorGroupType): string {
        const {numberOfYears} = investorGroup.planningPeriod;
        return `${numberOfYears} year planning period`;
    }

    private createFutureValueOfExcessAssetsTableCells(allAssetStacks: AssetRelianceStack): TableCell<number>[] {
        const futureValueOfExcessAssets: number = allAssetStacks.futureValueOfExcessAssets;
        const tableCellClassName = undefined;
        return [
            this.createTableCell({value: futureValueOfExcessAssets, className: tableCellClassName})
        ];
    }
}