import React from "react";
import {AgendaItem} from "./models/AgendaDetails";
import AgendaEntryForm from "./AgendaEntryForm";
import {DEFAULT_DISCUSSION_TOPIC} from "./agendaConstants";
import AgendaEditDelete from "./AgendaEditDelete";
import {Button} from "../components";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import classNames from "classnames";

type AgendaEntryListProps = {
    agendaEntries: AgendaItem[];
    onUpdateAgendaEntryItems: (
        dataIndex: number,
        agendaEntry: AgendaItem,
        previousAgendaEntry?: AgendaItem
    ) => void;
    setAgendaEntryFormData: (agendaItem: AgendaItem) => void;
    agendaEntryFormData: AgendaItem;
    onReorderEntries: (result: DropResult) => void;
};

const AgendaEntryList: React.FC<AgendaEntryListProps> = ({
                                                             agendaEntries,
                                                             onUpdateAgendaEntryItems,
                                                             setAgendaEntryFormData,
                                                             agendaEntryFormData,
                                                             onReorderEntries,
                                                         }: AgendaEntryListProps) => {
    const onClickAddEditDiscussionTopic = (
        dataIndex: number,
        agendaEntry: AgendaItem
    ) => {
        const addItemIndex = agendaEntries.findIndex((item) => item["mode"]);
        if (addItemIndex !== -1) {
            onUpdateAgendaEntryItems(dataIndex, agendaEntry, {
                ...agendaEntries[addItemIndex],
                ...agendaEntryFormData,
            });
        } else {
            onUpdateAgendaEntryItems(dataIndex, agendaEntry);
        }

        setAgendaEntryFormData({...agendaEntry});
    };

    const handleDeleteAgendaEntry = (
        dataIndex: number,
        agendaItem: AgendaItem
    ) => {
        onUpdateAgendaEntryItems(dataIndex, {...agendaItem, mode: "delete"});
    };

    const sortedAgendaEntries = [...agendaEntries].sort((a: AgendaItem, b: AgendaItem) => a.itemSequenceNo - b.itemSequenceNo);

    return (
        <>
            <DragDropContext onDragEnd={onReorderEntries}>
                <Droppable droppableId="droppable">
                    {(providedDroppable) => {
                        return (
                            <div
                                {...providedDroppable.droppableProps}
                                ref={providedDroppable.innerRef}
                                className="agenda-page__schedule__list"
                                aria-label="Agenda Entries"
                            >
                                {sortedAgendaEntries
                                    .map((agendaItem: AgendaItem, index) => {
                                        return (
                                            <Draggable
                                                key={agendaItem.id || index.toString()}
                                                draggableId={agendaItem.id || index.toString()}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        aria-label="Agenda Entry"
                                                        className={"agenda-page__schedule__list__item"}
                                                        key={index}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <AgendaEntry
                                                            agendaItem={agendaItem} dataIndex={index}
                                                            onClickEditEntryItem={onClickAddEditDiscussionTopic}
                                                            onDeleteEntryItem={handleDeleteAgendaEntry}
                                                            agendaEntryFormData={agendaEntryFormData}
                                                            setAgendaEntryFormData={setAgendaEntryFormData} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {providedDroppable.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
            <div className="margin-lg">
                <Button
                    icon="left"
                    iconName="add"
                    size="medium"
                    kind={"borderless"}
                    onClick={() =>
                        onClickAddEditDiscussionTopic(agendaEntries.length, {
                            ...DEFAULT_DISCUSSION_TOPIC,
                        })
                    }
                >
                    Add Discussion Topic
                </Button>
            </div>
        </>
    );
};

type AgendaEntryProps = {
    agendaItem: AgendaItem,
    dataIndex: number,
    onClickEditEntryItem: (dataIndex: number, agendaEntry: AgendaItem) => void,
    onDeleteEntryItem: (dataIndex: number, agendaItem: AgendaItem) => void,
    agendaEntryFormData: AgendaItem,
    setAgendaEntryFormData: (agendaItem: AgendaItem) => void
}

export function AgendaEntry({
                         agendaItem,
                         dataIndex,
                         onClickEditEntryItem,
                         onDeleteEntryItem,
                         agendaEntryFormData,
                         setAgendaEntryFormData
                     }: AgendaEntryProps) {
    const {
        title,
        startTime,
        presenterName,
        notes,
        mode,
        itemSequenceNo,
    } = agendaItem;
    const divider = startTime && presenterName ? "|" : "";
    return <>
        {!mode ? (
            <>
                {(startTime || presenterName) && (
                    <div
                        className="agenda-header justify-content-between display-flex align-items-center">
                        <b className={"agenda-title"}>
                            <div>
                                {`${startTime || ""} ${divider}`}{" "}
                                <span className={"presenter-name"}>
                                        {" "}
                                    {`${presenterName || ""}`}
                                      </span>
                            </div>
                        </b>
                        <span className="align">
                                    <AgendaEditDelete
                                        agendaEntryData={agendaItem}
                                        dataIndex={dataIndex}
                                        onClickEditEntryItem={
                                            onClickEditEntryItem
                                        }
                                        onDeleteEntryItem={
                                            onDeleteEntryItem
                                        }
                                    />
                                  </span>
                    </div>
                )}
                <div
                    className="agenda-header display-flex justify-content-between align-items-center">
                    <h4 className="fontweight-medium margintop-sm marginbottom-sm agenda-title">
                        {title}
                    </h4>
                    {!(startTime || presenterName) && (
                        <span className="align">
                                    <AgendaEditDelete
                                        agendaEntryData={agendaItem}
                                        dataIndex={dataIndex}
                                        onClickEditEntryItem={
                                            onClickEditEntryItem
                                        }
                                        onDeleteEntryItem={
                                            onDeleteEntryItem
                                        }
                                    />
                                  </span>
                    )}
                </div>
                <span>{notes}</span>
            </>
        ) : (
            <AgendaEntryForm
                agendaEntryData={{
                    ...agendaEntryFormData,
                    itemSequenceNo: itemSequenceNo,
                }}
                dataIndex={dataIndex}
                onUpdateAgendaEntryItems={
                    onClickEditEntryItem
                }
                setAgendaEntryFormData={setAgendaEntryFormData}
                onDeleteEntryFormObject={onDeleteEntryItem}
            />
        )}
    </>;
}

export default AgendaEntryList;
