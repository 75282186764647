import {StandaloneAccount} from "../../models/StandaloneAccount";
import moment from "moment";
import {DISPLAY_DATE_FORMAT} from "../../../constants/common";
import {LiabilitiesPopover} from "../Liabilities/LiabilitiesPopover";
import {TableCell} from "../../../components/Table/TableCell";
import {formatCurrency} from "../../../utils/format";
import {calculateInEstateValue} from "../common/AssetSummaryCalculator";
import React from "react";
import {shortenInstitution, shortenName, shortenString} from "../../common/textUtils";

export const AssetSummaryStandaloneAccountDetails = ({account}: { account: StandaloneAccount }) => {
    const investableValue = account.holdings.totalInvestableValue ? account.holdings.totalInvestableValue : 0;
    const presentableTaxStatus = (account.entityType) ? account.taxStatus : undefined;
    const presentableAsOfDate = account?.asOfDate ? `As of ${moment(account?.asOfDate).format(DISPLAY_DATE_FORMAT)}` : '--';
    const presentableAccountNumber = `(...${account.accountNumber?.substring(account.accountNumber.length - 4)})`;

    const shouldShowAccountNumber = !!account.institution && !!account.accountNumber && account.accountNumber.length > 3;
    const liabilitySummaries = account.personalLiabilities;
    return <>
        <div role="cell" className='textalign-left'>
            <div className="cell-with-popover">
                <div className="label-15px cell-text-content paddingright-sm">
                    <span>{shortenName(account.name)}</span>
                </div>
                {
                    liabilitySummaries?.length > 0
                        ? <LiabilitiesPopover id={account.id} liabilitySummaries={liabilitySummaries}/>
                        : <></>
                }
            </div>
            <div className="sublabel-12px cell-text-content">{presentableAsOfDate}</div>
        </div>
        <TableCell text={account.institution && shortenInstitution(account.institution)}
                   subtext={shouldShowAccountNumber ? presentableAccountNumber : undefined} textSize="small"/>
        <TableCell text={account.entityType && shortenString(account.entityType, 10)} subtext={presentableTaxStatus} textSize="small"/>
        <TableCell text={formatCurrency(calculateInEstateValue(investableValue, account.memberOwnerships))}
                   className="textalign-right"/>
    </>;
};
