import {AgendaDetails, Presenter, PresenterType, ServiceTeamMemberAgenda} from "./models/AgendaDetails";
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Button, Input, UnderlinedHeader} from "../components";
import {ServiceTeamMember} from "../ClientManagement/models/ServiceTeam";
import {EditableDropdownAgenda} from "./EditableDropdownAgenda";

type PresentersProps = {
    partners: ServiceTeamMember[],
    updatedAgendaDetails: AgendaDetails,
    setUpdatedAgendaDetails: (updatedAgendaDetails: AgendaDetails) => void,
}

function PresentersSection(props: { title: string, buttonText: string, onAddPresenter: () => void, children: ReactNode }) {
    return <section>
        <div className="display-flex justify-content-between align-items-center">
            <UnderlinedHeader
                className="agenda-details-section-header"
                primaryText={props.title}
                rightAlignedContent={<Button
                    icon="left"
                    iconName="add"
                    size="medium"
                    kind="borderless"
                    onClick={props.onAddPresenter}
                >
                    {props.buttonText}
                </Button>}
            />
        </div>
        {props.children}
    </section>
}


const Presenters: React.FC<PresentersProps> = ({partners, updatedAgendaDetails, setUpdatedAgendaDetails}) => {
    const [ntPresenters, setNtPresenters] = useState<Presenter[]>([]);
    const [externalPresenters, setExternalPresenters] = useState<Presenter[]>([]);
    const [ntPartners, setNtPartners] = useState<ServiceTeamMemberAgenda[]>(partners);

    useEffect(() => {

        // form ntPartners with selected presentrs having disabled true, and non-selected presenter having disabled false.
        const svcTeamNtPartners: Array<ServiceTeamMemberAgenda> = ntPartners;
        const customPresenters: Presenter[] = [];
        svcTeamNtPartners.forEach(mbr => mbr.disabled = false);
        updatedAgendaDetails.presenters.filter(p => p.presenterType == 'NT_PRESENTER' && p.presenterName).forEach((np) => {
            const selectedPresenterIndexInPartners = partners.findIndex(p => (p.partnerCode == np.presenterId));

            if (selectedPresenterIndexInPartners == -1) {
                customPresenters.push(np);
            } else {
                svcTeamNtPartners[selectedPresenterIndexInPartners].disabled = true;
            }
        });
        const copyOfNtPartners: Array<ServiceTeamMemberAgenda> = JSON.parse(JSON.stringify(svcTeamNtPartners));

        customPresenters.forEach(np => {
            copyOfNtPartners.push(
                {
                    partnerCode: np.presenterId as string,
                    partnerName: np.presenterName as string,
                    phoneNumber: '',
                    email: '',
                    title: np.presenterTitle as string,
                    disabled: true
                }
            )
        });
        setNtPartners(JSON.parse(JSON.stringify(copyOfNtPartners)));
    }, [])


    useEffect(() => {
        setNtPresenters(updatedAgendaDetails.presenters.filter(presenter => presenter.presenterType === 'NT_PRESENTER'));
        setExternalPresenters(updatedAgendaDetails.presenters.filter(presenter => presenter.presenterType === 'EXTERNAL_PRESENTER'));

    }, [updatedAgendaDetails?.presenters])

    function formatDisplayName(partnerName: string, partnerTitle: string) {
        return (partnerName + (partnerTitle ? `, ${partnerTitle}` : ''));
    }

    function addNewPresenter(presenterType: PresenterType) {

        const ntPresenter = [...ntPresenters];
        const extPresenter = [...externalPresenters];

        if (presenterType === 'NT_PRESENTER') {
            ntPresenter.splice(ntPresenter.length, 0, {presenterType})
        }
        setNtPresenters(ntPresenter);
        if (presenterType === 'EXTERNAL_PRESENTER') {
            extPresenter.splice(extPresenter.length, 0, {presenterType})
        }

        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: [...ntPresenter, ...extPresenter]
        })
    }

    function deletePresenter(presenterType: PresenterType, index: number) {
        const updatedPresenters = [...ntPresenters, ...externalPresenters];
        const presenterId = updatedPresenters[index].presenterId;
        let indexOfPresenter = index;
        if (presenterType === "EXTERNAL_PRESENTER") {
            indexOfPresenter = ntPresenters.length + index;
        }
        updatedPresenters.splice(indexOfPresenter, 1);
        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: updatedPresenters
        })

        const copyOfPartners = ntPartners;
        const deletedPartner = copyOfPartners.find(p => p.partnerCode == presenterId);
        if (deletedPartner) deletedPartner.disabled = false;
        setNtPartners(copyOfPartners)
    }

    function getPartnerDisplayName(presenterId: string, presenterName: string) {
        if (!presenterId) return presenterName;
        const selectedPartner = ntPartners.find(partner => partner.partnerCode === presenterId);
        return (selectedPartner && formatDisplayName(selectedPartner.partnerName, selectedPartner.title));
    }

    function handleChangePresenter(value: string, index: number) {
        const existingPartner = ntPartners.find(p => p.partnerCode == value);
        const updatedPresentersList = [...ntPresenters, ...externalPresenters];
        if (!existingPartner) {
            const customPartnerName = value.split(/,(.*)/s)[0];
            const customPartnerTitle = value.split(/,(.*)/s)[1];
            const customPArtnerfullName = customPartnerTitle ? customPartnerName + customPartnerTitle : customPartnerName;
            updatedPresentersList[index] = {
                ...updatedPresentersList[index],
                presenterId: customPArtnerfullName,
                presenterName: customPartnerName,
                presenterTitle: customPartnerTitle ? customPartnerTitle : ""
            }
        } else {
            const selectedPartnerIndex = ntPartners.findIndex(p => p.partnerCode == value);
            const newNtPartners = ntPartners;
            newNtPartners[selectedPartnerIndex].disabled = true;
            setNtPartners(newNtPartners);

            const existingPresenter: Presenter = {
                presenterId: existingPartner.partnerCode,
                presenterName: existingPartner.partnerName,
                presenterTitle: existingPartner.title,
                presenterType: 'NT_PRESENTER'
            }
            const updatedNTPresenters = ntPresenters;
            updatedNTPresenters[index] = existingPresenter;
            setNtPresenters(updatedNTPresenters);

            updatedPresentersList[index] = {
                ...updatedPresentersList[index],
                ...existingPresenter

            }
        }


        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: updatedPresentersList
        })
    }

    function handleChangeExternalPresenter(e: ChangeEvent<HTMLInputElement>, index: number) {
        const {name, value} = e.target;
        const updatedPresentersList = [...ntPresenters, ...externalPresenters];
        const externalPresenterIndex = ntPresenters.length + index;
        updatedPresentersList[externalPresenterIndex] = {
            ...updatedPresentersList[externalPresenterIndex],
            [name]: value
        }
        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: updatedPresentersList
        })
    }


    function addCustomPresenter(value: any, index: number) {

        if (value.startsWith(',')) return;
        const customPartnerName = value.split(/,(.*)/s)[0];
        const customPartnerTitle = value.split(/,(.*)/s)[1];
        const fullName = customPartnerTitle ? customPartnerName + customPartnerTitle : customPartnerName;
        if (ntPartners.findIndex(p => (p.partnerName == customPartnerName && ((p.title == customPartnerTitle) || !customPartnerTitle))) >= 0) {
            return;
        }
        if (value) {
            const newPresenter: Presenter = {
                id: '',
                presenterId: fullName,
                presenterName: customPartnerName,
                presenterTitle: customPartnerTitle,
                presenterType: 'NT_PRESENTER'
            }
            setNtPresenters([...ntPresenters, newPresenter])
            handleChangePresenter(value, index);
            setNtPartners([...ntPartners, {
                partnerCode: fullName,
                partnerName: customPartnerName,
                phoneNumber: '',
                email: '',
                title: customPartnerTitle,
                disabled: true
            }])
        }

    }

    const NTPresenter = ({
                             presenterId,
                             index,
                             presenterName
                         }: { presenterId: string, index: number, presenterName: string }) => {
        const displayName = getPartnerDisplayName(presenterId, presenterName);
        return <div key={presenterId || presenterName}
                    aria-label={`Northern Trust Presenter - ${displayName}`}
                    className='display-flex justify-content-between presenter-list-view__fields'>
            <EditableDropdownAgenda
                value={presenterId}
                aria-label={`Northern Trust Presenter Dropdown - ${displayName}`}
                items={ntPartners}
                onAdd={(value) => addCustomPresenter(value, index)}

                onChange={(value) => {
                    handleChangePresenter(value, index)
                }}
                label={""}/>
            <Button
                size="medium"
                kind="borderless"
                onClick={() => deletePresenter('NT_PRESENTER', index)}
            >
                Delete
            </Button>
        </div>
    };

    return (
        <>
            <PresentersSection
                title='Presenters'
                buttonText='Add Presenter'
                onAddPresenter={() => addNewPresenter('NT_PRESENTER')}
            >
                <div className="layout-data-entry-form__field presenter-field margintop-20">
                    <label id="northernTrustPresenter">
                        <b>Northern Trust Presenters</b> <i className="color-text--error">*</i>
                    </label>
                    <div className="presenter-list-view">
                        {ntPresenters.map(({presenterId, presenterName}, index) =>
                            <NTPresenter
                                key={index}
                                presenterId={presenterId!}
                                index={index}
                                presenterName={presenterName as string}
                            />)}
                    </div>
                </div>
            </PresentersSection>
            <PresentersSection
                title='External Presenters (optional)'
                buttonText='Add External Presenter'
                onAddPresenter={() => addNewPresenter('EXTERNAL_PRESENTER')}
            >
                <div>
                    {externalPresenters.map(({presenterName, presenterTitle}, index) => (
                        <div
                            className='external-presenter'
                            key={`EXTERNAL_PRESENTER-${index}`}
                            aria-label={`External Presenter - ${presenterName}`}
                        >
                            <div className="layout-data-entry-form__field">
                                <label id="presenterName"><b>Presenter Name</b></label>
                                <Input
                                    name="presenterName"
                                    aria-label={`External Presenter Name - ${presenterName}`}
                                    removeMarginTop
                                    size="medium"
                                    type="text"
                                    value={presenterName || ''}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeExternalPresenter(e, index)}
                                />
                            </div>
                            <div className="layout-data-entry-form__field">
                                <label id="presenterTitle"><b>Presenter Title</b></label>
                                <Input
                                    name="presenterTitle"
                                    aria-label={`External Presenter Title - ${presenterTitle}`}
                                    removeMarginTop
                                    size="medium"
                                    type="text"
                                    value={presenterTitle || ''}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeExternalPresenter(e, index)}
                                />
                            </div>
                            <div className="external-delete-button">
                                <Button
                                    size="medium"
                                    kind="borderless"
                                    onClick={() => deletePresenter('EXTERNAL_PRESENTER', index)}
                                >
                                    Delete
                                </Button>
                            </div>
                            <hr className="section"/>
                        </div>
                    ))}
                </div>
            </PresentersSection>
        </>
    )
}

export default Presenters;