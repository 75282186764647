import {Button} from "./index";
import React from "react";

interface DeleteButtonProps {
    onDelete: () => void
    className?: string
}

export const DeleteButton = ({onDelete, className = 'delete', ...rest}: DeleteButtonProps) =>
    <Button
        icon='left'
        iconName="close"
        kind="borderless"
        destructive
        size='medium'
        className={className}
        {...rest}
        onClick={onDelete}
    />;

