import React, {useEffect} from 'react';
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {
    selectActiveFormAsset,
    selectClientAssets, selectInEstateAssetTotals,
} from "../../../Assets/clientAssetsSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {AssetSummaryReportView} from "./AssetSummaryReportView";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {setAccordionState} from "../../../components/Accordion/accordionSlice";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";

const accordionIds = [
    {
        id: "AssetSummaryLiabilityAccordionReport",
        expandedItems: ["PersonalLiabilities"]
    },
    {
        id: "AssetSummaryAccordionReport",
        expandedItems: ["Accounts", "PersonalAssets", "FutureInflows", "LifeInsurances"]
    },
    {
        id:"AssetSummaryOutOfEstateAccordion",
        expandedItems: [ "Irrevocable Trust",
            "Irrevocable Life Insurance Trust (ILIT)",
            "Charitable Foundation",
            "Donor Advised Fund (DAF)",
            "529 Plan",
            "Dynasty Trust",
            "LP, LLC, Other Partnership",
            "S Corp",
            "C Corp",
            "Other"]
    }
]

const accordionPreferencesIds = [
    {
        id:"AssetSummaryOutOfEstateAccordionReport",
        expandedItems: [ "Irrevocable Trust",
            "Irrevocable Life Insurance Trust (ILIT)",
            "Charitable Foundation",
            "Donor Advised Fund (DAF)",
            "529 Plan",
            "Dynasty Trust",
            "LP, LLC, Other Partnership",
            "S Corp",
            "C Corp",
            "Other"]
    }
]

export type AssetSummaryReportProps = {
    displayName: string,
    investorGroup: InvestorGroupType,
    profileId: string,
    memberGroup?: MemberGroup,
    legalEntities: LegalEntityReadModel[],
    isDetailed: boolean
}

const AssetSummaryReport: React.FC<any> = ({
    displayName,
    isDetailed,
    investorGroup,
    profileId,
    memberGroup,
    legalEntities
}: AssetSummaryReportProps) => {
    const dispatch = useAppDispatch();

    const clientAssets = useAppSelector(selectClientAssets);
    const inEstateAssetsTotals = useAppSelector(selectInEstateAssetTotals)!;
    const activeFormAsset = useAppSelector(selectActiveFormAsset);

    useEffect(() => {
        accordionIds.forEach(accordion => {
            dispatch(setAccordionState({
                accordionId: accordion.id,
                state: {
                    expandedItems: accordion.expandedItems
                }
            }));
        });

        accordionPreferencesIds.forEach(accordion=>{
            dispatch(setAccordionPreferencesState({
                accordionId: accordion.id,
                state: {
                    expandedItems: accordion.expandedItems
                }
            }));
        })
    }, []);

    if (!clientAssets || !investorGroup || !legalEntities) {
        return <LoadingIndicator/>;
    }

    return <AssetSummaryReportView
        isDetailed={isDetailed}
        activeFormAsset={activeFormAsset!}
        clientAssets={clientAssets!}
        displayName={displayName}
        inEstateAssetsTotals={inEstateAssetsTotals}
        investorGroup={investorGroup!}
        profileId={profileId}
        memberGroup={memberGroup}
        legalEntities={legalEntities}
    />
}

export default AssetSummaryReport;
