import {GoalModelType} from "../models/GoalModelType";
import {formatCurrency} from "../../utils/format";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {deleteNonLifestyleGoal, selectGoalModel} from "../controller/GoalsModelSlice";
import {Accordion, AccordionItem, DropdownItem, Icon, InfoPopover, TableActionMenu} from "../../components";
import React, {useMemo, useState} from "react";
import GoalUtils from "../Summary/GoalUtils";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import {Table} from "xps-react";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {goalsApiClient} from "../GoalsApiClient";
import {useHistory} from "react-router-dom";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import NonLifestyleGoalListHeader from "../Summary/NonLifestyleGoalListHeader";
import {GoalBarChartKeyColors} from "../BarChartSidebar/GoalBarChartKeyColors";
import DeleteGoalModal from "./DeleteGoalModal";
import {SplitLifestyleInfoPopover} from "../Prioritization/SplitLifestyleInfoPopover";


export function getGoalsForDisplay(type: GoalType, goalModel: GoalModelType) {
    switch (type) {
        case GoalType.DISCRETIONARY:
            return goalModel.discretionaryGoals
        case GoalType.PHILANTHROPIC:
            return goalModel.philanthropicGoals
        default:
            return []
    }
}

export const SUPPORTED_GOAL_TYPES = [
    GoalType.DISCRETIONARY,
    GoalType.PHILANTHROPIC
]

export interface NonLifestyleGoalListProps {
    type: GoalType,
    label: string,
    displayHeader: boolean
}

export interface NonLifestyleGoalRow {
    name: string,
    annualFlow: number,
    yearsOfFlow: number,
    yearsUntilFlow: number,
    presentValue: number,
    isFundedByNonInvestableAssets: boolean,
    savedGoal: NonLifestyleGoalType
}

export function makeNonLifestyleGoalListTableRowData(
    goalsForDisplay: NonLifestyleGoalType[],
    investorGroup: InvestorGroupType
): NonLifestyleGoalRow[] {
    const rowData: NonLifestyleGoalRow[] = goalsForDisplay.map<NonLifestyleGoalRow>(
        (goal: NonLifestyleGoalType) => {
            const {yearsOfFlow, yearsUntilFlow} = GoalUtils.getYearsOfFlowAndYearsUntilFlow(goal, investorGroup)

            return {
                name: goal.userInputs.description,
                annualFlow: goal.userInputs.annualFlow,
                presentValue: goal.calculatedFields.presentValue,
                isFundedByNonInvestableAssets: goal.userInputs.isFundedByNonInvestableAssets,
                yearsOfFlow,
                yearsUntilFlow,
                savedGoal: goal
            }
        }
    );
    return rowData;
}

export function getNameCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div className="display-flex flex-column justify-content-center height-100p">
                                    <span className="table-label-15px">
                                        {original.name}
                                        {original.isFundedByNonInvestableAssets &&
                                            <Icon name='link' size="large"
                                                  color="#000000"/>}
                                        <SplitLifestyleInfoPopover show={original.name === 'Lifestyle Spending'} direction="top"/>
                                    </span>
            </div>
        );
    };
}

export function getAnnualFlowCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{formatCurrency(original.annualFlow)}</span>
            </div>
        );
    };
}

export function getYearsUntilFlowCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{original.yearsUntilFlow}</span>
            </div>
        );
    };
}

export function getYearsOfFlowCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{original.yearsOfFlow}</span>
            </div>
        );
    };
}

export function gePresentValueCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{formatCurrency(original.presentValue)}</span>
            </div>
        );
    };
}

function NonLifestyleGoalsTable({
                                    type,
                                    goalsForDisplay,
                                    investorGroup,
                                    proposal
                                }: {
    type: GoalType,
    goalsForDisplay: NonLifestyleGoalType[],
    investorGroup: InvestorGroupType,
    proposal: ProfileResponse
}) {
    const history = useHistory()

    const memoizedTable = useMemo(() => <Table
        resizable={false}
        showPagination={false}
        sortable={false}
        defaultPageSize={9999}
        data={
            makeNonLifestyleGoalListTableRowData(goalsForDisplay, investorGroup)
        }
        columns={
            [
                {
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    accessor: 'name',
                    Cell: getNameCell(),
                    Header: 'Name',
                },
                {
                    accessor: 'annual-flow',
                    Cell: getAnnualFlowCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Annual Flow',
                    maxWidth: 128
                },
                {
                    accessor: 'years-until-flow',
                    Cell: getYearsUntilFlowCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Years Until Flow',
                    maxWidth: 128
                },
                {
                    accessor: 'years-of-flow',
                    Cell: getYearsOfFlowCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Years of Flow',
                    maxWidth: 128
                },
                {
                    accessor: 'present-value',
                    Cell: gePresentValueCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Present Value',
                    maxWidth: 128
                },
                {
                    accessor: 'rowActionMenu',
                    className: "overflow-visible",
                    minWidth: 32,
                    maxWidth: 32,
                    Cell: ({original}: { original: NonLifestyleGoalRow }) => {
                        const dispatch = useAppDispatch();
                        const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
                        const handleClickOnDeleteGoal = async () => {
                            const response = await goalsApiClient.deleteNonLifestyleGoal(original.savedGoal.id!);
                            if (response.status === 204) {
                                dispatch(deleteNonLifestyleGoal({goal: original.savedGoal}))
                            }
                        }
                        let editURL: string;
                        switch (type) {
                            case GoalType.DISCRETIONARY:
                                editURL = "EditDiscretionaryGoal";
                                break;
                            case GoalType.PHILANTHROPIC:
                                editURL = "EditPhilanthropicGoal";
                                break;
                        }

                        return (
                            <span className="table-action-menu">
                                <TableActionMenu className="paddingright-0" panelWidth={240}>
                                    <DropdownItem
                                        itemText="Edit Goal"
                                        value=""
                                        key="edit-goal"
                                        onClick={() => history.push(
                                            `/Profile/${proposal.id}/ClientProfile/Goals/${editURL}`,
                                            {savedGoal: original.savedGoal}
                                        )}
                                    />
                                    <DropdownItem
                                        id={`delete${type}Goal`}
                                        onClick={() => setShowDeleteGoalModal(true)}
                                        itemText={`Delete Goal`}
                                        value={''}
                                        key="delete-goal"
                                    >
                                        <Icon name="delete" className="goals-table-delete-icon"/>
                                    </DropdownItem>
                                </TableActionMenu>
                                 <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                                                  onCancel={() => setShowDeleteGoalModal(false)}
                                                  onDelete={handleClickOnDeleteGoal}
                                                  goalType={type}
                                                  description={original.name}
                                                  cancelButtonText={"CANCEL"}/>
                            </span>
                        )
                    }
                }
            ]
        }
    />, [goalsForDisplay, investorGroup]);
    return <div data-testid={`${type}-goal-table`}>{memoizedTable}</div>;
}

const NonLifestyleGoalList = ({type, label, displayHeader}: NonLifestyleGoalListProps) => {
    const goalsForDisplay: NonLifestyleGoalType[] = getGoalsForDisplay(type, useAppSelector(selectGoalModel))
    const {investorGroup, proposal} = useAppSelector(selectGoalModel)
    if (!SUPPORTED_GOAL_TYPES.includes(type)) {
        throw new Error(`This component does not support goals of type ${type}`)
    }

    if (goalsForDisplay.length === 0) {
        return null
    }
    let color;
    switch (type) {
        case GoalType.DISCRETIONARY:
            color = GoalBarChartKeyColors.DISCRETIONARY
            break;
        case GoalType.PHILANTHROPIC:
            color = GoalBarChartKeyColors.PHILANTHROPIC
            break;
        default:
            break;
    }
    return (
        <section data-testid={`${type}-goal-list`}>
            <div className="accordion-tables">
                <Accordion
                    accordionId={`${type}GoalListAccordion`}
                    allowMultipleExpanded
                    allowZeroExpanded
                >
                    {displayHeader && <NonLifestyleGoalListHeader/>}
                    <AccordionItem
                        uuid={`NonLifestyleGoalList-${type}`}
                        accentColor={color}
                        primaryText={label}
                        rightAlignedContent={
                            <div className="display-flex align-items-center">
                                <div>
                                    <span className="font-lg paddingright-xl">
                                        {formatCurrency(GoalUtils.getNonLifestyleGoalCumulativeTotalPresentValue(goalsForDisplay))}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <NonLifestyleGoalsTable type={type} goalsForDisplay={goalsForDisplay}
                                                investorGroup={investorGroup} proposal={proposal}/>
                    </AccordionItem>
                </Accordion>
            </div>
        </section>

    )
}

export default NonLifestyleGoalList;


