import {AssetSubclassSummary} from "../../../ClientManagement/AssetAllocation/AssetHoldings";
import {riskAssetClasses, riskControlClasses} from "../../../ClientManagement/AssetAllocation/AssetClasses";
import {AssetSubclassesSummary} from "../../../ClientManagement/AssetAllocation/AssetSubclassesSummary";
import {CurrentVsProposedReportPageData} from "./CurrentVsProposedReportView";
import {CurrentAllocationResponse} from "../../../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import {ProposedAllocationResponse} from "../../../ClientManagement/AssetAllocation/ProposedAllocationResponse";

function splitRemainingRAAndRCASubclasses(noOfRowsForRiskAssetsLastNode: number,
                                          riskAssetsArray: Array<AssetSubclassSummary[]>,
                                          calculatedData: CurrentVsProposedReportPageData[],
                                          riskControl: AssetSubclassSummary[],
                                          totalRowsPerPage: number,
                                          riskControlAssetsArray: Array<AssetSubclassSummary[]>) {
    if (noOfRowsForRiskAssetsLastNode > 3) {
        riskAssetsArray.forEach((riskAssetsSummary, index) => {
            if (index === 0) {
                calculatedData.push({
                    riskAssets: riskAssetsSummary,
                    riskControl: [],
                    continueLabel: ""
                })
            } else {
                calculatedData.push({
                    riskAssets: riskAssetsSummary,
                    riskControl: [],
                    continueLabel: "RA"
                })
            }
        })
        for (let i = 0; i < riskControl.length; i += totalRowsPerPage) {
            calculatedData.push({
                riskAssets: [],
                riskControl: riskControl.slice(i, i + totalRowsPerPage),
                continueLabel: ""
            })
        }
    } else {
        riskAssetsArray.forEach((riskAsset, index) => {
            if (index == riskAssetsArray.length - 1) {
                let numberOfRowsFitsIntoRemainingPage = 6 - noOfRowsForRiskAssetsLastNode;
                for (let i = 0; i < riskControl.length; i += numberOfRowsFitsIntoRemainingPage) {
                    riskControlAssetsArray.push(riskControl.slice(i, i + numberOfRowsFitsIntoRemainingPage));
                }
                calculatedData.push({
                    riskAssets: riskAsset,
                    riskControl: riskControlAssetsArray[0],
                    continueLabel: "RA"
                })
                calculatedData.push({
                    riskAssets: [],
                    riskControl: riskControlAssetsArray[1],
                    continueLabel: "RCA"
                })
            } else {
                calculatedData.push({
                    riskAssets: riskAssetsArray[index],
                    riskControl: [],
                    continueLabel: ""
                });
            }
        });
    }
}

export const splitCurrentVsProposed = async (currentVsProposedPage: CurrentVsProposedReportPageData,
                                             currentAllocation: CurrentAllocationResponse,
                                             proposedAllocation: ProposedAllocationResponse): Promise<CurrentVsProposedReportPageData[]> => {
    const calculatedData: CurrentVsProposedReportPageData[] = [];

    if (currentVsProposedPage) {
        let pageCategory = getPageCategory(currentAllocation, proposedAllocation);

        let riskAssets = currentVsProposedPage.riskAssets;
        let riskControl = currentVsProposedPage.riskControl;

        if (pageCategory == PageSplitCategory.RARCA_MULTI_PAGE) {
            calculatedData.push({
                riskAssets,
                riskControl: [],
                continueLabel: ""
            });
            calculatedData.push({
                riskAssets: [],
                riskControl,
                continueLabel: ""
            })
        } else if (pageCategory == PageSplitCategory.RA_MULTI_PAGE_RCA_MULTI_PAGE) {

            let totalRowsPerPage = 10;
            let riskAssetsArray: Array<AssetSubclassSummary[]> = [];
            let riskControlAssetsArray: Array<AssetSubclassSummary[]> = [];
            for (let i = 0; i < riskAssets.length; i += totalRowsPerPage) {
                riskAssetsArray.push(riskAssets.slice(i, i + totalRowsPerPage));
            }

            let noOfRowsForRiskAssetsLastNode = riskAssetsArray[riskAssetsArray.length - 1].length;
            let totalNoOfRowsLeft = noOfRowsForRiskAssetsLastNode + riskControl.length;

            if (totalNoOfRowsLeft > 6) {
                splitRemainingRAAndRCASubclasses(noOfRowsForRiskAssetsLastNode, riskAssetsArray, calculatedData, riskControl, totalRowsPerPage, riskControlAssetsArray);

            } else {
                riskAssetsArray.forEach((riskAssetsSummary, index) => {
                    if (index == riskAssetsArray.length - 1) {
                        calculatedData.push({
                            riskAssets: riskAssetsSummary,
                            riskControl,
                            continueLabel: "RA"
                        })
                    } else {
                        calculatedData.push({
                            riskAssets: riskAssetsSummary,
                            riskControl: [],
                            continueLabel: ""
                        });
                    }
                });
            }
        } else {
            calculatedData.push({
                riskAssets,
                riskControl,
                continueLabel: ""
            })
        }
    }
    return calculatedData;
};

const getPageCategory = (currentAllocation: CurrentAllocationResponse, proposedAllocation: ProposedAllocationResponse): PageSplitCategory => {

    let pageCategory = PageSplitCategory.RARCA_SINGLE_PAGE;

    const riskAssetClassesLength = assetClassesLength(currentAllocation.riskAssets, proposedAllocation.riskAssets, riskAssetClasses);
    const riskControlClassesLength = assetClassesLength(currentAllocation.riskControl, proposedAllocation.riskControlAssets, riskControlClasses);

    if (riskAssetClassesLength + riskControlClassesLength > 6) {
        pageCategory = PageSplitCategory.RARCA_MULTI_PAGE;

        if (riskAssetClassesLength > 10) {
            pageCategory = PageSplitCategory.RA_MULTI_PAGE_RCA_MULTI_PAGE;
        }
    }
    return pageCategory;
}

const assetClassesLength = (currentAllocationSummaries: AssetSubclassesSummary[],
                            proposedAllocationSummaries: AssetSubclassesSummary[],
                            assetClasses: string[]): number => {
    let length = 0;
    assetClasses.forEach(subclass => {
        const currentSubclassSummary = currentAllocationSummaries.find(item => (item.name === subclass) && (item.totalInvestableAmount != 0));
        const proposedSubclassSummary = proposedAllocationSummaries.find(item => (item.name === subclass) && (item.totalInvestableAmount != 0));
        if (currentSubclassSummary || proposedSubclassSummary) {
            length = length + 1;
        }
    })
    return length;
}

enum PageSplitCategory {
    RARCA_SINGLE_PAGE = "RaRcaSinglePage",
    RARCA_MULTI_PAGE = "RaRcaMultiPage",
    RA_MULTI_PAGE_RCA_MULTI_PAGE = "RaMultiPageRcaMultiPage"
}