import React, {ReactNode} from "react"
import {Button, Modal} from 'xps-react';

export interface ModalWrapperProps {
    id: string;
    headerText: string | ReactNode;
    isOpen?: boolean;
    size?: string;
    alertIcon?: string,
    alertIconType?: string,
    showCloseButton?: boolean;
    buttons?: ModalButton[];
    onRequestClose?: () => void;
}

export interface ModalButton {
    text: string;
    primary?: boolean;
    onClick?: () => void;
    destructive?: boolean;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
                                                       id,
                                                       headerText,
                                                       isOpen,
                                                       size = "small",
                                                       alertIcon,
                                                       alertIconType,
                                                       showCloseButton = false,
                                                       buttons = [],
                                                       children,
                                                       onRequestClose
                                                   }) => {
    return (
        <Modal
            alertIcon={alertIcon}
            alertIconType={alertIconType}
            header={headerText}
            label="modal-label"
            id={id}
            size={size}
            isOpen={isOpen}
            showCloseButton={showCloseButton}
            footer={
                <>
                    {buttons.map((button, index) => (
                        <Button
                            className={button.destructive ? 'modal-wrapper-container button--destructive btn-skin--primary marginright-12' : 'marginright-12'}
                            destructive={button.destructive}
                            key={index}
                            kind={button.primary ? 'primary' : 'secondary'}
                            onClick={button.onClick}
                            size="medium"
                        >
                            {button.text}
                        </Button>
                    ))}
                </>
            }
            onRequestClose={onRequestClose}
        >
            {children}
        </Modal>
    );
}

export default ModalWrapper;
