import {CurrencyInput, UnderlinedHeader} from "../../components";
import {formatCurrency} from "../../utils/format";
import React, {ChangeEvent, useState} from "react";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";

type FamilyAssetsDetailsProps = {
    memberSelected: MemberType,
    familyAssets: FamilyAssets,
    appliedAssets: number,
    onChangeTotalAssets: (value: number) => void,
    onBlur: (familyAssets: FamilyAssets) => void
}
const FamilyAssetsDetails = ({
                                 memberSelected,
                                 familyAssets,
                                 appliedAssets, // total of family assets from all family goals
                                 onChangeTotalAssets,
                                 onBlur
                             }: FamilyAssetsDetailsProps) => {
    const [totalAssets, setTotalAssets] = useState(familyAssets.totalAssets);

    const handleChangeValue = (event: ChangeEvent<HTMLInputElement>, value: string | number) => {
        setTotalAssets(value as number);
        onChangeTotalAssets(value as number);
    }

    const availableAssets = familyAssets.totalAssets - appliedAssets;

    return (
        <>
            <section className="family-asset_details_header">
                <UnderlinedHeader primaryText="Family Asset Details"/>
            </section>
            <div className="family-asset-details-table">
                <label>{memberSelected.firstName + "'s Total Assets"}</label>
                <label>{"Applied to " + memberSelected.firstName + "'s Goals"}</label>
                <label>{"Available Assets"}</label>
                <CurrencyInput
                    value={totalAssets}
                    onChangeValue={handleChangeValue}
                    onBlur={() => onBlur({
                        ...familyAssets!,
                        totalAssets: totalAssets
                    })}
                />
                <span>{formatCurrency(appliedAssets)}</span>
                <span>{formatCurrency(availableAssets)}</span>
            </div>
        </>
    )
}

export default FamilyAssetsDetails;