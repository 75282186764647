import {camelize} from "../../utils/stringUtils";
import {Label} from "../Label";
import React from "react";
import {clampStringsWithOptions} from "../../utils/Clamp";
import NumberInput from "./NumberInput";
import classNames from "classnames";

type PercentInputProps = {
    defaultValue: string,
    value: string,
    label: string,
    className?: string,
    minValue?: number,
    maxValue?: number,
    maxLength?: number,
    hideLabel?: boolean,
    hideInlineLabel?: boolean,
    onChange: (newValue: string) => void,
    onBlur?: (newValue: string) => void,
    formatOptions?: Intl.NumberFormatOptions,
    size?: 'small' | 'medium' | 'large',
    textAlign?: 'left' | 'right',
    disableOnChangeCallWhenHandlingFocusEvent?: boolean
}

function PercentInput({
                          className,
                          defaultValue,
                          hideLabel,
                          label,
                          hideInlineLabel,
                          onBlur,
                          onChange,
                          value,
                          maxLength = 5,
                          maxValue = 100,
                          minValue = 0,
                          size,
                          formatOptions={
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: 'decimal',
                          },
                          disableOnChangeCallWhenHandlingFocusEvent,
                          textAlign = 'left'
                      }: PercentInputProps) {
    const camelLabel = camelize(label);
    const labelId = `${camelLabel}-label`;
    return <>
        {hideLabel ? <React.Fragment/> : <Label labelId={labelId} label={label}/>}
        <div className="percentInput">
            <NumberInput
                allowDecimals={true}
                aria-label={label}
                aria-labelledby={labelId}
                inlineLabel={hideInlineLabel ? undefined : "%"}
                inlineLabelAlign="right"
                className={classNames('percent-field', className)}
                id={`${camelLabel}Input`}
                minValue={minValue}
                maxValue={maxValue}
                maxLength={maxLength}
                name={camelLabel}
                textAlign={textAlign}
                formatOptions={formatOptions}
                value={value}
                size={size}
                onChangeValue={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let newValue = event.target.value.replace(/^0+(\d)/, '$1');
                    if (newValue === '.') {newValue = '0.'}
                    onChange(newValue);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    const currentValue = e.target.value;
                    const clampedValue = clampStringsWithOptions(currentValue, minValue.toString(), maxValue.toString(), {
                        defaultValue: defaultValue
                    });
                    !disableOnChangeCallWhenHandlingFocusEvent && onChange(clampedValue);
                    onBlur && onBlur(clampedValue);
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    const currentValue = e.target.value;
                    if (!parseFloat(currentValue)  &&  !disableOnChangeCallWhenHandlingFocusEvent) {
                        onChange('');
                    }
                }}
            />
        </div>
    </>;
}

export default PercentInput;
