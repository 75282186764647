import React, {ChangeEvent, FocusEvent, ReactNode, useCallback, useState} from "react";
import Input from "./Input";
import {NO_OP} from "../../constants/common";
import {clamp} from "../../utils/Clamp";
import {HtmlElementProps} from "../types";

export const defaultFormatOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    currency: 'USD',
    currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};

export type NumberInputProps = HtmlElementProps & {
    value: number | string | null,
    onChangeValue?: (event: ChangeEvent<HTMLInputElement>, value: number | string) => void,
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void,
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void,
    allowDecimals?: boolean,
    allowNegativeValues?: boolean,
    emptyValue?: string,
    maxLength?: number,
    maxValue?: number
    minValue?: number,
    locale?: string,
    formatOptions?: Intl.NumberFormatOptions,
    borderless?: boolean,
    description?: string,
    disabled?: boolean,
    readOnly?: boolean,
    error?: string,
    icon?: string,
    iconClassName?: string,
    id?: string,
    inlineLabel?: string | ReactNode,
    inlineLabelAlign?: 'left' | 'right',
    label?: string,
    lblRequired?: boolean,
    name?: string,
    optional?: boolean,
    placeholder?: string,
    removeMarginTop?: boolean,
    size?: 'small' | 'medium' | 'large',
    textAlign?: 'left' | 'right'
}

export default function NumberInput({
                                        value,
                                        maxLength,
                                        onFocus = NO_OP,
                                        onBlur = NO_OP,
                                        onChangeValue = NO_OP,
                                        allowDecimals = false,
                                        allowNegativeValues = false,
                                        locale = 'en-US',
                                        emptyValue = '',
                                        maxValue = Number.MAX_SAFE_INTEGER,
                                        minValue = 0,
                                        formatOptions = defaultFormatOptions,
                                        className,
                                        error,
                                        textAlign = 'left',
                                        ...rest
                                    }: NumberInputProps) {
    if (allowNegativeValues && minValue === 0) {
        minValue = Number.MIN_SAFE_INTEGER;
    }

    const [focused, setFocused] = useState(false);
    const formatting = {...defaultFormatOptions, ...formatOptions};
    const sanitize = (rawValue: string) => {
        if (minValue < 0) {
            return rawValue
                .replace(/[^0-9.\\-]/g, '')
                .replace(/(\S)-/g, '$1');
        }
        return rawValue.replace(/[^0-9.]/g, '');
    };
    const isValid = (rawValue: number) => !Number.isNaN(rawValue) && Number.isFinite(rawValue);
    const format = useCallback((currentValue: number | string) => {
        const number = (typeof currentValue === 'string') ? parseFloat(currentValue) : currentValue;
        if (isValid(number)) {
            const normalized = (formatting.style === 'percent') ? number / 100 : number;
            const clamped = clamp(normalized, minValue, maxValue);
            return clamped.toLocaleString(locale, formatting);
        }
        return emptyValue;
    }, [emptyValue, formatting, locale, maxValue, minValue]);

    const parseDecimalInput = (input: string) => {
        const decimalIndex = input.indexOf('.');
        if (input.indexOf('.', decimalIndex + 1) !== -1) {
            return input.slice(0, -1);
        }
        const isTooLong = (formatting.maximumFractionDigits !== undefined) &&
            input.length > (decimalIndex + formatting.maximumFractionDigits + 1);
        if (decimalIndex >= 0 && isTooLong) {
            return input.slice(0, -1);
        }
        return input;
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        onFocus(event);
        setFocused(true);
    };
    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        const {target} = event;
        const sanitizedValue = sanitize(target.value);
        const parsedValue = allowDecimals ? parseDecimalInput(sanitizedValue) : parseFloat(sanitizedValue);
        target.value = format(String(parsedValue));
        onBlur(event);
        setFocused(false);
    };
    const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        const {target} = event;
        let sanitizedValue = sanitize(target.value);
        if (sanitizedValue === '') {
            sanitizedValue = emptyValue;
        }
        const parsedValue = parseValue(sanitizedValue);
        target.value = String(parsedValue);
        onChangeValue(event, parsedValue);
    };

    const parseValue = (sanitizedValue: string) => {
        if (sanitizedValue === '-') {
            return sanitizedValue;
        }

        return allowDecimals ? parseDecimalInput(sanitizedValue) : parseFloat(sanitizedValue);
    };

    const getControlledValue = () => {
        if (value === null) {
            return emptyValue;
        }
        if (focused) {
            const numberValue = (typeof value === 'string') ? parseFloat(value) : value;
            return value === '-' || isValid(numberValue) ? value : emptyValue;
        } else {
            return format(value);
        }
    };

    return (
        <Input
            {...rest}
            maxLength={(maxLength && allowDecimals) ? maxLength + 1 : maxLength}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChangeValue}
            value={getControlledValue()}
            className={className}
            textAlign={textAlign}
            error={error}
        />
    );
}
