import React, {useState} from "react";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Button, Dropdown, DropdownItem, Icon} from "../../components";
import {
    resetMeeting,
    selectActiveMeeting,
    selectAudienceMembers,
    selectAudienceSize,
    selectMeetingControlRequest,
    selectParticipatingInMeeting,
    selectShowMeetingControls,
    setActiveMeeting,
    setMeetingControlRequest,
    setMeetingModalVisibility
} from "./meetingSlice";
import {meetingApiClient} from "./MeetingApiClient";
import {Meeting, MeetingParticipation, MeetingStatus, MeetingType} from "./Meeting";
import {useHistory} from "react-router-dom";
import {ProfileResponse} from "../models/ProfileResponse";
import {selectApprovedProfile} from "../ClientProfile/approvedProfileSlice";
import useMeetingUtils from "./useMeetingUtils";
import {msalUtils} from "../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {NO_OP} from "../../constants/common";
import {darkTheme, FluentThemeProvider, ParticipantList, ParticipantListParticipant} from '@azure/communication-react';
import {SideDrawer} from 'xps-react';
import {useAppInsights} from "src/AppInsights";
import {useRelayContext} from "./Relay/types/RelayContext";
import ParticipantAudioVideo from "./ParticipantAudioVideo";
import {CommunicationsConnectionState, useCommunicationsContext} from "./CommunicationsContext";

export const MeetingControls: React.FC = () => {
    const showMeetingControls = useAppSelector(selectShowMeetingControls);
    return (showMeetingControls
            ? <div className="meeting-controls"><ControlMenu/></div>
            : null
    )
}

const ControlMenu: React.FC = () => {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
    const communicationsContext = useCommunicationsContext();

    return (
        <div>
            <ControlMenuHeader onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed}/>
            {
                !isCollapsed
                && communicationsContext.connectionState === CommunicationsConnectionState.CONNECTED
                && <ParticipantAudioVideo/>
            }
            <ControlMenuActions isCollapsed={isCollapsed}/>
        </div>
    )
}

type ControlMenuHeaderDisplayProps = {
    label: string
    onClick: () => void
}

const ControlMenuHeaderDisplay: React.FC<ControlMenuHeaderDisplayProps> = ({label, onClick}) => (
    <div className="flex-grow-1">
        <span onClick={onClick} className="control-menu-item__label">{label}</span>
    </div>
)

type ControlMenuHeaderProps = {
    onClick: () => void
    isCollapsed: boolean;
}

const ControlMenuHeader: React.FC<ControlMenuHeaderProps> = (props: ControlMenuHeaderProps) => {
    const {status} = useAppSelector(selectActiveMeeting)!;
    const dispatch = useAppDispatch();

    let HeaderComponent;

    switch (status) {
        case MeetingStatus.STARTED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting In Progress"}/>
            break;
        case MeetingStatus.PAUSED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Paused"}/>
            break;
        case MeetingStatus.STOPPED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Stopped"}/>
            break;
        case MeetingStatus.ENDED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Ended"}/>
            break;
        default:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Not Started"}/>
    }

    return <>
        <div role="heading" className={
            classNames('control-menu-heading',
                {"meeting-not-started": status === MeetingStatus.CREATED},
                {"meeting-in-progress": status === MeetingStatus.STARTED},
                {"meeting-paused": status === MeetingStatus.PAUSED},
                {"meeting-stopped": status === MeetingStatus.STOPPED},
                {"meeting-not-started": status === MeetingStatus.ENDED},
            )}>
            <div className="menu-chevron-container" onClick={props.onClick}>
                <Icon className={
                    classNames('control-menu-heading__chevron',
                        {"meeting-in-progress": status === MeetingStatus.STARTED}
                    )} data-testid="chevron" name={props.isCollapsed ? 'chevron_up' : 'chevron_down'} size="large"/>
            </div>
            <div className="header-dropdown">
                {HeaderComponent}
                <Dropdown
                    id="meetingControlActionMenu"
                    name="dropdown-grouped"
                    className="meeting-control-action-menu"
                    aria-label="meetingControlActionMenu"
                    alignRight={true}
                    buttonIcon="only"
                    buttonSize="small"
                    defaultPageSize={15}
                    disabled={false}
                    dropUp={false}
                    dropdownKind="menu"
                    buttonKind="borderless"
                    dynamicDropAlign={false}
                    dynamicDropDirection={true}
                    iconNameClose="menu_dots"
                    iconNameOpen="menu_dots"
                    labelWidth="100%"
                    nativeOnMobile={false}
                    open={false}
                    panelHeight="auto"
                    panelWidth={135}
                    required={false}
                    searchable={false}
                    size="small"
                    value={null}
                    virtualScroll={false}
                >
                    <DropdownItem
                        className="meeting-info-dropdown-item"
                        onClick={() => {
                            dispatch(setMeetingModalVisibility({meetingInfo: true}));
                        }}
                    >
                        <span>Meeting Info</span>
                        <Icon name="arrow_up_right"/>
                    </DropdownItem>
                </Dropdown>
            </div>
        </div>
    </>;
};

interface ControlMenuActionsProps {
    isCollapsed: boolean;
}

const ControlMenuActions: React.FC<ControlMenuActionsProps> = ({isCollapsed}) => {
    const {isCurrentUserPresenting} = useMeetingUtils();
    const audienceSize = useAppSelector(selectAudienceSize);
    const participants = useAppSelector(selectAudienceMembers);
    const {status} = useAppSelector(selectActiveMeeting)!;
    const meeting = useAppSelector(selectActiveMeeting) as Meeting;
    const [showParticipantList, setShowParticipantList] = useState(false);

    const getParticipants = (): ParticipantListParticipant[] => {
        return participants?.map(
            (member) => {
                return {
                    userId: member.userId,
                    displayName: member.userName,
                    isRemovable: false,
                }
            }
        ) || []
    }

    return (
        <>
            {!isCollapsed &&
                <div className="control-menu-actions">
                    <div>
                        <span className="meeting-presenter-label">Presenter:</span>
                    </div>
                    <div>
                    <span
                        className="meeting-presenter">{meeting.presenterName} ({meeting.presenter?.toUpperCase()})</span>
                    </div>
                    {(status !== MeetingStatus.ENDED) &&
                        <div>
                            <MenuActionButton label={`Participants (${audienceSize})`}
                                              icon={showParticipantList ? "cancel_outline" : "arrow_up_right"}
                                              color="primary"
                                              onClick={() => setShowParticipantList(true)}></MenuActionButton>
                        </div>}
                    {isCurrentUserPresenting ? <PresenterControlMenuActions/> : <ParticipantControlMenuActions/>}
                </div>
            }
            <SideDrawer
                id="participants-side-drawer"
                aria-label="View Participants"
                className="meeting-participants"
                direction="right"
                size="small"
                noPadding={false}
                isOpen={showParticipantList}>
                <div className="meeting-participant-list">
                    <h3>Participants</h3>
                    <Button
                        icon="only"
                        iconName="close"
                        kind="borderless"
                        size="large"
                        onClick={() => setShowParticipantList(false)}
                    ></Button>
                </div>
                <FluentThemeProvider fluentTheme={{...darkTheme, semanticColors: {bodyBackground: '#000000'}}}>
                    <ParticipantList participants={getParticipants()}/>
                </FluentThemeProvider>
            </SideDrawer>
        </>
    );
};

type MeetingEventData = {
    name: string;
    action: string;
    data: string;
}

const PresenterControlMenuActions: React.FC = () => {
    const meeting = useAppSelector(selectActiveMeeting) as Meeting;
    const {id} = useAppSelector(selectApprovedProfile) as ProfileResponse;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const appInsights = useAppInsights();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;

    const triggerMeetingEvent = (data: MeetingEventData) => {
        if (meeting.type == MeetingType.CLIENT_MEETING) {
            appInsights.trackEvent({
                name: data.name,
                properties: {
                    screen: window.location.href.split("/").pop(),
                    action: data.action,
                    meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                    meetingId: meeting.onlineMeetingId,
                    data: data.data
                }
            })
        }
    }

    let transitionMeetingStatusButton;
    switch (meeting.status) {
        case MeetingStatus.STARTED:
            transitionMeetingStatusButton =
                <MenuActionButton label="Pause Sharing" icon="pause_circle_outline" color="primary" onClick={() => {
                    meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.PAUSED})
                        .then((updateMeeting) => dispatch(setActiveMeeting(updateMeeting)))
                        .catch(error => console.error('Could not update meeting', error.message))
                }}/>
            break;
        case MeetingStatus.PAUSED:
        case MeetingStatus.STOPPED:
            transitionMeetingStatusButton =
                <MenuActionButton label="Resume Sharing" icon="play_circle_outline" color="primary" onClick={() => {
                    meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.STARTED})
                        .then((updateMeeting) => dispatch(setActiveMeeting(updateMeeting)))
                        .catch(error => console.error('Could not update meeting', error.message))
                }}/>
            break;
        default:
            transitionMeetingStatusButton =
                <MenuActionButton label="Start Meeting" icon="play_circle_outline" color="primary" onClick={() => {
                    meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.STARTED})
                        .then((updateMeeting) => {
                            triggerMeetingEvent({
                                name: "StartMeeting",
                                action: "Start meeting button click",
                                data: "Start meeting button clicked"
                            });
                            dispatch(setActiveMeeting(updateMeeting))
                        }).catch(error => console.error('Could not update meeting', error.message))
                }}/>
            break;
    }

    const handleEndMeeting = () => {
        const updatedMeeting = {...meeting, status: MeetingStatus.ENDED};
        meetingApiClient.updateMeeting(updatedMeeting)
            .then(() => {
                triggerMeetingEvent({
                    name: "EndMeeting",
                    action: "End meeting button click",
                    data: "End meeting button clicked"
                });
                dispatch(setActiveMeeting(updatedMeeting));
                dispatch(resetMeeting());
                history.push(`/Profile/${id}`);
            }).catch(error => console.error('Could not update meeting', error.message))
    };

    return <>
        {transitionMeetingStatusButton}
        {meeting.status === MeetingStatus.STARTED || meeting.status === MeetingStatus.PAUSED ?
            <MenuActionButton label="Stop Meeting" icon="stop_circle_outline" color="alternate" onClick={() => {
                meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.STOPPED})
                    .then((updateMeeting) => dispatch(setActiveMeeting(updateMeeting)))
                    .catch(error => console.error('Could not update meeting', error.message))
            }}/> : <React.Fragment/>
        }
        {meeting.status === MeetingStatus.CREATED || meeting.status === MeetingStatus.STOPPED ?
            <MenuActionButton label="End Meeting" icon="cancel_outline" color="alternate" onClick={handleEndMeeting}/> :
            <React.Fragment/>
        }
    </>
};

const ParticipantControlMenuActions: React.FC = () => {
    const meeting = useAppSelector(selectActiveMeeting) as Meeting;
    const history = useHistory();
    const msal = useMsal();
    const dispatch = useAppDispatch();
    const currentUser = msalUtils.getLanId(msal);
    const meetingControlRequest = useAppSelector(selectMeetingControlRequest);
    const {sharedObjectMutators} = useRelayContext();

    return <>
        {
            meeting.status === MeetingStatus.STARTED
            && meetingControlRequest?.user.id === currentUser
            && !meetingControlRequest?.response
            && <MenuActionButton label="Requesting..." icon="hand_right" color="primary" onClick={NO_OP}/>
        }

        {meeting.status === MeetingStatus.STARTED
            && meetingControlRequest?.user.id !== currentUser
            && <MenuActionButton label="Request Control" icon="hand_right" color="primary" onClick={() => {
                const controlRequest = {
                    user: {
                        id: msalUtils.getLanId(msal),
                        name: msalUtils.getAccountName(msal)
                    }
                };
                dispatch(setMeetingControlRequest(controlRequest));
                sharedObjectMutators.requestControl(controlRequest);
            }}/>}

        <MenuActionButton label="Leave Meeting" icon="cancel_outline" color="alternate" onClick={() => {
            history.push(`/`);
        }}/>

    </>;

};

type ControlMenuItemProps = {
    color: 'primary' | 'alternate',
    onClick: () => void,
    label: string;
    icon?: string;
    classList?: string[],
};

const MenuActionButton: React.FC<ControlMenuItemProps> = ({onClick, label, icon, color, classList = []}) => {
    return <Button type="button"
                   kind="secondary"
                   size="medium"
                   icon={icon ? 'right' : 'none'}
                   iconName={icon}
                   onClick={onClick}
                   className={classNames('control-menu-item', `control-menu-item-${color}`, ...classList)}
    >
        <span className="control-menu-item__label">{label}</span>
    </Button>;
}
