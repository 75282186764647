import React, {ChangeEvent} from "react";
import {UnderlinedHeader} from "../../components";
import {Checkbox} from "xps-react";
import {NonLifestyleGoalType} from "../models/GoalType";

type GoalCharacteristicsProps = {
    discretionaryGoal: NonLifestyleGoalType,
    replaceDiscretionaryGoal: (discretionaryGoal: NonLifestyleGoalType) => void;
}

export const GoalCharacteristics = ({
                                        discretionaryGoal,
                                        replaceDiscretionaryGoal
                                    }: GoalCharacteristicsProps) => {

    const handleUpdateAssetPurchase = (isAssetPurchase: boolean) => {
        replaceDiscretionaryGoal({
            ...discretionaryGoal,
            userInputs: {
                ...discretionaryGoal.userInputs,
                isAssetPurchase
            }
        })
    };

    return <section aria-label="Goal Characteristics">
        <UnderlinedHeader
            primaryText='Goal Characteristics'
            className="goal-timeframe-underlined-header"
        />
        <Checkbox
            name={"Asset Purchase"}
            value="Asset Purchase"
            checked={discretionaryGoal.userInputs.isAssetPurchase}
            aria-label={"Asset Purchase"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateAssetPurchase(e.target.checked)}
        />
    </section>
}
