import React, {FunctionComponent, ReactNode, useContext} from 'react';
import classNames from 'classnames';
import {Icon} from '../Icon';
import AccordionContext from './AccordionContext';
import {IconPlaceholder} from "../Icon/IconPlaceholder";

type AccordionItemProps = {
    children?: ReactNode,
    uuid: string,
    buttonClassName?: string,
    className?: string,
    accentColor?: string,
    primaryText?: string,
    rightAlignedContent?: ReactNode,
    HeaderComponent?: FunctionComponent<{ expanded: boolean }>,
    accordionHeight?: string,
    expandable?: boolean,
    isSingleAccordion?: boolean
};

export default function AccordionItem({
                                          children,
                                          uuid,
                                          buttonClassName,
                                          HeaderComponent,
                                          className,
                                          accentColor,
                                          rightAlignedContent,
                                          primaryText,
                                          accordionHeight,
                                          expandable = true,
                                          isSingleAccordion = true,
                                      }: AccordionItemProps) {
    const {expandedItems, toggleItem} = useContext(AccordionContext);
    const toggle = () => {
        if (expandable) {
            toggleItem(uuid);
        }
    }
    const isExpanded = expandedItems.includes(uuid);
    const iconName = isExpanded ? 'chevron_down' : 'chevron_right';
    const icon = expandable ? <Icon name={iconName} size="large"/> : <IconPlaceholder/>;
    const Header = (HeaderComponent)
        ? <HeaderComponent expanded={isExpanded}/>
        : (
            <div className="accented-accordion-item__header">
                <span className="accented-accordion-item__left-content">
                    {icon}
                    <span className="paddingleft-md">
                        <div className="h4 fontweight-normal margin-xs">{primaryText}</div>
                    </span>
                </span>
                {rightAlignedContent && (
                    <span className="accented-accordion-item__right-content">{rightAlignedContent}</span>
                )}
            </div>
        );

    const AccordionItemWithoutAccent = (
        <li
            className={classNames(
                'accordion__item',
                {'accordion__item--expanded': isExpanded, 'accordion__item--collapsed': !isExpanded},
                accentColor ? className : null,
            )}
        >
            <div
                className={classNames('accordion__button', buttonClassName, expandable && 'accordion__button--expandable')}
                aria-pressed={isExpanded ? 'true' : 'false'}
                onClick={toggle}
                role="button"
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        toggle();
                    }
                }}
                tabIndex={0}
            >
                {Header}
            </div>
            <div
                className={`${isSingleAccordion ? 'accordion__content' : 'accordion__content-right-moved'}`}>{children}</div>
        </li>
    );

    if (accentColor) {
        return (
            <div style={{minHeight: accordionHeight}}
                 role="accordionitem"
                 className={classNames('accented-accordion-item', className)}>
                <div aria-label="accordion accent color"
                     className="accented-accordion-item__accent"
                     style={{backgroundColor: accentColor, minHeight: accordionHeight}}/>
                {AccordionItemWithoutAccent}
            </div>
        );
    } else {
        return AccordionItemWithoutAccent;
    }
}
