import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {formatCurrency} from "../../../utils/format";
import {AssetTableDisplay} from "../../../ClientManagement/AssetReliance/TableDisplay/AssetTableDisplay";
import {AssetRelianceStack} from "../../../ClientManagement/models/AssetRelianceResponse";
import {ReviewAssetTableDisplayReport} from "./ReviewAssetTableDisplayReport";
import {hasPositiveExcessAssets} from "../../../ClientManagement/AssetReliance/AssetRelianceUtil";
import {GoalTableDisplayReport} from "./GoalTableDisplayReport";

export class ExcessAssetsTableDisplayReport extends TableDisplay<number> {
    constructor(
        columnCounter: ColumnCounter,
        private readonly assetTableDisplay_: AssetTableDisplay | ReviewAssetTableDisplayReport,
        private readonly goalTableDisplay_: GoalTableDisplayReport,
        private readonly allAssetsStack_: AssetRelianceStack
    ) {
        super(columnCounter, (value) => formatCurrency(value));
    }

    get rows(): TableRow<number>[] {
        return [];
    }

    protected get headerLabel(): string {
        return hasPositiveExcessAssets(this.allAssetsStack_.excessAssets) ? "Excess Assets" : "Asset Shortfall";
    }

    protected get headerValues(): TableCell<number>[] {
        const assetHeaderValues_ = this.assetTableDisplay_.header.values;
        const goalHeaderValues_ = this.goalTableDisplay_.header.values;
        const excessAssetsHeaderValues_ = Array(this.columnCount).fill(0);

        if (assetHeaderValues_.length !== this.columnCount || goalHeaderValues_.length !== this.columnCount) {
            console.error(`Mismatched column counts: Excess Assets = ${this.columnCount}, Assets = ${assetHeaderValues_.length}, Goals = ${goalHeaderValues_.length}`);
            return [];
        }

        for (let i = 0, l = this.columnCount; i < l; i++) {
            excessAssetsHeaderValues_[i] = assetHeaderValues_[i].originalValue - goalHeaderValues_[i].originalValue;
        }
        return excessAssetsHeaderValues_.map(value => {
            const tableCellClassName_ = value < 0 ? 'negative-difference' : undefined;
            return this.createTableCell({value, className: tableCellClassName_});
        });
    }
}