import {AssetsViewContextValue} from "../../common/AssetsViewContext";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {DeleteModalProps} from "../../common/AssetsView";
import {useHistory} from "react-router-dom";
import {StandaloneAccountRow} from "./StandaloneAccountRow";
import {AssetSummaryStandaloneAccountDetails} from "./AssetSummaryStandaloneAccountDetails";
import React from "react";
import {LegalAgreement} from "../../models/InvestmentProgram";
import {AssetSummaryLegalAgreementDetails} from "./AssetSummaryLegalAgreementDetails";
import {LegalAgreementRow} from "./LegalAgreementRow";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {Icon} from "../../../components";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../constants/common";

export type StandaloneAccountOrLegalAgreement = StandaloneAccount | LegalAgreement;

export type AccountTableSectionProps = {
    viewType: AssetsViewContextValue,
    accountsAndLegalAgreements: StandaloneAccountOrLegalAgreement[],
    profileId: string,
    handleDelete: (deleteModalProps: DeleteModalProps) => void,
    hideActionMenu?: boolean,
    isDraggable: boolean,
    captureAccountsOrder?: ({source, destination}: DropResult) => void
}

export function AccountTableSection({
                                        viewType,
                                        accountsAndLegalAgreements,
                                        profileId,
                                        handleDelete,
                                        hideActionMenu = false,
                                        isDraggable = false,
                                        captureAccountsOrder = NO_OP
                                    }:
                                        AccountTableSectionProps) {
    const history = useHistory();

    const isLegalAgreement = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        return (accountOrLegalAgreement as any).legalAgreementNumber;
    };

    const renderLegalAgreementRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const legalAgreement = accountOrLegalAgreement as LegalAgreement;

        return (
            <LegalAgreementRow
                legalAgreement={legalAgreement}
                key={legalAgreement.id}
                onClickEdit={id => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditLegalAgreement/${id}`);
                }}
                onClickViewHoldings={(legalAgreementId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/LegalAgreementHoldings/${legalAgreementId}`);
                }}
                renderLegalAgreementDetails={(agreement) => {
                    return <AssetSummaryLegalAgreementDetails
                        legalAgreement={agreement}/>;
                }}
                gridClassName={"assets-grid-with-actionmenu"}
                actionsDisabled={false}
                hideActionMenu={hideActionMenu}
            />
        );
    };

    const renderStandaloneAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const account = accountOrLegalAgreement as StandaloneAccount;

        return (
            <StandaloneAccountRow
                key={account.id}
                standaloneAccount={account}
                onClickEdit={(accountId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditStandaloneAccount/${accountId}`);
                }}
                onClickDelete={(standaloneAccount: StandaloneAccount) => {
                    handleDelete({
                        showDeleteModal: true,
                        modalTitle: "Asset",
                        assetDescription: standaloneAccount.name,
                        assetType: "standalone account",
                        assetId: standaloneAccount.id,
                    });
                }}
                onClickViewHoldings={(accountId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/StandaloneAccount/${accountId}/Holdings`);
                }}
                actionsDisabled={false}
                hideActionMenu={hideActionMenu}
                gridClassName={"assets-grid-with-actionmenu"}
                renderStandaloneAccountDetails={(standaloneAccount) => {
                    return <AssetSummaryStandaloneAccountDetails account={standaloneAccount}/>;
                }}
            />
        );
    };

    if (isDraggable) {
        return (
            <DragDrop
                className="draggable"
                direction="vertical"
                draggableId="accountDraggableId1"
                droppableId="accountDroppableId1"
                onDragEnd={captureAccountsOrder}
                items={
                    accountsAndLegalAgreements.map((accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) =>
                        <div className="display-flex align-items-right" tabIndex={-1} key={accountOrLegalAgreement.id}
                        data-testid="draggable-account">
                            <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                            {isLegalAgreement(accountOrLegalAgreement)
                                ? renderLegalAgreementRow(accountOrLegalAgreement)
                                : renderStandaloneAccountRow(accountOrLegalAgreement)
                            }
                        </div>)
                }>
            </DragDrop>
        );
    }
    return <>
        {
            accountsAndLegalAgreements.map((accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) =>
                <div className="display-flex align-items-right" tabIndex={-1} key={accountOrLegalAgreement.id} data-testid={"account-row"}>
                    {isLegalAgreement(accountOrLegalAgreement)
                        ? renderLegalAgreementRow(accountOrLegalAgreement)
                        : renderStandaloneAccountRow(accountOrLegalAgreement)
                    }
                </div>)
        }
    </>;
}