import React, {useEffect, useState} from "react";
import {StackedBarChart} from "../../components";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS,
    COLOR_GOALS,
    COLOR_NT_GREY,
    COLOR_NT_GREY_050,
    COLOR_NT_ORANGE_800,
    COLOR_PROJECTED_ASSETS
} from "../../constants/colors";
import ChartStatusLine from "./ChartStatusLine";
import ExcessAssetsVerticalLine from "./ExcessAssetsVerticalLine";
import {
    AssetRelianceAssetType,
    AssetRelianceBarChartData,
    AssetRelianceStack,
    EMPTY_BAR_CHART_DATA,
} from "../models/AssetRelianceResponse";
import {Col, Row} from "react-grid-system";
import {getRefWidth, hasPositiveExcessAssets} from "./AssetRelianceUtil";
import _ from "lodash";
import {truncateCurrency} from "../../utils/format";
import AssetRelianceBarChartLegend, {
    assetRelianceBarChartLegend
} from "../../components/Legend/AssetRelianceBarChartLegend";


type AssetRelianceBarchartProps = {
    allAssetsStack: AssetRelianceStack;
    additionalAssetStacks: AssetRelianceStack[];
    titleCellWidth: number;
    totalGoals: number;
    totalNetAssets: number;
    stackContainerWidth: number;
}

const CHART_WIDTH: number = 96;

const AssetRelianceBarchartContainer: React.FC<AssetRelianceBarchartProps> = ({
                                                                                  allAssetsStack,
                                                                                  additionalAssetStacks = [],
                                                                                  titleCellWidth,
                                                                                  totalGoals,
                                                                                  totalNetAssets,
                                                                                  stackContainerWidth
                                                                              }) => {

    const [charts, setCharts] = useState<AssetRelianceBarChartData[]>(
        getAllBarChartData({stacks: [allAssetsStack], totalGoals, totalNetAssets})
    );
    const [allAssetsColWidth, setAllAssetsColWidth] = useState<number>(0);

    useEffect(() => {
        setCharts(getAllBarChartData({
            stacks: [allAssetsStack, ...additionalAssetStacks],
            totalGoals,
            totalNetAssets
        }))
    }, [allAssetsStack.excessAssets, totalGoals]);

    const minimumValueToApplyGraphRounding = 1_000_000;

    const totalStacksCount = additionalAssetStacks.length + 1;
    const barchartMaxValue = totalGoals >= totalNetAssets ? totalGoals : totalNetAssets;
    const chartUnderlayMaxValue = (barchartMaxValue >= minimumValueToApplyGraphRounding)
        ? roundUpToNext(barchartMaxValue, 5)
        : 1_000_000;
    const barchartHeightPercentage = `${((barchartMaxValue / chartUnderlayMaxValue) * 100).toFixed(2)}%`;

    return (
        <>
            <Row style={{justifyContent: "flex-end"}}>
                <div className="asset-reliance-barchart-legend-label">
                    <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.NET_ASSETS}
                                                 label={"ASSETS"}/>
                </div>
                <div className="asset-reliance-barchart-legend-label">
                    <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.GOALS}
                                                 label={"GOALS"}/>
                </div>
                <div className="asset-reliance-barchart-legend-label">
                    {hasPositiveExcessAssets(allAssetsStack.excessAssets) ?
                        <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.EXCESS_ASSETS}
                                                     label={"EXCESS ASSETS"}/>
                        : <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.ASSET_SHORTFALL}
                                                       label={"ASSET SHORTFALL"}/>}

                </div>
            </Row>
            <Row>
                <Col className="asset-reliance-chart-container">
                    <BarchartUnderlay totalStacksCount={totalStacksCount}
                                      width={allAssetsColWidth}
                                      titleCellWidth={titleCellWidth}
                                      stackContainerWidth={stackContainerWidth}
                                      maxValue={chartUnderlayMaxValue}
                    />
                    <Row data-testid="AssetRelianceBarchartRow"
                         className="asset-reliance-barchart-row"
                         style={{height: barchartHeightPercentage}}>
                        <Col width={titleCellWidth} style={{minWidth: titleCellWidth}}/>
                        <Col md={stackContainerWidth}
                             ref={(col: Col & HTMLDivElement | null) => {
                                 const width = getRefWidth(col);
                                 setAllAssetsColWidth(width);
                             }}>
                            <BarchartOverlay goals={allAssetsStack.netAssets - allAssetsStack.excessAssets}
                                             barchartColumnWidth={allAssetsColWidth}
                                             hasAssetShortfall={allAssetsStack.excessAssets < 0}
                                             stack={allAssetsStack}
                                             barchartMaxValue={allAssetsStack.excessAssets < 0 ? totalGoals : allAssetsStack.netAssets}
                            />
                            <StackBarchartContainer chartItems={charts[0].chartItems}
                                                    stack={charts[0].stack}
                                                    totalGoals={totalGoals}
                                                    totalNetAssets={totalNetAssets}
                                                    sequenceNumber={0}
                            />
                        </Col>
                        {charts.slice(1).map(({chartItems, stack}, index) => {
                            const sequenceNumber = index + 1;
                            return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                                <Col md={stackContainerWidth}
                                     key={sequenceNumber}>
                                    {stack &&
                                        <BarchartOverlay goals={stack.netAssets - stack.excessAssets}
                                                         barchartColumnWidth={allAssetsColWidth}
                                                         hasAssetShortfall={allAssetsStack.excessAssets < 0}
                                                         stack={stack}
                                                         barchartMaxValue={allAssetsStack.excessAssets < 0 ? totalGoals : allAssetsStack.netAssets}
                                        />}
                                    <StackBarchartContainer chartItems={chartItems}
                                                            stack={stack}
                                                            totalGoals={totalGoals}
                                                            totalNetAssets={totalNetAssets}
                                                            sequenceNumber={sequenceNumber}
                                    />
                                </Col>
                            </React.Fragment>
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    )
}

type BarChartDataProps = {
    excludedAssets: number;
    excessAssets: number;
    bottomChartItemAmount: number;
    bottomChartItemLabel: string;
}

const getBarChartDataForExcessAssets = ({
                                            excludedAssets,
                                            excessAssets,
                                            bottomChartItemAmount,
                                            bottomChartItemLabel,
                                        }: BarChartDataProps) => ([
    {
        label: "Excluded Assets",
        total: excludedAssets,
        color: COLOR_NT_GREY_050,
        highlight: 0
    },
    {
        label: "Excess Assets",
        total: Math.abs(excessAssets),
        color: (excessAssets < 0) ? COLOR_NT_GREY_050 : COLOR_ASSETS_ACCOUNTS,
        highlight: 0
    },
    {
        label: bottomChartItemLabel,
        total: bottomChartItemAmount,
        color: COLOR_ASSETS_ACCOUNTS,
        highlight: bottomChartItemAmount
    },
])

const getBarChartDataForAssetShortfall = ({
                                              excessAssets,
                                              bottomChartItemAmount,
                                              bottomChartItemLabel,
                                          }: BarChartDataProps) => ([
    {
        label: "Asset Shortfall",
        total: Math.abs(excessAssets),
        color: COLOR_NT_GREY_050,
        highlight: 0
    },
    {
        label: bottomChartItemLabel,
        total: bottomChartItemAmount,
        color: COLOR_ASSETS_ACCOUNTS,
        highlight: bottomChartItemAmount
    },
])

type AllBarChartsDataProps = {
    stacks: Array<AssetRelianceStack>,
    totalGoals: number,
    totalNetAssets: number
}

export const getAllBarChartData = ({
                                       stacks,
                                       totalGoals,
                                       totalNetAssets
                                   }: AllBarChartsDataProps): Array<AssetRelianceBarChartData> => {
    let isAssetSufficient = totalGoals < totalNetAssets;
    const bottomChartItemLabel = isAssetSufficient ? "Goals" : "Net Assets";

    let stacksWithData: Array<AssetRelianceBarChartData> = [];

    if (isAssetSufficient) {
        stacksWithData = stacks.map(stack => ({
            chartItems: getBarChartDataForExcessAssets({
                excludedAssets: stack.totalExcludedAssets,
                excessAssets: stack.excessAssets,
                bottomChartItemAmount: stack.netAssets - stack.excessAssets,
                bottomChartItemLabel
            } as BarChartDataProps),
            totalGoals,
            stack,
        }));
    } else {
        stacksWithData = stacks.map(stack => ({
            chartItems: getBarChartDataForAssetShortfall({
                excludedAssets: stack.totalExcludedAssets,
                excessAssets: stack.excessAssets,
                bottomChartItemAmount: totalNetAssets - stack.totalExcludedAssets,
                bottomChartItemLabel
            } as BarChartDataProps),
            totalGoals,
            stack,
        }));
    }
    if (stacksWithData.length < 2) {
        const emptyBarCharts: Array<AssetRelianceBarChartData> = Array(6 - stacksWithData.length).fill(EMPTY_BAR_CHART_DATA);
        return [...stacksWithData, ...emptyBarCharts]
    }

    return stacksWithData;
}

type BarchartUnderlayProps = {
    totalStacksCount: number;
    width: number;
    maxValue: number;
    titleCellWidth: number;
    stackContainerWidth: number;
}

const BarchartUnderlay: React.FC<BarchartUnderlayProps> = ({
                                                               totalStacksCount,
                                                               width,
                                                               maxValue,
                                                               titleCellWidth,
                                                               stackContainerWidth,
                                                           }) => {
    return (
        <>
            <Row className="asset-reliance-barchart-row barchart-row-underlay">
                <Col width={titleCellWidth} style={{minWidth: titleCellWidth}}>
                    {_.range(6).map((index) => {
                        const verticalOffset = `${(index * 20)}`;
                        const lineWidth = (totalStacksCount <= 1 ? 6 : totalStacksCount) * width;
                        const labelNumber = truncateCurrency((maxValue * ((5 - index) * 0.2)), {
                            formatAsCurrency: false,
                            includeUnits: true,
                            maxValue: maxValue
                        });
                        return <div key={index}>
                            <ChartStatusLine
                                id={'barChartUnderlay'}
                                label={`${labelNumber}`}
                                height={'1px'}
                                zIndex={0}
                                width={`calc(${lineWidth}px + 100%)`}
                                lineColor={COLOR_NT_GREY}
                                verticalOffset={verticalOffset}
                                marginLeft={0}
                                textColor={''}
                            />
                        </div>
                    })}
                </Col>
                {_.range(totalStacksCount).map((index) => {
                    return <React.Fragment key={`UnderlayColumn` + index}>
                        <span className="divider"></span>
                        <Col md={stackContainerWidth}></Col>
                    </React.Fragment>
                })}
            </Row>
        </>
    );
}

type BarchartOverlayProps = {
    barchartColumnWidth: number;
    goals: number;
    hasAssetShortfall: boolean;
    stack: AssetRelianceStack;
    barchartMaxValue: number;
}

const BarchartOverlay: React.FC<BarchartOverlayProps> = ({
                                                             barchartColumnWidth,
                                                             goals,
                                                             hasAssetShortfall,
                                                             stack,
                                                             barchartMaxValue
                                                         }) => {
    const goalsPercentage = getGoalsLineOffset(barchartMaxValue, goals);
    const goalsPositionWithExcessAssets = (goalsPercentage).toFixed(2);
    const goalsPositionWithAssetShortFall = (hasAssetShortfall && stack.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) ? goalsPositionWithExcessAssets : '0';

    return (
        <>
            <ChartStatusLine label=''
                             width={`${barchartColumnWidth}px`}
                             verticalOffset={hasAssetShortfall ? goalsPositionWithAssetShortFall : goalsPositionWithExcessAssets}
                             lineColor={COLOR_GOALS}
                             marginLeft={0}
                             textColor={COLOR_NT_ORANGE_800}
                             id={"barChartOverlay"}/>
        </>
    );
}

type BarchartProps = Omit<AssetRelianceBarChartData, 'totalGoals'> & {
    totalGoals: number;
    totalNetAssets: number;
    sequenceNumber: number;
};

const StackBarchartContainer: React.FC<BarchartProps> = ({
                                                             chartItems,
                                                             stack,
                                                             sequenceNumber,
                                                             totalGoals,
                                                             totalNetAssets
                                                         }) => {
    return (
        <>{
            stack
                ? <StackBarchart chartItems={chartItems}
                                 sequenceNumber={sequenceNumber}
                                 stack={stack}
                                 totalGoals={totalGoals}
                                 totalNetAssets={totalNetAssets}/>
                : <EmptyStackBarchart sequenceNumber={sequenceNumber}/>
        }</>
    )
};

const countNumberOfDigits = (truncatedValueRoundedToTen: number) => {
    return String(truncatedValueRoundedToTen).match(/\d/g)?.length || 0;
};

const roundUpToNext = (value: number, roundUpBy: number) => {
    const truncatedValue = Number(truncateCurrency(value, {
        formatAsCurrency: false,
        includeUnits: false,
    }));

    // Round up to the next roundUpBy value. If value falls exactly on a roundUpBy value boundary, round up to the next roundUpBy value
    const isEvenlyDivisibleByTheRoundUp = truncatedValue % roundUpBy === 0;
    const valueToRound = isEvenlyDivisibleByTheRoundUp
        ? truncatedValue + 1
        : truncatedValue;
    const truncatedValueRoundedToRoundUpByValue = Math.ceil(valueToRound / roundUpBy) * roundUpBy;

    const base = 10;
    const truncatedDigits = countNumberOfDigits(truncatedValue);
    const roundedDigits = countNumberOfDigits(truncatedValueRoundedToRoundUpByValue);
    const scaledValueAfterRounding = truncatedValueRoundedToRoundUpByValue / Math.pow(base, roundedDigits);

    const scaledDigits = countNumberOfDigits(Math.floor(value)) + (roundedDigits - truncatedDigits);
    return scaledValueAfterRounding * Math.max(Math.pow(base, scaledDigits), 1);
}

type StackBarchartProps = BarchartProps & {
    stack: AssetRelianceStack
};

const StackBarchart: React.FC<StackBarchartProps> = ({
                                                         chartItems,
                                                         stack,
                                                         totalGoals,
                                                         totalNetAssets
                                                     }) => {
    const isAssetSufficient = totalNetAssets >= totalGoals;

    const {topMargin, bottomMargin} = isAssetSufficient ?
        getExcessAssetsLinePositionData(stack, totalNetAssets) :
        getAssetShortfallLinePositionData(stack, stack.netAssets, totalGoals);
    const pillColor = (stack.excessAssets > 0) ? COLOR_EXCESS_ASSETS : COLOR_PROJECTED_ASSETS;

    return <div data-testid={"AssetRelianceStackBarchart"} className="barchart-container">
        <StackedBarChart
            showLabels={false}
            chartWidth={CHART_WIDTH}
            data={chartItems}
        />
        <ExcessAssetsVerticalLine color={pillColor}
                                  bottomPercentage={bottomMargin.toFixed(2)}
                                  excessAssets={stack.excessAssets}
                                  topPercentage={topMargin.toFixed(2)}/>
    </div>;
};

const GRID_COLUMN_OFFSET = 5;

const EmptyStackBarchart: React.FC<Pick<BarchartProps, 'sequenceNumber'>> = ({sequenceNumber}) => {
    return <div
        style={{gridColumnStart: (GRID_COLUMN_OFFSET + sequenceNumber)}}
    >
        <StackedBarChart
            key={sequenceNumber}
            showLabels={false}
            chartWidth={CHART_WIDTH}
            data={[]}
            noDataColor={COLOR_NT_GREY_050}
        />
    </div>;
};

const getGoalsLineOffset = (barchartMaxValue: number, goals: number): number => {
    const goalsPercentage = (goals / barchartMaxValue) * 100;
    return 100 - goalsPercentage;
}

const getExcessAssetsLinePositionData = (stack: AssetRelianceStack, totalNetAssets: number) => {
    const stackGoals = stack.netAssets - stack.excessAssets
    const stackTotal = stack.totalExcludedAssets + Math.abs(stack.excessAssets) + stackGoals;
    let topMargin, bottomMargin;

    if (stack.excessAssets >= 0) {
        topMargin = (stack.totalExcludedAssets / stackTotal) * 100;
        bottomMargin = (stackGoals / totalNetAssets) * 100;
    } else {
        topMargin = 100 - ((stackGoals / totalNetAssets) * 100);
        topMargin = (topMargin >= 0) ? (topMargin + 1.7) : 0;
        bottomMargin = (stackGoals / stackTotal) * 100;
    }

    return {
        topMargin,
        bottomMargin,
    }
}

const getAssetShortfallLinePositionData = (stack: AssetRelianceStack, netAssets: number, totalGoals: number) => {
    let topMargin = 1.7;
    let bottomMargin = (netAssets / totalGoals) * 100;
    if (stack.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
        const investableStackGoals = Math.abs(stack.netAssets - stack.excessAssets);
        topMargin = (investableStackGoals / totalGoals) * 100;
        topMargin = 100 - topMargin + 1.7;
        const stackGoals = stack.netAssets - stack.excessAssets;
        bottomMargin = (netAssets / stackGoals) * 100;
    }
    return {
        topMargin,
        bottomMargin,
    }
}

export default AssetRelianceBarchartContainer;