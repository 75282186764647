import {Logo} from 'xps-react';
import {Button, DropdownItem, PageActionMenu} from 'src/components';
import './agenda.png';
import React, {useEffect, useMemo, useState} from "react";
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {DropResult} from "react-beautiful-dnd";
import moment from "moment";
import AgendaEntryList from "./AgendaEntryList";
import {agendaApiClient} from "./AgendaApiClient";
import {AgendaDetails, AgendaItem, Presenter, PresenterType, ServiceTeamMemberAgenda} from "./models/AgendaDetails";
import {DEFAULT_AGENDA_ENTRIES, DEFAULT_DISCUSSION_TOPIC} from "./agendaConstants";
import LoadingIndicator from "../pages/LoadingIndicator";
import DataEntrySideDrawer from "../components/DataEntry/DataEntrySideDrawer";
import AgendaMeetingDetails from "./AgendaMeetingDetails";
import {useAppSelector} from "../store/hooks";
import {selectResourceCode} from "../ClientManagement/ClientProfile/activeProfileSlice";
import {partnerApiClient} from "../ClientManagement/PartnerApiClient";
import {RouteWithId} from "../routes/types";
import usePageViewTimer from "../hooks/usePageViewTimer";
import {generateDefaultAgenda} from "./__testUtils__/generators";
import ModalWrapper from "../components/Modal/ModalWrapper/ModalWrapper";
import {HistoryBlockModal} from "../components/HistoryBlockModal/HistoryBlockModal";
import {selectApprovedProfile} from "../ClientManagement/ClientProfile/approvedProfileSlice";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import {ProfileResponse} from "../ClientManagement/models/ProfileResponse";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";

import {isProposal} from "../ClientManagement/models/isProposal";
import {updateNtPresenterDetails} from "./AgendaUtils";

enum ShiftDirection {
    UP = -1,
    DOWN = 1
}

const Agenda = () => {
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [agendaDetails, setAgendaDetails] = useState<AgendaDetails | null>(null);
    const [openMeetingAgenda, setOpenMeetingAgenda] = useState(false);
    const resourceCode = useAppSelector(selectResourceCode);
    const [partners, setPartners] = useState<ServiceTeamMemberAgenda[]>([]);
    const [presenters, setPresenters] = useState<Presenter[]>([]);
    const [agendaEntryItems, setAgendaEntryItems] = useState<Array<AgendaItem> | []>([]);
    const [showAgendaClearConfirmationModal, setShowAgendaClearConfirmationModal] = useState<boolean>(false);
    const [agendaEntryFormData, setAgendaEntryFormData] = useState<AgendaItem>({...DEFAULT_DISCUSSION_TOPIC});
    const [approvedProfileFromApi, setApprovedProfileFromApi] = useState<ProfileResponse>();
    const approvedProfile = useAppSelector(selectApprovedProfile);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);

    const handleErrorCloseButton = () => {
        setGenericError({...genericError, isOpen: false});
    }

    usePageViewTimer('Agenda Page Load Timer (milliseconds)', isLoading);
    const displayName: string = approvedProfile?.displayName || "";

    const placeholderDiscussionTopic = useMemo(() => ({
        startTime: "00:00AM",
        presenterName: "PRESENTER",
        title: "Item Title",
        notes: "Additional Notes"
    }), [])

    function seedErrorModal(error: Error | any) {
        setGenericError({
            isOpen: true,
            header: "Communication Failure",
            message: "There has been a communication failure.  The data you see may not be accurate, please refresh your browser.  If this error continues, please contact the support team.",
            operationId: error.headers.get('trace-id')
        });
    }

    useEffect(() => {
        if (!approvedProfile?.id) {
            clientManagementApiClient.getProfile(id)
                .then(newProfile => {
                    if (isProposal(newProfile)) {
                        clientManagementApiClient.getProfile(newProfile.profileIdForProposal)
                            .then(newApprovedProfile => setApprovedProfileFromApi(newApprovedProfile))
                            .catch(error => {
                                console.error('Could not fetch profile for proposal', error.message)
                            });
                    } else {
                        setApprovedProfileFromApi(newProfile);
                    }
                }).catch((error) => {
                seedErrorModal(error);
                console.error('Could not fetch profile', error.message);
            })
        } else {
            setApprovedProfileFromApi(approvedProfile);
        }
    }, [id, approvedProfile?.id]);

    useEffect(() => {
        if (resourceCode) {
            partnerApiClient.getServiceTeam(resourceCode)
                .then((response: ServiceTeamMemberAgenda[]) => {
                    if (response.length > 0) {
                        setPartners(response);
                    }
                }).catch(reason => {
                    return reason;
                }
            ).catch((error) => {
                seedErrorModal(error);
                console.error('Failed to retrieve conditions', error.message);
            })
        } else {
            if (approvedProfile?.owner) {
                partnerApiClient.getPartnerDetail(approvedProfile.owner)
                    .then((response: ServiceTeamMemberAgenda) => {
                        setPartners([response]);
                    }).catch(reason => {
                        return reason;
                    }
                );
            }
        }
    }, [resourceCode, approvedProfile?.owner]);

    useEffect(() => {
        setIsLoading(true);
        if (approvedProfileFromApi?.id) {
            agendaApiClient.getAgenda(approvedProfileFromApi.id).then(
                agenda => {
                    if (!agenda.presenters.length) {
                        agenda.presenters = generateDefaultAgenda().presenters
                    }
                    agenda.clientProfileName = agenda.clientProfileName || displayName;
                    setAgendaDetails(agenda);
                    setAgendaEntryItems(JSON.parse(JSON.stringify(agenda.agendaItems)));
                    setIsLoading(false);
                }
            ).catch((error) => {
                seedErrorModal(error);
                setIsLoading(false);
            })
        }
    }, [approvedProfileFromApi?.id]);

    useEffect(() => {
        const isMeetingAgendaPage = history.location.pathname.split("/").includes("MeetingAgenda");
        setOpenMeetingAgenda(isMeetingAgendaPage);
    }, [history.location.pathname]);

    const removeNullEXTPresenters = (presentersList: Presenter[]) => {
        const updatedPresenters: Presenter[] = presentersList;
        const ntPresenters = updatedPresenters.filter(({presenterType}) => presenterType === 'NT_PRESENTER');
        const extPresenters = updatedPresenters.filter(({
                                                            presenterType,
                                                            presenterName,
                                                            presenterTitle
                                                        }) => presenterType === 'EXTERNAL_PRESENTER' && (presenterName?.trim() || presenterTitle?.trim()));
        return [...ntPresenters, ...extPresenters];
    }

    const handleSaveAgendaItems = async (updatedAgendaDetails: AgendaDetails, navigate: boolean = false): Promise<void> => {
        updateAgendaEntryItems();
        updatedAgendaDetails.agendaItems = JSON.parse(JSON.stringify(agendaEntryItems.filter(({
                                                                                                  startTime,
                                                                                                  presenterName,
                                                                                                  title,
                                                                                                  notes
                                                                                              }) => {
            return (!(startTime === '00:00AM' && presenterName === 'PRESENTER' && title === 'Item Title' && notes === 'Additional Notes')
                && !(startTime === '' && presenterName === '' && title === '' && notes === ''))
        })));

        updatedAgendaDetails.presenters = removeNullEXTPresenters(updatedAgendaDetails.presenters)

        if (agendaDetails?.id) {
            agendaApiClient.updateAgenda(updatedAgendaDetails).then((updatedData) => {
                if (updatedData) {
                    setAgendaDetails(updatedData);
                    setAgendaEntryItems(JSON.parse(JSON.stringify(updatedData.agendaItems)));
                }
            });
        } else {
            agendaApiClient.saveAgenda({
                ...agendaDetails,
                ...updatedAgendaDetails,
                profileId: approvedProfile?.id!
            }).then((savedData) => {
                if (savedData) {
                    setAgendaDetails(savedData);
                    setAgendaEntryItems(JSON.parse(JSON.stringify(savedData.agendaItems)));
                }
            });
        }
        if (navigate) {
            history.goBack();
        }
    }

    const updateAgendaEntryItems = () => {
        if (agendaEntryFormData.mode) {
            const index = agendaEntryItems.findIndex(item => item.mode === agendaEntryFormData.mode);
            if (index !== -1) {
                delete agendaEntryFormData.mode;
                agendaEntryItems[index] = {...agendaEntryFormData};
                setAgendaEntryFormData({...DEFAULT_DISCUSSION_TOPIC});
            }
        }
    }

    const navigateToCreateEditAgenda = () => {
        history.push(`/Profile/${id}/ClientProfile/Agenda/MeetingAgenda`);
    }



    useEffect(() => {

        const updatedPresenters: Presenter[] = [];
        agendaDetails?.presenters.forEach(({
                                               presenterId,
                                               presenterName,
                                               presenterType,
                                               presenterTitle
                                           }) => {
            if (presenterType === 'NT_PRESENTER') {
                updateNtPresenterDetails(presenterId, presenterTitle, updatedPresenters, presenterType, presenterName, partners);
            } else {
                if (presenterName || presenterTitle) {
                    updatedPresenters.push({
                        presenterType,
                        presenterName: presenterName,
                        presenterTitle: presenterTitle
                    })
                }
            }
        });
        setPresenters(updatedPresenters);

    }, [agendaDetails?.presenters, partners])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const setActiveEntryFormData = (dataIndex: number, agendaEntry: AgendaItem) => {

        agendaEntryItems[dataIndex] = Object.values(agendaEntry).some(item => item && item !== agendaEntry['itemSequenceNo']) ? {
            ...agendaEntry
        } : {
            ...placeholderDiscussionTopic,
            itemSequenceNo: agendaEntry['itemSequenceNo']
        };
    }

    const setPreviousFormData = (previousEditableData: AgendaItem) => {
        const addItemIndex = agendaEntryItems.findIndex(item => item['mode'] === previousEditableData['mode']);
        delete previousEditableData['mode'];

        setActiveEntryFormData(addItemIndex, previousEditableData);
    }

    const handleUpdateAgendaEntryItems = (dataIndex: number, agendaEntry: AgendaItem, previousEditableData?: AgendaItem): void => {
        switch (agendaEntry['mode']) {
            case 'delete':
                agendaEntryItems.splice(dataIndex, 1);
                break;
            case 'edit':
                if (previousEditableData) {
                    setPreviousFormData(previousEditableData);
                }
                setActiveEntryFormData(dataIndex, agendaEntry);
                break;
            case 'add':
                const maxOrder = Math.max(...agendaEntryItems.map(topic => topic.itemSequenceNo)) + 1;

                if (previousEditableData) {
                    setPreviousFormData(previousEditableData);
                    agendaEntryItems[dataIndex] = {...agendaEntry, itemSequenceNo: maxOrder};
                } else {
                    agendaEntryItems[dataIndex] = Object.values(agendaEntry).some(item => item && item !== agendaEntry['itemSequenceNo']) ? {
                        ...agendaEntry,
                        itemSequenceNo: maxOrder
                    } : {
                        ...placeholderDiscussionTopic,
                        itemSequenceNo: maxOrder
                    };

                }
                break;
            default:
                setActiveEntryFormData(dataIndex, agendaEntry);
        }

        setAgendaEntryItems([
            ...agendaEntryItems,
        ])
    }

    const onReorderEntries = ({source, destination}: DropResult) => {
        if (destination) {
            const start = Math.min(source.index, destination.index);
            const end = Math.max(source.index, destination.index);
            const shiftSequenceNumber = destination.index > source.index ? ShiftDirection.UP : ShiftDirection.DOWN;
            const destinationSeqNumber = agendaEntryItems[destination.index].itemSequenceNo;

            const updatedAgendaEntries = agendaEntryItems.map((item, index) => {
                let updatedStackSequenceNumber: number = item.itemSequenceNo;
                if (index === source.index) {
                    updatedStackSequenceNumber = destinationSeqNumber
                } else if ((start <= index) && (index <= end)) {
                    updatedStackSequenceNumber += shiftSequenceNumber
                }
                return {
                    ...item,
                    itemSequenceNo: updatedStackSequenceNumber
                }
            });
            updatedAgendaEntries.sort((a, b) => a.itemSequenceNo - b.itemSequenceNo);
            setAgendaEntryItems(updatedAgendaEntries);
        }
    }

    const areMeetingDetailsSaved = agendaDetails && agendaDetails.id && agendaDetails.meetingTitle && agendaDetails.meetingDate && agendaDetails.presenters.length;

    const handleAgendaClearCancel = (showModal: boolean): void => {
        setShowAgendaClearConfirmationModal(showModal);
    }

    const handleAgendaClearConfirm = (): void => {
        agendaApiClient.deleteAgenda(approvedProfile?.id as string).then((result) => {
            if (result.status === 200) {
                setAgendaDetails(generateDefaultAgenda({clientProfileName: displayName}));
                setAgendaEntryItems(() => JSON.parse(JSON.stringify(DEFAULT_AGENDA_ENTRIES)));
                handleAgendaClearCancel(false);
            }

        }).catch(() => {
            handleAgendaClearCancel(false);
        }).catch((error) => {
            seedErrorModal(error);
            console.error('Failed to retrieve conditions', error.message);
        })

    }

    return (
        <div className="agenda">
            <GenericErrorModal
                errorModalData={genericError}
                onClickClose={handleErrorCloseButton}
                closeButtonText={'Close'}
            />
            <article className="agenda-page layout-sidebar-left">
                {areMeetingDetailsSaved ?
                    (<section data-theme="aqua">
                        <div className="agenda-page__cover">
                            <div className="agenda-page__edit-meeting-details">
                                <Button
                                    icon="only"
                                    iconName="edit"
                                    kind="borderless"
                                    onClick={() => navigateToCreateEditAgenda()}
                                    size="small"
                                    tabIndex={0}
                                    type="button"
                                />
                            </div>
                            <div className="agenda-page__cover__title">
                                <Logo color="white" logoType="single" width="270"/>
                                <h1 className="margintop-none marginright-xxl">{agendaDetails.meetingTitle}</h1>
                                <div className={"agenda-meeting-detail-header"}>
                                    <b>
                                        <span>{`Prepared for ${agendaDetails.clientProfileName}`}</span>
                                        <br/>
                                        <span>{moment(agendaDetails.meetingDate).format('dddd, MMMM Do YYYY')}</span>
                                        <br/>
                                        <span>{agendaDetails.meetingLocation}</span>
                                        <br/>
                                    </b>
                                </div>
                                <hr/>
                            </div>
                            <div className="agenda-page__cover__team">
                                <span className="agenda-page__cover__team__presented-by margintop-none marginbottom-lg">
                                  <b>Presented by your Northern Trust Team</b>
                                </span>
                                <br/>
                                <br/>
                                <div className="agenda-page__cover__team__members">
                                    {presenters.filter(p => p.presenterType == 'NT_PRESENTER').map(({
                                                                                                        presenterName,
                                                                                                        presenterTitle
                                                                                                    }, index) => (
                                        <div className="agenda-page__cover__team__member" key={index}>
                                            <b className="margintop-none marginbottom-xs">{presenterName}</b>
                                            <br/>
                                            {presenterTitle ? <><span>{presenterTitle}</span><br/></> : ""}
                                            <br/>
                                        </div>
                                    ))}
                                </div>
                                {(presenters.findIndex(p => p.presenterType == "EXTERNAL_PRESENTER") >= 0) && <>

                                <span className="agenda-page__cover__team__presented-by margintop-none marginbottom-lg">
                                  <b>Other Presenters</b>
                                </span>
                                    <br/>
                                    <br/>
                                    <div className="agenda-page__cover__team__members">
                                        {presenters.map(({presenterType, presenterName, presenterTitle}, index) => (
                                            (presenterType === "EXTERNAL_PRESENTER") &&
                                            <div className="agenda-page__cover__team__member" key={index}>
                                                <b className="margintop-none marginbottom-xs">{presenterName}</b>
                                                <br/>
                                                {presenterTitle && <><span>{presenterTitle}</span><br/></>}
                                                <br/>
                                            </div>
                                        ))}
                                    </div>

                                </>}
                            </div>
                        </div>
                    </section>)
                    : (<section data-theme="aqua">
                        <div className="agenda-page__cover">
                            <div className="agenda-page__cover__title">
                                <Logo color="white" logoType="single" width="270"/>
                            </div>
                            <div className="agenda-page__cover__empty">
                                <Button
                                    icon="left"
                                    iconName='add'
                                    size="medium"
                                    rounded
                                    onClick={() => navigateToCreateEditAgenda()}
                                >
                                    Add Meeting Details
                                </Button>
                            </div>
                        </div>
                    </section>)}

                <section className="margin-xxl">
                    <div className="agenda-entries__header">
                        <Button
                            className="marginright-md"
                            disabled={false}
                            icon="none"
                            kind="primary"
                            onClick={() => handleSaveAgendaItems(agendaDetails!)}
                            size="medium"
                            type="button"
                            rounded
                        >
                            Save
                        </Button>
                        <PageActionMenu
                            className="marginright-xxl"
                            panelHeight="auto"
                            panelWidth={200}
                            buttonKind="primary"
                            aria-label="Agenda Page Action Menu"
                        >
                            <DropdownItem
                                key={"clearAgenda"}
                                itemText={"Clear Agenda"}
                                disabled={!agendaDetails?.id}
                                value={"Clear Agenda"}
                                onClick={() => handleAgendaClearCancel(true)}
                            />
                        </PageActionMenu>
                    </div>
                    <div className="agenda-page__schedule">
                        <h2 className="marginbottom-xxxl">Today's Agenda</h2>
                        {agendaDetails?.agendaItems &&
                            <AgendaEntryList
                                agendaEntries={agendaEntryItems}
                                onUpdateAgendaEntryItems={handleUpdateAgendaEntryItems}
                                setAgendaEntryFormData={setAgendaEntryFormData}
                                agendaEntryFormData={agendaEntryFormData}
                                onReorderEntries={onReorderEntries}
                            />}
                    </div>
                </section>
                <div className="agenda-page__border"/>
            </article>
            {agendaDetails && <DataEntrySideDrawer isOpen={openMeetingAgenda} size="full" formLayout="split">
                <Switch>
                    <Route path={"/Profile/:profileId/ClientProfile/Agenda/MeetingAgenda"}
                           component={() =>
                               <AgendaMeetingDetails
                                   partners={partners}
                                   agendaDetails={agendaDetails}
                                   handleSaveAgenda={handleSaveAgendaItems}
                                   displayName={displayName}
                               />}/>
                </Switch>
            </DataEntrySideDrawer>}

            <ModalWrapper
                id="edit-confirmation-modal"
                isOpen={showAgendaClearConfirmationModal}
                headerText={"Confirm"}
                buttons={[
                    {
                        text: "keep editing",
                        onClick: () => handleAgendaClearCancel(false)
                    },
                    {
                        text: "clear agenda",
                        onClick: handleAgendaClearConfirm,
                        destructive: true,
                        primary: true,
                    }
                ]}
            >
                <div className="font-md">
                    {"You are about to clear the current Agenda. Click Keep Editing to continue with your currently entered text. "}
                </div>
            </ModalWrapper>

            <HistoryBlockModal
                when={
                    JSON.stringify(agendaDetails?.agendaItems) !== JSON.stringify(agendaEntryItems)
                }
                itemType={"Agenda"}
                onSave={() => handleSaveAgendaItems(agendaDetails!)}
                onDiscard={() => {
                    return null
                }}
            />

        </div>

    );
};

export default Agenda;