import React from "react";
import {Col, Grid, Row} from "xps-react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import MeetingHistoryDetails from "./MeetingHistoryDetails";
import {Button} from "../../components";

const MeetingsHistory: React.FC = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const {profile} = useProfileAndProposals(id);

    const handleProfileDetailsNavigation = () => {
        history.push(`/Profile/${profile.id}`);
    };


    return (
        <Grid fluid={true}>
            <Row>
                <Col>
                    <div className="page-container meetings-details-container">
                        <div className="meetings-details-cta">
                            <div className="h1">Meeting History - {profile?.displayName}</div>
                            <div className="right-aligned">
                                <Button
                                    id="NavigateToProfileDetails"
                                    className="marginright-sm"
                                    icon="none"
                                    includeRef={false}
                                    kind="secondary"
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                    onClick={handleProfileDetailsNavigation}
                                >
                                    <span className="new-client-text">CLOSE</span>
                                </Button>
                            </div>
                        </div>
                        <MeetingHistoryDetails/>
                    </div>
                </Col>
            </Row>
        </Grid>
    )
}
export default MeetingsHistory;
