import {UnderlinedHeader} from "../../components";
import React from "react";
import {Switch} from 'xps-react';
import {InvestorGroupMemberType, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import moment from "moment/moment";

export const LifestyleGoalFunding = ({
                                         onToggle,
                                         isOn,
                                         investorGroup,
                                         sufficientYears
                                     }: {
    onToggle: () => void,
    isOn: boolean,
    investorGroup: InvestorGroupType,
    sufficientYears: number,
}) => {
    let firstName: string = "";
    let clientAge: number = 0;
    if (investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER) {
        firstName = investorGroup.partnerMember?.firstName!;
        clientAge = (moment().diff(investorGroup.partnerMember?.birthdate, 'years'))
    } else {
        firstName = investorGroup.primaryMember?.firstName!;
        clientAge = (moment().diff(investorGroup.primaryMember?.birthdate, 'years'))
    }
    const fundedPortfolioThroughAgeDescription: string = `${clientAge + sufficientYears} (${firstName}, in ${sufficientYears} years)`;

    return <section aria-label="Goal Funding" className="lifestyle-spending-section">
        <UnderlinedHeader
            className="lifestyle-spending-section-header"
            primaryText='Goal Funding'
        />

        <div className="lifestyle-goal-funding-table">
            <label>
                <b>Fund using <br/> Non-Investable Assets</b>
            </label>
            <span
                className="funded-by-non-investable-assets-slider-container"
            >
                <span className="funded-by-non-investable-assets-slider"
                      data-testid="funded-by-non-investable-assets-slider"
                      onClick={onToggle}
                >
                    <Switch
                        isOn={isOn}
                        offText="OFF"
                        onText="ON"
                        stateTextPosition="right"
                    />
                </span>
            </span>
        </div>
        <div className="lifestyle-goal-funding-table">
            <label>
                <b></b>
            </label>
            <span className="paddingright-xl" style={{fontSize: "13px"}}>In order to alleviate investable insufficiency, this option <br/> can be enabled to fund latter years of lifestyle <br/> spending using non-investable assets.</span>
        </div>
        {isOn &&
            <div className="lifestyle-goal-funding-table">
                <label>
                    <b>Funded by Portfolio Through Age</b>
                </label>
                <div data-testid="funded-by-portfolio-through-age" className="funded-by-portfolio-through-age-description">
                    {fundedPortfolioThroughAgeDescription}
                </div>
            </div>
        }
    </section>
}
