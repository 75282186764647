import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {
    Button,
    CurrencyInput,
    DatePicker,
    Input, Label, PercentInput, RadioGroup,
    RequiredFieldsBanner,
    RequiredFieldsSubheader
} from "../../components";
import {MemberGroup, MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {EquityCompensationFormData, SPOUSE_EQUITY} from "../models/EquityCompensation";
import {DISPLAY_DATE_FORMAT, ISO8601_DATE_FORMAT} from "../../constants/common";
import {ClientMemberOwnerDropdown} from "../Ownership/Sole/ClientMemberOwnerDropdown";
import {booleanToRadioButton, radioButtonToBoolean} from "../formHelpers";
import {RadioYesNoOptional} from "../models/Common";
import moment, {Moment} from "moment";
import deepEquals from "fast-deep-equal";
import {mapToInvestorMemberGroupMember} from "../Ownership/mappers";
import AssetsViewContext from "../common/AssetsViewContext";
import { formatCurrencyRounded, formatCurrencyToNumber } from "src/utils/format";
import { assetsApiClient } from "../AssetsApiClient";
import {setActiveFormAsset} from "../clientAssetsSlice";
import {useAppDispatch} from "../../store/hooks";
import {
    calculateEquityCompensationValue
} from "../AssetSummary/common/AssetSummaryCalculator";

export type EquityCompensationProps = {
    handleSave: (request: EquityCompensationFormData) => Promise<EquityCompensationFormData>,
    initialEquityComp: EquityCompensationFormData,
    handleCancel: (isFormChanged: boolean) => void,
    memberGroup: MemberGroup,
    formatTitle: (description: string) => string,
}

export function EquityCompensationForm(props: EquityCompensationProps) {
    const {id} = useParams<RouteWithId>();
    const {profile} = useProfileAndProposals(id);
    const history = useHistory();
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [equityCompensationFormData, setEquityCompensationFormData] = useState(props.initialEquityComp);
    const [isAsOfDateInlineErrorShown, setIsAsOfDateInlineErrorShown] = useState(false);
    const [isStockNameInlineErrorShown, setIsStockNameInlineErrorShown] = useState(false);
    const [isAccountNameInlineErrorShown, setIsAccountNameInlineErrorShown] = useState(false);
    const viewType = useContext(AssetsViewContext);
    const dispatch = useAppDispatch();

    const isAnyRequiredFieldEmpty = () => {
        const {entityOrAccountName, companyOrStockName, asOfDate} = equityCompensationFormData;
        return !entityOrAccountName || !companyOrStockName || !asOfDate;
    }

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [equityCompensationFormData.entityOrAccountName, equityCompensationFormData.companyOrStockName, equityCompensationFormData.asOfDate]);

    useEffect(() => {
        dispatch(setActiveFormAsset({
            assetType: 'equityCompensation',
            id: equityCompensationFormData.id,
            inEstateValue: calculateEquityCompensationValue(equityCompensationFormData),
            description: equityCompensationFormData.entityOrAccountName,
            hasInEstateOwnership: true,
        }));
        return clearActiveFormAsset;
    }, [equityCompensationFormData.entityOrAccountName]);

    const clearActiveFormAsset = () => {
        dispatch(setActiveFormAsset(null));
    }

    const getMemberName = (member: MemberGroupMember): string => {
        return `${member.firstName} ${member.lastName}`;
    }

    const summaryItems: DataEntrySummaryItem[] = [
        {
            label: 'Entity / Account Name',
            value: equityCompensationFormData.entityOrAccountName
        },
        {
            label: 'Owner',
            value: getMemberName(getCurrentOwnerByOwnerMemberId(equityCompensationFormData.owner))
        },
        {
            label: 'Pre-Tax Unvested',
            value: equityCompensationFormData.preTaxUnvestedValue ?
                formatCurrencyRounded(equityCompensationFormData.preTaxUnvestedValue, 0): ''
        },
        {
            label: 'Pre-Tax Vested',
            value: equityCompensationFormData.preTaxVestedValue ?
            formatCurrencyRounded(equityCompensationFormData.preTaxVestedValue, 0): ''
        },
        {
            label: 'After-Tax Unvested',
            value: equityCompensationFormData.afterTaxUnvestedValue ?
            formatCurrencyRounded(equityCompensationFormData.afterTaxUnvestedValue, 0): ''
        },
        {
            label: 'After-Tax Vested',
            value: equityCompensationFormData.afterTaxVestedValue ?
            formatCurrencyRounded(equityCompensationFormData.afterTaxVestedValue, 0): ''
        }
    ];

    const handleTaxRateChange = (value: string) => {
        setEquityCompensationFormData({
            ...equityCompensationFormData, taxRate: formatCurrencyToNumber(value)
        })
    }

    const calculateSummary = () => {
        assetsApiClient.calculateEquityCompensationSummary(profile.id, equityCompensationFormData).then(res => {
            setEquityCompensationFormData(res);
            dispatch(setActiveFormAsset({
                assetType: 'equityCompensation',
                id: equityCompensationFormData.id,
                inEstateValue: calculateEquityCompensationValue(res),
                description: res.entityOrAccountName,
                hasInEstateOwnership: true,
            }));
        });
    }

    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const handleSaveButton = async (isNavigateOptionAndGrant: boolean = false) => {
        const {entityOrAccountName, companyOrStockName, asOfDate} = equityCompensationFormData;
        if (!entityOrAccountName || !companyOrStockName || !asOfDate) {
            setRequiredFieldsBannerShown(true);
            setIsAccountNameInlineErrorShown(!entityOrAccountName);
            setIsStockNameInlineErrorShown(!companyOrStockName);
            setIsAsOfDateInlineErrorShown(!asOfDate);
            return;
        }
        const equityData = await props.handleSave(equityCompensationFormData);
        if (isNavigateOptionAndGrant) {
            history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/EquityCompensation/${equityData.id}/AddOptionsAndGrants`);
        } else {
            navigateToAssetsView()
        }
    }

    const handleCancelButton = () => {
        const isFormChanged = !deepEquals(equityCompensationFormData, props.initialEquityComp);
        props.handleCancel(isFormChanged);
    }

    function getCurrentOwnerByOwnerMemberId(ownerMemberId: string) {
        let selectedOwner = props.memberGroup.primaryMember;
        if (props.memberGroup.partnerMember?.id === ownerMemberId) {
            selectedOwner = props.memberGroup.partnerMember;
        } else if (props.memberGroup.additionalMembers.length > 0 && (props.memberGroup.additionalMembers.find(member => member.id === ownerMemberId) !== undefined)) {
            selectedOwner = props.memberGroup.additionalMembers.find(member => member.id === ownerMemberId)!
        }
        return selectedOwner;
    }

    const isFutureDate = (date: Moment) => date.isAfter(moment().endOf('day'));

    const handleAddStockOptionAndGrants = async () => {
        await handleSaveButton(true);
    }

    const onOwnerChange = (ownerId: string) => {
        setEquityCompensationFormData({
            ...equityCompensationFormData,
            owner: ownerId,
        })
    }

    return (
        <aside aria-label="Equity Compensation" className="equity-compensation-form-section">
            <DataEntryHeader
                className='dataEntryHeader'
                title={props.formatTitle(equityCompensationFormData.entityOrAccountName)}
                SubtitleComponent={RequiredFieldsSubheader}
                onPrimaryButtonClick={() => handleSaveButton(false)}
                onSecondaryButtonClick={handleCancelButton}
                primaryButtonText="Save"
                secondaryButtonText="Cancel"
            />
            <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="Equity compensation"/>
            <div className="equity-compensation__form layout-data-entry-form">
                <article>
                    <div className="layout-data-entry-form__field">
                        <Label label="Entity / Account Name" htmlFor="entityAndAccountNameId" required/>
                        <Input
                            id="entityAndAccountNameId"
                            className="entityAndAccountName_field"
                            name="accountName"
                            size="medium"
                            value={equityCompensationFormData.entityOrAccountName}
                            onChange={(event) => {
                                setEquityCompensationFormData({
                                    ...equityCompensationFormData,
                                    entityOrAccountName: event.target.value
                                })
                                setIsAccountNameInlineErrorShown(!event.target.value);
                            }}
                            error={isAccountNameInlineErrorShown ? "Entity / Account Name is required." : undefined}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <Label label="Ticker" htmlFor="tickerId"/>
                        <Input
                            id="tickerId"
                            className="ticker-input_field"
                            name="Ticker"
                            size="medium"
                            value={equityCompensationFormData.ticker}
                            onChange={(event) => {
                                setEquityCompensationFormData({
                                    ...equityCompensationFormData,
                                    ticker: event.target.value
                                })
                            }}
                        />
                    </div>
                    <div className="layout-data-entry-form__field as-of-date-picker-comp">
                        <Label label="As of Date" htmlFor="asOfDateId" required/>
                        <DatePicker
                            className="equity-compensation-asofdate"
                            id="asOfDateId"
                            displayFormat={DISPLAY_DATE_FORMAT}
                            hideKeyboardShortcutsPanel
                            isOutsideRange={isFutureDate}
                            date={equityCompensationFormData.asOfDate ? moment(equityCompensationFormData.asOfDate) : undefined}
                            onDateChange={
                                (date: Moment) => {
                                    const selectedDate = date?.utc().format(ISO8601_DATE_FORMAT);
                                    setEquityCompensationFormData({...equityCompensationFormData, asOfDate: selectedDate})
                                    setIsAsOfDateInlineErrorShown(!selectedDate);
                                }
                            }
                            error={isAsOfDateInlineErrorShown ? "Invalid date format" : undefined}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <Label label="Company / Stock Name" htmlFor="compStockId" required/>
                        <Input
                            id="compStockId"
                            name="StockName"
                            size="medium"
                            value={equityCompensationFormData.companyOrStockName}
                            onChange={(event) => {
                                setEquityCompensationFormData({
                                    ...equityCompensationFormData,
                                    companyOrStockName: event.target.value
                                })
                                setIsStockNameInlineErrorShown(!event.target.value);
                            }}
                            error={isStockNameInlineErrorShown ? "Company / Stock Name is required." : undefined}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <Label label="Stock Price" htmlFor="stockPriceId"/>
                        <CurrencyInput
                            id="stockPriceId"
                            className="stockprice_field"
                            name="stockPrice"
                            size="medium"
                            allowDecimals={true}
                            value={equityCompensationFormData.stockPrice}
                            onChangeValue={(_, newValue) => {
                                setEquityCompensationFormData({
                                    ...equityCompensationFormData,
                                    stockPrice: formatCurrencyToNumber(newValue.toString()),
                                });
                            }}
                            onBlur={calculateSummary}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <PercentInput
                            defaultValue={"0"}
                            value={equityCompensationFormData.taxRate.toString()}
                            label="Tax Rate"
                            size="medium"
                            minValue={0}
                            maxValue={99}
                            onChange={handleTaxRateChange}
                            onBlur={calculateSummary}
                        />
                    </div>

                    <ClientMemberOwnerDropdown
                        id="ownerDropdown"
                        label="Owner"
                        labelId="owner"
                        value={mapToInvestorMemberGroupMember(getCurrentOwnerByOwnerMemberId(equityCompensationFormData.owner))}
                        firstOwnerOption={mapToInvestorMemberGroupMember(props.memberGroup.primaryMember)}
                        secondOwnerOption={mapToInvestorMemberGroupMember(props.memberGroup.partnerMember !== null ? props.memberGroup.partnerMember : props.memberGroup.additionalMembers.find(member => member.relationshipType === SPOUSE_EQUITY))}
                        onChange={onOwnerChange}
                    />
                    <div className="layout-data-entry-form__field">
                        <RadioGroup
                            id="doesPermitBeneficiary"
                            name="doesPermitBeneficiary"
                            label="Does this account permit a beneficiary?"
                            layout="horizontal"
                            values={["Yes", "No"]}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const {value} = e.target;
                                setEquityCompensationFormData({
                                    ...equityCompensationFormData,
                                    doesPermitBeneficiary: radioButtonToBoolean(value as RadioYesNoOptional),
                                })
                            }}
                            selected={booleanToRadioButton(equityCompensationFormData.doesPermitBeneficiary)}
                        />
                    </div>
                </article>
                <aside aria-label="Account Summary">
                    <DataEntrySummary
                        items={summaryItems}
                        title="Account Summary"
                    >
                        <Button
                            ariaLabel="Add Options and Grants"
                            size="medium"
                            icon="left"
                            iconName="chart_bar"
                            onClick={handleAddStockOptionAndGrants}
                        >
                            Add Options and Grants
                        </Button>
                    </DataEntrySummary>
                </aside>
            </div>
        </aside>
    );
}