import React from "react";
import {EventHandler, NO_OP} from "../../constants/common";
import Input from './Input';
import {camelize} from "../../utils/stringUtils";
import {Label} from "../Label/Label";

type NameInputProps = {
    name: string | null,
    label: string,
    className?: string,
    maxLength?: number,
    readOnly?: boolean,
    required?: boolean,
    onChange?: EventHandler,
    error?: string | null,
    hasInteractions?: boolean,
    whenUserHasInteracted?: () => void,
    forceShowErrors?: boolean
}
export const Name: React.FC<NameInputProps> = ({
                                                   name,
                                                   className = "",
                                                   label,
                                                   readOnly = false,
                                                   required = true,
                                                   maxLength = 256,
                                                   onChange = NO_OP,
                                                   error,
                                                   hasInteractions,
                                                   whenUserHasInteracted,
                                                   forceShowErrors
                                               }) => {
    const camelized = camelize(label);
    const labelId = `${camelized}-label`;
    const inputId = `${camelized}Input`;
    const errorsWillBeShown = !!error && (forceShowErrors || hasInteractions);
    return (
        <div>
            <div className={`parent ${className}`} style={{marginBottom: '8px'}}>
                <Label label={label} required={required} htmlFor={inputId} labelId={labelId}/>
                <Input
                    data-test-id="name-input-id"
                    className={`${camelized}Field profileDetails`}
                    name={camelized}
                    aria-label={camelized}
                    aria-labelledby={labelId}
                    error={errorsWillBeShown ? error : undefined}
                    id={inputId}
                    onChange={onChange}
                    onBlur={whenUserHasInteracted}
                    removeMarginTop
                    readOnly={readOnly}
                    size="medium"
                    type="text"
                    value={name || ""}
                    maxLength={maxLength}
                />
            </div>
        </div>
    );
}
