import {StackedBarChart} from "../../components";
import React, {useEffect, useMemo, useState} from "react";
import {formatCurrency, truncateCurrency} from "../../utils/format";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EMPTY_ASSETS,
    COLOR_EQUITY_COMPENSATIONS,
    COLOR_FUTURE_INFLOWS,
    COLOR_LIABILITIES,
    COLOR_LIFE_INSURANCES,
    COLOR_NT_GREY_050,
    COLOR_PERSONAL_ASSETS
} from "../../constants/colors";
import {useAppSelector} from "../../store/hooks";
import {selectPlanSummary} from "./planSummarySlice";
import classNames from "classnames";
import {StackedBarChartItemType} from "../../components/StackedBarChart/StackedBarChartItem";

export type ExcessAssetsProps = {
    hasAssetsAndGoals: boolean;
    totalGoals: number;
};

type ChartLabelStyles = {
    netAssetsStyle: Position,
    goalsStyle: Position,
    zeroDollarStyle: Position,
    excessLineStyle: Position,
    excessLabelStyle: Position,
    excessValueStyle: Position,
}

type Position = {
    top: string,
    bottom?: string
}

type ShiftClasses = {
    goalsLabel: string;
    netAssetsLabel: string;
    excessAssetsChartContainer: string;
}

const ExcessAssets: React.FC<ExcessAssetsProps> = ({hasAssetsAndGoals, totalGoals}) => {
    const planSummary = useAppSelector(selectPlanSummary)!;
    const [netAssetsChartLabelElement, setNetAssetsChartLabelElement] = useState<HTMLDivElement | null>(null);
    const [goalsChartLabelElement, setGoalsChartLabelElement] = useState<HTMLDivElement | null>(null);
    const [shiftClasses, setShiftClasses] = useState<ShiftClasses>({
        goalsLabel: '',
        netAssetsLabel: '',
        excessAssetsChartContainer: '',
    });

    const netAssets = planSummary.totalGrossValue - planSummary.totalLiabilitiesValue || 0;
    const totalLiabilities = planSummary.totalLiabilitiesValue || 0;
    const excessAssets = planSummary.excessAssets;
    const hasExcessAssets = excessAssets >= 0;

    const excessAssetsPercentage = hasExcessAssets ? (excessAssets / netAssets) * 100 : (Math.abs(excessAssets) / (netAssets + Math.abs(excessAssets))) * 100;
    const excessLinePosition = excessAssetsPercentage.toFixed(2);
    const excessAssetsPosition = (excessAssetsPercentage / 2).toFixed(2);
    const liabilitiesPosition = (totalLiabilities / (netAssets + totalLiabilities) * 100).toFixed(2);
    const excessAssetsStyles: ChartLabelStyles = {
        excessLineStyle: {top: '0%', bottom: `calc(100% - ${excessLinePosition}%)`},
        excessLabelStyle: {top: `calc(${excessAssetsPosition}% - 22px)`},
        netAssetsStyle: {top: '0%'},
        goalsStyle: {top: `${excessLinePosition}%`},
        excessValueStyle: {top: `${excessAssetsPosition}%`},
        zeroDollarStyle: {top: `calc(100% - ${liabilitiesPosition}%)`}
    }
    const excessClassPrefix = `${hasExcessAssets ? 'excess-assets' : 'asset-shortfall'}`;
    const chartData: StackedBarChartItemType[] = hasAssetsAndGoals ? [
        {
            label: "Accounts",
            total: planSummary.standaloneAccountsTotalPresentValue,
            color: COLOR_ASSETS_ACCOUNTS
        },
        {
            label: "Equity Compensations",
            total: planSummary.equityCompensationsTotalPresentValue,
            color: COLOR_EQUITY_COMPENSATIONS
        },
        {
            label: "Investment Program",
            total: planSummary.investmentProgramTotalMarketValue,
            color: COLOR_ASSETS_ACCOUNTS
        },
        {
            label: "General Inflows",
            total: planSummary.generalInflowsTotalPresentValue,
            color: COLOR_FUTURE_INFLOWS
        },
        {
            label: "Social Securities",
            total: planSummary.socialSecurityTotalPresentValue,
            color: COLOR_FUTURE_INFLOWS,
        },
        {
            label: "Personal Assets",
            total: planSummary.personalAssetsTotalPresentValue,
            color: COLOR_PERSONAL_ASSETS
        },
        {
            label: "Life Insurances",
            total: planSummary.inEstateLifeInsuranceFundingGoals,
            color: COLOR_LIFE_INSURANCES
        }
    ] : [
        {
            label: "No Assets",
            total: 1,
            color: COLOR_EMPTY_ASSETS
        }
    ];

    if (planSummary.totalLiabilitiesValue) {
        chartData.push(
            {
                label: "Liabilities",
                total: planSummary.totalLiabilitiesValue,
                color: COLOR_LIABILITIES,
                className: 'liabilities-bar'
            })
    }

    if (!hasExcessAssets) {
        excessAssetsStyles.goalsStyle.top = '0%';
        excessAssetsStyles.netAssetsStyle.top = `${excessLinePosition}%`;
        excessAssetsStyles.excessLineStyle.top = '3px';
        chartData.unshift(
            {
                label: "Asset Shortfall",
                total: Math.abs(excessAssets),
                color: COLOR_NT_GREY_050
            })
    }

    const assetsMessage = useMemo(() => {
        if (!hasAssetsAndGoals) {
            return (
                <>No assets or goals have been captured yet.</>
            );
        } else if (hasExcessAssets) {
            return (
                <>With this plan, your assets exceed your goals by{" "}
                    <b>{formatCurrency(excessAssets)}</b>.</>
            );
        }
        return (
            <>With this plan, there is an asset shortfall of{" "}
                <b>{formatCurrency(Math.abs(excessAssets))}.</b></>
        );
    }, [hasAssetsAndGoals, hasExcessAssets, netAssets, totalGoals]);

    useEffect(() => {
        if (hasAssetsAndGoals) {
            // Calculate if labels should be shifted upward and container expanded to accommodate
            const goalsLabelTop = goalsChartLabelElement?.getBoundingClientRect().top || 0;
            const assetsLabelTop = netAssetsChartLabelElement?.getBoundingClientRect().top || 0;
            const doAssetsAndGoalsLabelsCollide = Math.abs(goalsLabelTop - assetsLabelTop) <= 48;
            const doGoalsExceedAssets = totalGoals > netAssets;
            setShiftClasses({
                goalsLabel: classNames({
                    "excess-assets-shift-upward": doGoalsExceedAssets && doAssetsAndGoalsLabelsCollide
                }),
                netAssetsLabel: classNames({
                    "excess-assets-shift-upward": !doGoalsExceedAssets && doAssetsAndGoalsLabelsCollide
                }),
                excessAssetsChartContainer: classNames(
                    'excess-assets-chart-container',
                    doAssetsAndGoalsLabelsCollide && "excess-assets-extra-padding-top"
                ),
            });
        } else {
            setShiftClasses({
                goalsLabel: '',
                netAssetsLabel: '',
                excessAssetsChartContainer: 'excess-assets-chart-container',
            });
        }
    }, [hasAssetsAndGoals, goalsChartLabelElement, netAssetsChartLabelElement, totalGoals, netAssets]);

    const leftPanelContent = <>
        <div
            data-testid="net-assets-chart-label"
            style={excessAssetsStyles.netAssetsStyle}
            className="excess-assets-chart-label net-assets__label-color"
            ref={(element) => {
                setNetAssetsChartLabelElement(element);
            }}
        >
            <div
                className="excess-assets-chart-line net-assets-chart-line__color"
                data-testid="net-assets-chart-line"
            />
            <div className={shiftClasses.netAssetsLabel}>NET ASSETS</div>
            <div className={shiftClasses.netAssetsLabel}>{formatCurrency(netAssets)}</div>
        </div>
        <div
            data-testid="goals-chart-label"
            style={excessAssetsStyles.goalsStyle}
            className="excess-assets-chart-label goals__label-color"
            ref={(element) => {
                setGoalsChartLabelElement(element);
            }}
        >
            <div
                className="excess-assets-chart-line excess-assets-chart-line__full-width goals-chart-line__color"
                data-testid="goals-chart-line"
            />
            <div className={shiftClasses.goalsLabel}>GOALS</div>
            <div className={shiftClasses.goalsLabel}>{formatCurrency(-1 * totalGoals)}</div>
        </div>
    </>;

    const centerPanelOverlayContent = <>
        <div data-testid="assets-chart-excess-bar"
             className={`assets-chart-excess-bar ${excessClassPrefix}__bar-color`}
             style={excessAssetsStyles.excessLineStyle}/>
        <div
            className={`assets-chart-excess-pill ${excessClassPrefix}__pill-color`}
            style={excessAssetsStyles.excessLabelStyle}
            data-testid="assets-chart-excess-pill"
        >
            {(hasExcessAssets ? '' : '-') + truncateCurrency(Math.abs(excessAssets))}
        </div>
    </>;

    const rightPanelContent = <div
        data-testid="excess-assets-chart-label"
        style={excessAssetsStyles.excessValueStyle}
        className={`excess-assets-chart-label ${excessClassPrefix}__label-color`}
    >
        <div
            className="excess-assets-chart-line"
            data-testid="excess-value-chart-line"
        />
        <div>{hasExcessAssets ? 'EXCESS ASSETS' : 'ASSET SHORTFALL'}</div>
        <div>{formatCurrency(excessAssets)}</div>
    </div>;

    return (
        <div className={"excess-assets-card"}>
            <div className="sub-header">Excess Assets</div>
            <div>
                <span
                    className="excess-assets-value"
                    data-testid="excess-assets-value"
                >{assetsMessage}</span>
            </div>
            <div data-testid="excess-assets-chart-container"
                 className={shiftClasses.excessAssetsChartContainer}>
                <div className={"excess-assets-chart__left-panel"}>{hasAssetsAndGoals ? leftPanelContent : null}</div>
                <div className={"excess-assets-chart__center-panel"}>
                    {hasAssetsAndGoals ? centerPanelOverlayContent : null}
                    <div data-testid="assets-chart-container" className={"assets-chart-container"}>
                        <StackedBarChart
                            className={"assets-chart"}
                            showLabels={false}
                            chartWidth={220}
                            data={chartData}
                        />
                    </div>
                </div>
                <div className={"excess-assets-chart__right-panel"}>{hasAssetsAndGoals ? rightPanelContent : null}</div>
            </div>
        </div>
    );
};

export default ExcessAssets;
