import {NavLink,} from "react-router-dom";
import classNames from "classnames";
import {ConfirmExitMeetingModal} from "src/ClientManagement/Meeting/MeetingActions";
import MeetingToasts from "src/ClientManagement/Meeting/Toast/MeetingToasts";
import AppHeader from "src/components/Header/AppHeader";
import PdfPreviewPane from "src/components/QuickSlides/PdfPreviewPane";
import LoadingIndicator from "src/pages/LoadingIndicator";
import ProfileControls from "../ProfileControls";
import ClientProfileHeader from "../ClientProfileHeader";
import {LinkProps} from "src/models/routeData/RouteParamTypes";
import {ResourcesState} from "src/Resources/resourcesSlice";
import {
    Meeting,
    MeetingContentCanvas,
    MeetingContentDimensions,
    MeetingContentDOM,
    MeetingContentScrollPositions,
    MeetingStatus,
    MultiPartMeetingContent
} from "src/ClientManagement/Meeting/Meeting";
import Routes from "./Routes";
import MeetingInfoModal from "../../Meeting/Modal/MeetingInfoModal";
import React, {useEffect, useRef, useState} from "react";
import {MeetingContainerSchema, useRelayContext} from "../../Meeting/Relay/types/RelayContext";
import useMeetingUtils from "../../Meeting/useMeetingUtils";
import {getRefHeight, getRefWidth} from "../../../utils/refUtils";
import {useAppSelector} from "../../../store/hooks";
import {selectMeetingModalVisibility} from "../../Meeting/meetingSlice";
import usePrevious from "../../../utils/usePrevious";
import {splitMultiPartMeetingContent} from "../../Meeting/multiPartMeetingContentUtils";
import {
    getScrollHeight,
    getScrollLeft,
    getScrollTop,
    getScrollWidth
} from "../../../components/ScrollableContainer/ScrollableContainerUtils";

const MAX_MESSAGE_SIZE_BYTES = 250_000;

export type ClientProfileProps = {
    history: any;
    onLogoClick: () => void;
    navigationLinks: LinkProps[];
    documentInfo: ResourcesState | undefined;
    showMeetingControls: boolean;
    isMeetingActive: string;
    meeting: Meeting;
    isLoading: boolean;
    isConfirmExitMeetingModalOpen: boolean;
    handleCancel: () => void;
}

const ClientProfile = ({
                           history,
                           onLogoClick,
                           navigationLinks,
                           documentInfo,
                           showMeetingControls,
                           isMeetingActive,
                           meeting,
                           isLoading,
                           isConfirmExitMeetingModalOpen,
                           handleCancel
                       }: ClientProfileProps) => {
    const {sharedObjects} = useRelayContext();
    const {isCurrentUserPresenting} = useMeetingUtils();
    const presentationViewRef = useRef<HTMLDivElement | null>(null);
    const [meetingContentDimensions, setMeetingContentDimensions] = useState<MeetingContentDimensions>({
        height: `0px`,
        width: `0px`,
    })
    const [meetingContentDOM, setMeetingContentDOM] = useState<MeetingContentDOM>('');
    const [meetingContentCanvasRecords, setMeetingContentCanvasRecords] = useState<MeetingContentCanvas>({});
    const [meetingContentScrollPositions, setMeetingContentScrollPositions] = useState<MeetingContentScrollPositions>({});
    const timerId = useRef<ReturnType<typeof setInterval> | undefined>()

    const isSyncingPresentationView: boolean = !!sharedObjects
        && !!presentationViewRef.current
        && isCurrentUserPresenting
        && meeting.status === MeetingStatus.STARTED;

    const modalVisibility = useAppSelector(selectMeetingModalVisibility);
    const previousSyncingPresentationView = usePrevious(isSyncingPresentationView);

    const [forceRedraw, setForceRedraw] = useState<boolean>(false);

    useEffect(() => {
        setForceRedraw(previousSyncingPresentationView !== isSyncingPresentationView);
    }, [previousSyncingPresentationView, isSyncingPresentationView]);

    useEffect(() => {
        if (sharedObjects) {
            sharedObjects.meetingPortalParticipantJoinDDS.on('valueChanged', () => {
                setForceRedraw(true);
            });
        }
    }, [sharedObjects]);

    useEffect(() => {
        const clearTimer = () => {
            if (timerId.current) {
                clearInterval(timerId.current);
            }
        };

        if (!isSyncingPresentationView) {
            clearTimer();
            return;
        }

        timerId.current = setInterval(() => {
            if (presentationViewRef.current && sharedObjects) {
                checkMeetingContentDimensions(presentationViewRef, forceRedraw, meetingContentDimensions, sharedObjects, (dimensions) => {
                    setMeetingContentDimensions(dimensions);
                });

                checkMeetingContentDom(presentationViewRef, forceRedraw, meetingContentDOM, sharedObjects, (content) => {
                    setMeetingContentDOM(content);
                });

                checkMeetingContentCanvas(presentationViewRef, forceRedraw, meetingContentCanvasRecords, sharedObjects, (canvasData) => {
                    setMeetingContentCanvasRecords(canvasData);
                });

                checkMeetingContentScrollPositions(presentationViewRef, forceRedraw, meetingContentScrollPositions, sharedObjects, (scrollPositions) => {
                    setMeetingContentScrollPositions(scrollPositions);
                });

                setForceRedraw(false);
            }
        }, 500);

        return () => {
            clearTimer();
        };
    }, [
        // Data structures referenced for diffing
        meetingContentDOM,
        meetingContentCanvasRecords,
        meetingContentDimensions,
        // Control when to sync
        isSyncingPresentationView,
        forceRedraw,
    ]);

    return (
        <div className="app-viewport app-viewport--in-meeting">
            <div className="host-viewport">
                <AppHeader
                    headerToolbarAlignment="center"
                    history={history}
                    HeaderToolbar={ClientProfileHeader}
                    LinkRenderer={NavLink}
                    onLogoClick={onLogoClick}
                    links={navigationLinks}
                    navigationStyle="drawer"
                    theme="none"
                    showMeetingControl={true}
                >
                    {documentInfo?.currentPage && documentInfo?.pdfUrl && (
                        <PdfPreviewPane
                            currentPage={documentInfo.currentPage}
                            pdf={documentInfo.pdfUrl}
                            redirectUrl={documentInfo.redirectUrl}
                        />
                    )}
                </AppHeader>
            </div>
            {!showMeetingControls && <ProfileControls/>}
            <div
                data-testid="presentation-viewport"
                className={classNames(
                    "presentation-viewport",
                    "presentation-viewport--presenter",
                    {
                        "presentation-viewport--meeting-border meeting-border--red":
                            showMeetingControls &&
                            isMeetingActive &&
                            meeting?.status !== MeetingStatus.STARTED &&
                            meeting?.status !== MeetingStatus.PAUSED &&
                            meeting?.status !== MeetingStatus.ENDED,
                    },
                    {
                        "presentation-viewport--meeting-border meeting-border--orange":
                            showMeetingControls &&
                            isMeetingActive &&
                            meeting?.status === MeetingStatus.PAUSED,
                    }
                )}
            >
                <div className="presentation-view" ref={presentationViewRef}>
                    {isLoading ? <LoadingIndicator/> : <Routes/>}
                </div>
            </div>
            <MeetingToasts/>

            {modalVisibility?.meetingInfo && <MeetingInfoModal/>}
            {isConfirmExitMeetingModalOpen && (
                <ConfirmExitMeetingModal
                    isOpen={isConfirmExitMeetingModalOpen}
                    handleCancel={handleCancel}
                    path={"/"}
                />
            )}
        </div>
    );
}

const checkMeetingContentDimensions = (
    presentationViewRef: React.MutableRefObject<HTMLDivElement | null>,
    forceRedraw: boolean,
    meetingContentDimensions: MeetingContentDimensions,
    sharedObjects: MeetingContainerSchema,
    onDimensionsChanged: (dimensions: MeetingContentDimensions) => void,
) => {
    const dimensions = {
        height: `${getRefHeight(presentationViewRef.current)}px`,
        width: `${getRefWidth(presentationViewRef.current)}px`,
    };
    if (forceRedraw || (dimensions.height !== meetingContentDimensions.height || dimensions.width !== meetingContentDimensions.width)) {
        sharedObjects.domContentDDS.set('meetingContentDimensions', dimensions);
        onDimensionsChanged(dimensions);
    }
}

const checkMeetingContentDom = (
    presentationViewRef: React.MutableRefObject<HTMLDivElement | null>,
    forceRedraw: boolean,
    meetingContentDOM: string,
    sharedObjects: MeetingContainerSchema,
    onDomContentChanged: (content: string) => void,
) => {
    if (presentationViewRef.current) {
        const domContent = presentationViewRef.current.innerHTML;
        if (forceRedraw || domContent !== meetingContentDOM) {
            splitMultiPartMeetingContent(domContent, MAX_MESSAGE_SIZE_BYTES, (multiPartContent: MultiPartMeetingContent) => {
                setTimeout(() => {
                    sharedObjects.domContentDDS.set(
                        'meetingContentDOM',
                        multiPartContent
                    );
                }, multiPartContent.part * 50);
            });
            onDomContentChanged(domContent);
        }
    }
}

const checkMeetingContentCanvas = (
    presentationViewRef: React.MutableRefObject<HTMLDivElement | null>,
    forceRedraw: boolean,
    meetingContentCanvasRecords: MeetingContentCanvas,
    sharedObjects: MeetingContainerSchema,
    onCanvasContentChanged: (canvasData: MeetingContentCanvas) => void,
) => {
    if (presentationViewRef.current) {
        let hasCanvasUpdate = false;
        const canvasData: MeetingContentCanvas = {};
        presentationViewRef.current.querySelectorAll('canvas').forEach((targetElement) => {
            const querySelector = getPathToPresentationViewElement(targetElement);
            const canvasDataUrl = targetElement.toDataURL();

            if (meetingContentCanvasRecords[querySelector] !== canvasDataUrl) {
                hasCanvasUpdate = true;
            }
            canvasData[querySelector] = canvasDataUrl;
        });
        onCanvasContentChanged(canvasData);

        if (forceRedraw || hasCanvasUpdate) {
            for (let [querySelector, canvasDataURL] of Object.entries(canvasData)) {
                splitMultiPartMeetingContent(canvasDataURL, MAX_MESSAGE_SIZE_BYTES, (multiPartContent: MultiPartMeetingContent) => {
                    setTimeout(() => {
                        sharedObjects.canvasContentDDS.set(
                            querySelector,
                            multiPartContent
                        );
                    }, multiPartContent.part * 50);
                });
            }
        }
    }
}

const checkMeetingContentScrollPositions = (
    presentationViewRef: React.MutableRefObject<HTMLDivElement | null>,
    forceRedraw: boolean,
    meetingContentScrollPositions: MeetingContentScrollPositions,
    sharedObjects: MeetingContainerSchema,
    onScrollPositionsChanged: (scrollPositions: MeetingContentScrollPositions) => void,
) => {
    if (presentationViewRef.current) {
        let hasScrollPositionUpdate = false;
        const scrollPositions: MeetingContentScrollPositions = {};
        presentationViewRef.current.querySelectorAll('[data-syncscrollposition]').forEach((targetElement) => {
            const querySelector = getPathToPresentationViewElement(targetElement);
            const scrollWidth = getScrollWidth(targetElement);
            const scrollHeight = getScrollHeight(targetElement);
            const scrollState = {
                horizontalScrollPercentage: scrollWidth ? (getScrollLeft(targetElement) / scrollWidth) : 0,
                verticalScrollPercentage: scrollHeight ? (getScrollTop(targetElement) / scrollHeight) : 0,
            };

            const existingScrollState = meetingContentScrollPositions[querySelector];
            if (!existingScrollState
                || existingScrollState.horizontalScrollPercentage !== scrollState.horizontalScrollPercentage
                || existingScrollState.verticalScrollPercentage !== scrollState.verticalScrollPercentage) {
                hasScrollPositionUpdate = true;
            }
            scrollPositions[querySelector] = scrollState;
        });
        onScrollPositionsChanged(scrollPositions);

        if (forceRedraw || hasScrollPositionUpdate) {
            sharedObjects.domContentDDS.set('meetingContentScrollPositions', scrollPositions);
        }
    }
}

// TODO revisit testing strategy
const getPathToPresentationViewElement = (element: Element): string => {
    if (element.className.includes('presentation-view')) {
        return '';
    }
    if (element.id) {
        return '#' + element.id;
    }
    if (element === document.body) {
        return element.tagName;
    }
    if (element.parentElement) {
        let ix = 0;
        const siblings = element.parentElement.children;
        for (let i = 0; i < siblings.length; i++) {
            const sibling = siblings[i];
            if (sibling === element) {
                const parentPath = getPathToPresentationViewElement(element.parentElement);
                return (parentPath ? parentPath + ' ' : '') + element.tagName + ':nth-child(' + (ix + 1) + ')';
            }
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
                ix++;
            }
        }
    }

    return element.tagName;
}

export default ClientProfile;