import React from "react";
import {PersonalAsset} from "../../models/PersonalAsset";
import {LiabilitiesPopover} from "../Liabilities/LiabilitiesPopover";
import {shortenString} from "../../common/textUtils";

export const AssetSummaryPersonalAssetDetails = ({personalAsset}: { personalAsset: PersonalAsset }) => {
    const liabilitySummaries = personalAsset.personalLiabilities;
    return (
        <span role="cell" className="grid-span-4 paddingleft-xxl textalign-left display-flex">
            <div className="cell-with-popover">
                <span className="cell-text-content paddingright-sm">{shortenString(personalAsset.description, 23)}</span>
                {
                    liabilitySummaries.length > 0 ?
                        <LiabilitiesPopover id={personalAsset.id}
                                            liabilitySummaries={liabilitySummaries}/> :
                        <></>
                }
            </div>
        </span>
    );
};
