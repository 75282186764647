import React from "react";
import StockOptionTableRow from "./StockOptionTableRow";
import {StockOption} from "../../models/EquityCompensation";
import {DropdownItem} from "../../../components";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";

export type StockOptionTableProps = {
    stockOptions: StockOption[],
    deleteStockOption: (stockIndex: number) => void;
    updateStockOption: (stockOption: StockOption, stockIndex: number, refreshCalculations: boolean) => void;
    handleEditVesting: (stockIndex: number) => void;
    isColumnsHidden: boolean,
    setIsColumnHidden: (k: boolean) => void;
}

const StockOptionTable: React.FC<StockOptionTableProps> = ({
                                                               stockOptions,
                                                               updateStockOption,
                                                               handleEditVesting,
                                                               deleteStockOption,
                                                               isColumnsHidden,
                                                               setIsColumnHidden,
                                                           }) => {
    return <div role="table" className="stock-options-grid stock-options-grid-table" aria-label="Stock Options Table">
        <StockOptionsTableHeader isColumnsHidden={isColumnsHidden} setIsColumnHidden={setIsColumnHidden}/>
        {stockOptions.map((stockOption, index) =>
            <StockOptionTableRow
                key={index}
                index={index}
                stockOption={stockOption}
                updateStockOption={updateStockOption}
                handleEditVesting={handleEditVesting}
                deleteStockOption={deleteStockOption}
                isColumnsHidden={isColumnsHidden}
            />
        )}
    </div>;
}

function StockOptionsTableHeader({isColumnsHidden, setIsColumnHidden}: {isColumnsHidden:boolean, setIsColumnHidden: (k: boolean) => void}) {
    return <div role="row" className={`${isColumnsHidden? 'stock-options-grid-column-hidden': 'stock-options-grid'} stock-options-grid-table-header`} tabIndex={-1}>
        <span role="columnheader" className="textalign-left">Grant Description/ID</span>
        <span role="columnheader" className="textalign-left">Grant date</span>
        <span role="columnheader" className="textalign-left">Type</span>
        <span role="columnheader" className="textalign-left">Expiration Date</span>
        <span role="columnheader" className="textalign-right">Exercise Price</span>
        <span role="columnheader" className="textalign-right">Shares Granted</span>
        <span role="columnheader" className="textalign-right">Shares Vested</span>
        <span role="columnheader" className="textalign-right">Shares Exercised</span>
        <span role="columnheader" className="textalign-right">Shares Unvested</span>
        {!isColumnsHidden && (<><span role="columnheader" className="textalign-right">Gross Potential Value</span>
        <span role="columnheader" className="textalign-right">Gross Exer. Value</span>
        <span role="columnheader" className="textalign-right">After Tax Potential Value</span></>)}
        <span role="columnheader" className="textalign-right">After Tax Unvested Mkt. Value</span>
        <span role="columnheader" className="textalign-right">After Tax Exer. Value</span>
        <TableActionDropdownMenu
            ariaLabel={`Stock Grant Menu`}>
            {isColumnsHidden ?
                <DropdownItem
                    aria-label="Show Additional Values"
                    itemText="Show Additional Values"
                    value="Show Additional Values"
                    onClick={() => setIsColumnHidden(false)}
                />
                :
                <DropdownItem
                    aria-label="Hide Additional Values"
                    itemText="Hide Additional Values"
                    value="Hide Additional Values"
                    onClick={() => setIsColumnHidden(true)}
                >
                </DropdownItem>}
        </TableActionDropdownMenu>
    </div>;
}

export default StockOptionTable;