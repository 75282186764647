import React from "react";
import {Dropdown, DropdownItem} from "../../components";
import {MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";

type BeneficiaryDropdownProps = {
    value: string | null,
    members: MemberGroupMember[],
    onChange: (selectedMemberId: string) => void,
    className?: string
};

export function BeneficiaryDropdown({members, onChange, value, className, ...rest}: BeneficiaryDropdownProps) {
    const dropdownItems = members.map(toDropdownItem);
    return <Dropdown
        size="medium"
        aria-label={`Life insurance beneficiary`}
        value={value ? value : null}
        onChange={(data: { value: string }) => onChange(data.value)}
        className={className}
        {...rest}
    >
        {dropdownItems}
    </Dropdown>;
}

function toDropdownItem(member: MemberGroupMember) {
    let itemText = '';
    if (member.lastName !== '') {
        itemText = `${member.firstName} ${member.lastName} (Age ${member.age})`;
    } else {
        itemText = `${member.firstName}`;
    }
    return <DropdownItem itemText={itemText} value={member.id} key={member.id}/>;
}
