import React from "react";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {AccordionItem, Icon} from "../../components";
import {formatCurrency} from "../../utils/format";
import {BeneficiaryGoals} from "../models/GoalModelType";
import BeneficiaryGoalsTable from "../components/BeneficiaryGoalsTable";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import classNames from "classnames";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";

type  BeneficiaryGoalsAccordionItemProps = {
    memberSelected: MemberType,
    beneficiaryGoals: BeneficiaryGoals,
    investorGroup: InvestorGroupType,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onApplyFamilyAssets: (goal: FamilyGoalType, assetAmountToApply: number) => void,
    hasFamilyAssets: boolean
    familyAssets: FamilyAssets,
    availableAssets: number
}

function getTotalFamilyAssets(beneficiaryGoals: BeneficiaryGoals) {
    return Object.values(beneficiaryGoals.goals).reduce((prev, curr) => prev + (curr.familyAssetsAmount || 0), 0)
}

function getTotalMyResponsibility(beneficiaryGoals: BeneficiaryGoals) {
    return Object.values(beneficiaryGoals.goals).reduce((prev, curr) => {
        const myResponsibility = curr.calculatedFields?.presentValue - (curr.familyAssetsAmount || 0)
        return prev + (myResponsibility > 0 ? myResponsibility : 0);
    }, 0)
}

export const BeneficiaryGoalsAccordionItem = ({
                                                  memberSelected,
                                                  beneficiaryGoals,
                                                  investorGroup,
                                                  onEditGoal,
                                                  onApplyFamilyAssets,
                                                  hasFamilyAssets,
                                                  familyAssets,
                                                  availableAssets
                                              }: BeneficiaryGoalsAccordionItemProps) => (
    <AccordionItem
        uuid="FamilyGoal"
        accentColor="#FFC24B"
        expandable={false}
        HeaderComponent={() => {
            return <div className={classNames("accordion-header-content family-goals-accordion-header-grid")}
                        data-testid="family-goal-accordion-item">
                 <span className={`paddingleft-md display-flex align-items-center grid-span-3`}>
                     <span className="paddingleft-md">
                       <div className="h4 fontweight-normal margin-none">{`${memberSelected.firstName}'s Goals`}</div>
                     </span>
                 </span>
                <span className="font-lg textalign-right">{formatCurrency(beneficiaryGoals.totalPresentValue)}</span>
                <span
                    className="font-lg textalign-right">{formatCurrency(getTotalFamilyAssets(beneficiaryGoals))}</span>
                <span className="font-lg textalign-right">{formatCurrency(getTotalMyResponsibility(beneficiaryGoals))}</span>
            </div>
        }}
    >
        <BeneficiaryGoalsTable
            beneficiaryGoals={Object.values(beneficiaryGoals.goals)}
            investorGroup={investorGroup}
            onEditGoal={onEditGoal}
            onApplyFamilyAssets={onApplyFamilyAssets}
            hasFamilyAssets={hasFamilyAssets}
            showApplyFamilyAssets={true}
            familyAssets={familyAssets}
            availableAssets={availableAssets}
        />
    </AccordionItem>
)

