import React, {useState} from "react";
import {Checkbox} from "xps-react";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";

type ConfirmReviewDisclosureProps = {
    isOpen: boolean;
    isDisclosureReviewed: boolean;
    onCancel: () => void,
    onAcknowledge: () => void,
}

const ConfirmReviewDisclosure = ({
                                     isOpen,
                                     isDisclosureReviewed,
                                     onCancel,
                                     onAcknowledge,
                                 }: ConfirmReviewDisclosureProps) => {

    const [isChecked, setIsChecked] = useState(isDisclosureReviewed);
    const [showWarning, setShowWarning] = useState(false);
    const handleAcknowledge = () => {
        if (isChecked) {
            onAcknowledge();
        } else {
            setShowWarning(true);
        }
    }
    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={isOpen}
            headerText="Discretionary Distribution Disclosure"
            size="large"
            buttons={[
                {
                    text: 'Cancel',
                    onClick: onCancel,
                },
                {
                    text: 'Acknowledge Disclosure',
                    onClick: handleAcknowledge,
                }
            ]}
        >
            <div className="display-flex flex-column">
                <div className="font-sm">
                    This amount represents historical discretionary distributions from trusts for which Northern Trust
                    serves as trustee. Future distributions are subject to Northern Trust's discretionary distribution
                    approval process. Historical figures have been incorporated for illustrative purposes only and
                    cannot be
                    relied upon as a commitment by Northern Trust to provide discretionary distributions from a trust in
                    future years or as a measure of investment performance.
                </div>
                <br/>
                <div>
                    <Checkbox
                        name="ackDisclosureCheckBox"
                        aria-label="I acknowledge that I have reviewed this information with a client."
                        value="I acknowledge that I have reviewed this information with a client."
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                    />
                </div>
                {showWarning &&
                    <div className="font-sm color-text--error paddingleft-20">
                        You must acknowledge this disclosure with a client to proceed.
                    </div>
                }
            </div>
        </ModalWrapper>
    );
}

export default ConfirmReviewDisclosure;