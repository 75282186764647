import {ReleaseTogglesType} from "./ReleaseToggles";

export const RELEASE_TOGGLE_ALL_ON: ReleaseTogglesType = {
    enableLifestyleGoalFunding: true,
    enableGoalsPrioritization: true,
    enableHolisticAdvice: true,
    enableLifeInsuranceAsset: true,
    enableEquityCompensationAsset: true,
    enableCurrentNetWorth: true,
    enableReviseAssetAllocation: true,
    enableToonimo: true,
    enableProfileAssistant: true,
    enableMultipleSpendingPeriods: true,
    enableInflowReserve: true,
    enableQuickSlides: true,
    enableActivePortfolioReserve: true,
    enableSendReportToDCH: true,
    enableDetailedAssetSummaryReport: true,
    enableDetailedGoalsSummaryReport: true,
    enableGoalsSummaryReport: true,
    enableWealthTransferMenu: true,
    enableFutureValueOfExcessAssets: true,
    enableDetailedCurrentVsProposedReport: true,
    enabledNetWorthOverTime: true,
    enableMeetingManagement: true,
    enableDetailedAssetRelianceReport: true,
    enableClientDocument: true,
    enableDetailedCurrentNetWorthReport:true,
    enableTrustInflows: true,
    enableProposalApproval: true

}
export const RELEASE_TOGGLE_ALL_OFF: ReleaseTogglesType = {
    enableLifestyleGoalFunding: false,
    enableGoalsPrioritization: false,
    enableHolisticAdvice: false,
    enableLifeInsuranceAsset: false,
    enableEquityCompensationAsset: false,
    enableCurrentNetWorth: false,
    enableReviseAssetAllocation: false,
    enableToonimo: false,
    enableMultipleSpendingPeriods: false,
    enableProfileAssistant: false,
    enableInflowReserve: false,
    enableQuickSlides: false,
    enableActivePortfolioReserve: false,
    enableSendReportToDCH: false,
    enableDetailedAssetSummaryReport: false,
    enableDetailedGoalsSummaryReport: false,
    enableWealthTransferMenu: false,
    enableFutureValueOfExcessAssets: false,
    enableDetailedCurrentVsProposedReport: false,
    enableMeetingManagement: false,
    enableDetailedAssetRelianceReport: false,
    enableClientDocument: false,
    enableTrustInflows: false,
    enableProposalApproval: false
}
