import {formatCurrency} from "../../../utils/format";
import {Accordion, AccordionItem, BarChartSidebar, DropdownItem, PageActionMenu} from "../../../components";
import React, {useEffect, useState} from "react";
import {AccordionItemListHeader} from "../../../components/Accordion";
import {GroupedTable, GroupedTableData} from "../../../components/Table/GroupedTable";
import classNames from "classnames";
import {DropdownGroup} from "xps-react";
import {selectReleaseToggles} from "src/ReleaseToggles/releaseTogglesSlice";
import {AccordionItemPresentValueHeader} from "../../../components/Accordion/AccordionItemPresentValueHeader";
import {
    EstateSummary,
    EstateType,
    MemberBeneficiary,
    PhilanthropicBeneficiary, TaxSummary
} from "../../../WealthTransfer/models/api";
import {COLOR_ESTATE_BENEFICIARIES, COLOR_TAXES} from "../../../constants/colors";
import {EstateTaxRunningTotal} from "../../../WealthTransfer/components/EstateTaxRunningTotal";
import {useAppSelector} from "../../../store/hooks";
import {PAGE_CONSTANTS, splitBeneficiaryData} from "./WealthTransferUtils";
import PrintViewWrapper from "../PrintViewWrapper";
import {NO_OP} from "../../../constants/common";


type WealthTransferReportViewPlanningProps = {
    profileName: string,
    estateSummary: EstateSummary,
    estateType: EstateType,
    displayName: string
}

function EstimatedTaxReport(props: {
    showContinuedLabel: boolean;
    value: number, showStateTax: boolean, showFedTax: boolean, estateSummary: EstateSummary, stateTaxSummary: TaxSummary, showBothTaxes: boolean, federalTaxSummary: TaxSummary, stateEstateTaxDeduction: { amount: number; label: string }[]
}) {
    return <>{props.showBothTaxes &&
        <Accordion expanded={["estate-summary-estimated-estate-tax"]}>
        <AccordionItem
            uuid="estate-summary-estimated-estate-tax"
            primaryText={!props.showContinuedLabel ? "Estimated Estate Tax" : "Estimated Estate Tax (continued)"}
            accentColor={COLOR_TAXES}
            rightAlignedContent={<AccordionItemPresentValueHeader
                value={props.value}/>}
        >
            <div role="table" className="grouped-table-grid grouped-table">
                <span role="columnheader" className="grid-span-4 justify-content-start">Type</span>
                <span role="columnheader" className="justify-content-end">Present Value</span>
            </div>

            {(props.showBothTaxes && props.showStateTax) && <EstateTaxRunningTotal
                taxType={"State"}
                estateSummary={props.estateSummary}
                effectiveTaxRate={props.stateTaxSummary.effectiveTaxRate}
                estimatedEstateTax={props.stateTaxSummary.estimatedEstateTax}
                additionalExemptionsOrDeductions={[{
                    label: "State Exemption",
                    amount: props.stateTaxSummary.totalAvailableExemption
                }]}
                taxableEstateValue={props.stateTaxSummary.taxableEstateValue}
            />}
            {(props.showBothTaxes && props.showFedTax) &&
                <EstateTaxRunningTotal
                    taxType={"Federal"}
                    estateSummary={props.estateSummary}
                    effectiveTaxRate={props.federalTaxSummary.effectiveTaxRate}
                    estimatedEstateTax={props.federalTaxSummary.estimatedEstateTax}
                    additionalExemptionsOrDeductions={props.stateEstateTaxDeduction.concat([{
                        label: "Available Exemption",
                        amount: props.federalTaxSummary.totalAvailableExemption
                    }])}
                    taxableEstateValue={props.federalTaxSummary.taxableEstateValue}
                />}
        </AccordionItem>
    </Accordion>
}</>;
}

const WealthTransferReportView = ({
                                      profileName,
                                      estateSummary,
                                      estateType,
                                      displayName
                                  }: WealthTransferReportViewPlanningProps) => {
    const MINIMUM_SPACE_REQUIRED_FOR_STATE_TAX = 500;
    const releaseToggles = useAppSelector(selectReleaseToggles);

    const doBeneficiariesExist = estateSummary.memberBeneficiaries.length > 0 || estateSummary.philanthropicBeneficiaries.length > 0;
    const stateEstateTaxDeduction = estateSummary.stateTaxSummary.estimatedEstateTax > 0 ? [{
        label: "State Estate Tax Deduction", amount: estateSummary.stateTaxSummary.estimatedEstateTax
    }] : [];

    const [groupedTablePages, setGroupedTablePages] = useState<GroupedTableData[][]>([])
    const [showStateAndFedTaxInNewPage, setShowStateAndFedTaxInNewPage] = useState<boolean>(false);
    const [showOnlyStateTaxInLastPage, setShowOnlyStateTaxInLastPage] = useState<boolean>(false);
    const [showStateAndFedTaxInLastPage, setShowStateAndFedTaxInLastPage] = useState<boolean>(false);

    function createIndivdualData(memberPageData: any[]) {
        const individualBeneficiaries = memberPageData.map(memberBeneficiary => ({
            itemName: `${memberBeneficiary.name} (Age ${memberBeneficiary.age})`,
            itemValue: memberBeneficiary.presentValue
        }));

        return {
            groupName: "Individual",
            groupId: '',
            items: individualBeneficiaries,
            groupValue: individualBeneficiaries.reduce((previousValue: number, currentValue) => {
                return previousValue + currentValue.itemValue;
            }, 0)
        };
    }

    function createPhilanthropicData(philanthropicPageData: any[]) {
        const philanthropicBeneficiaries = philanthropicPageData.map(beneficiary => ({
            itemName: beneficiary.philanthropyName,
            itemValue: beneficiary.value
        }));

        return {
            groupName: "Philanthropy",
            groupId: '',
            items: philanthropicBeneficiaries,
            groupValue: philanthropicBeneficiaries.reduce((previousValue: number, currentValue) => {
                return previousValue + currentValue.itemValue;
            }, 0)
        };
    }

    function isMergeRequired(individualPageData: Array<any[]>, philanthropicPageData: Array<any[]>, MAX_ROWCOUNT_IN_PAGE: number): boolean {
        return (individualPageData[individualPageData.length - 1].length + philanthropicPageData[0].length) <= MAX_ROWCOUNT_IN_PAGE;
    }

    function mergeIndividualAndPhilanthropicPagesToEstatesummary(individualPageData: Array<any[]>, philanthropicPageData: Array<any[]>) {
        let tempgroupedTablePages: GroupedTableData[][] = []

        for (const element of individualPageData) {
            const individualGroupTableData = createIndivdualData(element);
            tempgroupedTablePages.push([individualGroupTableData]);
        }
        const MAX_ROWCOUNT_IN_PAGE = 15;
        let startIndexForPhilanthropicData = 0;
        if (isMergeRequired(individualPageData, philanthropicPageData, MAX_ROWCOUNT_IN_PAGE)) {
            startIndexForPhilanthropicData = 1;
            const philanthropicGroupTableData = createPhilanthropicData(philanthropicPageData[0]);
            tempgroupedTablePages[individualPageData.length - 1] = [...tempgroupedTablePages[individualPageData.length - 1], philanthropicGroupTableData];
        }

        for (startIndexForPhilanthropicData; startIndexForPhilanthropicData < philanthropicPageData.length; startIndexForPhilanthropicData++) {
            const philanthropicGroupTableData = createPhilanthropicData(philanthropicPageData[startIndexForPhilanthropicData]);
            tempgroupedTablePages.push([philanthropicGroupTableData]);
        }

        return tempgroupedTablePages;
    }

    useEffect(() => {
        if (groupedTablePages.length === 0) {
            let memberBeneficiaryArray: Array<MemberBeneficiary[]> = [];
            let tempMemberBeneficiaries = estateSummary.memberBeneficiaries;
            let memberPageData = splitBeneficiaryData(tempMemberBeneficiaries, memberBeneficiaryArray, 0);
            let philanthropicArray: Array<PhilanthropicBeneficiary[]> = [];
            let tempPhilanthropicBeneficiaries = estateSummary.philanthropicBeneficiaries;
            let philanthropicPageData = splitBeneficiaryData(tempPhilanthropicBeneficiaries, philanthropicArray, memberPageData.remainingSpace);
            let tempgroupedTablePages: GroupedTableData[][] =[];
            if (memberPageData.beneficiaryArrayPages.length > 0 && philanthropicPageData.beneficiaryArrayPages.length > 0) {
                tempgroupedTablePages = mergeIndividualAndPhilanthropicPagesToEstatesummary(memberPageData.beneficiaryArrayPages, philanthropicPageData.beneficiaryArrayPages);
            } else {
                if (memberPageData.beneficiaryArrayPages.length > 0 && philanthropicPageData.beneficiaryArrayPages.length === 0) {
                    memberPageData.beneficiaryArrayPages.forEach((element)=> {
                        const individualGroupTableData = createIndivdualData(element);
                        tempgroupedTablePages.push([individualGroupTableData]);
                    })
                }
                if (philanthropicPageData.beneficiaryArrayPages.length > 0 && memberPageData.beneficiaryArrayPages.length === 0) {
                    philanthropicPageData.beneficiaryArrayPages.forEach(
                        (element)=> {
                            const philanthropicGroupTableData = createPhilanthropicData(element);
                            tempgroupedTablePages.push([philanthropicGroupTableData]);
                        }
                    )
                }
            }
            setGroupedTablePages([...tempgroupedTablePages]);

            const availableSpaceInLastPage = philanthropicPageData.remainingSpace;

            if (availableSpaceInLastPage > PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT || availableSpaceInLastPage < MINIMUM_SPACE_REQUIRED_FOR_STATE_TAX || availableSpaceInLastPage === 0) {
                setShowStateAndFedTaxInNewPage(true);
            } else if (availableSpaceInLastPage > MINIMUM_SPACE_REQUIRED_FOR_STATE_TAX && availableSpaceInLastPage < PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT) {
                if (estateSummary.stateTaxSummary.estimatedEstateTax === 0 && availableSpaceInLastPage > (MINIMUM_SPACE_REQUIRED_FOR_STATE_TAX+100)) {
                    setShowStateAndFedTaxInLastPage(true);
                    setShowStateAndFedTaxInNewPage(false);
                } else {
                    setShowOnlyStateTaxInLastPage(true);
                }
            }
        }
    }, [])

    function isLastPage(pageIndex: number): boolean {
        return pageIndex === (groupedTablePages.length - 1);
    }

    return <>  {groupedTablePages.map((groupedTablePage, pageIndex) => (
        <PrintViewWrapper pageNumber={pageIndex} displayName={displayName} key={pageIndex}>
            <article className="summary-page layout-split-left">
                <BarChartSidebar
                    data={[{
                        label: 'Beneficiaries',
                        className: '',
                        color: '#66AA93',
                        total: estateSummary.beneficiaryTotalValue,
                    }, {
                        label: 'Estimated Estate Tax',
                        className: '',
                        color: '#FFDF77',
                        total: estateSummary.estimatedEstateTax,
                    }]}
                    noDataText=""
                    displayName={profileName}
                    title='Wealth Transfer'
                    subtitle='Explore opportunities to transfer your wealth, fulfill goals, and reduce your estate tax exposure.'>

                    <div>
                <span
                    className="condensed-subtitle margintop-xxxl">{estateType === EstateType.CURRENT ? "CURRENT ESTATE VALUE" : "FUTURE ESTATE VALUE"}</span>
                        <div className="h1 margintop-sm marginbottom-xxxl"
                             aria-label={'Current Estate Value'}>{formatCurrency(estateSummary.estateValue)}</div>
                    </div>
                </BarChartSidebar>
                <section>
                    <div className="summary-page-content">
                        {pageIndex === 0 && <div className="section-header">
                            <WealthTransferReportHeader
                                atAge={estateSummary.atAge}
                                estateType={estateType}
                                estateValue={estateSummary.estateValue}
                                beneficiaryTotalValue={estateSummary.beneficiaryTotalValue}
                                estimatedEstateTax={estateSummary.estimatedEstateTax}
                                enableWealthTransferMenu={releaseToggles?.enableWealthTransferMenu ?? false}/>
                        </div>}

                        <AccordionItemListHeader
                            testId={'wealth-transfer-accordion-header'}
                            firstColumnTitle="Transferred To"
                            lastColumnTitle={estateType === EstateType.CURRENT ? "Present Value" : "Future Value"}
                            className={classNames('estate-summary-grid', 'estate-grid-table')}/>

                        <Accordion expanded={['estate-summary-beneficiaries']}>
                            <AccordionItem
                                uuid="estate-summary-beneficiaries"
                                primaryText={pageIndex > 0 ? "Beneficiaries (continued)" : "Beneficiaries"}
                                accentColor={COLOR_ESTATE_BENEFICIARIES}
                                rightAlignedContent={<AccordionItemPresentValueHeader
                                    value={estateSummary.beneficiaryTotalValue}/>}
                                expandable={doBeneficiariesExist}>
                                {doBeneficiariesExist && <GroupedTable
                                    columnHeaders={["Name", "Present Value"]}
                                    tableData={groupedTablePage}/>}
                            </AccordionItem>
                        </Accordion>


                        {isLastPage(pageIndex) && <EstimatedTaxReport value={estateSummary.estimatedEstateTax}
                                                                      showStateTax={showOnlyStateTaxInLastPage || showStateAndFedTaxInLastPage}
                                                                      showFedTax={showStateAndFedTaxInLastPage}
                                                                      estateSummary={estateSummary}
                                                                      stateTaxSummary={estateSummary.stateTaxSummary}
                                                                      showBothTaxes={ !showStateAndFedTaxInNewPage }
                                                                      federalTaxSummary={estateSummary.federalTaxSummary}
                                                                      stateEstateTaxDeduction={stateEstateTaxDeduction}
                                                                      showContinuedLabel={false}
                        />}
                    </div>
                </section>
            </article>
        </PrintViewWrapper>))}
        {(showStateAndFedTaxInNewPage || showOnlyStateTaxInLastPage) &&
            <PrintViewWrapper pageNumber={10} displayName={displayName} key={10}>

                <article className="summary-page layout-split-left">
                    <BarChartSidebar
                        data={[{
                            label: 'Beneficiaries',
                            className: '',
                            color: '#66AA93',
                            total: estateSummary.beneficiaryTotalValue,
                        }, {
                            label: 'Estimated Estate Tax',
                            className: '',
                            color: '#FFDF77',
                            total: estateSummary.estimatedEstateTax,
                        }]}
                        noDataText=""
                        displayName={profileName}
                        title='Wealth Transfer'
                        subtitle='Explore opportunities to transfer your wealth, fulfill goals, and reduce your estate tax exposure.'>

                        <div>
                <span
                    className="condensed-subtitle margintop-xxxl">{estateType === EstateType.CURRENT ? "CURRENT ESTATE VALUE" : "FUTURE ESTATE VALUE"}</span>
                            <div className="h1 margintop-sm marginbottom-xxxl"
                                 aria-label={'Current Estate Value'}>{formatCurrency(estateSummary.estateValue)}</div>
                        </div>
                    </BarChartSidebar>
                    <section>
                        <div className="summary-page-content">
                            <EstimatedTaxReport value={estateSummary.estimatedEstateTax}
                                                showStateTax={showStateAndFedTaxInNewPage}
                                                showFedTax={showStateAndFedTaxInNewPage || showOnlyStateTaxInLastPage}
                                                estateSummary={estateSummary}
                                                stateTaxSummary={estateSummary.stateTaxSummary}
                                                showBothTaxes={showStateAndFedTaxInNewPage|| showOnlyStateTaxInLastPage}
                                                federalTaxSummary={estateSummary.federalTaxSummary}
                                                stateEstateTaxDeduction={stateEstateTaxDeduction}
                                                showContinuedLabel={showOnlyStateTaxInLastPage}/>
                        </div>
                    </section>
                </article>
            </PrintViewWrapper>}
    </>

}

interface WealthTransferReportHeaderProps {
    estateValue: EstateSummary['estateValue'],
    beneficiaryTotalValue: EstateSummary['beneficiaryTotalValue'],
    estimatedEstateTax: EstateSummary['estimatedEstateTax'],
    enableWealthTransferMenu: boolean,
    estateType: EstateType,
    atAge: number
}

function WealthTransferReportHeader({
                                        estateValue,
                                        enableWealthTransferMenu,
                                        beneficiaryTotalValue,
                                        estimatedEstateTax,
                                        estateType,
                                        atAge
                                    }: WealthTransferReportHeaderProps) {

    const text = estateType === EstateType.CURRENT ? 'Your current estate value is ' : `Your estimated estate value at age ${atAge} is `;
    return (<>
        <h3>{text} <b>{formatCurrency(estateValue)}</b>.
            Under your current
            plan, <b>{formatCurrency(beneficiaryTotalValue)}</b> would be
            distributed to beneficiaries
            and <b>{formatCurrency(estimatedEstateTax)}</b> to estimated estate taxes.
        </h3>
        {enableWealthTransferMenu && <div>
            <PageActionMenu
                className="marginright-xs"
                panelHeight="auto"
                panelWidth={240}
                aria-label="more-options-dropdown"
                key={"page-action-menu"}>

                <DropdownGroup groupName="Value" key={"dr-dropdown-group"}>
                    <DropdownItem key={"current"} value="" itemText=""
                                  onClick={NO_OP}>

                        <div aria-label="wealth-transfer-menu-present-value"
                             className="page-action-menu-options">
                            Present Value
                            <input name="wealth-transfer-group"
                                   type="radio" value='current'
                                   aria-label="present-value-option"
                                   checked={estateType === EstateType.CURRENT}
                                   onChange={NO_OP}
                            />
                        </div>
                    </DropdownItem>
                    <DropdownItem className="page-action-menu-options" key={"future"} value=""
                                  itemText="" onClick={NO_OP}>
                        <div aria-label="wealth-transfer-menu-future-value"
                             style={{width: "100%"}}
                             className="page-action-menu-options">
                            Future Value <input name="wealth-transfer-group" type="radio"
                                                aria-label="future-value-option" value='future'
                                                checked={estateType === EstateType.FUTURE}
                                                onChange={NO_OP}/>
                        </div>
                    </DropdownItem>
                </DropdownGroup>
            </PageActionMenu>
        </div>}
    </>);
}

export default WealthTransferReportView;