import {DataDisplayView, ProposedAllocationEntry, ReviseAssetSubclassesSummary} from "../ReviseAssetAllocation";
import {formatNumberRoundedToTwoDecimals, formatNumberRoundedToWholeNumber} from "../../../utils/format";

export type ResetAssetClassification = {
    updatedAssetClassificationList: ReviseAssetSubclassesSummary[],
    updatedTotalProposedPercentOfTotalPortfolio: number,
    updatedTotalDifferenceAmount: number,
    updatedTotalProposedAmount: number,
    updatedTotalProposedPercent: number
}

export const calculateProposedInvestablePercentOfTotalPortfolio = (proposedAllocationPercent: number, totalProposedInvestablePercentOfTotalPortfolio: number, totalProposedAllocationPercent: number): number => {
    if (totalProposedAllocationPercent == 0) {
        return 0
    }
    return (proposedAllocationPercent * (totalProposedInvestablePercentOfTotalPortfolio * totalProposedAllocationPercent / 100)) / totalProposedAllocationPercent;
}

export const resetAssetClassification = (
    assetClassificationList: ReviseAssetSubclassesSummary[],
    totalProposedInvestablePercentOfTotalPortfolio: number,
    totalProposedPercent: number,
    totalRecommendedTargetPercent: number,
): ResetAssetClassification => {
    const updatedAssetClassificationList = assetClassificationList.map((assetSubClass) => {
        const proposedInvestablePercent = assetSubClass.excludeSubclass ? 0 : assetSubClass.recommendedProposedTargetPercent;
        const proposedInvestableAmount = assetSubClass.excludeSubclass ? 0 : assetSubClass.recommendedProposedTargetAmount;
        return ({
            ...assetSubClass,
            proposedInvestablePercent,
            proposedInvestableAmount,
            proposedInvestablePercentOfTotalPortfolio: calculateProposedInvestablePercentOfTotalPortfolio(
                proposedInvestablePercent,
                totalProposedInvestablePercentOfTotalPortfolio,
                totalProposedPercent || totalRecommendedTargetPercent
            ),
            differenceAmount: proposedInvestableAmount - assetSubClass.currentInvestableAmount,
            editedManually: false,
            excludedProposedAllocation: 0
        });
    });
    const updatedTotalProposedPercentOfTotalPortfolio = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.proposedInvestablePercentOfTotalPortfolio;
    }, 0);
    const updatedTotalDifferenceAmount = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.differenceAmount;
    }, 0);

    const updatedTotalProposedAmount = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.proposedInvestableAmount;
    }, 0);

    const updatedTotalProposedPercent = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.proposedInvestablePercent;
    }, 0);

    return {
        updatedAssetClassificationList,
        updatedTotalProposedPercentOfTotalPortfolio,
        updatedTotalDifferenceAmount,
        updatedTotalProposedAmount,
        updatedTotalProposedPercent
    }
}

function calculateRevisedAmountFromRevisedPercentage(proposedPercent: number, initialTotalProposedAmount: number): number {
    const proposedAmount = (initialTotalProposedAmount * proposedPercent) / 100;
    return Number(proposedAmount.toFixed(2));
}

function calculateRevisedPercentFromRevisedAmount(proposedAmount: number, initialTotalProposedAmount: number): number {
    if (initialTotalProposedAmount == 0) {
        return 0
    }
    const proposedPercent = (proposedAmount / initialTotalProposedAmount) * 100;
    return Number(proposedPercent.toFixed(2));
}

function calculateRevisedAmountOrPercent(proposedAllocationEntry: ProposedAllocationEntry, initialTotalProposedAmount: number, displayView: DataDisplayView) {
    if (displayView === DataDisplayView.PERCENTAGE_VIEW) {
        const updatedProposedAmount = calculateRevisedAmountFromRevisedPercentage(proposedAllocationEntry.proposedAllocationPercent!, initialTotalProposedAmount);
        return {
            updatedProposedAmount,
            updatedProposedPercent: proposedAllocationEntry.proposedAllocationPercent!
        }
    } else {
        const updatedProposedPercent = calculateRevisedPercentFromRevisedAmount(proposedAllocationEntry.proposedAllocationAmount!, initialTotalProposedAmount);
        return {
            updatedProposedAmount: proposedAllocationEntry.proposedAllocationAmount!,
            updatedProposedPercent
        }
    }
}

export function updateAssetClassificationForProposedEntry({
                                                              assetClassificationList,
                                                              proposedAllocationEntry,
                                                              previousTotalProposedAmount,
                                                              previousTotalProposedPercent,
                                                              previousTotalProposedPercentOfTotalPortfolio,
                                                              previousTotalDifferenceAmount,
                                                              initialTotalProposedAmount,
                                                              initialTotalProposedPercentOfTotalPortfolio,
                                                              displayView,
                                                              excludeSubclass
                                                          }: {
    assetClassificationList: ReviseAssetSubclassesSummary[],
    proposedAllocationEntry: ProposedAllocationEntry,
    previousTotalProposedAmount: number,
    previousTotalProposedPercent: number,
    previousTotalProposedPercentOfTotalPortfolio: number,
    previousTotalDifferenceAmount: number,
    initialTotalProposedAmount: number,
    initialTotalProposedPercentOfTotalPortfolio: number,
    displayView: DataDisplayView,
    excludeSubclass: boolean
}) {
    const updatedEntryIndex = assetClassificationList.findIndex(subclass => subclass.subclassName === proposedAllocationEntry.assetSubClass);
    const {
        currentInvestableAmount,
        proposedInvestablePercent,
        proposedInvestableAmount,
        proposedInvestablePercentOfTotalPortfolio,
        differenceAmount
    } = assetClassificationList[updatedEntryIndex];

    const {
        updatedProposedAmount,
        updatedProposedPercent
    } = calculateRevisedAmountOrPercent(proposedAllocationEntry, initialTotalProposedAmount, displayView);
    const updatedTotalProposedAmount = previousTotalProposedAmount - proposedInvestableAmount + updatedProposedAmount;
    const updatedTotalProposedPercent = previousTotalProposedPercent - proposedInvestablePercent + updatedProposedPercent;
    const updatedDifferenceAmount = updatedProposedAmount - currentInvestableAmount;
    const updatedTotalDifferenceAmount = previousTotalDifferenceAmount - differenceAmount + updatedDifferenceAmount;
    const updatedProposedPercentOfTotalPortfolio = calculateProposedInvestablePercentOfTotalPortfolio(
        updatedProposedPercent,
        initialTotalProposedPercentOfTotalPortfolio,
        updatedTotalProposedPercent
    );
    const updatedTotalProposedPercentOfTotalPortfolio = previousTotalProposedPercentOfTotalPortfolio - proposedInvestablePercentOfTotalPortfolio + updatedProposedPercentOfTotalPortfolio;

    const updatedRiskAssets = [...assetClassificationList];
    updatedRiskAssets[updatedEntryIndex] = {
        ...updatedRiskAssets[updatedEntryIndex],
        proposedInvestableAmount: updatedProposedAmount,
        proposedInvestablePercent: updatedProposedPercent,
        proposedInvestablePercentOfTotalPortfolio: updatedProposedPercentOfTotalPortfolio,
        differenceAmount: updatedDifferenceAmount,
        excludeSubclass,
        editedManually: proposedAllocationEntry.editedManually,
        excludedProposedAllocation: proposedAllocationEntry.excludedProposedAllocation
    }

    return {
        updatedAssetClassificationList: updatedRiskAssets,
        updatedTotalProposedAmount,
        updatedTotalProposedPercent,
        updatedTotalDifferenceAmount,
        updatedTotalProposedPercentOfTotalPortfolio
    };
}

export const formatAllocationPercentage = (value: number) => {
    if (value === 0 || value === 100) {
        return `${formatNumberRoundedToWholeNumber(value)}%`;
    }
    return `${formatNumberRoundedToTwoDecimals(value)}%`;
};

export const formatAllocationAmount = (value: number) => {
    return formatNumberRoundedToWholeNumber(value);
};

export const isDollarView = (displayView: DataDisplayView): boolean => {
    return displayView === DataDisplayView.DOLLAR_VIEW
}