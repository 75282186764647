import {BeneficiariesFormData, PhilanthropicBequestFormData} from "../models/ui";
import {roundNumber} from "../../utils/format";
import {EstateSummary} from "../models/api";
import {safeParseFloat} from "../../utils/stringUtils";

export function validateForm(formData: BeneficiariesFormData, estateSummary: EstateSummary) {
    return {
        isMemberBequestsInvalid: !isIndividualMemberBequestsValid(formData.memberBequests),
        isRemainingMemberBequestInvalid: !isRemaingMemberBequestValid(formData.residuaryMemberBequests),
        isRemainingEstateInvalid: !isRemainingEstateValid(formData),
        isGeneralBequestsInvalid: !isGeneralBequestsValid(formData, estateSummary),
        philanthropicBequestsValidity: philanthropicBequestsValidity(formData.philanthropicBequests)
    };
}

function philanthropicBequestsValidity(bequests: PhilanthropicBequestFormData[]) {
    return bequests.map(({ philanthropyName }) => !!philanthropyName);
}

function isIndividualMemberBequestsValid(memberBequests: BeneficiariesFormData['memberBequests']) {
    return bequestsValid(memberBequests)
}

function isRemaingMemberBequestValid(residuaryMemberBequests: BeneficiariesFormData['residuaryMemberBequests']){
    return bequestsValid(residuaryMemberBequests)
}

function bequestsValid(bequests: { memberId: string| null}[]) {
    if (bequests.length === 0) {
        return true;
    }
    const lastIndex = bequests.length - 1;
    return bequests[lastIndex].memberId !== null;
}

export function isRemainingEstateValid(formData: BeneficiariesFormData) {
    if (formData.residuaryMemberBequests.length === 0) {
        return true;
    }
    const sumOfResiduaryBequests = formData.residuaryMemberBequests
        .map(bequest => safeParseFloat(bequest.percentage))
        .reduce((total, percentage) => total + percentage);
    return sumOfResiduaryBequests <= 100;
}

export function isGeneralBequestsValid(formData: BeneficiariesFormData, estateSummary: EstateSummary) {
    const sumOfGeneralBequests = getSumOfIndividualAndPhilanthropicBequests(formData);
    return sumOfGeneralBequests <= roundNumber(estateSummary.beneficiaryTotalValue);
}

function getSumOfIndividualBequests(formData: BeneficiariesFormData) {
    return formData.memberBequests.length === 0 ? 0 : formData.memberBequests.map(bequest => parseFloat(bequest.presentValue))
        .reduce((total, presentValue) => total + presentValue);
}

function getSumOfPhilanthropicBequests(formData: BeneficiariesFormData) {
    return formData.philanthropicBequests.length === 0 ? 0 : formData.philanthropicBequests.map(bequest => parseFloat(bequest.value))
        .reduce((total, presentValue) => total + presentValue);
}

function getSumOfIndividualAndPhilanthropicBequests(formData: BeneficiariesFormData) {
    return getSumOfIndividualBequests(formData) + getSumOfPhilanthropicBequests(formData);
}
