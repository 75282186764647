import {ChangeEventHandler, ReactNode} from 'react';
import {Label} from 'src/components';
import {Radio as XpsRadio} from 'xps-react';

type RadioGroupWithErrorMessageProps = {
    id: string,
    label: string,
    name: string,
    values: string[],
    layout: "horizontal" | "vertical"
    selected?: string | null,
    required?: boolean,
    error?: boolean,
    errorMessage?: string,
    children?: ReactNode,
    onChange: ChangeEventHandler
}

const RadioGroupWithErrorMessage = ({
    id,
    label,
    name,
    values,
    selected,
    required,
    error,
    errorMessage,
    children,
    onChange,
}: RadioGroupWithErrorMessageProps) => {

    const radioIdString = id || `${name.replace(/ /g, '_')}`;
    const labelId = `${radioIdString}__label`;

    return (
        <div className='radiogroup' role='radiogroup' aria-labelledby={labelId}>
            <div className='radiogroup__label radiogroupwitherrormessage_label' >
                <Label id={labelId} label={label} required={required} />
            </div>
            <div>
                <div className='radiogroup--horizontal'>
                    {values.map((value, index) => 
                        <XpsRadio 
                            id={`${radioIdString}_${index}`}
                            key={value} 
                            name={name}
                            value={value}
                            checked={value === selected}
                            onChange={onChange}
                            error={error}
                        />)}
                    {children ?? <div className='radiogroupwitherrormessage_empty_button'></div>}
                </div>
                <label className="input__info color-text--error">{error && errorMessage}</label>
            </div>
        </div>
    )
};

export default RadioGroupWithErrorMessage;