import React, {ChangeEvent, CSSProperties, Fragment, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import ExcessAssets from "./ExcessAssets";
import {
    resetPortfolioReserveButtonStates,
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveModel,
    selectPortfolioReserveUserPreferences,
    selectPortfolioReserveYears,
    setPortfolioReserveModel,
    setPortfolioReserveUserPreferences,
    setPortfolioReserveYears,
} from "./PortfolioReserveSlice";
import PortfolioRisk from "./PortfolioRisk";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import ReserveTargetDetails from "./ReserveTargetDetails";
import {goalsApiClient} from "../GoalsApiClient";
import {Button} from "../../components";
import EditPortfolioReserveTableWarning from "./EditPortfolioReserveTableWarning"
import {ReserveTargetAllocationDonut} from "./ReserveTargetAllocationDonut";
import ClickableYearsInput, {IconState} from "../../components/YearsInput/ClickableYearsInput";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import SavePortfolioReserveModal from "./SavePortfolioReserveModal";
import {portfolioReserveIsInvestablySufficient} from "../../Portfolio/portfolioUtils";
import {COLOR_PROJECTED_ASSETS} from "../../constants/colors";
import {useAppInsights} from "../../AppInsights";
import {selectParticipatingInMeeting} from "../../ClientManagement/Meeting/meetingSlice";
import {MeetingParticipation} from "../../ClientManagement/Meeting/Meeting";
import {QuickSlide} from "./QuickSlide/QuickSlide";
import {CVAR_SLIDE_TEXT, MONTE_CARLO_SLIDE_TEXT} from "./QuickSlide/constants";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

enum QuickSlideState {
    NONE,
    MONTE_CARLO,
    CVAR
}

const EditPortfolioReserveTable = () => {
    const dispatch = useAppDispatch();
    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {
        assets,
        investorGroup,
        profile,
        portfolioReserve,
        lifestyleSpendingGoal,
        nonLifestyleGoals,
        familyGoals,
        taxLiabilities
    } = portfolioReserveModel;
    const [editPortfolioReserve, setEditPortfolioReserve] = useState(portfolioReserve)
    const [editPortfolioReserveYears, setEditPortfolioReserveYears] = useState({
        prTargetInputYears: portfolioReserve.portfolioReserveTargetLength,
        minimumRiskInputYears: portfolioReserve.minimumRiskReserveLength
    });
    const {prTargetInputYears, minimumRiskInputYears} = useAppSelector(selectPortfolioReserveYears);

    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {enableQuickSlides} = releaseToggles!;


    const prTargetAssetAllocationInfo = {
        totalRiskAssetsPercent: editPortfolioReserve.targetLengthAllocation.riskAssetPercent,
        totalRiskControlAssetsPercent: editPortfolioReserve.targetLengthAllocation.riskControlPercent
    }
    const minimumRiskAssetAllocation = {
        totalRiskAssetsPercent: editPortfolioReserve.minimumRiskAllocation.riskAssetPercent,
        totalRiskControlAssetsPercent: editPortfolioReserve.minimumRiskAllocation.riskControlPercent
    }
    const riskControlAllocationDifference = Math.round(minimumRiskAssetAllocation.totalRiskControlAssetsPercent) - Math.round(prTargetAssetAllocationInfo.totalRiskControlAssetsPercent)

    let riskControlDiffDesc = "";
    if (riskControlAllocationDifference > 0) {
        riskControlDiffDesc = `A longer Reserve Target means more assets moved to Risk Control (${riskControlAllocationDifference}%).`;
    }
    if (riskControlAllocationDifference < 0) {
        riskControlDiffDesc = `A shorter Reserve Target means fewer assets moved to Risk Control (${riskControlAllocationDifference}%).`;
    }
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences)

    const {
        portfolioReserveOptions,
        editablePortfolioReserve,
        clickedInputYear,
        navigatedBeforeSavingPortfolioReserve,
    } = portfolioReserveUserPreferences

    const portfolioReserveButtonState = useAppSelector(selectPortfolioReserveButtonStates);
    const minRiskDescriptionDefault = editPortfolioReserve.portfolioReserveTargetLength > editPortfolioReserve.minimumRiskReserveLength ?
        "A shorter Reserve Target means a wider range of expected outcomes." :
        "A longer Reserve Target means a narrower range of expected outcomes.";
    let [riskDescriber, setRiskDescriber] = useState<string>("less risk");
    let [minRiskDescriptionText, setMinRiskDescriptionText] = useState<string>(minRiskDescriptionDefault);

    const initialPortfolioReserveTargetLength = useRef(portfolioReserve.initialPortfolioReserveTargetLength);
    const initialMinimumRiskReserveLength = useRef(portfolioReserve.minimumRiskReserveLength);
    const [didMount, setDidMount] = useState<boolean>(false);
    const yearsInputWidth: CSSProperties = {
        width: "302px"
    }

    const prTargetInputMatchesProfilePR = (prTargetInputYears === profile.portfolioReserveTargetLength)
    const prTargetInputMatchesPortfolioSupports = (prTargetInputYears === initialPortfolioReserveTargetLength.current)
    const changedPrValue = ((profile.portfolioReserveTargetLength != undefined) ? (!prTargetInputMatchesProfilePR) : (!prTargetInputMatchesPortfolioSupports))
    const [savedPortfolioReserveClicked, setSavedPortfolioReserveClicked] = useState<boolean>(false);

    const reserveTargetInputController = useRef(new AbortController())
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const displayInsufficientWarning = !portfolioReserveIsInvestablySufficient({
        nonLifestyleGoals,
        familyGoals,
        totalInvestableValue: assets.totalInvestableValue,
        lifestylePresentValue: editPortfolioReserve.lifestylePresentValues.reserveTarget,
        taxLiabilities
    })
    const appInsights = useAppInsights();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;

    const [quickSlideState, setQuickSlideState] = useState<QuickSlideState>(QuickSlideState.NONE)

    useEffect(() => {
        // Anything in here is fired on component mount.
        const startTime: number = new Date().valueOf();
        return () => {
            // Anything in here is fired on component unmount.
            const endTime: number = new Date().valueOf();
            appInsights.trackEvent({
                name: 'EditPortfolioReserve',
                properties:
                    {
                        screen: 'Edit Portfolio Reserve',
                        action: 'Time Spent',
                        meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                        data: {
                            timeSpent: endTime - startTime // Gives the time Spent in milliseconds
                        }
                    }
            })
        }
    }, [])

    useEffect(() => {
        dispatch(setPortfolioReserveUserPreferences({...portfolioReserveUserPreferences, clickedInputYear: false}));
    }, []);

    function updateDescriptionText(prYears: number, minimumRiskYears: number) {
        if (prYears < minimumRiskYears) {
            setRiskDescriber("less risk");
            setMinRiskDescriptionText("A longer Reserve Target means a narrower range of expected outcomes.");
        } else if (prYears > minimumRiskYears) {
            setRiskDescriber("more risk");
            setMinRiskDescriptionText("A shorter Reserve Target means a wider range of expected outcomes.");
        } else {
            setRiskDescriber(riskDescriber)
            setMinRiskDescriptionText(minRiskDescriptionText)
        }
    }

    useEffect(() => {
        if (!isValidNumber(prTargetInputYears) || !isValidNumber(minimumRiskInputYears)) {
            return;
        }
        updateDescriptionText(prTargetInputYears, minimumRiskInputYears);

        if (didMount && (portfolioReserveButtonState.excessAssets || profile.portfolioReserveTargetLength) && isValidNumber(prTargetInputYears) && isValidNumber(minimumRiskInputYears)) {
            setIsLoading(true)
            fetchPortfolioReserveData(prTargetInputYears, minimumRiskInputYears);
        } else {
            setDidMount(true);
        }
    }, [prTargetInputYears, minimumRiskInputYears]);

    const handlePRTargetChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
        setEditPortfolioReserveYears({
            ...editPortfolioReserveYears,
            prTargetInputYears: value
        })

        dispatch(setPortfolioReserveYears({...editPortfolioReserveYears, prTargetInputYears: value}));
    }

    const handleIncreasePRYear = async () => {
        if (isValidNumber(prTargetInputYears) && prTargetInputYears + 1 < investorGroup.planningPeriod.numberOfYears) {
            setEditPortfolioReserveYears({
                ...editPortfolioReserveYears,
                prTargetInputYears: prTargetInputYears + 1
            })

            dispatch(setPortfolioReserveYears({
                ...editPortfolioReserveYears,
                prTargetInputYears: prTargetInputYears + 1
            }));
        }
    }

    const handleDecreasePRYear = async () => {
        if (isValidNumber(prTargetInputYears) && prTargetInputYears - 1 > 0) {
            setEditPortfolioReserveYears({
                ...editPortfolioReserveYears,
                prTargetInputYears: prTargetInputYears - 1
            })

            dispatch(setPortfolioReserveYears({
                ...editPortfolioReserveYears,
                prTargetInputYears: prTargetInputYears - 1
            }));
        }
    }

    const handleReserveTargetBlur = async (selectedReserveTarget: number) => {
        if (isValidNumber(prTargetInputYears) && prTargetInputYears >= 0) {
            if (selectedReserveTarget > investorGroup.planningPeriod.numberOfYears) {
                setEditPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    prTargetInputYears: investorGroup.planningPeriod.numberOfYears
                })

                dispatch(setPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    prTargetInputYears: investorGroup.planningPeriod.numberOfYears
                }));
            }
        }
    }
    const updatePortfolioReserveData = async (selectedReserveTarget: number, selectedMinimumRisk: number, abortSignal: AbortSignal) => {
        setEditPortfolioReserveYears(
            {
                prTargetInputYears: selectedReserveTarget,
                minimumRiskInputYears: selectedMinimumRisk
            }
        )
        goalsApiClient.getPortfolioReserve(profile.id,
            {
                selectedReserveTarget: String(selectedReserveTarget),
                selectedMinimumRisk: String(selectedMinimumRisk)
            },
            abortSignal
        ).then(response => {
            setEditPortfolioReserve(response)
            setIsLoading(false)
        });
    }

    const hideMinRiskHeaderText = () => {
        dispatch(setPortfolioReserveUserPreferences({
            portfolioReserveOptions,
            editablePortfolioReserve: true,
            clickedInputYear: true,
            editedMinimumRisk: true
        }))
    }

    const handleMinRiskChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
        setEditPortfolioReserveYears({
            ...editPortfolioReserveYears,
            minimumRiskInputYears: value
        })
        hideMinRiskHeaderText()

        dispatch(setPortfolioReserveYears({...editPortfolioReserveYears, minimumRiskInputYears: value}));
    }

    const handleIncreaseMinYear = async () => {
        if (isValidNumber(minimumRiskInputYears) && minimumRiskInputYears + 1 < investorGroup.planningPeriod.numberOfYears) {
            setEditPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears + 1
            })
            hideMinRiskHeaderText()

            dispatch(setPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears + 1
            }));
        }
    }

    const handleDecreaseMinYear = async () => {
        if (isValidNumber(minimumRiskInputYears) && minimumRiskInputYears - 1 > 0) {
            setEditPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears - 1
            })
            hideMinRiskHeaderText()

            dispatch(setPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears - 1
            }));
        }
    }

    const handleMinimumRiskBlur = async (selectedMinimumRisk: number) => {
        if (isValidNumber(minimumRiskInputYears) && minimumRiskInputYears >= 0) {
            if (selectedMinimumRisk > investorGroup.planningPeriod.numberOfYears) {
                setEditPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    minimumRiskInputYears: investorGroup.planningPeriod.numberOfYears
                })

                dispatch(setPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    minimumRiskInputYears: investorGroup.planningPeriod.numberOfYears
                }));
            }
        }
    }

    const fetchPortfolioReserveData = (targetInputYears: number, minimumRiskYears: number) => {
        const newReserveTargetController = new AbortController()
        const {signal} = newReserveTargetController;

        reserveTargetInputController.current.abort()
        reserveTargetInputController.current = newReserveTargetController
        updatePortfolioReserveData(targetInputYears, minimumRiskYears, signal).then();
    }

    const updatePortfolioReserveResponse = (): PortfolioReserveResponse => {
        return {
            ...portfolioReserve,
            portfolioReserveTargetLength: editPortfolioReserve.portfolioReserveTargetLength,
            monteCarlo: {
                monteCarloPortfolioSupports: editPortfolioReserve.monteCarlo.monteCarloPortfolioSupports,
                monteCarloMinimumRisk: portfolioReserve.monteCarlo.monteCarloMinimumRisk
            },
            cvar: {
                cvarPortfolioSupports: editPortfolioReserve.cvar.cvarPortfolioSupports,
                cvarMinimumRisk: portfolioReserve.cvar.cvarMinimumRisk
            },
            lifestylePresentValues: {
                reserveTarget: editPortfolioReserve.lifestylePresentValues.reserveTarget,
                minimumRisk: portfolioReserve.lifestylePresentValues.minimumRisk
            },
            targetLengthAllocation: editPortfolioReserve.targetLengthAllocation
        }
    }

    const updateLifestyleSpendingGoal = (): LifestyleSpendingGoal => {
        return {
            ...lifestyleSpendingGoal,
            calculatedFields: {
                ...editPortfolioReserve.lifestylePresentValues.reserveTarget
            }
        }
    }
    const displayTargetLengthLabel = () => {
        if (prTargetInputMatchesProfilePR) {
            return <>What is my <span style={{fontWeight: "410"}}>Reserve Target</span>?</>;
        } else if (prTargetInputMatchesPortfolioSupports) {
            return <>What does my <span style={{fontWeight: "410"}}>current portfolio support today</span>?</>;
        } else {
            return <>What if I <span style={{fontWeight: "410"}}>explore a different Reserve Target</span>?</>;
        }
    }

    const portfolioRiskRowActive = portfolioReserveButtonState.portfolioRisk || profile.portfolioReserveTargetLength;
    const excessAssetsRowActive = portfolioReserveButtonState.excessAssets || profile.portfolioReserveTargetLength;

    function getYearsInputIconState() {
        if (editablePortfolioReserve && !clickedInputYear) {
            return IconState.PENCIL
        } else if (editablePortfolioReserve && clickedInputYear) {
            return IconState.ARROWS
        }
        return IconState.NONE
    }

    return (
        <div className="edit-portfolio-reserve-page__table" role="table">
            <EditPortfolioReserveTableWarning editedReserveLength={changedPrValue}/>
            <SavePortfolioReserveModal setPRClicked={savedPortfolioReserveClicked}/>
            {enableQuickSlides && (quickSlideState === QuickSlideState.MONTE_CARLO) &&
                <QuickSlide
                    title={"Monte Carlo Range of Outcomes"}
                    imageSrc={"/MonteCarloBellCurve.svg"}
                    children={<>
                        <div className={"marginbottom-lg"}>{MONTE_CARLO_SLIDE_TEXT[0]}</div>
                        <div className={"marginbottom-lg"}>{MONTE_CARLO_SLIDE_TEXT[1]}</div>
                        <div className={"marginbottom-lg"}>{MONTE_CARLO_SLIDE_TEXT[2]}</div>
                    </>}
                    closeHandler={() => setQuickSlideState(QuickSlideState.NONE)}
                ></QuickSlide>
            }
            {enableQuickSlides && (quickSlideState === QuickSlideState.CVAR) &&
                <QuickSlide
                    title={"Conditional Value at Risk"}
                    subtext={" (Average of worst 5% of expected returns)"}
                    imageSrc={"/CVaRGraph.svg"}
                    children={<>
                        <div className={"marginbottom-lg"}>{CVAR_SLIDE_TEXT}</div>
                    </>}
                    closeHandler={() => setQuickSlideState(QuickSlideState.NONE)}
                ></QuickSlide>
            }
            <div
                className={`edit-portfolio-reserve-page__table__row`}
                role="row">
                <div className="edit-portfolio-reserve-page__table__column">
                    <div style={{paddingTop: "35px"}}
                         className="edit-portfolio-reserve-page__table__column__content">
                        How many years of lifestyle spending would I like to protect?
                    </div>
                </div>
                <div
                    className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered">
                    <div data-testid="portfolioReserveTargetLengthYearsInputContainer">
                        <div style={{
                            width: "320px",
                            fontSize: "24px",
                            lineHeight: "30px",
                            fontWeight: "370",
                            paddingTop: prTargetInputYears === profile.portfolioReserveTargetLength ? "40px" : "25px"
                        }}>{displayTargetLengthLabel()}</div>
                        <ClickableYearsInput
                            name="portfolioReserveTargetLength"
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.stopPropagation();
                                dispatch(setPortfolioReserveUserPreferences({
                                    ...portfolioReserveUserPreferences,
                                    portfolioReserveOptions,
                                    editablePortfolioReserve: true,
                                    clickedInputYear: true
                                }))
                            }}
                            onUpArrowClick={handleIncreasePRYear}
                            onDownArrowClick={handleDecreasePRYear}
                            onBlur={(e: ChangeEvent<HTMLInputElement>) => handleReserveTargetBlur(Number(e.target.value))}
                            iconState={getYearsInputIconState()}
                            disabledAndReadOnly={!editablePortfolioReserve && !profile.portfolioReserveTargetLength}
                            value={prTargetInputYears}
                            onChangeValue={handlePRTargetChangeValue}
                            inputStyle={{
                                textAlign: "right",
                                paddingRight: "20%",
                            }}
                            error={navigatedBeforeSavingPortfolioReserve ? "Please confirm the Reserve Target Length." : ""}
                            upArrowClassName={"dds-icons input__icon input__icon__increase-support-button"}
                            downArrowClassName={"dds-icons input__icon input__icon__decrease-support-button"}
                            inputClassName={editablePortfolioReserve && !clickedInputYear ? "edit-portfolio-reserve-page__target-length-input editable-target-border" : "edit-portfolio-reserve-page__target-length-input"}
                        />
                        {displayInsufficientWarning &&
                            <div style={{
                                ...yearsInputWidth,
                                color: COLOR_PROJECTED_ASSETS,
                                fontSize: "12px",
                                marginBottom: "12px"
                            }}>You need to reduce your portfolio reserve to achieve investable sufficiency</div>
                        }
                        <div style={{...yearsInputWidth, fontSize: "15px"}}>Your current asset allocation supports
                            a <span
                                style={{
                                    fontWeight: "500",
                                    color: "#05676E"
                                }}>{initialPortfolioReserveTargetLength.current} year </span>
                            reserve.
                        </div>
                        <div style={{...yearsInputWidth, marginTop: "32px"}}>
                            {portfolioReserveOptions.showReserveTargetDetails &&
                                <ReserveTargetDetails
                                    presentValue={editPortfolioReserve.lifestylePresentValues.reserveTarget}/>}
                        </div>
                    </div>
                </div>
                <div
                    className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered">
                    <div data-testid="lifestyleMinimumRiskYearsInputContainer">
                        <div style={{
                            width: "320px",
                            fontSize: "24px",
                            fontWeight: "370",
                            lineHeight: "30px",
                            paddingTop: "40px",
                            paddingBottom: prTargetInputYears === profile.portfolioReserveTargetLength ? "0px" : "15px"
                        }}>
                            What if I take on <span style={{fontWeight: "410"}}>{riskDescriber}</span>?
                        </div>
                        <ClickableYearsInput
                            name="lifestyleMinimumRisk"
                            onUpArrowClick={handleIncreaseMinYear}
                            onDownArrowClick={handleDecreaseMinYear}
                            onBlur={(e: ChangeEvent<HTMLInputElement>) => handleMinimumRiskBlur(Number(e.target.value))}
                            iconState={editablePortfolioReserve ? IconState.ARROWS : IconState.NONE}
                            disabledAndReadOnly={!editablePortfolioReserve && !profile.portfolioReserveTargetLength}
                            value={minimumRiskInputYears}
                            onChangeValue={handleMinRiskChangeValue}
                            inputStyle={{
                                textAlign: "right",
                                paddingRight: "20%",
                            }}
                            upArrowClassName={"dds-icons icon  input__icon input__icon__increase-risk-button"}
                            downArrowClassName={"dds-icons icon  input__icon input__icon__decrease-risk-button"}
                            inputClassName={"edit-portfolio-reserve-page__target-length-input"}
                        />
                        {navigatedBeforeSavingPortfolioReserve && <div style={{height: "24px"}}></div>}
                        {displayInsufficientWarning && <div style={{height: "52px"}}></div>}
                        <div style={{...yearsInputWidth, fontSize: "15px"}}>The Lifestyle Minimum-Risk Portfolio
                            Reserve
                            is
                            <span style={{
                                fontWeight: "500",
                                color: "#05676E"
                            }}> {initialMinimumRiskReserveLength.current} years.</span>
                        </div>
                        <div style={{...yearsInputWidth, marginTop: "32px"}}>
                            {portfolioReserveOptions.showReserveTargetDetails &&
                                <ReserveTargetDetails
                                    presentValue={editPortfolioReserve.lifestylePresentValues.minimumRisk}/>}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`edit-portfolio-reserve-page__table__row`}
                role="row">
                <div className="edit-portfolio-reserve-page__table__column">
                    <div
                        data-testid="cvar-quick-slide-container"
                        className={`edit-portfolio-reserve-page__table__column__content ${portfolioRiskRowActive ? "" : "row-inactive"}`}>
                        How risky is this asset allocation?
                        <div className="edit-portfolio-reserve-page__table__column__subtext">
                            <span>Average of worst 5% of expected returns</span>
                            {enableQuickSlides &&
                                <Button
                                    icon="only"
                                    iconName="info_outline"
                                    kind="borderless"
                                    onClick={() => {
                                        setQuickSlideState(QuickSlideState.CVAR);
                                    }}
                                    size="small"
                                />
                            }
                        </div>
                    </div>
                </div>
                <div
                    className={`edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered`}>
                    <PortfolioRisk
                        cvar={editPortfolioReserve.cvar.cvarPortfolioSupports}
                        totalInvestableAssets={assets.totalInvestableValue}/>
                </div>
                <div
                    className={`edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered`}>
                    <PortfolioRisk
                        cvar={editPortfolioReserve.cvar.cvarMinimumRisk}
                        totalInvestableAssets={assets.totalInvestableValue}/>
                </div>

            </div>
            <div
                className={`edit-portfolio-reserve-page__table__row`}
                role="row">
                <div className="edit-portfolio-reserve-page__table__column">
                    <div
                        data-testid="monte-carlo-quick-slide-container"
                        className={`edit-portfolio-reserve-page__table__column__content ${excessAssetsRowActive ? "" : "row-inactive"}`}>
                        How much do my assets exceed my goals?
                        <div className="edit-portfolio-reserve-page__table__column__subtext">
                            {portfolioReserveOptions.showOutcomeRange &&
                                <>
                                    <span>Range of Outcomes</span>
                                    {enableQuickSlides &&
                                        <Button
                                            icon="only"
                                            iconName="info_outline"
                                            kind="borderless"
                                            onClick={() => {
                                                setQuickSlideState(QuickSlideState.MONTE_CARLO);
                                            }}
                                            size="small"
                                        />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div
                    className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered">
                    <ExcessAssets
                        presentValueType={"portfolioReserve"}
                        editPortfolioReserveData={editPortfolioReserve}
                    />
                </div>
                <div
                    className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered">
                    <ExcessAssets
                        presentValueType={"minimumRisk"}
                        minRiskDescriptionText={minRiskDescriptionText}
                        editPortfolioReserveData={editPortfolioReserve}
                    />
                </div>
            </div>
            {
                portfolioReserveOptions.showAssetAllocation ? (
                    <div
                        className={`edit-portfolio-reserve-page__table__row edit-portfolio-reserve-page__table__middle-row`}
                        role="row">
                        <div className="edit-portfolio-reserve-page__table__column">
                            <div
                                className={`edit-portfolio-reserve-page__table__column__content`}>
                                How does this Reserve Target impact my proposed asset allocation?
                            </div>
                        </div>
                        <div
                            className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered">
                            <ReserveTargetAllocationDonut
                                reserveTargetAssetAllocation={prTargetAssetAllocationInfo}/>
                        </div>
                        <div
                            className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered">
                            <ReserveTargetAllocationDonut
                                reserveTargetAssetAllocation={minimumRiskAssetAllocation}/>
                            <span className="edit-portfolio-reserve-page__text">{riskControlDiffDesc}</span>
                        </div>
                    </div>
                ) : <Fragment/>}

            <div className="edit-portfolio-reserve-page__table__row edit-portfolio-reserve-page__table__last-row"
                 role="row">
                <div className="edit-portfolio-reserve-page__table__column">
                </div>
                <div
                    className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered">
                    {clickedInputYear &&
                        <Button
                            rounded
                            size="medium"
                            kind="primary"
                            disabled={isLoading}
                            onClick={async () => {
                                dispatch(resetPortfolioReserveButtonStates());
                                await clientManagementApiClient.putProfile({
                                    ...profile,
                                    portfolioReserveTargetLength: prTargetInputYears
                                }).then((updatedProfile) => {
                                    dispatch(setPortfolioReserveModel({
                                        ...portfolioReserveModel,
                                        profile: updatedProfile,
                                        portfolioReserve: updatePortfolioReserveResponse(),
                                        lifestyleSpendingGoal: updateLifestyleSpendingGoal()
                                    }))
                                    dispatch(setPortfolioReserveUserPreferences({
                                        ...portfolioReserveUserPreferences,
                                        navigatedBeforeSavingPortfolioReserve: false
                                    }))
                                    dispatch(setPortfolioReserveYears({
                                        ...editPortfolioReserveYears,
                                        minimumRiskInputYears: portfolioReserve.minimumRiskReserveLength
                                    }));
                                }).then(() => {
                                    setSavedPortfolioReserveClicked(true)
                                    appInsights.trackEvent({
                                        name: 'EditPortfolioReserve',
                                        properties:
                                            {
                                                screen: 'Edit Portfolio Reserve',
                                                action: 'Set Portfolio Reserve Button Click',
                                                meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                                                data: {
                                                    hasRecommendedPortfolioReserve: prTargetInputMatchesPortfolioSupports,
                                                    portfolioReserveLength: prTargetInputYears
                                                }
                                            }
                                    })
                                }).catch(error => {
                                    console.error('Could not save portfolio reserve target length', error.message)
                                })
                            }}
                        >
                            Set Portfolio Reserve
                        </Button>}
                </div>
                <div className="edit-portfolio-reserve-page__table__column">
                </div>
            </div>

        </div>
    );

}

const isValidNumber = (value: number | undefined): value is number => {
    return value !== undefined && !isNaN(value);
};

export default EditPortfolioReserveTable;
