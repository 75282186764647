import {MemberType} from "../../ClientManagement/models/MemberType";
import {Dropdown} from "../../components";
import React from "react";
export const noGoalsPageText = 'No goals have been entered for';

type NoGoalBeneficiaryDetailProps = {
    memberSelected: MemberType,
    dropdownItems: JSX.Element[]
};

export function NoGoalBeneficiaryDetail({
                                            memberSelected,
                                            dropdownItems
                                        }: NoGoalBeneficiaryDetailProps) {
    return <div className="new-family-goal-page-cta" role="addGoalDropdownItems">
        <h2>{noGoalsPageText + " " + memberSelected.firstName + " " + memberSelected.lastName + "."}</h2>
        <Dropdown
            className={"add-goal-dropdown-menu dropdown--rounded"}
            size="medium"
            buttonIcon="left"
            buttonKind="primary"
            drop="down"
            dropdownKind="menu"
            defaultText="Add New Goal"
            iconNameOpen="add"
            iconNameClose="add"
            panelWidth={"auto"}
            rounded={true}
            aria-label="new-family-goal"
        >
            {dropdownItems}
        </Dropdown>
    </div>;
}