import {
    AssetRelianceResponse,
} from "../../../ClientManagement/models/AssetRelianceResponse";
import {Mapping as ClassNamesMapping} from "classnames";
import {hasPositiveExcessAssets} from "../../../ClientManagement/AssetReliance/AssetRelianceUtil";
import {
    COLOR_ASSET_SHORTFALL_ACCENT,
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS_ACCENT, COLOR_GOALS
} from "../../../constants/colors";
import {
    useAssetStackContainerWidth
} from "../../../ClientManagement/AssetReliance/AssetRelianceContent";
import AssetRelianceBarchartContainer from "../../../ClientManagement/AssetReliance/AssetRelianceBarchartContainer";
import AssetRelianceTitleGrid from "../../../ClientManagement/AssetReliance/AssetRelianceTitleGrid";
import NoAssetsComponent from "../../../ClientManagement/AssetReliance/NoAssetsComponent";
import React, {useEffect, useState} from "react";
import {AssetRelianceDetails, createTableDisplayObjects, splitAssetRelianceData} from "./reportUtil";
import PrintViewWrapper from "../PrintViewWrapper";
import {AccordionTableReport} from "./AccordionTableReport";
import {CellClassParamsReport} from "./TableRowComponentReport";
import AssetRelianceHeader from "../../../ClientManagement/AssetReliance/AssetRelianceHeader";
import {useAppSelector} from "../../../store/hooks";
import {selectAssetReliancePageActionMenu} from "../../../ClientManagement/AssetReliance/AssetRelianceSlice";
import {selectPrimaryContact} from "../../../ClientManagement/ClientProfile/activeProfileSlice";

interface assetRelianceContentReportProps {
    assetRelianceData: AssetRelianceResponse,
    id: string,
    displayName: string
}
const TITLE_CELL_WIDTH = 310;

const AssetRelianceContentReport = ({assetRelianceData, id, displayName}: assetRelianceContentReportProps) => {

    const accountHasAssets = !!assetRelianceData.allAssetsStack.netAssets || !!assetRelianceData.goals.totalPresentValue;

    const assetRelianceDetails : AssetRelianceDetails = createTableDisplayObjects(assetRelianceData.goals, assetRelianceData.allAssetsStack, assetRelianceData.assetStacks, assetRelianceData.excludedAssetStacks);

    const assetStacksContainerWidth = useAssetStackContainerWidth(assetRelianceData.excludedAssetStacks.length);

    const createTableCellValueClassNames = () => ({columnIndex_}: CellClassParamsReport): ClassNamesMapping[] => {
        return [{"table-cell-values": columnIndex_ >= 0},];
    };

    const excessAssetsAccentColor = hasPositiveExcessAssets(assetRelianceData.allAssetsStack.excessAssets) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT

    const [splittedAssetReliance, setSplittedAssetReliance] = useState<Array<AssetRelianceDetails>>([]);

    const assetRelianceButtonState = useAppSelector(selectAssetReliancePageActionMenu);

    const primaryContact = useAppSelector(selectPrimaryContact);

    useEffect(() => {
        const defaultData = {
            ...assetRelianceDetails,
            assets: null,
            excessAssets: null,
            goals: null
        }
        splitAssetRelianceData(assetRelianceDetails, defaultData).then((res: Array<AssetRelianceDetails>) => {
            setSplittedAssetReliance(res);
        });
    }, []);

    return (<>
            {assetRelianceDetails.columnCounter.count > 0 ? splittedAssetReliance.map((assetRelianceDetailsData, index) => {
                return (

                    <PrintViewWrapper displayName={displayName} key={index}>
                        <div className="asset-reliance">
                        <AssetRelianceHeader
                            id={id}
                            title="Review Asset Reliance"
                            headerOptions={assetRelianceButtonState}
                            primaryContact={primaryContact!}
                            showPageActionMenu={false}
                        />
                        {accountHasAssets ?
                            <main className="asset-reliance-content">
                                <AssetRelianceBarchartContainer allAssetsStack={assetRelianceData.allAssetsStack}
                                                                additionalAssetStacks={assetRelianceData.excludedAssetStacks}
                                                                totalGoals={assetRelianceData.goals.totalPresentValue}
                                                                totalNetAssets={Number(assetRelianceData.allAssetsStack.presentValue)}
                                                                stackContainerWidth={assetStacksContainerWidth}
                                                                titleCellWidth={TITLE_CELL_WIDTH}
                                />

                                <AssetRelianceTitleGrid
                                    additionalStacksTitles={assetRelianceData.excludedAssetStacks.map(stack => stack.name)}
                                    stackContainerWidth={assetStacksContainerWidth}
                                    titleCellWidth={TITLE_CELL_WIDTH}
                                    id={id}
                                />
                                {assetRelianceDetailsData.assets && <AccordionTableReport accordionTableId_={`${id}-review-asset-reliance-assets`}
                                                                           collapsedAccentColor_={COLOR_ASSETS_ACCOUNTS}
                                                                           ariaLabel_='assets-row'
                                                                           tableDisplayData_={assetRelianceDetailsData.assets}
                                                                           titleCellWidth_={TITLE_CELL_WIDTH}
                                                                           valueColumnSize_={assetStacksContainerWidth}
                                                                           additionalCellClasses_={createTableCellValueClassNames()} isReportExpanded_={true}
                                />}
                                {assetRelianceDetailsData.goals && <AccordionTableReport accordionTableId_={`${id}-review-asset-reliance-goals`}
                                                                                   collapsedAccentColor_={COLOR_GOALS}
                                                                                   ariaLabel_='goals-row'
                                                                                   tableDisplayData_={assetRelianceDetailsData.goals}
                                                                                   titleCellWidth_={TITLE_CELL_WIDTH}
                                                                                   valueColumnSize_={assetStacksContainerWidth}
                                                                                   additionalCellClasses_={createTableCellValueClassNames()} isReportExpanded_={true}
                                />}
                                {assetRelianceDetailsData.excessAssets && <AccordionTableReport accordionTableId_={`${id}-review-asset-reliance-excess-assets`}
                                                                 collapsedAccentColor_={excessAssetsAccentColor}
                                                                 ariaLabel_='excess-assets-row'
                                                                 tableDisplayData_={assetRelianceDetailsData.excessAssets}
                                                                 titleCellWidth_={TITLE_CELL_WIDTH}
                                                                 valueColumnSize_={assetStacksContainerWidth}
                                                                 additionalCellClasses_={createTableCellValueClassNames()}
                                />}
                            </main>
                            : <NoAssetsComponent/>
                        }
                        </div>
                    </PrintViewWrapper>
                )
            }) :
                <PrintViewWrapper displayName={displayName} key={1}>
                {<NoAssetsComponent/>}
                </PrintViewWrapper>
                }
        </>
    )
}
export default AssetRelianceContentReport;