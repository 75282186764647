import React, {useEffect, useState} from 'react';
import {Col, Grid, Row} from "xps-react";
import {AlertMessage, Button, Name} from '../../components';
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {useHistory, useParams} from "react-router-dom";
import {NewClientPrimaryContact} from "./NewClientPrimaryContact";
import {EffectiveTaxRates, taxRateWithDefaults} from "./EffectiveTaxRates";
import {referenceDataClient} from "../../core/ReferenceDataClient";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {EffectiveFees} from './EffectiveFees';
import {validateProfileResponseContainer} from '../models/ProfileResponse';
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {clearPartnerWorkstationState, selectLinkedUlek} from "../PartnerWorkstation/partnerWorkstationSlice";
import {
    NewClientProfileValidationContainer,
    selectIsSaveButtonClicked,
    selectIsValidForm,
    selectNewClientFormInteractions,
    selectNewClientFormValidation,
    selectProfileRequestForm,
    setIsSaveButtonClicked,
    setIsValidForm,
    setNewClientFormInteractions,
    setNewClientFormValidation,
    setProfileRequestForm
} from "./NewClientProfileSlice";
import NewClientFinancialModel from "./NewClientFinancialModel";
import {useAppInsights} from "../../AppInsights";
import {MeetingParticipation} from "../Meeting/Meeting";
import {validateInputRemovingSpaces} from "../../utils/stringUtils";
import {RouteWithProfileType} from "../../routes/types";

type NewClientProfileProps = {
    initialReferenceData?: ReferenceDataType,
}

export const defaultReferenceData: ReferenceDataType = {
    defaultPersonalPlanningHorizon: 100,
    statesOfResidency: [],
    taxRates: {
        federalTaxRateDefault: '',
        stateTaxRateDefault: '',
        capitalGainsPercentDefault: '',
        federalTaxRateMinimum: '',
        federalTaxRateMaximum: '',
        stateTaxRateMinimum: '',
        stateTaxRateMaximum: '',
        capitalGainsPercentMinimum: '',
        capitalGainsPercentMaximum: ''
    },
    managementFees: {
        managementFeePercentDefault: '',
        managementFeePercentMaximum: '',
        managementFeePercentMinimum: ''
    },
    accreditedStatus: {
        defaultValue: '',
        items: [],
    },
    allocationType: {
        defaultValue: '',
        items: [],
    }
};

const NewClientProfile: React.FC<NewClientProfileProps> = (props) => {
    const {
        initialReferenceData = defaultReferenceData,
    } = props;

    const history = useHistory();
    const msal = useMsal();
    // TODO: Refactor reference data to its own redux slice
    const [referenceData, updateReferenceData] = useState(initialReferenceData);
    const [accountName, updateAccountName] = useState('');

    const dispatch = useAppDispatch();
    const linkedUlek = useAppSelector(selectLinkedUlek)!;
    const profileRequestForm = useAppSelector(selectProfileRequestForm);
    const isSaveButtonClicked = useAppSelector(selectIsSaveButtonClicked)
    const {newClientProfileForm} = useAppSelector(selectNewClientFormValidation);
    const formInteractions = useAppSelector(selectNewClientFormInteractions);
    const isValidForm = useAppSelector(selectIsValidForm);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const appInsights = useAppInsights();
    const {type} = useParams<RouteWithProfileType>();

    useEffect(() => {
        referenceDataClient.getReferenceData()
            .then((data: ReferenceDataType) => {
                updateReferenceData(data);
                dispatch(setProfileRequestForm({
                    ...profileRequestForm,
                    primaryContact: {
                        ...profileRequestForm.primaryContact,
                        personalPlanningHorizon: data.defaultPersonalPlanningHorizon
                    },
                    effectiveTaxRate: taxRateWithDefaults(profileRequestForm.effectiveTaxRate, data.taxRates),
                    managementFees: data.managementFees.managementFeePercentDefault,
                    accreditedStatus: data.accreditedStatus.defaultValue,
                    allocationType: data.allocationType.defaultValue,
                }))
            })
    }, []);

    useEffect(() => {
        checkFormIsValid();
    }, [profileRequestForm]);

    useEffect(() => {
        updateAccountName(msalUtils.getAccountName(msal)!);
    }, [msal]);

    const checkFormIsValid = () => {
        const profileValidation: NewClientProfileValidationContainer = validateProfileResponseContainer(profileRequestForm);
        dispatch(setNewClientFormValidation(profileValidation));
        const isValid = Object.values(profileValidation).every(x => x === null);
        dispatch(setIsValidForm(isValid));
        return isValid;
    }

    const handleSaveButton = async () => {
        if (checkFormIsValid()) {
            if (saveButtonDisabled) {
                return;
            }
            setSaveButtonDisabled(true);

            clientManagementApiClient.postProfile({
                ...profileRequestForm,
                prospect: type.toUpperCase() === 'PROSPECT',
                createdByName: accountName,
                lastModifiedByName: accountName,
                resourceCode: linkedUlek
            }).then(profileResponse => {
                appInsights.trackEvent({
                    name: 'ProfileSettings',
                    properties:
                        {
                            screen: 'Profile Settings',
                            action: 'Save Button Click',
                            meetingStatus: MeetingParticipation.OUT_OF_MEETING,
                            data: 'Profile Created'
                        }
                })

                resetLinkedClient();
                history.push({
                    pathname: `/Profile/${profileResponse.id}/ClientProfile/FamilyTree`
                })

            }).catch((error) => {
                console.error('Could not create profile', error.message);
                setSaveButtonDisabled(false)
            })
        } else {
            dispatch(setIsSaveButtonClicked(true))
        }
    };

    const resetLinkedClient = () => {
        dispatch(clearPartnerWorkstationState());
    }

    const profileTypeFormattedText = () => {
        switch (type.toUpperCase()) {
            case ('PROSPECT'):
                return {
                    header: 'Prospect',
                }
            default:
                return {
                    header: 'Client',
                }
        }
    }

    return (
        <div className="new-client-profile-page">
            <div className="client-table">
                <Grid>
                    <Row>
                        <Col>
                            <span className="font-xxl font-xxl">{`New ${profileTypeFormattedText().header} Profile`}</span>
                            <p className="font-default color-text--error noMargin">* Required fields</p>
                        </Col>
                        <Col>
                            <div className="textalign-right">
                                <Button className="marginright-md"
                                        icon="none"
                                        id="cancel_new_client_profile_button"
                                        includeRef={false}
                                        kind="secondary"
                                        onClick={() => {
                                            resetLinkedClient();
                                            history.push(`/Dashboard/${type}`)
                                        }}
                                        size="medium"
                                        tabIndex={0}
                                        type="button"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    icon="none"
                                    id="save_new_client_profile_button"
                                    includeRef={false}
                                    kind="primary"
                                    onClick={handleSaveButton}
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                    disabled={saveButtonDisabled}
                                >
                                    SAVE
                                </Button>
                            </div>
                        </Col>
                        {!isValidForm && isSaveButtonClicked && (
                            <AlertMessage
                                className="display-flex justify-content-right alertBanner"
                                id="alertBanner"
                                fullWidth={false}
                                icon="warning"
                                showAlert={true}
                                showCloseBtn={false}
                                type="error"
                            >
                                <b className="alertBannerStyleName">Required fields missing</b>
                                &nbsp; &nbsp;
                                <b className="font-weight-400">All required fields must be entered to save a client
                                    profile.</b>
                            </AlertMessage>
                        )}
                    </Row>
                </Grid>
                <Grid>
                    <Row>
                        <Col>
                            <div className="h4">Profile Details</div>
                            <hr className="section"/>
                            <div className="parent">
                                <Name name={profileRequestForm.displayName}
                                      label={"Display Name"}
                                      onChange={(e: any) => dispatch(setProfileRequestForm({
                                          ...profileRequestForm,
                                          displayName: e.target.value && validateInputRemovingSpaces(e.target.value)
                                      }))}
                                      required={true}
                                      error={newClientProfileForm?.displayName}
                                      hasInteractions={formInteractions.displayName}
                                      whenUserHasInteracted={() => {
                                          dispatch(setNewClientFormInteractions({
                                              ...formInteractions,
                                              displayName: true
                                          }))
                                      }}
                                      forceShowErrors={isSaveButtonClicked}/>
                            </div>
                            <NewClientPrimaryContact referenceData={referenceData}/>
                        </Col>
                        <Col>
                            <EffectiveTaxRates taxRate={profileRequestForm.effectiveTaxRate}
                                               updateTaxRate={(taxRate) => {
                                                   dispatch(setProfileRequestForm({
                                                       ...profileRequestForm,
                                                       effectiveTaxRate: taxRate
                                                   }))
                                               }}
                                               referenceData={referenceData}/>
                            <NewClientFinancialModel referenceData={referenceData}
                                                     financialModel={{
                                                         accreditedStatus: referenceData.accreditedStatus.defaultValue,
                                                         allocationType: referenceData.allocationType.defaultValue,
                                                     }}
                                                     updateFinancialModel={(financialModel) => {
                                                         dispatch(setProfileRequestForm({
                                                             ...profileRequestForm,
                                                             accreditedStatus: financialModel.accreditedStatus,
                                                             allocationType: financialModel.allocationType
                                                         }))
                                                     }}
                            />
                            <EffectiveFees managementFees={profileRequestForm.managementFees}
                                           updateManagementFees={(managementFees) => {
                                               dispatch(setProfileRequestForm({
                                                   ...profileRequestForm,
                                                   managementFees: managementFees
                                               }))
                                           }}
                                           referenceData={referenceData}/>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default NewClientProfile;
