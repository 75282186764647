import React, {useEffect, useMemo} from 'react';
import {ReactDiagram} from 'gojs-react';
import {
    initializeTreeDiagramWithOtherMembers,
    useDocumentBoundsChangeHandler,
    useFamilyTreeViewportBoundsChangeHandler,
    useToResetFamilyTreeToDefault
} from "./RenderFamilyTreeUtils";
import {FamilyTreeLink, FamilyTreeNode, FamilyTreeViewportBounds} from "../models/FamilyTreeType";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectFamilyTreeViewportBounds, setFamilyTreeViewportBounds} from "./FamilyTreeSlice";
import {Point} from "gojs";

export interface FamilyTreeGraphProps {
    familyTreeData: Array<FamilyTreeNode>;
    familyTreeRelationshipData: Array<FamilyTreeLink>;
}

const FamilyTreeGraph: React.FC<FamilyTreeGraphProps> = ({
                                                             familyTreeData,
                                                             familyTreeRelationshipData,
                                                         }) => {
    const dispatch = useAppDispatch();
    const familyTreeViewportBounds = useAppSelector(selectFamilyTreeViewportBounds);
    const shouldReFitTreeWhenDocumentBoundsChange = useMemo(() => !familyTreeViewportBounds, []);

    const diagramReference = React.useRef<ReactDiagram>(null);

    const updateViewportBounds = (viewportBounds: FamilyTreeViewportBounds) => {
        dispatch(setFamilyTreeViewportBounds(viewportBounds));
    };
    useFamilyTreeViewportBoundsChangeHandler(diagramReference, updateViewportBounds);

    useDocumentBoundsChangeHandler(diagramReference, shouldReFitTreeWhenDocumentBoundsChange, updateViewportBounds);
    useToResetFamilyTreeToDefault(diagramReference, familyTreeViewportBounds);

    useEffect(() => {
        const diagram = diagramReference.current?.getDiagram();
        if (diagram && familyTreeViewportBounds) {
            diagram.scale = familyTreeViewportBounds.scale;
            diagram.position = new Point(familyTreeViewportBounds.position.x, familyTreeViewportBounds.position.y)
        }
    }, [
        diagramReference.current,
        familyTreeViewportBounds?.scale,
        familyTreeViewportBounds?.position.x,
        familyTreeViewportBounds?.position.y
    ]);

    return (
        <React.Fragment>
            <ReactDiagram
                ref={diagramReference}
                divClassName='diagram-component'
                initDiagram={() => initializeTreeDiagramWithOtherMembers()}
                nodeDataArray={familyTreeData}
                linkDataArray={familyTreeRelationshipData}
            />
        </React.Fragment>
    );
}

export default FamilyTreeGraph;