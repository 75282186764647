import Popover from "@nt/dds-react/core/Popover";
import React from "react";
import {Button} from "../../components";

export function SplitLifestyleInfoPopover(props: { show: boolean, direction: string}) {
    return <>
        {
            (
                props.show &&
                <span data-testid="split-lifestyle-info-popover">
                    <Popover
                        content={<div className="tax-info-card__content hover-text"
                        >
                            <span>This lifestyle goal is partially funded</span><br/>
                            <span>by non-investable assets. The years until</span><br/>
                            <span>flow, years of flow, and present value show the</span><br/>
                            <span>amount and time frame funded by the portfolio</span><br/>
                            <span>and funded by non-investable assets</span>
                        </div>}
                        direction={props.direction}
                        delay={0}
                    >
                        <Button className="popover-button"
                                icon="only"
                                iconName="info_outline"
                                kind="borderless"
                                size="small"
                        />
                    </Popover>
                </span>
            )
        }
    </>
}


