import {StandaloneAccount} from "../../models/StandaloneAccount";
import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import {DropdownGroup} from "xps-react";
import classNames from "classnames";

type AssetSummaryStandaloneAccountProps = {
    standaloneAccount: StandaloneAccount;
    onClickEdit: (id: string) => void;
    onClickDelete: (standaloneAccount: StandaloneAccount) => void;
    onClickViewHoldings: (id: string) => void;
    renderStandaloneAccountDetails: (standaloneAccount: StandaloneAccount) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string,
    hideActionMenu?: boolean
};
export const StandaloneAccountRow = ({
                                         standaloneAccount,
                                         onClickEdit,
                                         onClickDelete,
                                         onClickViewHoldings,
                                         renderStandaloneAccountDetails,
                                         actionsDisabled,
                                         gridClassName,
                                         hideActionMenu = false
                                     }: AssetSummaryStandaloneAccountProps) => {
    return (<div key={`standalone-account-row-${standaloneAccount.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}
                 aria-label={standaloneAccount.name}>
        {renderStandaloneAccountDetails(standaloneAccount)}
        <TableCell text={formatCurrency(standaloneAccount.inEstateValue)} className="textalign-right"/>
        {!hideActionMenu &&
            <TableActionDropdownMenu ariaLabel={`${standaloneAccount.name} Menu`} disabled={actionsDisabled}>
                <DropdownGroup groupName=" ">
                    <DropdownItem
                        itemText="Edit Account"
                        value="Edit Account"
                        onClick={() => onClickEdit(standaloneAccount.id)}
                    />
                    <DropdownItem
                        itemText="View Holdings"
                        value="View Holdings"
                        onClick={() => onClickViewHoldings(standaloneAccount.id)}
                    />
                </DropdownGroup>
                <DropdownGroup groupName=" ">
                    <DropdownItem
                        itemText="Delete Account"
                        value="Delete Account"
                        onClick={() => onClickDelete(standaloneAccount)}
                    >
                        <Icon name="delete" className="account-table-delete-icon"/>
                    </DropdownItem>
                </DropdownGroup>
            </TableActionDropdownMenu>}
    </div>);
};
