import {createAssetBarChartData} from "../AssetSummary/BarChart/AssetBarChartUtils";
import {AssetBarChartFooter} from "../AssetSummary/BarChart/AssetBarChartFooter";
import {BarChartSidebar} from "../../components";
import React, {useContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    InEstateAssetTotals,
    resetClientAssets,
    selectActiveFormAsset,
    selectClientAssets,
    setClientAssets
} from "../clientAssetsSlice";
import {InvestorGroupType, MemberGroup, MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {LegalEntityReadModel} from "../models/Ownership";
import {assetsApiClient} from "../AssetsApiClient";
import AssetSummary from "../AssetSummary/AssetSummary";
import CurrentNetWorth from "../CurrentNetWorth/CurrentNetWorth";
import {NO_OP} from "../../constants/common";
import CustomModal from "../../components/Modal/Custom/CustomModal";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {FamilyRelationshipType} from "../../ClientManagement/models/FamilyRelationshipType";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {SECOND_TO_DIE, SECOND_TO_DIE_ID} from "../models/LifeInsurance";
import {useAppInsights} from "../../AppInsights";
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {FamilyTreeType} from "../../ClientManagement/models/FamilyTreeType";
import {RouteWithId} from "../../routes/types";
import AssetsViewContext from "./AssetsViewContext";
import DeleteInvestmentProgramConfirmationModal from "./DeleteInvestmentProgramConfirmationModal";
import {InvestmentProgram} from "../models/InvestmentProgram";
import {Button, Modal} from "xps-react";
import DataEntrySideDrawer from "src/components/DataEntry/DataEntrySideDrawer";
import AddOptionsAndGrants from "../EquityCompensation/AddOptionsAndGrants";
import LegalAgreementHoldings from "../InvestmentProgram/LegalAgreementHoldings";
import HoldingsRouter from "../StandaloneAccount/HoldingsRouter";

type AssetsViewProps = {
    inEstateTotals: InEstateAssetTotals,
    familyTree?: FamilyTreeType,
    showDeathBenefit?: boolean,
    toggleShowDeathBenefit?: () => void
}

const initialDeleteModalProps = {
    showDeleteModal: false,
    modalTitle: "Asset",
    assetDescription: "",
    assetId: "",
    handleDelete: NO_OP
} as DeleteModalProps;

export const AssetsView = ({
                               inEstateTotals,
                               familyTree,
                               showDeathBenefit = false,
                               toggleShowDeathBenefit,
                           }: AssetsViewProps) => {

    const viewType = useContext(AssetsViewContext);
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const appInsights = useAppInsights();
    const {approvedProfile, profile, isLoading: isLoadingProfile} = useProfileAndProposals(id);

    const isAssetSummary = viewType === "AssetSummary"
    const activeProfileId = profile?.id;
    const displayName = approvedProfile.displayName;

    const clientAssets = useAppSelector(selectClientAssets);
    const activeFormAsset = useAppSelector(selectActiveFormAsset);

    const [isLoadingAssetView, setIsLoadingAssetView] = useState(true);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const [legalEntities, setLegalEntities] = useState<LegalEntityReadModel[]>();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [openDrawer, updateOpenDrawer] = useState(false);
    const [openHoldings, updateOpenHoldings] = useState(false);
    const [openOptionsAndGrant, setOpenOptionsAndGrant] = useState(false);
    const [deleteModalProps, setDeleteModalProps] = useState<DeleteModalProps>(initialDeleteModalProps);
    const [showLegalEntityDeleteError, setShowLegalEntityDeleteError] = useState<boolean>(false);
    const [openIPConfirmation, setOpenIPConfirmation] = useState(false);

    useEffect(() => {
        if (isLoadingProfile) return;

        appInsights.startTrackPage(viewType);
        setIsLoadingAssetView(true);
        Promise.all([
            assetsApiClient.getAssetsSummary(activeProfileId),
            clientManagementApiClient.getInvestorGroup(activeProfileId),
            assetsApiClient.getLegalEntities(activeProfileId),
            clientManagementApiClient.getMemberGroup(activeProfileId)
        ])
            .then(([assetsResponse,
                       investorGroupResponse,
                       legalEntitiesResponse,
                       memberGroupResponse]) => {
                dispatch(setClientAssets(assetsResponse));
                setInvestorGroup(investorGroupResponse);
                setLegalEntities(legalEntitiesResponse);
                setMemberGroup(handleMemberGroupResponse(memberGroupResponse, familyTree));
                setIsLoadingAssetView(false);
            })
            .catch(error => console.error('Could not fetch asset details', error.message))
            .finally(() =>
                appInsights.stopTrackPage(viewType, window.location.href, {
                    profileId: activeProfileId,
                })
            );
    }, [isLoadingProfile, activeProfileId, openDrawer]);

    useEffect(() => {
        return function cleanup() {
            dispatch(resetClientAssets());
        }
    }, []);

    useEffect(() => {
        const pathArray = history.location.pathname.split("/");
        const isHoldingsPage = pathArray.includes("Holdings") || pathArray.includes("LegalAgreementHoldings");
        updateOpenHoldings(isHoldingsPage);
        if (!isHoldingsPage) {
            const onDifferentPage = !history.location.pathname.endsWith(viewType);
            updateOpenDrawer(onDifferentPage);
        }
        setOpenOptionsAndGrant(pathArray.includes("AddOptionsAndGrants"));
    }, [history.location.pathname]);

    if (isLoadingProfile || !clientAssets || !investorGroup || !legalEntities || !memberGroup) {
        return <LoadingIndicator/>;
    }

    const getLegalAgreementIds = (investmentProgram: InvestmentProgram | null) => {
        return (investmentProgram?.legalAgreements ?? []).map(legalAgreement => legalAgreement.id);
    };

    const clearLegalAgreementCollateral = (legalAgreementIds: string[]) => {
        const personalLiabilities = clientAssets.personalLiabilities
            .map(personalLiability => ({...personalLiability}));

        personalLiabilities.forEach(personalLiability => {
            if(legalAgreementIds.includes(personalLiability.collateralId ?? '')) {
                personalLiability.collateralId = null;
                personalLiability.collateral = null;
            }
        })

        return personalLiabilities;
    };

    const onRemoveInvestmentProgram = async () => {
        const response = await assetsApiClient.deleteInvestmentProgram(activeProfileId)

        if (response.status === 200) {
            const deletedLegalAgreementIds = getLegalAgreementIds(clientAssets.investmentProgram);
            const personalLiabilities = clearLegalAgreementCollateral(deletedLegalAgreementIds);

            dispatch(setClientAssets({...clientAssets, personalLiabilities, investmentProgram: null}));
        }
        setOpenIPConfirmation(false);
    }

    const onRefreshInvestmentProgramHoldings = async () => {
        const legalAgreementIdsBefore = getLegalAgreementIds(clientAssets.investmentProgram);
        const investmentProgram = await assetsApiClient.refreshInvestmentProgramHoldings(activeProfileId)

        if (investmentProgram) {
            const legalAgreementIdsAfter = getLegalAgreementIds(investmentProgram);
            const deletedLegalAgreementIds = legalAgreementIdsBefore
                .filter(legalAgreementId => !legalAgreementIdsAfter.includes(legalAgreementId));
            const personalLiabilities = clearLegalAgreementCollateral(deletedLegalAgreementIds);

            dispatch(setClientAssets({...clientAssets, personalLiabilities, investmentProgram: investmentProgram}));
        }
    }

    const handleArrangeAssets = () => {
        history.push(`/Profile/${activeProfileId}/ClientProfile/AssetSummary/ArrangeAssets`)
    }

    async function onDeleteLegalEntity(legalEntityId: string) {
        try {
            await assetsApiClient.deleteLegalEntity(activeProfileId, legalEntityId);
            setLegalEntities(await assetsApiClient.getLegalEntities(activeProfileId));
        } catch (e) {
            setShowLegalEntityDeleteError(true)
        }
    }

    return <article className="asset-summary-page layout-split-left">
        <BarChartSidebar
            data={createAssetBarChartData(inEstateTotals, isAssetSummary ? activeFormAsset : null)}
            noDataText='No assets have been captured yet.'
            displayName={displayName}
            title={isAssetSummary ? 'Your Assets' : 'Current Net Worth'}>
            <AssetBarChartFooter
                clientAssets={clientAssets}
                inEstateAssetsTotals={inEstateTotals}
                activeFormAsset={isAssetSummary ? activeFormAsset : null}
                investorGroup={investorGroup}
                title={isAssetSummary ? 'Total Net Assets' : 'Current Net Worth'}
            />
        </BarChartSidebar>
        {isAssetSummary ?
            <AssetSummary
                legalEntities={legalEntities}
                clientAssets={clientAssets}
                investorGroup={investorGroup}
                openDrawer={openDrawer}
                openHoldings={openHoldings}
                isLoading={isLoadingAssetView}
                profileId={profile.id}
                onRemoveAssetClick={setDeleteModalProps!}
                onRemoveInvestmentProgram={() => setOpenIPConfirmation(true)}
                onDeleteLegalEntity={onDeleteLegalEntity}
                onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                memberGroup={memberGroup}
                onArrangeAssets={handleArrangeAssets}
            /> :
            <CurrentNetWorth
                profileId={profile.id}
                clientAssets={clientAssets}
                investorGroup={investorGroup}
                memberGroup={memberGroup}
                openDrawer={openDrawer}
                legalEntities={legalEntities}
                isLoading={isLoadingAssetView}
                showDeathBenefit={showDeathBenefit}
                toggleShowDeathBenefit={toggleShowDeathBenefit}
                onRemoveAssetClick={setDeleteModalProps!}
                onRemoveInvestmentProgram={() => setOpenIPConfirmation(true)}
                onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                onDeleteLegalEntity={onDeleteLegalEntity}
            />
        }

        <CustomModal
            isOpen={deleteModalProps.showDeleteModal}
            title={`Delete this ${deleteModalProps.modalTitle}?`}
            content={
                <>
                    <div className="font-md">
                        {`The ${deleteModalProps.assetDescription} ${deleteModalProps.assetType?.toLowerCase()} and all associated data will be deleted permanently.`}
                    </div>
                </>
            }
            onClickConfirm={async () => {
                const apiDeleteFn = getApiDeleteFunction(deleteModalProps.assetType!);
                const response = await apiDeleteFn(profile.id, deleteModalProps.assetId);
                const responseStatus = response.status;

                if (responseStatus === 200) {
                    dispatch(setClientAssets(await assetsApiClient.getAssetsSummary(activeProfileId)));
                }

                setDeleteModalProps({
                    ...deleteModalProps,
                    showDeleteModal: false
                });
            }}
            onClickCancel={() => setDeleteModalProps({...deleteModalProps, showDeleteModal: false})}
            confirmText={`Delete ${deleteModalProps.modalTitle}`}
            cancelText={`Cancel`}
        />

        <Modal
            header="Unable to Delete Entity"
            label="modal-label"
            id={'delete-legal-entity-error'}
            size='small'
            isOpen={showLegalEntityDeleteError}
            showCloseButton={false}
            footer={<Button
                        className={'marginright-12'}
                        destructive={false}
                        kind={'primary'}
                        onClick={() => setShowLegalEntityDeleteError(false)}
                        size="medium">
                        EXIT
                    </Button>}>
           <span className={'font-md'}>This entity has linked accounts. To delete this entity, please change asset ownership or delete assets, then try again.</span>
        </Modal>

        <DeleteInvestmentProgramConfirmationModal
            isOpen={openIPConfirmation}
            investmentProgram={clientAssets.investmentProgram}
            onConfirm={onRemoveInvestmentProgram}
            onCancel={() => setOpenIPConfirmation(false)}
        />
        <DataEntrySideDrawer isOpen={openHoldings} size="full" formLayout="split"
                                 scrollableRegionId='holdings-side-drawer'>
                <Switch>
                    <Route path={`/Profile/:profileId/ClientProfile/${viewType}/StandaloneAccount/:assetId/Holdings`}
                           component={HoldingsRouter}/>
                    <Route exact path={`/Profile/:profileId/ClientProfile/${viewType}/LegalAgreementHoldings/:assetId`}
                           component={LegalAgreementHoldings}/>
                </Switch>
            </DataEntrySideDrawer>
            <DataEntrySideDrawer size="full" formLayout="split" isOpen={openOptionsAndGrant}>
                <Switch>
                    <Route
                        path={`/Profile/:profileId/ClientProfile/${viewType}/EquityCompensation/:assetId/AddOptionsAndGrants`}
                        component={AddOptionsAndGrants}/>
                </Switch>
            </DataEntrySideDrawer>
    </article>
}

export type DeleteModalProps = {
    showDeleteModal: boolean,
    modalTitle: "Asset" | "Liability"
    assetDescription: string,
    assetType?: AssetTypeString,
    assetId: string,
}

type AssetTypeString =
    'standalone account'
    | 'personal asset'
    | 'social security'
    | 'general inflow'
    | 'liability'
    | 'life insurance'
    | 'equity compensation' ;

function getApiDeleteFunction(assetType: AssetTypeString): (profileId: string, assetId: string) => Promise<Response> {
    if (assetType === 'general inflow') {
        return assetsApiClient.deleteGeneralInflow;
    }

    if (assetType === 'social security') {
        return assetsApiClient.deleteSocialSecurity;
    }

    if (assetType === 'personal asset') {
        return assetsApiClient.deletePersonalAsset;
    }

    if (assetType === 'liability') {
        return assetsApiClient.deletePersonalLiability;
    }

    if (assetType === 'life insurance') {
        return assetsApiClient.deleteLifeInsurance;
    }

    if (assetType === 'equity compensation') {
        return assetsApiClient.deleteEquityCompensation;
    }

    return assetsApiClient.deleteAccount;
}

function handleMemberGroupResponse(memberGroup: MemberGroup, familyTree: FamilyTreeType | undefined) {
    let additionalMembers: MemberGroupMember[] = [...memberGroup.additionalMembers];
    let isSecondToDiePresent = false;
    if ((familyTree !== null && familyTree!.primaryContact !== null && familyTree!.primaryContact.family.length > 0)) {
        isSecondToDiePresent = familyTree!.primaryContact.family.some(member =>
            ((member.type === FamilyRelationshipType.SPOUSE || member.type === FamilyRelationshipType.EX_SPOUSE)
                && (member.fromMember.lifeStatus === LifeStatus.Living)));
    }
    if (isSecondToDiePresent) {
        additionalMembers.push({
            id: SECOND_TO_DIE_ID,
            firstName: SECOND_TO_DIE,
            lastName: "",
            lifeStatus: LifeStatus.Living,
            deceasedDate: null,
            age: 0,
            relationshipType: null
        });
    }
    return {...memberGroup, additionalMembers: [...additionalMembers]};
}