import {ServiceTeamMember} from "./models/ServiceTeam";
import {MissingLegalAgreements, PartnerResource, PartnerResourceRequest} from "./models/PartnerResource";
import {get, HttpOptions} from "../core/api";

export const getServiceTeam = (resourceCode: string): Promise<ServiceTeamMember[]> => {
    return get(`/partner/partners?resourceCode=${resourceCode}`)
        .then((res: Response) => res.json());
}

export const getPartnerDetail = (partnerCode: string): Promise<ServiceTeamMember> => {
    return get(`/partner/partnerDetail?partnerCode=${partnerCode}`)
        .then((res: Response) => res.json());
}

export const getPartnerResources = (partnerResourceRequest: PartnerResourceRequest): Promise<PartnerResource[]> => {
    const params: HttpOptions = {
        queryParams: partnerResourceRequest
    }

    return get(`/aggregation/resources`, params).then((res: Response) => {
        if (res.status === 200 || res.status === 201) {
            return res.json()
        } else {
            return [];
        }
    });
}

export const getLegalAgreements = (partnerCode: string): Promise<MissingLegalAgreements[]> => {
    return get(`/partner/unmatchedLegalAgreements?partnerCode=${partnerCode}`)
        .then((res: Response) => res.json());
}

export const partnerApiClient = {
    getServiceTeam: getServiceTeam,
    getPartnerDetail: getPartnerDetail,
    getPartnerResources: getPartnerResources,
    getLegalAgreements: getLegalAgreements,
};
