import {useAppDispatch, useAppSelector} from "../../store/hooks";
import React, {ChangeEvent} from "react";
import {DropdownGroup} from "xps-react";
import {DropdownItem, PageActionMenu, PresentationPaneButton, PresentationPaneHeader} from "../../components";
import {useHistory, useParams} from "react-router-dom";
import {PortfolioReserveOptionsType} from "./models/PortfolioReserveOptions";
import {selectPortfolioReserveUserPreferences, setPortfolioReserveOptions} from "./PortfolioReserveSlice";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";

type SetPortfolioReserveHeaderProps = {
    displaySetReserveTargetButton: boolean;
    isSetReserve: boolean;
}

const SetPortfolioReserveHeader = (props: SetPortfolioReserveHeaderProps) => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName;
    const {portfolioReserveOptions} = useAppSelector(selectPortfolioReserveUserPreferences);
    const dispatch = useAppDispatch();

    const {displaySetReserveTargetButton} = props;
    const reserveButtonText = props.isSetReserve ? 'Set My Reserve Target' : 'Change Reserve Target';


    const handleButtonClick = () => {
        history.push(`/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve/EditPortfolioReserve`, {forceNavigate: true});
    }

    const handleReserveOptionDropdown = (e: ChangeEvent<HTMLInputElement>, portfolioReserveOptionsType: PortfolioReserveOptionsType) => {
        let selectedPortfolioReserveOptions = {...portfolioReserveOptions}
        switch (portfolioReserveOptionsType) {
            case PortfolioReserveOptionsType.SHOW_RESERVE_TARGET_DETAILS:
                selectedPortfolioReserveOptions.showReserveTargetDetails = !selectedPortfolioReserveOptions.showReserveTargetDetails;
                break;
            case PortfolioReserveOptionsType.SHOW_RISK_IMPACT:
                selectedPortfolioReserveOptions.showRiskImpact = !selectedPortfolioReserveOptions.showRiskImpact;
                break;
            case PortfolioReserveOptionsType.SHOW_OUTCOME_RANGE:
                selectedPortfolioReserveOptions.showOutcomeRange = !selectedPortfolioReserveOptions.showOutcomeRange;
                break;
            case PortfolioReserveOptionsType.SHOW_ASSET_ALLOCATION:
                selectedPortfolioReserveOptions.showAssetAllocation = !selectedPortfolioReserveOptions.showAssetAllocation;
                break;
            default:
                break;
        }

        dispatch(setPortfolioReserveOptions(selectedPortfolioReserveOptions));
    }

    const titleRowChildren = () => (
        displaySetReserveTargetButton ? <div>
                <PresentationPaneButton
                    className="set-my-reserve-target"
                    kind="secondary"
                    onClick={handleButtonClick}
                >
                    {reserveButtonText}
                </PresentationPaneButton>
            </div>
            :
            <PageActionMenu
                className="three-dots-menu"
                panelHeight="auto"
                panelWidth={240}
                aria-label="show-portfolio-reserve-options"
                key={"page-action-menu"}
            >
                <DropdownGroup groupName=" " key={"ls-dropdown-group"} show={true}>
                    <DropdownItem className="reserve-page-action-menu-options" value="" itemText="">
                        Show Reserve Target Details
                        <input
                            id="showReserveTargetDetails"
                            name="show-reserve-target-details"
                            type="checkbox"
                            aria-label="show-reserve-target-details"
                            key={"show-reserve-target-details"}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleReserveOptionDropdown(e, PortfolioReserveOptionsType.SHOW_RESERVE_TARGET_DETAILS)
                            }}
                            checked={portfolioReserveOptions.showReserveTargetDetails}
                        />
                    </DropdownItem>
                    <DropdownItem className="reserve-page-action-menu-options" value="" itemText="">
                        Show 1 Year Risk Impact
                        <input
                            id="showRiskImpact"
                            name="show-risk-impact"
                            type="checkbox"
                            aria-label="show-risk-impact"
                            key={"show-risk-impact"}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleReserveOptionDropdown(e, PortfolioReserveOptionsType.SHOW_RISK_IMPACT)
                            }}
                            checked={portfolioReserveOptions.showRiskImpact}
                        />
                    </DropdownItem>
                    <DropdownItem className="reserve-page-action-menu-options" value="" itemText="">
                        Show Range of Outcomes
                        <input
                            id="showOutcomeRange"
                            name="show-outcome-range"
                            type="checkbox"
                            aria-label="show-outcome-range"
                            key={"show-outcome-range"}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleReserveOptionDropdown(e, PortfolioReserveOptionsType.SHOW_OUTCOME_RANGE)
                            }}
                            checked={portfolioReserveOptions.showOutcomeRange}
                        />
                    </DropdownItem>
                    <DropdownItem className="reserve-page-action-menu-options" value="" itemText="">
                        Show Asset Allocation
                        <input
                            id="showAssetAllocation"
                            name="show-asset-allocation"
                            type="checkbox"
                            aria-label="show-asset-allocation-option"
                            key={"show-asset-allocation"}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleReserveOptionDropdown(e, PortfolioReserveOptionsType.SHOW_ASSET_ALLOCATION)
                            }}
                            checked={portfolioReserveOptions.showAssetAllocation}
                        />
                    </DropdownItem>
                </DropdownGroup>
            </PageActionMenu>);

    return <PresentationPaneHeader className="set-portfolio-reserve-page__header"
                                   displayName={displayName}
                                   title="The Portfolio Reserve safeguards your lifestyle spending, particularly during a market downturn."
                                   titleRow={titleRowChildren()}
    />
}

SetPortfolioReserveHeader.defaultProps = {
    displaySetReserveTargetButton: false
}

export default SetPortfolioReserveHeader;
