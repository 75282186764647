import {useEffect} from "react";
import {
    selectActiveFormAsset,
    selectClientAssets, selectInEstateCnwAssetTotals,
    selectInEstateCnwAssetTotalsUsingDeathBenefit} from "../../../Assets/clientAssetsSlice";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import useProfileAndProposals from "../../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import {CurrentNetWorthReportView} from "./CurrentNetWorthReportView";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {setAccordionState} from "../../../components/Accordion/accordionSlice";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";


const accordionIds = [
    {
        id: "CurrentNetWorthInEstateAssetListReport",
        expandedItems: ["AccountsCNWReport", "PersonalAssetsCNWReport", "FutureInflowsCNWReport", "LifeInsurancesCNWReport", "PersonalLiabilitiesCNWReport"]
    }]


const accordionPreferencesIds = [
    {
        id:"CurrentNetWorthOutOfEstateAccordionReport",
        expandedItems: [ "Irrevocable Trust",
            "Irrevocable Life Insurance Trust (ILIT)",
            "Charitable Foundation",
            "Donor Advised Fund (DAF)",
            "529 Plan",
            "Dynasty Trust",
            "LP, LLC, Other Partnership",
            "S Corp",
            "C Corp",
            "Other"]
    }
]

export type CurrentNetWorthReportProps = {
    showDeathBenefit: boolean,
    displayName: string,
    investorGroup: InvestorGroupType,
    legalEntities: LegalEntityReadModel[],
    isDetailed: boolean
}

export const CurrentNetWorthReport  = ({
    showDeathBenefit, 
    displayName,
    isDetailed,
    investorGroup,
    legalEntities
}:CurrentNetWorthReportProps) => {
    const dispatch = useAppDispatch();

    const clientAssets = useAppSelector(selectClientAssets);
    const inEstateCnwAssetTotals = useAppSelector(showDeathBenefit ? selectInEstateCnwAssetTotalsUsingDeathBenefit : selectInEstateCnwAssetTotals)!;
    const activeFormAsset = useAppSelector(selectActiveFormAsset);

    useEffect(() => {
        accordionIds.forEach(accordion => {
            dispatch(setAccordionState({
                accordionId: accordion.id,
                state: {
                    expandedItems: accordion.expandedItems
                }
            }));
        });

        accordionPreferencesIds.forEach(accordion=>{
            dispatch(setAccordionPreferencesState({
                accordionId: accordion.id,
                state: {
                    expandedItems: accordion.expandedItems
                }
            }));
        })
    }, [clientAssets]);

    if (!clientAssets || !investorGroup || !legalEntities) {
        return <LoadingIndicator/>;
    }

    return <CurrentNetWorthReportView
        isDetailed={isDetailed}
        clientAssets={clientAssets}
        investorGroup={investorGroup}
        showDeathBenefit={showDeathBenefit}
        displayName={displayName}
        inEstateTotals={inEstateCnwAssetTotals}
        activeFormAsset={activeFormAsset!}
        legalEntities={legalEntities}
    />
}
