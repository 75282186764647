import React from "react";
import {StockGrant} from "../../models/EquityCompensation";
import StockGrantTableRow from "./StockGrantTableRow";

export type StockGrantTableProps = {
    stockGrants: StockGrant[],
    deleteStockGrant: (stockIndex: number) => void;
    updateStockGrant: (stockGrant: StockGrant, stockIndex: number) => void;
    handleEditVesting: (stockIndex: number) => void;
}

const StockGrantTable: React.FC<StockGrantTableProps> = ({
                                                             stockGrants,
                                                             updateStockGrant,
                                                             handleEditVesting,
                                                             deleteStockGrant
                                                         }) => {
    return <div role="table" className="stock-grants-grid stock-grants-grid-table" aria-label="Stock Grants Table">
        <StockGrantsTableHeader/>
        {stockGrants.map((stockGrant, index) =>
            <StockGrantTableRow
                key={index+'-stock-grant'}
                index={index}
                stockGrant={stockGrant}
                updateStockGrant={updateStockGrant}
                handleEditVesting={handleEditVesting}
                deleteStockGrant={deleteStockGrant}
            />
        )}
    </div>;
}

function StockGrantsTableHeader() {
    return <div role="row" className="stock-grants-grid stock-grants-grid-table-header" tabIndex={-1}>
        <span role="columnheader" className="textalign-left">Grant Description/ID</span>
        <span role="columnheader" className="textalign-left">Grant date</span>
        <span role="columnheader" className="textalign-left">Type</span>
        <span role="columnheader" className="textalign-left">Expiration Date</span>
        <span role="columnheader" className="textalign-right">Shares Granted</span>
        <span role="columnheader" className="textalign-right">Shares Vested</span>
        <span role="columnheader" className="textalign-right">Shares Unvested</span>
        <span role="columnheader" className="textalign-right">Unvested Mkt. Value</span>
        <span role="columnheader" className="textalign-right">After Tax Unvested Mkt. Value</span>
        <span role="columnheader" className="textalign-right"></span>
    </div>;
}

export default StockGrantTable;