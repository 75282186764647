import {EquityCompensationFormData} from "src/Assets/models/EquityCompensation";
import {AssetsSummary, CurrentNetWorthAsset, InvestmentProgramSummary} from "../../models/Assets";
import {LegalAgreement} from "../../models/InvestmentProgram";
import {LifeInsurance} from "../../models/LifeInsurance";
import {
    LegalEntityOwnership,
    MemberOwnership,
    MemberOwnershipFormData,
    OwnershipCategory
} from "../../models/Ownership";
import {PersonalAsset} from "../../models/PersonalAsset";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {PersonalLiability} from "../../models/PersonalLiability";
import { InvestorGroupType } from "src/ClientManagement/models/InvestorGroupType";
import { formatCurrency } from "src/utils/format";

export function calculateOutOfEstateTotalValue(assets: AssetsSummary, useDeathBenefitForLifeInsurance = false): number {
    const personalAssetsTotal = assets.personalAssets.data.reduce((previousValue, currentValue) => {
        const outOfEstateValue = currentValue.presentValue - currentValue.inEstateValue;
        return previousValue + outOfEstateValue;
    }, 0);

    const lifeInsuranceTotal = calculateOutOfEstateLifeInsuranceTotalValue(assets.lifeInsurances.data, useDeathBenefitForLifeInsurance);

    const standaloneAccountsTotal = assets.accounts.data.reduce((previousValue, standaloneAccount) => {
        const totalMarketValue = standaloneAccount.holdings.totalMarketValue ? standaloneAccount.holdings.totalMarketValue : 0;
        const outOfEstateValue = totalMarketValue - standaloneAccount.inEstateValue;
        return previousValue + outOfEstateValue;
    }, 0);

    const investmentProgramTotal = assets.investmentProgram
        ? getOutOfEstateTotalValueForInvestmentProgram(assets.investmentProgram.legalAgreements)
        : 0;

    const personalLiabilityTotal = calculatePersonalLiabilityOutOfEstateTotalValue(assets.personalLiabilities);

    return personalAssetsTotal + standaloneAccountsTotal + lifeInsuranceTotal + investmentProgramTotal - personalLiabilityTotal;
}

export function calculateInEstateTotalValue(assets: AssetsSummary): number {
    const accountTotal = getInEstateTotalPresentValue(assets.accounts.data);
    const equityCompensationTotal = calculateInEstateEquityCompensationTotalValue(assets.equityCompensations.data);
    const personalAssetTotal = getInEstateTotalPresentValue(assets.personalAssets.data);
    const lifeInsuranceTotal = calculateInEstateLifeInsuranceTotalValue(assets.lifeInsurances.data);
    const generalInflowsTotal = assets.generalInflows.totalPresentValue;
    const socialSecuritiesTotal = assets.socialSecurities.totalPresentValue;
    const investmentProgramTotal = assets.investmentProgram ? getInEstateTotalValueForInvestmentProgram(assets.investmentProgram.legalAgreements) : 0;

    return accountTotal + personalAssetTotal + lifeInsuranceTotal + generalInflowsTotal + socialSecuritiesTotal + investmentProgramTotal + equityCompensationTotal;
}

export function calculateCNWInEstateTotalValue(assets: AssetsSummary, useDeathBenefitForLifeInsurance = false): number {
    const accountTotal = getInEstateTotalPresentValue(assets.accounts.data);
    const equityCompensationTotal = calculateInEstateCNWEquityCompensationTotalValue(assets.equityCompensations.data);
    const personalAssetTotal = getInEstateTotalPresentValue(assets.personalAssets.data);
    const lifeInsuranceTotal = calculateInEstateLifeInsuranceTotalValue(assets.lifeInsurances.data, useDeathBenefitForLifeInsurance);
    const investmentProgramTotal = assets.investmentProgram ? getInEstateTotalValueForInvestmentProgram(
        assets.investmentProgram.legalAgreements) : 0;
    const totalInEstateLiabilitiesValue = assets.liabilitiesValue.inEstateValue;
    return (accountTotal + equityCompensationTotal + personalAssetTotal + lifeInsuranceTotal + investmentProgramTotal) - totalInEstateLiabilitiesValue;
}

export function calculateCNWAssetsMemberOwnedTotalPresentValue(memberId: string, assets: CurrentNetWorthAsset[]): number {
    return assets.reduce((total, asset) => {
        return total + calculateMemberOwnedValue(memberId, asset.memberOwnerships, asset.ownershipCategory, asset.presentValue)
    }, 0)
}

export function calculateCNWAssetsJointlyOwnedTotalPresentValue(assets: CurrentNetWorthAsset[]): number {
    return assets.reduce((total, asset) => {
        return total + calculateJointlyOwnedValue(asset.ownershipCategory, asset.presentValue);
    }, 0);
}

export function calculateMemberOwnedValue(memberId: string, assetMemberOwnerships: MemberOwnership[], ownershipCategory: OwnershipCategory, assetValue: number): number {
    if (ownershipCategory === "JTWROS (Joint Tenants with Rights of Survivorship)") return 0;
    const percentageOwned = assetMemberOwnerships.find(memberOwnership => memberOwnership.member.id === memberId)?.percentage
    return (assetValue && assetValue > 0 && percentageOwned) ? (assetValue * percentageOwned) / 100 : 0;
}

export function calculateEquityCompensationsPrimaryMemberOwnedValue(equityCompensation: EquityCompensationFormData, investorGroup: InvestorGroupType): string {
    if(equityCompensation.owner === investorGroup.primaryMember.id) {
        return formatCurrency(equityCompensation.afterTaxVestedValue || undefined);
    }
    return '--';
}

export function calculateEquityCompensationsPartnerMemberOwnedValue(equityCompensation: EquityCompensationFormData, investorGroup: InvestorGroupType): string {
    if(equityCompensation.owner === investorGroup?.partnerMember?.id) {
        return formatCurrency(equityCompensation.afterTaxVestedValue || undefined);
    }
    return '--';
}

export function calculateJointlyOwnedValue(ownershipCategory: OwnershipCategory, assetValue: number): number {
    return ownershipCategory === "JTWROS (Joint Tenants with Rights of Survivorship)" ? assetValue : 0;
}

export function calculatePersonalAssetInEstateTotalValue(personalAssets: PersonalAsset[]): number {
    return personalAssets.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.inEstateValue;
    }, 0);
}

export function calculateInEstateLifeInsuranceTotalValue(lifeInsurances: LifeInsurance[], useDeathBenefitForLifeInsurance = false): number {
    return lifeInsurances
        .filter(lifeInsurance => useDeathBenefitForLifeInsurance || lifeInsurance.isCashValueWillFundGoals)
        .reduce((previousValue, currentValue) => {
            const lifeInsuranceValue = useDeathBenefitForLifeInsurance ? currentValue.deathBenefitValue : currentValue.cashValue;
            return previousValue + calculateInEstateFormValueForLifeInsurance(lifeInsuranceValue, currentValue.memberOwnerships);
        }, 0);
}

export function calculateInEstateEquityCompensationTotalValue(equityCompensations: EquityCompensationFormData[]): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = calculateEquityCompensationValue(currentValue);
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateInEstateCNWEquityCompensationTotalValue(equityCompensations: EquityCompensationFormData[]): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = currentValue.afterTaxVestedValue || 0;
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateTotalEquityCompensationsPrimaryOwnedValue(equityCompensations: EquityCompensationFormData[], investorGroup: InvestorGroupType): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = (currentValue.owner === investorGroup.primaryMember.id) 
                ? currentValue.afterTaxVestedValue || 0 
                : 0;
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateTotalEquityCompensationsPartnerOwnedValue(equityCompensations: EquityCompensationFormData[], investorGroup: InvestorGroupType): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = (currentValue.owner === investorGroup.partnerMember?.id) 
                ? currentValue.afterTaxVestedValue || 0 
                : 0;
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateEquityCompensationValue(currentValue: EquityCompensationFormData) {
    return (currentValue.afterTaxVestedValue || 0) + (currentValue.afterTaxUnvestedValue || 0);
}

export function calculateInEstateFormValueForLifeInsurance(value: number, memberOwnerships: MemberOwnership[]): number {
    const inEstatePercentageTotal = memberOwnerships.filter(member => member.isInEstateMember).reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);
    return inEstatePercentageTotal * value / 100;
}

export function calculateOutOfEstateLifeInsuranceTotalValue(lifeInsurances: LifeInsurance[], useDeathBenefitForLifeInsurance = false): number {
    return lifeInsurances
        .filter(lifeInsurance => useDeathBenefitForLifeInsurance || lifeInsurance.isCashValueWillFundGoals)
        .reduce((previousValue, currentValue) => {
            const lifeInsuranceValue = useDeathBenefitForLifeInsurance ? currentValue.deathBenefitValue : currentValue.cashValue;
            return previousValue + calculateOutOfEstateFormValueForLifeInsurance(lifeInsuranceValue, currentValue.memberOwnerships, currentValue.legalEntityOwnerships);
        }, 0);
}

export function calculateOutOfEstateFormValueForLifeInsurance(value: number, memberOwnerships: MemberOwnership[], legalEntityOwnerships: LegalEntityOwnership[]): number {
    const outOfEstateMemberOwnershipsTotal = memberOwnerships.filter(member => !member.isInEstateMember).reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);

    const legalEntityOwnershipsTotal = legalEntityOwnerships.reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);

    return (outOfEstateMemberOwnershipsTotal + legalEntityOwnershipsTotal) * value / 100;
}

export function calculatePersonalLiabilityOutOfEstateTotalValue(personalLiabilities: PersonalLiability[]): number {
    return personalLiabilities.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.loanBalanceEstateValue.outOfEstateValue;
    }, 0);
}

export function calculateAllAccountsInEstateTotalMarketValue(
    standaloneAccounts: StandaloneAccount[],
    investmentProgram: InvestmentProgramSummary | null) {
    return calculateInEstateTotalMarketValueForAccounts(standaloneAccounts, investmentProgram?.legalAgreements || [])
}

export function calculateInEstateTotalMarketValueForAccounts(
    standaloneAccounts: StandaloneAccount[],
    legalAgreements: LegalAgreement[]) {
    const standaloneAccountTotal = getInEstateTotalPresentValue(standaloneAccounts);
    const investmentProgramTotal = getInEstateTotalValueForInvestmentProgram(legalAgreements);
    return standaloneAccountTotal + investmentProgramTotal;
}

export function calculateAllAccountsInEstateInvestableTotalValue(
    standaloneAccounts: StandaloneAccount[],
    investmentProgram: InvestmentProgramSummary | null) {
    return calculateInEstateInvestableTotalValueForAccounts(standaloneAccounts, investmentProgram?.legalAgreements || [])
}

export function calculateInEstateInvestableTotalValueForAccounts(
    standaloneAccounts: StandaloneAccount[],
    legalAgreements: LegalAgreement[]) {
    const standaloneAccountTotal = calculateInEstateInvestableStandaloneAccountsTotalValue(standaloneAccounts);
    const investmentProgramTotal = getInEstateTotalValueForInvestmentProgram(legalAgreements);
    return standaloneAccountTotal + investmentProgramTotal;
}

export function calculateInEstateInvestableStandaloneAccountsTotalValue(standaloneAccounts: StandaloneAccount[]): number {
    return standaloneAccounts.reduce((previousValue, currentValue) => {
        const value = currentValue.holdings.totalInvestableValue;
        return previousValue + calculateInEstateValue(value ? value : 0, currentValue.memberOwnerships);
    }, 0);
}

export function calculateInEstateValue(value: number, memberOwnerships: MemberOwnership[]): number {
    const inEstatePercentageTotal = memberOwnerships.reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);
    return inEstatePercentageTotal * value / 100;
}

export function calculateInEstateFormValue(value: number, memberOwnerships: MemberOwnershipFormData[]): number {
    const inEstatePercentageTotal = memberOwnerships.reduce((previous, current) => {
        return previous + parseFloat(current.percentage);
    }, 0);
    return inEstatePercentageTotal * value / 100;
}

export function getInEstateTotalPresentValue(assets: { inEstateValue: number }[]) {
    return assets.reduce((previousValue, currentValue) => previousValue + currentValue.inEstateValue, 0);
}

export function getOutOfEstateTotalValueForInvestmentProgram(legalAgreements: LegalAgreement[]): number {
    return legalAgreements.map(legalAgreement => {
        const totalMemberOwnershipsPercentage = legalAgreement.memberOwnerships
            .filter(member => !member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        const totalLegalEntityPercentage = legalAgreement.legalEntityOwnerships
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return ((totalMemberOwnershipsPercentage + totalLegalEntityPercentage) * legalAgreement.marketValue) / 100;
    }).reduce((previous, current) => previous + current, 0);
}

export function getInEstateTotalValueForInvestmentProgram(legalAgreements: LegalAgreement[]): number {
    return legalAgreements.map(legalAgreement => {
        const totalPercentage = legalAgreement.memberOwnerships
            .filter(member => member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return totalPercentage * legalAgreement.marketValue / 100;
    }).reduce((previous, current) => previous + current, 0);
}
