import React from "react";
import {Table} from "xps-react";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import {Accordion, AccordionItem, Icon, UnderlinedHeader} from "../../../components";
import {SummaryTaxLiabilitiesRow, TaxLiabilitiesListHeader} from "../../../Goals/Summary/SummaryTaxLiabilitiesList";
import {formatCurrency} from "../../../utils/format";

export interface SummaryTaxLiabilitiesReportProps {
    goalModel: GoalModelType
}

const SectionHeader = () => {
    return <UnderlinedHeader
        leftAlignedContent={
            <span className="display-flex align-items-center">
                <Icon name="chevron_double_right"/>
                <span
                    className="condensed-subtitle paddingleft-md">Liability Type</span>
            </span>
        }
        rightAlignedContent={
            (
                <>
                    <span className="condensed-subtitle value-label marginright-sm">
                        Present Value
                    </span>
                </>
            )
        }
    />
}



const SummaryTaxLiabilitiesListReport = ({goalModel}: SummaryTaxLiabilitiesReportProps) => {
    const {taxLiabilities: goalTaxLiabilities} = goalModel;

    return (
        <>
            <section>
                <div className="goal-list">
                    <div className="accordion-tables">
                        <Accordion
                            expanded={["TaxLiabilitiesReport"]}
                            accordionId="SummaryLifestyleGoalListAccordionReport"
                            allowMultipleExpanded
                            allowZeroExpanded
                        >
                            <div className="sticky-header">
                                <div data-testid="tax-liabilities-underlined-header">
                                    <TaxLiabilitiesListHeader taxLiabilities={goalTaxLiabilities}/>
                                    <SectionHeader />
                                </div>
                                <AccordionItem
                                    uuid="TaxLiabilitiesReport"
                                    accentColor="#FFDF77"
                                    primaryText="Tax Liabilities"
                                    rightAlignedContent={
                                        <div className="display-flex align-items-center"
                                             data-testid="tax-liabilities-accordion-item">
                                            <span className="font-lg paddingright-xl">
                                                {formatCurrency(goalTaxLiabilities.totalTaxLiabilities)}
                                            </span>
                                        </div>
                                    }
                                >
                                    <div data-testid="tax-liabilities-table">
                                        <Table
                                            resizable={false}
                                            showPagination={false}
                                            sortable={false}
                                            data={
                                                [
                                                    {
                                                        description: "Estimated Deferred Tax Liability",
                                                        presentValue: `${formatCurrency(goalTaxLiabilities.estimatedDeferredTaxLiability)}`,
                                                    },
                                                    {
                                                        description: "Estimated Unrealized Capital Gains Liability",
                                                        presentValue: `${formatCurrency(goalTaxLiabilities.estimatedUnrealizedCapitalGainsLiability)}`,
                                                    },
                                                ]
                                            }
                                            columns={
                                                [
                                                    {
                                                        minWidth: 30,
                                                        maxWidth: 30,
                                                    },
                                                    {
                                                        accessor: 'description',
                                                        Cell: ({original}: { original: SummaryTaxLiabilitiesRow }) => {
                                                            return (
                                                                <div className="display-flex flex-column justify-content-center height-100p">
                                                                    <span className="table-label-15px">{original.description}</span>
                                                                </div>
                                                            );
                                                        },
                                                        Header: 'Description'
                                                    },
                                                    {
                                                        accessor: 'presentValue',
                                                        Cell: ({original}: { original: SummaryTaxLiabilitiesRow }) => {
                                                            return (
                                                                <div className="display-flex flex-column justify-content-center align-items-end height-100p">
                                                                    <span className="table-label-15px">{original.presentValue}</span>
                                                                </div>
                                                            );
                                                        },
                                                        Header: 'Present Value',
                                                        headerClassName: 'display-flex justify-content-end',
                                                    },
                                                    {
                                                        accessor: 'rowActionMenu',
                                                        className: "overflow-visible",
                                                        minWidth: 32,
                                                        maxWidth: 32,
                                                    }
                                                ]
                                            }
                                        />
                                    </div>
                                </AccordionItem>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SummaryTaxLiabilitiesListReport;