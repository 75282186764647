import {Popover} from "../Popover";
import React from "react";
import {Button} from "../Button";

export interface InfoPopoverProps {
    content: JSX.Element,
    direction: 'top' | 'bottom' | 'left' | 'right',
    width?: string,
    buttonProps?: object,
    label?: string
}

const InfoPopover = ({
                         content,
                         direction,
                         width = "288px",
                         buttonProps = {},
                         label = "info-popover"
                     }: InfoPopoverProps) => {
    return (
        <Popover
            className="popover-text"
            content={content}
            direction={direction}
            width={width}
            arrow={false}
            aria-label={label}
        >
            <Button className="popover-button"
                    icon="only"
                    iconName="info_outline"
                    kind="borderless"
                    size="small"
                    {...buttonProps}
            />
        </Popover>
    )
}

export default InfoPopover;