import React, {useEffect, useState} from 'react';
import {Route, Switch, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import GoalsService from "./GoalsService";
import FamilyGoal from "../Family/FamilyGoalContainer";
import {selectGoalModel, setGoalModel} from "./GoalsModelSlice";
import Summary from "../Summary/Summary";
import LoadingIndicator from "../../pages/LoadingIndicator";
import LifestyleSpendingForm from "../LifestyleSpendingForm/LifestyleSpendingForm";
import AddFamilyGoal from "../Family/form/AddFamilyGoal";
import {GoalModelType} from "../models/GoalModelType";
import AddDiscretionaryGoal from "../Discretionary/AddDiscretionaryGoal";
import AddPhilanthropicGoal from "../Philanthropic/AddPhilanthropicGoal";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import Prioritization from "../Prioritization/Prioritization";
import {genericEmptyErrorModalData, GenericErrorModalData} from "../../components/Modal/Error/GenericErrorModal";


const Goals = () => {
    const {id} = useParams<RouteWithId>();
    const previousGoalModel: GoalModelType = useAppSelector<GoalModelType>(selectGoalModel);
    const {nonLifestyleGoalChanged, lifestyleSpendingGoal} = previousGoalModel;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);

    const handleError = ((traceId: string) => {
        setGenericError({
            isOpen: true,
            header: 'Communication Failure',
            message: 'There has been a communication failure. The data you see may not be accurate, please refresh your browser. If this error continues, please contact the support team.',
            operationId: traceId
        });
    })
    usePageViewTimer('Goal Summary Page Load Timer (milliseconds)', isLoading);
    useEffect(() => {
        GoalsService.getGoalsModel(id, previousGoalModel, handleError)
            .then(goalResponse => {
                dispatch(setGoalModel(goalResponse))
                setIsLoading(false)
            })
    }, [id])

    const totalLifestyleAnnualSpend = lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.reduce(
        (accumulator, currentValue) => accumulator + currentValue.annualSpend, 0);
    useEffect(() => {
        if (nonLifestyleGoalChanged && totalLifestyleAnnualSpend > 0) {
            GoalsService.getGoalsModel(id, previousGoalModel, handleError)
                .then(goalResponse => {
                    dispatch(setGoalModel(goalResponse))
                    setIsLoading(false)
                })
        }
    }, [nonLifestyleGoalChanged])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <Switch>
            <Route path="/Profile/:id/ClientProfile/Goals/AddLifestyleSpending"
                   render={() => <LifestyleSpendingForm/>}/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditLifestyleSpending"
                   render={() => <LifestyleSpendingForm isEditing={true}/>}/>
            <Route path="/Profile/:id/ClientProfile/Goals/FamilyGoal" render={() => <FamilyGoal/>}/>
            <Route path="/Profile/:id/ClientProfile/Goals/AddFamilyGoal"
                   component={AddFamilyGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditFamilyGoal"
                   component={AddFamilyGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/AddDiscretionaryGoal"
                   component={AddDiscretionaryGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditDiscretionaryGoal"
                   component={AddDiscretionaryGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/AddPhilanthropicGoal"
                   component={AddPhilanthropicGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditPhilanthropicGoal"
                   component={AddPhilanthropicGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/Prioritization"
                   component={Prioritization}/>
            <Route path="/Profile/:id/ClientProfile/Goals/Summary"
                   render={() => <Summary errorModelData={genericError}/>}/>
        </Switch>)
}

export default Goals
