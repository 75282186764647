import React from 'react';
import {Dropdown, DropdownItem, InfoPopover} from "../../components";
import {FinancialModelSelection} from "../models/FinancialModelSelection";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";

type FinancialModelSelectionProps = {
    financialModel: FinancialModelSelection,
    updateFinancialModel: (newModel: FinancialModelSelection) => void,
    referenceData: ReferenceDataType,
    disabled?: boolean
}

const NewClientFinancialModel: React.FC<FinancialModelSelectionProps> = ({
                                                                             financialModel,
                                                                             updateFinancialModel,
                                                                             referenceData,
                                                                             disabled = false
                                                                         }) => {
    return (
        <>
            <div className="h4 sections modelSelectionLabel">Model Selection</div>
            <hr className="section"/>
            <div className="h4 parent">
                <label className="h5 label accreditedStatusLabel"
                       id="accreditedStatusInput-label"
                       data-testid="accreditedStatusLabel"
                >
                    Accredited Status
                </label>
                <InfoPopover
                    content={
                        <div className="popover-content"><b>Accredited Status</b> should reflect whether the proposed
                            fulfillment includes Private Equity & Hedge.</div>
                    }
                    direction="bottom"
                    label="accredited-status"
                />
                <Dropdown
                    className="accreditedStatus paddingbottom-12"
                    aria-label="accreditedStatusInput"
                    aria-labelledby="accreditedStatusInput-label"
                    alignRight={false}
                    buttonIcon="right"
                    buttonKind="secondary"
                    defaultPageSize={15}
                    disabled={disabled}
                    dropdownKind="select"
                    dynamicDropAlign={false}
                    dynamicDropDirection={false}
                    id="accreditedStatusInput"
                    iconNameClose="arrow_drop_up"
                    iconNameOpen="arrow_drop_down"
                    labelWidth="300px"
                    nativeOnMobile={false}
                    panelHeight="auto"
                    panelWidth="300px"
                    defaultText="Select..."
                    onChange={(e: any) => {
                        updateFinancialModel({
                            ...financialModel,
                            accreditedStatus: e.itemText
                        });
                    }}
                    size="medium"
                    virtualScroll={false}
                    value={financialModel.accreditedStatus}
                >
                    {referenceData.accreditedStatus.items.map(status => (
                        <DropdownItem key={status} itemText={status} value={status}/>
                    ))}
                </Dropdown>
            </div>
            <div className="h4 parent">
                <label className="h5 label allocationTypeLabel"
                       id="allocationTypeInput-label"
                       data-testid="allocationTypeLabel"
                >
                    Allocation Type
                </label>
                <InfoPopover
                    content={
                        <div className="popover-content"><b>Allocation Type</b> should reflect fulfillment for risk assets. The risk asset model will follow the Maximum Growth Investment Objective for the selected type - Tactical or Strategic.</div>
                    }
                    direction="bottom"
                    label="allocation-type"
                />
                <Dropdown
                    className="allocationTypeInput"
                    aria-label="allocationTypeInput"
                    aria-labelledby="allocationTypeInput-label"
                    alignRight={false}
                    buttonIcon="right"
                    buttonKind="secondary"
                    defaultPageSize={15}
                    dropdownKind="select"
                    disabled={disabled}
                    dynamicDropAlign={false}
                    dynamicDropDirection={false}
                    id="allocationTypeInput"
                    iconNameClose="arrow_drop_up"
                    iconNameOpen="arrow_drop_down"
                    labelWidth="300px"
                    nativeOnMobile={false}
                    panelHeight="auto"
                    panelWidth="300px"
                    defaultText="Select..."
                    onChange={(e: any) => {
                        updateFinancialModel({
                            ...financialModel,
                            allocationType: e.itemText
                        });
                    }}
                    size="medium"
                    virtualScroll={false}
                    value={financialModel.allocationType}
                >
                    {referenceData.allocationType.items.map(status => (
                        <DropdownItem key={status} itemText={status} value={status}/>
                    ))}
                </Dropdown>
            </div>
        </>
    );
};

export default NewClientFinancialModel;
