import {EstateSummary, MemberBeneficiary, PhilanthropicBeneficiary} from "../../../WealthTransfer/models/api";
import {GroupedTableData} from "../../../components/Table/GroupedTable";

export const PAGE_CONSTANTS = {
    TOTAL_PAGE_HEIGHT: 1055,
    PAGE_PADDING_HEIGHT: 96,
    SECTION_HEADER_HEIGHT: 100+100,
    ACCORDION_HEADER_HEIGHT: 72,
    TABLE_HEADER_HEIGHT: 36,
    TABLE_ROW_HEIGHT: 48,
    ASSET_LIABILITY_BOTTOM_MARGIN: 71,
    TABLE_OFFSET:85
}

export type WealthTransferData = {
    beneficiaryPageData :GroupedTableData[] | undefined,
    estimatedEstateTaxPageData: EstateSummary
}
type BeneficiaryPagesAndSpace = {
    beneficiaryArrayPages:Array<Beneficiary[]>,
    remainingSpace:number
}

export type Beneficiary = MemberBeneficiary | PhilanthropicBeneficiary

export function splitBeneficiaryData(beneficiaries: Beneficiary[], beneficiaryArrayPages:Array<Beneficiary[]>, remainingSpace:number):BeneficiaryPagesAndSpace {
    if(beneficiaries.length <= 0 )  return {beneficiaryArrayPages:  beneficiaryArrayPages, remainingSpace:remainingSpace};

    let availablePageHeight = remainingSpace>0?remainingSpace:PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
    let usedSpace = 0;
    if(remainingSpace === 0){
        usedSpace += PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
    }
    usedSpace += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
    let beneficiariesForAPage:Beneficiary[] = []
    for(let index=0;index<beneficiaries.length;index++){
        const beneficiary:Beneficiary=beneficiaries[index];
        usedSpace += PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
        if(usedSpace > availablePageHeight){
            beneficiariesForAPage.push(beneficiary);
            beneficiaryArrayPages.push(beneficiariesForAPage);
            availablePageHeight=PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
            usedSpace =0;
            usedSpace += PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
            usedSpace += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            if(beneficiaries.length>1) {
                splitBeneficiaryData(beneficiaries.slice(index+1), beneficiaryArrayPages,0);
                beneficiariesForAPage =[];
                break;
            }
        } else {
            beneficiariesForAPage.push(beneficiary);
        }
    }
    if(beneficiariesForAPage.length !=0) beneficiaryArrayPages.push(beneficiariesForAPage);

    return {beneficiaryArrayPages:  beneficiaryArrayPages, remainingSpace:PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT-usedSpace};
}