import {ReportConfig} from "../models/Reporting";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";
import {initialSelectedPagesForAnnualReport} from "../ReportingConstants";
import { ClientMeetingDocumentItem } from "src/Resources/models/ClientDocuments";

export interface ReportingState {
    selectedPagesForAnnualReport: ReportConfig,
    selectShowDrawerForClientDocuments: boolean,
    selectShowDrawerForMyDocuments: boolean,
    selectShowDrawerForAdviceLibrary: boolean,
    selectShowDrawerForPracticeDocuments: boolean,
    selectedClientDocuments: ClientMeetingDocumentItem[],
    selectedMyDocuments: ClientMeetingDocumentItem[],
    selectAdviceLibraryDocuments: string[],
    selectPracticeDocuments: string[]
}

export const initialStateOfSelectedPagesForAnnualReport: ReportingState = {
    selectedPagesForAnnualReport: initialSelectedPagesForAnnualReport,
    selectShowDrawerForClientDocuments: false,
    selectShowDrawerForMyDocuments: false,
    selectShowDrawerForAdviceLibrary: false,
    selectShowDrawerForPracticeDocuments:false,
    selectedClientDocuments: [],
    selectedMyDocuments: [],
    selectAdviceLibraryDocuments: [],
    selectPracticeDocuments: []
};

export const reportingSlice = createSlice({
    name: "reporting",
    initialState: initialStateOfSelectedPagesForAnnualReport,
    reducers: {
        setSelectedPagesForAnnualReport: (state, action: PayloadAction<ReportConfig>) => {
            state.selectedPagesForAnnualReport = action.payload;
        },
        setShowDrawerForAdviceLibrary: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForAdviceLibrary = action.payload;
        },
        setShowDrawerForClientDocuments: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForClientDocuments = action.payload;
        },
        setShowDrawerForMyDocuments: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForMyDocuments = action.payload;
        },
        setSelectedClientDocuments: (state, action: PayloadAction<ClientMeetingDocumentItem[]>) => {
            state.selectedClientDocuments = action.payload;
        },
        setSelectedMyDocuments: (state, action: PayloadAction<ClientMeetingDocumentItem[]>) => {
            state.selectedMyDocuments = action.payload;
        },
        setSelectedAdviceLibraryDocuments: (state, action: PayloadAction<string[]>) => {
            state.selectAdviceLibraryDocuments = action.payload;
        },
        setShowDrawerForPracticeDocuments: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForPracticeDocuments = action.payload;
        },
        setSelectedPracticeDocuments: (state, action: PayloadAction<string[]>) => {
            state.selectPracticeDocuments = action.payload;
        },
        resetReportingState: (_state, _action: PayloadAction<void>) => initialStateOfSelectedPagesForAnnualReport
    }
});

export const {
    setSelectedPagesForAnnualReport,
    setShowDrawerForClientDocuments,
    setShowDrawerForMyDocuments,
    setShowDrawerForAdviceLibrary,
    setShowDrawerForPracticeDocuments,
    setSelectedClientDocuments,
    setSelectedMyDocuments,
    setSelectedAdviceLibraryDocuments,
    setSelectedPracticeDocuments,
    resetReportingState
} = reportingSlice.actions;

export const selectSelectedPagesForAnnualReport = (state: RootState) => state.client.reporting.selectedPagesForAnnualReport;

export const selectShowDrawerForAdviceLibrary = (state: RootState) => state.client.reporting.selectShowDrawerForAdviceLibrary;
export const selectAdviceLibraryDocuments = (state: RootState) => state.client.reporting.selectAdviceLibraryDocuments;
export const selectAdviceLibraryDocumentsCount = (state: RootState) => state.client.reporting.selectAdviceLibraryDocuments.length;

export const selectShowDrawerForPracticeDocuments = (state: RootState) => state.client.reporting.selectShowDrawerForPracticeDocuments;
export const selectPracticeDocuments = (state: RootState) => state.client.reporting.selectPracticeDocuments;
export const selectPracticeDocumentsCount = (state: RootState) => state.client.reporting.selectPracticeDocuments.length;

export const selectShowDrawerForClientDocuments = (state: RootState) => state.client.reporting.selectShowDrawerForClientDocuments;
export const selectSelectedClientDocuments = (state: RootState) => state.client.reporting.selectedClientDocuments;

export const selectShowDrawerForMyDocuments = (state: RootState) => state.client.reporting.selectShowDrawerForMyDocuments;
export const selectSelectedMyDocuments = (state: RootState) => state.client.reporting.selectedMyDocuments;

export default reportingSlice.reducer;