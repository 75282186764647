import React from "react";
import {Route} from "react-router-dom";
import {Header} from "../components/Header/index";
import ClientProfile from "../ClientManagement/ClientProfile/ClientProfile";
import NewClientProfile from "../ClientManagement/NewClientProfile/NewClientProfile";
import ProfileDetails from "../ClientManagement/ClientProfile/ProfileDetails/ProfileDetails";
import ProfileSettings, {ProfileSettingsType} from "../ClientManagement/ProfileSettings/ProfileSettings";
import PartnerWorkstation from "../ClientManagement/PartnerWorkstation/PartnerWorkstation";
import PageUnavailable from "../components/ErrorPage/PageUnavailable";
import MeetingsHistory from "../ClientManagement/Meeting/MeetingsHistory";
import ViewArchivedProposalsAndProfiles
    from "../ClientManagement/ClientProfile/ProfileDetails/ArchivedProposals/ViewArchivedProposalsAndProfiles";
import {RouteTracker} from "./RouteTracker";
import DashboardParent from "../ClientManagement/PartnerDashboard";
import {counterDeviceScaling} from "../utils/counteractDeviceScaling";

const RouteWithHeader: React.FC<{ path: string | string[], component: React.ReactNode }> = ({path, component}) => (
    <Route exact path={path}>
        <div style={{...counterDeviceScaling()}}>
            <Header/>
            {component}
        </div>
    </Route>
);

export class Routes extends React.Component {
    render() {
        return (
            <div>
                <RouteWithHeader path={["/", "/Dashboard/:type"]} component={<DashboardParent/>}/>
                <RouteWithHeader path={"/Profile/:type/New"} component={<NewClientProfile/>}/>
                <RouteWithHeader path={"/Profile/:id"} component={<ProfileDetails/>}/>
                <RouteWithHeader path={"/PartnerWorkstation"} component={<PartnerWorkstation/>}/>
                <Route path="/Profile/:id/ClientProfile" component={ClientProfile}/>
                <RouteWithHeader path={"/Profile/:profileId/ProfileSettings"} component={
                    <ProfileSettings settingsType={ProfileSettingsType.PROFILE}/>
                }/>
                <RouteWithHeader path={"/Profile/:profileId/NewProposalSettings"} component={
                    <ProfileSettings settingsType={ProfileSettingsType.NEW_PROPOSAL}/>
                }/>
                <RouteWithHeader path={"/Profile/:profileId/ProposalSettings/:proposalId"} component={
                    <ProfileSettings settingsType={ProfileSettingsType.PROPOSAL}/>
                }/>
                <RouteWithHeader path={"/Profile/:id/ViewArchivedProposals"} component={
                    <ViewArchivedProposalsAndProfiles/>
                }/>
                <RouteWithHeader path={"/Profile/:id/MeetingsHistory"} component={
                    <MeetingsHistory/>
                }/>
                <RouteWithHeader path={"/unavailable"} component={
                    <PageUnavailable/>
                }/>
            </div>
        );
    }
}

export default () => (
    <>
        <RouteTracker/>
        <Routes/>
    </>
);
