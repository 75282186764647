import React from "react";
import {useAppSelector, useAppDispatch} from "../../store/hooks";
import {selectApprovedProfile} from "./approvedProfileSlice";
import {selectProfile} from "./activeProfileSlice";
import {useHistory} from "react-router-dom";
import {Button} from "../../components";
import {resetShowOutOfEstateAssets} from "../../Assets/common/OutOfEstate/OutOfEstateAssetsSlice";
import {resetAccordionPreferencesState} from "../../Assets/common/accordionPreferencesSlice";

const CloseProfile: React.FC = () => {
    const dispatch = useAppDispatch();
    const id = useAppSelector(selectApprovedProfile)?.id;
    const profileIdForProposal = useAppSelector(selectProfile)?.profileIdForProposal;
    const history = useHistory();
    const closeButtonText = profileIdForProposal ? 'CLOSE PROPOSAL' : 'CLOSE PROFILE';
    return (
        <Button
            className={"close-profile-button"}
            id="closeProfile"
            icon="none"
            includeRef={false}
            kind="primary"
            size="medium"
            tabIndex={0}
            type="button"
            onClick={() => {
                dispatch(resetAccordionPreferencesState(id!));
                dispatch(resetShowOutOfEstateAssets());
                history.push(`/Profile/${id}`)
            }}
        >
            {closeButtonText}
        </Button>
    )
};

const ProfileControls:React.FC = () => {
    return (
        <div
            className="profile-controls"
        >
            <CloseProfile/>
        </div>
    )
}


export default ProfileControls;
