import React, {useEffect, useState} from "react";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {
    CurrentAllocationResponse,
    EMPTY_CURRENT_ALLOCATION_RESPONSE
} from "../../../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import {
    EMPTY_PROPOSED_ALLOCATION_RESPONSE,
    ProposedAllocationResponse
} from "../../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import CurrentVsProposedReportView from "./CurrentVsProposedReportView";

type currentVsProposedDetailsReportProps = {
    displayName: string
}

const CurrentVsProposedDetailsReport = ({displayName}: currentVsProposedDetailsReportProps) => {
    const {id} = useParams<RouteWithId>();
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [currentAllocation, setCurrentAllocation] = useState<CurrentAllocationResponse>(EMPTY_CURRENT_ALLOCATION_RESPONSE);
    const [proposedAllocation, setProposedAllocation] = useState<ProposedAllocationResponse>(EMPTY_PROPOSED_ALLOCATION_RESPONSE);

    useEffect(() => {
        (async () => {
            if (id) {
                setPageLoaded(false);
                const [currentAllocationResponse, proposedAllocationResponse] = await Promise.all([
                    wealthManagementApiClient.getCurrentAllocation(id),
                    wealthManagementApiClient.getProposedAllocation(id)]
                );
                setPageLoaded(true);
                setCurrentAllocation(currentAllocationResponse);
                setProposedAllocation(proposedAllocationResponse);
            }
        })();
    }, [id]);

    return (
        pageLoaded ? <CurrentVsProposedReportView currentAllocation={currentAllocation}
                                                        proposedAllocation={proposedAllocation}
                                                        displayName={displayName}/>
                    : <LoadingIndicator displayMessage={"Page loading"}/>
    );
}

export default CurrentVsProposedDetailsReport;