import React from 'react';

type AccordionItemInfoWithNoDocumentsProps = {
    hRef?: string
    isReportSection?: boolean
}

export const AccordionItemInfoWithNoDocuments = ({hRef,isReportSection}: AccordionItemInfoWithNoDocumentsProps) => {
    return (<>
            <div className={`${isReportSection ? 'reporting-drawer__no-docs-content':'nav-drawer-no-documents'}`}><span className={`${isReportSection ? 'reporting-drawer__no-docs-content__title':'nav-drawer-no-documents__title'}`}>No documents uploaded.</span>
                <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__message1':'nav-drawer-no-documents__message1'}`}><br></br>Documents uploaded in SharePoint will</div>
                <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__message2':'nav-drawer-no-documents__message2'}`}>be available here. Refresh to view recently</div>
                <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__message3':'nav-drawer-no-documents__message3'}`}>uploaded documents.</div>
            </div>
            <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__share-point-link':'nav-drawer-no-documents__share-point-link'}`}>
                <a
                    className=""
                    href={hRef}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    CLICK HERE TO ACCESS YOUR SHAREPOINT
                    <i className="icon dds-icons marginleft-sm">
                        launch
                    </i>
                </a>
            </div>

        </>
    );
}