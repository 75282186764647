import AssetRelianceBarchartContainer from "./AssetRelianceBarchartContainer";
import AssetRelianceTitleGrid from "./AssetRelianceTitleGrid";
import React, {useEffect, useState} from "react";
import NoAssetsComponent from "./NoAssetsComponent";
import {AssetRelianceGoalSummaryDTO, AssetRelianceStack} from "../models/AssetRelianceResponse";
import {GoalTableDisplay} from "./TableDisplay/GoalTableDisplay";
import {ExcessAssetsTableDisplay} from "./TableDisplay/ExcessAssetsTableDisplay";
import {
    COLOR_ASSET_SHORTFALL_ACCENT,
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS_ACCENT,
    COLOR_GOALS
} from "../../constants/colors";
import {ReviewAssetTableDisplay} from "./TableDisplay/ReviewAssetTableDisplay";
import {AccordionTable, FixedColumnCounter} from "../../components";
import {CellClassParameters} from "../../components/AccordionTable/TableRowComponent";
import {Mapping as ClassNamesMapping} from "classnames";
import {hasPositiveExcessAssets} from "./AssetRelianceUtil";
import {AssetRelianceButtonState} from "./AssetRelianceButtonState";
import {InvestorGroupType} from "../models/InvestorGroupType";

const TITLE_CELL_WIDTH = 310;


export interface AssetRelianceContentProps {
    goalsSummaryDTO: AssetRelianceGoalSummaryDTO,
    allAssetsStack: AssetRelianceStack,
    assetStacks: AssetRelianceStack[],
    excludedAssetStacks: AssetRelianceStack[],
    id: string,
    accountHasAssets: boolean,
    headerOptions: AssetRelianceButtonState,
    investorGroup: InvestorGroupType
}

const AssetRelianceContent: React.FC<AssetRelianceContentProps> = ({
                                                                       goalsSummaryDTO,
                                                                       allAssetsStack,
                                                                       assetStacks,
                                                                       excludedAssetStacks = [],
                                                                       id,
                                                                       accountHasAssets,
                                                                       headerOptions,
                                                                       investorGroup
                                                                   }) => {

    const totalGoals = goalsSummaryDTO.totalPresentValue;
    const {
        excessAssets,
        assets,
        goals
    } = createTableDisplayObjects(goalsSummaryDTO, allAssetsStack, assetStacks, excludedAssetStacks, headerOptions.showExpectedExcessAsset!, investorGroup);

    const assetStacksContainerWidth = useAssetStackContainerWidth(excludedAssetStacks.length);

    const createTableCellValueClassNames = () => ({columnIndex}: CellClassParameters): ClassNamesMapping[] => {
        return [{"table-cell-values": columnIndex >= 0},];
    };

    const createTableCellValueClassNamesForExcessAssets = () => ({columnIndex}: CellClassParameters): ClassNamesMapping[] => {
        return [{"table-cell-values": columnIndex >= 0}, {"excess_assets": true}];
    };

    const excessAssetsAccentColor = hasPositiveExcessAssets(allAssetsStack.excessAssets) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT;

    return <>
        {accountHasAssets ?
            <main className="asset-reliance-content">
                <AssetRelianceBarchartContainer allAssetsStack={allAssetsStack}
                                                additionalAssetStacks={excludedAssetStacks}
                                                totalGoals={totalGoals}
                                                totalNetAssets={allAssetsStack.presentValue}
                                                stackContainerWidth={assetStacksContainerWidth}
                                                titleCellWidth={TITLE_CELL_WIDTH}
                />
                <AssetRelianceTitleGrid
                    additionalStacksTitles={excludedAssetStacks.map(stack => stack.name)}
                    stackContainerWidth={assetStacksContainerWidth}
                    titleCellWidth={TITLE_CELL_WIDTH}
                    id={id}
                />
                <AccordionTable accordionTableId={`${id}-review-asset-reliance-assets`}
                                collapsedAccentColor={COLOR_ASSETS_ACCOUNTS}
                                ariaLabel='assets-row'
                                tableDisplayData={assets}
                                titleCellWidth={TITLE_CELL_WIDTH}
                                valueColumnSize={assetStacksContainerWidth}
                                additionalCellClasses={createTableCellValueClassNames()}
                />
                <AccordionTable accordionTableId={`${id}-review-asset-reliance-goals`}
                                collapsedAccentColor={COLOR_GOALS}
                                ariaLabel='goals-row'
                                tableDisplayData={goals}
                                titleCellWidth={TITLE_CELL_WIDTH}
                                valueColumnSize={assetStacksContainerWidth}
                                additionalCellClasses={createTableCellValueClassNames()}
                />
                <AccordionTable accordionTableId={`${id}-review-asset-reliance-excess-assets`}
                                collapsedAccentColor={excessAssetsAccentColor}
                                expandedAccentColor={excessAssetsAccentColor}
                                ariaLabel='excess-assets-row'
                                tableDisplayData={excessAssets}
                                titleCellWidth={TITLE_CELL_WIDTH}
                                valueColumnSize={assetStacksContainerWidth}
                                additionalCellClasses={createTableCellValueClassNamesForExcessAssets()}
                />
            </main>
            : <NoAssetsComponent/>
        }
    </>
}

export default AssetRelianceContent;

const createTableDisplayObjects = (
    goalsDTO: AssetRelianceGoalSummaryDTO,
    allAssetsStack: AssetRelianceStack,
    assetStacks: AssetRelianceStack[],
    excludedAssetStacks: AssetRelianceStack[],
    showExpectedExcessAssets: boolean,
    investorGroup: InvestorGroupType
) => {
    const stacksToGenerateAssetRows = [allAssetsStack, ...assetStacks];
    const stacksToGenerateAssetColumns = [allAssetsStack, ...excludedAssetStacks];
    const assetColumnCounter = new FixedColumnCounter(stacksToGenerateAssetColumns.length);
    const assets = new ReviewAssetTableDisplay(assetColumnCounter, stacksToGenerateAssetRows, stacksToGenerateAssetColumns);
    const goals = new GoalTableDisplay(assetColumnCounter, goalsDTO, stacksToGenerateAssetColumns);
    const excessAssets = new ExcessAssetsTableDisplay(assetColumnCounter, assets, goals, allAssetsStack, showExpectedExcessAssets, investorGroup);

    return {
        assets,
        excessAssets,
        goals,
        columnCounter: assetColumnCounter
    };
}

const ONE_EXCLUDED_ASSETS_WIDTH = 4.55
const TWO_EXCLUDED_ASSETS_WIDTH = 3.03
const THREE_EXCLUDED_ASSETS_WIDTH = 2.275
const FOUR_EXCLUDED_ASSETS_WIDTH = 1.825
const DEFAULT_EXCLUDED_ASSETS_WIDTH = 1.51

export const useAssetStackContainerWidth = (excludedAssetStacksAmount: number) => {
    const [assetStacksContainerWidth, setAssetStacksContainerWidth] = useState<number>(1.5);
    useEffect(() => {
        switch (excludedAssetStacksAmount) {
            case 1:
                setAssetStacksContainerWidth(ONE_EXCLUDED_ASSETS_WIDTH)
                break;
            case 2:
                setAssetStacksContainerWidth(TWO_EXCLUDED_ASSETS_WIDTH)
                break;
            case 3:
                setAssetStacksContainerWidth(THREE_EXCLUDED_ASSETS_WIDTH)
                break;
            case 4:
                setAssetStacksContainerWidth(FOUR_EXCLUDED_ASSETS_WIDTH)
                break;
            default:
                setAssetStacksContainerWidth(DEFAULT_EXCLUDED_ASSETS_WIDTH)
                break;
        }
    }, [excludedAssetStacksAmount]);
    return assetStacksContainerWidth;
}