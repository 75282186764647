import React, {useEffect, useState} from 'react';
import {ReactComponentLike} from 'prop-types';
import NavContext from './NavContext';
import {Accordion} from '../Accordion';
import {NavDrawerItem, NavDrawerSideMenuList} from '.';
import {HistoryProps, LinkProps, SubMenuProps} from '../../models/routeData/RouteParamTypes';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectApprovedProfileFromApi,
    selectClientDocuments,
    selectMyDocuments,
    setClientDocumentsResponse,
    setMyDocumentsResponse
} from "../../Resources/clientMeetingDocumentSlice";
import {getClientMeetingDocumentsSubMenu} from "../../ClientManagement/ClientProfile/ClientProfileLinks";
import {resourcesApiClient} from "../../Resources/ResourcesApiClient";
import {ClientMeetingDocumentsResponse} from "../../Resources/models/ClientDocuments";
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";

type NavDrawerItemGroupProps = {
    history?: HistoryProps,
    links: LinkProps[],
    LinkRenderer: ReactComponentLike | string,
    sideMenuRequired: boolean
}

export const NavDrawerItemGroup = ({
                                       history,
                                       links = [],
                                       LinkRenderer,
                                       sideMenuRequired
                                   }: NavDrawerItemGroupProps) => {
    const [sideMenus, setSideMenus] = useState<SubMenuProps[]>([]);
    const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(false);
    const [sideMenuPath, setSideMenuPath] = useState<string | undefined>('');
    const [sideMenuTitle, setSideMenuTitle] = useState<string>('');

    const msal = useMsal();
    const currentUserId = msalUtils.getLanId(msal);
    const dispatch = useAppDispatch();

    const clientDocumentsSubMenus = useAppSelector(selectClientDocuments);
    const myDocumentsSubMenus = useAppSelector(selectMyDocuments);
    const approvedProfileFromApi = useAppSelector(selectApprovedProfileFromApi);

    const onRefreshSharePointDocs = () => {

        const clientName = (approvedProfileFromApi.primaryContact.firstName).concat(" ", approvedProfileFromApi.primaryContact.lastName);
        if (sideMenuTitle == "Client Documents") {
            resourcesApiClient.getClientMeetingDocuments(approvedProfileFromApi.id, clientName, currentUserId).then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
                dispatch(setClientDocumentsResponse(clientMeetingDocumentsResponse));
            }).catch(console.error);
        } else if (sideMenuTitle == "My Documents") {
            resourcesApiClient.getMyDocuments(currentUserId).then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
                dispatch(setMyDocumentsResponse(clientMeetingDocumentsResponse));
            }).catch(console.error);
        }
    }

    useEffect(() => {
        let subMenuOptions: SubMenuProps[] = [];
        if (sideMenuTitle == "Client Documents") {
            subMenuOptions = (clientDocumentsSubMenus.documentItems.length > 0 || clientDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(clientDocumentsSubMenus) : []
        } else {
            subMenuOptions = (myDocumentsSubMenus.documentItems.length > 0 || myDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(myDocumentsSubMenus) : []
        }
        setSideMenus(subMenuOptions);
    }, [clientDocumentsSubMenus, myDocumentsSubMenus]);

    const toggleMenu = (link: LinkProps, path?: string) => {
        setSideMenuPath(!isOpenSideMenu ? path : '');
        setIsOpenSideMenu(((link.subMenuTitle !== "My Documents") && (link.subMenuTitle !== "Client Documents")) && !link.subMenuOptions?.length ? false : !isOpenSideMenu);
        setSideMenus(!isOpenSideMenu ? (link.subMenuOptions || []) : []);
        setSideMenuTitle(link.subMenuTitle || '');
    };

    return (
        <NavContext.Provider value={{toggleMenu}}>
            <Accordion allowMultipleExpanded={true}>
                {links.filter(link => !link['hidden'])?.map(link => (
                    <NavDrawerItem
                        key={link.id}
                        history={history}
                        link={link}
                        LinkRenderer={LinkRenderer}
                    />
                ))}
            </Accordion>

            {sideMenuRequired && (
                <NavDrawerSideMenuList
                    className="advice-library-menu"
                    isOpen={isOpenSideMenu}
                    items={sideMenus}
                    LinkRenderer={LinkRenderer}
                    location={`${sideMenuPath}`}
                    onRequestClose={() => setIsOpenSideMenu(false)}
                    title={sideMenuTitle}
                    onRefreshSharePointDocs={onRefreshSharePointDocs}
                />
            )}
        </NavContext.Provider>
    )
}
