import React, {ChangeEvent, useState} from 'react';
import {Button, CurrencyInput, Input} from "../../components";
import {YearsInput} from "./YearsInput";
import {LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";

interface LifestyleSpendingPeriodProps {
    lifestyleSpendingPeriod: LifestyleSpendingPeriodInputs
    investorGroup: InvestorGroupType,
    updateDescription: (description: string) => void,
    updateAnnualSpend: (annualSpend: string) => void,
    updateLengthOfSpend: (lengthOfSpend: string) => void
    handleAnnualSpendBlur: () => void
    handleLengthOfSpendBlur: () => void
    handleDeleteSpendingPeriod: () => void
    isFirstIndex: boolean
    disableLengthOfSpend: boolean
    hasError: boolean
}

const LifestyleSpendingPeriod = ({
                                     lifestyleSpendingPeriod,
                                     investorGroup,
                                     updateDescription,
                                     updateAnnualSpend,
                                     updateLengthOfSpend,
                                     handleAnnualSpendBlur,
                                     handleLengthOfSpendBlur,
                                     handleDeleteSpendingPeriod,
                                     isFirstIndex,
                                     disableLengthOfSpend,
                                     hasError
                                 }: LifestyleSpendingPeriodProps) => {
    const [isDescriptionInlineErrorShown, setIsDescriptionInlineErrorShown] = useState(false);

    const {startYear, endYear} = lifestyleSpendingPeriod

    const yearsUntilFlow = startYear - investorGroup.planningPeriod.startYear
    const lengthOfSpend = endYear - startYear

    const ageFrom: number = investorGroup.planningPeriod.ageFrom + yearsUntilFlow
    const ageTo: number = ageFrom + lengthOfSpend

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsDescriptionInlineErrorShown(false)
        updateDescription(e.target.value)
    };

    const handleDescriptionBlur = () => {
        setIsDescriptionInlineErrorShown(false)
        if (!lifestyleSpendingPeriod.description ||
            lifestyleSpendingPeriod.description === "") {
            setIsDescriptionInlineErrorShown(true)
        }
    };

    const handleDeleteModalClick = () => {
        setShowDeletePeriodModal(false)
        handleDeleteSpendingPeriod()
    }

    const [showDeletePeriodModal, setShowDeletePeriodModal] = useState<boolean>(false)

    return (
        <>
            <span className="textalign-right">
                <Input
                    className="descriptionFieldInput"
                    name="description"
                    aria-label="description"
                    aria-labelledby="descriptionFieldInput-label"
                    id="description"
                    data-testid="testingSpendingPeriod"
                    value={lifestyleSpendingPeriod.description}
                    onChange={handleDescriptionChange}
                    onBlur={handleDescriptionBlur}
                    error={isDescriptionInlineErrorShown ? "Description is required." : undefined}
                    removeMarginTop
                    size="medium"
                    type="text"
                    maxLength={200}
                />
            </span>
            <span>
                <CurrencyInput
                    className="AnnualSpendFieldInput"
                    aria-label="annualSpending"
                    name="annualSpending"
                    value={lifestyleSpendingPeriod.annualSpend}
                    onChangeValue={(e) => updateAnnualSpend(e.target.value)}
                    onBlur={handleAnnualSpendBlur}
                    emptyValue="0"
                    maxLength={16}
                    removeMarginTop
                    size="medium"
                />
            </span>
            <span className="textalign-right">
                <div>
                    <YearsInput
                        className="LengthOfSpendFieldInput"
                        aria-label="lengthOfSpend"
                        value={lengthOfSpend}
                        readOnly={disableLengthOfSpend}
                        name="lengthOfSpend"
                        onChangeValue={(e) => updateLengthOfSpend(e.target.value)}
                        onBlur={handleLengthOfSpendBlur}
                        hasError={hasError}
                    />
                </div>
            </span>
            <span>
                {!disableLengthOfSpend &&
                    <Button
                        className="delete_icon"
                        icon="only"
                        iconName="close"
                        kind="borderless"
                        onClick={() => setShowDeletePeriodModal(true)}
                        size="small"
                        type="button"
                    />
                }
            </span>
            <div className="LifestyleSpendingPeriodRanges">
                <span className="LifestyleSpendingPeriodHeaders">Spending Time Frame:</span>
                <span
                    data-testid="spending-time-frame-value">{startYear}—{endYear}
                                </span>
                <span className="LifestyleSpendingPeriodHeaders">Client's Age Range:</span>
                <span
                    data-testid="age-range-value">{ageFrom}—{ageTo}
                                </span>
            </div>
            <DeleteSpendingPeriodModal showDeleteModal={showDeletePeriodModal}
                                       handleModalDeleteButton={handleDeleteModalClick}
                                       handleModalCancelButton={() => setShowDeletePeriodModal(false)}
                                       lifestyleSpendingPeriod={lifestyleSpendingPeriod}/>
        </>);
}

type DeleteSpendingPeriodModalProps = {
    showDeleteModal: boolean
    handleModalDeleteButton: () => void
    handleModalCancelButton: () => void
    lifestyleSpendingPeriod: LifestyleSpendingPeriodInputs
}

const DeleteSpendingPeriodModal: React.FC<DeleteSpendingPeriodModalProps> = (
    {
        showDeleteModal,
        handleModalDeleteButton,
        handleModalCancelButton,
        lifestyleSpendingPeriod,
    }) => {
    return (
        <div data-testid="SpendingPeriodModal">
            <ModalWrapper
                id="discard-changes-modal"
                isOpen={showDeleteModal}
                headerText={`Delete this Goal?`}
                buttons={[
                    {
                        text: "CANCEL",
                        onClick: handleModalCancelButton,
                    },
                    {
                        text: "DELETE",
                        onClick: handleModalDeleteButton,
                        destructive: true,
                        primary: true,
                    },
                ]}
            >
                <div className="font-md">
                    Are you sure you want to delete {lifestyleSpendingPeriod.description}?
                </div>
            </ModalWrapper>
        </div>
    )
}


export default LifestyleSpendingPeriod
