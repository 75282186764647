import {InvestorGroupMemberType, InvestorGroupType} from "../models/InvestorGroupType";
import {FutureValueOfExcessAssetsDTO} from "../../Assets/models/Assets";
import Highcharts from "highcharts";
import {formatCurrency} from "../../utils/format";
import {NET_WORTH_OVER_TIME_PLOT_LINE} from "../../constants/colors";

export type NetWorthOverTimeGraphProps = {
    investorGroup: InvestorGroupType,
    projectedNetWorthAge: number | undefined,
    redrawPlotLineTooltip: boolean,
    netWealthAtCurrentAge: number | undefined,
    futureValueOfExcessAssetsDTO: FutureValueOfExcessAssetsDTO
}

export const createGraphOptions: (props: NetWorthOverTimeGraphProps) => Highcharts.Options
    = (props: NetWorthOverTimeGraphProps) => {
    let {
        investorGroup,
        projectedNetWorthAge,
        redrawPlotLineTooltip,
        netWealthAtCurrentAge,
        futureValueOfExcessAssetsDTO,
    } = props;

    const chartDisplayName = investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER ?
        investorGroup.partnerMember?.name! : investorGroup.primaryMember?.name!;

    const drawPlotLineOnGraph = function (chart: Highcharts.Chart) {
        drawPlotLineWithVariableHeight(chart, (projectedNetWorthAge ?? 0) - investorGroup.planningPeriod.ageFrom);
        if (redrawPlotLineTooltip) {
            redrawPlotLineTooltip = false;
            drawPlotlineLabelTooltip(chart, (projectedNetWorthAge ?? 0) - investorGroup.planningPeriod.ageFrom, projectedNetWorthAge ?? 0);
        }
    }

    return {
        title: {
            text: `NET WORTH OVER TIME, BY ${chartDisplayName.toUpperCase()}`,
            verticalAlign: 'bottom'
        },
        accessibility: {
            enabled: true
        },
        chart: {
            marginRight: 40,
            events: {
                load: function () {
                    drawPlotLineOnGraph(this);
                },
                redraw: function () {
                    drawPlotLineOnGraph(this);
                }
            }
        },
        tooltip: {
            formatter: function (
                this: Highcharts.TooltipFormatterContextObject,
                tooltip: Highcharts.Tooltip
            ) {
                return `Age ${this.x}<br> <b>${formatCurrency(this.y ?? 0)}</b>`;
            },
            shared: true
        },
        xAxis: {
            type: 'linear',
            minorTickInterval: 5,
            plotLines: getPlotLines(projectedNetWorthAge ?? 0),
        },
        yAxis: {
            title: {
                text: "Future Value in dollars",
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            labels: {
                enabled: false
            }
        },
        series: [{
            type: 'line',
            pointStart: investorGroup.planningPeriod.ageFrom,
            marker: {
                enabled: false,
            },
            name: "Age",
            color: "#00A0AA",
            lineWidth: 4,
            data: [
                netWealthAtCurrentAge ?? 0,
                ...futureValueOfExcessAssetsDTO.futureValueOfExcessAssetsByAge
            ]
        }]
    };
}

let elementSVG: any;

function drawPlotLineWithVariableHeight(chart: Highcharts.Chart, dataIndex: number) {
    var xAxis = chart.xAxis[0],
        yAxis = chart.yAxis[0],
        xValue = xAxis.toPixels(chart.series[0].data[dataIndex].x ?? 0, false),
        yValue = yAxis.toPixels(chart.series[0].data[dataIndex].y ?? 0, false);

    if (elementSVG) {
        elementSVG.destroy();
    }

    elementSVG = chart.renderer.rect(xValue - 2, yValue, 4, chart.plotHeight + chart.plotTop - yValue, 0, 1)
        .attr({
            stroke: NET_WORTH_OVER_TIME_PLOT_LINE,
            fill: NET_WORTH_OVER_TIME_PLOT_LINE,
            zIndex: 2,
        })
        .add();
}

function drawPlotlineLabelTooltip(chart: Highcharts.Chart, dataIndex: number, netWorthAge: number) {
    var xAxis = chart.xAxis[0],
        yAxis = chart.yAxis[0],
        yValue = yAxis.toPixels(chart.series[0].data[dataIndex].y ?? 0, false);

    let plotLineLabel = (xAxis.options?.plotLines as Highcharts.XAxisPlotLinesOptions[])[0].label;

    if (plotLineLabel) {
        plotLineLabel.y = yValue + ((chart.plotHeight + chart.plotTop - yValue) / 2) - 20;
        xAxis.update({
            ...xAxis.options,
            ...{
                options: {
                    plotLines: getPlotLines(netWorthAge)
                }
            }
        });
    }
}

const getPlotLines: (age: number) => Highcharts.XAxisPlotLinesOptions[] = (age: number) => {
    const horizontalOffsetToCenterLabel: number = (Math.log10(age) + 1) * -0.5;
    const horizontalRotationAngle: number = 0;
    return [{
        value: age,
        width: 0,
        label: {
            align: 'center',
            textAlign: 'center',
            rotation: horizontalRotationAngle,
            useHTML: true,
            x: horizontalOffsetToCenterLabel,
            formatter: function () {
                return `<div class="net-worth-over-time-chart-plot-line">${age}</div>`;
            }
        },
    }];
}