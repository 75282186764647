import React from 'react';
import {Loader} from 'xps-react';
import classNames from "classnames";

type LoadingIndicatorProps = {
    displayMessage?: string;
    className?: string;
}

export const LoadingIndicator = (loadingIndicatorProps: LoadingIndicatorProps) => {
    return <div
        className={classNames('loading-container', loadingIndicatorProps.className)}>
        <Loader
            message={loadingIndicatorProps.displayMessage ? loadingIndicatorProps.displayMessage : "Loading"}
            size="md"
        />
    </div>
}

export default LoadingIndicator;