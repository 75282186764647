import {FamilyRelationshipType} from "./models/FamilyRelationshipType";
import {MemberType} from "./models/MemberType";
import {PrimaryContact} from "./models/PrimaryContact";
import {FamilyRelationshipState} from "./FamilyTree/FamilyTreeSlice";

const LEGAL_PARTNER_RELATIONSHIPS = [
    FamilyRelationshipType.SPOUSE,
    FamilyRelationshipType.DOMESTIC_PARTNER,
];

const CURRENT_PARTNER_RELATIONSHIPS = [
    FamilyRelationshipType.SPOUSE,
    FamilyRelationshipType.DOMESTIC_PARTNER,
    FamilyRelationshipType.SIGNIFICANT_OTHER,
    FamilyRelationshipType.EX_SPOUSE
];

export const isLegalPartnerRelationship: (relationshipType: FamilyRelationshipType | null) => boolean =
    (relationshipType) => !!relationshipType && LEGAL_PARTNER_RELATIONSHIPS.includes(relationshipType);

export const isPartnerRelationship: (relationshipType: FamilyRelationshipType | null | undefined) => boolean =
    (relationshipType) => !!relationshipType && CURRENT_PARTNER_RELATIONSHIPS.includes(relationshipType);

export const findPartners: (member: MemberType) => MemberType[] =
    member => member.family.filter(({type}) => isPartnerRelationship(type))
        .map(relationship => relationship.fromMember);

export const findChildren: (member: MemberType | undefined) => MemberType[] =
    member => member && member.family ? member.family.filter(({type}) => type === FamilyRelationshipType.CHILD)
        .map(relationship => relationship.fromMember) : [];

export const findExtendedParents: (member: MemberType | undefined | null) => MemberType[] =
    member => member && member.family ? member.family.filter(({type}) => type === FamilyRelationshipType.PARENT)
        .map(relationship => relationship.fromMember) : [];

export const findPrimaryContactPartners: (member: PrimaryContact) => MemberType[] =
    member => member.family.filter(({type}) => isPartnerRelationship(type))
        .map(relationship => relationship.fromMember);

export const findPartnersAndExes: (member: MemberType) => MemberType[] =
    member => member.family.filter(({type}) => isPartnerRelationship(type) || type === FamilyRelationshipType.EX_SPOUSE)
        .map(relationship => relationship.fromMember);

export const isChildOf: (childId: string, member: MemberType, primaryContact?: MemberType) => boolean = (childId, member, primaryContact) => {
    // if member is of type extended
    const parents = primaryContact && getParentsForPrimaryMemberOrLateral(primaryContact, childId);
    let children = [];

    if (parents && parents.length > 0 && parents.filter(p => p.id === member.id)) {
        if (primaryContact?.id === childId) {
            children = primaryContact.family
                .filter(relationship => relationship.type === FamilyRelationshipType.PARENT)
                .filter(r => r.fromMember.id === member.id) || []
        } else {
            const primaryContactPartner = primaryContact && findPartnersAndExes(primaryContact)
                .find(partner => partner.id === childId);
            children = primaryContactPartner?.family
                .filter(relationship => relationship.type === FamilyRelationshipType.PARENT)
                .filter(r => r.fromMember.id === member.id) || []
        }
    } else {
        children = member.family
            .filter(relationship => relationship.type === FamilyRelationshipType.CHILD)
            .filter(child => child.fromMember.id === childId);
    }

    return children.length > 0;
}
export const getPrimaryParentForMemberWhenMemberIsNotPrimaryOrLateral: (memberId: string, memberType: MemberType) => MemberType | undefined =
    (memberId: string, memberType: MemberType) => {
        const children = memberType.family.filter(relationship => relationship.type === FamilyRelationshipType.CHILD);
        if (children.find(relationship => relationship.fromMember.id === memberId)) {
            return memberType
        } else {
            return memberType.family
                .map(relationship => getPrimaryParentForMemberWhenMemberIsNotPrimaryOrLateral(memberId, relationship.fromMember))
                .find(member => member)
        }
    }

export const findAllPartnersForMemberId = (memberId: string, rootFamilyMember: MemberType): Map<string, [MemberType, FamilyRelationshipType]> => {
    let partners: Map<string, [MemberType, FamilyRelationshipType]> = new Map<string, [MemberType, FamilyRelationshipType]>();

    rootFamilyMember.family.forEach(relationship => {
        if (memberId === rootFamilyMember.id) {
            if (isPartnerRelationship(relationship.type)) {
                partners.set(relationship.fromMember.id, [relationship.fromMember, relationship.type])
            }
        }
        if (memberId === relationship.fromMember.id) {
            if (isPartnerRelationship(relationship.type)) {
                partners.set(rootFamilyMember.id, [rootFamilyMember, relationship.type])
            }
        }
        partners = new Map([...Array.from(partners.entries()), ...Array.from(findAllPartnersForMemberId(memberId, relationship.fromMember).entries())]);
    });

    return partners;
}

export const findCommonChildren: (member: MemberType, partnerMember: MemberType) => MemberType[] = (member: MemberType, partnerMember: MemberType) => {
    const partnerChildren = findChildren(partnerMember);
    return findChildren(member)
        .filter(child => partnerChildren.find(partnerChild => partnerChild.id === child.id))
        .sort((child1, child2) => child2.age >= child1.age ? 1 : -1);
}

export const getAllPartnerRelationshipStatesForMemberId: (memberId: string, rootFamilyMember: MemberType) => FamilyRelationshipState[] =
    (memberId: string, rootFamilyMember: MemberType) => Array.from(findAllPartnersForMemberId(memberId, rootFamilyMember).entries())
        .map(([partnerId, [memberType, relationship]]) => ({
            memberId: partnerId,
            fullName: `${memberType.firstName} ${memberType.lastName}`,
            type: relationship
        }))

export const getRelationshipsState = (memberBeingEditedId: string, primaryContact: MemberType): FamilyRelationshipState[] => {
    const familyRelationshipStates: FamilyRelationshipState[] = [];

    const primaryParent = getPrimaryParentForMemberWhenMemberIsNotPrimaryOrLateral(memberBeingEditedId, primaryContact);
    const primaryOrPartnerParents = getParentsForPrimaryMemberOrLateral(primaryContact, memberBeingEditedId);
    const maybeExtendedFamilyRelation = getExtendedFamilyConnection(primaryContact, memberBeingEditedId);
    const isOtherMember = primaryContact.otherMembers.findIndex(m => m.fromMember.id === memberBeingEditedId) >= 0;
    const isPrimaryOrLateralMemberWithParents = primaryOrPartnerParents.length > 0;
    const hasParentsButNotPrimaryOrLateral = !!primaryParent;

    if (isOtherMember) {
        familyRelationshipStates.push({
            memberId: primaryContact.id,
            fullName: `${primaryContact.firstName} ${primaryContact.lastName}`,
            type: FamilyRelationshipType.OTHER
        })
        return familyRelationshipStates;
    }

    if (isPrimaryOrLateralMemberWithParents) {
        primaryOrPartnerParents.forEach(p => {
            familyRelationshipStates.push({
                memberId: p.id,
                fullName: `${p.firstName} ${p.lastName}`,
                type: FamilyRelationshipType.PARENT
            })
        })
    } else if (hasParentsButNotPrimaryOrLateral) {
        familyRelationshipStates.push({
            memberId: primaryParent.id,
            fullName: `${primaryParent.firstName} ${primaryParent.lastName}`,
            type: FamilyRelationshipType.CHILD
        })
        const otherParent = Array.from(findAllPartnersForMemberId(primaryParent.id, primaryContact).entries())
            .map(([, [member]]) => member)
            .find(member => isChildOf(memberBeingEditedId, member, primaryContact));
        if (otherParent) {
            familyRelationshipStates.push({
                memberId: otherParent.id,
                fullName: `${otherParent.firstName} ${otherParent.lastName}`,
                type: FamilyRelationshipType.CHILD
            })
        }
    } else if (maybeExtendedFamilyRelation) {
        familyRelationshipStates.push({
            memberId: maybeExtendedFamilyRelation.id,
            fullName: `${maybeExtendedFamilyRelation.firstName} ${maybeExtendedFamilyRelation.lastName}`,
            type: FamilyRelationshipType.PARENT
        })
    }
    getAllPartnerRelationshipStatesForMemberId(memberBeingEditedId, primaryContact)
        .forEach(partner => familyRelationshipStates.push(partner))

    return familyRelationshipStates;
}

const getExtendedFamilyConnection = (primaryContact: MemberType, memberId: string): MemberType | null => {
    const partners = findPartnersAndExes(primaryContact);

    for (let member of [primaryContact, ...partners]) {
        const parents = findExtendedParents(member)
        if (parents.some(parent => parent.id === memberId)) {
            return member;
        }
    }
    return null;
}

export const getParentsForPrimaryMemberOrLateral = (primaryContact: MemberType, memberId: string): MemberType[] => {
    const partners = findPartnersAndExes(primaryContact);

    for (let member of [primaryContact, ...partners]) {
        if (member.id === memberId) {
            return findExtendedParents(member)
        }
    }
    return [];
}

export const getAllExtendedParents = (primaryContact: MemberType): MemberType[] => {
    const partners = findPartnersAndExes(primaryContact);
    let parents: MemberType[] = []

    for (let member of [primaryContact, ...partners]) {
        parents = [...parents, ...findExtendedParents(member)]
    }
    return parents;
}

export const isMemberPrimaryOrLateral = (primaryContact: MemberType, memberId: string): boolean => {
    if (primaryContact.id === memberId) return true;

    return findPartnersAndExes(primaryContact)
        .findIndex(m => m.id === memberId) >= 0;
}

export const isExtendedFamily = (primaryContact: MemberType, memberId: string): boolean => {
    return getAllExtendedParents(primaryContact).some(p => p.id === memberId);
}

export const getChildForExtendedParent = (primaryContact: MemberType, memberId: string): MemberType | null => {
    const partners = findPartnersAndExes(primaryContact);

    for (let member of [primaryContact, ...partners]) {
        if (member.family.some(m => m.type === FamilyRelationshipType.PARENT && m.fromMember.id === memberId)) {
            return member;
        }
    }
    return null;
}
