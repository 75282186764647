import React from 'react';
import {InfoPopover, PercentInput} from "../../components";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";

type EffectiveFeesProps = {
    referenceData: ReferenceDataType,
    managementFees: string,
    updateManagementFees: (managementFees: string) => void,
    isReadOnly?: boolean
}

export const EffectiveFees = (props: EffectiveFeesProps) => {
    const {referenceData, updateManagementFees, managementFees, isReadOnly = false} = props;
    const {managementFeePercentMaximum, managementFeePercentMinimum} = referenceData.managementFees;

    return <div>
        <div className="h4 sections">
            <span>Effective Fees</span>
            <InfoPopover
                content={
                    <div>
                        <b>Effective Fees</b> should reflect the effective fee on investable assets including both
                        advisory
                        and
                        product fees.
                    </div>
                }
                direction="bottom"
                label="effective-fees-info"
                width="345px"
            />
        </div>
        <hr className="section"/>
        <div className="h4 parent">
            {/*TODO : For hasProposal = 'true', temporary fields have been added. Needs to change once theme is introduced.*/}

            {isReadOnly ? <div>
                    <label className="h5 label" htmlFor="managementFees">
                        Management Fees
                    </label>
                    <div className="totalInput totalTax-alignment" id="managementFeesInput">
                        <span className="disabledInput">{parseFloat(managementFees).toFixed(2)}{'%'}</span>
                    </div>
                </div> :
                <PercentInput
                    defaultValue={referenceData.managementFees.managementFeePercentDefault}
                    className={"managementFees profileDetails"}
                    label={"Management Fees"}
                    minValue={Number(managementFeePercentMinimum)}
                    maxValue={Number(managementFeePercentMaximum)}
                    onChange={(value: string) => updateManagementFees(value)}
                    value={managementFees}
                />}
        </div>
    </div>;
};
