import Popover from "@nt/dds-react/core/Popover";
import {formatCurrency} from "../../../utils/format";
import {Icon} from "../../../components";
import {toDisplayDateFormat} from "../../../utils/dateUtils";
import {DISPLAY_MONTH_YEAR_FORMAT} from "../../../constants/common";
import React from "react";
import {PersonalLiabilitySummary} from "../../models/PersonalLiability";
import {shortenString} from "../../common/textUtils";

type Props = {
    personalLiabilitySummary: PersonalLiabilitySummary
}

function AssetSummaryPersonalLiabilityDetails({personalLiabilitySummary}: Props) {
    return <>
        <span role="cell"
              className='paddingleft-xxl textalign-left cell-with-popover'>
                <span
                    className='cell-text-content paddingright-sm'>{shortenString(personalLiabilitySummary.description, 23)}</span>
            {
                personalLiabilitySummary.collateral &&
                <Popover
                    id={`personal-liability-popover-${personalLiabilitySummary.id}`}
                    delay={0}
                    content={
                        <div>
                            <i>The liability is collateralized by the <br/> following asset:</i>
                            <hr/>
                            <div
                                className="asset-summary-popover-content">
                                <span>{personalLiabilitySummary.collateral.description}</span>
                                <span>{formatCurrency(personalLiabilitySummary.collateral.presentValue)}</span>
                            </div>
                        </div>
                    }
                    direction="bottom"
                    aria-label="collateral">
                    <Icon className="popover-trigger popoverContainer" name="link" ariaHidden={false}
                          ariaLabel="collateralized asset"/>
                </Popover>
            }
            </span>
        <span role="cell"
              className='textalign-left cell-text-content'>{personalLiabilitySummary.institution}</span>
        <span role="cell"
              className='textalign-right cell-text-content'>
                {`${personalLiabilitySummary.interestRate}%`}
            </span>
        <span role="cell"
              className='textalign-right cell-text-content'>
                {toDisplayDateFormat(personalLiabilitySummary.maturityDate, DISPLAY_MONTH_YEAR_FORMAT)}
            </span>
    </>
}

export default AssetSummaryPersonalLiabilityDetails;