import React, {ReactNode, useState} from "react";
import CommunicationsContext, {CommunicationsConnectionState} from "./CommunicationsContext";
import {
    CallAgentProvider,
    CallClientProvider,
    CallProvider,
    createStatefulCallClient,
    StatefulCallClient
} from "@azure/communication-react";
import {Call, CallAgent} from "@azure/communication-calling";
import {AzureCommunicationTokenCredential} from "@azure/communication-common";

type CommunicationProviderProps = {
    children: ReactNode,
}

type CallManagers = {
    statefulCallClient: StatefulCallClient,
    callAgent?: CallAgent,
    call?: Call,
    tokenCredential?: AzureCommunicationTokenCredential
}

const createInitialCallManager = () => {
    return {
        statefulCallClient: createStatefulCallClient({
            userId: {communicationUserId: ''}
        }),
    };
}

const CommunicationsProvider = ({children}: CommunicationProviderProps) => {
    const [callManagers, setCallManagers] = useState<CallManagers>(createInitialCallManager());

    const connect = async (
        acsAccessToken: string,
        userId: string,
        joinUrl: string) => {
        const tokenCredential = new AzureCommunicationTokenCredential(acsAccessToken);
        const statefulCallClient = createStatefulCallClient({
            userId: {communicationUserId: userId}
        });

        const callAgent = await statefulCallClient.createCallAgent(tokenCredential);

        const call = await statefulCallClient.getDeviceManager().then((deviceManager) => {
            return deviceManager.askDevicePermission({
                audio: true,
                video: true
            }).then((deviceAccess) => {
                return callAgent.join({meetingLink: joinUrl}, {
                    audioOptions: {muted: !deviceAccess.audio}
                });
            })
        })

        setCallManagers({
            statefulCallClient,
            callAgent,
            call,
            tokenCredential
        })
    };

    const disconnect = async (isCurrentUserPresenting: boolean) => {
        const promisedDisposals = [];
        if (callManagers.call) {
            promisedDisposals.push(callManagers.call.hangUp({forEveryone: isCurrentUserPresenting}));
        }
        if (callManagers.callAgent) {
            promisedDisposals.push(callManagers.callAgent.dispose());
        }
        if (promisedDisposals.length) {
            await Promise.all(promisedDisposals).catch((error) => {
                console.error("Error hanging up call : ", error.message)
            });
        }
        if (callManagers.tokenCredential) {
            callManagers.tokenCredential.dispose();
        }
        setCallManagers(createInitialCallManager());
    }

    return (<CommunicationsContext.Provider value={{
        connect,
        disconnect,
        connectionState: callManagers.call
            ? CommunicationsConnectionState.CONNECTED
            : CommunicationsConnectionState.DISCONNECTED,
    }}>
        <CallClientProvider callClient={callManagers.statefulCallClient}>
            <CallAgentProvider callAgent={callManagers?.callAgent}>
                <CallProvider call={callManagers?.call}>
                    {children}
                </CallProvider>
            </CallAgentProvider>
        </CallClientProvider>
    </CommunicationsContext.Provider>);
}

export default CommunicationsProvider;