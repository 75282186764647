import React, {useEffect, useState} from "react";
import LoadingIndicator from "../pages/LoadingIndicator";
import FamilyTree from "../ClientManagement/FamilyTree/FamilyTree";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import useProfileAndProposals from "../hooks/useProfileAndProposals";
import {reportingApiClient} from "./ReportingApiClient";
import {ClientProfilePageConfigForFamilyTree, FamilyTreeScreenShotDetails, ReportConfig} from "./models/Reporting";
import PlanSummary from "../Plans/PlanSummary/PlanSummary";
import SetPortfolioReserve from "../Goals/PortfolioReserve/SetPortfolioReserve";
import PortfolioReserve from "../Goals/PortfolioReserve/PortfolioReserve";
import AssetReliance from "../ClientManagement/AssetReliance/AssetReliance";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {
    selectFamilyTree,
    setFamilyTreeViewportBounds,
    setIsExtendedFamilySelected,
    setIsOtherMembersSelected
} from "../ClientManagement/FamilyTree/FamilyTreeSlice";
import CurrentVsProposed from "../ClientManagement/AssetAllocation/CurrentVsProposed";
import PrintViewWrapper from "./DetailedEmailReports/PrintViewWrapper";
import GoalsSummaryContainer from "./DetailedEmailReports/GoalsDetailsReport/GoalsSummaryContainer";
import GoalsService from "../Goals/controller/GoalsService";
import {selectGoalModel, setGoalModel} from "../Goals/controller/GoalsModelSlice";
import Summary from "../Goals/Summary/Summary";
import {GoalModelType} from "../Goals/models/GoalModelType";
import {NO_OP} from "../constants/common";
import CurrentVsProposedDetailsReport from "./DetailedEmailReports/CurrentVsProposed/CurrentVsProposedDetailsReport";
import AssetRelianceContainer from "./DetailedEmailReports/AssetRelianceReport/AssetRelianceContainer";
import AgendaContainer from "./DetailedEmailReports/AgendaDetailReport/AgendaContainer";
import CoverPage from "./CoverPage";
import DisclaimerPages from "./DisclaimerPages";
import AssetsReports from "./DetailedEmailReports/Assets";
import WealthTransferReport from "./DetailedEmailReports/WealthTransferReport/WealthTransferReport";

type PageComponentProps = {
    displayName: string
}
type PageType = {
    PageComponent: React.FC<PageComponentProps>,
    isEnabled: boolean,
    familyTreeScreenShotDetails?: FamilyTreeScreenShotDetails | null
}

const FamilyTreeReport = ({familyTreeConfig}: { familyTreeConfig: ClientProfilePageConfigForFamilyTree }) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const familyTree = useAppSelector(selectFamilyTree);

    useEffect(() => {
        if (familyTreeConfig.familyTreeScreenShotDetails) {
            const {
                familyMembersDetails,
                isOtherMembersSelected,
                isExtendedFamilySelected
            } = familyTreeConfig.familyTreeScreenShotDetails;
            familyMembersDetails && dispatch(setFamilyTreeViewportBounds(familyMembersDetails));
            dispatch(setIsOtherMembersSelected(isOtherMembersSelected));
            dispatch(setIsExtendedFamilySelected(isExtendedFamilySelected));
        }
        setIsLoading(false);
    }, [familyTreeConfig,familyTree]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <FamilyTree/>
}

const GoalsSummary = () => {
    const {id} = useParams<RouteWithId>();
    const previousGoalModel = useAppSelector<GoalModelType>(selectGoalModel);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        GoalsService.getGoalsModel(id, previousGoalModel, NO_OP)
            .then(goalResponse => {
                dispatch(setGoalModel(goalResponse))
                setIsLoading(false)
            })
    }, [id]);

    if (isLoading) {
        return <LoadingIndicator/>
    }
    return <Summary/>
}

const ReportingPrintView = () => {
    const {id} = useParams<RouteWithId>();
    const {approvedProfile} = useProfileAndProposals(id);
    const displayName = approvedProfile.displayName;
    const [pages, setPages] = useState<PageType[]>();

    useEffect(() => {
        reportingApiClient.getReportConfig().then((reportConfig) => {
            setPages(getPages(reportConfig));
        })
    }, []);


    if (!pages || !displayName) {
        return <LoadingIndicator/>
    }

    function getPages(reportConfig: ReportConfig): PageType[] {
        return [
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                        displayName={displayNameForScreen}
                        showHeader={false}
                        showFooter={false}
                    >
                        <CoverPage
                            displayName={displayNameForScreen}
                            creatorName={reportConfig.coverPage.creatorName!}
                            creatorEmail={reportConfig.coverPage.creatorEmail!}
                        />
                    </PrintViewWrapper>,
                isEnabled: reportConfig.coverPage.isEnabled
            },
            {
                PageComponent: () => {
                    return <AgendaContainer/>
                },
                isEnabled: reportConfig.agenda.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}><FamilyTreeReport
                    familyTreeConfig={reportConfig.familyTree}/></PrintViewWrapper>,
                isEnabled: reportConfig.familyTree.isEnabled,
                familyTreeScreenShotDetails: reportConfig.familyTree.familyTreeScreenShotDetails
            },
            {
                PageComponent: () => {
                    return <AssetsReports reportConfig={reportConfig} />;
                },
                isEnabled: isAnyAssetPageEnabled(reportConfig)
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}> <GoalsSummary/></PrintViewWrapper>,
                isEnabled: reportConfig.goalsSummary.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <GoalsSummaryContainer
                    displayName={displayNameForScreen}/>,
                isEnabled: reportConfig.goalsSummaryDetailed.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}> <PlanSummary/></PrintViewWrapper>,
                isEnabled: reportConfig.planSummary.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}>
                    <SetPortfolioReserve/></PrintViewWrapper>,
                isEnabled: reportConfig.reviewPortfolioReserveDefinition.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}>
                    <PortfolioReserve/></PrintViewWrapper>,
                isEnabled: reportConfig.reviewPortfolioReserve.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}><AssetReliance/></PrintViewWrapper>,
                isEnabled: reportConfig.assetReliance.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <AssetRelianceContainer
                    displayName={displayNameForScreen}/>,
                isEnabled: reportConfig.assetRelianceDetailed.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}>
                    <CurrentVsProposed/></PrintViewWrapper>,
                isEnabled: reportConfig.currentVsProposed.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) =>
                    <CurrentVsProposedDetailsReport displayName={displayNameForScreen}/>,
                isEnabled: reportConfig.currentVsProposedDetailed.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) =>
                    <WealthTransferReport/>,
                isEnabled: reportConfig.wealthTransferDetailed.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) =>
                    <DisclaimerPages displayName={displayNameForScreen}/>,
                isEnabled: reportConfig.disclaimerPage.isEnabled
            }
        ]
    }

    return (
        <div className='preview-and-send presentation-viewport' data-theme="print">
            {pages.map(({PageComponent, isEnabled}) => {
                if (isEnabled) {
                    return <PageComponent displayName={displayName}/>
                }
                return <></>;
            })}
        </div>
    );
};

export default ReportingPrintView;

function isAnyAssetPageEnabled(reportConfig: ReportConfig): boolean {
    return reportConfig.assetsSummary.isEnabled
        || reportConfig.assetsSummaryDetailed.isEnabled
        || reportConfig.currentNetWorth.isEnabled
        || reportConfig.currentNetWorthDetailed.isEnabled
        || reportConfig.currentNetWorthAtDeath.isEnabled
        || reportConfig.currentNetWorthAtDeathDetailed.isEnabled
}
