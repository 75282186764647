import { TableActionDropdownMenu } from "../../../components/Table/TableActionDropdownMenu";
import { AccordionItem, DropdownItem, Icon } from "../../../components";
import React from "react";
import classNames from "classnames";
import { EquityCompensationFormData } from "../../models/EquityCompensation";
import { TableCell } from "src/components/Table/TableCell";
import { NO_OP } from "src/constants/common";
import { formatCurrency } from "src/utils/format";
import { toDisplayDateFormat } from "src/utils/dateUtils";
import {shortenName} from "../../common/textUtils";
import {getCurrentOwnerByOwnerMemberId, getMemberName} from "../common/ownershipUtils";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";

type AssetSummaryEquityCompProps = {
  equityComp: EquityCompensationFormData;
  onClickEdit: (id: string | undefined) => void;
  onClickDelete: (equityComp: EquityCompensationFormData) => void;
  actionsDisabled: boolean;
  gridClassName: string;
  uuid: string;
  memberGroup: MemberGroup | undefined;
};
export const EquityCompensationTableRow = ({
  equityComp,
  onClickEdit,
  onClickDelete,
  actionsDisabled,
  gridClassName,
  uuid,
  memberGroup,
}: AssetSummaryEquityCompProps) => {
  const hasStockOptions = equityComp.stockOptionAfterTaxUnvestedValue !== null || equityComp.stockOptionAfterTaxVestedValue !== null;
  const hasStockGrants = equityComp.stockGrantAfterTaxUnvestedValue !== null
  return (
    <AccordionItem
      className="assets-grid-with-actionmenu equity-compensation-summary-accordion-item"
      HeaderComponent={({ expanded }) => (
        <div
          key={`equity-compensation-row-${equityComp.id}`}
          aria-label={`equity-compensation-row-${equityComp.id}`}
          role="row"
          className={classNames("assets-grid-table-row", gridClassName)}
          tabIndex={0}
        >
          <span
            role="cell"
            aria-label="Name & Owner"
            className="display-flex align-items-center"
          >
            <Icon
              name={expanded ? "chevron_down" : "chevron_right"}
              size="large"
            />
            <span className="paddingleft-md">
              <div className="cell-text-content">
                {shortenName(equityComp.entityOrAccountName)}
              </div>
              <div
                className="sublabel-12px cell-text-content"
                data-testid="tableCellSubText"
              >
                {getMemberName(getCurrentOwnerByOwnerMemberId(equityComp.owner, memberGroup!))}
              </div>
            </span>
          </span>
          <TableCell
            ariaLabel="Pre-Tax Unvested"
            text={
              equityComp.preTaxUnvestedValue
                ? formatCurrency(equityComp.preTaxUnvestedValue)
                : "--"
            }
            className="textalign-right"
          />
          <TableCell
            ariaLabel="Pre-Tax Vested"
            text={
              equityComp.preTaxVestedValue
                ? formatCurrency(equityComp.preTaxVestedValue)
                : "--"
            }
            className="textalign-right"
          />
          <TableCell
            ariaLabel="After-Tax Unvested"
            text={
              equityComp.afterTaxUnvestedValue
                ? formatCurrency(equityComp.afterTaxUnvestedValue)
                : "--"
            }
            className="textalign-right"
          />
          <TableCell
            ariaLabel="After-Tax Vested"
            text={
              equityComp.afterTaxVestedValue
                ? formatCurrency(equityComp.afterTaxVestedValue)
                : "--"
            }
            className="textalign-right"
          />
          <TableActionDropdownMenu
            disabled={actionsDisabled}
            ariaLabel={`${equityComp.entityOrAccountName} Menu`}
            onClick={(e) => e.stopPropagation()}
          >
            <DropdownItem
              itemText="Edit Asset"
              value="Edit Asset"
              onClick={() => onClickEdit(equityComp.id)}
            />
            <DropdownItem
              itemText="Delete Asset"
              value="Delete Asset"
              onClick={() => onClickDelete(equityComp)}
            >
              <Icon name="delete" className="asset-table-delete-icon" />
            </DropdownItem>
          </TableActionDropdownMenu>
        </div>
      )}
      uuid={uuid}
      key={uuid}
    >
      {hasStockOptions && <div
        key={`stock-options-row-${equityComp.id}`}
        aria-label={`stock-options-row-${equityComp.id}`}
        role="row"
        className={classNames(
          "assets-grid-table-row nested-row-within-accordion",
          gridClassName
        )}
        tabIndex={0}
      >
        <TableCell
          ariaLabel="Name & As Of Date"
          text={"Options"}
          subtext={
            equityComp.stockOptionsAsOfDate
              ? `As of ${toDisplayDateFormat(equityComp.asOfDate)}`
              : "--"
          }
        />
        <TableCell
          text={
            equityComp.stockOptionPreTaxUnvestedValue
              ? formatCurrency(equityComp.stockOptionPreTaxUnvestedValue)
              : "--"
          }
          className="textalign-right"
          ariaLabel="Pre-Tax Unvested"
        />
        <TableCell
          text={
            equityComp.stockOptionPreTaxVestedValue
              ? formatCurrency(equityComp.stockOptionPreTaxVestedValue)
              : "--"
          }
          className="textalign-right"
          ariaLabel="Pre-Tax Vested"
        />
        <TableCell
          text={
            equityComp.stockOptionAfterTaxUnvestedValue
              ? formatCurrency(equityComp.stockOptionAfterTaxUnvestedValue)
              : "--"
          }
          className="textalign-right"
          ariaLabel="After-Tax Unvested"
        />
        <TableCell
          text={
            equityComp.stockOptionAfterTaxVestedValue
              ? formatCurrency(equityComp.stockOptionAfterTaxVestedValue)
              : "--"
          }
          className="textalign-right"
          ariaLabel="After-Tax Vested"
        />
        <TableActionDropdownMenu
          disabled={true}
          ariaLabel={`${equityComp.entityOrAccountName} Stock Options Menu`}
        >
          <DropdownItem
            itemText="Edit Options"
            value="Edit Options"
            onClick={NO_OP}
          />
        </TableActionDropdownMenu>
      </div>}
      {hasStockGrants && <div
        key={`stock-grants-row-${equityComp.id}`}
        aria-label={`stock-grants-row-${equityComp.id}`}
        role="row"
        className={classNames(
          "assets-grid-table-row nested-row-within-accordion",
          gridClassName
        )}
        tabIndex={0}
      >
        <TableCell
          ariaLabel="Name & As Of Date"
          text={"Grants"}
          subtext={
            equityComp.stockGrantsAsOfDate
              ? `As of ${toDisplayDateFormat(equityComp.asOfDate)}`
              : "--"
          }
        />
        <TableCell
          ariaLabel="Pre-Tax Unvested"
          text={
            equityComp.stockGrantPreTaxUnvestedValue
              ? formatCurrency(equityComp.stockGrantPreTaxUnvestedValue)
              : "--"
          }
          className="textalign-right"
        />
        <TableCell
          ariaLabel="Pre-Tax Vested"
          text={"--"}
          className="textalign-right"
        />
        <TableCell
          ariaLabel="After-Tax Unvested"
          text={
            equityComp.stockGrantAfterTaxUnvestedValue
              ? formatCurrency(equityComp.stockGrantAfterTaxUnvestedValue)
              : "--"
          }
          className="textalign-right"
        />
        <TableCell
          ariaLabel="After-Tax Vested"
          text={"--"}
          className="textalign-right"
        />
        <TableActionDropdownMenu
          disabled={true}
          ariaLabel={`${equityComp.entityOrAccountName} Stock Options Menu`}
        >
          <DropdownItem
            itemText="Edit Grants"
            value="Edit Grants"
            onClick={NO_OP}
          />
        </TableActionDropdownMenu>
      </div>}
    </AccordionItem>
  );
};
