import React, {useEffect, useState} from "react";
import {Col, Grid, Row} from "xps-react";
import {RouteWithId} from "../../../routes/types";
import {useHistory, useParams} from "react-router-dom";
import {toDisplayDateFormat} from "../../../utils/dateUtils";
import {ProfileDetailsProposals} from "./ProfileDetailsProposals";
import useProfileAndProposals from "../../../hooks/useProfileAndProposals";
import {MeetingActions} from "../../Meeting/MeetingActions";
import {Button, DropdownItem} from "../../../components";
import usePageViewTimer from "../../../hooks/usePageViewTimer";
import {ProfileUpdates} from "./ProfileUpdates";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {setHeaderTickerTabs} from "../HeaderTickerTabsSlice";
import {EMPTY_HEADER_TICKER_TABS_RESPONSE} from "../HeaderTickerTabType";
import {resetShowOutOfEstateAssets} from "../../../Assets/common/OutOfEstate/OutOfEstateAssetsSlice";
import {resetAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import {ProfileDetailsActionMenu} from "./ProfileDetailsActionMenu";
import {clientManagementApiClient} from "../../ClientManagementApiClient";
import {
    ProfileAwareActivatePortfolioReserveSwitch
} from "../../../Goals/PortfolioReserve/ProfileAwareActivatePortfolioReserveSwitch";
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import {LabelPosition} from "../../../Goals/PortfolioReserve/ActivatePortfolioReserveSwitch";

const ProfileDetails: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const {profile, proposals, isLoading} = useProfileAndProposals(id);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [openArchiveProfileModal, setOpenArchiveProfileModal] = useState(false);

    usePageViewTimer('Profile Details Page Load Timer (milliseconds)', isLoading);

    useEffect(() => {
        dispatch(resetAccordionPreferencesState(id));
        dispatch(resetShowOutOfEstateAssets());
    });

    const handleViewProfileNavigation = () => {
        history.push(`/Profile/${id}/ClientProfile/FamilyTree`);
    };

    const handleProfileSettingsNavigation = () => {
        history.push(`/Profile/${id}/ProfileSettings`);
    };

    const handleNewProposalSettingsNavigation = () => {
        history.push(`/Profile/${id}/NewProposalSettings`);
    };

    const handleViewArchivedProposalsNavigation = () => {
        history.push(`/Profile/${id}/ViewArchivedProposals`);
    };

    const handleArchivingProfileAndDashboardNavigation = async () => {
        setOpenArchiveProfileModal(false)
        await clientManagementApiClient.putProfile({...profile, archived: true})
            .catch(error => console.error('Could not archive profile', error.message));
        history.push(`/`);
    };

    const handleProposalSettingsNavigation = (proposalId: string) => {
        history.push(`/Profile/${id}/ProposalSettings/${proposalId}`);
    }

    useEffect(() => {
        dispatch(setHeaderTickerTabs(EMPTY_HEADER_TICKER_TABS_RESPONSE));
    }, [])

    return (
        <>
            <CustomModal isOpen={openArchiveProfileModal}
                         title='Archive this profile?'
                         content='Are you sure you want to archive the selected profile?'
                         onClickCancel={() => setOpenArchiveProfileModal(false)}
                         onClickConfirm={() => handleArchivingProfileAndDashboardNavigation()}
                         cancelText='CANCEL'
                         confirmText='ARCHIVE PROFILE'
            />

            <Grid fluid={true}>
                <Row>
                    <Col>
                        <div className="page-container profile-details-container">
                            <div className="profile-details-cta">
                                <div className="h1">{profile?.displayName}</div>
                                <div className="right-aligned">
                                    <ProfileDetailsActionMenu
                                        dropdownItems={[
                                            <DropdownItem
                                                key={`archiveProfile`}
                                                aria-label="archiveProfile"
                                                className="viewProposalDetails"
                                                onClick={() => {
                                                    setOpenArchiveProfileModal(true)
                                                }}
                                                itemText="Archive Profile"
                                                value="ArchiveProposal"
                                            />
                                        ]}
                                    />
                                    <Button
                                        id="NavigateToProfileSettings"
                                        className="marginleft-sm marginright-sm"
                                        icon="none"
                                        iconName="settings"
                                        includeRef={false}
                                        kind="secondary"
                                        size="medium"
                                        tabIndex={0}
                                        type="button"
                                        onClick={handleProfileSettingsNavigation}
                                    >
                                        <span className="new-client-text">PROFILE SETTINGS</span>
                                    </Button>
                                    <Button
                                        id="NavigateToFamilyTree"
                                        className="marginleft-sm"
                                        icon="none"
                                        iconName="settings"
                                        includeRef={false}
                                        kind="primary"
                                        size="medium"
                                        tabIndex={0}
                                        type="button"
                                        onClick={handleViewProfileNavigation}
                                    >
                                    <span
                                        className="new-client-text">{proposals && proposals.length <= 0 ? 'EDIT PROFILE' : 'VIEW PROFILE'}</span>
                                    </Button>
                                </div>
                            </div>
                            <span className="greyed-out">{
                                `Last updated ${toDisplayDateFormat(profile.lastModifiedDate)} by ${profile.lastModifiedByName}`
                            }</span>
                            {
                                releaseToggles?.enableActivePortfolioReserve &&
                                <div>
                                    <span><ProfileAwareActivatePortfolioReserveSwitch
                                        className="display-flex align-items-center"
                                        labelPosition={LabelPosition.Left}/></span>
                                </div>
                            }
                            <ProfileDetailsProposals profileId={profile.id}
                                                     onCreateNewProposal={handleNewProposalSettingsNavigation}
                                                     onUpdateProposal={handleProposalSettingsNavigation}
                                                     onViewArchivedProposals={handleViewArchivedProposalsNavigation}
                                                     proposals={proposals}
                                                     isLoading={isLoading}/>

                            {releaseToggles?.enableProfileAssistant ?
                                <section className="">
                                    <ProfileUpdates profileId={profile.id}/>
                                </section>
                                :
                                <React.Fragment/>
                            }
                        </div>
                    </Col>
                    <Col className="meeting-actions-section">
                        <MeetingActions/>
                    </Col>
                </Row>
            </Grid>
        </>
    );
};
export default ProfileDetails;
