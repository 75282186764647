import {GoalType} from "../Goals/models/GoalType";
import {AssetRelianceAssetType} from "../ClientManagement/models/AssetRelianceResponse";

const DEFAULT_COLOR = '#EAEAEA';

export const getAssetColor = (assetType: AssetRelianceAssetType) => {
    switch (assetType) {
        case AssetRelianceAssetType.PERSONAL_ASSET:
            return '#4A8B67';
        case AssetRelianceAssetType.GENERAL_INFLOWS:
        case AssetRelianceAssetType.SOCIAL_SECURITY:
            return '#66AA93';
        case AssetRelianceAssetType.LIFE_INSURANCE:
            return '#A6D8BE';
        case AssetRelianceAssetType.RISK_CONTROL:
        case AssetRelianceAssetType.CONCENTRATED_INVESTMENTS:
        case AssetRelianceAssetType.LIQUID_RISK_ASSET:
        case AssetRelianceAssetType.SEMI_LIQUID_RISK_ASSET:
        case AssetRelianceAssetType.IL_LIQUID_RISK_ASSET:
        case AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET:
        case AssetRelianceAssetType.OTHER_ASSET:
        case AssetRelianceAssetType.EQUITY_DERIVATIVES:
            return '#3B9FA5';
        case AssetRelianceAssetType.PERSONAL_LIABILITY:
            return '#B1B1B1';
        default:
            return handleUnknownTypeWithDefault(assetType);
    }
}

export const getGoalColor = (goalType: GoalType) => {
    switch (goalType) {
        case GoalType.LIFESTYLE_SPENDING:
            return '#52897F';
        case GoalType.FAMILY:
            return '#FFC24B';
        case GoalType.DISCRETIONARY:
            return '#D99131';
        case GoalType.PHILANTHROPIC:
            return '#F1B040';
        case GoalType.TAX_LIABILITIES:
            return '#FFDF77';
        default:
            return handleUnknownTypeWithDefault(goalType);
    }
}

function handleUnknownTypeWithDefault(unknownType: never): string {
    console.debug("Unknown Type using default color", unknownType);
    return DEFAULT_COLOR;
}