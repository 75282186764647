import React from 'react';
import {LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import LifestyleSpendingPeriod from "./LifestyleSpendingPeriod";


interface LifestyleSpendingPeriodsListProps {
    lifestyleSpendingPeriodsList: LifestyleSpendingPeriodInputs[]
    replaceLifestyleSpendingPeriods: (updatedLifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[]) => Promise<void>
    handleAnnualSpendBlur: () => void
    handleLengthOfSpendBlur: () => void
    investorGroup: InvestorGroupType
    hasError: boolean
}

const LifestyleSpendingPeriodsList = ({
                                          lifestyleSpendingPeriodsList,
                                          replaceLifestyleSpendingPeriods,
                                          investorGroup,
                                          hasError,
                                          handleAnnualSpendBlur,
                                          handleLengthOfSpendBlur
                                      }: LifestyleSpendingPeriodsListProps) => {

    const handleUpdateSpendingPeriodList = async (replaceSpendingPeriod: LifestyleSpendingPeriodInputs, index: number) => {
        let tempList = [...lifestyleSpendingPeriodsList]
        tempList.splice(index, 1, replaceSpendingPeriod)
        await replaceLifestyleSpendingPeriods(tempList)
    }

    const handleDescriptionChange = async (description: string, index: number) => {
        const replaceSpendingPeriod: LifestyleSpendingPeriodInputs = {
            ...lifestyleSpendingPeriodsList[index],
            description: description,
        }
        await handleUpdateSpendingPeriodList(replaceSpendingPeriod, index)
    }

    const handleAnnualSpendChange = async (annualSpend: string, index: number) => {
        const replaceSpendingPeriod: LifestyleSpendingPeriodInputs = {
            ...lifestyleSpendingPeriodsList[index],
            annualSpend: parseInt(annualSpend) ? parseInt(annualSpend) : 0,
        }
        await handleUpdateSpendingPeriodList(replaceSpendingPeriod, index)
    }

    const handleLengthOfSpendChange = async (newInput: string, changeIndex: number) => {
        const changedLengthOfSpend = parseInt(newInput) ? parseInt(newInput) : 0;
        const initialLengthOfSpend = lifestyleSpendingPeriodsList[changeIndex].endYear - lifestyleSpendingPeriodsList[changeIndex].startYear
        const delta = changedLengthOfSpend - initialLengthOfSpend

        const updatedSpendingPeriods = lifestyleSpendingPeriodsList.map((period, index, arr) => {
            if (index > changeIndex) {
                return {
                    ...period,
                    startYear: period.startYear + delta,
                    endYear: period.endYear + delta
                }
            } else if (index === changeIndex) {
                return {
                    ...period,
                    endYear: period.startYear + changedLengthOfSpend
                }
            } else {
                return period
            }
        })

        await replaceLifestyleSpendingPeriods(updatedSpendingPeriods)
    }

    const handleDeleteSpendingPeriod = async (index: number) => {
        const listWithDeletions = [...lifestyleSpendingPeriodsList]
        listWithDeletions.splice(index, 1)

        if (listWithDeletions.length > 1) {
            const delta = lifestyleSpendingPeriodsList[index].endYear - lifestyleSpendingPeriodsList[index].startYear
            return await replaceLifestyleSpendingPeriods(updatePeriodYears(listWithDeletions, index, delta));
        }
        return await replaceLifestyleSpendingPeriods([{
            ...listWithDeletions[0],
            startYear: investorGroup.planningPeriod.startYear,
            endYear: investorGroup.planningPeriod.startYear + investorGroup.planningPeriod.numberOfYears
        }])
    }

    const disableLengthOfSpend = lifestyleSpendingPeriodsList.length == 1

    return (
        <>
            {lifestyleSpendingPeriodsList.map((item, index) => {
                return (
                    <div key={index}>
                        {index != 0 && <hr/>}
                        <div className='lifestyle-grid spending-period' data-testid="lifestyle-spending-period">
                            <LifestyleSpendingPeriod
                                lifestyleSpendingPeriod={item}
                                investorGroup={investorGroup}
                                updateDescription={(description) => {
                                    handleDescriptionChange(description, index)
                                }}
                                updateAnnualSpend={(annualSpend) => {
                                    handleAnnualSpendChange(annualSpend, index)
                                }}
                                updateLengthOfSpend={(lengthOfSpend) => {
                                    handleLengthOfSpendChange(lengthOfSpend, index)
                                }}
                                handleAnnualSpendBlur={handleAnnualSpendBlur}
                                handleDeleteSpendingPeriod={() => {
                                    handleDeleteSpendingPeriod(index)
                                }}
                                handleLengthOfSpendBlur={handleLengthOfSpendBlur}
                                //Throw-away code, don't implement off of
                                isFirstIndex={(index === 0)}
                                disableLengthOfSpend={disableLengthOfSpend}
                                hasError={hasError}
                            />
                        </div>
                    </div>
                )
            })}
        </>
    )
}

const updatePeriodYears = (periods: LifestyleSpendingPeriodInputs[], index: number, delta: number) => {
    return periods.map((period, newIndex, arr) => {
        if (newIndex < index) {
            return period
        } else {
            return {
                ...period,
                startYear: period.startYear - delta,
                endYear: period.endYear - delta
            }
        }
    })
}

export default LifestyleSpendingPeriodsList
