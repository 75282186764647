import React from "react";
import {formatCurrency} from "../../utils/format";
import {Dropdown} from "../../components";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {AccordionHeader} from "../../Assets/common/AccordionHeader";
import {NO_OP} from "../../constants/common";

type BeneficiaryGoalsAccordionHeaderProps = { memberSelected: MemberType, dropdownItems: JSX.Element[], totalPresentValue: number }
const BeneficiaryGoalsAccordionHeader = ({
                                             memberSelected,
                                             dropdownItems,
                                             totalPresentValue
                                         }: BeneficiaryGoalsAccordionHeaderProps) => (
    <div className="sticky-header">
        <div className="section-header">
            <h3>
                You plan to fund <b>{formatCurrency(totalPresentValue)}</b> of {memberSelected.firstName}'s goals.
            </h3>
            <div>
                <Dropdown
                    className="dropdown--rounded"
                    size="medium"
                    alignRight
                    buttonIcon="left"
                    buttonKind="primary"
                    drop="down"
                    dropdownKind="menu"
                    defaultText="Add New Goal"
                    iconNameOpen="add"
                    iconNameClose="add"
                    panelWidth={"auto"}
                    rounded
                    aria-label="add-new-goal"
                >
                    {dropdownItems}
                </Dropdown>
            </div>
        </div>
        <div data-testid="family-goal-underline-item">
            <AccordionHeader columnTitles={["Name", "Present Value", "Family Assets", "My Responsibility"]}
                             onClick={NO_OP}
                             className={'family-goals-accordion-header-grid'}
                             showIcon={false}
            />
        </div>
    </div>
)

export default BeneficiaryGoalsAccordionHeader;
