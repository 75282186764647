import {Dropdown, DropdownItem} from "../../../components";
import React from "react";
import {
    ownershipCategories,
    ownershipCategoriesForSingleMemberProfile,
    OwnershipCategory
} from "../../models/Ownership";
import {DropdownOnChangeData} from "../../../components/Dropdown/Dropdown";
import {MemberGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {isLivingMember} from "../../../ClientManagement/mappers";

type OwnershipCategoryDropdownProps = {
    value: OwnershipCategory,
    onChange: (data: DropdownOnChangeData<OwnershipCategory>) => void,
    partnerMember?: MemberGroupMember,
}

const OwnershipCategoryDropdown = ({value, onChange, partnerMember}: OwnershipCategoryDropdownProps) => {
    return <div className="layout-data-entry-form__field">
        <label id="ownershipCategory">
            <b>Ownership Category</b>
        </label>
        <Dropdown
            aria-labelledby="ownershipCategory"
            size="medium"
            id="ownershipDropdown"
            value={value}
            onChange={onChange}
        >
            {ownershipCategoryItems(partnerMember)}

        </Dropdown>
    </div>;
}

const ownershipCategoryItems = (partnerMember?: MemberGroupMember) => {
    let allowedCategories: Readonly<OwnershipCategory[]> = ownershipCategoriesForSingleMemberProfile;
    if (partnerMember && isLivingMember(partnerMember)) {
        allowedCategories = ownershipCategories;
    }
    return allowedCategories.map((ownershipCategoryOption) =>
        <DropdownItem itemText={ownershipCategoryOption} key={ownershipCategoryOption}
                      value={ownershipCategoryOption}/>);
};

export default OwnershipCategoryDropdown;
