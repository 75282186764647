import {EstateSummary} from "../models/api";
import {formatCurrency, formatPercentWithTwoDecimals} from "../../utils/format";
import React from "react";

type TaxType = 'Federal' | 'State';

type EstateTaxRunningTotalProps = {
    taxType: TaxType;
    estimatedEstateTax: number;
    effectiveTaxRate: number;
    estateSummary: EstateSummary;
    additionalExemptionsOrDeductions?: { label: string, amount: number }[];
    taxableEstateValue: number;
}

export function EstateTaxRunningTotal({
                                          taxType,
                                          estimatedEstateTax,
                                          effectiveTaxRate,
                                          estateSummary,
                                          additionalExemptionsOrDeductions = [],
                                          taxableEstateValue,
                                      }: EstateTaxRunningTotalProps) {
    return <div data-testid={`${taxType}-tax-running-totals`} className="running-total">
        <div className="running-total__section-header">
            <div className="header-column">{taxType}</div>
            <div className="currency">
                {formatCurrency(estimatedEstateTax)}
            </div>
        </div>
        {effectiveTaxRate > 0 && <div data-testid={`${taxType}-tax-running-totals-breakdown`} className="running-total__section">
            <div className="running-total__section-content">
                <div className="line-item-row">
                    <div>Estimated Estate</div>
                    <div>{formatCurrency(estateSummary.estateValue)}</div>
                </div>
                {estateSummary.philanthropicBeneficiaries.length > 0 && <div className="line-item-row">
                    <div>Charitable Deduction</div>
                    <div>-{formatCurrency(estateSummary.charitableDeduction)}</div>
                </div>}
                {additionalExemptionsOrDeductions.map(({label, amount}) =>
                    <div className="line-item-row" key={label}>
                        <div>{label}</div>
                        <div>-{formatCurrency(amount)}</div>
                    </div>
                )}
            </div>

            <div className="running-total__section-content">
                <div className="line-item-row subsection-header">
                    <div>Taxable Estate</div>
                    <div>{formatCurrency(taxableEstateValue)}</div>
                </div>
                <div className="line-item-row">
                    <div>* {taxType} Tax Rate</div>
                    <div>{formatPercentWithTwoDecimals(effectiveTaxRate / 100)}</div>
                </div>
            </div>

            <div className="running-total__section-content">
                <div className="line-item-row subsection-header">
                    <div>Estimated {taxType} Estate Tax</div>
                    <div>{formatCurrency(estimatedEstateTax)}</div>
                </div>
            </div>
        </div>}
    </div>;
}