import {Button} from "../../../components";
import React, {ReactNode, useState} from "react";

type QuickSlideProps = {
    title: string;
    subtext?: string;
    imageSrc?: string;
    children?: ReactNode;
    closeHandler: () => void;
};

export const QuickSlide = (props: QuickSlideProps) => {
    const {
        title,
        subtext,
        imageSrc,
        children,
        closeHandler
    } = props;

    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    let infoCard = isExpanded ? "quick-slide-info-card__expanded font-lg" : "quick-slide-info-card font-default";
    let infoCardHeader = isExpanded ? "quick-slide-info-card__lg" :"quick-slide-info-card__header";
    return (
        <div className={infoCard} data-testid={isExpanded?"infoCardExpanded":"infoCard"}>
            <div className="padding-lg quick-slide-info-card__content">
                <div className={infoCardHeader} data-testid={isExpanded?"infoCardHeaderExpanded":"infoCardHeader"}>
                    <div>
                        <span>
                            <b>{title}</b>
                            {subtext}
                        </span>
                    </div>

                    <div>
                        <Button
                            className="expand_icon"
                            icon="only"
                            iconName="fullscreen"
                            kind="borderless"
                            onClick={() => setIsExpanded(!isExpanded)}
                            size="small"
                            type="button"
                        />
                        <Button
                            className="delete_icon"
                            icon="only"
                            iconName="close"
                            kind="borderless"
                            onClick={closeHandler}
                            size="small"
                            type="button"
                        />
                    </div>
                </div>
                {imageSrc &&
                    <div className={"display-flex flex-column align-content-center"}>
                    <img
                        className={"paddingbottom-md disable-edge-visual-search"}
                        src={imageSrc}/>
                    </div>
                }

                <div>
                    {children}
                </div>
            </div>
        </div>
    );
}