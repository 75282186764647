import React from "react";
import {DatePicker} from "../DatePicker";
import moment, {Moment} from "moment";
import {Label} from "../Label";
import {DISPLAY_DATE_FORMAT, EventHandler} from "../../constants/common";

type BirthdateInputType = {
    birthdate: string | null,
    className?: string,
    onChange: EventHandler,
    required?: boolean,
    width?: string;
}

const validateBirthdate = (birthdate: Moment):Moment => {
    if (moment().diff(birthdate, 'years') > 149) {
        const afterBirthdate = moment().dayOfYear() - birthdate.dayOfYear() >= 0;
        //Returns random date between 1 to birthdate day with previous birthdate month unless it is January
        return moment({
            days: Math.floor(27 * Math.random()) + 1,
            months: birthdate.month() - 1 <= 0 ? birthdate.month() : birthdate.month() - 1,
            years: afterBirthdate ? moment().year() - 149 : moment().year() - 150
        });
    }

    return birthdate;
}

export const BirthdateInput: React.FC<BirthdateInputType> = ({birthdate, className = "", onChange, required = false, width}) => {
    return (
        <div className={`h4 parent ${className}`}>
            <Label label={"Birthdate"} required={required}/>
            <div className="profileDetails">
                <DatePicker
                    className="birthdateField"
                    id="birthdateInput"
                    aria-label="birthdateInput"
                    aria-labelledby="birthdateInput-label"
                    displayFormat={DISPLAY_DATE_FORMAT}
                    hideKeyboardShortcutsPanel
                    // isOutsideRange ensures that future birthdays are invalid
                    isOutsideRange={(date: Moment) => date.isAfter(moment())}
                    date={birthdate ? moment(birthdate): undefined}
                    onDateChange={
                        (date: Moment) => {
                            const updatedBirthdate = validateBirthdate(date).format(DISPLAY_DATE_FORMAT);
                            onChange(updatedBirthdate);
                        }
                    }
                    width={width}
                />
            </div>
        </div>
    );
}