import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {goalsApiClient} from "../GoalsApiClient";
import {
    emptyLifestyleSpendingPeriod,
    LifestyleSpendingGoal,
    LifestyleSpendingGoalResponse,
    LifestyleSpendingPeriodInputs,
} from '../models/LifestyleSpendingGoal';
import {AlertBanner, Button} from "../../components"
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";
import DiscardModal from "../../components/DiscardModal/DiscardModal";
import LifestyleFormBarChartSideBar from "../BarChartSidebar/LifestyleFormBarChartSideBar";
import {selectGoalModel, updateLifestyleSpendingGoal} from "../controller/GoalsModelSlice";
import {GoalModelType} from "../models/GoalModelType";
import LifestyleSpendingLivePreview from "./LifestyleSpendingLivePreview";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import LifestyleSpendingReserveInformation from "./LifestyleSpendingReserveInformation";
import {LifestyleGoalFunding} from "./LifestyleGoalFunding";
import {selectReleaseToggles} from 'src/ReleaseToggles/releaseTogglesSlice';
import LifestylePlanningPeriod from "../../components/PlanningPeriod/LifestylePlanningPeriod";
import {isInvestablySufficient} from "../../Portfolio/portfolioUtils";
import LifestyleSpendingPeriodsList from "./LifestyleSpendingPeriodsList";
import {RouteWithId} from "../../routes/types";
import {emptyPortfolioReserveResponse, PortfolioReserveResponse} from "../models/PortfolioReserve";
import LoadingIndicator from "../../pages/LoadingIndicator";

const LifestyleSpendingForm = ({isEditing = false}: { isEditing?: boolean }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id: proposalId} = useParams<RouteWithId>();
    const goalModel = useAppSelector<GoalModelType>(selectGoalModel);
    const {
        proposal,
        lifestyleSpendingGoal: savedLifestyleGoal,
        investorGroup,
        discountRateSelection,
        assets
    } = goalModel;
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {enableLifestyleGoalFunding, enableMultipleSpendingPeriods} = releaseToggles!;

    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

    const [originalGoalState] = useState(savedLifestyleGoal);

    const [lifestyleSpendingGoal, setLifestyleSpendingGoal] = useState<LifestyleSpendingGoal>(savedLifestyleGoal)
    const isSufficient = isInvestablySufficient({
        ...goalModel,
        totalInvestableValue: assets.totalInvestableValue,
        lifestylePresentValue: lifestyleSpendingGoal.calculatedFields
    });
    const reserveTargetIsSet = (proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [portfolioReserveResponse, setPortfolioReserveResponse] = useState<PortfolioReserveResponse>(emptyPortfolioReserveResponse)
    let ifNoInvAssetsButInflowsAreAlignedToLS = !assets.totalInvestableValue && lifestyleSpendingGoal.calculatedFields.totalAlignedInflowPresentValue > 0;
    useEffect(() => {
        //load PR into page
        fetchPortfolioReserve().then(responsePR => {
            setPortfolioReserveResponse(responsePR)
            setIsLoading(false);
        })
    }, [])

    const fetchPortfolioReserve = async (): Promise<PortfolioReserveResponse> => {
        let portfolioReserve: PortfolioReserveResponse = emptyPortfolioReserveResponse;
        if ((assets.totalInvestableValue > 0 || ifNoInvAssetsButInflowsAreAlignedToLS) && reserveTargetIsSet) {
            portfolioReserve = await goalsApiClient.getPortfolioReserve(proposalId,
                {
                    selectedReserveTarget: String(proposal.portfolioReserveTargetLength)
                }
            );
        }
        return portfolioReserve;
    }


    const handleDiscardChanges = () => {
        dispatch(updateLifestyleSpendingGoal(originalGoalState));
    }

    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        dispatch(updateLifestyleSpendingGoal(originalGoalState));
        history.push(`/Profile/${proposalId}/ClientProfile/Goals/Summary`, {forceNavigate: true});
    };

    const updatePresentValue = async (lifestyleGoal: LifestyleSpendingGoal): Promise<LifestyleSpendingGoal> => {
        const lifestylePresentValueResponse = await goalsApiClient.postLifestylePresentValueWithMultipleLSPeriods(proposalId, lifestyleGoal.userInputs)
        return {
            ...lifestyleGoal,
            calculatedFields: lifestylePresentValueResponse
        }
    }

    const saveLifestyleGoal = (lifestyleGoal: LifestyleSpendingGoal): Promise<LifestyleSpendingGoalResponse> => {
        return goalsApiClient.postLifeStyleGoal(
            proposalId,
            {
                id: lifestyleGoal.id,
                userInputs: {...lifestyleGoal.userInputs},
                lengthOfPlanningPeriod: investorGroup.planningPeriod.numberOfYears
            },
        )
    }

    const handleSaveChanges = async () => {
        const savedGoalWithId = await saveLifestyleGoal(lifestyleSpendingGoal)
        dispatch(updateLifestyleSpendingGoal(savedGoalWithId))
    };

    const handleSaveButtonClick = async () => {
        await handleSaveChanges()
        history.push(`/Profile/${proposalId}/ClientProfile/Goals/Summary`, {forceNavigate: true})
    };

    const handleCancelButtonClick = () => {
        if (lifestyleSpendingGoal === savedLifestyleGoal) {
            history.push(`/Profile/${proposalId}/ClientProfile/Goals/Summary`, {forceNavigate: true});
        } else {
            setShowCancelModal(true);
        }
    }

    const toggleFundedByNonInvestables = async (newValue: boolean) => {
        const newGoal: LifestyleSpendingGoal = {
            ...lifestyleSpendingGoal,
            userInputs: {
                ...lifestyleSpendingGoal.userInputs,
                isFundedByNonInvestableAssets: newValue,
            }
        }
        const response: LifestyleSpendingGoal = (await updatePresentValue(newGoal))
        setLifestyleSpendingGoal(response)
    }

    const handleAnnualSpendBlur = async () => {
        if (lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods[0].annualSpend >= 0 && !hasPlanningPeriodError) {
            setLifestyleSpendingGoal(await updatePresentValue(lifestyleSpendingGoal))
        }
    }

    const handleLengthOfSpendBlur = async () => {
        if (!hasPlanningPeriodError) {
            setLifestyleSpendingGoal(await updatePresentValue(lifestyleSpendingGoal))
        }
    }

    const totalLifestyleSpendingYears = lifestyleSpendingGoal
        .userInputs.lifestyleSpendingPeriods
        .map((period) => period.endYear - period.startYear)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const hasPlanningPeriodError = totalLifestyleSpendingYears !== investorGroup.planningPeriod.numberOfYears;

    const replaceLifestyleSpendingPeriods = async (newSpendingPeriods: LifestyleSpendingPeriodInputs[]) => {
        const updatedGoal = {
            ...lifestyleSpendingGoal,
            userInputs: {
                ...lifestyleSpendingGoal.userInputs,
                lifestyleSpendingPeriods: newSpendingPeriods,
            }
        }
        setLifestyleSpendingGoal(updatedGoal)
    }

    const showGoalFundingSection = !isSufficient && enableLifestyleGoalFunding;

    const addNewSpendingPeriod = async () => {
        const numberOfSpendingPeriods = lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.length
        const planningPeriodEndYear = investorGroup.planningPeriod.numberOfYears + investorGroup.planningPeriod.startYear
        const newSpendingPeriod = {
            ...emptyLifestyleSpendingPeriod(planningPeriodEndYear, planningPeriodEndYear),
            description: "Lifestyle Spending Period " + (numberOfSpendingPeriods + 1),
            startYear: lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods[numberOfSpendingPeriods - 1].endYear,
            endYear: lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods[numberOfSpendingPeriods - 1].endYear
        }
        await replaceLifestyleSpendingPeriods([...lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods, newSpendingPeriod])
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }


    return (
        <div className="layout-split-sidebar">
            <DiscardModal
                itemType={"Goal"}
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleModalDiscardButton}
                isEditing={isEditing}/>
            <HistoryBlockModal
                when={
                    JSON.stringify(originalGoalState) !== JSON.stringify(lifestyleSpendingGoal)
                }
                itemType="goal"
                onSave={handleSaveChanges}
                onDiscard={handleDiscardChanges}
                saveEnabled={!hasPlanningPeriodError}
            />
            <LifestyleFormBarChartSideBar lifestyleSpendingGoal={lifestyleSpendingGoal}/>
            <div className='lifestyle-spending layout-data-entry-form'>
                <DataEntryHeader
                    className='data-entry-header'
                    title={isEditing ? 'Edit Lifestyle Spending' : 'Add Lifestyle Spending'}
                    SubtitleComponent={() => <LifestylePlanningPeriod/>}
                    onPrimaryButtonClick={handleSaveButtonClick}
                    primaryButtonText='Save'
                    onSecondaryButtonClick={handleCancelButtonClick}
                    secondaryButtonText='Cancel'
                    disablePrimaryButton={hasPlanningPeriodError}
                />

                <div className="marginbottom-md present-value-banner-container" data-testid="unsavedPRBanner">
                    {!reserveTargetIsSet &&
                        <AlertBanner
                            fullWidth={false}
                            icon="security"
                            showAlert={true}
                            showCloseBtn={false}
                            type="info"
                        >

                        <span className="banner-text">
                            Present value is based on default Portfolio Reserve until Portfolio Reserve is set
                        </span>
                        </AlertBanner>
                    }
                </div>
                <div className='lifestyle-spending__form'>
                    <article>
                        <div className='lifestyle-grid'>

                            <span className='LifestyleSpendingPeriodHeaders'>Description</span>
                            <span className='LifestyleSpendingPeriodHeaders'>Annual Spending</span>
                            <span className='LifestyleSpendingPeriodHeaders'>Length of Spend</span>
                        </div>
                        <LifestyleSpendingPeriodsList
                            lifestyleSpendingPeriodsList={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                            replaceLifestyleSpendingPeriods={replaceLifestyleSpendingPeriods}
                            handleAnnualSpendBlur={handleAnnualSpendBlur}
                            handleLengthOfSpendBlur={handleLengthOfSpendBlur}
                            investorGroup={investorGroup}
                            hasError={hasPlanningPeriodError}
                        />

                        {enableMultipleSpendingPeriods &&
                            <Button
                                className="margintop-xxl"
                                kind="primary"
                                size={"medium"}
                                type="button"
                                onClick={addNewSpendingPeriod}
                            >
                                + ADD NEW SPENDING PERIOD
                            </Button>
                        }
                        <hr/>
                        {hasPlanningPeriodError &&
                            <div data-testid="spending-period-warning">
                                <div className="lifestyle-spending-total-container color-text--error">
                                    <b>Total Lifestyle Spending</b>
                                    <div className="lifestyle-spending-sum-container">
                                        <span
                                            data-testid="spending-total-sum">
                                            <b>{totalLifestyleSpendingYears} years</b>
                                        </span>
                                    </div>
                                </div>
                                <span className="color-text--error">
                                    The total lifestyle spend must equal {investorGroup.planningPeriod.numberOfYears} years.
                                </span>
                            </div>
                        }
                        {!hasPlanningPeriodError &&
                            <div className="lifestyle-spending-total-container">
                                <b>Total Lifestyle Spending</b>
                                <div className="lifestyle-spending-sum-container">
                                <span
                                    data-testid="spending-total-sum">
                                    <b>{totalLifestyleSpendingYears} years</b>
                                </span>
                                </div>
                            </div>
                        }
                        {showGoalFundingSection &&
                            <LifestyleGoalFunding
                                isOn={lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets}
                                onToggle={() => toggleFundedByNonInvestables(!lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets)}
                                investorGroup={investorGroup}
                                sufficientYears={lifestyleSpendingGoal.calculatedFields.sufficientYears}
                            />
                        }
                    </article>
                    <div style={{flexDirection: "column"}}>
                        <LifestyleSpendingLivePreview
                            lifestyleSpendingGoal={lifestyleSpendingGoal}
                            investorGroup={investorGroup}
                            discountRateSelection={discountRateSelection}
                            profile={proposal}
                            isSufficient={isSufficient}
                            hasError={hasPlanningPeriodError}
                        />
                        {proposal.portfolioReserveTargetLength != null &&
                            proposal.portfolioReserveTargetLength != undefined &&
                            <LifestyleSpendingReserveInformation
                                lifestyleSpendingGoal={lifestyleSpendingGoal}
                                profile={proposal}
                                portfolioReserve={portfolioReserveResponse}
                                hasError={hasPlanningPeriodError}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LifestyleSpendingForm;