import React from "react";

export const WelcomeImage: React.FC = () => {
    return (
        <img className="center-content content-box-shadow"
             alt="client view wait for meeting to begin"
             src="/wait-for-meeting.png"/>
    )
}

export const ThankYouImage: React.FC = () => {
    return (
        <img className="center-content content-box-shadow"
             alt="client view end of meeting"
             src="/thankyou-meeting.png"/>
    )
}

export const MeetingBackgroundImage: React.FC = () => {
    return (
        <img className="center-content content-box-shadow height-100p width-100p"
             alt="client meeting background"
             src="/meeting-background.png"/>
    )
}