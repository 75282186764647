import React, { ReactNode } from 'react'
import { Col } from 'xps-react'
import { Button } from 'src/components'

type SelectedDocumentsSectionProps = {
    title: string,
    onAttach: () => void,
    children: ReactNode
}
const SelectedDocumentsSection = ({
    title,
    onAttach,
    children
}: SelectedDocumentsSectionProps) => {
  return (
    <Col xs={6} className="display-flex flex-column">
        <div className="page-group marginbottom-xxxl">
            <div className="page-section-title">
                <div className="page-item">
                    {title}
                    <Button
                        onClick={onAttach}
                        kind="borderless"
                        icon="left"
                        size="small"
                        iconName={"attach_file"}
                    >
                        Attach
                    </Button>
                </div>
            </div>
            <hr className="section"/>
            {children}
        </div>
    </Col>
    
  )
}

export default SelectedDocumentsSection;