import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {SocialSecurity} from "../../models/SocialSecurity";
import {DeleteModalProps} from "../../common/AssetsView";
import React from "react";
import {shortenString} from "../../common/textUtils";
import {FutureInflowsPopover} from "./FutureInflowsPopover";
import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {DropResult} from "react-beautiful-dnd";
import {sortByOrdinalAndPresentValue} from "../common/utils";

type SocialSecuritiesTableProps = {
    investorGroup: InvestorGroupType,
    socialSecurities: SocialSecurity[],
    onEdit: (assetId: string) => void,
    onDelete: (deleteModalProps: DeleteModalProps) => void,
    hideActionMenu?: boolean,
    isDraggable?: boolean,
    captureSocialSecuritiesOrder: ({source, destination}: DropResult) => void
};

export function SocialSecuritiesTable({
                                          investorGroup,
                                          socialSecurities,
                                          onEdit,
                                          onDelete,
                                          hideActionMenu = false,
                                          isDraggable = false,
                                          captureSocialSecuritiesOrder
                                      }: SocialSecuritiesTableProps) {

    const sortedSocialSecurities = sortByOrdinalAndPresentValue(socialSecurities) as SocialSecurity[]

    function handleDelete(socialSecurity: SocialSecurity) {
        onDelete({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: getCurrentOwnerByOwnerMemberId(socialSecurity.memberOwnership.member.id).name,
            assetType: "social security",
            assetId: socialSecurity.id!,
        });
    }

    function getCurrentOwnerByOwnerMemberId(ownerMemberId: string) {
        let selectedOwner = investorGroup.primaryMember;
        if (investorGroup.partnerMember?.id === ownerMemberId) {
            selectedOwner = investorGroup.partnerMember;
        }
        return selectedOwner;
    }

    if (isDraggable) {
        return <>
            <div role="row" className="grid-display-contents asset-row-subheader">
                <span role="cell" className="textalign-left grid-span-3 cell-text-content"><b>Social Security</b></span>
            </div>
            <DragDrop
                className="draggable"
                direction="vertical"
                draggableId="socialSecurityDraggableId1"
                droppableId="socialSecurityDroppableId1"
                onDragEnd={captureSocialSecuritiesOrder}
                items={
                    sortedSocialSecurities?.map((socialSecurity) =>
                        <div className="display-flex align-items-right" tabIndex={-1} key={socialSecurity.id}>
                            <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                            {buildSocialSecuritiesTableRow(socialSecurity, investorGroup, onEdit, handleDelete, hideActionMenu)}
                        </div>)
                }
            >
            </DragDrop>
        </>
    }
    return <>
        <div role="row" className="grid-display-contents asset-row-subheader">
            <span role="cell" className="textalign-left grid-span-3 cell-text-content"
                  data-testid="social-Security-header-text"><b>Social Security</b></span>
        </div>
        {sortedSocialSecurities?.map((socialSecurity) => buildSocialSecuritiesTableRow(socialSecurity, investorGroup, onEdit, handleDelete, hideActionMenu))}
    </>;
}

function buildSocialSecuritiesTableRow(socialSecurity: SocialSecurity, investorGroup: InvestorGroupType,
                                       handleClickOnEditSocialSecurity: (id: string) => void,
                                       handleClickOnDeleteSocialSecurity: (socialSecurity: SocialSecurity) => void,
                                       hideActionMenu: boolean) {
    let ownerName = investorGroup.primaryMember.name;
    if (investorGroup.partnerMember?.id === socialSecurity.memberOwnership.member.id) {
        ownerName = investorGroup.partnerMember.name;
    }
    return <div key={`social-security-row-${socialSecurity.id}`} role="row"
                className="assets-grid-with-actionmenu assets-grid-table-row row-within-subheading" tabIndex={0}
                aria-label={`social security - ${ownerName}`}>
        <div role="cell" className='textalign-left display-flex'>
            <div className="cell-with-popover">
                <div className='cell-text-content'>
                    {shortenString(ownerName, 23)}
                </div>
                <FutureInflowsPopover futureInflow={socialSecurity}/>
            </div>
        </div>
        <span role="cell"
              className='textalign-right cell-text-content'>{formatCurrency(socialSecurity.netAnnualFlow)}
            <div className="sublabel-12px cell-text-content">{formatCurrency(socialSecurity.grossAnnualFlow)}</div>
        </span>
        <span role="cell" className='textalign-right cell-text-content'>{socialSecurity.yearsUntilFlow}</span>
        <span role="cell" className='textalign-right cell-text-content'>{socialSecurity.yearsOfFlow}</span>
        <span role="cell"
              className='textalign-right cell-text-content'>{formatCurrency(socialSecurity.presentValue)}</span>

        {!hideActionMenu &&
            <TableActionDropdownMenu ariaLabel={`${ownerName} Menu`}>
                <DropdownItem
                    itemText="Edit Asset"
                    value="Edit Asset"
                    onClick={() => handleClickOnEditSocialSecurity(socialSecurity.id!)}
                />
                <DropdownItem
                    itemText="Delete Asset"
                    value="Delete Asset"
                    onClick={() => handleClickOnDeleteSocialSecurity(socialSecurity)}
                >
                    <Icon name="delete" className="asset-table-delete-icon"/>
                </DropdownItem>
            </TableActionDropdownMenu>}
    </div>;
}