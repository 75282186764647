import {MemberOwnership, MemberOwnershipFormData, MemberOwnershipWriteModel} from "./Ownership";

export const trustInflowTypes = [
    "NT - Annuity",
    "NT - Unitrust",
    "NT - Base Budget",
    "NT - Mandatory Income",
    "NT - 5 & 5",
    "EXT - Annuity",
    "EXT - Unitrust",
    "EXT - Base Budget",
    "EXT - Mandatory Income",
    "EXT - 5 & 5"
] as const;

export type TrustInflowType = typeof trustInflowTypes[number];

export interface GeneralInflow {
    id?: string,
    profileId: string,
    description: string,
    type: TypeOptions,
    trustInflowType?: TrustInflowType,
    memberOwnerships: MemberOwnership[],
    grossAnnualFlow: number,
    taxRate: number,
    netAnnualFlow: number,
    yearsUntilFlow: number,
    yearsOfFlow: number,
    inflowReserveLength: number | null,
    startDate: string | null,
    endDate: string | null,
    isInflowWillFundLifestyleGoal: boolean,
    isHighRisk: boolean,
    willAdjustWithInflation: boolean,
    presentValue: number,
    isInflowExpired?: boolean,
    totalPresentValue: number,
    interestRate: number,
    lifestyleGoalAligned: number,
    excessFutureInflow: number,
    inflowReservePresentValue: number,
    ordinal: number,
    isDisclosureReviewed: boolean
}

/* UI types */
export type GeneralInflowDetails = Omit<GeneralInflow, 'profileId' | 'taxRate' | 'memberOwnerships'> & {
    taxRate: string,
    inflowYearsFrom: number,
    inflowYearsTo: number,
    ownersAgeRangeFrom: number,
    ownersAgeRangeTo: number,
    memberOwnerships: MemberOwnershipFormData[]
};

export interface InflowTimeFrame {
    startDate: string | null,
    endDate: string | null,
    yearsUntilFlow: number,
    yearsOfFlow: number,
    inflowYearsFrom: number,
    inflowYearsTo: number,
    ownersAgeRangeFrom: number,
    ownersAgeRangeTo: number,
}

export type TypeOptions = "Salary" | "Pension" | "Annuities" | "Trust Inflow" | "Other Inflows";

/* Network types */
export type CreateGeneralInflowRequest =
    Omit<GeneralInflow, 'id' | 'presentValue' | 'memberOwnerships' | 'totalPresentValue' | 'interestRate'
        | 'lifestyleGoalAligned' | 'excessFutureInflow' | 'inflowReservePresentValue' | 'ordinal' > & {
    memberOwnerships: MemberOwnershipWriteModel[],
};

export type UpdateGeneralInflowRequest =
    Omit<GeneralInflow, 'presentValue' | 'memberOwnerships' | 'totalPresentValue' | 'interestRate'
        | 'lifestyleGoalAligned' | 'excessFutureInflow' | 'inflowReservePresentValue' | 'ordinal'> & {
    memberOwnerships: MemberOwnershipWriteModel[]
};

export interface GeneralInflowPresentValueRequest {
    netAnnualFlow: number,
    yearsUntilFlow: number,
    yearsOfFlow: number,
    inflowReserveLength: number | null,
    isHighRisk: boolean,
    willAdjustWithInflation: boolean,
    isInflowWillFundLifestyleGoal: boolean,
    inflowId?: string,
    id?: string,
    startDate: string | null
    memberId: string,
}

export interface GeneralInflowPresentValueResponse {
    totalPresentValue: number,
    interestRate: number,
    lifestyleGoalAligned: number,
    excessFutureInflow: number,
    inflowReservePresentValue: number,
}