import React from "react";
import AssetHoldingHeader from "./AssetHoldingHeader";
import {Accordion, AccordionItem} from "../../components";
import CurrentVsProposedHorizontalDivider from "./CurrentVsProposedHorizontalDivider";
import {AssetSubclassesSummary} from "./AssetSubclassesSummary";
import {Col, Row} from "react-grid-system";
import {camelize} from "../../utils/stringUtils";
import classNames from "classnames";
import {
    AllocationDifference,
    findSubclassSummary,
    getDifference,
    getTotalInvestibleAmount,
    getTotalInvestiblePercent
} from "./assetAllocationUtils";

type AssetHoldingProps = {
    id: string
    assetClassName: string
    total: number
    totalProposed?: number
    totalPercentage: number
    totalProposedPercentage?: number
    accentColor: string
    assetSubclasses: string[]
    currentAssetSubclassSummaries: AssetSubclassesSummary[]
    proposedAssetSubclassSummaries: AssetSubclassesSummary[]
}

const FIRST_COLUMN_WIDTH = 315;
const NOT_FIRST_COLUMN_WIDTH = 300;

const AssetHoldings: React.FC<AssetHoldingProps> = ({
                                                        id,
                                                        assetClassName,
                                                        total,
                                                        totalProposed,
                                                        totalPercentage,
                                                        totalProposedPercentage,
                                                        assetSubclasses,
                                                        currentAssetSubclassSummaries,
                                                        proposedAssetSubclassSummaries,
                                                        accentColor
                                                    }) => {
    const filteredAssetSubclassSummaries = getFilteredAssetSubclassSummaries(
        assetSubclasses,
        currentAssetSubclassSummaries,
        proposedAssetSubclassSummaries
    );
    const hasSubclassSummaryRows = filteredAssetSubclassSummaries.length > 0;

    return (
        <>
            <CurrentVsProposedTableHeader allocationType={assetClassName.toUpperCase()}/>
            <Row className={"row"}>
                <Accordion accordionId={id}
                           className="asset-stack-table-accordion">
                    <AccordionItem
                        uuid={assetClassName}
                        primaryText={assetClassName}
                        accentColor={accentColor}
                        HeaderComponent={({expanded}) => <AssetHoldingHeader
                            expanded={expanded}
                            title={assetClassName}
                            total={total}
                            totalProposed={totalProposed}
                            totalPercentage={totalPercentage}
                            totalProposedPercentage={totalProposedPercentage}
                            showChevron={hasSubclassSummaryRows}
                        />}>
                        <AssetSubclassSummaries assetSubclassSummaries={filteredAssetSubclassSummaries}/>
                    </AccordionItem>
                </Accordion>
            </Row>
        </>

    )
};

type CurrentVsProposedTableHeaderProps = {
    allocationType: string;
}

export const CurrentVsProposedTableHeader: React.FC<CurrentVsProposedTableHeaderProps> = ({
                                                                                       allocationType
                                                                                   }) => {
    return (
        <Row className={"row-component"}>
            <Col width={FIRST_COLUMN_WIDTH}
                 style={{minWidth: FIRST_COLUMN_WIDTH}}
                 className="allocation-type">
                <span className='paddingleft-xxl'>{allocationType}</span>
            </Col>
            <Col width={NOT_FIRST_COLUMN_WIDTH}
                 style={{minWidth: NOT_FIRST_COLUMN_WIDTH}}
                 className="sub-header-container">
                <CurrentVsProposedHorizontalDivider/>
                <div className="amounts flex">
                    <span className="total-dollar-amount flex-grow-1">Total $</span>
                    <span className="total-percent-amount paddingright-lg" style={{width: '135px'}}>Total %</span>
                </div>
            </Col>
            <Col width={NOT_FIRST_COLUMN_WIDTH}
                 style={{minWidth: NOT_FIRST_COLUMN_WIDTH}}
                 className="sub-header-container proposed">
                <CurrentVsProposedHorizontalDivider/>
                <div className="amounts flex">
                    <span className="total-dollar-amount flex-grow-1">Total $</span>
                    <span className="total-percent-amount paddingright-lg" style={{width: '135px'}}>Total %</span>
                </div>
            </Col>
            <Col width={NOT_FIRST_COLUMN_WIDTH}
                 style={{minWidth: NOT_FIRST_COLUMN_WIDTH}}
                 className="sub-header-container">
                <CurrentVsProposedHorizontalDivider/>
                <div className="amounts">
                    <span className="total-dollar-amount flex-grow-1">Total $</span>
                    <span className="total-percent-amount paddingright-lg" style={{width: '135px'}}>Total %</span>
                </div>
            </Col>
        </Row>
    );
}

export type AssetSubclassSummary = {
    subclass: string
    current: AssetSubclassesSummary | null
    proposed: AssetSubclassesSummary | null
}

export const getFilteredAssetSubclassSummaries = (
    assetSubclasses: string[],
    currentAssetSubclassSummaries: AssetSubclassesSummary[],
    proposedAssetSubclassSummaries: AssetSubclassesSummary[]
): AssetSubclassSummary[] => {
    return assetSubclasses
        .map(subclass => ({
            subclass: subclass,
            current: findSubclassSummary(subclass, currentAssetSubclassSummaries),
            proposed: findSubclassSummary(subclass, proposedAssetSubclassSummaries),
        }))
        .filter(subclassSummary => {
            return !!subclassSummary.current?.totalInvestableAmount
                || !!subclassSummary.proposed?.totalInvestableAmount;
        });
}

type AssetSubclassSummariesProps = {
    assetSubclassSummaries: AssetSubclassSummary[]
}

export const AssetSubclassSummaries: React.FC<AssetSubclassSummariesProps> = ({assetSubclassSummaries}) => {
    return (
        <div data-testid={'asset-subclass-summaries'} className='asset-subclass-summary-container'>
            {assetSubclassSummaries.map(({subclass, current, proposed}) =>
                <AssetSubclassSummaryRow key={subclass}
                                         name={subclass}
                                         totalInvestableAmount={getTotalInvestibleAmount(current)}
                                         totalInvestablePercent={getTotalInvestiblePercent(current)}
                                         totalInvestableAmountProposed={getTotalInvestibleAmount(proposed)}
                                         totalInvestablePercentProposed={getTotalInvestiblePercent(proposed)}
                                         allocationDifference={getDifference(current, proposed)}
                />
            )}
        </div>
    )
};

type AssetSubclassSummaryRowProps = {
    name: string,
    totalInvestableAmount: number | string,
    totalInvestablePercent: number | string,
    totalInvestableAmountProposed: number | string,
    totalInvestablePercentProposed: number | string,
    allocationDifference: AllocationDifference
}

const AssetSubclassSummaryRow: React.FC<AssetSubclassSummaryRowProps> = ({
                                                                             name,
                                                                             totalInvestableAmount,
                                                                             totalInvestablePercent,
                                                                             totalInvestableAmountProposed,
                                                                             totalInvestablePercentProposed,
                                                                             allocationDifference
                                                                         }) => {
    return (
        <Row className={"asset-subclass-summary-grid"} aria-label={`${name} subclass`}>
            <Col className="subclass-first-column-container" width={318} style={{minWidth: '318px'}}>
                 <span key={`asset-stack-table-header-row-${camelize(name)}-label`}
                       role="cell"
                       className="subclass-first-column">{name}
                 </span>
            </Col>
            <Col className={"subclass-total-container flex"} width={300} style={{minWidth: '300px'}}>
                 <span
                     className={"subclass-total-amount flex-grow-1"}>
                     {totalInvestableAmount}
                 </span>
                <span className={"subclass-total-amount paddingright-lg"} style={{width: '135px'}}>
                 {`${totalInvestablePercent}`}
                </span>
            </Col>
            <Col className={"subclass-total-container flex"} width={300} style={{minWidth: '300px'}}>
                 <span
                     className={"subclass-total-amount flex-grow-1"}>
                     {totalInvestableAmountProposed}
                 </span>
                <span className={"subclass-total-amount paddingright-lg"} style={{width: '135px'}}>
                     {`${totalInvestablePercentProposed}`}
                 </span>
            </Col>
            <Col className={"subclass-total-container flex textalign-right"} width={300} style={{minWidth: '300px'}}>
                  <span
                      className={classNames("subclass-total-amount flex-grow-1", {"negative-difference": allocationDifference.isNegative})}>
                         {allocationDifference.differenceAmount}
                     </span>
                <span className='paddingright-lg' style={{width: '135px'}}>
                    <span
                        className={classNames("subclass-total-amount", {"negative-difference": allocationDifference.isNegative})}>
                         {allocationDifference.differencePercentage}
                     </span>
                </span>
            </Col>
        </Row>
    )
        ;
};

export default AssetHoldings;