import React, {ChangeEvent, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectDisplayName} from "../ClientProfile/approvedProfileSlice";
import {PresentationPaneHeader} from "../../components";
import ClickableYearsInput, {IconState} from "../../components/YearsInput/ClickableYearsInput";
import {emptyInvestorGroup, InvestorGroupType} from "../models/InvestorGroupType";
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {AssetsSummary, emptyFutureValueOfExcessAssets, FutureValueOfExcessAssetsDTO} from "../../Assets/models/Assets";
import {formatCurrency} from "../../utils/format";
import {calculateCNWInEstateTotalValue} from "../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {createGraphOptions} from "./NetWorthOverTimeGraphUtils";

const NetWorthOverTime: React.FC = () => {
    const dispatch = useAppDispatch();
    const displayName = useAppSelector(selectDisplayName) ?? "";
    const [futureValueOfExcessAssetsDTO, setFutureValueOfExcessAssetsDTO] = useState<FutureValueOfExcessAssetsDTO>(emptyFutureValueOfExcessAssets);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {id} = useParams<RouteWithId>();
    const [projectedNetWorthAge, setProjectedNetWorthAge] = useState<number>();
    const [confirmedProjectedNetWorthAge, setConfirmedProjectedNetWorthAge] = useState<number>();
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    

    let redrawPlotLineTooltip: boolean = true;

    useEffect(() => {
        Promise.all([
            assetsApiClient.getFutureValueOfExcessAssets(id),
            assetsApiClient.getAssetsSummary(id),
            clientManagementApiClient.getInvestorGroup(id)
        ]).then(([
                     futureValueOfExcessAssetsResponse,
                     assetsSummaryResponse,
                     investorGroupResponse
                 ]) => {
            dispatch(setClientAssets(assetsSummaryResponse));
            setFutureValueOfExcessAssetsDTO(futureValueOfExcessAssetsResponse);
            setInvestorGroup(investorGroupResponse);
            setProjectedNetWorthAge(investorGroupResponse.planningPeriod.numberOfYears + investorGroupResponse.planningPeriod.ageFrom);
            setConfirmedProjectedNetWorthAge(investorGroupResponse.planningPeriod.numberOfYears + investorGroupResponse.planningPeriod.ageFrom);
            setIsLoading(false);
        }).catch(error => console.error('Could not fetch asset details', error.message));
    }, [])

    const personalPlanningHorizon = investorGroup.planningPeriod.numberOfYears + investorGroup.planningPeriod.ageFrom;

    const handleDecreaseYear = () => {
        if (projectedNetWorthAge! > investorGroup.planningPeriod.ageFrom) {
            setConfirmedProjectedNetWorthAge(projectedNetWorthAge! - 1);
            setProjectedNetWorthAge(projectedNetWorthAge! - 1);
            redrawPlotLineTooltip = true;
        }
    }

    const handleIncreaseYear = () => {
        if (projectedNetWorthAge! < personalPlanningHorizon) {
            setConfirmedProjectedNetWorthAge(projectedNetWorthAge! + 1);
            setProjectedNetWorthAge(projectedNetWorthAge! + 1);
            redrawPlotLineTooltip = true;
        }
    }

    const handleTargetProjectedNetWorthAgeBlur = (updatedAge: number) => {
        if (isNaN(updatedAge) || updatedAge > personalPlanningHorizon) {
            setConfirmedProjectedNetWorthAge(personalPlanningHorizon);
            setProjectedNetWorthAge(personalPlanningHorizon);
        } else if (updatedAge < investorGroup.planningPeriod.ageFrom) {
            setConfirmedProjectedNetWorthAge(investorGroup.planningPeriod.ageFrom);
            setProjectedNetWorthAge(investorGroup.planningPeriod.ageFrom);
        } else {
            setConfirmedProjectedNetWorthAge(updatedAge);
            setProjectedNetWorthAge(updatedAge);
        }
        redrawPlotLineTooltip = true;
    }

    const handleTargetProjectedNetWorthAge = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
        setProjectedNetWorthAge(value)
    }

    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const netWealthAtCurrentAge = calculateCNWInEstateTotalValue(clientAssets!) + totalTaxLiabilitiesForAllAccounts;

    const showProjectedNetWorthAtAge = () => {
        const selectedAgeIsCurrentAge: boolean = (
            confirmedProjectedNetWorthAge === undefined
            || confirmedProjectedNetWorthAge === investorGroup.planningPeriod.ageFrom
        );

        if (
            clientAssets && selectedAgeIsCurrentAge
        ) {
            return formatCurrency(netWealthAtCurrentAge);
        }

        return formatCurrency(futureValueOfExcessAssetsDTO.futureValueOfExcessAssetsByAge[confirmedProjectedNetWorthAge! - investorGroup.planningPeriod.ageFrom - 1]);
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const graphOptions: Highcharts.Options = createGraphOptions({
        investorGroup,
        projectedNetWorthAge: confirmedProjectedNetWorthAge,
        redrawPlotLineTooltip,
        netWealthAtCurrentAge,
        futureValueOfExcessAssetsDTO,
    });

    return (
        <div className="net-worth-over-time">
            <div id="net-worth-over-time-header">
                <PresentationPaneHeader displayName={displayName} title={"Net Worth Over Time"}/>
                <span className="net-worth-over-time__projected-net-worth font-xl">
               Your projected net worth at &nbsp;
                    <ClickableYearsInput
                        name={"projectedNetWorthAge"}
                        onUpArrowClick={handleIncreaseYear}
                        onDownArrowClick={handleDecreaseYear}
                        onChangeValue={handleTargetProjectedNetWorthAge}
                        onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTargetProjectedNetWorthAgeBlur(Number(e.target.value))}
                        value={projectedNetWorthAge}
                        inputStyle={{
                            textAlign: "right",
                            paddingRight: "15%",
                        }}
                        inputClassName={"net-worth-over-time__age-input editable-age-border"}
                        upArrowClassName={"dds-icons input__icon input__icon__increase-support-button"}
                        downArrowClassName={"dds-icons input__icon input__icon__decrease-support-button"}
                        disabledAndReadOnly={false}
                        iconState={IconState.ARROWS}
                        inlineLabel={"age"}
                        inlineLabelAlign={"left"}
                    />&nbsp; is &nbsp;<span className="font-xxl">{showProjectedNetWorthAtAge()}</span>
                </span>
            </div>
            <div className="net-worth-over-time-body">
                <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
            </div>
        </div>
    );
}


export default NetWorthOverTime;