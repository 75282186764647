import React, {useEffect, useState} from "react";
import {Tab, TabBar, TabPanel, TabsProvider} from "../../components";
import {PartnerDashboardProfile} from "../models/ProfileResponse";
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {setHeaderTickerTabs} from "../ClientProfile/HeaderTickerTabsSlice";
import {EMPTY_HEADER_TICKER_TABS_RESPONSE} from "../ClientProfile/HeaderTickerTabType";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import PartnerDashboard from "./PartnerDashboard";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithProfileType} from "../../routes/types";
import {ClientType} from "../constants";

const DashboardParent = () => {
    const dispatch = useAppDispatch();

    const [clientProfiles, setClientProfiles] = useState([] as PartnerDashboardProfile[])
    const [isLoading, setIsLoading] = useState(true)

    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {type} = useParams<RouteWithProfileType>();
    const history = useHistory();

    usePageViewTimer('Partner Dashboard Page Load Timer (milliseconds)', isLoading);


    useEffect(() => {
        dispatch(setHeaderTickerTabs(EMPTY_HEADER_TICKER_TABS_RESPONSE));
        clientManagementApiClient
            .getPartnerDashboardProfiles()
            .then((p) => {
                setIsLoading(false);
                setClientProfiles(p.data);
            }).catch(error => console.error('Could not fetch partner dashboard profiles', error.message))
    }, [])

    const getLastSelectedTab = () => {
        if (type?.toLowerCase() === ClientType.PROSPECT) {
            return 1;
        } else {
            return 0;
        }
    }

    return <div className="display-flex margin-xl">
        <section className="partner-dashboard-section">
            <div className="partner-dashboard-page">
                <TabsProvider tabIdArray={["clientsTab", "prospectsTab"]} defaultActiveIndex={getLastSelectedTab()}>
                    {releaseToggles?.enableProspectProfiles ?
                        <TabBar size="medium" theme="dark">
                            <Tab className="clients-tab" name="Clients" onClick={() => {
                                history.replace('/Dashboard/Client')
                            }}/>
                            <Tab name="Prospects" onClick={() => {
                                history.replace('/Dashboard/Prospect')
                            }}/>
                        </TabBar> :
                        <TabBar size="medium" theme="dark">
                            <Tab className="clients-tab" name="Clients" onClick={() => {
                                history.replace('/Dashboard/Client')
                            }}/>
                        </TabBar>
                    }
                    <TabPanel>
                        <PartnerDashboard profiles={clientProfiles.filter(profile => !profile.prospect)}
                                          isEnableProfileAssistant={releaseToggles?.enableProfileAssistant ?? false}
                                          isLoading={isLoading}
                                          tabType={ClientType.CLIENT}
                        />
                        <PartnerDashboard profiles={clientProfiles.filter(profile => profile.prospect)}
                                          isEnableProfileAssistant={false}
                                          isLoading={isLoading}
                                          tabType={ClientType.PROSPECT}
                        />
                    </TabPanel>
                </TabsProvider>
            </div>
        </section>
    </div>
}

export default DashboardParent;