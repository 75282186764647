import React from "react";
import {PrioritizationType, UpdateNonLifestyleGoalType} from "../models/GoalType";
import {Table} from "xps-react";
import {
    gePresentValueCell,
    getAnnualFlowCell,
    getNameCell,
    getYearsOfFlowCell,
    getYearsUntilFlowCell
} from "../components/NonLifestyleGoalList";
import {NonLifestyleGoalRow} from "./Prioritization";
import {goalsApiClient} from "../GoalsApiClient";
import {DropdownItem, TableActionMenu} from "../../components";
import {goalTypeColumn} from "./NonLifeStyleGoalsFBNITable";
import {ProgressBar} from "../components/ProgressBar";


type NonLifestyleGoalsNeedsTableProps = {
    goalsForDisplay: NonLifestyleGoalRow[];
    tableClassName: string,
    profileId: string,
    name: string,
    displayProgressBar?:boolean,
    handleDropDownOnClick: (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => void
}
const NonLifestyleGoalsPrioritizationTable = ({
                                                  goalsForDisplay,
                                                  tableClassName,
                                                  profileId,
                                                  name,
                                                  displayProgressBar,
                                                  handleDropDownOnClick
                                              }: NonLifestyleGoalsNeedsTableProps) => {

    return (

        <div data-testid={`non-lifestyle-goal-${name}-table`} className={tableClassName}>
            <Table
                resizable={false}
                showPagination={false}
                sortable={false}
                defaultPageSize={9999}
                data={
                    goalsForDisplay
                }
                columns={
                    [
                        {
                            minWidth: 5,
                            maxWidth: 12,
                        },
                        {
                            minWidth: 25,
                            maxWidth: 25,
                        },
                        {
                            accessor: 'name',
                            Cell: getNameCell(),
                            Header: 'NAME',
                        },
                        goalTypeColumn,
                        {
                            accessor: 'progress-bar',
                            Header: '% FUNDED BY PORTFOLIO',
                            Cell: getProgressBarCell(),
                            headerClassName: 'display-flex justify-content-end',
                            maxWidth: 128,
                            show: displayProgressBar ? 1 : 0
                        },
                        {
                            accessor: 'progress-bar-percentage',
                            Cell: getProgressBarPercentageCell(),
                            headerClassName: 'display-flex justify-content-end',
                            className: 'table-label-15px',
                            maxWidth: 100,
                            show: displayProgressBar ? 1 : 0
                        },
                        {
                            accessor: 'annual-flow',
                            Cell: getAnnualFlowCell(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'ANNUAL FLOW',
                            maxWidth: 128
                        },
                        {
                            accessor: 'years-until-flow',
                            Cell: getYearsUntilFlowCell(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'YEARS UNTIL FLOW',
                            maxWidth: 128
                        },
                        {
                            accessor: 'years-of-flow',
                            Cell: getYearsOfFlowCell(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'YEARS OF FLOW',
                            maxWidth: 128
                        },
                        {
                            accessor: 'present-value',
                            Cell: gePresentValueCell(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'PRESENT VALUE',
                            maxWidth: 128
                        },
                        {
                            accessor: 'rowActionMenu',
                            className: "overflow-visible",
                            minWidth: 32,
                            maxWidth: 32,
                            Cell: getThreeDotMenuCell(name, profileId, handleDropDownOnClick)
                        }
                    ]
                }

            />
        </div>
    )
}

function getProgressBarCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
                <ProgressBar percentage={original.fundedByPortfolioPercentage!}/>
        );
    };
}

function getProgressBarPercentageCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div>
                {original.fundedByPortfolioPercentage}%
            </div>
        );
    };
}

function getThreeDotMenuCell(name: string, profileId: string, handleDropDownOnClick: (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => void) {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        const updatedNonLifestyleGoalType = {
            id: original.savedGoal!.id!,
            goalType: original.goalType!,
            prioritizationType: name == "needs" ? PrioritizationType.WANTS : PrioritizationType.NEEDS
        }
        const handleClickOnDropdown = async () => {
            const response = await goalsApiClient.updateNonLifestyleGoalForPrioritizationType(profileId, updatedNonLifestyleGoalType);
            if (response.ok) {
                handleDropDownOnClick(updatedNonLifestyleGoalType)
            }
        }

        return (
            <span className="table-action-menu">
                                    <TableActionMenu className="paddingright-0" panelWidth={240}
                                                     data-testId="three-dot-menu">
                                        <DropdownItem
                                            itemText={name == "needs" ? "Move to Wants" : "Move to Needs"}
                                            data-testId={`${name}-dropdown`}
                                            value=""
                                            key={`${name}-dropdown`}
                                            onClick={handleClickOnDropdown}
                                        />
                                    </TableActionMenu>

                                </span>
        )
    };
}

export default NonLifestyleGoalsPrioritizationTable;



