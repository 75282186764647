import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import useProfileAndProposals from "../../../hooks/useProfileAndProposals";
import {
    isLoaded,
    isNotLoaded,
    resetEstateSummary,
    selectEstateSummary, setEstateSummary
} from "../../../WealthTransfer/EstateSummarySlice";
import React, {useEffect, useState} from "react";
import {EstateType} from "../../../WealthTransfer/models/api";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {wealthTransferApiClient} from "../../../WealthTransfer/WealthTransferApiClient";
import WealthTransferReportView from "./WealthTransferReportView";

function pageName(pathname: string) {
    const parts = pathname.split('/');
    return parts[parts.length - 1];
}

const WealthTransferReport = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const {id} = useParams<RouteWithId>();
    const {profile: profileOrProposal, approvedProfile} = useProfileAndProposals(id);
    const displayName = approvedProfile.displayName;

    const estateSummaryState = useAppSelector(selectEstateSummary);
    const [openDrawer, updateOpenDrawer] = useState(false);
    const [, setIsLoading] = useState<boolean>(true);
    const [estateType, ] = useState<EstateType>(EstateType.CURRENT);

    useEffect(() => {
            const drawerOpening = openDrawer && isLoaded(estateSummaryState);
            if (!profileOrProposal.id || drawerOpening) {
                return;
            }
            dispatch(resetEstateSummary());
            setIsLoading(true);
            Promise.all([
                wealthTransferApiClient.getEstateSummary(profileOrProposal.id, estateType),
            ]).then(([estateSummaryResponse]) => {
                setIsLoading(false);
                dispatch(setEstateSummary(estateSummaryResponse));
            });
        },
        [profileOrProposal.id, openDrawer, dispatch, estateType]
    );

    useEffect(() => {
        const shouldOpenEditDrawer = ['Beneficiaries', 'TaxExemptions'].includes(pageName(history.location.pathname));
        updateOpenDrawer(shouldOpenEditDrawer);
    }, [history.location.pathname]);


    if (isNotLoaded(estateSummaryState)) {
        return <LoadingIndicator/>;
    }


    return  <WealthTransferReportView
        displayName={displayName}
            profileName={approvedProfile.displayName}
            estateSummary={estateSummaryState.estateSummary}
            estateType={estateType}

        />
}

export default WealthTransferReport;