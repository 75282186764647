import React from "react";
import {NonLifestyleGoalType} from "../models/GoalType";
import {DiscountRateType} from "../models/DiscountRate";
import {formatNumber} from "../../utils/format";
import {Button, PercentInput, UnderlinedHeader} from "../../components";

interface GoalGlidePathProps {
    nonLifestyleGoal: NonLifestyleGoalType,
    discountRateSelection: DiscountRateType,
    updateNonLifestyleGoal: (updateNonLifestyleGoalGlidePath: Partial<NonLifestyleGoalType["userInputs"]>) => void,
    updatePresentValue: () => void,
    resetRiskControlAsset: () => void
}

const GoalGlidePath = ({
                                 nonLifestyleGoal,
                                 updateNonLifestyleGoal,
                                 updatePresentValue,
                                 resetRiskControlAsset,
                                 discountRateSelection
                             }: GoalGlidePathProps) => {
    const isRiskInfoInUserInput = nonLifestyleGoal.userInputs.riskAsset != null

    const riskAssetsValue = isRiskInfoInUserInput
        ? nonLifestyleGoal.userInputs.riskAsset
        : nonLifestyleGoal.calculatedFields.riskAsset;

    const riskControlValue = isRiskInfoInUserInput
        ? nonLifestyleGoal.userInputs.riskControl
        : nonLifestyleGoal.calculatedFields.riskControl;

    const handleChangeRiskAssets = (newValue: string) => {
        const riskAsset = Number(newValue);
        const riskControl = 100 - riskAsset

        updateNonLifestyleGoal({riskAsset, riskControl});
    }

    const handleChangeRiskControlAssets = (newValue: string) => {
        const riskControl = Number(newValue)
        const riskAsset = 100 - riskControl

        updateNonLifestyleGoal({riskAsset, riskControl});
    }


    return (
        <section aria-label="Proposed Glidepath Allocation">
            <UnderlinedHeader
                primaryText='Proposed Glidepath Allocation'
                rightAlignedContent={
                        <Button
                            icon="left"
                            iconName="restore"
                            includeRef={false}
                            kind="borderless"
                            onClick={resetRiskControlAsset}
                            size="small"
                            tabIndex={0}
                            type="button"
                            style={{
                                visibility: isRiskInfoInUserInput ? 'visible' : 'hidden'
                            }}
                        >
                            REVERT TO GLIDEPATH
                        </Button>
                }
                className="goal-glidepath-underlined-header"
            />
            <div className="family-goal-glide-path-table">
                <label>Risk Assets</label>
                <PercentInput
                    defaultValue=""
                    label="riskAssets"
                    hideLabel
                    value={riskAssetsValue ? riskAssetsValue.toString() : '0'}
                    minValue={0}
                    maxValue={100}
                    onChange={handleChangeRiskAssets}
                    onBlur={() => {updatePresentValue()}}
                    formatOptions={{maximumFractionDigits: 0}}
                    disableOnChangeCallWhenHandlingFocusEvent={true}
                />
                <DiscountRateDiv
                    discountRateSelection={discountRateSelection}
                    calculatedFields={nonLifestyleGoal.calculatedFields}
                />
                <label>Risk Control Assets</label>
                <PercentInput
                    defaultValue=""
                    label="riskControlAssets"
                    hideLabel
                    value={riskControlValue ? riskControlValue.toString() : '0'}
                    minValue={0}
                    maxValue={100}
                    onChange={handleChangeRiskControlAssets}
                    onBlur={() => {updatePresentValue()}}
                    formatOptions={{maximumFractionDigits: 0}}
                    disableOnChangeCallWhenHandlingFocusEvent={true}
                />
            </div>
        </section>
    )
}
function DiscountRateDiv({calculatedFields, discountRateSelection}: {calculatedFields: NonLifestyleGoalType['calculatedFields'], discountRateSelection: DiscountRateType}) {
    return discountRateSelection === DiscountRateType.TRIPLE_NET
        ? (
            <div style={{marginTop: 10}}>
                <span className="marginleft-lg"><b>Triple Net Discount Rate</b></span>
                <span
                    className="marginleft-lg">{formatNumber(calculatedFields!.tripleNetDiscountRate)}%</span>
            </div>
        )
        : (
            <div style={{marginTop: 10}}>
                <span className="marginleft-lg"><b>Gross Discount Rate</b></span>
                <span
                    className="marginleft-lg">{formatNumber(calculatedFields!.grossDiscountRate)}%</span>
            </div>
        );
}
export default GoalGlidePath;
