import React, {useEffect, useState} from 'react';
import {RouteWithProfileAndProposalId} from '../../routes/types';
import {useHistory, useParams} from 'react-router-dom';
import {clientManagementApiClient} from '../ClientManagementApiClient';
import {ProfileRequestValidation, ProfileResponse, validateProfileResponse} from '../models/ProfileResponse';
import {Col, Radio, Row, Table} from "xps-react";
import {AlertMessage, Button, Icon, Name, Tab, TabBar, TabPanel, TabsProvider} from "../../components";
import {calculateTotalTax, EffectiveTaxRates} from "../NewClientProfile/EffectiveTaxRates";
import {EffectiveFees} from "../NewClientProfile/EffectiveFees";
import {EffectiveTaxRateType} from "../models/EffectiveTaxRateType";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {FinancialModelSelection} from "../models/FinancialModelSelection";
import {referenceDataClient} from "../../core/ReferenceDataClient";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {PrimaryContactInformation} from './PrimaryContactInformation';
import {PrimaryContact} from '../models/PrimaryContact';
import {msalUtils} from "../../MsalUtils";
import {ServiceTeamMember} from "../models/ServiceTeam";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {PartnerResource} from "../models/PartnerResource";
import {
    clearPartnerWorkstationState,
    selectLinkedUlek,
    setLinkedClientName,
    setLinkedUlek
} from "../PartnerWorkstation/partnerWorkstationSlice";
import {selectProfile, setProfile} from "../ClientProfile/activeProfileSlice";
import {partnerApiClient} from "../PartnerApiClient";
import {caseInsensitiveEquals} from "../../utils/stringUtils";
import {PARTNER_DASHBOARD_PAGE_SIZES} from "../PartnerDashboard/partnerDashboardConstants";
import {getEmptyProfileSettingsForm, selectProfileSettingsForm, setProfileSettingsForm} from "./ProfileSettingsSlice";
import {wealthManagementApiClient} from "../WealthManagementApiClient";
import NewClientFinancialModel from "../NewClientProfile/NewClientFinancialModel";
import ErrorModal, {emptyErrorModalData, ErrorModalData} from "../../components/Modal/Error/ErrorModal";

export const enum ProfileSettingsType {
    // TODO NEW_PROFILE
    PROFILE = 'PROFILE',
    PROPOSAL = 'PROPOSAL',
    NEW_PROPOSAL = 'NEW_PROPOSAL',
}

type ProfileSettingsProps = {
    settingsType?: ProfileSettingsType
}

type ProfileSettingsContentProps = {
    settingsType: ProfileSettingsType,
    generalSettings: JSX.Element,
    serviceTeamSettings: JSX.Element
}

const ProfileSettingsContent: React.FC<ProfileSettingsContentProps> = ({
                                                                           settingsType,
                                                                           generalSettings,
                                                                           serviceTeamSettings
                                                                       }) => {
    return settingsType === ProfileSettingsType.NEW_PROPOSAL
        ? generalSettings
        : <TabsProvider tabIdArray={['details', 'serviceTeam']}>
            <TabBar size="medium">
                <Tab name="General"/>
                <Tab name="Service Team"/>
            </TabBar>
            <TabPanel>
                {generalSettings}
                {serviceTeamSettings}
            </TabPanel>
        </TabsProvider>;
}

const ProfileSettings: React.FC<ProfileSettingsProps> = ({settingsType = ProfileSettingsType.PROFILE}) => {
    const history = useHistory();
    const {profileId, proposalId} = useParams<RouteWithProfileAndProposalId>();
    const msal = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [primaryContact, updatePrimaryContact] = useState({} as PrimaryContact);
    const [referenceData, updateReferenceData] = useState({} as ReferenceDataType);
    const [showErrorBanner, updateShowErrorBanner] = useState(false);
    const [saveHasBeenClicked, updateSaveHasBeenClicked] = useState(false);
    const [profileOwner, updateProfileOwner] = useState('');
    const [accountName, updateAccountName] = useState('');
    const [serviceTeam, updateServiceTeam] = useState([] as ServiceTeamMember[]);
    const [displayNameInteractions, updateDisplayNameInteractions] = useState(false);
    const [clientProfileValidation, updateClientProfileValidation] = useState({
        displayName: null,
        primaryContact: null
    } as ProfileRequestValidation)
    const clientProfile = useAppSelector(selectProfile);
    const linkedUlek = useAppSelector(selectLinkedUlek)!;
    const profileSettingsForm = useAppSelector(selectProfileSettingsForm);
    const displayName = profileSettingsForm.displayName;
    const dispatch = useAppDispatch();
    const [hasProposal, updateHasProposal] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [proposalError, setProposalError] = React.useState<ErrorModalData>(emptyErrorModalData);

    useEffect(() => {
        checkFormIsValid();
    }, [displayName, primaryContact]);

    useEffect(() => {
        clientManagementApiClient.getProfile(settingsType === ProfileSettingsType.PROPOSAL ? proposalId : profileId)
            .then((profile: ProfileResponse) => {
                if (clientProfile.resourceCode) {
                    profile.resourceCode = clientProfile.resourceCode;
                }
                dispatch(setProfile(profile));
                dispatch(setProfileSettingsForm({
                    ...profileSettingsForm,
                    displayName: profile.displayName,
                    effectiveTaxRate: {
                        ...profile.effectiveTaxRate,
                        total: calculateTotalTax(profile.effectiveTaxRate.federal, profile.effectiveTaxRate.stateAndLocal)
                    },
                    allocationType: profile.allocationType,
                    accreditedStatus: profile.accreditedStatus,
                    managementFees: profile.managementFees,
                }));
                updateProfileOwner(profile.owner);
                updatePrimaryContact(profile.primaryContact);
            }).catch(error => {
            console.error('Could not fetch profile/proposal', error.message);
        })
        referenceDataClient.getReferenceData()
            .then((data: ReferenceDataType) => updateReferenceData(data))
        clientManagementApiClient.hasProposals(profileId).then((hasProposals) => {
            updateHasProposal(hasProposals);
        }).catch(error => console.log('Could not fetch proposals', error.message))
    }, [profileId]);

    useEffect(() => {
        if (clientProfile.resourceCode) {
            partnerApiClient.getServiceTeam(clientProfile.resourceCode)
                .then((team: ServiceTeamMember[]) => {
                    updateServiceTeam(team);
                });
        } else {
            updateServiceTeam([{
                partnerCode: msalUtils.getLanId(msal),
                partnerName: msalUtils.getAccountName(msal),
                phoneNumber: msalUtils.getAccountPhoneNumber(msal),
                email: msalUtils.getAccountEmail(msal),
                title: msalUtils.getAccountTitle(msal)
            }]);
        }
    }, [clientProfile]);

    useEffect(() => {
        updateAccountName(msalUtils.getAccountName(msal)!);
    }, []);

    useEffect(() => {
        if (clientProfile?.resourceCode !== '') {
            partnerApiClient.getPartnerResources({
                resourceCode: clientProfile.resourceCode,
                partnerCode: msalUtils.getLanId(msal)
            })
                .then((linkedPartnerResource: PartnerResource[]) => {
                    if (linkedPartnerResource !== undefined) {
                        setLinkedPartnerWorkstationClient(linkedPartnerResource[0]);
                    }
                }).catch(error => {
                    console.error('Could not fetch partner resources', error.message);
                }
            );
        }
    }, [clientProfile?.resourceCode]);

    const checkFormIsValid = () => {
        const profileValidation = validateProfileResponse({displayName, primaryContact} as ProfileResponse)
        updateClientProfileValidation(profileValidation);
        const isValid = Object.values(profileValidation).every(x => x === null);
        updateShowErrorBanner(!isValid);
        return isValid;
    }

    const handleSaveProfile = () => {
        return clientManagementApiClient.putProfile({
            ...clientProfile,
            displayName: displayName,
            primaryContact: primaryContact,
            effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
            managementFees: profileSettingsForm.managementFees,
            accreditedStatus: profileSettingsForm.accreditedStatus,
            allocationType: profileSettingsForm.allocationType,
            lastModifiedByName: accountName,
            resourceCode: linkedUlek,
            owner: profileOwner
        }).catch(error => {
            console.error('Could not save profile', error.message)
        });
    };

    const handleCreateNewProposal = async (): Promise<boolean> => {
        setSaveButtonDisabled(true);
        const response = await wealthManagementApiClient.postProposal(profileId, {
            proposalName: profileSettingsForm.displayName,
            createdByName: accountName,
            effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
            accreditedStatus: profileSettingsForm.accreditedStatus,
            allocationType: profileSettingsForm.allocationType,
            managementFees: profileSettingsForm.managementFees,
        })

        if (!response.isSuccessStatusCode) {
            setProposalError({
                isOpen: true,
                header: "Unable to save a new proposal",
                message: "If this error remains after retrying to save, please contact the help desk",
                operationId: response.traceId
            });
            console.error('Could not create proposal');
        }
        return response.isSuccessStatusCode;
    };

    const handleSaveProposal = () => {
        return clientManagementApiClient.putProposal(profileId, proposalId, {
            id: proposalId,
            proposalName: profileSettingsForm.displayName,
            createdByName: accountName,
            effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
            accreditedStatus: profileSettingsForm.accreditedStatus,
            allocationType: profileSettingsForm.allocationType,
            managementFees: profileSettingsForm.managementFees,
        }).catch(error => {
            console.error('Could not save proposal', error.message)
        });
    };

    const handleSaveButton = async () => {
        if (checkFormIsValid()) {
            let handleSave;
            switch (settingsType) {
                case ProfileSettingsType.NEW_PROPOSAL :
                    handleSave = handleCreateNewProposal;
                    break;
                case ProfileSettingsType.PROFILE :
                    handleSave = handleSaveProfile;
                    break;
                case ProfileSettingsType.PROPOSAL :
                    handleSave = handleSaveProposal;
                    break;
                default :
                    console.error("Unknown ProfileSettingsType : ", settingsType);
                    return;
            }

            handleSave().then((data) => {
                if (data) {
                    resetFormAndLinkedClient();
                    history.push({
                        pathname: `/Profile/${profileId}`
                    })
                }
            });
        } else {
            updateShowErrorBanner(true);
            updateSaveHasBeenClicked(true);
        }
    }

    const resetFormAndLinkedClient = () => {
        dispatch(setProfileSettingsForm(getEmptyProfileSettingsForm()));
        dispatch(clearPartnerWorkstationState());
    }

    const setLinkedPartnerWorkstationClient = (partnerResource: PartnerResource) => {
        dispatch(setLinkedUlek(partnerResource.resourceCode));
        dispatch(setLinkedClientName(partnerResource.firstName + " " + partnerResource.lastName));
    }

    const generalSettings = <GeneralTab displayName={displayName}
                                        displayNameInteractions={displayNameInteractions}
                                        updateDisplayNameInteractions={updateDisplayNameInteractions}
                                        settingsType={settingsType}
                                        clientProfile={clientProfile}
                                        clientProfileValidation={clientProfileValidation}
                                        saveHasBeenClicked={saveHasBeenClicked}
                                        primaryContact={primaryContact}
                                        referenceData={referenceData}
                                        isReadOnly={settingsType === ProfileSettingsType.PROFILE && hasProposal}/>;

    const serviceTeamSettings = <ServiceTeamTab serviceTeam={serviceTeam}
                                                savedProfileOwner={clientProfile.owner}
                                                profileOwnerFormState={profileOwner}
                                                updateProfileOwnerFormState={updateProfileOwner}
                                                userLanID={msalUtils.getLanId(msal)}
                                                linkedUlek={linkedUlek}/>;


    const handleProposalRetry = async () => {
        setProposalError({...proposalError, isOpen: false});
        await handleSaveButton();
    }

    const handleProposalCancel = () => {
        setProposalError({...proposalError, isOpen: false});
        setSaveButtonDisabled(false);
    }
    return (
        <div>
            {!isAuthenticated && msalUtils.handleLogin(msal)}
            {isAuthenticated && <div className="page-container">
                <div className="profile-settings">
                    <ProfileSettingsHeader clientProfile={clientProfile}
                                           settingsType={settingsType}
                                           hasErrors={showErrorBanner && saveHasBeenClicked}
                                           onSave={handleSaveButton}
                                           onCancel={() => {
                                               resetFormAndLinkedClient();
                                               history.goBack()
                                           }}
                                           buttonDisabled={saveButtonDisabled}
                    />
                    <ProfileSettingsContent settingsType={settingsType}
                                            generalSettings={generalSettings}
                                            serviceTeamSettings={serviceTeamSettings}

                    />
                    <ErrorModal
                        errorModalData={proposalError}
                        onClickRetry={handleProposalRetry}
                        onClickCancel={handleProposalCancel}
                    />
                </div>
            </div>
            }
        </div>
    );
}

type ProfileSettingsHeaderProps = {
    clientProfile: ProfileResponse,
    settingsType: ProfileSettingsType,
    onCancel: () => void,
    onSave: () => void,
    hasErrors: boolean,
    buttonDisabled: boolean
}

const getProfileSettingsHeaderText = (settingsType: ProfileSettingsType, clientProfile: ProfileResponse) => {
    switch (settingsType) {
        case ProfileSettingsType.NEW_PROPOSAL:
            return 'New Proposal';
        case ProfileSettingsType.PROPOSAL:
            return `Proposal Settings - ${clientProfile?.displayName}`;
        case ProfileSettingsType.PROFILE:
            return `Client Profile Settings - ${clientProfile?.displayName}`;
    }
}

const ProfileSettingsHeader: React.FC<ProfileSettingsHeaderProps> = ({
                                                                         clientProfile,
                                                                         settingsType,
                                                                         onCancel,
                                                                         onSave,
                                                                         hasErrors,
                                                                         buttonDisabled,
                                                                     }) => {
    const title = getProfileSettingsHeaderText(settingsType, clientProfile);
    return (
        <div className="row-container">
            <Row>
                <span className="font-xxl title">{title}</span>
                <Col>
                    <div className="textalign-right">
                        <Button className="marginright-md"
                                icon="none"
                                id="cancel_new_client_profile_button"
                                includeRef={false}
                                kind="secondary"
                                onClick={onCancel}
                                size="medium"
                                tabIndex={0}
                                type="button"
                        >
                            CANCEL
                        </Button>
                        <Button
                            icon="none"
                            id="save_new_client_profile_button"
                            includeRef={false}
                            kind="primary"
                            onClick={onSave}
                            size="medium"
                            tabIndex={0}
                            type="button"
                            disabled={buttonDisabled}
                        >
                            SAVE
                        </Button>
                    </div>
                </Col>
                {hasErrors && (
                    <AlertMessage
                        className="display-flex justify-content-right alertBanner"
                        id="alertBanner"
                        fullWidth={false}
                        icon="warning"
                        showAlert={true}
                        showCloseBtn={false}
                        type="error"
                    >
                        <b className="alertBannerStyleName">Required fields missing</b>
                        &nbsp; &nbsp;
                        <b className="font-weight-400">All required fields must be entered to save a client
                            profile.</b>
                    </AlertMessage>
                )}
            </Row>
        </div>
    );
}

type GeneralTabProps = {
    displayName: string,
    displayNameInteractions: boolean,
    updateDisplayNameInteractions: (e: boolean) => void,
    settingsType: ProfileSettingsType,
    clientProfile: ProfileResponse,
    clientProfileValidation: ProfileRequestValidation,
    saveHasBeenClicked: boolean,
    primaryContact: PrimaryContact,
    referenceData: ReferenceDataType,
    isReadOnly: boolean
}

export const GeneralTab: React.FC<GeneralTabProps> = ({
                                                          displayName,
                                                          displayNameInteractions,
                                                          updateDisplayNameInteractions,
                                                          settingsType,
                                                          clientProfile,
                                                          clientProfileValidation,
                                                          saveHasBeenClicked,
                                                          primaryContact,
                                                          referenceData,
                                                          isReadOnly
                                                      }) => {
    const profileSettingsForm = useAppSelector(selectProfileSettingsForm);
    const dispatch = useAppDispatch();

    return (
        <div className="general-tab">
            <Row>
                <Col>
                    <div className="margintop-lg">
                        <span className="h4">{
                            settingsType === ProfileSettingsType.PROFILE
                                ? 'Profile Details'
                                : 'Proposal Details'
                        }</span>
                        <span
                            className="font-default color-text--error marginleft-md">* Required fields</span>
                    </div>
                    <hr className="section"/>
                    <div className="parent">
                        <Name name={displayName}
                              label={"Display Name"}
                              onChange={(e: any) => {
                                  dispatch(setProfileSettingsForm({
                                      ...profileSettingsForm,
                                      displayName: e.target.value
                                  }));
                              }}
                              required={true}
                              error={clientProfileValidation.displayName}
                              hasInteractions={displayNameInteractions}
                              whenUserHasInteracted={() => {
                                  updateDisplayNameInteractions(true)
                              }}
                              forceShowErrors={saveHasBeenClicked}/>
                    </div>

                    {clientProfile?.primaryContact
                        ? (<PrimaryContactInformation
                            primaryContact={primaryContact}
                            showPartnerWorkstationLink={settingsType === ProfileSettingsType.PROFILE}/>)
                        : undefined}
                </Col>
                <Col className="margintop-lg">
                    {referenceData.taxRates && clientProfile?.effectiveTaxRate ? (
                        <EffectiveTaxRates
                            taxRate={profileSettingsForm.effectiveTaxRate}
                            updateTaxRate={(taxRate: EffectiveTaxRateType) => {
                                dispatch(setProfileSettingsForm({
                                    ...profileSettingsForm,
                                    effectiveTaxRate: taxRate
                                }));
                            }}
                            referenceData={referenceData}
                            isReadOnly={isReadOnly}/>
                    ) : undefined}
                    {referenceData.accreditedStatus && clientProfile?.accreditedStatus ? (
                        <NewClientFinancialModel financialModel={{
                            accreditedStatus: profileSettingsForm.accreditedStatus,
                            allocationType: profileSettingsForm.allocationType
                        }}
                                                 updateFinancialModel={(financialModel: FinancialModelSelection) => {
                                                     dispatch(setProfileSettingsForm({
                                                         ...profileSettingsForm,
                                                         ...financialModel
                                                     }));
                                                 }}
                                                 referenceData={referenceData}
                                                 disabled={isReadOnly}/>
                    ) : undefined}

                    {referenceData.managementFees && clientProfile?.managementFees ? (
                        <EffectiveFees managementFees={profileSettingsForm.managementFees}
                                       updateManagementFees={((managementFees: string) => {
                                           dispatch(setProfileSettingsForm({
                                               ...profileSettingsForm,
                                               managementFees: managementFees
                                           }));
                                       })}
                                       referenceData={referenceData}
                                       isReadOnly={isReadOnly}/>
                    ) : undefined}
                </Col>
            </Row>
        </div>
    );
}

type ServiceTeamTabProps = {
    serviceTeam: ServiceTeamMember[],
    savedProfileOwner: string,
    profileOwnerFormState: string,
    updateProfileOwnerFormState: (e: string) => void,
    userLanID: string,
    linkedUlek: string
}

const ServiceTeamTab: React.FC<ServiceTeamTabProps> = ({
                                                           serviceTeam,
                                                           savedProfileOwner,
                                                           profileOwnerFormState,
                                                           updateProfileOwnerFormState,
                                                           userLanID,
                                                           linkedUlek
                                                       }) => {
    return (
        <div className="service-team-table">
            <div className={"service-team-subtitle"}>
                <span>Service Team partner access is managed within Partner Workstation</span>
            </div>
            <Table
                id="service_team_table"
                data={serviceTeam}
                key={profileOwnerFormState}
                showPagination={serviceTeam.length > 10}
                defaultPageSize={15}
                pageSizeOptions={PARTNER_DASHBOARD_PAGE_SIZES}
                columns={[
                    {
                        id: 'ownerId',
                        Header: 'Owner',
                        accessor: 'partnerCode',
                        maxWidth: 200,
                        Cell: ({value}: { value: string }) => {
                            const partnerCode = value;
                            if (caseInsensitiveEquals(savedProfileOwner, userLanID)) {
                                const isChecked = caseInsensitiveEquals(partnerCode, profileOwnerFormState);
                                return <Radio
                                    checked={isChecked}
                                    value={partnerCode}
                                    id={partnerCode}
                                    name={partnerCode}
                                    role={"radio"}
                                    aria-label={partnerCode}
                                    aria-checked={isChecked}
                                    onChange={() => updateProfileOwnerFormState(partnerCode)}
                                />
                            }
                            return caseInsensitiveEquals(partnerCode, savedProfileOwner)
                                ? <Icon name={'status_validated'} color={'#64a70b'}/>
                                : <div/>
                        },
                    },
                    {
                        Header: 'Partner Name',
                        accessor: 'partnerName',
                        maxWidth: 200
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phoneNumber',
                        maxWidth: 200
                    },
                    {
                        Header: 'Email Address',
                        accessor: 'email',
                        maxWidth: 200
                    },
                    {
                        Header: 'Partner Title / Role',
                        accessor: 'title'
                    }
                ]}
            />
            {!linkedUlek &&
                <div className={"no-partner-workstation-text"}>
                    <span>This profile is not connected to Partner Workstation</span>
                </div>
            }
        </div>
    );
}

export default ProfileSettings;
