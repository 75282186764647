import {formatCurrency} from "../../utils/format";
import React, {CSSProperties} from "react";
import {LifestylePresentValueResponse} from "../models/PresentValue";


export type ReserveTargetDetailsProps = {
    presentValue: LifestylePresentValueResponse
}

const flexStyles: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px"
}

const ReserveTargetDetails: React.FC<ReserveTargetDetailsProps> = ({presentValue
                                                                   }) => {
    const displayAlignedInflows = presentValue.totalAlignedInflowPresentValue === 0 ? 0 : -presentValue.totalAlignedInflowPresentValue

    return <div>
        <div style={{...flexStyles, fontWeight: "500"}}>
            <span>Total Lifestyle Spending</span>
            <span>{formatCurrency(presentValue.presentValue)}</span>
        </div>
        <div style={flexStyles}>
            <span>Funded by Inflows</span>
            <span>{formatCurrency(displayAlignedInflows)}</span>
        </div>
        <div style={flexStyles}>
            <span>Funded by Non-Investable Assets</span>
            <span>{formatCurrency(presentValue.presentValueForInsufficientYears)}</span>
        </div>
        <div style={flexStyles}>
            <span>Required Portfolio Spending</span>
            <span>{formatCurrency(presentValue.requiredPortfolioSpendingPresentValue)}</span>
        </div>
        <div style={flexStyles}>
            <span>Lifestyle Asset Allocation %</span>
            <span>{`RA ${Math.round(presentValue.riskAsset)} / RC ${Math.round(presentValue.riskControl)}`}</span>
        </div>
    </div>;
}

export default ReserveTargetDetails;
