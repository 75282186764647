import {formatCurrency} from "../../utils/format";
import React from "react";
import {TaxStatusOptions} from "../models/TaxDetails";

type TaxLiabilitiesTableProps = {
    taxStatus: TaxStatusOptions,
    unrealizedCapitalGainsTax?: number | null,
    deferredTaxLiability?: number | null,
    liabilityPaidByPortfolio: boolean | null,
};

export default function TaxLiabilitiesTable({
                                                taxStatus,
                                                unrealizedCapitalGainsTax,
                                                deferredTaxLiability,
                                                liabilityPaidByPortfolio
                                            }: TaxLiabilitiesTableProps) {
    const renderTaxLiabilitiesData = () => {
        switch (taxStatus) {
            case "Taxable": {
                const taxType = 'Estimated Unrealized Capital Gains Tax';
                return (<>
                    <span role="cell">{taxType}</span>
                    <span role="cell" className="textalign-right" aria-label={`${taxType} - Present Value`}>
                        {(unrealizedCapitalGainsTax !== null) ? formatCurrency(unrealizedCapitalGainsTax) : <span className="disabled-dash">&ndash;&ndash;</span>}
                    </span>
                    <span role="cell" className="textalign-right" aria-label={`${taxType} - Investable Value`}>
                        {(unrealizedCapitalGainsTax !== null && liabilityPaidByPortfolio) ? formatCurrency(unrealizedCapitalGainsTax) : <span className="disabled-dash">&ndash;&ndash;</span>}
                    </span>
                </>);
            }
            case "Deferred": {
                const taxType = 'Estimated Deferred Tax Liability';
                return (<>
                    <span role="cell">{taxType}</span>
                    <span role="cell" className="textalign-right" aria-label={`${taxType} - Present Value`}>
                        {(deferredTaxLiability !== null) ? formatCurrency(deferredTaxLiability) : <>&ndash;&ndash;</>}
                    </span>
                    <span role="cell" className="textalign-right" aria-label={`${taxType} - Investable Value`}>
                       {(deferredTaxLiability !== null && liabilityPaidByPortfolio) ? formatCurrency(deferredTaxLiability) : <>&ndash;&ndash;</>}
                    </span>
                </>);
            }
        }
    };

    return <div role="table" aria-label="Tax Liabilities" className="tax-liabilities-grid padding-lg">
        <div role="row" tabIndex={-1}
             className="tax-liabilities-table-header tax-liabilities-grid tax-liabilities-grid-item grid-span-all">
            <span role="columnheader">Tax Type</span>
            <span role="columnheader" className="textalign-right">Present value</span>
            <span role="columnheader" className="textalign-right">Investable</span>
        </div>
        <div role="row" tabIndex={0}
             className="tax-liabilities-table-row tax-liabilities-grid tax-liabilities-grid-item grid-span-all">
            {renderTaxLiabilitiesData()}
        </div>
    </div>;
}
