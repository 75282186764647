import React from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {patchProfile, selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {ActivatePortfolioReserveSwitch, LabelPosition} from "./ActivatePortfolioReserveSwitch";
import {patchApprovedProfile} from "../../ClientManagement/ClientProfile/approvedProfileSlice";
import {patchProposals} from "../../ClientManagement/Proposals/proposalsSlice";

export interface ProfileAwareActivatePortfolioReserveSwitchProps {
    className?: string;
    labelPosition: LabelPosition;
}

export const ProfileAwareActivatePortfolioReserveSwitch = (props: ProfileAwareActivatePortfolioReserveSwitchProps) => {
    const profileOrProposal = useAppSelector(selectProfile);
    const dispatch = useAppDispatch();

    return <ActivatePortfolioReserveSwitch
        isActivePortfolioReserve={profileOrProposal.isActivePortfolioReserve}
        profileOrProposalId={profileOrProposal.id}
        postToggleCallback={(isActivePortfolioReserve) => {
            try {
                dispatch(patchProfile({isActivePortfolioReserve}));
                dispatch(patchApprovedProfile({isActivePortfolioReserve}));
                dispatch(patchProposals({isActivePortfolioReserve}));
            } catch (error) {
                console.error('Could not activate portfolio reserve toggle', error);
            }
        }}
        {...props}
    />
}