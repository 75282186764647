import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import AssetRelianceHeader from "./AssetRelianceHeader";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {wealthManagementApiClient} from "../WealthManagementApiClient";
import AssetRelianceContent from "./AssetRelianceContent";
import {
    selectAssetReliancePageActionMenu,
    selectAssetRelianceResponse,
    setAssetReliancePageActionMenu,
    setAssetRelianceResponse
} from "./AssetRelianceSlice";
import LoadingIndicator from 'src/pages/LoadingIndicator';
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {RouteWithId} from "../../routes/types";
import {resetAccordionTableState} from "../../components/AccordionTable/AccordionTableSlice";
import AssetRelianceFooter from "./AssetRelianceFooter";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import {selectPrimaryContact} from "../ClientProfile/activeProfileSlice";
import {EMPTY_ASSET_RELIANCE_BUTTON_STATE} from "./AssetRelianceButtonState";
import {emptyInvestorGroup, InvestorGroupType} from "../models/InvestorGroupType";
import {clientManagementApiClient} from "../ClientManagementApiClient";

const AssetReliance: React.FC = () => {
    const assetResponse = useAppSelector(selectAssetRelianceResponse);
    const assetRelianceButtonState = useAppSelector(selectAssetReliancePageActionMenu);
    const primaryContact = useAppSelector(selectPrimaryContact);

    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const unblockRef = useRef();

    const accountHasAssets = !!assetResponse.allAssetsStack.netAssets || !!assetResponse.goals.totalPresentValue;
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [genericError, setGenericError] = useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);

    const handleErrorCloseButton = () => {
        setGenericError({...genericError, isOpen: false});
    }

    usePageViewTimer('Asset Reliance Page Load Timer (milliseconds)', !pageLoaded);

    useEffect(() => {
        if (id) {
            setPageLoaded(false);
            Promise.all([
                wealthManagementApiClient.getAssetReliance(id),
                clientManagementApiClient.getInvestorGroup(id)
            ]).then(([assetRelianceResponse, investorGroupResponse]) => {
                dispatch(setAssetRelianceResponse(assetRelianceResponse));
                setInvestorGroup(investorGroupResponse);
                setPageLoaded(true);
            }).catch((error) => {
                setGenericError({
                    isOpen: true,
                    header: "Communication Failure",
                    message: "There has been a communication failure.  The data you see may not be accurate, please refresh your browser.  If this error continues, please contact the support team.",
                    operationId: error.headers.get('trace-id')
                });
                console.error('Could not fetch asset reliance data', error.message);
            });
        }
        return () => {
            dispatch(resetAccordionTableState());
        };
    }, [id])

    useEffect(() => {
        unblockRef.current = history.block((newLocation: Location) => {
            dispatch(setAssetReliancePageActionMenu({
                ...EMPTY_ASSET_RELIANCE_BUTTON_STATE
            }))
            return true;
        })
    }, [history])

    return (
        <div className="asset-reliance">
            <GenericErrorModal
                errorModalData={genericError}
                onClickClose={handleErrorCloseButton}
                closeButtonText={'Close'}
            />
            <AssetRelianceHeader
                id={id}
                title="Review Asset Reliance"
                headerOptions={assetRelianceButtonState}
                primaryContact={primaryContact!}
                showPageActionMenu={assetResponse.allAssetsStack.excessAssets > 0}
            />
            <>
                {pageLoaded ?
                    <AssetRelianceContent
                        goalsSummaryDTO={assetResponse.goals}
                        allAssetsStack={assetResponse.allAssetsStack}
                        assetStacks={assetResponse.assetStacks}
                        excludedAssetStacks={assetResponse.excludedAssetStacks}
                        id={id}
                        accountHasAssets={accountHasAssets}
                        headerOptions={assetRelianceButtonState}
                        investorGroup={investorGroup}
                    />
                    : <LoadingIndicator displayMessage="Page loading"/>
                }
            </>
            <AssetRelianceFooter/>
        </div>
    )
}

export default AssetReliance;