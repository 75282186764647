import React, {MouseEventHandler, ReactNode} from "react";
import classNames from 'classnames';
import {Button as XpsButton} from 'xps-react';

export type ButtonKind = 'primary' | 'secondary' | 'tertiary' | 'borderless';

export type ButtonProps = {
    id?: string,
    role?: string,
    children?: ReactNode,
    className?: string,
    kind?: ButtonKind,
    rounded?: boolean,
    destructive?: boolean,
    icon?: 'right' | 'left' | 'none' | 'only',
    iconName?: string,
    onClick?: MouseEventHandler,
    includeRef?: boolean,
    size?: 'large' | 'medium' | 'small',
    tabIndex?: number,
    type?: 'button' | 'reset' | 'submit',
    padding?: boolean,
    noPadding?: boolean,
    disabled?: boolean,
    style?: any,
    ariaLabel?: string,
}

const Button = ({ children, className, kind = 'primary', rounded = false, destructive = false, ...rest }: ButtonProps) => (
    <XpsButton
        className={classNames({ 'button--rounded': rounded }, { 'button--destructive': destructive }, className)}
        kind={kind}
        {...rest}
    >
        {children}
    </XpsButton>
);

export default Button;
