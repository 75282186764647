import {Icon as XpsIcon} from 'xps-react';

type IconProps = {
    color?: string,
    name: string,
    size?: string,
    className?: string,
    ariaHidden?: boolean,
    ariaLabel?: string,
    type?: 'success' | 'warning' | 'error' | 'info',
};

const Icon = ({ color, ...props }: IconProps) => <XpsIcon {...props} style={color ? { color } : undefined} />;

export default Icon;
