import {useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {formatCurrency} from "../../utils/format";
import React from "react";
import {Table} from "xps-react";
import {ProgressBar} from "../components/ProgressBar";
import {SplitLifestyleInfoPopover} from "./SplitLifestyleInfoPopover";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";


export interface LifestyleGoalTableRow {
    name: JSX.Element,
    yearsUntilFlow: number,
    yearsOfFlow: number,
    requiredPortfolioSpendingPresentValue: string
}



type LifestyleGoalTableProp = {
    displayProgressBar : boolean;
}
export const LifestyleGoalTable = (props : LifestyleGoalTableProp) => {

    const {lifestyleSpendingGoal, investorGroup, taxLiabilities, assets} = useAppSelector<GoalModelType>(selectGoalModel);

    const isLifestyleGoalFBNI = lifestyleSpendingGoal.calculatedFields.presentValueForInsufficientYears > 0;

    const {id} = useParams<RouteWithId>();
    const profile = useProfileAndProposals(id).profile;

    function getFBPPercentageForLifestyle() {
        let assetConsideredForCalculations = assets.totalInvestableValue-taxLiabilities.totalTaxLiabilitiesFundedByPortfolio;
        const lifeStylePvFundedByPortfolio = lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue;
        const reserveTargetAmount = lifestyleSpendingGoal.calculatedFields.reserveTargetAmount;
        const reserveTargetIsSet = (profile.portfolioReserveTargetLength !== undefined && profile.portfolioReserveTargetLength !== null);

        if(reserveTargetIsSet && reserveTargetAmount > assetConsideredForCalculations){
            assetConsideredForCalculations = reserveTargetAmount
        }
        if(assetConsideredForCalculations >= lifeStylePvFundedByPortfolio) {
            return 100;
        }else {
            return (Math.round( assetConsideredForCalculations*100/lifeStylePvFundedByPortfolio));
        }
    }

    const lifestyleGoalRow : LifestyleGoalTableRow = {
        name: <div>
            <span>Lifestyle Spending</span>
            <SplitLifestyleInfoPopover
                show={isLifestyleGoalFBNI} direction="top"/>
        </div>,
        yearsUntilFlow: 0,
        yearsOfFlow: lifestyleSpendingGoal.calculatedFields.sufficientYears === 0 ? investorGroup.planningPeriod.numberOfYears :
            lifestyleSpendingGoal.calculatedFields.sufficientYears,
        requiredPortfolioSpendingPresentValue: formatCurrency(lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue)
    }

    return (
        <div data-testid={'lifestyle-goal-table'} className='goals-table-single-row'>
            <Table
                resizable={false}
                showPagination={false}
                sortable={false}
                defaultPageSize={9999}
                data={
                    [lifestyleGoalRow]
                }
                columns={
                    [
                        {
                            minWidth: 5,
                            maxWidth: 12,
                            className: 'lifestyle-background-color'
                        },
                        {
                            minWidth: 25,
                            maxWidth: 25,
                        },
                        {
                            accessor: 'name',
                            Header: 'NAME',
                            className: 'display-flex flex-column justify-content-center height-100p table-label-15px'
                        },
                        {
                            minWidth: 260,
                            maxWidth: 260,
                        },
                        {
                            accessor: 'progress-bar',
                            Header: '% FUNDED BY PORTFOLIO',
                            Cell: () => {
                                return (
                                        <ProgressBar percentage={getFBPPercentageForLifestyle()}/>
                                );
                            },
                            headerClassName: 'display-flex justify-content-end',
                            maxWidth: 128,
                            show: props.displayProgressBar ? 1 : 0,
                        },
                        {
                            accessor: 'progress-bar-percentage',
                            Cell: () => {
                                return (
                                    <div>
                                        {getFBPPercentageForLifestyle()}%
                                    </div>
                                );
                            },
                            headerClassName: 'display-flex justify-content-end',
                            maxWidth: 100,
                            className: 'table-label-15px',
                            show: props.displayProgressBar ? 1 : 0,
                        },
                        {
                            minWidth: 128,
                            maxWidth: 128,
                        },
                        {
                            accessor: 'yearsUntilFlow',
                            Header: 'YEARS UNTIL FLOW',
                            headerClassName: 'display-flex justify-content-end',
                            className: 'display-flex flex-column justify-content-center align-items-end height-100p table-label-15px',
                            maxWidth: 128
                        },
                        {
                            accessor: 'yearsOfFlow',
                            Header: 'YEARS OF FLOW',
                            headerClassName: 'display-flex justify-content-end',
                            className: 'display-flex flex-column justify-content-center align-items-end height-100p table-label-15px',
                            maxWidth: 128
                        },
                        {
                            accessor: 'requiredPortfolioSpendingPresentValue',
                            Header: 'PRESENT VALUE',
                            headerClassName: 'display-flex justify-content-end',
                            className: 'display-flex flex-column justify-content-center align-items-end height-100p table-label-15px',
                            maxWidth: 128
                        },
                        {
                            minWidth: 32,
                            maxWidth: 32,
                        }
                    ]
                }

            />
        </div>
    );
};