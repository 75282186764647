import React, {useCallback, useEffect, useState} from "react";
import AddFormContainer from "../AddFormContainer";
import {EquityCompensationForm} from "./EquityCompensationForm";

import {EquityCompensationFormData} from "../models/EquityCompensation";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {assetsApiClient} from "../AssetsApiClient";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import LoadingIndicator from "../../pages/LoadingIndicator";

const AddEquityCompensation: React.FC = () => {
    const profile = useAppSelector(selectProfile);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType | null>(null);
    const [taxRateForProfile, setTaxRateForProfile] = useState<number>(0);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();

    const getInitialEquityCompData = useCallback(
        (): EquityCompensationFormData => ({
            id: '',
            profileId: profile?.id,
            entityOrAccountName: '',
            ticker: '',
            asOfDate: '',
            companyOrStockName: '',
            stockPrice: 0,
            taxRate: taxRateForProfile,
            doesPermitBeneficiary: false,
            owner: memberGroup?.primaryMember.id!,
            preTaxUnvestedValue: null,
            preTaxVestedValue: null,
            afterTaxUnvestedValue: null,
            afterTaxVestedValue: null,
            stockOptionPreTaxUnvestedValue: null,
            stockOptionPreTaxVestedValue: null,
            stockOptionAfterTaxUnvestedValue: null,
            stockOptionAfterTaxVestedValue: null,
            stockGrantPreTaxUnvestedValue: null,
            stockGrantAfterTaxUnvestedValue: null,
            stockOptionsAsOfDate: null,
            stockGrantsAsOfDate: null
        }),
        [taxRateForProfile, profile, memberGroup]
    );

    useEffect(() => {
        let componentUnmounted = false;

        Promise.all([
            clientManagementApiClient.getInvestorGroup(profile.id),
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getTaxRate(profile.id)
        ]).then(([investorGroupResponse, memberGroupResponse, taxRateResponse]) => {
            if (componentUnmounted) return;
            setInvestorGroup(investorGroupResponse);
            setTaxRateForProfile(taxRateResponse);
            setMemberGroup(memberGroupResponse);
        }).catch(error => console.error('Could not fetch asset details', error.message));

        return () => {
            componentUnmounted = true;
        }
    }, [profile.id]);

    const handleSave = async (data: EquityCompensationFormData): Promise<EquityCompensationFormData> => {
       const equityCompensation = await assetsApiClient.postEquityComp(profile.id, data);
       console.log(investorGroup)
       return equityCompensation;
    }

    if (!memberGroup || !profile) {
        return <LoadingIndicator/>;
    }

    return (
        <div className="equity-compensation">
            <AddFormContainer
                modalTitle="Asset"
                form={(handleCancel) => {
                    return (
                        <EquityCompensationForm
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            initialEquityComp={getInitialEquityCompData()}
                            memberGroup={memberGroup}
                            formatTitle={() => 'Add Equity Compensation'}
                        />
                    );
                }}
            />
        </div>
    )
};

export default AddEquityCompensation;