import {Dropdown, DropdownItem, EditableDropdown} from "../../../components/Dropdown";
import {Button, PercentInput} from "../../../components";
import {LegalEntityFormData, LegalEntityOwnershipFormData, legalEntityTypes} from "../../../Assets/models/Ownership";
import React, {ChangeEvent, useState} from "react";
import {Checkbox} from "xps-react";
import {calculateTotalOwnedValue} from "../helpers";

type LegalEntityOwnershipRowProps = {
    legalEntityOwnership: LegalEntityOwnershipFormData,
    index: number,
    onChange: (index: number, legalEntityOwnership: LegalEntityOwnershipFormData) => void;
    onDelete: (index: number) => void,
    legalEntities: LegalEntityFormData[],
    onAddLegalEntity: (name: string) => void,
    totalAssetValue?: number | null,
}
const LegalEntityOwnershipRow = ({
                                     legalEntityOwnership,
                                     index,
                                     legalEntities,
                                     onChange,
                                     onAddLegalEntity,
                                     onDelete,
                                     totalAssetValue
                                 }: LegalEntityOwnershipRowProps) => {
    const [showTypeEmptyError, setShowTypeEmptyError] = useState(false);
    const [showNameEmptyError, setShowNameEmptyError] = useState(false);
    const labelSuffix = index + 1;
    const onLegalEntityNameChange = (newLegalEntityName: string) => {
        const existingType = legalEntities.find((legalEntity) => {
            return legalEntity.name === newLegalEntityName;
        })?.type;

        onChange(index, {
            ...legalEntityOwnership,
            name: newLegalEntityName,
            type: existingType ?? legalEntityOwnership.type
        });
        setShowNameEmptyError(false);
    };

    return (
        <div className="legal-entity-ownership-table-row">
            <div role="row" className="legal-entity-ownership-table-sub-row">
                <Dropdown
                    aria-label={`Non-Client Owner Type ${labelSuffix}`}
                    value={legalEntityOwnership.type}
                    error={showTypeEmptyError ? "This field is required" : undefined}
                    onChange={data => {
                        onChange(index, {
                            ...legalEntityOwnership,
                            type: data.value
                        });
                        setShowTypeEmptyError(false);
                    }}
                    onBlur={() => {
                        if (!legalEntityOwnership.type) {
                            setShowTypeEmptyError(true);
                        }
                    }}
                >
                    {legalEntityTypeItems}
                </Dropdown>

                <EditableDropdown label={`Non-Client Owner Name ${labelSuffix}`}
                                  value={legalEntityOwnership.name}
                                  items={legalEntities.map(owner => owner.name)}
                                  onAdd={newValue => onAddLegalEntity(newValue)}
                                  error={showNameEmptyError ? "This field is required" : undefined}
                                  onChange={onLegalEntityNameChange}
                                  onBlur={() => {
                                      if (!legalEntityOwnership.name) {
                                          setShowNameEmptyError(true);
                                      }
                                  }}
                />
                <PercentInput
                    label={`Non-Client Owner Percentage ${labelSuffix}`}
                    defaultValue={"0"}
                    hideLabel
                    size="medium"
                    hideInlineLabel
                    minValue={0}
                    maxValue={100}
                    value={legalEntityOwnership.percentage}
                    onChange={(newValue) => onChange(index, {
                        ...legalEntityOwnership,
                        percentage: newValue
                    })}
                />
                <Button
                    className="legal-entity-ownership-table-sub-row delete"
                    destructive
                    icon="left"
                    iconName="close"
                    aria-label={`delete non-client owner ${labelSuffix}`}
                    kind="borderless"
                    onClick={() => onDelete(index)}
                />
            </div>
            <div role="row" className="legal-entity-ownership-table-sub-row">
                <Checkbox
                    name={`Out of Estate Report ${labelSuffix}`}
                    value="Out of Estate Report"
                    checked={legalEntityOwnership.showWithinOutOfEstateReport}
                    aria-label={`Out of Estate Report ${labelSuffix}`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(index, {
                        ...legalEntityOwnership,
                        showWithinOutOfEstateReport: e.target.checked
                    })}
                />
                <div className="total-owned-container">
                    <span className="fontweight-medium">
                        Total Owned
                    </span>
                    <span className="marginleft-md">
                        {calculateTotalOwnedValue(legalEntityOwnership.percentage, totalAssetValue)}
                    </span>
                </div>
            </div>
        </div>
    );

};

const legalEntityTypeItems = legalEntityTypes.map((type) => {
    return <DropdownItem
        itemText={type}
        key={type}
        value={type}
    />
});

export default LegalEntityOwnershipRow;
