import React, {useContext} from 'react';
import {AssetsSummary} from "../../models/Assets";
import {AccordionItem} from "../../../components";
import {COLOR_NT_GREY_200} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../AssetSummary/common/AssetAccordionHeader";
import {formatCurrency} from "../../../utils/format";
import {GroupedTable, LegalEntityGroupHeader} from "../../../components/Table/GroupedTable";
import {createLegalEntityTypeSummaries, createNonClientMemberSummaries} from "../../mappers";
import {LegalEntityReadModel, LegalEntityType} from "../../models/Ownership";
import {PersonalAsset} from "../../models/PersonalAsset";
import {showOwnershipType} from "../../Ownership/helpers";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import AccordionWithHeader from "../AccordionWithHeader";
import {useHistory} from "react-router-dom";
import AssetsViewContext from "../AssetsViewContext";

export interface OutOfEstateAssetListProps {
    assets: AssetsSummary,
    legalEntities: LegalEntityReadModel[]
    showDeathBenefit?: boolean;
    onDeleteLegalEntity: (legalEntityId: string) => void;
}

const OutOfEstateAssetList = ({
                                  assets,
                                  legalEntities,
                                  showDeathBenefit = false,
                                  onDeleteLegalEntity
                              }: OutOfEstateAssetListProps) => {

    const viewType = useContext(AssetsViewContext);
    const history = useHistory();

    let legalEntityTypeSummaries;
    let nonClientMemberSummaries;

    if (viewType === 'AssetSummary') {
        legalEntityTypeSummaries = createLegalEntityTypeSummaries(viewType, assets, legalEntities);
        nonClientMemberSummaries = createNonClientMemberSummaries(viewType, assets);
    } else {
        const getPersonalAssetDetailsFn = (asset: PersonalAsset) => showOwnershipType(asset.ownershipCategory);
        const getStandaloneAccountDetailsFn = (asset: StandaloneAccount) => showOwnershipType(asset.ownershipCategory);

        legalEntityTypeSummaries = createLegalEntityTypeSummaries(viewType, assets, legalEntities, getPersonalAssetDetailsFn,
            getStandaloneAccountDetailsFn, showDeathBenefit);
        nonClientMemberSummaries = createNonClientMemberSummaries(viewType, assets, getPersonalAssetDetailsFn,
            getStandaloneAccountDetailsFn, showDeathBenefit);
    }

    const captionText = showDeathBenefit ? "Death Benefit" : "Cash Value";

    const allItems = [
        ...legalEntityTypeSummaries.map(summary => summary.entityType),
        ...nonClientMemberSummaries.map(summary => `${summary.memberName}-assets-summary`)
    ];

    const handleMenuItemClick = (route: string) => {
        history.push(route);
    };

    return <section data-testid="out-of-estate-asset-summary">
        <div className="asset-list">
            <div className="accordion-tables">
                <AccordionWithHeader
                    accordionId={viewType + "OutOfEstateAccordion"}
                    allItems={allItems}
                    columnTitles={['Owner', '', 'Present Value']}
                    renderHeader
                    headerProps={
                        viewType === 'CurrentNetWorth'
                            ? {
                                className: "current-net-worth-grid",
                                testId: "current-net-worth-headers"
                            }
                            : undefined
                    }
                >
                    <div className="assets">
                        {legalEntityTypeSummaries.map((summary, index) => {
                            return <AccordionItem
                                key={index}
                                uuid={summary.entityType}
                                accentColor={COLOR_NT_GREY_200}
                                HeaderComponent={({expanded}) => {
                                    const caption = viewType === 'CurrentNetWorth' &&
                                    summary.entityType === "Irrevocable Life Insurance Trust (ILIT)" ? captionText : undefined;

                                    return <AssetAccordionHeader
                                        expanded={expanded}
                                        title={entitySummaryTitles[summary.entityType]}
                                        caption={caption}
                                        formattedTotalPresentValue={formatCurrency(summary.totalValue)}
                                        gridClassName={"assets-grid"}/>;
                                }
                                }>

                                <GroupedTable columnHeaders={["Name", "Present Value"]}
                                              tableData={summary.entities}
                                              menuClickHandler={handleMenuItemClick}
                                              renderGroupHeader={group =>
                                                  <LegalEntityGroupHeader groupName={group.groupName}
                                                                          groupValue={group.groupValue}
                                                                          groupId={group.groupId}
                                                                          deleteEntity={onDeleteLegalEntity}/>}
                                />
                            </AccordionItem>;
                        })}
                        {nonClientMemberSummaries.map((summary) =>
                            <AccordionItem
                                key={summary.memberName}
                                uuid={`${summary.memberName}-assets-summary`}
                                accentColor={COLOR_NT_GREY_200}
                                HeaderComponent={({expanded}) =>
                                    <AssetAccordionHeader
                                        expanded={expanded}
                                        title={summary.memberName}
                                        formattedTotalPresentValue={formatCurrency(summary.totalValue)}
                                        gridClassName={"assets-grid"}/>
                                }
                            >
                                <GroupedTable
                                    columnHeaders={["Name", "Present Value"]}
                                    tableData={summary.assets}
                                    menuClickHandler={handleMenuItemClick}
                                />
                            </AccordionItem>)
                        }
                    </div>
                </AccordionWithHeader>
            </div>
        </div>
    </section>
}

const entitySummaryTitles: Record<LegalEntityType, string> = {
    "Irrevocable Trust": "Irrevocable Trusts",
    "Irrevocable Life Insurance Trust (ILIT)": "Irrevocable Life Insurance Trusts (ILITs)",
    "Charitable Foundation": "Charitable Foundations",
    "Donor Advised Fund (DAF)": "Donor Advised Funds (DAFs)",
    "529 Plan": "529 Plans",
    "Dynasty Trust": "Dynasty Trusts",
    "LP, LLC, Other Partnership": "LPs, LLCs, Other Partnerships",
    "S Corp": "S Corps",
    "C Corp": "C Corps",
    "Other": "Others",
};

export default OutOfEstateAssetList;
