import {MeetingContainerSchema, useRelayContext} from "../ClientManagement/Meeting/Relay/types/RelayContext";
import React, {useEffect, useRef} from "react";
import {
    MeetingContentCanvas,
    MeetingContentScrollPositions,
    MultiPartMeetingContent,
    MultiPartMeetingContentDOM
} from "../ClientManagement/Meeting/Meeting";
import {
    getScrollHeight,
    getScrollWidth,
    setScrollLeft,
    setScrollTop
} from "../components/ScrollableContainer/ScrollableContainerUtils";
import {IValueChanged} from "fluid-framework";

export const SynchronizedMeetingContent: React.FC = () => {
    const {sharedObjects} = useRelayContext();
    const meetingContentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (sharedObjects) {
            const messageParts: Record<string, string[]> = {};
            const meetingContentCanvas: MeetingContentCanvas = {};
            let meetingContentScrollPositions: MeetingContentScrollPositions = {};

            sharedObjects.domContentDDS.on("valueChanged", (valueChanged: IValueChanged) => {
                switch (valueChanged.key) {
                    case 'meetingContentDOM':
                        redrawMeetingContentDOM(sharedObjects, meetingContentRef, messageParts, meetingContentCanvas, meetingContentScrollPositions);
                        if (meetingContentRef?.current) {
                            rescaleMeetingContent(meetingContentRef.current);
                        }
                        break;
                    case 'meetingContentScrollPositions':
                        redrawMeetingContentScrollPositions(sharedObjects, meetingContentRef, (scrollPositions) => {
                            meetingContentScrollPositions = scrollPositions;
                        });
                        break;
                }
            });

            sharedObjects.canvasContentDDS.on("valueChanged", (valueChanged: IValueChanged) => {
                redrawMeetingContentCanvas(valueChanged.key, sharedObjects, meetingContentRef, messageParts, meetingContentCanvas);
            });
        }
    }, [sharedObjects]);

    return <div className="meeting-content app-viewport--in-meeting"
                style={{pointerEvents: "none"}}
                ref={meetingContentRef}/>;
};


const redrawMeetingContentCanvas = (
    canvasQuerySelector: string,
    sharedObjects: MeetingContainerSchema,
    meetingContentRef: React.MutableRefObject<HTMLDivElement | null>,
    messageParts: Record<string, string[]>,
    meetingContentCanvas: MeetingContentCanvas
) => {
    const multiPartMeetingContent: MultiPartMeetingContent | undefined = sharedObjects.canvasContentDDS.get(canvasQuerySelector);
    if (multiPartMeetingContent) {
        assembleMessageParts(messageParts, multiPartMeetingContent, (assembledMessage) => {
            if (meetingContentRef.current) {
                hydrateCanvases({
                    [canvasQuerySelector]: assembledMessage
                }, meetingContentRef.current);
            }
            meetingContentCanvas[canvasQuerySelector] = assembledMessage;
        });
    }
}

const redrawMeetingContentDOM = (
    sharedObjects: MeetingContainerSchema,
    meetingContentRef: React.MutableRefObject<HTMLDivElement | null>,
    messageParts: Record<string, string[]>,
    meetingContentCanvas: MeetingContentCanvas,
    meetingContentScrollPositions: MeetingContentScrollPositions,
) => {
    const content: MultiPartMeetingContentDOM | undefined = sharedObjects.domContentDDS.get('meetingContentDOM');
    if (meetingContentRef.current && content) {
        assembleMessageParts(messageParts, content, (assembledMessage) => {
            if (meetingContentRef.current) {
                meetingContentRef.current.innerHTML = assembledMessage;
            }
        });
    }
    if (meetingContentRef.current && meetingContentCanvas) {
        hydrateCanvases(meetingContentCanvas, meetingContentRef.current, (canvasQuerySelector) => {
            delete meetingContentCanvas[canvasQuerySelector];
        });
    }
    if (meetingContentRef.current && meetingContentScrollPositions) {
        updateScrollPositions(meetingContentScrollPositions, meetingContentRef.current);
    }
}

const redrawMeetingContentScrollPositions = (
    sharedObjects: MeetingContainerSchema,
    meetingContentRef: React.MutableRefObject<HTMLDivElement | null>,
    setMeetingContentScrollPositions: (scrollPositions: MeetingContentScrollPositions) => void,
) => {
    const scrollPositions: MeetingContentScrollPositions | undefined = sharedObjects.domContentDDS.get('meetingContentScrollPositions');
    if (meetingContentRef.current && scrollPositions) {
        updateScrollPositions(scrollPositions, meetingContentRef.current);
        setMeetingContentScrollPositions(scrollPositions);
    }
};

const assembleMessageParts = (
    messageParts: Record<string, string[]>,
    multiPartMeetingContent: MultiPartMeetingContent,
    assembledMessageCallback: (assembledMessage: string) => void
) => {
    const parts = messageParts[multiPartMeetingContent.messageId]
        || new Array(multiPartMeetingContent.totalParts).fill(undefined);
    parts[multiPartMeetingContent.part] = multiPartMeetingContent.content;
    // Check if the totalParts are present
    if (parts.every((part) => typeof part !== 'undefined')) {
        // Combine the parts then reset messageParts
        assembledMessageCallback(parts.join(''));
        delete messageParts[multiPartMeetingContent.messageId];
    } else {
        messageParts[multiPartMeetingContent.messageId] = parts;
    }
};

const hydrateCanvases = (
    meetingContentCanvas: MeetingContentCanvas,
    meetingContentElement: HTMLDivElement,
    handleCanvasNotFound?: (canvasQuerySelector: string) => void
) => {
    if (meetingContentCanvas) {
        for (let [querySelector, canvasDataURL] of Object.entries(meetingContentCanvas)) {
            const canvasElement: HTMLCanvasElement | null = meetingContentElement.querySelector(`.meeting-content ${querySelector}`);
            if (canvasElement) {
                const canvasContext = canvasElement.getContext('2d');
                if (canvasContext) {
                    const canvasImage = new Image();
                    canvasImage.onload = () => {
                        canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
                        canvasContext.drawImage(canvasImage, 0, 0);
                    };
                    canvasImage.src = canvasDataURL;
                }
            } else if (handleCanvasNotFound) {
                handleCanvasNotFound(querySelector);
            }
        }
    }
};

const updateScrollPositions = (
    meetingContentScrollPositions: MeetingContentScrollPositions,
    meetingContentElement: HTMLDivElement,
) => {
    if (meetingContentScrollPositions) {
        for (let [querySelector, scrollState] of Object.entries(meetingContentScrollPositions)) {
            const scrollContainer: HTMLCanvasElement | null = meetingContentElement.querySelector(`.meeting-content ${querySelector}`);
            if (scrollContainer) {
                setScrollLeft(scrollContainer, scrollState.horizontalScrollPercentage * getScrollWidth(scrollContainer));
                setScrollTop(scrollContainer, scrollState.verticalScrollPercentage * getScrollHeight(scrollContainer));
            }
        }
    }
};

const rescaleMeetingContent = (meetingContentRefCurrent: HTMLDivElement) => {

    const windowSize = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    const presentationWidth = 1366;
    const presentationHeight = 1024;

    const footerHeight = 64;

    const windowHeightRatio = (windowSize.height - footerHeight) / presentationHeight;
    const windowWidthRatio = windowSize.width / presentationWidth;

    const scale = Math.min(
        windowHeightRatio,
        windowWidthRatio
    );

    const updatedStyle = (windowHeightRatio > 1 && windowWidthRatio > 1) ?
        {
            scale: 1,
            widthPixelShift: 0,
            heightPixelShift: 0,
        } :
        {
            scale: scale,
            widthPixelShift: (presentationWidth - windowSize.width) / 2,
            heightPixelShift: (presentationHeight - (windowSize.height - footerHeight)) / 2,
        }

    meetingContentRefCurrent.style.transform = `translate(${-updatedStyle.widthPixelShift}px,${-updatedStyle.heightPixelShift}px) scale(${updatedStyle.scale})`;
}
