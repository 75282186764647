import React from "react";
import {Accordion, AccordionItem} from "../../../components";
import {GoalType} from "../../../Goals/models/GoalType";
import NonLifestyleGoalListHeader from "../../../Goals/Summary/NonLifestyleGoalListHeader";
import {formatCurrency} from "../../../utils/format";
import FamilyGoalUtils from "../../../Goals/Summary/FamilyGoalUtils";
import {FamilySummaryColumnHeader} from "../../../Goals/Summary/SummaryFamilyGoalList";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import FamilyGoalsSummaryItems from "../../../Goals/Summary/FamilyGoalsSummaryItems";

interface SummaryFamilyGoalListReportProps {
    goalModel: GoalModelType,
    label: string
}

const SummaryFamilyGoalListReport = ({goalModel, label}: SummaryFamilyGoalListReportProps) => {
    const {
        familyGoals,
        discretionaryGoals,
        philanthropicGoals,
        investorGroup
    } = goalModel;

    return (
        <>
            <section>
                <div className="accordion-tables" data-testid={`${GoalType.FAMILY}-goal-list`}>
                    <Accordion
                        expanded={["FamilyGoalReport"]}
                        accordionId="SummaryFamilyGoalListAccordionReport"
                        allowMultipleExpanded
                        allowZeroExpanded
                    >
                        {(discretionaryGoals.length === 0 && philanthropicGoals.length === 0) &&
                            <NonLifestyleGoalListHeader/>}
                        <AccordionItem
                            uuid="FamilyGoalReport"
                            accentColor="#FFC24B"
                            primaryText={label}
                            rightAlignedContent={
                                <div className="display-flex align-items-center"
                                     data-testid="family-goal-accordion-item">
                                            <span className="font-lg paddingright-xl">
                                                {formatCurrency(FamilyGoalUtils.getCumulativeTotalPresentValue(familyGoals))}
                                            </span>
                                </div>
                            }
                        >
                            <FamilySummaryColumnHeader/>
                            <FamilyGoalsSummaryItems familyGoals={familyGoals} investorGroup={investorGroup}/>
                        </AccordionItem>
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default SummaryFamilyGoalListReport;
