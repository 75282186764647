/* data types */
import {Member} from "../../ClientManagement/models/InvestorGroupType";

export type OwnershipWriteModel = {
    ownershipCategory: OwnershipCategory,
    memberOwnerships: MemberOwnershipWriteModel[],
    legalEntityOwnerships: LegalEntityOwnershipWriteModel[]
}

export type OwnershipReadModel = {
    ownershipCategory: OwnershipCategory,
    memberOwnerships: MemberOwnership[],
    legalEntityOwnerships: LegalEntityOwnership[],
}

export interface HasPercentageNumber {
    percentage: number,
}

export interface MemberOwnershipWriteModel extends HasPercentageNumber {
    memberId: string,
    isRevocableTrust: boolean,
}

export interface MemberOwnership extends HasPercentageNumber {
    member: Member,
    isRevocableTrust: boolean,
    isInEstateMember: boolean
}

export interface LegalEntityOwnership extends HasPercentageNumber {
    legalEntity: LegalEntityReadModel,
    showWithinOutOfEstateReport: boolean,
}

export interface LegalEntityOwnershipWriteModel extends HasPercentageNumber {
    legalEntity: {
        type: LegalEntityType,
        name: string,
    }
    showWithinOutOfEstateReport: boolean
}

export interface LegalEntityReadModel {
    id: string,
    name: string,
    type: LegalEntityType
}

export interface LegalEntityFormData {
    name: string,
    type?: LegalEntityType
}


/* UI types */
export type OwnershipDetailsFormData = {
    ownershipCategory: OwnershipCategory,
    soleOwnerType?: SoleOwnerType,
    memberOwnerships: MemberOwnershipFormData[],
    nonClientMemberOwnerships: NonClientMemberOwnershipFormData[],
    legalEntityOwnerships: LegalEntityOwnershipFormData[],
};

export interface HasPercentageString {
    percentage: string,
}

export type MemberOwnershipFormData = {
    memberId: string,
    isRevocableTrust: boolean,
    percentage: string,
};

export type NonClientMemberOwnershipFormData = {
    memberId: string,
    isRevocableTrust: boolean,
    percentage: string,
}

export interface LegalEntityOwnershipFormData extends HasPercentageString {
    type?: LegalEntityType,
    name: string,
    legalEntityId?: string,
    showWithinOutOfEstateReport: boolean,
}

export const ownershipCategoriesForSingleMemberProfile = Object.freeze([
    "Sole",
    "TIC (Tenants in Common)",
    "Partial"
] as const);

export const ownershipCategories = Object.freeze([
    ...ownershipCategoriesForSingleMemberProfile,
    "JTWROS (Joint Tenants with Rights of Survivorship)",
    "Community Property",
] as const);

export type OwnershipCategory = typeof ownershipCategories[number];

export const legalEntityTypes = Object.freeze([
    "Irrevocable Trust",
    "Irrevocable Life Insurance Trust (ILIT)",
    "Charitable Foundation",
    "Donor Advised Fund (DAF)",
    "529 Plan",
    "Dynasty Trust",
    "LP, LLC, Other Partnership",
    "S Corp",
    "C Corp",
    "Other",
] as const);
export type LegalEntityType = typeof legalEntityTypes[number];

export const soleIndividualOwnerType = Object.freeze([
    "Individual"
] as const);

export const soleOwnerTypes = Object.freeze([
    ...soleIndividualOwnerType,
    ...legalEntityTypes
] as const);


export type SoleOwnerType = typeof soleOwnerTypes[number];
