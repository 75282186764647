import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
    addFamilyGoal,
    deleteFamilyGoal,
    selectGoalModel,
    updateLifestyleSpendingGoal, updateNonLifestyleGoalReducer
} from "../../controller/GoalsModelSlice";
import {FamilyGoalsType, FamilyGoalType} from "../../models/FamilyGoalType";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {GoalModelType} from "../../models/GoalModelType";
import FamilyGoalUtils from "../../Summary/FamilyGoalUtils";
import AddNewFamilyGoalBarChartSideBar from "../../BarChartSidebar/AddNewFamilyGoalBarChartSideBar";
import {MemberType} from "../../../ClientManagement/models/MemberType";
import {FamilyGoalService} from "../FamilyGoalService";
import {RouteWithId} from "../../../routes/types";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import DataEntryFooter from "../../../components/DataEntry/DataEntryFooter";
import {goalsApiClient} from "../../GoalsApiClient";
import NonlifestyleGoalLivePreview from "../../NonLifestyleGoals/NonlifestyleGoalLivePreview";
import NonLifestyleGoalForm from "../../components/NonLifestyleGoalForm";
import {GoalType, NonLifestyleGoalType} from "../../models/GoalType";
import DeleteGoalModal from "src/Goals/components/DeleteGoalModal";
import {LifestyleSpendingGoal} from "../../models/LifestyleSpendingGoal";
import DiscardModal from "../../../components/DiscardModal/DiscardModal";
import {HistoryBlockModal} from "../../../components/HistoryBlockModal/HistoryBlockModal";


const AddFamilyGoal = ({isEditing = false}: { isEditing?: boolean }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {
        investorGroup,
        proposal,
        discountRateSelection,
        lifestyleSpendingGoal,
    } = useAppSelector<GoalModelType>(selectGoalModel)
    const {id: profileId} = useParams<RouteWithId>();

    const selectedGoalSubType: FamilyGoalsType = history.location.state.selectedGoalSubType
    const memberSelectedInHistory: boolean = !!history.location.state.memberSelected
    const savedGoalInHistory: boolean = !!history.location.state.savedGoal;
    const portfolioReserveIsSet = proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null;

    let memberSelected: MemberType;
    let familyGoalForState: FamilyGoalType;
    let lifestyleGoalState: LifestyleSpendingGoal = lifestyleSpendingGoal;

    if (savedGoalInHistory) {
        familyGoalForState = history.location.state.savedGoal;
        memberSelected = FamilyGoalUtils.getBeneficiaryFromProfile(proposal, familyGoalForState.beneficiaryId);
    } else if (memberSelectedInHistory && !savedGoalInHistory) {
        familyGoalForState = FamilyGoalUtils.initializeFamilyGoal(profileId, history.location.state.memberSelected.id, selectedGoalSubType, investorGroup)
        memberSelected = history.location.state.memberSelected
    } else {
        throw new Error("Goal Not Found, must pass a Goal or a Beneficiary")
    }

    const [familyGoal, replaceFamilyGoal] = useState<FamilyGoalType>(familyGoalForState)
    const [lifestyleGoal, replaceLifestyleGoal] = useState<LifestyleSpendingGoal>(lifestyleGoalState)

    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [originalGoalState] = useState(familyGoalForState);
    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        dispatch(updateNonLifestyleGoalReducer(originalGoalState));
        history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`, {forceNavigate: true});
    };

    const handleCancelButtonClick = () => {
        if (familyGoal === familyGoalForState) {
            history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`, {forceNavigate: true});
        } else {
            setShowCancelModal(true);
        }
    }

    const handleSaveButtonClick = async () => {
        FamilyGoalService.saveFamilyGoal(profileId, familyGoal).then(savedFamilyGoalResponse => {
            dispatch(addFamilyGoal({goal: savedFamilyGoalResponse, member: memberSelected}))
            dispatch(updateLifestyleSpendingGoal(lifestyleGoal));
            history.push(`/Profile/${profileId}/ClientProfile/Goals/FamilyGoal`, {
                ...history.location.state,
                memberSelected
            });
        })
    }

    const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);

    const handleOnDeleteClick = async () => {
        const response = await goalsApiClient.deleteFamilyGoal(familyGoal.id!);
        if (response.status === 204) {
            dispatch(deleteFamilyGoal({goal: familyGoal}))
            history.goBack()
        }
    }

    const handleUpdateFamilyGoal = (updatedGoal: NonLifestyleGoalType) => {
        replaceFamilyGoal(FamilyGoalUtils.convertFamilyNonLifestyleGoalTypeToFamilyGoalType(updatedGoal))
    }

    return (
        <div className="layout-split-sidebar">
            <DiscardModal
                itemType={"Goal"}
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleModalDiscardButton}
                isEditing={isEditing}/>
            <HistoryBlockModal
                when={
                    JSON.stringify(originalGoalState) !== JSON.stringify(familyGoalForState)
                }
                itemType="goal"
                onSave={handleSaveButtonClick}
                onDiscard={handleCancelButtonClick}
            />

            <AddNewFamilyGoalBarChartSideBar familyGoalFromForm={familyGoal} lifestyleSpendingGoalFromForm={lifestyleGoal}/>
            <div className='layout-data-entry-form add-new-goal-entry-form'>
                <DataEntryHeader
                    className="add-family-goals_title"
                    title={`${savedGoalInHistory ? 'Edit ' : 'Add '}` + familyGoal.userInputs.selectedGoalSubType}
                    primaryButtonText="Save"
                    onPrimaryButtonClick={handleSaveButtonClick}
                    secondaryButtonText="Cancel"
                    onSecondaryButtonClick={handleCancelButtonClick}
                />
                <div className="family-goal-container">
                    <NonLifestyleGoalForm
                        nonLifestyleGoal={FamilyGoalUtils.convertFamilyGoalTypeToNonLifestyleGoalType(familyGoal)}
                        replaceNonLifestyleGoal={handleUpdateFamilyGoal}
                        typeDropdownItems={FamilyGoalUtils.getFamilyGoalsTypeDropdownItems()}
                        discountRateSelection={discountRateSelection}
                        lifestyleGoal={lifestyleGoal}
                        replaceLifestyleGoal={replaceLifestyleGoal}
                        portfolioReserveIsSet={portfolioReserveIsSet}
                    />
                    <NonlifestyleGoalLivePreview nonLifestyleGoal={familyGoal}/>
                </div>
                {
                    savedGoalInHistory &&
                    <DataEntryFooter
                        className="color-text--error"
                        hideDuplicate={true}
                        hideAddNew={true}
                        sticky={false}
                        onDelete={() => setShowDeleteGoalModal(true)}
                    />
                }
            </div>
            <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                             onCancel={() => setShowDeleteGoalModal(false)}
                             onDelete={handleOnDeleteClick}
                             goalType={GoalType.FAMILY}
                             description={familyGoal.userInputs.description}/>
        </div>
    )
}

export default AddFamilyGoal
