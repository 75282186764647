import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {assetsApiClient} from "../../AssetsApiClient";
import {
    EMPTY_INVESTMENT_PROGRAM_RESPONSE,
    InvestmentProgram,
    InvestmentProgramsResponse
} from "../../models/InvestmentProgram";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {useAppInsights} from "../../../AppInsights";
import AddInvestmentProgram from "./AddInvestmentProgram";
import NoInvestmentProgram from "./NoInvestmentProgram";
import AssetsViewContext from "../../common/AssetsViewContext";

export type SearchParams = {
    team: boolean,
    page: number,
    pageSize: number,
    groupName?: string,
    portfolioManager?: string
};

export default function AddInvestmentProgramContainer() {
    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);
    const appInsights = useAppInsights();
    const [myInvestmentPrograms, setMyInvestmentPrograms] = useState<InvestmentProgramsResponse>(EMPTY_INVESTMENT_PROGRAM_RESPONSE);
    const [teamInvestmentPrograms, setTeamInvestmentPrograms] = useState<InvestmentProgramsResponse>(EMPTY_INVESTMENT_PROGRAM_RESPONSE);
    const [receivedInvestmentProgramResponses, setReceivedInvestmentProgramResponses] = useState<boolean>(false);
    const [isValidatingBeforeSaving, setIsValidatingBeforeSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasAccessToInvestmentProgram, setHasAccessToInvestmentProgram] = useState(true);
    const [displayNoMatchFound, setDisplayNoMatchFound] = useState(false);

    useEffect(() => {
        appInsights.startTrackPage("ViewInvestmentProgram");
        setIsLoading(true);
        Promise.all([
            assetsApiClient.getInvestmentPrograms(profile.id, {team: false, page: 1, pageSize: 25}),
            assetsApiClient.getInvestmentPrograms(profile.id, {team: true, page: 1, pageSize: 25})
        ])
            .then(([myInvestmentProgramResponse, teamInvestmentProgramResponse]) => {
                setMyInvestmentPrograms(myInvestmentProgramResponse);
                setTeamInvestmentPrograms(teamInvestmentProgramResponse);
                setReceivedInvestmentProgramResponses(true);
                setHasAccessToInvestmentProgram(myInvestmentProgramResponse.investmentPrograms.length > 0 || teamInvestmentProgramResponse.investmentPrograms.length > 0)
            })
            .catch(reason => console.log(reason))
            .finally(() => {
                stopTrackingViewInvestmentProgram();
                setIsLoading(false);
            });
    }, []);

    const stopTrackingViewInvestmentProgram = () => {
        return () => {
            appInsights.stopTrackPage("ViewInvestmentProgram", window.location.href, {
                profileId: profile.id,
            });
        };
    }

    const onSave = (investmentProgram: InvestmentProgram, isTeam: boolean) => {
        if (investmentProgram) {
            appInsights.startTrackPage("AddInvestmentProgram")
            setIsValidatingBeforeSaving(true);

            assetsApiClient.postInvestmentProgram({
                investmentProgramId: investmentProgram.id,
                profileId: profile.id,
                isTeam: isTeam,
            }).then(response => {
                setIsValidatingBeforeSaving(false);
                if (response.status === 201) {
                    history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
                }
            }).catch(reason => {
                setIsValidatingBeforeSaving(false);

                if (reason.status == 409) {
                    setErrorMessage('Sorry, we are unable to add the Investment Program to the Profile at this time because it is linked to another Profile.')
                } else if (reason.status == 404) {
                    setErrorMessage('Sorry, we are unable to add the Investment Program to the Profile at this time.')
                }
            }).finally(() =>
                appInsights.stopTrackPage("AddInvestmentProgram", window.location.href, {
                    investmentProgramId: investmentProgram.id
                })
            );
        }
    }

    const onSearch = (searchParams: SearchParams) => {
        setIsLoading(true);
        setDisplayNoMatchFound(false);
        assetsApiClient.getInvestmentPrograms(profile.id, searchParams)
            .then(investmentProgramsResponse => {
                setDisplayNoMatchFound(investmentProgramsResponse.investmentPrograms.length === 0);
                if (searchParams.team) {
                    setTeamInvestmentPrograms(investmentProgramsResponse);
                } else {
                    setMyInvestmentPrograms(investmentProgramsResponse);
                }
            })
            .catch(reason => console.log(reason))
            .finally(() => {
                setIsLoading(false);
            })
    }

    const onCancel = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    };

    if (!receivedInvestmentProgramResponses) {
        return <LoadingIndicator />;
    }

    if (isValidatingBeforeSaving) {
        return <LoadingIndicator
            displayMessage={"Validating Investment Program and loading Legal Agreements..."}/>;
    }

    if (!hasAccessToInvestmentProgram) {
        return (
            <NoInvestmentProgram onCancel={onCancel} />
        )
    }

    return (
        <div className="investment-programs">
            <AddInvestmentProgram
                onSearch={onSearch}
                onCancel={onCancel}
                onSave={onSave}
                errorMessage={errorMessage}
                myInvestmentPrograms={myInvestmentPrograms}
                teamInvestmentPrograms={teamInvestmentPrograms}
                isLoading={isLoading}
                displayNoMatchFound={displayNoMatchFound}
            />
        </div>
    )
}
