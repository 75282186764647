import {Icon, NumberInput, Popover} from "../../../components";
import React, {ChangeEvent, FocusEvent, useEffect, useState} from "react";
import {DataDisplayView, ProposedAllocationEntry} from "../ReviseAssetAllocation";
import {isDollarView} from "./reviseAssetAllocationUtils";
import classNames from "classnames";

export type ReviseAllocationInputProps = {
    overrideValue: number;
    assetSubclassName: string;
    showIpcRangeWarning: boolean;
    updateProposedAllocationEntry: (value: ProposedAllocationEntry) => void;
    disabled: boolean;
    displayView: DataDisplayView;
    totalProposedAmount: number;
};

const ReviseAllocationInput = (props: ReviseAllocationInputProps) => {
    const {
        overrideValue,
        assetSubclassName,
        showIpcRangeWarning,
        updateProposedAllocationEntry,
        disabled,
        displayView,
        totalProposedAmount
    } = props;
    const [proposedAllocationValue, setProposedAllocationValue] = useState<string | null>("0");

    useEffect(() => {
        setProposedAllocationValue(overrideValue?.toString() || null);
    }, [overrideValue]);

    const handleChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number | string) => {
        setProposedAllocationValue(value as string);
    }

    const calculateProposedPercentage = (proposedAllocationAmount: number) => {
        if(totalProposedAmount !== 0) {
            let quotient = (proposedAllocationAmount/totalProposedAmount).toFixed(4);
            return (+quotient * 100);
        }
        return 0;
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        const {value} = event.target;
        const formattedValue = value.replace(/[$,]/g, '');

        setProposedAllocationValue(formattedValue);

        if(parseFloat(formattedValue) == overrideValue) return;

        let proposedAllocationEntry: ProposedAllocationEntry;
        if (isDollarView(displayView)) {
            proposedAllocationEntry = {
                assetSubClass: assetSubclassName,
                proposedAllocationPercent: null,
                proposedAllocationAmount: parseFloat(formattedValue) || 0,
                excludeSubclass: false,
                excludedProposedAllocation: calculateProposedPercentage(parseFloat(formattedValue)),
                editedManually: true
            };
        } else {
            proposedAllocationEntry = {
                assetSubClass: assetSubclassName,
                proposedAllocationPercent: parseFloat(value.trim()) || 0,
                proposedAllocationAmount: null,
                excludeSubclass: false,
                excludedProposedAllocation: parseFloat(value.trim()) || 0,
                editedManually: true
            };
        }
        updateProposedAllocationEntry(proposedAllocationEntry);
    }

    return (
        <div className="display-flex justify-content-between align-content-center">
            {isDollarView(displayView) ? <NumberInput
                    removeMarginTop
                    aria-label={`${assetSubclassName} Proposed $`}
                    size="small"
                    minValue={0}
                    maxValue={totalProposedAmount}
                    maxLength={15}
                    allowDecimals
                    formatOptions={{
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "decimal",
                    }}
                    value={proposedAllocationValue}
                    onChangeValue={handleChangeValue}
                    onBlur={handleBlur}
                    tabIndex={0}
                    className={classNames({"warning-bg": showIpcRangeWarning && !disabled})}
                    disabled={disabled}
                /> :
                <NumberInput
                    removeMarginTop
                    aria-label={`${assetSubclassName} Proposed %`}
                    size="small"
                    minValue={0}
                    maxValue={100}
                    formatOptions={{
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "decimal",
                    }}
                    allowDecimals
                    value={proposedAllocationValue}
                    onChangeValue={handleChangeValue}
                    onBlur={handleBlur}
                    tabIndex={0}
                    className={classNames({"warning-bg": showIpcRangeWarning && !disabled})}
                    disabled={disabled}
                />}
            {showIpcRangeWarning && !disabled &&
                <Popover
                    aria-label={`${assetSubclassName} IPC Out Of Range Warning Message`}
                    content="This value is out of IPC range. Proposed allocation will still be able to be saved."
                    direction="bottom"
                    width="203px"
                >
                    <Icon className="ipc-range-info-icon" name="status_warning" ariaHidden={false}
                          ariaLabel="IPC Out Of Range Info Icon"/>
                </Popover>
            }
        </div>
    );
};

export default ReviseAllocationInput;
