import React from 'react';
import NoGoalsLandingPage from "./NoGoalsLandingPage";
import GoalList from "./GoalList";
import {useAppSelector} from "../../store/hooks";
import SummaryBarChartSideBar from "../BarChartSidebar/SummaryBarChartSideBar";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {GoalModelType} from "../models/GoalModelType";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";

type SummaryProps = {
    errorModelData?: GenericErrorModalData
}

const Summary = ({errorModelData}: SummaryProps) => {
    const {
        lifestyleSpendingGoal,
        familyGoals,
        discretionaryGoals,
        philanthropicGoals
    } = useAppSelector<GoalModelType>(selectGoalModel)

    const clientHasFamilyGoals = !!familyGoals && Object.values(familyGoals).length > 0
    const clientHasLifestyleGoals = !!lifestyleSpendingGoal.id;
    const clientHasNonLifestyleGoals = [...discretionaryGoals, ...philanthropicGoals].some(nonLifestyleGoal => !!nonLifestyleGoal.id);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const clientHasSavedGoals = clientHasLifestyleGoals || clientHasFamilyGoals || clientHasNonLifestyleGoals;
    const handleErrorCloseButton = () => {
        errorModelData!.isOpen = false;
        setGenericError({...genericError, isOpen: false});
    }
    return (
        <ScrollableContainer className="goals-summary-container layout-split-sidebar">
            {errorModelData?.isOpen ?
                <GenericErrorModal
                    errorModalData={errorModelData}
                    onClickClose={() => handleErrorCloseButton()}
                    closeButtonText={'Close'}/> : <React.Fragment/>
            }
            <SummaryBarChartSideBar/>
            {clientHasSavedGoals ? <GoalList/> : <NoGoalsLandingPage/>}
        </ScrollableContainer>
    )
}

export default Summary;
