import {NonLifestyleGoalType} from "../Goals/models/GoalType";
import {BeneficiaryGoals, BeneficiaryGoalsResponse, TaxLiabilities} from "../Goals/models/GoalModelType";
import {FamilyGoalType} from "../Goals/models/FamilyGoalType";
import {LifestylePresentValueResponse} from "../Goals/models/PresentValue";


export type InvestableSufficiencyArguments = {
    nonLifestyleGoals: NonLifestyleGoalType[],
    familyGoals: { [key: string]: BeneficiaryGoals },
    totalInvestableValue: number,
    lifestylePresentValue: LifestylePresentValueResponse,
    taxLiabilities: TaxLiabilities
}
export const isInvestablySufficient = (sufficiencyArgs: InvestableSufficiencyArguments): boolean => getNetInvestableValue(sufficiencyArgs) > 0;
export const portfolioReserveIsInvestablySufficient = (sufficiencyArgs: InvestableSufficiencyArguments): boolean => {
    return (getNetInvestableValue(sufficiencyArgs) + sufficiencyArgs.lifestylePresentValue.presentValueForInsufficientYears) > 0;
}

export const getNetInvestableValue = ({
                                          nonLifestyleGoals,
                                          familyGoals,
                                          totalInvestableValue,
                                          lifestylePresentValue,
                                          taxLiabilities
                                      }: InvestableSufficiencyArguments): number => {
    let portfolioAlignedGoalsAndLiabilitiesValue: number = getPortfolioAlignedGoalsAndLiabilitiesValue({
        nonLifestyleGoals,
        familyGoals,
        lifestylePresentValue,
        taxLiabilities
    });
    return (totalInvestableValue - (portfolioAlignedGoalsAndLiabilitiesValue + lifestylePresentValue.presentValueForInsufficientYears))
}

export type getPortfolioAlignedGoalsAndLiabilitiesArguments = Omit<InvestableSufficiencyArguments, "totalInvestableValue">;

export const getPortfolioAlignedGoalsAndLiabilitiesValue = ({
                                                                nonLifestyleGoals,
                                                                familyGoals,
                                                                lifestylePresentValue,
                                                                taxLiabilities
                                                            }: getPortfolioAlignedGoalsAndLiabilitiesArguments): number => {
    let goalsFundedByInvestablesPresentValue = 0
    nonLifestyleGoals
        .forEach((nonLifestyleGoal: NonLifestyleGoalType) => {
            goalsFundedByInvestablesPresentValue += (nonLifestyleGoal.userInputs.isFundedByNonInvestableAssets ? 0 : nonLifestyleGoal.calculatedFields.presentValue)
        })
    Object.values(familyGoals)
        .forEach((beneficiaryGoal: BeneficiaryGoalsResponse) => {
            Object.values(beneficiaryGoal.goals)
                .forEach((fg: FamilyGoalType) => {
                    goalsFundedByInvestablesPresentValue += (fg.userInputs.isFundedByNonInvestableAssets ? 0 : fg.calculatedFields.presentValue)
                })
        })

    return (lifestylePresentValue.requiredPortfolioSpendingPresentValue + goalsFundedByInvestablesPresentValue + taxLiabilities.totalTaxLiabilitiesFundedByPortfolio)
}