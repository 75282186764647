import React from 'react';
import {useAppSelector} from "../../store/hooks";
import {formatCurrency} from "../../utils/format";
import GoalBarChartSideBar from "./GoalBarChartSidebar";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {BarChartSummaryTable, BarChartSummaryTableItem} from "../../components/BarChartSidebar/BarChartSummaryTable";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {BeneficiaryGoals, GoalModelType} from "../models/GoalModelType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {GoalBarChartKeyColors} from "./GoalBarChartKeyColors";

export function buildFamilyGoalTableItems(memberSelected: MemberType, familyGoals: GoalModelType['familyGoals']): BarChartSummaryTableItem[] {

    const makeTableItem = (beneficiaryGoals: BeneficiaryGoals, isActive: boolean | number = false): BarChartSummaryTableItem => ({
        leftText: beneficiaryGoals.name,
        rightText: formatCurrency(beneficiaryGoals.totalPresentValue),
        isActive: typeof isActive === "boolean" ? isActive : false,
        keyColor: GoalBarChartKeyColors.FAMILY
    });

    const defaultBeneficiary: BeneficiaryGoals = {
        beneficiaryId: "",
        birthdate: null,
        age: memberSelected.age,
        goals: {},
        name: `${memberSelected.firstName} ${memberSelected.lastName}`,
        totalPresentValue: 0
    };

    //To handle scenario where no family goal is saved
    if (Object.keys(familyGoals).length === 0) {
        return [makeTableItem(defaultBeneficiary, true)]
    }

    let activeBeneficiary = Object.values(familyGoals)
        .find((beneficiaryGoals: BeneficiaryGoals) => beneficiaryGoals.beneficiaryId === memberSelected.id)

    if (!activeBeneficiary) {
        activeBeneficiary = defaultBeneficiary;
    }

    const summaryTableItems = Object.values(familyGoals)
        .filter((beneficiaryGoals: BeneficiaryGoals) => beneficiaryGoals.beneficiaryId !== memberSelected.id)
        .sort((bg1: BeneficiaryGoals, bg2: BeneficiaryGoals) => Date.parse(bg2.birthdate!).valueOf() - Date.parse(bg1.birthdate!).valueOf())
        .map(makeTableItem)

    summaryTableItems.unshift(makeTableItem(activeBeneficiary, true))

    return summaryTableItems
}


interface FamilyGoalBarChartSideBarProps {
    memberSelected: MemberType
}

function FamilyGoalBarChartSideBar({memberSelected}: FamilyGoalBarChartSideBarProps) {
    const {familyGoals} = useAppSelector<GoalModelType>(selectGoalModel)

    return (
        <GoalBarChartSideBar
            familyGoals={familyGoals}
        >
            <BarChartSummaryTable
                leftHeader="Family Goals"
                rightHeader={formatCurrency(FamilyGoalUtils.getCumulativeTotalPresentValue(familyGoals))}
                items={buildFamilyGoalTableItems(memberSelected, familyGoals)}
            />
        </GoalBarChartSideBar>)
}

export default FamilyGoalBarChartSideBar;

