import React, {useEffect, useState} from 'react';
import {InvestorGroupType, MemberGroup, MemberGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {assetsApiClient} from "../../../Assets/AssetsApiClient";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {
    resetClientAssets,
    selectClientAssets, setClientAssets,
} from "../../../Assets/clientAssetsSlice";
import {FamilyRelationshipType} from "../../../ClientManagement/models/FamilyRelationshipType";
import {LifeStatus} from "../../../ClientManagement/models/MemberType";
import {SECOND_TO_DIE, SECOND_TO_DIE_ID} from "../../../Assets/models/LifeInsurance";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFamilyTree} from "../../../ClientManagement/FamilyTree/FamilyTreeSlice";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import useProfileAndProposals from "../../../hooks/useProfileAndProposals";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import { CurrentNetWorthReport } from '../CurrentNetWorthReport/CurrentNetWorthReport';
import AssetSummaryReport from '../AssetSummaryDetailReport/AssetSummaryReport';
import { ReportConfig } from 'src/Reporting/models/Reporting';

type AssetsReportsProps = {
    reportConfig: ReportConfig
}
const AssetsReports = ({ reportConfig }: AssetsReportsProps) => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const {approvedProfile, profile} = useProfileAndProposals(id);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, setLegalEntities] = useState<LegalEntityReadModel[]>();
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();

    const clientAssets = useAppSelector(selectClientAssets);
    const familyTree = useAppSelector(selectFamilyTree);

    const activeProfileId = profile?.id;
    const isLoadingProfile = !activeProfileId;
    const displayName = approvedProfile.displayName;
    
    useEffect(() => {

        if (isLoadingProfile) return;
        if (!isDataLoaded) {
            Promise.all([
                assetsApiClient.getAssetsSummary(activeProfileId),
                clientManagementApiClient.getInvestorGroup(activeProfileId),
                assetsApiClient.getLegalEntities(activeProfileId),
                clientManagementApiClient.getMemberGroup(activeProfileId)
            ])
                .then(([assetsResponse, investorGroupResponse, legalEntitiesResponse, memberGroupResponse]) => {
                    dispatch(setClientAssets(assetsResponse));
                    setInvestorGroup(investorGroupResponse);
                    setLegalEntities(legalEntitiesResponse);
                    let additionalMembers: MemberGroupMember[] = [...memberGroupResponse.additionalMembers];
                    let isSecondToDiePresent = false;
                    if ((familyTree !== null && familyTree!.primaryContact !== null && familyTree!.primaryContact.family.length > 0)) {
                        isSecondToDiePresent = familyTree!.primaryContact.family.some(member => ((member.type === FamilyRelationshipType.SPOUSE || member.type === FamilyRelationshipType.EX_SPOUSE) && (member.fromMember.lifeStatus === LifeStatus.Living)));
                    }
                    if (isSecondToDiePresent) {
                        additionalMembers.push({
                            relationshipType: null,
                            id: SECOND_TO_DIE_ID,
                            firstName: SECOND_TO_DIE,
                            lastName: "",
                            lifeStatus: LifeStatus.Living,
                            deceasedDate: null,
                            age: 0
                        });
                    }
                    setMemberGroup({...memberGroupResponse, additionalMembers: [...additionalMembers]});

                })
                .catch(error => console.error('Could not fetch asset summary data', error.message))
            setIsDataLoaded(true);

        }
    }, [approvedProfile]);

    useEffect(() => {
        return function cleanup() {
            dispatch(resetClientAssets());
        }
    }, []);

    if (isLoadingProfile || !clientAssets || !investorGroup || !legalEntities) {
        return <LoadingIndicator/>;
    }

    return <>
        {reportConfig.assetsSummary.isEnabled && <AssetSummaryReport
            isDetailed={false}
            displayName={displayName}
            investorGroup={investorGroup!}
            profileId={profile.id}
            memberGroup={memberGroup}
            legalEntities={legalEntities!}
        />}
        {reportConfig.assetsSummaryDetailed.isEnabled && <AssetSummaryReport
            isDetailed={true}
            displayName={displayName}
            investorGroup={investorGroup!}
            profileId={profile.id}
            memberGroup={memberGroup}
            legalEntities={legalEntities!}
        />}
        {reportConfig.currentNetWorth.isEnabled && <CurrentNetWorthReport
            showDeathBenefit={false}
            isDetailed={false}
            displayName={displayName}
            investorGroup={investorGroup!}
            legalEntities={legalEntities!}
        />}
        {reportConfig.currentNetWorthDetailed.isEnabled && <CurrentNetWorthReport
            showDeathBenefit={false}
            isDetailed={true}
            displayName={displayName}
            investorGroup={investorGroup!}
            legalEntities={legalEntities!}
        />}
        {reportConfig.currentNetWorthAtDeath.isEnabled && <CurrentNetWorthReport
            showDeathBenefit={true}
            isDetailed={false}
            displayName={displayName}
            investorGroup={investorGroup!}
            legalEntities={legalEntities!}
        />}
        {reportConfig.currentNetWorthAtDeathDetailed.isEnabled && <CurrentNetWorthReport
            showDeathBenefit={true}
            isDetailed={true}
            displayName={displayName}
            investorGroup={investorGroup!}
            legalEntities={legalEntities!}
        />}
    </>
}

export default AssetsReports;
