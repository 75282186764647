import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/main.scss';
import {MsalProvider} from "@azure/msal-react";
import {initializeMsalInstance} from "./MsalUtils";
import {createStore} from "./store/store";
import {Provider} from "react-redux";

const msalInstance = initializeMsalInstance();

if (process.env.NODE_ENV !== 'production') {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={createStore()}>
            <MsalProvider instance={msalInstance}>
                <App/>
            </MsalProvider>
        </Provider>
    </React.StrictMode>, document.getElementById('root'));