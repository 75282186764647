/* data types */

import {TaxStatusOptions} from "./TaxDetails";
import {Optional} from "../../utils/customTypes";
import {OwnershipDetailsFormData, OwnershipReadModel, OwnershipWriteModel} from "./Ownership";
import {Asset} from "./Asset";
import {LiabilityDetails} from "./PersonalLiability";

export type InvestmentProgramsResponse = {
    totalPages: number,
    pageNumber: number,
    pageSize: number,
    totalInvestmentPrograms: number,
    investmentPrograms: InvestmentProgram[],
}

export const EMPTY_INVESTMENT_PROGRAM_RESPONSE: InvestmentProgramsResponse = {
    totalPages: 0,
    pageNumber: 1,
    pageSize: 25,
    totalInvestmentPrograms: 0,
    investmentPrograms: []
};

export type InvestmentProgram = {
    name: string,
    id: string,
    portfolioManagerName: string,
    status: string,
    lastUpdatedDate: string,
    totalMarketValue: number,
    legalAgreements: LegalAgreement[],
};

export type LegalAgreement = Asset & {
    name: string,
    asOfDate: string,
    legalAgreementNumber: string,
    legalAgreementTypeCode: string,
    institution: string,
    marketValue: number,
    taxStatus: TaxStatusOptions,
    permitBeneficiary: boolean | null,
    accountNumbers: string[],
    financialAccountPersistentIds: string[],
    entityType: string | null,
    isEntityCapitalGains: boolean | null,
    isLiabilityPaidByPortfolio: boolean | null,
    personalLiabilities: LiabilityDetails[],
}

/*---------Network Types-------------*/

export type CreateInvestmentProgramRequest = {
    investmentProgramId: string,
    profileId: string,
    isTeam: boolean,
};

export type UpdateLegalAgreementRequest = Pick<LegalAgreement, 'name' | 'isEntityCapitalGains' |
    'isLiabilityPaidByPortfolio'> & OwnershipWriteModel;


type LegalAgreementWithMaybeId = Optional<LegalAgreement, 'id'>

type NonFormDataFields = 'profileId' | 'personalLiabilities' | 'inEstateValue' | 'marketValue' | keyof OwnershipReadModel;

export type LegalAgreementFormData =
    Omit<LegalAgreementWithMaybeId, NonFormDataFields>
    & OwnershipDetailsFormData;