import {GeneralInflow} from "../../models/GeneralInflow";
import {DeleteModalProps} from "../../common/AssetsView";
import React from "react";
import {shortenString} from "../../common/textUtils";
import {FutureInflowsPopover} from "./FutureInflowsPopover";
import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {DropResult} from "react-beautiful-dnd";
import {sortByOrdinalAndPresentValue} from "../common/utils";

type GeneralInflowTableProps = {
    generalInflows: GeneralInflow[];
    onEdit: (assetId: string) => void;
    onDelete: (deleteModalProps: DeleteModalProps) => void;
    hideActionMenu?: boolean;
    isDraggable?: boolean;
    captureGeneralInflowsOrder: ({source, destination}: DropResult) => void
}

export function GeneralInflowTable({
                                       generalInflows,
                                       onEdit,
                                       onDelete,
                                       hideActionMenu = false,
                                       isDraggable = false,
                                       captureGeneralInflowsOrder
                                   }: GeneralInflowTableProps) {

    const sortedGeneralInflows = sortByOrdinalAndPresentValue(generalInflows) as GeneralInflow[]

    function handleDelete(assetId: string, description: string) {
        onDelete({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: description,
            assetType: "general inflow",
            assetId: assetId,
        })
    }

    if (isDraggable) {
        return <>
            <div role="row" className="grid-display-contents asset-row-subheader">
                <span role="cell" className="textalign-left grid-span-3 cell-text-content"><b>General Inflows</b></span>
            </div>
            <DragDrop
                className="draggable"
                direction="vertical"
                draggableId="generalInflowDraggableId1"
                droppableId="generalInflowDroppableId1"
                onDragEnd={captureGeneralInflowsOrder}
                items={
                    sortedGeneralInflows?.map((generalInflow) =>
                        <div className="display-flex align-items-right" tabIndex={-1} key={generalInflow.id}>
                            <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                            {buildGeneralInflowsTableRow(generalInflow, onEdit, handleDelete, hideActionMenu)}
                        </div>)
                }
            >
            </DragDrop>
        </>
    }
    return <>
        <div role="row" className="grid-display-contents asset-row-subheader">
            <span role="cell" className="textalign-left grid-span-3 cell-text-content"><b>General Inflows</b></span>
        </div>
        {sortedGeneralInflows?.map((generalInflow) => buildGeneralInflowsTableRow(generalInflow, onEdit, handleDelete, hideActionMenu))}
    </>;
}

function buildGeneralInflowsTableRow(generalInflow: GeneralInflow,
                                     handleClickOnEditGeneralInflow: (id: string) => void,
                                     handleClickOnDeleteGeneralInflow: (id: string, description: string) => void,
                                     hideActionMenu: boolean) {
    return <div key={`general-inflows-row-${generalInflow.id}`} role="row"
                className="assets-grid-with-actionmenu assets-grid-table-row row-within-subheading" tabIndex={0}
                aria-label={`general inflow - ${generalInflow.id}`}>
        <div role="cell" className='textalign-left display-flex'>
            <div className="cell-with-popover">
                <div className='cell-text-content'>
                    {shortenString(generalInflow.description, 23)}
                </div>
                <FutureInflowsPopover futureInflow={generalInflow}/>
            </div>
        </div>
        <span role="cell"
              className='textalign-right cell-text-content'>{formatCurrency(generalInflow.netAnnualFlow)}
            <div className="sublabel-12px cell-text-content">{formatCurrency(generalInflow.grossAnnualFlow)}</div>
        </span>

        <span role="cell" className='textalign-right cell-text-content'>{generalInflow.yearsUntilFlow}</span>
        <span role="cell" className='textalign-right cell-text-content'>{generalInflow.yearsOfFlow}</span>
        <span role="cell"
              className='textalign-right cell-text-content'>{formatCurrency(generalInflow.presentValue)}</span>

        {!hideActionMenu &&
            <TableActionDropdownMenu ariaLabel={`${generalInflow.description} Menu`}>
                <DropdownItem
                    itemText="Edit Asset"
                    value="Edit Asset"
                    onClick={() => handleClickOnEditGeneralInflow(generalInflow.id!)}
                />
                <DropdownItem
                    itemText="Delete Asset"
                    value="Delete Asset"
                    onClick={() => handleClickOnDeleteGeneralInflow(generalInflow.id!, generalInflow.description)}
                >
                    <Icon name="delete" className="asset-table-delete-icon"/>
                </DropdownItem>
            </TableActionDropdownMenu>}
    </div>;
}