import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import classNames, {Mapping as ClassNamesMapping} from "classnames";
import {COLOR_NT_GREY_550} from "../../../constants/colors";
import {Icon, TableRow} from "../../../components";

export interface CellClassParamsReport {
    isBlank_: boolean;
    columnIndex_: number;
    rowIndex_?: number;
}

interface TableRowPropsForReport<ValueType, TR extends TableRow<ValueType>> {
    additionalCellClasses_?: (params: CellClassParamsReport) => ClassNamesMapping[];
    ariaLabel_?: string;
    indentTitle_?: boolean;
    row_: TR;
    onClick_?: () => void;
    isSummaryRow_?: boolean;
    rowIndex_?: number;
    onTitleCellMouseEnter_?: (row: TR, rowIndex?: number) => void;
    onTitleCellMouseLeave_?: (row: TR) => void;
    valueColumnSize_?: number;
    isExpanded_: boolean;
    titleCellWidth_: number;
}

const NO_OP_ARRAY_REPORT = () => [];

export const TableRowComponentReport = <ValueType, TR extends TableRow<ValueType>>({
                                                                                 additionalCellClasses_ = NO_OP_ARRAY_REPORT,
                                                                                 ariaLabel_,
                                                                                 indentTitle_,
                                                                                 row_,
                                                                                 onClick_,
                                                                                 isSummaryRow_,
                                                                                 rowIndex_,
                                                                                 onTitleCellMouseEnter_,
                                                                                 onTitleCellMouseLeave_,
                                                                                 valueColumnSize_,
                                                                                 isExpanded_,
                                                                                 titleCellWidth_
                                                                             }: TableRowPropsForReport<ValueType, TR>,) => {
    const [icon, setIconForReport] = useState<string>('');

    useEffect(() => {
        if (onClick_) {
            if (isExpanded_) {
                setIconForReport('chevron_down');
            } else {
                setIconForReport('chevron_right');
            }
        } else {
            setIconForReport('');
        }
    }, [onClick_, isExpanded_]);

    return (
        <div className="table-row-container">
            <Row className={classNames("row", {"summary-row": isSummaryRow_})}
                 role={`row ${!!onClick_ ? 'button' : ''}`}
                 aria-label={ariaLabel_}
                 style={{flexFlow: "nowrap"}}
                 onClick={() => {
                     if (onClick_) {
                         onClick_();
                     }
                 }}>
                <Col role="column" className={classNames("col title", additionalCellClasses_({
                    isBlank_: false,
                    columnIndex_: -1,
                    rowIndex_: rowIndex_,
                }))}
                     width={titleCellWidth_}
                     style={{minWidth: titleCellWidth_}}
                     onMouseEnter={() => {
                         if (onTitleCellMouseEnter_) {
                             onTitleCellMouseEnter_(row_, rowIndex_);
                         }
                     }}
                     onMouseLeave={() => {
                         if (onTitleCellMouseLeave_) {
                             onTitleCellMouseLeave_(row_);
                         }
                     }}
                >
                    <RowAccent_ color_={row_.accentColor}/>
                    <Icon name={icon} size="large"/>
                    <div className={classNames("table-row-title-container", {"indent-title": indentTitle_})}>
                        <div className="table-row-title">
                            {row_.label}
                            {row_.labelSuffix &&
                                <span className="marginleft-sm"
                                      style={{
                                          fontStyle: 'italic',
                                          color: COLOR_NT_GREY_550
                                      }}
                                >{row_.labelSuffix}</span>}
                        </div>
                        {row_.subtitle && <div className="sub-title">{row_.subtitle}</div>}
                    </div>
                </Col>
                {row_.values.map((cellValue_, columnIndex_) => {
                    return <Col role="column" key={`${columnIndex_}-${cellValue_.originalValue}`}
                                className={classNames("col",
                                    {"empty-cell": cellValue_.isBlank},
                                    additionalCellClasses_({
                                        isBlank_: cellValue_.isBlank,
                                        columnIndex_: columnIndex_,
                                        rowIndex_: rowIndex_
                                    }),
                                    cellValue_.className,
                                )}
                                md={valueColumnSize_ ?? 1.5}>
                        <div className='rendered-value'>{cellValue_.renderedValue}</div>
                    </Col>
                })}
            </Row>
        </div>)
}

interface RowAccentProps_ {
    color_: string;
}

const RowAccent_: React.FC<RowAccentProps_> = ({color_}) => {
    return (
        <div
            aria-label="accordion accent color"
            className="accent"
            style={{backgroundColor: color_}}
        />
    )
}