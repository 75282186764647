import {LiabilityDetails} from "../../models/PersonalLiability";
import Popover from "@nt/dds-react/core/Popover";
import {formatCurrency} from "../../../utils/format";
import {Icon} from "../../../components";
import React from "react";

export function LiabilitiesPopover(props: { id: string, liabilitySummaries: LiabilityDetails[] }) {
    return <Popover
        id={`liabilities-popover-${props.id}`}
        delay={0}
        content={
            <div>
                <i>This asset is serving as collateral for <br/> the following liabilities:</i>
                <hr/>
                {props.liabilitySummaries.map((liability) => (
                    <div key={`liability-summary-popover-${liability.description}`}
                         className="asset-summary-popover-content">
                        <span>{liability.description}</span>
                        <span>{formatCurrency(liability.loanBalance)}</span>
                    </div>
                ))}
            </div>
        }
        direction="bottom"
        aria-label="liabilities">
        <Icon className="popover-trigger popoverContainer" name="link" ariaHidden={false}
              ariaLabel="associated liabilities"/>
    </Popover>;
}
