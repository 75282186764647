import {formatCurrency} from "../../utils/format";
import {Table} from "xps-react";
import React, {useMemo} from "react";
import {LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {DropdownItem, TableActionMenu} from "../../components";
import {PlanningPeriodType} from "../../ClientManagement/models/InvestorGroupType";

type LifestyleSpendingTableProps = {
    lifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[];
    planningPeriod: PlanningPeriodType;
}

type LifestyleGoalRow = {
    name: string,
    annualFlowValue: string,
    yearsOfFlowValue: string,
    ageAtStartValue?: string,
}

const tableStyleDefault = {
    expanderDefaults: {width: 32},
    rowHeight: 'large',
    resizable: false,
    showPagination: false,
    sortable: false,
};


function getAgeAtStart(planningPeriod: PlanningPeriodType, spendingPeriod: LifestyleSpendingPeriodInputs) {
    const yearDiff = planningPeriod.startYear - spendingPeriod.startYear;
    return planningPeriod.ageFrom - yearDiff;
}

function getYearsOfFlow(spendingPeriod: LifestyleSpendingPeriodInputs) {
    return spendingPeriod.endYear - spendingPeriod.startYear;
}

const LifestyleSpendingTable = ({planningPeriod, lifestyleSpendingPeriods}: LifestyleSpendingTableProps) => {
    const memoizedTable = useMemo(() => {
        const groupedLifestyleGoalData: LifestyleGoalRow[] = lifestyleSpendingPeriods.map((spendingPeriod) => {
            return {
                name: spendingPeriod.description,
                annualFlowValue: formatCurrency(spendingPeriod.annualSpend),
                yearsOfFlowValue: `${(getYearsOfFlow(spendingPeriod))}`,
                ageAtStartValue: `${(getAgeAtStart(planningPeriod, spendingPeriod))}`
            };
        });
        return <Table
            {...tableStyleDefault}
            {...lifestyleSpendingTableColumns}
            defaultPageSize={1_000_000}
            data={groupedLifestyleGoalData}
        />;
    }, [planningPeriod, lifestyleSpendingPeriods]);

    return <div>{memoizedTable}</div>;
}

const lifestyleSpendingTableColumns = {
    columns: [
        {
            minWidth: 30,
            maxWidth: 30,
        },
        {
            accessor: 'name',
            Cell: ({original}: { original: LifestyleGoalRow }) => {
                return (
                    <div className="display-flex flex-column justify-content-center height-100p">
                        <span className="table-label-15px">{original.name}</span>
                    </div>
                );
            },
            Header: 'Name'
        },
        {
            accessor: 'annualFlow',
            Cell: ({original}: { original: LifestyleGoalRow }) => {
                return (
                    <div className="display-flex flex-column justify-content-center align-items-end height-100p">
                        <span className="table-label-15px">{original.annualFlowValue}</span>
                    </div>
                );
            },
            Header: "Annual Flow",
            headerClassName: 'display-flex justify-content-end',
            maxWidth: 128,
        },
        {
            accessor: 'yearsOfFlow',
            Cell: ({original}: { original: LifestyleGoalRow }) => {
                return (
                    <div className="display-flex flex-column justify-content-center align-items-end height-100p">
                        <span className="table-label-15px">{original.yearsOfFlowValue}</span>
                    </div>
                );
            },
            Header: 'Years Of Flow',
            headerClassName: 'display-flex justify-content-end',
            maxWidth: 128,
        },
        {
            accessor: 'ageAtStart',
            Cell: ({original}: { original: LifestyleGoalRow }) => {
                return (
                    <div className="display-flex flex-column justify-content-center align-items-end height-100p">
                        <span className="table-label-15px">{original.ageAtStartValue}</span>
                    </div>
                );
            },
            Header: 'Age At Start',
            headerClassName: 'display-flex justify-content-end',
            maxWidth: 128,
        },
        {
            accessor: 'id',
            className: "overflow-visible",
            minWidth: 32,
            maxWidth: 32,
            Cell: ({original}: { original: LifestyleGoalRow }) => {
                const history = useHistory();
                const {id} = useParams<RouteWithId>();

                function handleClickOnEditLifestyleSpending() {
                    history.push(`/Profile/${id}/ClientProfile/Goals/EditLifestyleSpending`);
                }

                return (
                    <span className="table-action-menu">
                        <TableActionMenu className="paddingright-0" panelWidth={240}>
                            <DropdownItem
                                id="editLifestyleSpending"
                                onClick={() => handleClickOnEditLifestyleSpending()}
                                itemText={`Edit ${original.name}`}
                                value={`${original.name}`}
                            />
                        </TableActionMenu>
                    </span>
                )
            }
        }
    ]
};


export default LifestyleSpendingTable;
