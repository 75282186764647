import React from 'react';
import classNames from 'classnames';
import NumberInput, {defaultFormatOptions, NumberInputProps} from "./NumberInput";

type CurrencyInputProps = NumberInputProps;

export default function CurrencyInput({
                                          className,
                                          formatOptions = defaultFormatOptions,
                                          error,
                                          ...rest
                                      }: CurrencyInputProps) {
    return (
        <NumberInput
            {...rest}
            className={classNames('currency-field', className)}
            inlineLabel="$"
            inlineLabelAlign="left"
            formatOptions={{...formatOptions, style: 'decimal'}}
            error={error}
        />
    )
};
