import React, {useContext, useEffect, useState} from 'react';
import {assetsApiClient} from "../../../AssetsApiClient";
import {AllOrApprovedProduct, Product} from "../../../models/Product";
import DataEntryHeader from "../../../../components/DataEntry/DataEntryHeader";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "../../../../routes/types";
import {useAppSelector} from "../../../../store/hooks";
import {selectProfile} from "../../../../ClientManagement/ClientProfile/activeProfileSlice";
import LoadingIndicator from "../../../../pages/LoadingIndicator";
import {StandaloneAccount} from "../../../models/StandaloneAccount";
import {RequiredFieldsBanner} from "../../../../components";
import {EstateValue, HoldingAssetSubclassDetails, HoldingType} from "../../../models/Holding";
import {isEmpty} from "../../../../utils/stringUtils";
import {EditableHoldingFields} from "../EditHoldingValues";
import HoldingsInfo from "../HoldingsInfo";
import {InvestorGroupType} from "../../../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../../../ClientManagement/ClientManagementApiClient";
import AddProduct from './AddProduct';
import Holdings from './Holdings';
import {AssetClassifications} from "../../../models/AssetClassifications";
import AssetsViewContext from "../../../common/AssetsViewContext";
import {allSelectedAssetClassNames, mapHoldingAssetSubclassDetailsToHoldingWriteModel} from '../holdingUtils';
import {DropdownOnChangeData} from "../../../../components/Dropdown/Dropdown";
import {selectReleaseToggles} from "../../../../ReleaseToggles/releaseTogglesSlice";

export default function AddProducts() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {assetId: accountId} = useParams<RouteWithAssetId>();
    const profile = useAppSelector(selectProfile);
    const [standaloneAccount, setStandaloneAccount] = useState<StandaloneAccount>();
    const [editableHoldings, setEditableHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const [hasInvalidProducts, setHasInvalidProducts] = useState<boolean>(false);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [focusOnField, setFocusOnField] = useState<EditableHoldingFields>();
    const [assetClassNamesBySubclassDetailId, setAssetClassNamesBySubclassDetailId] = useState<Map<string, string>>(new Map());
    const [expandedAssetClasses, setExpandedAssetClasses] = useState<string[]>([]);
    const [allOrApprovedProduct, setAllOrApprovedProduct] = useState<AllOrApprovedProduct>("allProducts");

    useEffect(() => {
        let componentUnmounted = false;
        Promise.all([
            assetsApiClient.getAccount(profile.id, accountId),
            clientManagementApiClient.getInvestorGroup(profile.id),
            assetsApiClient.getAssetClassifications()
        ]).then(([account, investorGroupResponse, assetClassifications]) => {
            if (componentUnmounted) return;
            setStandaloneAccount(account);
            setEditableHoldings(account.holdings.allHoldings);
            setInvestorGroup(investorGroupResponse);
            setClassifications(assetClassifications);
            setAssetClassNamesBySubclassDetailId(mapSubclassDetailIdsToAssetNames(assetClassifications))
            setExpandedAssetClasses(allSelectedAssetClassNames(assetClassifications, account.holdings.allHoldings));
        }).catch(error => console.error('Could not fetch asset details', error.message));
        return () => {
            componentUnmounted = true;
        }
    }, [accountId]);

    function navigateToHoldingSummary() {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/StandaloneAccount/${accountId}/Holdings`);
    }

    const onDiscardClick = () => {
        history.goBack();
    };

    async function onSave() {
        if (hasInvalidHoldings(editableHoldings)) {
            setHasInvalidProducts(true);
        } else {
            await assetsApiClient.postHoldings(
                profile.id,
                accountId,
                editableHoldings.map(editableHolding => mapHoldingAssetSubclassDetailsToHoldingWriteModel(editableHolding)));
            navigateToHoldingSummary();
        }
    }

    function hasInvalidHoldings(holdings: HoldingAssetSubclassDetails[]) {
        return holdings.some(holding => !isValidHolding(holding));
    }

    function isValidHolding(holding: HoldingAssetSubclassDetails) {
        return holding.productName && holding.productName.trim().length > 0;
    }

    const handleAllOrApprovedProduct = (allOrApproved: DropdownOnChangeData<AllOrApprovedProduct>) => {
        setAllOrApprovedProduct(allOrApproved.value);

    }

    function handleProductSelected(product: Product) {
        if (isEmpty(product.assetSubclassDetailId)) return;

        setFocusOnField('taxCost');
        setEditableHoldings([
            ...editableHoldings,
            {
                productId: allOrApprovedProduct === "allProducts" ? product.productId : undefined,
                productCode: allOrApprovedProduct === "approvedProducts" ? product.productId : undefined,
                assetSubclassDetailsId: product.assetSubclassDetailId,
                marketValue: null,
                marketEstateValue: {
                    inEstateValue: 0,
                    outOfEstateValue: 0,
                    totalValue: 0
                },
                taxCost: null,
                investableValue: null,
                locked: false,
                concentrated: false,
                assetSubclassDetailsName: product.assetSubclassDetail,
                productName: product.name,
                ticker: product.ticker,
                cusip: product.cusip,
                type: allOrApprovedProduct === "allProducts" ? HoldingType.Product : HoldingType.ApprovedProduct
            }
        ]);

        ensureAssetClassExpanded(product.assetSubclassDetailId);
    }

    function handleAddCustomProduct(assetSubclassDetail: HoldingAssetSubclassDetails) {
        if (isEmpty(assetSubclassDetail.assetSubclassDetailsId)) return;

        setFocusOnField('productName');
        setEditableHoldings([
            ...editableHoldings,
            {
                productId: assetSubclassDetail.productId,
                assetSubclassDetailsId: assetSubclassDetail.assetSubclassDetailsId,
                marketValue: null,
                marketEstateValue: {
                    inEstateValue: 0,
                    outOfEstateValue: 0,
                    totalValue: 0
                },
                taxCost: null,
                investableValue: null,
                locked: false,
                concentrated: false,
                assetSubclassDetailsName: assetSubclassDetail.assetSubclassDetailsName,
                productName: assetSubclassDetail.productName,
                ticker: "",
                cusip: "",
                type: HoldingType.CustomProduct
            }
        ]);

        ensureAssetClassExpanded(assetSubclassDetail.assetSubclassDetailsId);
    }

    function ensureAssetClassExpanded(assetSubclassDetailId: string) {
        const assetClassName = assetClassNamesBySubclassDetailId.get(assetSubclassDetailId);

        if(assetClassName && !expandedAssetClasses.includes(assetClassName)) {
            setExpandedAssetClasses([
                ...expandedAssetClasses,
                assetClassName
            ]);
        }
    }

    function mapSubclassDetailIdsToAssetNames(assetClasses: AssetClassifications) {
        const map = new Map<string, string>();

        assetClasses.riskAssetClasses.concat(assetClasses.riskControlAssetClasses).forEach(assetClass => {
            assetClass.assetSubclasses.forEach(assetSubclass => {
                assetSubclass.assetSubclassDetails.forEach(assetSubclassDetails => {
                    map.set(assetSubclassDetails.assetSubclassDetailsId, assetClass.assetClassName);
                });
            });
        });

        return map;
    }

    function handleDeleteHolding(indexToBeDeleted: number) {
        const updatedHoldings = [
            ...editableHoldings.slice(0, indexToBeDeleted),
            ...editableHoldings.slice(indexToBeDeleted + 1, editableHoldings.length),
        ];

        setEditableHoldings(updatedHoldings);
    }

    function handleUpdateHolding(value: number | string | null | undefined | EstateValue,
                                    index: number,
                                    valueType: EditableHoldingFields) {
        const updatedHoldings = [
            ...editableHoldings.slice(0, index),
            {
                ...editableHoldings[index],
                [valueType]: value,
            },
            ...editableHoldings.slice(index + 1, editableHoldings.length),
        ];

        setEditableHoldings(updatedHoldings);

        if (!hasInvalidHoldings(updatedHoldings)) {
            setHasInvalidProducts(false);
        }
    }

    if (!standaloneAccount || !investorGroup || !classifications) {
        return <LoadingIndicator/>;
    }

    const inEstateOwnershipPercentage = standaloneAccount.memberOwnerships
        .filter(mo => mo.isInEstateMember)
        .reduce((previous, ownership) => {
            return previous + ownership.percentage;
        }, 0);

    const handleSearch = (searchTerm: string) => assetsApiClient.searchProducts(profile.id, searchTerm, allOrApprovedProduct)

    return (
        <div id="searchTypeAheadSection" className="products-container holdings-container">
            <DataEntryHeader
                title={`Edit Products - ${(standaloneAccount?.name)}`}
                primaryButtonText="Save"
                onPrimaryButtonClick={onSave}
                secondaryButtonText="Discard Changes"
                onSecondaryButtonClick={onDiscardClick}
            />
            <RequiredFieldsBanner showAlert={hasInvalidProducts}
                                  message="Product name must be entered to save a Product."/>
            <HoldingsInfo account={standaloneAccount}
                          primaryMember={investorGroup.primaryMember}
                          partnerMember={investorGroup.partnerMember}
                          allHoldings={editableHoldings}
                          classifications={classifications}
            />

            <section className="add-product">
                <AddProduct
                    onPerformSearch={handleSearch}
                    onProductSelect={handleProductSelected}
                    onAddCustomProduct={handleAddCustomProduct}
                    customProductClassifications={classifications}
                    onChangeAllOrApprovedProductDropdown={handleAllOrApprovedProduct}
                    defaultAllOrApprovedProduct={allOrApprovedProduct}
                    enableApprovedProducts={releaseToggles!.enableApprovedProducts}/>
            </section>

            <section>
                <Holdings
                    selectedHoldings={editableHoldings}
                    hasInvalidProducts={hasInvalidProducts}
                    isValidHolding={isValidHolding}
                    onUpdateHolding={handleUpdateHolding}
                    assetClassifications={classifications}
                    inEstateOwnershipPercentage={inEstateOwnershipPercentage}
                    focusOnIndex={focusOnField ? (editableHoldings.length - 1) : undefined}
                    focusOnField={focusOnField}
                    expanded={expandedAssetClasses}
                    onChange={items => setExpandedAssetClasses(items ?? [])}
                    onDeleteHolding={handleDeleteHolding}
                />
            </section>
        </div>
    )
}