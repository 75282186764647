import React, {useEffect, useState} from "react";
import DisclaimerWrapper from "./DisclaimerWrapper";
import {reportingApiClient} from "./ReportingApiClient";
import {DisclaimerTableData} from "./models/Reporting";
import {EMPTY_DISCLAIMER_TABLE_DATA} from "../Resources/__testUtils__/generators";

type DisclaimerPagesProps = {
    displayName: string
}

const DisclaimerPages = ({displayName}: DisclaimerPagesProps) => {

    const [disclaimerData, setDisclaimerData] =
        useState<DisclaimerTableData>(EMPTY_DISCLAIMER_TABLE_DATA);

    useEffect(() => {
        (async () => {
            const disclaimerTableData = await reportingApiClient.getDisclaimerData();
            setDisclaimerData(disclaimerTableData);
        })();
    }, []);

    return (
        <>
            <DisclaimerWrapper displayName={displayName} title="Disclaimers" keyValue="page1">
                <DisclaimerPage1/>
            </DisclaimerWrapper>
            <DisclaimerWrapper displayName={displayName} title="Disclaimers" subtitle="(continued)" keyValue="page2">
                <DisclaimerPage2 disclaimerData={disclaimerData}/>
            </DisclaimerWrapper>
            <DisclaimerWrapper displayName={displayName} title="Disclaimers" subtitle="(continued)" keyValue="page3">
                <DisclaimerPage3/>
            </DisclaimerWrapper>
        </>
    );
};
export default DisclaimerPages;

const DisclaimerPage1: React.FC = () => {
    return (
        <>
            <div className="column">
                <div className="paragraph">The Northern Trust Goals Powered SolutionsTM (“GPS”) application uses an
                    interactive and dynamic approach to portfolio planning and asset liability matching. The application
                    integrates the characteristics of each client’s unique and changing financial goals with the
                    client’s time horizon and risk preference. The process is done on an interactive basis with the
                    client.</div>
                <div className="paragraph">The GPS application allows the client to view different simulations based on
                    changes to the time horizon and risk preference related to their specific goals and objectives. A
                    client’s risk preference can be customized and present itself in the amount of risk control
                    allocated to a specific goal or objective. The GPS application is part of a dynamic planning
                    collaboration between the client and advisor through time.</div>
                <div className="paragraph">The Portfolio Reserve and Capital Allocation assume a portfolio that is spent
                    down over time. You understand that the Goals Driven Wealth Management process is dynamic – i.e., as
                    your goals change and investment returns are realized, the portfolio strategy will be continually
                    updated to meet new requirements and address evolving risk preferences through time.</div>
                <div className="paragraph">Core Funding Goals are defined in four broad categories:
                    <ul>
                        <li className="pointer">Core Lifestyle: annual spending;</li>
                        <li className="pointer">Discretionary: future purchases such as second homes, aircraft, art,
                            etc.;
                        </li>
                        <li className="pointer">Family: transfers of wealth to subsequent generations to achieve
                            specific objectives;
                        </li>
                        <li className="pointer">Philanthropy: transfers of wealth to fund charitable, social, community,
                            or political objectives.
                        </li>
                    </ul></div>
            </div>
            <div className="column">
                <div className="paragraph">Client goals are matched to a portfolio that reflects an asset mix of risk
                    control and risk assets that assumes a glide path, over time, from risk assets exposure moving to
                    risk control exposure as the year of goal funding approaches. The glide path is based on two
                    empirical funding poles: 100% risk control for goals through the next two years and 100% risk assets
                    for goals intended 15 years and beyond. In general, as the goal moves closer to the date of funding,
                    allocations to risk assets are gradually reduced while allocations to risk control are gradually
                    increased.</div>
                <div className="paragraph">The Portfolio Reserve is the component of the risk control allocation that is
                    matched against the Lifestyle category of your Core Funding Goals; it represents the number of years
                    of consumption spending intended to be matched with risk control.</div>
                <div className="paragraph">You will identify the length of time and the spending amount; Northern Trust
                    will calculate the Portfolio Reserve using a discounted cash flow methodology.</div>
                <div className="paragraph">The glide path is a defined formula whereby the asset allocation and discount
                    rate used to calculate the net present value of Core Funding Goals assumes a higher proportion of
                    risk control as goals move closer to the date of funding. The glide path determines the amount of
                    risk control and risk assets to be applied to Core Funding Goals through time.</div>
            </div>
            <div className="column">
                <div className="paragraph">The Goals Driven Wealth Management process is dependent upon and assumes,
                    without independent verification, the accuracy and completeness of all information available from
                    sources including client custodial account statements and direct client input. There is no guarantee
                    as to its accuracy, timeliness, or completeness. Clients are urged to review the values included in
                    their analysis.</div>
                <div className="paragraph">The Goals Driven Wealth Management process is not meant to be used as the sole
                    means of developing a financial plan. In addition, please contact your advisor if there have been
                    any changes to your financial situation or investment objectives, or if you wish to request or
                    modify any investment restrictions on the management of your account. Northern Trust makes no
                    warranty, express or implied, as to the future performance or benefits of a portfolio modeled and
                    managed in accordance with the Goal Driven Wealth Management process.</div>
                <div className="paragraph">The gross return and inflation assumptions used in the Northern Trust Goals
                    Powered SolutionsTM (“GPS”) application combine long-dated historical return data with forward-
                    looking capital market assumptions developed by Northern Trust’s Investment Policy Committee. The
                    application starts off with a geometric gross return assumption which is adjusted downward to
                    reflect personal effective tax rates on income and capital gains, an estimate for fees, and
                    inflation. The tax rate adjustment is not applied to tax-deferred and tax-exempt accounts. A
                    separate deferred tax liability is created that is equal to the product of your personal effective
                    tax rate and the total present value of each tax deferred account.</div>
            </div>
        </>
    )
}

type DisclaimerPage2Props = {
    disclaimerData: DisclaimerTableData
}
const DisclaimerPage2: React.FC<DisclaimerPage2Props> = ({disclaimerData}) => {
    return (
        <div className="disclaimer-page__wrapper">
            <div className="paragraph column">The table below represents the annual pre-tax statistical characteristics
                of
                the Northern Trust long-term, 20-year capital market assumptions that are used in the GPS
                application. Maximum Risk/Return provides characteristics for the accredited risk asset
                sub-portfolio, which includes U.S. equity, developed ex-U.S. equity, emerging markets, global real
                estate and infrastructure, natural resources, private investments, hedge funds and high-yield bonds.
                Minimum Risk/Return provides characteristics for the tax-optimized risk control assets sub-
                portfolio, which includes tax-exempt and inflation-protected bonds.
            </div>
            <div className="paragraph column">The geometric mean return displayed in the table is used in the GPS
                application
                and is the result of compounding historical and expected returns. To calculate the average or 50th
                percentile outcome for a one-year investment, historic volatility is added to the geometric mean
                return. This return is the arithmetic mean return displayed in the table on the previous page.
                Assuming capital market returns follow a normal distribution, the arithmetic mean return is the
                return for which there is a 50% chance one-year investment outcomes will be better than expected and
                a 50% chance that outcomes will be worse than expected based on static portfolios. The lowest 10%
                and highest 10% return thresholds in the table on the previous page are also based on the arithmetic
                mean return and assume a normal distribution of annual returns.
            </div>
            <div className="paragraph column">There are risks involved with investing, including possible loss of
                principal.
                Northern Trust makes no warranty, express or implied, as to the future performance or benefits of a
                portfolio modeled and managed in accordance with the Goal Driven Wealth Management process.
            </div>
            <div className="disclaimer-page__table_nested_grid column" role="table" aria-label="disclaimer-table">
                <div className="">
                    <div className="table_grid">
                        <DisclaimerTableHeader/>
                    </div>
                    {disclaimerData.returnRateValuesDtos.map(({
                                                                  type,
                                                                  minimumRiskReturn,
                                                                  lowRiskReturn,
                                                                  moderatelyLowRiskReturn,
                                                                  moderatelyRiskReturn,
                                                                  moderatelyHighRiskReturn,
                                                                  maximumRiskReturn
                                                              }) => {
                        return (
                            <div className="table_grid"
                                 role="row"
                                 aria-label={`${type} row`}
                                 key={type}>
                                <div className="table_header table_header__item1 table_row"
                                     role="cell"
                                     aria-label={`${type} name`}>{type}</div>
                                <div className="table_row"
                                     role="cell"
                                     aria-label={`${type} minimum`}> {minimumRiskReturn} </div>
                                <div className="table_row"
                                     role="cell"
                                     aria-label={`${type} low`}> {lowRiskReturn} </div>
                                <div className="table_row"
                                     role="cell"
                                     aria-label={`${type} moderately low`}> {moderatelyLowRiskReturn} </div>
                                <div className="table_row"
                                     role="cell"
                                     aria-label={`${type} moderately`}> {moderatelyRiskReturn} </div>
                                <div className="table_row"
                                     role="cell"
                                     aria-label={`${type} moderately high`}> {moderatelyHighRiskReturn} </div>
                                <div className="table_row"
                                     role="cell"
                                     aria-label={`${type} maximum`}> {maximumRiskReturn} </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

const DisclaimerPage3: React.FC = () => {
    return (
        <>
            <div className="column">
                <div className="paragraph"><b>Important Information Regarding Hypothetical Projections:</b> The return
                    assumptions used in the Northern Trust Goals Powered SolutionsTM (“GPS”) application are based on a
                    combination of historical data and forward-looking expectations, and may not be indicative of future
                    results. For the Core Funding Goals you identify in quantitative terms (see categories listed
                    above), Northern Trust will use a discounted cash flow methodology to calculate the present value of
                    the money that would have to be invested currently in specific asset types in order to fund those
                    goals over time periods you specify. The portfolio development process will be driven by a goals-
                    based asset-liability matching process. A systematic approach of matching suitable assets to the
                    achievement of those Core Funding Goals achieves a balance of enhancing the likelihood of reaching
                    those goals, and generating portfolio returns. The characteristics of your Core Funding Goals inform
                    the asset-liability matching process whereby each specific liability is aligned to a portfolio of
                    assets with intentional risk characteristics. This matching process determines the ratio of risk
                    control to risk assets. High yield fixed income is considered as an equity oriented risk asset in
                    the Goal Driven Wealth Management matching process.</div>
                <div className="paragraph"><b>Investment Universe:</b> Northern Trust’s software application considers as
                    fulfillment options those securities and investment vehicles – including proprietary funds -
                    formally approved by Northern Trust for use in discretionary investment management accounts.
                    Investments not considered may have characteristics similar or superior to those so approved. Where
                    applicable and appropriate, assets may be used to position the portfolio towards smaller
                    capitalization and value-oriented securities.</div>
            </div>
            <div className="column">
                <div className="paragraph"><b>For One-on-One Use Only:</b> This presentation is for your private
                    information and
                    is intended for one-on- one use with current or prospective clients of Northern Trust. The
                    information is not intended as investment advice or an offer or solicitation for the purchase or
                    sale of any security or financial instrument, and is subject to change without notice. Northern
                    Trust and its affiliates may have positions in, and may effect transactions in, the markets,
                    contracts and related investments described herein, which positions and transactions may be in
                    addition to, or different from, those taken in connection with the investments described herein. All
                    material has been obtained from sources believed to be reliable, but the accuracy, completeness and
                    interpretation cannot be guaranteed and should not be relied upon as such. It should not be assumed
                    that any investments were or will prove to be profitable.</div>
                <div className="paragraph"><b>Discretionary Investment Management Services:</b>
                    Northern Trust provides discretionary investment management to clients and institutions. Our
                    investment professionals create portfolios that reflect your particular investment goals based on
                    the financial information you share with us. Your portfolio manager makes the day-to-day decisions
                    regarding your investments in a disciplined manner that is consistent with your investment
                    objectives, risk parameters, and other specific preferences.</div>
            </div>
            <div className="column">
                <div className="paragraph"><b>IMPORTANT:</b> The projections or other information generated by Northern
                    Trust’s
                    software application regarding the likelihood of various investment outcomes are hypothetical in
                    nature, do not reflect actual investment results and are not guarantees of future results. Results
                    may vary with each use and over time.</div>
                <div className="paragraph"><b>LEGAL, INVESTMENT AND TAX NOTICE:</b> Information is not intended to be and
                    should
                    not be construed as an offer, solicitation or recommendation with respect to any transaction and
                    should not be treated as legal advice, investment advice or tax advice. Clients should under no
                    circumstances rely upon this information as a substitute for obtaining specific legal or tax advice
                    from their own professional legal or tax advisors.</div>
                <div className="paragraph"><b>Northern Trust Securities, Inc. is a member of the Financial Industry
                    Regulatory Authority (FINRA) and the Securities Investor Protection Corporation (SIPC).</b></div>
                <div className="paragraph"><b>NOT FDIC INSURED / NO BANK GUARANTEE / MAY LOSE VALUE</b></div>
            </div>
        </>
    )
}

const DisclaimerTableHeader: React.FC = () => {
    return (
        <>
            <div className="table_header__item1 table_header__row"></div>
            <div className="word_wrap table_header__row"> {"Minimum\nRisk / Return"} </div>
            <div className="word_wrap table_header__row"> {"Low\nRisk / Return"} </div>
            <div className="word_wrap table_header__row"> {"Moderately Low\nRisk / Return"} </div>
            <div className="word_wrap table_header__row"> {"Moderate\nRisk / Return"} </div>
            <div className="word_wrap table_header__row"> {"Moderately High\nRisk / Return"} </div>
            <div className="word_wrap table_header__row"> {"Maximum\nRisk / Return"} </div>
        </>
    )
}