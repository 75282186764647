import {AccordionHeader} from "./AccordionHeader";
import React, {ReactNode, useState} from "react";
import {Accordion} from "../../components";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectAccordionPreferencesStates} from "./accordionPreferencesSlice";
import {setAccordionState} from "../../components/Accordion/accordionSlice";

type AccordionWithHeaderProps = {
    accordionId: string,
    allItems: string[],
    columnTitles: string[],
    children: ReactNode,
    renderHeader: boolean
    headerProps?: {
        testId?: string,
        className?: string,
        gridSpanClass?: string
    },
    ignoreExpandedItems?: boolean
};

const AccordionWithHeader = ({
    accordionId,
    children,
    columnTitles,
    allItems,
    renderHeader,
    headerProps = {},
    ignoreExpandedItems = false
                             }: AccordionWithHeaderProps) => {

    const dispatch = useAppDispatch();
    const {accordionPreferencesStates} = useAppSelector(selectAccordionPreferencesStates);

    const [expandedItems, setExpandedItems] =
        useState(accordionPreferencesStates[accordionId]
            ? accordionPreferencesStates[accordionId].expandedItems
            : []);

    const areAllItemsExpanded = expandedItems.length === allItems.length;

    const handleAccordionHeaderToggle = () => {
        const itemsToExpand: string[] = areAllItemsExpanded ? [] : allItems;

        dispatch(setAccordionState({
            accordionId,
            state: {
                expandedItems: itemsToExpand
            }
        }));

        setExpandedItems(itemsToExpand);
    }

    const handleAccordionChange = (items: string[] | undefined = []) => {
        setExpandedItems(items);
    };

    return (
        <>
            {renderHeader && <AccordionHeader columnTitles={columnTitles}
                             onClick={handleAccordionHeaderToggle}
                             isExpanded={areAllItemsExpanded} {...headerProps}
            />}
            <Accordion
                accordionId={accordionId}
                allowMultipleExpanded allowZeroExpanded
                expanded={!!ignoreExpandedItems ? undefined : expandedItems}
                onChange={handleAccordionChange}
                persistAccordionPreferencesForProfile
            >
                {children}
            </Accordion>
        </>

    )
}

export default AccordionWithHeader;
