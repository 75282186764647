import React from 'react';
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import {NonLifestyleCumulativePresentValue} from "../../../Goals/Summary/NonLifestyleCumulativePresentValue";
import {GoalType} from "../../../Goals/models/GoalType";
import SummaryLifestyleGoalListReport from "./SummaryLifestyleGoalListReport";
import NonLifestyleGoalListReport from "./NonLifestyleGoalListReport";
import SummaryFamilyGoalListReport from "./SummaryFamilyGoalListReport";
import SummaryTaxLiabilitiesListReport from "./SummaryTaxLiabilitiesListReport";

interface GoalListReportProps {
    goalModel: GoalModelType,
    pageNo: number,
    goalsSummaryList: Array<GoalModelType>
}

const GoalListReport = ({goalModel, pageNo, goalsSummaryList}: GoalListReportProps) => {
    const {
        familyGoals,
        discretionaryGoals,
        philanthropicGoals,
        taxLiabilities,
        lifestyleSpendingGoal
    } = goalModel;
    const clientHasFamilyGoals = familyGoals && Object.values(familyGoals).length > 0;
    const clientHasTaxLiabilities = taxLiabilities.totalTaxLiabilities > 0;
    const displayHeaderDiscretionaryGoals = discretionaryGoals && (discretionaryGoals.length > 0);
    const displayHeaderPhilanthropicGoals = !displayHeaderDiscretionaryGoals && philanthropicGoals && (philanthropicGoals.length > 0);

    const isOtherGoals = clientHasFamilyGoals || displayHeaderDiscretionaryGoals || displayHeaderPhilanthropicGoals;

    const isGoalContinued = (key: keyof GoalModelType): string => {
        let labelContinue = "";

        if (key in goalModel) {
            goalsSummaryList.forEach((goal, index) => {
                if ((key in goal) && Object.values(goal[key]).length && pageNo > index) {
                    labelContinue = " (continued)";
                }
            });
        }

        return labelContinue;
    }

    return (
        <article>
            {lifestyleSpendingGoal['id'] && <SummaryLifestyleGoalListReport goalModel={goalModel}/>}
            {isOtherGoals && <div className="goal-list">
                {pageNo === 0 && <NonLifestyleCumulativePresentValue/>}
                {displayHeaderDiscretionaryGoals &&
                    <NonLifestyleGoalListReport label={`Discretionary Goals${isGoalContinued('discretionaryGoals')}`}
                                                type={GoalType.DISCRETIONARY}
                                                displayHeader={displayHeaderDiscretionaryGoals} goalModel={goalModel}/>}
                {philanthropicGoals.length > 0
                    && <NonLifestyleGoalListReport label={`Philanthropic Goals${isGoalContinued('philanthropicGoals')}`}
                                                   type={GoalType.PHILANTHROPIC}
                                                   displayHeader={displayHeaderPhilanthropicGoals}
                                                   goalModel={goalModel}/>}
                {clientHasFamilyGoals && <SummaryFamilyGoalListReport goalModel={goalModel}
                                                                      label={`Family Goal${isGoalContinued("familyGoals")}`}/>}
            </div>}
            {clientHasTaxLiabilities && <SummaryTaxLiabilitiesListReport goalModel={goalModel}/>}
        </article>
    )
}

export default GoalListReport;
