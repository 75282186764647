import React, {useMemo, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {useAppDispatch} from "../../store/hooks";
import {clearPartnerWorkstationState} from "../../ClientManagement/PartnerWorkstation/partnerWorkstationSlice";
import AppHeader from "./AppHeader";
import {useMsal} from "@azure/msal-react";
import {handleLogout} from "../../MsalUtils";
import LogoutModal from "../Modal/Logout/LogoutModal";

const Header: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const navigateHome = () => {
        dispatch(clearPartnerWorkstationState());
        history.push({pathname: '/'})
    }
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const msal = useMsal();

    const logoutLink = useMemo(() => {
        return [
            {
                id: 'Logout',
                name: "log out",
                enabled: true,
                customHandler: () => setIsLogoutModalOpen(true)
            }
        ]
    }, [])

    const onClickLogout = () => {
        handleLogout(msal);
    }
    const onClickCancel = () => {
        setIsLogoutModalOpen(false);
    }

    return (
        <>
            <AppHeader
                appTitle="Goals Driven Wealth Management"
                onLogoClick={navigateHome}
                links={logoutLink}
                LinkRenderer={NavLink}
                headerToolbarAlignment="center"
                navigationStyle="header"
            />
            <LogoutModal isOpen={isLogoutModalOpen} onClickCancel={onClickCancel} onClickLogout={onClickLogout}/>
        </>
    )
};

Header.displayName = "Header";
export default Header;

