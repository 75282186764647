import React, {ReactNode} from 'react';
import {Dropdown} from '../Dropdown';
import classNames from 'classnames';

interface PageActionMenuProps {
    className?: string;
    panelHeight?: string;
    panelWidth?: number;
    children?: ReactNode;
    drop?: string,
    buttonKind?: "borderless" | "primary" | "secondary" | "tertiary",
    rounded?: boolean
}

const PageActionMenu = ({
    className, 
    panelWidth = 155,
    drop = "drop",
    children,
    buttonKind = "borderless",
    rounded = true,
    ...rest}: PageActionMenuProps) => (
    <span className={classNames('page-action-menu', className)}>
        <Dropdown
            alignRight
            buttonIcon="only"
            buttonKind={buttonKind}
            buttonSize="large"
            className="page-action-menu__dropdown"
            drop={drop}
            dropdownKind="menu"
            iconNameOpen="menu_dots"
            iconNameClose="menu_dots"
            panelWidth={panelWidth}
            rounded={rounded}
            {...rest}
            >
                {children}
        </Dropdown>
    </span>
);

export default PageActionMenu;