import {combineReducers, configureStore, DeepPartial} from "@reduxjs/toolkit";
import profileReducer, {ProfileState} from "../ClientManagement/ClientProfile/activeProfileSlice";
import proposalsReducer, {ProfilesState} from "../ClientManagement/Proposals/proposalsSlice";
import approvedProfileReducer from "../ClientManagement/ClientProfile/approvedProfileSlice"
import clientAssetsReducer, {ClientAssetsState} from '../Assets/clientAssetsSlice';
import newClientProfileReducer, {
    NewClientProfileState
} from "../ClientManagement/NewClientProfile/NewClientProfileSlice";
import familyTreeReducer, {FamilyTreeState} from "../ClientManagement/FamilyTree/FamilyTreeSlice";
import partnerWorkstationReducer, {
    PartnerWorkstationState
} from "../ClientManagement/PartnerWorkstation/partnerWorkstationSlice";
import goalReducer, {GoalState} from "../Goals/controller/GoalsModelSlice";
import portfolioReserveReducer, {PortfolioReserveState} from "../Goals/PortfolioReserve/PortfolioReserveSlice"
import profileSettingsReducer, {ProfileSettingsState} from "../ClientManagement/ProfileSettings/ProfileSettingsSlice";
import planSummaryReducer, {PlanSummaryState} from "../Plans/PlanSummary/planSummarySlice";
import meetingReducer, {MeetingState} from "../ClientManagement/Meeting/meetingSlice";
import accordionReducer, {AccordionStates} from "../components/Accordion/accordionSlice";
import resourcesReducer, {ResourcesState} from "../Resources/resourcesSlice";
import assetRelianceReducer, {AssetRelianceState} from "../ClientManagement/AssetReliance/AssetRelianceSlice";
import reportingReducer, {ReportingState} from "../Reporting/CreateReport/ReportingSlice";
import accordionTableReducer, {AccordionTableStates} from "../components/AccordionTable/AccordionTableSlice";
import headerTickerTabsReducer, {HeaderTickerTabsState} from "../ClientManagement/ClientProfile/HeaderTickerTabsSlice";
import releaseTogglesReducer from "../ReleaseToggles/releaseTogglesSlice";
import {ReleaseTogglesType} from "../ReleaseToggles/ReleaseToggles";
import outOfEstateAssetsReducer, {OutOfEstateAssetsState} from "../Assets/common/OutOfEstate/OutOfEstateAssetsSlice";
import menuStructureReducer, {MenuStructureState} from "../Resources/menuStructureSlice";
import {estateSummaryReducer, EstateSummaryState} from "../WealthTransfer/EstateSummarySlice";
import accordionPreferencesReducer, {AccordionPreferencesStates} from "../Assets/common/accordionPreferencesSlice";
import clientMeetingDocumentReducer, {MeetingDocumentsState} from "../Resources/clientMeetingDocumentSlice";

export type RootState = {
    goalModel: GoalState,
    client: {
        profile: ProfileState;
        approvedProfile: ProfileState,
        proposals: ProfilesState;
        profileSettings: ProfileSettingsState;
        newClientProfile: NewClientProfileState;
        familyTree: FamilyTreeState,
        assets?: ClientAssetsState,
        partnerWorkstation?: PartnerWorkstationState;
        planSummary?: PlanSummaryState;
        meeting?: MeetingState;
        accordion: AccordionStates;
        action: string;
        resources?: ResourcesState;
        assetReliance: AssetRelianceState;
        accordionTable: AccordionTableStates;
        outOfEstate: OutOfEstateAssetsState;
        headerTickerTabs: HeaderTickerTabsState;
        reporting: ReportingState;
        accordionPreferences: AccordionPreferencesStates;
        meetingDocuments: MeetingDocumentsState
    },
    wealthTransfer: {
        estateSummary: EstateSummaryState
    },
    portfolioReserve?: PortfolioReserveState,
    releaseToggles?: ReleaseTogglesType,
    menuStructure: MenuStructureState
};
export const createStore = (preloadedState: DeepPartial<RootState> = {}) => configureStore({
    reducer: combineReducers({
        client: combineReducers({
            profile: profileReducer,
            approvedProfile: approvedProfileReducer,
            proposals: proposalsReducer,
            profileSettings: profileSettingsReducer,
            newClientProfile: newClientProfileReducer,
            familyTree: familyTreeReducer,
            assets: clientAssetsReducer,
            partnerWorkstation: partnerWorkstationReducer,
            planSummary: planSummaryReducer,
            meeting: meetingReducer,
            accordion: accordionReducer,
            resources: resourcesReducer,
            assetReliance: assetRelianceReducer,
            accordionTable: accordionTableReducer,
            outOfEstate: outOfEstateAssetsReducer,
            headerTickerTabs: headerTickerTabsReducer,
            reporting: reportingReducer,
            accordionPreferences: accordionPreferencesReducer,
            meetingDocuments: clientMeetingDocumentReducer
        }),
        goalModel: goalReducer,
        wealthTransfer: combineReducers({
            estateSummary: estateSummaryReducer
        }),
        portfolioReserve: portfolioReserveReducer,
        releaseToggles: releaseTogglesReducer,
        menuStructure: menuStructureReducer
    }),
    preloadedState,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: {warnAfter: 128},
        serializableCheck: {warnAfter: 128}
    })
});

const temporaryStore = createStore();
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof temporaryStore.getState>;
export type AppDispatch = typeof temporaryStore.dispatch;

