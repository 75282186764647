import React from 'react';
import ModalWrapper from "../ModalWrapper/ModalWrapper";

export interface GenericErrorModalData {
    isOpen: boolean;
    header: string;
    message: string;
    operationId: string;
}

export const genericEmptyErrorModalData: GenericErrorModalData = {
    isOpen: false,
    header: '',
    message: '',
    operationId: ''
}

interface GenericErrorModalProps {
    closeButtonText?: string;
    onClickClose: () => void;
    errorModalData: GenericErrorModalData;
}

const GenericErrorModal: React.FC<GenericErrorModalProps> = ({
                                                                 closeButtonText = 'Close',
                                                                 onClickClose ,
                                                                 errorModalData
                                                             }) => {
    return (
        <ModalWrapper
            id={"Generic Error Modal"}
            data-testid={"Generic Error Modal"}
            headerText={errorModalData.header}
            isOpen={errorModalData.isOpen}
            showCloseButton={true}
            alertIconType={'error'}
            alertIcon={'warning'}
            buttons={[
                {
                    text: closeButtonText,
                    onClick: onClickClose
                },
            ]}
        >
            <div>{errorModalData.message}</div>
            <br/>
            <div>{`Operation Id: ${errorModalData.operationId}`}</div>
        </ModalWrapper>
    )
}

export default GenericErrorModal;