import React from "react";
import {IAzureAudience} from "@fluidframework/azure-client";
import {setAudienceMembers, setAudienceSize} from "../meetingSlice";
import {useAppDispatch} from "../../../store/hooks";

type RelayAudienceManagerProps = {
    audience: IAzureAudience | undefined,
}

export const RelayAudienceManager: React.FC<RelayAudienceManagerProps> = ({
                                                                              audience,
                                                                          }) => {
    if (!audience) {
        return <></>;
    }

    const dispatch = useAppDispatch();
    audience.on('membersChanged', () => {
        dispatch(setAudienceSize(audience.getMembers().size));
        dispatch(setAudienceMembers(Array.from(audience.getMembers().values())));
    });

    return <></>;
}

