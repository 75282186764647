import React, {useMemo} from "react";
import {GoalModelType} from "../models/GoalModelType";
import {Accordion, AccordionItem, DropdownItem, Icon, TableActionMenu} from "../../components";
import {formatCurrency} from "../../utils/format";
import {FamilyGoalType} from "../models/FamilyGoalType";
import BeneficiaryGoalsTable from "../components/BeneficiaryGoalsTable";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {useHistory, useParams} from "react-router-dom";
import {MemberType} from "../../ClientManagement/models/MemberType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {RouteWithId} from "../../routes/types";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../controller/GoalsModelSlice";

const FamilyGoalsSummaryItems = ({
                                     familyGoals,
                                     investorGroup
                                 }: { familyGoals: GoalModelType['familyGoals'], investorGroup: InvestorGroupType }) => {

    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const profile = useAppSelector(selectProfile)

    const handleEditGoal = (selectedGoalType: string, savedGoal: FamilyGoalType) => {
        history.push(
            `/Profile/${id}/ClientProfile/Goals/EditFamilyGoal`,
            {
                ...history.location.state,
                selectedGoalSubType: selectedGoalType,
                savedGoal
            }
        );
    }

    const sortedBeneficiariesGoals = [...Object.values(familyGoals)]
        .sort((bg1, bg2) =>
            Date.parse(bg1.birthdate!).valueOf() - Date.parse(bg2.birthdate!).valueOf());

    const memoizedBeneficiariesGoals = useMemo(() => sortedBeneficiariesGoals
        .map((beneficiaryGoal, index) => {

            const memberSelected: MemberType = FamilyGoalUtils.getBeneficiaryFromProfile(profile, beneficiaryGoal.beneficiaryId);

            const isAnyGoalFundedByNonInstAsst: boolean = Object.values(beneficiaryGoal.goals).some((goal: FamilyGoalType) => {
                return (goal.userInputs.isFundedByNonInvestableAssets === true)
            })
            return (
                <Accordion
                    key={`FamilyGoalAccordian${index}`}
                    allowMultipleExpanded
                    allowZeroExpanded
                    data-test-id={`FamilyGoalAccordian${index}`}
                    accordionId="FamilyGoalSummaryAccordionId"
                >
                    <AccordionItem
                        uuid={`FamilyGoalAccordianItem${index}`}
                        className="borderstyle-none"
                        HeaderComponent={({expanded}: { expanded: boolean }) => (
                            <div className="family-grid-table" data-testid={`beneficiary-summary-row-${index}`}>
                                <span className="paddingleft-md">
                                  <Icon name={expanded ? 'chevron_down' : 'chevron_right'}
                                        size="large"/>
                                    <span> {beneficiaryGoal.name}, {beneficiaryGoal.age}</span>
                                    {!expanded && isAnyGoalFundedByNonInstAsst &&
                                        <Icon name='link' size="large" color="#000000"/>
                                    }
                                </span>
                                <span className="textalign-right">
                                                    {formatCurrency(beneficiaryGoal.totalPresentValue)}
                                            </span>
                                <TableActionMenu className="beneficiaryGoalsActionMenu" panelWidth={240}>
                                    <DropdownItem
                                        onClick={() => history.push(
                                            `/Profile/${id}/ClientProfile/Goals/FamilyGoal`,
                                            {memberSelected}
                                        )}
                                        itemText={`View Family Member Goals`}
                                        value={`View Family Member Goals`}
                                    />
                                </TableActionMenu>
                            </div>
                        )}
                    >

                        <BeneficiaryGoalsTable
                            beneficiaryGoals={Object.values(beneficiaryGoal.goals)}
                            investorGroup={investorGroup}
                            onEditGoal={handleEditGoal}
                            showApplyFamilyAssets={false}
                        />
                    </AccordionItem>
                </Accordion>
            );
        }), [familyGoals, investorGroup]);
    return <div className="family-summary-accordions" data-testid='family-summary-table-parent-div'>
        {memoizedBeneficiariesGoals}
    </div>
}

export default FamilyGoalsSummaryItems;
