import {App_Conf} from "../../core/app_conf";
import {MeetingStatus} from "./Meeting";

export type MeetingAuthenticatorResponse = {
    meetingName: string,
    fluidContainerId: string,
    fluidToken: string,
    acsToken: string,
    meetingStatus: MeetingStatus,
    onlineMeetingJoinUrl: string
}

const {MEETING_PORTAL_AUTHENTICATOR_FN_URL} = App_Conf;

export const getMeetingDetails = async (meetingId: string, passcode: string, user: { id: string; name: string; }): Promise<MeetingAuthenticatorResponse> => {
    const url = new URL(MEETING_PORTAL_AUTHENTICATOR_FN_URL);

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            meetingId,
            passcode,
            user
        }),
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

export const meetingPortalAuthenticatorClient = {
    getMeetingDetails: getMeetingDetails
}