import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {emptyGoalModel, GoalModelType} from "../models/GoalModelType";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {DiscountRateType} from "../models/DiscountRate";
import {RootState} from "../../store/store";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {MemberType} from "../../ClientManagement/models/MemberType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";

export type GoalState = GoalModelType | undefined;

export const initialState: GoalModelType = {...emptyGoalModel}

function updateNonLifestyleGoal(goalFromActionId: string, action: PayloadAction<{ goal: NonLifestyleGoalType }>, goalInState: NonLifestyleGoalType) {
    if (goalInState.id === goalFromActionId) {
        return action.payload.goal
    } else {
        return goalInState
    }
}

export const goalsModelSlice = createSlice({
    name: 'goalModel',
    initialState,
    reducers: {
        // reducers need to return the new state, given the existing state and the action. In this case, the new state is
        // just the payload of the action; in other cases, you can mutate the state object, and changes to that state will
        // be recorded to create the new state.
        setGoalModel: (_state, action: PayloadAction<GoalModelType>) => {
            return action.payload
        },
        updateLifestyleSpendingGoal: (state, action: PayloadAction<LifestyleSpendingGoal>) => {
            return {
                ...state,
                lifestyleSpendingGoal: action.payload,
            }
        },
        addDiscretionaryGoal: (state, action: PayloadAction<{ goal: NonLifestyleGoalType }>) => {
            const {id: goalFromActionId} = action.payload.goal

            if (goalFromActionId) {
                const thisGoalIsInStateAlready = state.discretionaryGoals
                    .some((goalInState) => goalInState.id === goalFromActionId);

                if (thisGoalIsInStateAlready) {
                    const updatedDiscretionaryGoals = state.discretionaryGoals.map((goalInState) => updateNonLifestyleGoal(goalFromActionId, action, goalInState))

                    return {
                        ...state,
                        discretionaryGoals: updatedDiscretionaryGoals,
                        nonLifestyleGoalChanged: true
                    }
                }

                return {
                    ...state,
                    discretionaryGoals: [
                        ...state.discretionaryGoals,
                        action.payload.goal
                    ],
                    nonLifestyleGoalChanged: true
                }
            }
            return state
        },
        deleteNonLifestyleGoal: (state, action: PayloadAction<{ goal: NonLifestyleGoalType }>) => {

            const {goal} = action.payload;
            const {id} = goal

            if (id) {
                switch (goal.goalType) {
                    case GoalType.DISCRETIONARY:
                        const updatedDiscretionaryGoals = state.discretionaryGoals.filter((discretionaryGoal) => discretionaryGoal.id !== id)
                        state.discretionaryGoals = updatedDiscretionaryGoals;
                        state.nonLifestyleGoalChanged = true;
                        return state
                    case GoalType.PHILANTHROPIC:
                        const updatedPhilanthropicGoals = state.philanthropicGoals.filter((philanthropicGoal) => philanthropicGoal.id !== id)
                        state.philanthropicGoals = updatedPhilanthropicGoals;
                        state.nonLifestyleGoalChanged = true;
                        return state
                    default:
                        return state
                }
            }
        },
        addPhilanthropicGoal: (state, action: PayloadAction<{ goal: NonLifestyleGoalType }>) => {
            const {id: goalFromActionId} = action.payload.goal

            if (goalFromActionId) {
                const thisGoalIsInStateAlready = state.philanthropicGoals
                    .some((goalInState) => goalInState.id === goalFromActionId);

                if (thisGoalIsInStateAlready) {
                    const updatedPhilanthropicGoals = state.philanthropicGoals.map((goalInState) => updateNonLifestyleGoal(goalFromActionId, action, goalInState))

                    return {
                        ...state,
                        philanthropicGoals: updatedPhilanthropicGoals,
                        nonLifestyleGoalChanged: true,
                    }
                }

                return {
                    ...state,
                    philanthropicGoals: [
                        ...state.philanthropicGoals,
                        action.payload.goal
                    ],
                    nonLifestyleGoalChanged: true,
                }
            }
            return state
        },
        addFamilyGoal: (state, action: PayloadAction<{ goal: FamilyGoalType, member: MemberType }>) => {

            const {goal, member} = action.payload;
            const {beneficiaryId, id} = goal

            if (id && beneficiaryId) {
                state.familyGoals[beneficiaryId] = FamilyGoalUtils
                    .combineBeneficiaryGoalsWithFamilyGoal(goal, member, state.familyGoals[beneficiaryId]);
                state.nonLifestyleGoalChanged = true;
            }

            return state
        },
        deleteFamilyGoal: (state, action: PayloadAction<{ goal: FamilyGoalType }>) => {

            const {goal} = action.payload;
            const {beneficiaryId, id} = goal

            if (id && beneficiaryId) {
                const beneficiaryGoals = FamilyGoalUtils.deleteFamilyGoalFromBeneficiaryGoals(goal, state.familyGoals[beneficiaryId]);
                if (Object.entries(beneficiaryGoals.goals).length === 0) {
                    delete state.familyGoals[beneficiaryId];
                } else {
                    state.familyGoals[beneficiaryId] = beneficiaryGoals;
                }
                state.nonLifestyleGoalChanged = true;
            }

            return state
        },
        setDiscountRateSelection: (state, action: PayloadAction<DiscountRateType>) => {
            return {
                ...state,
                discountRateSelection: action.payload
            }
        },
        setNonLifestyleGoalChanged:(state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                nonLifestyleGoalChanged: action.payload
            };
        },
        updateNonLifestyleGoalReducer: (state, action: PayloadAction<NonLifestyleGoalType>) => {
            return {
                ...state,
                nonLifestyleGoal: action.payload,
            }
        },
    }
});

export const {
    setGoalModel,
    updateLifestyleSpendingGoal,
    addFamilyGoal,
    addDiscretionaryGoal,
    deleteNonLifestyleGoal,
    deleteFamilyGoal,
    addPhilanthropicGoal,
    setDiscountRateSelection,
    setNonLifestyleGoalChanged,
    updateNonLifestyleGoalReducer
} = goalsModelSlice.actions;

export const selectGoalModel = (state: RootState): GoalModelType => {
    return state.goalModel
        ? state.goalModel
        : emptyGoalModel
};
export const selectProfile = (state: RootState) => selectGoalModel(state).proposal;

export default goalsModelSlice.reducer;
