import React, {useContext} from 'react';
import {NO_OP_ASYNC} from "../../constants/common";

export enum CommunicationsConnectionState {
    CONNECTED,
    DISCONNECTED,
}

type CommunicationsContextValue = {
    connect: (accessToken: string, userId: string, joinUrl: string) => Promise<void>;
    disconnect: (isCurrentUserPresenting: boolean) => Promise<void>;
    connectionState: CommunicationsConnectionState
}

const defaultValue: CommunicationsContextValue = {
    connect: NO_OP_ASYNC,
    disconnect: NO_OP_ASYNC,
    connectionState: CommunicationsConnectionState.DISCONNECTED,
};

const communicationsContext = React.createContext(defaultValue);

export const useCommunicationsContext = () => {
    return useContext(communicationsContext);
};

export default communicationsContext;