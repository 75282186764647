import React from 'react';
import BarChartSidebar from "../../components/BarChartSidebar/BarChartSidebar";
import {useAppSelector} from "../../store/hooks";
import {BeneficiaryGoals, emptyTaxLiabilities, GoalModelType, TaxLiabilities} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {emptyLifestyleGoalForm, LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {GoalBarChartKeyColors} from "./GoalBarChartKeyColors";
import {NonLifestyleGoalType} from "../models/GoalType";
import {emptyProfileResponse, ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {formatCurrency} from "../../utils/format";
import GoalUtils from "../Summary/GoalUtils";
import {testInvestorGroupResponse} from "../../ClientManagement/TestUtils";

export class GoalBarChartSideBarRenderOptions {
    approvedProfile: ProfileResponse = {...emptyProfileResponse};
    children: React.ReactNode;
    discretionaryGoals: NonLifestyleGoalType[] = [];
    philanthropicGoals: NonLifestyleGoalType[] = [];
    familyGoals: {[p: string]: BeneficiaryGoals} = {};
    lifestyleSpendingGoal: LifestyleSpendingGoal = {...emptyLifestyleGoalForm(testInvestorGroupResponse.planningPeriod)};
    taxLiabilities: TaxLiabilities = {...emptyTaxLiabilities};
    emptyTotalGoalsAndTaxLiabilities: string = "$0";
}

function GoalBarChartSideBar(renderOptions?: Partial<GoalBarChartSideBarRenderOptions>) {
    const goalModelFromStore = useAppSelector<GoalModelType>(selectGoalModel);

    const {
        approvedProfile,
        children,
        discretionaryGoals,
        philanthropicGoals,
        familyGoals,
        lifestyleSpendingGoal,
        taxLiabilities,
        emptyTotalGoalsAndTaxLiabilities,
    } = {
        ... new GoalBarChartSideBarRenderOptions(),
        ...{
            approvedProfile: goalModelFromStore.approvedProfile,
            discretionaryGoals: goalModelFromStore.discretionaryGoals,
            philanthropicGoals: goalModelFromStore.philanthropicGoals,
            familyGoals: goalModelFromStore.familyGoals,
            lifestyleSpendingGoal: goalModelFromStore.lifestyleSpendingGoal,
            taxLiabilities: goalModelFromStore.taxLiabilities,
        },
        ...renderOptions
    };

    const displayName = approvedProfile.displayName;
    const discretionaryAndPhilanthropicGoals = discretionaryGoals.concat(philanthropicGoals);
    const totalGoalsAndTaxLiabilities = FamilyGoalUtils.calculateTotalGoalsAndTaxLiabilities(lifestyleSpendingGoal, familyGoals, taxLiabilities, discretionaryAndPhilanthropicGoals);
    const displayedTotalGoalsAndTaxLiabilities = (totalGoalsAndTaxLiabilities > 0 ? formatCurrency(totalGoalsAndTaxLiabilities) : emptyTotalGoalsAndTaxLiabilities);

    return <BarChartSidebar displayName={displayName}
                            title='Your Goals'
                            data={computeBarChartData(lifestyleSpendingGoal, familyGoals, discretionaryGoals, philanthropicGoals, taxLiabilities)}
                            noDataText='No goals have been captured yet.'>
        <span className="condensed-subtitle margintop-xxxl">TOTAL GOALS AND TAX LIABILITIES</span>
        <div data-testid="total-goals-tax-liability-div" className="h1 margintop-sm marginbottom-xxxl">{displayedTotalGoalsAndTaxLiabilities}</div>
        {children}
    </BarChartSidebar>;
}

function computeBarChartData(lifestyleSpendingGoal: LifestyleSpendingGoal, familyGoals: GoalModelType['familyGoals'], discretionaryGoals: NonLifestyleGoalType[], philanthropicGoals: NonLifestyleGoalType[],taxLiabilities: TaxLiabilities) {
    const barChartData = [];
    let cumulativeFamilyGoalPresentValue = FamilyGoalUtils.getCumulativeTotalPresentValue(familyGoals);
    let cumulativeDiscretionaryGoalPresentValue = GoalUtils.getNonLifestyleGoalCumulativeTotalPresentValue(discretionaryGoals);
    let cumulativePhilanthropicGoalPresentValue = GoalUtils.getNonLifestyleGoalCumulativeTotalPresentValue(philanthropicGoals);
    if (!!lifestyleSpendingGoal.calculatedFields.presentValue) {
        barChartData.push({
            className: 'lifestyle-spending-chart-item',
            label: 'Lifestyle Spending',
            color: GoalBarChartKeyColors.LIFESTYLE,
            total: lifestyleSpendingGoal.calculatedFields.presentValue
        });
    }
    if (cumulativeDiscretionaryGoalPresentValue > 0) {
        barChartData.push({
            className: 'discretionary-chart-item',
            label: 'Discretionary',
            color: GoalBarChartKeyColors.DISCRETIONARY,
            total: cumulativeDiscretionaryGoalPresentValue
        });
    }
    if (cumulativePhilanthropicGoalPresentValue > 0) {
        barChartData.push({
            className: 'philanthropic-chart-item',
            label: 'Philanthropic',
            color: GoalBarChartKeyColors.PHILANTHROPIC,
            total: cumulativePhilanthropicGoalPresentValue
        });
    }
    if (cumulativeFamilyGoalPresentValue > 0) {
        barChartData.push({
            className: 'family-chart-item',
            label: 'Family',
            color: GoalBarChartKeyColors.FAMILY,
            total: cumulativeFamilyGoalPresentValue
        });
    }
    if (taxLiabilities.totalTaxLiabilities > 0) {
        barChartData.push({
            className: 'asset-chart-item',
            label: 'Tax Liabilities',
            color: GoalBarChartKeyColors.TAX_LIABILITIES,
            total: taxLiabilities.totalTaxLiabilities
        });
    }
    return barChartData;
}

export default GoalBarChartSideBar;
