import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Logo} from 'xps-react';
import {Button, Name} from "../components";
import {useRelayContext} from "../ClientManagement/Meeting/Relay/types/RelayContext";
import {Meeting, MeetingStatus} from "../ClientManagement/Meeting/Meeting";
import LoadingIndicator from "../pages/LoadingIndicator";
import {meetingPortalAuthenticatorClient} from "../ClientManagement/Meeting/MeetingPortalAuthenticatorClient";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {resetMeeting, selectActiveMeeting, setActiveMeeting} from "../ClientManagement/Meeting/meetingSlice";
import {MeetingBackgroundImage, ThankYouImage, WelcomeImage} from "../ClientManagement/Meeting/MeetingImages";
import ModalWrapper from "../components/Modal/ModalWrapper/ModalWrapper";
import {useLocation} from "react-router-dom";
import {v4 as generate_uuid} from "uuid";
import {SynchronizedMeetingContent} from "./SynchronizedMeetingContent";
import AudioVideoPreview from "./AudioVideoPreview";
import {base64ToString} from "../utils/base64Utils";
import {useCommunicationsContext} from "../ClientManagement/Meeting/CommunicationsContext";

enum MeetingPortalView {
    LOGIN,
    AUDIO_VIDEO_PREVIEW,
    MEETING_CONTENT,
    LOADING,
}

type MeetingPortalParams = {
    id: string | null;
    code: string | null;
};

export const MeetingPortal: React.FC = () => {
    const location = useLocation();
    const params: MeetingPortalParams = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return {
            id: searchParams.get('id'),
            code: searchParams.get('code'),
        };
    }, []);

    const {
        connectToMeetingContainerFromMeetingPortal,
        disconnectFromMeetingContainer,
        sharedObjects
    } = useRelayContext();
    const communicationsContext = useCommunicationsContext();
    const [meetingId, setMeetingId] = useState<string>(params.id || '');
    const [passcode, setPasscode] = useState<string>(params.code || '');
    const activeMeeting = useAppSelector(selectActiveMeeting);
    const dispatch = useAppDispatch();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showMeetingNotFoundModal, setShowMeetingNotFoundModal] = useState<boolean>(false);
    const userId = useMemo(() => generate_uuid(), []);
    const [view, setView] = useState<MeetingPortalView>(MeetingPortalView.LOGIN);
    const [acsToken, setAcsToken] = useState<string>('');

    const connectToMeeting = useCallback(() => {
        if (meetingId && passcode) {
            joinMeeting(meetingId, passcode).then();
        }
    }, [meetingId, passcode]);

    useEffect(() => {
        connectToMeeting();
    }, []);

    const joinMeeting = async (joinMeetingId: string, joinMeetingPasscode: string) => {
        setView(MeetingPortalView.LOADING);
        const user = {
            id: userId,
            name: 'Guest',
        };
        return meetingPortalAuthenticatorClient.getMeetingDetails(joinMeetingId, joinMeetingPasscode, user)
            .then((meetingDetails) => {
                if (meetingDetails.meetingName
                    && meetingDetails.fluidToken
                    && meetingDetails.fluidContainerId) {
                    dispatch(setActiveMeeting({
                        ...activeMeeting,
                        id: 'meetingId',
                        status: meetingDetails.meetingStatus,
                        onlineMeetingJoinUrl: base64ToString(meetingDetails.onlineMeetingJoinUrl)
                    } as Meeting));

                    return connectToMeetingContainerFromMeetingPortal(
                        meetingDetails.fluidContainerId,
                        meetingDetails.fluidToken,
                        joinMeetingId,
                        joinMeetingPasscode,
                        user
                    ).then(() => {
                        console.debug('Connected to meeting container');
                        if (meetingDetails.acsToken) {
                            setAcsToken(meetingDetails.acsToken);
                            setView(MeetingPortalView.AUDIO_VIDEO_PREVIEW);
                        } else {
                            setView(MeetingPortalView.MEETING_CONTENT);
                            sharedObjects?.meetingPortalParticipantJoinDDS.set('participantJoinsFromMeetingPortal', 'join');
                        }
                    }).catch((error) => {
                        console.error('Could not connect to meeting container', error.message);
                        setShowErrorModal(true);
                        setView(MeetingPortalView.LOGIN);
                    });
                } else {
                    setShowMeetingNotFoundModal(true);
                    console.error('Could not find meeting for id', joinMeetingId);
                    setView(MeetingPortalView.LOGIN);
                }
            }).catch((error) => {
                setShowErrorModal(true);
                console.error('Could not fetch meeting details', error.message)
                setView(MeetingPortalView.LOGIN);
            });
    };

    return <div className="meeting-portal">
        {
            view === MeetingPortalView.LOADING && <LoadingIndicator/>
        }
        {
            view === MeetingPortalView.LOGIN && <>
                <MeetingBackgroundImage/>
                <div className="login-container center-content">
                    <div className="nt-logo">
                        <Logo
                            alt="Single Line Anchor Logo"
                            ariaLabel="Northern Trust Logo"
                            color="black"
                            logoType="single"
                            width="300px"
                        />
                    </div>
                    <div className="login-form">
                        <h2 className="login-instructions">Please enter the Meeting ID and Passcode to join your
                            meeting.</h2>
                        <Name
                            name={meetingId}
                            className="meeting-id-input"
                            label={'Meeting ID'}
                            aria-label={'meetingId'}
                            aria-labelledby={'meetingId'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setMeetingId(e.target.value);
                            }}
                        />
                        <Name
                            name={passcode}
                            className="meeting-passcode-input"
                            label={'Meeting Passcode'}
                            aria-label={'meetingPasscode'}
                            aria-labelledby={'meetingPasscode'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setPasscode(e.target.value);
                            }}
                        />
                        <Button className="join-meeting-button"
                                icon="none"
                                includeRef={false}
                                kind="primary"
                                size="medium"
                                tabIndex={0}
                                type="button"
                                onClick={() => {
                                    connectToMeeting();
                                }}>Join Your Meeting</Button>
                    </div>
                </div>
            </>
        }
        {
            view === MeetingPortalView.AUDIO_VIDEO_PREVIEW &&
            <AudioVideoPreview onJoinNow={() => {
                if (!activeMeeting?.onlineMeetingJoinUrl) {
                    console.error('Invalid onlineMeetingJoinUrl', activeMeeting?.onlineMeetingJoinUrl);
                    setShowErrorModal(true);
                    return;
                }
                communicationsContext.connect(acsToken, userId, activeMeeting.onlineMeetingJoinUrl)
                    .then(() => {
                        setView(MeetingPortalView.MEETING_CONTENT)
                        sharedObjects?.meetingPortalParticipantJoinDDS.set('participantJoinsFromMeetingPortal', 'join');
                    })
                    .catch((error) => {
                        console.error('Could not connect to meeting communications', error.message);
                        setShowErrorModal(true);
                        throw error;
                    });
            }}/>
        }
        {
            view === MeetingPortalView.MEETING_CONTENT && <>
                {
                    activeMeeting?.status === MeetingStatus.CREATED &&
                    <div className="meeting-portal-image-container"><WelcomeImage/></div>
                }
                {
                    activeMeeting?.status === MeetingStatus.ENDED &&
                    <div className="meeting-portal-image-container"><ThankYouImage/></div>
                }
                {
                    activeMeeting?.status !== MeetingStatus.CREATED &&
                    activeMeeting?.status !== MeetingStatus.ENDED &&
                    <SynchronizedMeetingContent/>
                }
                <div className="control-footer">
                    <Button
                        className={'leave-meeting button--destructive btn-skin--primary marginright-12'}
                        destructive={true}
                        kind="primary"
                        onClick={() => {
                            disconnectFromMeetingContainer();
                            communicationsContext.disconnect(false).then();
                            setMeetingId('');
                            setPasscode('');
                            dispatch(resetMeeting());
                            setView(MeetingPortalView.LOGIN);
                        }}
                        size="small"
                        icon="right"
                        iconName="cancel_outline"
                    >LEAVE MEETING</Button>
                </div>
            </>
        }
        {/* Error Modals */}
        {
            showErrorModal && <ModalWrapper
                id="join-meeting-error"
                isOpen={showErrorModal}
                headerText={"Error"}
                alertIconType={'error'}
                alertIcon={'warning'}
                buttons={[
                    {
                        text: "Close",
                        onClick: () => setShowErrorModal(false)
                    }
                ]}>
                <div className="font-md">
                    {"Error occurred while joining the meeting"}
                </div>
            </ModalWrapper>
        }
        {
            showMeetingNotFoundModal && <ModalWrapper
                id="meeting-not-found"
                isOpen={showMeetingNotFoundModal}
                headerText={"Meeting Not Found"}
                alertIconType={'warning'}
                alertIcon={'warning'}
                buttons={[
                    {
                        text: "Close",
                        onClick: () => setShowMeetingNotFoundModal(false)
                    }
                ]}>
                <div className="font-md">
                    {"No matching meeting found for given Meeting Id and Passcode combination"}
                </div>
            </ModalWrapper>
        }
    </div>;
}