import Input from '../Input/Input';
import React from "react";

export interface RangeInputProp {
    value: string | number,
    ariaLabel: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: () => void,
    readOnly?: boolean
}

export interface RangeInputsProps {
    left: RangeInputProp,
    right: RangeInputProp,
    hasStarted?: boolean,
    hasEnded?: boolean
}

const RangeInputs = (props: RangeInputsProps) => {

    return (
       <div >
            <div className="range-inputs">
                <Input
                    aria-label={props.left.ariaLabel}
                    value={props.left.value}
                    onChange={props.left.onChange}
                    onBlur={props.left.onBlur}
                    readOnly={props.hasStarted || props.hasEnded}
                />
                <span>to</span>
                <Input
                    aria-label={props.right.ariaLabel}
                    value={props.right.value}
                    onChange={props.right.onChange}
                    onBlur={props.right.onBlur}
                    readOnly={props.hasEnded}
                />
            </div>
        </div>
    )
}

export default RangeInputs;
