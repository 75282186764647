import React, {ChangeEvent, useEffect, useState} from 'react';
import {RequiredFieldsBanner} from "../../../components";
import {
    GeneralInflowDetails,
    GeneralInflowPresentValueRequest,
    GeneralInflowPresentValueResponse,
    InflowTimeFrame as InflowTimeFrameType, TrustInflowType,
    TypeOptions
} from "../../models/GeneralInflow";
import {useAppDispatch} from "../../../store/hooks";
import {formatCurrency} from "../../../utils/format";
import {
    InvestorGroupMember,
    InvestorGroupMemberType,
    InvestorGroupType,
    MemberGroup
} from "../../../ClientManagement/models/InvestorGroupType";
import {assetsApiClient} from "../../AssetsApiClient";
import {isNaN} from "mathjs";
import InflowTimeFrameCalculator, {
    calculateEndDate,
    calculateStartDate,
    extractYear,
    getBirthdateForOwnerWithLongerPlanningPeriod,
    getMemberWithLongestPlanningPeriod,
    getOwnersAgeRange
} from "../InflowTimeFrameCalculator";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import DataEntrySummary from "../../../components/DataEntry/DataEntrySummary";
import {setActiveFormAsset} from "../../clientAssetsSlice";
import deepEquals from "fast-deep-equal";
import {InflowCharacteristics} from "src/Assets/FutureInflows/InflowCharacteristics";
import {InflowReserve} from "src/Assets/FutureInflows/InflowReserve";
import {LifeStatus} from "../../../ClientManagement/models/MemberType";
import {AssetDetails, MemberOwnershipChange} from "./AssetDetails";
import {InflowTimeFrame} from "./inflowTimeFrame";
import {ClampingError, InflowTimeframeFieldType} from "./types";
import DisclosureForTrustInflow from "./DisclosureForTrustInflow";
import {ReleaseTogglesType} from "../../../ReleaseToggles/ReleaseToggles";
import ConfirmReviewDisclosure from "./ConfirmReviewDisclosure";

enum LastChangedFieldType {
    GrossAnnualInflow = "GrossAnnualInflow",
    NetAnnualInflow = "NetAnnualInflow"
}

type GeneralInFlowFormProps = {
    profileId: string,
    formatTitle: (description: string) => string,
    onSave: (generalInflow: GeneralInflowDetails) => void,
    onCancel: (isFormChanged: boolean) => void,
    initialGeneralInflow: GeneralInflowDetails,
    hasStartDatePassed?: boolean,
    investorGroup: InvestorGroupType,
    memberGroup: MemberGroup,
    releaseToggles: ReleaseTogglesType
}


function calculateNetAnnualFlow(grossAnnualFlow: number, taxRate: string) {
    return Math.round(grossAnnualFlow * (1 - parseFloat(taxRate) / 100));
}

function calculateGrossAnnualFlow(netAnnualFlow: number, taxRate: string) {
    return Math.round(netAnnualFlow / (1 - parseFloat(taxRate) / 100));
}

export function GeneralInflowForm(props: GeneralInFlowFormProps) {
    const dispatch = useAppDispatch();
    const investorGroup = props.investorGroup;

    const [generalInflow, updateGeneralInflow] = useState<GeneralInflowDetails>(props.initialGeneralInflow);
    const [lastChanged, setLastChanged] = useState<LastChangedFieldType>(LastChangedFieldType.GrossAnnualInflow);
    const [inflowFieldsReadOnly, setInflowFieldsReadOnly] = useState(props.hasStartDatePassed);
    const [isResetTimeFrameModalShown, setResetTimeFrameModalShown] = useState(false);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [isDescriptionInlineErrorShown, setIsDescriptionInlineErrorShown] = useState(false);
    const [alignedInflow, setAlignedInflow] = useState<GeneralInflowPresentValueResponse>({
        totalPresentValue: generalInflow.totalPresentValue,
        interestRate: generalInflow.interestRate,
        lifestyleGoalAligned: generalInflow.lifestyleGoalAligned,
        excessFutureInflow: generalInflow.excessFutureInflow,
        inflowReservePresentValue: generalInflow.inflowReservePresentValue
    });
    const [clampedInlineError, setClampedInlineError] = useState<ClampingError>({showMessage: false});
    const [isInflowTimeFrameWarningShown, setInflowTimeFrameWarningShown] = useState(false);
    const [inflowReserveLengthInYears, setInflowReserveLengthInYears] = useState(generalInflow.inflowReserveLength);
    const [editedInflowTimeFrame, setEditedInflowTimeFrame] = useState<InflowTimeFrameType>({
        startDate: generalInflow.startDate,
        endDate: generalInflow.endDate,
        yearsUntilFlow: generalInflow.yearsUntilFlow,
        yearsOfFlow: generalInflow.yearsOfFlow,
        inflowYearsFrom: generalInflow.inflowYearsFrom,
        inflowYearsTo: generalInflow.inflowYearsTo,
        ownersAgeRangeFrom: generalInflow.ownersAgeRangeFrom,
        ownersAgeRangeTo: generalInflow.ownersAgeRangeTo
    });
    const [isConfirmationDisclosureModalOpen, setConfirmationDisclosureModalOpen] = useState<boolean>(false);
    const [isDisclosureReviewed, setIsDisclosureReviewed] = useState<boolean>(generalInflow.isDisclosureReviewed);
    const calculator = new InflowTimeFrameCalculator(investorGroup);
    const isJointOwnership = generalInflow.memberOwnerships.length > 1;

    const assetSummaryItems = [
        {
            label: 'Net Annual Flow',
            value: formatCurrency(!isNaN(generalInflow.netAnnualFlow) ? generalInflow.netAnnualFlow : 0)
        },
        {
            label: 'Years until Flow',
            value: generalInflow.yearsUntilFlow
        },
        {
            label: 'Years of Flow',
            value: generalInflow.yearsOfFlow
        },
        {
            label: 'Present Value',
            value: formatCurrency(alignedInflow?.totalPresentValue)
        }
    ];

    const assetAlignmentItems = [
        {
            label: 'Lifestyle Goal Aligned',
            value: formatCurrency(alignedInflow?.lifestyleGoalAligned)
        },
        {
            label: 'Excess Future Inflow',
            value: formatCurrency(alignedInflow?.excessFutureInflow)
        }
    ]

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;
    const [selectedOwner, setSelectedOwner] = useState<InvestorGroupMember>(primaryMember);

    const isTrustInflow = generalInflow.type === "Trust Inflow";
    const isTrustInflowBaseBudgetOrUniTrust  = !generalInflow.trustInflowType
        || generalInflow.trustInflowType === "NT - Base Budget"
        || generalInflow.trustInflowType === "NT - Unitrust";

    const showDisclosureForTrustInflow = isTrustInflow && isTrustInflowBaseBudgetOrUniTrust;

    useEffect(() => {
        if (partnerMember?.id === getGeneralInflowOwnerId()) {
            setSelectedOwner(partnerMember);
        } else {
            setSelectedOwner(primaryMember);
        }
    }, [investorGroup]);

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [generalInflow.description]);

    useEffect(() => {
        const ownerPlanningPeriod = getOwnerInvestorGroupMember().planningPeriod;
        if (investorGroup.partnerMember && ownerPlanningPeriod) {
            const planningPeriodsNotTheSame = investorGroup.primaryMember.planningPeriod?.numberOfYears !== investorGroup.partnerMember.planningPeriod?.numberOfYears;
            const showSoftWarning = generalInflow.yearsUntilFlow + generalInflow.yearsOfFlow > ownerPlanningPeriod.numberOfYears && planningPeriodsNotTheSame;

            setInflowTimeFrameWarningShown(showSoftWarning);
        }
    }, [generalInflow, investorGroup]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setClampedInlineError({showMessage: false});
        }, 6000);
        return () => {
            clearTimeout(timeout);
        }
    }, [clampedInlineError.inflowTimeframeField]);

    useEffect(() => {
        let componentUnmounted = false;
        calculateGeneralInflowPresentValue()
            .then(response => {
                if (componentUnmounted) return;
                setAlignedInflow(response);
            }).catch(reason => console.log(reason));
        return () => {
            componentUnmounted = true;
        }
    }, [generalInflow.netAnnualFlow,
        generalInflow.yearsUntilFlow,
        generalInflow.yearsOfFlow,
        generalInflow.isHighRisk,
        generalInflow.willAdjustWithInflation,
        generalInflow.isInflowWillFundLifestyleGoal,
        generalInflow.memberOwnerships,
        generalInflow.inflowReserveLength,
    ]);

    useEffect(() => {
        dispatch(setActiveFormAsset({
            assetType: 'futureInflow',
            id: generalInflow.id,
            inEstateValue: alignedInflow?.totalPresentValue || 0,
            description: generalInflow.description,
            hasInEstateOwnership: true
        }));
    }, [alignedInflow?.totalPresentValue, generalInflow.description]);

    useEffect(() => {
        return clearActiveFormAsset
    }, []);

    const clearActiveFormAsset = () => {
        dispatch(setActiveFormAsset(null));
    };

    const handleChangeType = (data: any) => {
        const inflowType = data?.value as TypeOptions;
        const isInflowWillFundLifestyleGoal = inflowType !== "Other Inflows" && inflowType !== "Trust Inflow";
        const willAdjustWithInflation = inflowType === "Salary";
        const inflowIsHighRisk = false;
        const trustInflowType = inflowType === "Trust Inflow" && generalInflow.trustInflowType === undefined ? "NT - Base Budget" : undefined;
        updateGeneralInflow({
            ...generalInflow,
            type: inflowType,
            trustInflowType,
            description: inflowType,
            isInflowWillFundLifestyleGoal: isInflowWillFundLifestyleGoal,
            willAdjustWithInflation: willAdjustWithInflation,
            isHighRisk: inflowIsHighRisk
        });

        setIsDescriptionInlineErrorShown(!data?.value);
    }
    const handleChangeTrustInflowType = (trustInflowType: TrustInflowType | undefined) => {
        updateGeneralInflow({
            ...generalInflow,
            trustInflowType
        })
    }

    const handleTaxRate = (value: string) => {
        updateGeneralInflow({
            ...generalInflow,
            taxRate: value
        });
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        updateGeneralInflow({
            ...generalInflow,
            [name]: parseInt(value)
        });
    }

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        updateGeneralInflow({
            ...generalInflow,
            [name]: value
        });
    }

    const handleFundLifestyleGoalCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {checked} = e.target;
        const updatedInflowReserveLength = checked ? 0 : null;
        setInflowReserveLengthInYears(updatedInflowReserveLength);
        updateGeneralInflow({
            ...generalInflow,
            isInflowWillFundLifestyleGoal: checked,
            inflowReserveLength: updatedInflowReserveLength
        })
    }

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        updateGeneralInflow({
            ...generalInflow,
            [name]: checked
        });
    }

    const handleInflowReserveLengthChange = (value: number) => {
        updateGeneralInflow({
            ...generalInflow,
            inflowReserveLength: value
        });
    }

    const onCancelClick = () => {
        const isFormChanged = !deepEquals(generalInflow, props.initialGeneralInflow);
        props.onCancel(isFormChanged);
    }

    const formatPlanningPeriod = (member: InvestorGroupMember | undefined): string => {
        if (member) {
            const planningPeriod = member.planningPeriod
                ? member.planningPeriod.numberOfYears
                : investorGroup?.planningPeriod.numberOfYears;
            return `${planningPeriod} years (${member.firstName})`;
        } else {
            return "";
        }
    }

    const showProposalPlanningPeriod = () => {
        if (isJointOwnership) {
            return true;
        }

        const ownerIsPrimary = investorGroup.primaryMember.id === generalInflow.memberOwnerships[0].memberId;
        return ownerIsPrimary
            ? investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER
            : investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PRIMARY;
    }

    const isAnyRequiredFieldEmpty = () => {
        const isDescriptionEmpty = !generalInflow.description.trim();
        setIsDescriptionInlineErrorShown(isDescriptionEmpty);
        return isDescriptionEmpty;
    }

    function showErrorForInflowTimeframeField(field: InflowTimeframeFieldType) {
        return clampedInlineError.showMessage && clampedInlineError.inflowTimeframeField === field
    }

    const getOwnerInvestorGroupMember = () =>
        investorGroup.primaryMember.id === getGeneralInflowOwnerId()
            ? investorGroup.primaryMember
            : investorGroup.partnerMember!;

    function getInflowTimeframeErrorDiv() {
        return <div className="inflow-time-frame-error-msg">
            Inflow Time Frame was adjusted to match Proposal Planning Period.
        </div>;
    }

    function getGeneralInflowOwnerId() {
        return generalInflow.memberOwnerships.length > 1
            ? getMemberWithLongestPlanningPeriod(investorGroup).id
            : generalInflow.memberOwnerships[0].memberId;
    }

    function calculateGeneralInflowPresentValue(overrides?: Partial<GeneralInflowPresentValueRequest>) {
        const netAnnualFlow = !isNaN(generalInflow.netAnnualFlow) ? generalInflow.netAnnualFlow : 0;
        return assetsApiClient
            .calculateGeneralInflowPresentValue(props.profileId, {
                netAnnualFlow: netAnnualFlow,
                yearsUntilFlow: generalInflow.yearsUntilFlow,
                yearsOfFlow: generalInflow.yearsOfFlow,
                isHighRisk: generalInflow.isHighRisk,
                willAdjustWithInflation: generalInflow.willAdjustWithInflation,
                isInflowWillFundLifestyleGoal: generalInflow.isInflowWillFundLifestyleGoal,
                inflowId: generalInflow.id,
                id: generalInflow.id,
                startDate: generalInflow.startDate,
                memberId: getGeneralInflowOwnerId(),
                inflowReserveLength: generalInflow.inflowReserveLength,
                ...overrides
            });
    }

    function getPartnerMemberIfAlive() {
        if (props.memberGroup.partnerMember?.lifeStatus !== LifeStatus.Deceased) {
            return partnerMember;
        }
        return undefined;
    }

    const handleSave = () => {
        if (isAnyRequiredFieldEmpty()) {
            setRequiredFieldsBannerShown(true);
            return;
        }
        updateSaveButtonDisabled(true);
        props.onSave(generalInflow);
    };

    const mapOwnershipChanges = (ownershipChanges: MemberOwnershipChange[]) => {
        return ownershipChanges.map(({memberId, percentage, isRevocableTrust}) => ({
            memberId,
            percentage,
            isRevocableTrust
        }));
    };

    const handleOwnershipChange = (ownershipChanges: MemberOwnershipChange[]) => {
        const updatedGeneralInflow: GeneralInflowDetails = {
            ...generalInflow,
            memberOwnerships: mapOwnershipChanges(ownershipChanges)
        };
        const memberId = ownershipChanges.length > 1
            ? getMemberWithLongestPlanningPeriod(investorGroup).id
            : ownershipChanges[0].memberId;
        const {
            ownersAgeRangeFrom,
            ownersAgeRangeTo
        } = getOwnersAgeRange(investorGroup, memberId, updatedGeneralInflow.startDate, updatedGeneralInflow.endDate);
        updateGeneralInflow({
            ...updatedGeneralInflow,
            ownersAgeRangeFrom,
            ownersAgeRangeTo
        })
        setEditedInflowTimeFrame({
            ...editedInflowTimeFrame,
            ownersAgeRangeFrom,
            ownersAgeRangeTo
        });
    }

    const handleGrossAnnualFlowBlur = () => {
        const inflow = generalInflow.grossAnnualFlow || 0;
        updateGeneralInflow({
            ...generalInflow,
            grossAnnualFlow: inflow,
            netAnnualFlow: calculateNetAnnualFlow(inflow, generalInflow.taxRate)
        });
        setLastChanged(LastChangedFieldType.GrossAnnualInflow)
    }

    const handleTaxRateBlur = () => {
        let taxRate: string;
        if (parseFloat(generalInflow.taxRate) > 99) {
            taxRate = generalInflow.taxRate ? '99.00' : '0';
        } else {
            taxRate = generalInflow.taxRate ? generalInflow.taxRate : '0';
        }
        if (lastChanged === LastChangedFieldType.NetAnnualInflow) {
            updateGeneralInflow({
                ...generalInflow,
                taxRate: taxRate,
                grossAnnualFlow: calculateGrossAnnualFlow(generalInflow.netAnnualFlow, taxRate)
            });
        } else {
            updateGeneralInflow({
                ...generalInflow,
                taxRate: taxRate,
                netAnnualFlow: calculateNetAnnualFlow(generalInflow.grossAnnualFlow, taxRate),
            });
        }
    }

    const handleNetAnnualFlowBlur = () => {
        const inflow = generalInflow.netAnnualFlow || 0;
        updateGeneralInflow({
            ...generalInflow,
            netAnnualFlow: inflow,
            grossAnnualFlow: calculateGrossAnnualFlow(inflow, generalInflow.taxRate)
        });
        setLastChanged(LastChangedFieldType.NetAnnualInflow)
    }

    const handleYearsUntilFlowBlur = (_event: React.ChangeEvent<HTMLInputElement>, value: number) => {
        const editedYearsUntilFlow = value || 0;
        const resultingInflowTimeFrame = calculator.calculateUsingYearsUntilFlow(
            editedYearsUntilFlow,
            editedInflowTimeFrame,
            generalInflow.yearsUntilFlow);

        const isYearsUntilFlowClamped: boolean = resultingInflowTimeFrame.yearsUntilFlow !== editedYearsUntilFlow;

        setClampedInlineError({
            showMessage: isYearsUntilFlowClamped,
            inflowTimeframeField: InflowTimeframeFieldType.YearsUntilFlow
        });
        setEditedInflowTimeFrame(resultingInflowTimeFrame);
        updateGeneralInflow({
            ...generalInflow,
            ...resultingInflowTimeFrame
        });
    }

    const handleYearsOfFlowChange = (_event: React.ChangeEvent<HTMLInputElement>, value: number) => {
        setEditedInflowTimeFrame({
            ...editedInflowTimeFrame,
            yearsOfFlow: value,
        });
    }

    const handleYearsOfFlowBlur = (_event: React.ChangeEvent<HTMLInputElement>, value: number) => {
        const yearsOfFlow = value || 1;
        const currentDate = new Date();
        const resultingInflowTimeFrame = calculator.calculateUsingYearsOfFlow(yearsOfFlow, editedInflowTimeFrame, currentDate);
        const isYearsOfFlowClamped: boolean = resultingInflowTimeFrame.yearsOfFlow !== yearsOfFlow;

        setClampedInlineError({
            showMessage: isYearsOfFlowClamped,
            inflowTimeframeField: InflowTimeframeFieldType.YearsOfFlow
        });
        setEditedInflowTimeFrame(resultingInflowTimeFrame);
        const lengthInYears: number | null = inflowReserveLengthInYears && resultingInflowTimeFrame.yearsOfFlow < inflowReserveLengthInYears ? resultingInflowTimeFrame.yearsOfFlow : inflowReserveLengthInYears
        setInflowReserveLengthInYears(lengthInYears)
        updateGeneralInflow({
            ...generalInflow,
            ...resultingInflowTimeFrame,
            inflowReserveLength: lengthInYears
        })
    }

    const handleInflowYearsFromBlur = () => {
        const editedInflowYearsFrom = editedInflowTimeFrame.inflowYearsFrom;
        const resultingInflowTimeFrame = calculator.calculateUsingInflowYearsFrom(editedInflowYearsFrom, editedInflowTimeFrame, generalInflow.inflowYearsFrom);
        const inflowYearsFromClamped = resultingInflowTimeFrame.inflowYearsFrom !== editedInflowYearsFrom;

        setClampedInlineError({
            showMessage: inflowYearsFromClamped,
            inflowTimeframeField: InflowTimeframeFieldType.InflowYearsFrom
        });
        setEditedInflowTimeFrame(resultingInflowTimeFrame);
        updateGeneralInflow({
            ...generalInflow,
            ...resultingInflowTimeFrame
        });
    }

    const handleInflowYearsToBlur = () => {
        const editedInflowYearsTo = editedInflowTimeFrame.inflowYearsTo;
        const resultingInflowTimeFrame = calculator.calculateUsingInflowYearsTo(editedInflowYearsTo, editedInflowTimeFrame);
        const inflowYearsToClamped = resultingInflowTimeFrame.inflowYearsTo !== editedInflowYearsTo;

        setClampedInlineError({
            showMessage: inflowYearsToClamped,
            inflowTimeframeField: InflowTimeframeFieldType.InflowYearsTo
        });
        setEditedInflowTimeFrame(resultingInflowTimeFrame);
        updateGeneralInflow({
            ...generalInflow,
            ...resultingInflowTimeFrame
        })
    }

    const handleOwnersAgeRangeFromBlur = () => {
        const editedOwnersAgeRangeFrom = editedInflowTimeFrame.ownersAgeRangeFrom;
        const resultingInflowTimeFrame = calculator.calculateUsingOwnersAgeRangeFrom(editedOwnersAgeRangeFrom,
            editedInflowTimeFrame,
            generalInflow.ownersAgeRangeFrom);
        const ownersAgeRangeFromClamped = resultingInflowTimeFrame.ownersAgeRangeFrom !== editedOwnersAgeRangeFrom;

        setClampedInlineError({
            showMessage: ownersAgeRangeFromClamped,
            inflowTimeframeField: InflowTimeframeFieldType.OwnersAgeRangeFrom
        });
        setEditedInflowTimeFrame(resultingInflowTimeFrame);
        updateGeneralInflow({
            ...generalInflow,
            ...resultingInflowTimeFrame
        });
    }

    const handleOwnersAgeRangeToChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEditedInflowTimeFrame({
            ...editedInflowTimeFrame,
            ownersAgeRangeTo: parseInt(event.target.value || '0'),
        });
    }

    const handleOwnersAgeRangeToBlur = () => {
        const editedOwnersAgeRangeTo = editedInflowTimeFrame.ownersAgeRangeTo;
        const resultingInflowTimeFrame = calculator.calculateUsingOwnersAgeRangeTo(
            editedOwnersAgeRangeTo,
            editedInflowTimeFrame,
            generalInflow.ownersAgeRangeTo);
        const ownersAgeRangeToClamped = resultingInflowTimeFrame.ownersAgeRangeTo !== editedOwnersAgeRangeTo;

        setClampedInlineError({
            showMessage: ownersAgeRangeToClamped,
            inflowTimeframeField: InflowTimeframeFieldType.OwnersAgeRangeTo
        });
        setEditedInflowTimeFrame(resultingInflowTimeFrame);
        updateGeneralInflow({
            ...generalInflow,
            ...resultingInflowTimeFrame
        })
    }

    const handleOwnersAgeRangeFromChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEditedInflowTimeFrame({
            ...editedInflowTimeFrame,
            ownersAgeRangeFrom: parseInt(event.target.value || '0'),
        });
    }

    const handleInflowYearsToChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEditedInflowTimeFrame({
            ...editedInflowTimeFrame,
            inflowYearsTo: parseInt(event.target.value || '0'),
        });
    }


    const handleInflowYearsFromChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEditedInflowTimeFrame({
            ...editedInflowTimeFrame,
            inflowYearsFrom: parseInt(event.target.value || '0'),
        });
    }

    const handleChangeYearsUntilFlow = (_event: React.ChangeEvent<HTMLInputElement>, value: number) => {
        setEditedInflowTimeFrame({
            ...editedInflowTimeFrame,
            yearsUntilFlow: value,
        });
    };

    const handleResetTimeFrame = () => {
        const birthdate = getBirthdateForOwnerWithLongerPlanningPeriod(investorGroup);
        const startDate = calculateStartDate(birthdate, 0);
        const endDate = calculateEndDate(birthdate, 1, startDate);
        const {
            ownersAgeRangeFrom,
            ownersAgeRangeTo
        } = getOwnersAgeRange(investorGroup, getGeneralInflowOwnerId(), startDate, endDate);
        updateGeneralInflow({
            ...generalInflow,
            yearsOfFlow: 1,
            startDate,
            endDate,
            inflowYearsFrom: extractYear(startDate),
            inflowYearsTo: extractYear(endDate),
            ownersAgeRangeFrom,
            ownersAgeRangeTo,
            isInflowExpired: false,
        });
        const newInflowTimeFrame: InflowTimeFrameType = {
            yearsUntilFlow: 0,
            yearsOfFlow: 1,
            inflowYearsFrom: extractYear(startDate),
            inflowYearsTo: extractYear(endDate),
            startDate,
            endDate,
            ownersAgeRangeFrom,
            ownersAgeRangeTo
        };
        setEditedInflowTimeFrame(newInflowTimeFrame);
        setInflowFieldsReadOnly(false);
        setResetTimeFrameModalShown(false);
        const lengthInYears: number | null = inflowReserveLengthInYears && newInflowTimeFrame.yearsOfFlow < inflowReserveLengthInYears ? newInflowTimeFrame.yearsOfFlow : inflowReserveLengthInYears
        setInflowReserveLengthInYears(lengthInYears)
    }

    const handleConfirmationDisclosureModal = () => {
        setConfirmationDisclosureModalOpen(false);
        setIsDisclosureReviewed(true);
        updateGeneralInflow({...generalInflow, isDisclosureReviewed: true});
    }

    const handleCancel = () => {
        setConfirmationDisclosureModalOpen(false);
    }

    return <div>
        <DataEntryHeader
            title={props.formatTitle(generalInflow.description)}
            onPrimaryButtonClick={handleSave}
            disablePrimaryButton={isSaveButtonDisabled}
            onSecondaryButtonClick={onCancelClick}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
        />
        <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="future inflow"/>
        <div className="general-inflow__form layout-data-entry-form">
            <article>
                <AssetDetails onChangeType={handleChangeType}
                              onChangeTrustInflowType={handleChangeTrustInflowType}
                              generalInflow={generalInflow}
                              onChangeDescription={handleTextChange}
                              onBlurDescription={() => setIsDescriptionInlineErrorShown(!generalInflow.description.trim())}
                              descriptionInlineErrorShown={isDescriptionInlineErrorShown}
                              owner={selectedOwner}
                              primaryMember={primaryMember}
                              partnerMemberIfAlive={getPartnerMemberIfAlive()}
                              onChangeOwnership={handleOwnershipChange}
                              onChangeAnnualFlow={handleInputChange}
                              onBlurGrossAnnualFlow={handleGrossAnnualFlowBlur}
                              onChangeTaxRate={handleTaxRate}
                              onBlurTaxRate={handleTaxRateBlur}
                              onBlurNetAnnualFlow={handleNetAnnualFlowBlur}
                              releaseToggles={props.releaseToggles}
                />
                <InflowTimeFrame inflowFieldsReadOnly={inflowFieldsReadOnly}
                                 onShowResetInflowTimeFrameModal={() => setResetTimeFrameModalShown(true)}
                                 showResetTimeFrameModal={isResetTimeFrameModalShown}
                                 onCancelResetInflowTimeFrame={() => setResetTimeFrameModalShown(false)}
                                 onResetInflowTimeFrame={handleResetTimeFrame}
                                 editedInflowTimeFrame={editedInflowTimeFrame}
                                 onChangeYearsUntilFlow={handleChangeYearsUntilFlow}
                                 onBlurYearsUntilFlow={handleYearsUntilFlowBlur}
                                 generalInflow={generalInflow}
                                 showErrorOnYearsUntilFlow={showErrorForInflowTimeframeField(InflowTimeframeFieldType.YearsUntilFlow)}
                                 inflowTimeframeErrorDiv={getInflowTimeframeErrorDiv()}
                                 onChangeYearsOfFlow={handleYearsOfFlowChange}
                                 onBlurYearsOfFlow={handleYearsOfFlowBlur}
                                 showErrorOnYearsOfFlow={showErrorForInflowTimeframeField(InflowTimeframeFieldType.YearsOfFlow)}
                                 onChangeInflowYearsFrom={handleInflowYearsFromChange}
                                 onBlurInflowYearsFrom={handleInflowYearsFromBlur}
                                 showErrorOnInflowYearsFrom={showErrorForInflowTimeframeField(InflowTimeframeFieldType.InflowYearsFrom)}
                                 onChangeInflowYearsTo={handleInflowYearsToChange}
                                 onBlurInflowYearsTo={handleInflowYearsToBlur}
                                 showErrorOnInflowYearsTo={showErrorForInflowTimeframeField(InflowTimeframeFieldType.InflowYearsTo)}
                                 onChangeOwnersAgeRangeFrom={handleOwnersAgeRangeFromChange}
                                 onBlurOwnersAgeRangeFrom={handleOwnersAgeRangeFromBlur}
                                 showErrorOwnersAgeRangeFrom={showErrorForInflowTimeframeField(InflowTimeframeFieldType.OwnersAgeRangeFrom)}
                                 onChangeOwnersAgeRangeTo={handleOwnersAgeRangeToChange}
                                 onBlurOwnersAgeRangeTo={handleOwnersAgeRangeToBlur}
                                 showErrorOwnersAgeRangeTo={showErrorForInflowTimeframeField(InflowTimeframeFieldType.OwnersAgeRangeTo)}
                                 inflowTimeFrameWarningShown={isInflowTimeFrameWarningShown}
                                 ownersPlanningPeriod={formatPlanningPeriod(getOwnerInvestorGroupMember())}
                                 clampedInlineError={clampedInlineError}
                                 showProposalPlanningPeriod={showProposalPlanningPeriod()}
                                 proposalPlanningPeriod={formatPlanningPeriod(getMemberWithLongestPlanningPeriod(investorGroup))}
                                 onDismissAlertBanner={() => {
                                     setInflowTimeFrameWarningShown(false)
                                 }}
                                 showOwnersPlanningPeriod={!isJointOwnership}
                />
                <InflowCharacteristics
                    isInflowWillFundLifestyleGoal={generalInflow.isInflowWillFundLifestyleGoal}
                    isHighRisk={generalInflow.isHighRisk}
                    willAdjustWithInflation={generalInflow.willAdjustWithInflation}
                    onFundLifestyleGoalChange={handleFundLifestyleGoalCheckboxChange}
                    onChange={handleCheckboxChange}
                    interestRate={alignedInflow?.interestRate}
                />
                <InflowReserve
                    isInflowWillFundLifestyleGoal={generalInflow.isInflowWillFundLifestyleGoal}
                    inflowReserveLengthInYears={inflowReserveLengthInYears}
                    setInflowReserveLengthInYears={setInflowReserveLengthInYears}
                    yearsOfFlow={editedInflowTimeFrame.yearsOfFlow}
                    inflowReservePresentValue={alignedInflow?.inflowReservePresentValue}
                    onBlur={handleInflowReserveLengthChange}
                    releaseToggles={props.releaseToggles}
                />
                { showDisclosureForTrustInflow &&
                    <section>
                        <DisclosureForTrustInflow
                            onReviewDisclosure={() => setConfirmationDisclosureModalOpen(true)}
                            isDisclosureReviewed={isDisclosureReviewed}
                        />
                    </section>
                }
            </article>
            <aside>
                <DataEntrySummary
                    items={assetSummaryItems}
                    title="Asset Summary"
                    className="marginbottom-xxxl"
                />
                {
                    generalInflow.isInflowWillFundLifestyleGoal &&
                    <DataEntrySummary
                        items={assetAlignmentItems}
                        title="Asset Alignment"
                    />
                }
            </aside>
            <ConfirmReviewDisclosure
                isOpen={isConfirmationDisclosureModalOpen}
                isDisclosureReviewed={isDisclosureReviewed}
                onCancel={handleCancel}
                onAcknowledge={handleConfirmationDisclosureModal}
            />
        </div>
    </div>;
}
