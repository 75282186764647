export interface EstateSummary {
    estateValue: number;
    atAge: number;
    taxableEstateValue: number
    estimatedEstateTax: number
    charitableDeduction: number
    beneficiaryTotalValue: number
    unallocatedAmountToBeneficiaries: number
    federalTaxSummary: TaxSummary
    stateTaxSummary: TaxSummary
    memberBeneficiaries: MemberBeneficiary[]
    philanthropicBeneficiaries: PhilanthropicBeneficiary[]
}

export interface MemberBeneficiary {
    id: string
    name: string
    age: number
    presentValue: number
}

export interface PhilanthropicBeneficiary {
    philanthropyName: string
    value: number
}

export interface TaxSummary {
    effectiveTaxRate: number
    estimatedEstateTax: number
    taxableEstateValue: number
    totalAvailableExemption: number
}

export interface AllBequests {
    memberBequests: MemberBequest[],
    philanthropicBequests: PhilanthropicBequest[],
    residuaryMemberBequests: ResiduaryMemberBequest[]
}

export interface MemberBequest {
    memberId: string,
    presentValue: number,
}

interface PhilanthropicBequest {
    philanthropyName: string,
    value: number,
    type: PhilanthropicBequestType
}

export enum PhilanthropicBequestType {
    AMOUNT = 'AMOUNT',
    PERCENTAGE = 'PERCENTAGE'
}

export interface ResiduaryMemberBequest {
    memberId: string,
    percentage: number
}

export interface EstateTaxExemptionsWriteModel {
    primaryMemberExemption: PrimaryTaxExemptionWriteModel,
    partnerMemberExemption: PartnerTaxExemptionWriteModel
}

export interface PrimaryTaxExemptionWriteModel {
    usedTaxExemption: number,
}

export interface PartnerTaxExemptionWriteModel {
    usedTaxExemption: number | null,
    deceasedDate: string | null
}

export enum EstateType {
    CURRENT = "CURRENT",
    FUTURE = "FUTURE"
}