import React from 'react';
import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {formatCurrency, formatPercentWithWholeNumberAndTwoDecimals} from "../../utils/format";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {generateErrorableItem} from "../../components/DataEntry/DataEntryUtils";

export default function LifestyleSpendingReserveInformation({
                                                                lifestyleSpendingGoal,
                                                                profile,
                                                                portfolioReserve,
                                                                hasError
                                                            }: {
    lifestyleSpendingGoal: LifestyleSpendingGoal,
    profile: ProfileResponse,
    portfolioReserve: PortfolioReserveResponse,
    hasError: boolean
}) {
    let reserveTargetAmountItem: DataEntrySummaryItem = generateErrorableItem(
        'Reserve Target Amount',
        formatCurrency(lifestyleSpendingGoal.calculatedFields.reserveTargetAmount),
        hasError);
    let fundedByPortfolioReserveItem: DataEntrySummaryItem = generateErrorableItem(
        'Funded by Portfolio Reserve',
        formatCurrency(lifestyleSpendingGoal.calculatedFields.portfolioReservePresentValue),
        hasError);
    let fundedByInflowsItem: DataEntrySummaryItem = generateErrorableItem(
        'Funded by Inflows',
        formatCurrency(lifestyleSpendingGoal.calculatedFields.portfolioReserveAlignedInflowPresentValue),
        hasError);
    let tripleNetDiscountItem: DataEntrySummaryItem = generateErrorableItem(
        'Triple Net Discount Rate',
        formatPercentWithWholeNumberAndTwoDecimals(portfolioReserve.tripleNetDiscountRate)
        , hasError);
    return (
        <section>
            <DataEntrySummary title="Reserve Information"
                              items={[
                                  {
                                      label: 'Reserve Target Length',
                                      value: (profile.portfolioReserveTargetLength + ' years'),
                                      testId: "reserve-target-length",
                                  },
                                  reserveTargetAmountItem,
                                  fundedByPortfolioReserveItem,
                                  fundedByInflowsItem,
                                  tripleNetDiscountItem
                              ]}
            />
        </section>
    )
}
