import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-grid-system";
import CurrentVsProposedLegend from "../../../ClientManagement/AssetAllocation/CurrentVsProposedLegend";
import RiskDonut from "../../../ClientManagement/AssetAllocation/RiskDonut";
import EmptyAssetAllocation from "../../../ClientManagement/AssetAllocation/EmptyAssetAllocation";
import {
    COLUMN_WIDTH,
    CurrentVsProposedTableFooter,
    hasCurrentAllocation
} from "../../../ClientManagement/AssetAllocation/CurrentVsProposed";
import {
    AssetSubclassSummary,
    getFilteredAssetSubclassSummaries
} from "../../../ClientManagement/AssetAllocation/AssetHoldings";
import {riskAssetClasses, riskControlClasses} from "../../../ClientManagement/AssetAllocation/AssetClasses";
import {PresentationPaneHeader} from "../../../components";
import PrintViewWrapper from "../PrintViewWrapper";
import {CurrentAllocationResponse} from "../../../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import {ProposedAllocationResponse} from "../../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {COLOR_ASSETS_ACCOUNTS, COLOR_RISK_CONTROL_ASSETS} from "../../../constants/colors";
import AssetHoldingsReport from "./AssetHoldingsReport";
import {splitCurrentVsProposed} from "./CurrentVsProposedReportUtil";

export type CurrentVsProposedReportPageData = {
    riskAssets: AssetSubclassSummary[],
    riskControl: AssetSubclassSummary[],
    continueLabel: string
}
type CurrentVsProposedContentProps = {
    currentAllocation: CurrentAllocationResponse;
    proposedAllocation: ProposedAllocationResponse;
    displayName: string;
}

const CurrentVsProposedReportView: React.FC<CurrentVsProposedContentProps> = ({
                                                                                  currentAllocation,
                                                                                  proposedAllocation,
                                                                                  displayName
                                                                              }) => {
    const [currentVsProposedPage, setCurrentVsProposedPage] = useState<CurrentVsProposedReportPageData | null>(null);
    const [currentVsProposedPages, setCurrentVsProposedPages] = useState<CurrentVsProposedReportPageData[]>([]);
    const totalCurrentAllocationAmount = currentAllocation ? currentAllocation.totalRiskAssetsAmount + currentAllocation.totalRiskControlAssetsAmount : 0;
    const totalProposedAllocationAmount = proposedAllocation ? proposedAllocation.totalRiskAssetsAmount + proposedAllocation.totalRiskControlAssetsAmount : 0;
    const totalCurrentAllocationPercentage = currentAllocation ? currentAllocation.totalRiskAssetsPercent + currentAllocation.totalRiskControlAssetsPercent : 0;
    const totalProposedAllocationPercentage = 100;

    let totalRiskAssetsAmount: number | undefined;
    let totalRiskControlAssetsAmount: number | undefined;
    let totalRiskControlAssetsPercentage: number | undefined;
    let totalRiskControlAssetsAmountPercentage: number | undefined;
    if (proposedAllocation.totalRiskControlAssetsAmount !== 0 || proposedAllocation.totalRiskAssetsAmount !== 0) {
        totalRiskAssetsAmount = proposedAllocation.totalRiskAssetsAmount;
        totalRiskControlAssetsAmount = proposedAllocation.totalRiskControlAssetsAmount;
        totalRiskControlAssetsPercentage = proposedAllocation.totalRiskAssetsPercent;
        totalRiskControlAssetsAmountPercentage = proposedAllocation.totalRiskControlAssetsPercent;
    }

    useEffect(() => {
        const filteredRiskAssetSubclassSummaries = getFilteredAssetSubclassSummaries(
            riskAssetClasses,
            currentAllocation.riskAssets,
            proposedAllocation.riskAssets
        );
        const filteredRiskControlSubclassSummaries = getFilteredAssetSubclassSummaries(
            riskControlClasses,
            currentAllocation.riskControl,
            proposedAllocation.riskControlAssets
        );
        setCurrentVsProposedPage({
            riskAssets: filteredRiskAssetSubclassSummaries,
            riskControl: filteredRiskControlSubclassSummaries,
            continueLabel: ""
        })
    }, [currentAllocation, proposedAllocation]);

    useEffect(() => {
        if (currentVsProposedPage) {
            splitCurrentVsProposed(currentVsProposedPage, currentAllocation, proposedAllocation).then((res: CurrentVsProposedReportPageData[]) => {
                setCurrentVsProposedPages(res);
            });
        }
    }, [currentVsProposedPage])

    const InvestableAssets = <div className="investable-assets" style={{
        maxWidth: '315px',
        minWidth: '315px',
    }}></div>;
    const containerRef = useRef<Container & HTMLDivElement>(null);
    const totalNoOfPages = currentVsProposedPages.length;

    return (
        <>{currentVsProposedPages.map((currentPage: CurrentVsProposedReportPageData, index) => {
            const riskAsset = currentPage.riskAssets;
            const riskControl = currentPage.riskControl;
            const isLastPage = (index === totalNoOfPages - 1)
            const nonZeroRiskAssets = !!riskAsset.length;
            const nonZeroRiskControl = !!riskControl.length;
            const riskAssetContinueLabel = currentPage.continueLabel === "RA" ? "Risk Assets (continued)" : "Risk Assets";
            const riskControlContinueLabel = currentPage.continueLabel === "RCA" ? "Risk Control Assets (continued)" : "Risk Control Assets";

            return (
                <PrintViewWrapper displayName={displayName} key={index}>
                    <div data-testid="current-proposed-page" className="current-proposed-page">
                        <PresentationPaneHeader
                            displayName=""
                            title="Current vs. Proposed Asset Allocation"
                        />
                        <Container
                            ref={containerRef}
                            fluid className="current-proposed-container">
                            {hasCurrentAllocation(currentAllocation) ? <>
                                    <Row className={"row"}>
                                        {InvestableAssets}
                                        <>
                                            <Col width={COLUMN_WIDTH}
                                                 className={"allocation-donut"}
                                                 data-testid={'current-allocation-donut'}>
                                                <span className="title">Current Allocation</span>
                                                <CurrentVsProposedLegend
                                                    totalRiskAssetsPercent={currentAllocation.totalRiskAssetsPercent}
                                                    totalRiskControlAssetsPercent={currentAllocation.totalRiskControlAssetsPercent}
                                                />
                                                <RiskDonut data={currentAllocation}
                                                           donutSize={'md'}/>
                                            </Col>
                                        </>
                                        <Col width={COLUMN_WIDTH}
                                             className={"allocation-donut"}
                                             data-testid={'proposed-allocation-donut'}>
                                            <span className="title">Proposed Allocation</span>
                                            <CurrentVsProposedLegend
                                                totalRiskAssetsPercent={proposedAllocation.totalRiskAssetsPercent}
                                                totalRiskControlAssetsPercent={proposedAllocation.totalRiskControlAssetsPercent}
                                            />
                                            <RiskDonut data={proposedAllocation}
                                                       donutSize={'md'}/>
                                        </Col>
                                        <Col width={COLUMN_WIDTH}
                                             style={{minWidth: COLUMN_WIDTH}}
                                             className={"investable-assets"}>
                                        </Col>
                                    </Row>
                                    {nonZeroRiskAssets && <AssetHoldingsReport
                                        id="CurrentProposedRiskAssetsAccordionReportId"
                                        assetClassName={"Risk Assets"}
                                        total={currentAllocation.totalRiskAssetsAmount}
                                        totalProposed={totalRiskAssetsAmount}
                                        totalPercentage={currentAllocation.totalRiskAssetsPercent}
                                        totalProposedPercentage={totalRiskControlAssetsPercentage}
                                        accentColor={COLOR_ASSETS_ACCOUNTS}
                                        assetSubclassSummaries={riskAsset}
                                        continueLabel={riskAssetContinueLabel}/>}
                                    {nonZeroRiskControl && <AssetHoldingsReport
                                        id={"CurrentProposedRiskControlAssetsAccordionReportId"}
                                        assetClassName={"Risk Control Assets"}
                                        total={currentAllocation.totalRiskControlAssetsAmount}
                                        totalProposed={totalRiskControlAssetsAmount}
                                        totalPercentage={currentAllocation.totalRiskControlAssetsPercent}
                                        totalProposedPercentage={totalRiskControlAssetsAmountPercentage}
                                        accentColor={COLOR_RISK_CONTROL_ASSETS}
                                        assetSubclassSummaries={riskControl}
                                        continueLabel={riskControlContinueLabel}/>}
                                    {isLastPage && <CurrentVsProposedTableFooter
                                        currentAllocationAmount={totalCurrentAllocationAmount}
                                        proposedAllocationAmount={totalProposedAllocationAmount}
                                        currentAllocationPercentage={totalCurrentAllocationPercentage}
                                        proposedAllocationPercentage={totalProposedAllocationPercentage}/>}
                                </>
                                : <EmptyAssetAllocation/>
                            }
                        </Container>
                    </div>
                </PrintViewWrapper>
            )
        })
        }</>
    )
}

export default CurrentVsProposedReportView;