import {AssetTotals, emptyAssetTotals} from "../../../Assets/models/Assets";
import {emptyInvestorGroup, InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {emptyLifestyleGoalForm, LifestyleSpendingGoal} from "../../models/LifestyleSpendingGoal";
import {emptyPortfolioReserveResponse, PortfolioReserveResponse} from "../../models/PortfolioReserve";
import {emptyProfileResponse, ProfileResponse} from "../../../ClientManagement/models/ProfileResponse";
import {AllGoalsType, emptyTaxLiabilities, TaxLiabilities} from "../../models/GoalModelType";

export type PortfolioReserveModelType = {
    assets: AssetTotals;
    investorGroup: InvestorGroupType;
    lifestyleSpendingGoal: LifestyleSpendingGoal;
    portfolioReserve: PortfolioReserveResponse;
    profile: ProfileResponse;
    normalizedNonLifestyleGoals: {
        nonLifestyleRiskControl: number;
        nonLifestyleTotalPresentValue: number;
        goals: { annualFlow: number, yearsOfFlow: number, yearsUntilFlow: number }[]
    }
    familyGoals: AllGoalsType['family']
    nonLifestyleGoals: AllGoalsType['nonLifestyle']
    taxLiabilities: TaxLiabilities;
}

export const emptyPortfolioReserveModel: PortfolioReserveModelType = {
    lifestyleSpendingGoal: emptyLifestyleGoalForm(emptyInvestorGroup.planningPeriod),
    profile: emptyProfileResponse,
    portfolioReserve: emptyPortfolioReserveResponse,
    assets: emptyAssetTotals,
    investorGroup: emptyInvestorGroup,
    normalizedNonLifestyleGoals: {
        nonLifestyleRiskControl: 0,
        nonLifestyleTotalPresentValue: 0,
        goals: []
    },
    familyGoals: {},
    nonLifestyleGoals: [],
    taxLiabilities: emptyTaxLiabilities
}
