import React from "react";
import {Table} from "xps-react";
import {formatCurrency} from "../../../utils/format";
import {
    getGoalsForDisplay,
    makeNonLifestyleGoalListTableRowData,
    NonLifestyleGoalListProps,
    NonLifestyleGoalRow,
    SUPPORTED_GOAL_TYPES
} from "../../../Goals/components/NonLifestyleGoalList";
import {GoalType, NonLifestyleGoalType} from "../../../Goals/models/GoalType";
import {GoalBarChartKeyColors} from "../../../Goals/BarChartSidebar/GoalBarChartKeyColors";
import {Accordion, AccordionItem, Icon} from "../../../components";
import NonLifestyleGoalListHeader from "../../../Goals/Summary/NonLifestyleGoalListHeader";
import GoalUtils from "../../../Goals/Summary/GoalUtils";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";

const NonLifestyleGoalListTable = ({
                                       type,
                                       goalsForDisplay,
                                       investorGroup
                                   }: { type: GoalType, goalsForDisplay: NonLifestyleGoalType[], investorGroup: InvestorGroupType }) => {

    return <div data-testid={`${type}-goal-table`}>
        <Table
            resizable={false}
            showPagination={false}
            sortable={false}
            defaultPageSize={9999}
            data={makeNonLifestyleGoalListTableRowData(goalsForDisplay, investorGroup)}
            columns={
                [
                    {
                        minWidth: 30,
                        maxWidth: 30,
                    },
                    {
                        accessor: 'name',
                        Cell: ({original: nonLifestyleRow}: { original: NonLifestyleGoalRow }) => {
                            return (
                                <div className="display-flex flex-column justify-content-center height-100p">
                                    <span className="table-label-15px">
                                        {nonLifestyleRow.name}
                                        {nonLifestyleRow.isFundedByNonInvestableAssets &&
                                            <Icon name='link' size="large" color="#000000"/>}
                                    </span>
                                </div>
                            );
                        },
                        Header: 'Name',
                    },
                    {
                        accessor: 'annual-flow',
                        Cell: ({original: nonLifestyleRow}: { original: NonLifestyleGoalRow }) => {
                            return (
                                <div
                                    className="display-flex flex-column justify-content-center align-items-end height-100p">
                                    <span
                                        className="table-label-15px">{formatCurrency(nonLifestyleRow.annualFlow)}</span>
                                </div>
                            );
                        },
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'Annual Flow',
                        maxWidth: 128
                    },
                    {
                        accessor: 'years-until-flow',
                        Cell: ({original: nonLifestyleRow}: { original: NonLifestyleGoalRow }) => {
                            return (
                                <div
                                    className="display-flex flex-column justify-content-center align-items-end height-100p">
                                    <span className="table-label-15px">{nonLifestyleRow.yearsUntilFlow}</span>
                                </div>
                            );
                        },
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'Years Until Flow',
                        maxWidth: 128
                    },
                    {
                        accessor: 'years-of-flow',
                        Cell: ({original: nonLifestyleRow}: { original: NonLifestyleGoalRow }) => {
                            return (
                                <div
                                    className="display-flex flex-column justify-content-center align-items-end height-100p">
                                    <span className="table-label-15px">{nonLifestyleRow.yearsOfFlow}</span>
                                </div>
                            );
                        },
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'Years of Flow',
                        maxWidth: 128
                    },
                    {
                        accessor: 'present-value',
                        Cell: ({original: nonLifestyleRow}: { original: NonLifestyleGoalRow }) => {
                            return (
                                <div
                                    className="display-flex flex-column justify-content-center align-items-end height-100p">
                                    <span
                                        className="table-label-15px">{formatCurrency(nonLifestyleRow.presentValue)}</span>
                                </div>
                            );
                        },
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'Present Value',
                        maxWidth: 128
                    }
                ]
            }
        />
    </div>;
}

interface NonLifestyleGoalListReportProps {
    goalModel: GoalModelType
}

const barChartKeyColor = (type: GoalType) => {
    let color;
    switch (type) {
        case GoalType.DISCRETIONARY:
            color = GoalBarChartKeyColors.DISCRETIONARY
            break;
        case GoalType.PHILANTHROPIC:
            color = GoalBarChartKeyColors.PHILANTHROPIC
            break;
        default:
            break;
    }
    return color;
}

const NonLifestyleGoalListReport = ({
                                        type,
                                        label,
                                        displayHeader,
                                        goalModel
                                    }: NonLifestyleGoalListProps & NonLifestyleGoalListReportProps) => {
    const goalsForDisplay: NonLifestyleGoalType[] = getGoalsForDisplay(type, goalModel);
    const {investorGroup} = goalModel;
    if (!SUPPORTED_GOAL_TYPES.includes(type)) {
        throw new Error(`This component does not support goals of type ${type}`);
    }

    if (goalsForDisplay.length === 0) {
        return null;
    }
    const color = barChartKeyColor(type);
    return (
        <section data-testid={`${type}-goal-list`}>
            <div className="accordion-tables">
                <Accordion
                    expanded={[`NonLifestyleGoalListReport-${type}`]}
                    accordionId={`${type}GoalListAccordionReport`}
                    allowMultipleExpanded
                    allowZeroExpanded
                >
                    {displayHeader && <NonLifestyleGoalListHeader/>}
                    <AccordionItem
                        uuid={`NonLifestyleGoalListReport-${type}`}
                        accentColor={color}
                        primaryText={label}
                        rightAlignedContent={
                            <div className="display-flex align-items-center">
                                <div>
                                    <span className="font-lg paddingright-xl">
                                        {formatCurrency(GoalUtils.getNonLifestyleGoalCumulativeTotalPresentValue(goalsForDisplay))}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <NonLifestyleGoalListTable type={type} goalsForDisplay={goalsForDisplay}
                                                   investorGroup={investorGroup}/>
                    </AccordionItem>
                </Accordion>
            </div>
        </section>

    )
}

export default NonLifestyleGoalListReport;
