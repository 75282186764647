export enum PortfolioReserveOptionsType {
    SHOW_ASSET_ALLOCATION,
    SHOW_RESERVE_TARGET_DETAILS,
    SHOW_RISK_IMPACT,
    SHOW_OUTCOME_RANGE
}

export type PortfolioReserveUserPreferences = {
    profileId?: string;
    portfolioReserveOptions: PortfolioReserveOptions;
    editablePortfolioReserve?: boolean;
    clickedInputYear?: boolean;
    editedMinimumRisk?: boolean;
    navigatedBeforeSavingPortfolioReserve?: boolean;
}

export type PortfolioReserveOptions = {
    showAssetAllocation: boolean;
    showReserveTargetDetails:  boolean;
    showRiskImpact:  boolean;
    showOutcomeRange: boolean;
}

export const emptyPortfolioReserveOptions : PortfolioReserveOptions = {
    showAssetAllocation: false,
    showReserveTargetDetails:  false,
    showRiskImpact:  false,
    showOutcomeRange: false
}

export const emptyPortfolioReserveUserPreferences: PortfolioReserveUserPreferences = {
    portfolioReserveOptions: emptyPortfolioReserveOptions,
    editablePortfolioReserve: false
}
