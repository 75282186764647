import React, {FC, useEffect, useState} from "react";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";
import {App_Conf} from "../../../core/app_conf";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectActiveMeeting, selectMeetingModalVisibility, setMeetingModalVisibility} from "../meetingSlice";
import QRCode from "qrcode";

const MeetingInfoModal: FC = () => {
    const meeting = useAppSelector(selectActiveMeeting);

    const dispatch = useAppDispatch();
    const modalVisibility = useAppSelector(selectMeetingModalVisibility);

    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const [qrCodeImageSize, setQrCodeImageSize] = useState<{width: number, height: number}>({width: 0, height: 0});

    const {APP_URL} = App_Conf;

    let generateCodes = true;

    useEffect(() => {
        if (generateCodes) {generateQRCode()}
        return () => {
            generateCodes = false
        }
    }, [])

    useEffect(() => {
        if (qrCodeUrl !== '') {
            renderQRCode();
        }
    }, [qrCodeUrl, qrCodeImageSize])

    const renderQRCode = () => {
        const canvasElement: HTMLCanvasElement | null = document.querySelector(`.qr-code`);
        if (canvasElement) {
            if (qrCodeImageSize.width > 0 && qrCodeImageSize.height > 0) {
                canvasElement.width = qrCodeImageSize.width;
                canvasElement.height = qrCodeImageSize.height;
            }
            const canvasContext = canvasElement.getContext('2d');
            if (canvasContext) {
                const canvasImage = new Image();
                canvasImage.onload = () => {
                    canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
                    canvasContext.drawImage(canvasImage, 0, 0);
                    if (qrCodeImageSize.width === 0 && qrCodeImageSize.height === 0) {
                        setQrCodeImageSize({width: canvasImage.naturalWidth, height: canvasImage.naturalHeight});
                    }
                };
                canvasImage.src = qrCodeUrl;
            }
        }
    }

    const generateQRCode = () => {
        const text = `${APP_URL}/meetings?id=${meeting?.onlineMeetingId}&code=${meeting?.onlineMeetingCode}`
        QRCode.toDataURL(text, {errorCorrectionLevel: 'L', margin: 0, scale: 5})
            .then(url => {
                if (generateCodes) {setQrCodeUrl(url)}
            })
    }

    return <ModalWrapper
        id={'meeting-info'}
        isOpen={modalVisibility?.meetingInfo}
        headerText={meeting?.title}
        buttons={[
            {
                text: "Close",
                onClick: () => {
                    dispatch(setMeetingModalVisibility({
                        ...modalVisibility,
                        meetingInfo: false,
                    }));
                },
            }
        ]}>
        {
            <>
                <div>
                    <div className="meeting-info-modal-scan-qr-instructions">Scan the QR code with your tablet to join the meeting, or join manually using the information below.</div>
                    <div className="meeting-info-modal-qr-code-box">
                        <canvas className="qr-code"/>
                    </div>
                    <div className="meeting-info-modal-text">
                        <div>{APP_URL + "/meetings"}</div>
                    </div>
                    <div className="meeting-info-modal-text">
                        <div><span className="meeting-info-modal-text-bold">Meeting ID:</span> {meeting?.onlineMeetingId}</div>
                        <div><span className="meeting-info-modal-text-bold">Passcode:</span> {meeting?.onlineMeetingCode}</div>
                    </div>
                </div>
            </>
        }
    </ModalWrapper>
}

export default MeetingInfoModal;