import {formatCurrency, formatNumberRoundedToTwoDecimals, formatNumberRoundedToWholeNumber,} from "../../utils/format";
import {AssetSubclassesSummary} from "./AssetSubclassesSummary";

export const findSubclassSummary = (
    subclass: string,
    summaries: AssetSubclassesSummary[]
): NullableAssetSubclassesSummary => {
    const subclassSummaries = summaries.filter(
        (summary) => summary.name === subclass
    );
    return subclassSummaries && subclassSummaries.length > 0
        ? subclassSummaries[0]
        : null;
};

export const EMPTY_ALLOCATION = "--";

export const getTotalInvestibleAmount = (
    summary: NullableAssetSubclassesSummary
): number | string => {
    return summary && summary.totalInvestableAmount
        ? formatCurrency(summary.totalInvestableAmount)
        : EMPTY_ALLOCATION;
};

export const getTotalInvestiblePercent = (
    summary: NullableAssetSubclassesSummary
): string => {
    return summary && summary.totalInvestablePercent
        ? formatNumberRoundedToTwoDecimals(summary.totalInvestablePercent) + "%"
        : EMPTY_ALLOCATION;
};

export const getTotalInvestiblePercentForClassification = (
    totalInvestableAmount?: number, totalClassificationAmount?: number
): string => {
    if (totalInvestableAmount && totalClassificationAmount) {
        const totalInvestablePercentInClassification = (totalInvestableAmount / totalClassificationAmount) * 100;
        return formatNumberRoundedToTwoDecimals(totalInvestablePercentInClassification) + "%";
    } else {
        return EMPTY_ALLOCATION;
    }
};

export const getDifference = (
    current: NullableAssetSubclassesSummary,
    proposed: NullableAssetSubclassesSummary
): AllocationDifference => {
    if (!current?.totalInvestableAmount && !proposed?.totalInvestableAmount) {
        return {
            differenceAmount: EMPTY_ALLOCATION,
            isNegative: false,
            differencePercentage: EMPTY_ALLOCATION,
        };
    }
    const currentAmount = current?.totalInvestableAmount
        ? current.totalInvestableAmount
        : 0;
    const proposedAmount = proposed?.totalInvestableAmount
        ? proposed.totalInvestableAmount
        : 0;
    const differenceAmt = proposedAmount - currentAmount;

    const currentPercent = current?.totalInvestablePercent
        ? current.totalInvestablePercent
        : 0;
    const proposedPercent = proposed?.totalInvestablePercent
        ? proposed.totalInvestablePercent
        : 0;
    const differencePercent = proposedPercent - currentPercent;

    return {
        differenceAmount: formatCurrency(differenceAmt),
        differencePercentage:
            formatNumberRoundedToTwoDecimals(differencePercent) + "%",
        isNegative: differenceAmt < 0,
    };
};

type NullableAssetSubclassesSummary = AssetSubclassesSummary | null;

export type AllocationDifference = {
    differenceAmount: string;
    differencePercentage: string;
    isNegative: boolean;
};

export const formatAllocationPercentage = (value: number) => {
    if (value === 0 || value === 100) {
        return formatNumberRoundedToWholeNumber(value) + "%";
    }
    return formatNumberRoundedToTwoDecimals(value) + "%";
};