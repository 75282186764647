import { ClientMeetingDocumentsResponse } from "../models/ClientDocuments";
import {SubMenuProps} from "../../models/routeData/RouteParamTypes";

export function generateClientMeetingDocumentsResponse(overrides?: Partial<ClientMeetingDocumentsResponse>): ClientMeetingDocumentsResponse {
    return generateMeetingDocumentsResponse("Client", overrides);
}

export function generateMyMeetingDocumentsResponse(overrides?: Partial<ClientMeetingDocumentsResponse>): ClientMeetingDocumentsResponse {
    return generateMeetingDocumentsResponse("My", overrides);
}

export function generateMeetingDocumentsResponse(type: "Client" | "My", overrides?: Partial<ClientMeetingDocumentsResponse>): ClientMeetingDocumentsResponse {
    return {
        documentItems: [
            {
                id: `${type} Folder Doc1 - id`,
                name: `${type} Folder Doc1.pdf`,
                downloadUrl: ""
            },
            {
                id: `${type} Folder Doc2 - id`,
                name: `${type} Folder Doc2.pdf`,
                downloadUrl: ""
            },
            {
                id: `${type} Folder Doc3 - id`,
                name: `${type} Folder Doc3.pdf`,
                downloadUrl: ""
            }
        ],
        folderItems: [
            {
                id: `${type} Folder1 - id`,
                name: `${type} Folder1`,
                driveItems: [{
                    id: `${type} Folder1 Doc1 - id`,
                    name: `${type} Folder1 Doc1`,
                    downloadUrl: ""
                },
                    {
                        id: `${type} Folder1 Doc2 - id`,
                        name: `${type} Folder1 Doc2`,
                        downloadUrl: ""
                    },
                    {
                        id: `${type} Folder1 Doc3 - id`,
                        name: `${type} Folder1 Doc3`,
                        downloadUrl: ""
                    }],
                folderItems: [
                    {
                        id: `${type} Folder2 - id`,
                        name: `${type} Folder2`,
                        driveItems: [{
                            id: `${type} Folder2 Doc1 - id`,
                            name: `${type} Folder2 Doc1`,
                            downloadUrl: ""
                        },
                            {
                                id: `${type} Folder2 Doc2 - id`,
                                name: `${type} Folder2 Doc2`,
                                downloadUrl: ""
                            },
                            {
                                id: `${type} Folder2 Doc3 - id`,
                                name: `${type} Folder2 Doc3`,
                                downloadUrl: ""
                            }],
                        folderItems: [
                            {
                                id: `${type} Folder3 - id`,
                                name: `${type} Folder3`,
                                driveItems: [{
                                    id: `${type} Folder3 Doc1 - id`,
                                    name: `${type} Folder3 Doc1`,
                                    downloadUrl: ""
                                },
                                    {
                                        id: `${type} Folder3 Doc2 - id`,
                                        name: `${type} Folder3 Doc2`,
                                        downloadUrl: ""
                                    },
                                    {
                                        id: `${type} Folder3 Doc3 - id`,
                                        name: `${type} Folder3 Doc3`,
                                        downloadUrl: ""
                                    }],
                                folderItems: [{
                                    id: `${type} Folder4 - id`,
                                    name: `${type} Folder4`,
                                    driveItems: [{
                                        id: `${type} Folder4 Doc1 - id`,
                                        name: `${type} Folder4 Doc1`,
                                        downloadUrl: ""
                                    },
                                        {
                                            id: `${type} Folder4 Doc2 - id`,
                                            name: `${type} Folder4 Doc2`,
                                            downloadUrl: ""
                                        },
                                        {
                                            id: `${type} Folder4 Doc3 - id`,
                                            name: `${type} Folder4 Doc3`,
                                            downloadUrl: ""
                                        }],
                                    folderItems: [],
                                }],
                            },
                            {
                                id: `${type} Folder5 - id`,
                                name: `${type} Folder5`,
                                driveItems: [{
                                    id: `${type} Folder5 Doc1 - id`,
                                    name: `${type} Folder5 Doc1`,
                                    downloadUrl: ""
                                },
                                    {
                                        id: `${type} Folder5 Doc2 - id`,
                                        name: `${type} Folder5 Doc2`,
                                        downloadUrl: ""
                                    },
                                    {
                                        id: `${type} Folder5 Doc3 - id`,
                                        name: `${type} Folder5 Doc3`,
                                        downloadUrl: ""
                                    }],
                                folderItems: [],
                            }
                        ],
                    }
                ],
            },
            {
                id: `${type} Folder6 - id`,
                name: `${type} Folder6`,
                driveItems: [{
                    id: `${type} Folder6 Doc1 - id`,
                    name: `${type} Folder6 Doc1`,
                    downloadUrl: ""
                },
                    {
                        id: `${type} Folder6 Doc2 - id`,
                        name: `${type} Folder6 Doc2`,
                        downloadUrl: ""
                    },
                    {
                        id: `${type} Folder6 Doc3 - id`,
                        name: `${type} Folder6 Doc3`,
                        downloadUrl: ""
                    }],
                folderItems: [],
            }
        ],
        folderItem: undefined,
        ...overrides
    };
}

export function generateDisclaimerTableDataResponse() {
    return {
        "returnRateValuesDtos": [
            {
                "type": "Risk Control Allocation",
                "minimumRiskReturn": 100,
                "lowRiskReturn": 93,
                "moderatelyLowRiskReturn": 64,
                "moderatelyRiskReturn": 42,
                "moderatelyHighRiskReturn": 21,
                "maximumRiskReturn": 0
            },
            {
                "type": "Risk Assets Allocation",
                "minimumRiskReturn": 0,
                "lowRiskReturn": 7,
                "moderatelyLowRiskReturn": 36,
                "moderatelyRiskReturn": 58,
                "moderatelyHighRiskReturn": 79,
                "maximumRiskReturn": 100
            },
            {
                "type": "Projected Geometric Average Return",
                "minimumRiskReturn": 3.2,
                "lowRiskReturn": 3.6,
                "moderatelyLowRiskReturn": 4.9,
                "moderatelyRiskReturn": 5.8,
                "moderatelyHighRiskReturn": 6.6,
                "maximumRiskReturn": 7.3
            },
            {
                "type": "Projected Arithmetic Average Return",
                "minimumRiskReturn": 3.3,
                "lowRiskReturn": 3.6,
                "moderatelyLowRiskReturn": 5.1,
                "moderatelyRiskReturn": 6.2,
                "moderatelyHighRiskReturn": 7.2,
                "maximumRiskReturn": 8.3
            },
            {
                "type": "Standard Deviation",
                "minimumRiskReturn": 3,
                "lowRiskReturn": 3.1,
                "moderatelyLowRiskReturn": 5.7,
                "moderatelyRiskReturn": 8.4,
                "moderatelyHighRiskReturn": 11.2,
                "maximumRiskReturn": 14.1
            },
            {
                "type": "Highest 10% Threshold",
                "minimumRiskReturn": 7.2,
                "lowRiskReturn": 7.6,
                "moderatelyLowRiskReturn": 12.4,
                "moderatelyRiskReturn": 17,
                "moderatelyHighRiskReturn": 21.6,
                "maximumRiskReturn": 26.3
            },
            {
                "type": "Lowest 10% Threshold",
                "minimumRiskReturn": -0.6,
                "lowRiskReturn": -0.3,
                "moderatelyLowRiskReturn": -2.2,
                "moderatelyRiskReturn": -4.6,
                "moderatelyHighRiskReturn": -7.2,
                "maximumRiskReturn": -9.8
            }
        ]
    }
}

export const EMPTY_DISCLAIMER_TABLE_DATA = {
    returnRateValuesDtos : []
}

function generateSubMenuFolder(index: number) : SubMenuProps {
    return {
        id: `ClientFolder${index}`,
        title: `Client Folder${index}`,
        children: [
            {
                label: `Client Folder${index} Doc1.pdf`,
                link: `Doc1-id`
            },
            {
                label: `Client Folder${index} Doc2.pdf`,
                link: `Doc2-id`
            },
            {
                label: `Client Folder${index} Doc3.pdf`,
                link: `Doc3-id`
            }
        ]
    }
};

export function generateSubMenuOptionsForClientDocuments(): SubMenuProps[] {
    return [
        {
            ...generateSubMenuFolder(1),
            subMenuFolders: [
                {
                    ...generateSubMenuFolder(2),
                    subMenuFolders: [
                        {
                            ...generateSubMenuFolder(3),
                            subMenuFolders: [
                                {
                                    ...generateSubMenuFolder(4),
                                    subMenuFolders: []
                                }
                            ]
                        },
                        {
                            ...generateSubMenuFolder(5),
                            subMenuFolders: []
                        }
                    ]
                }
            ]
        },
        {
            ...generateSubMenuFolder(6),
            subMenuFolders: []
        }
    ];
}