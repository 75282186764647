import {ColumnCounter, TableCell} from "../../../components";
import {
    AssetRelianceAssetType,
    AssetRelianceStack
} from "../../../ClientManagement/models/AssetRelianceResponse";
import {AssetTableDisplayReport} from "./AssetTableDisplayReport";

export class ReviewAssetTableDisplayReport extends AssetTableDisplayReport {
    constructor(
        columnCounter: ColumnCounter,
        protected readonly rowStacks_: AssetRelianceStack[],
        protected readonly columnStacks_: AssetRelianceStack[],
        protected readonly originalRowStacks_: AssetRelianceStack[],
        protected readonly isAssetContinued_ : boolean
    ) {
        super(columnCounter, rowStacks_, columnStacks_,originalRowStacks_,isAssetContinued_);
    }

    protected get headerValues(): TableCell<number>[] {
        let headerValues_: number[] = [];
        if(this.isAssetContinued_)
        {
            headerValues_ = Array(this.columnCount).fill(null);
        }else
        {
            headerValues_ = Array(this.columnCount).fill(0);
            if (this.columnCount > 0) {
                for (let index_ = 0; index_ < this.columnCount; index_++) {
                    const stack_ = this.columnStacks_[index_];
                    if (stack_.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
                        headerValues_[index_] = stack_.investableValue;
                    } else {
                        headerValues_[index_] = stack_.netAssets;
                    }
                }
            }
        }

        return headerValues_.map((value) => this.createTableCell({value}));
    }
}