import {emptyLifestylePresentValueResponse, LifestylePresentValueResponse} from "./PresentValue";
import {PlanningPeriodType} from "../../ClientManagement/models/InvestorGroupType";

export type LifestyleSpendingPeriodInputs = {
    id?: string;
    description: string;
    annualSpend: number;
    startYear: number;
    endYear: number;
}

export type LifestyleSpendingGoalResponse = {
    id?: string;
    clientId: string;
    userInputs: LifestyleSpendingGoalInputs
    calculatedFields: LifestylePresentValueResponse
}

export type LifestyleSpendingGoalInputs = {
    lifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[];
    isFundedByNonInvestableAssets: boolean;
}

export type LifestyleSpendingGoal = {
    id?: string;
    calculatedFields: LifestylePresentValueResponse;
    userInputs: LifestyleSpendingGoalInputs
};


export type InflowPresentValue = {
    description: string;
    lifestyleGoalAligned: number;
    portfolioReserveAligned: number;
}

export type LifestyleSpendingRequest = {
    id?: string;
    userInputs: {
        lifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[];
        isFundedByNonInvestableAssets: boolean;
    },
    lengthOfPlanningPeriod: number
}

export const emptyLifestyleGoalForm = (planningPeriod: PlanningPeriodType): LifestyleSpendingGoal => ({
    calculatedFields: {...emptyLifestylePresentValueResponse},
    userInputs: {
        isFundedByNonInvestableAssets: false,
        lifestyleSpendingPeriods: [{...emptyLifestyleSpendingPeriod(planningPeriod.startYear, planningPeriod.numberOfYears + planningPeriod.startYear)}]
    }
});

export const emptyLifestyleSpendingPeriod = (startYear: number, endYear: number): LifestyleSpendingPeriodInputs => ({
    description: "Lifestyle Spending Period",
    annualSpend: 0,
    startYear,
    endYear,
})

