import {
    CurrencyInputWithLabel,
    DatePicker,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import React, {ChangeEvent, useEffect, useState} from "react";
import {EstateTaxExemptionsReadModel} from "../models/ui";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import {formatCurrency} from "../../utils/format";
import {EstateTaxExemptionsWriteModel} from "../models/api";
import moment, {Moment} from "moment";
import {DISPLAY_DATE_FORMAT, NO_OP} from "../../constants/common";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {Checkbox} from "xps-react";
import {momentToISO} from "../../utils/dateUtils";

export interface EditTaxExemptionFormProps {
    formData: EstateTaxExemptionsReadModel,
    onCancel: () => void,
    onSave: (updatedExemptions: EstateTaxExemptionsWriteModel) => void,
    onEvaluate: (updatedExemptions: EstateTaxExemptionsWriteModel) => void,
    getMaxExemption: (deceasedYear: number | null) => void
}

function fullName(member: { firstName: string, lastName: string }) {
    return `${member.firstName} ${member.lastName}`;
}

export function EditEstateTaxExemptionForm({
                                               formData,
                                               onSave,
                                               onCancel,
                                               onEvaluate,
                                               getMaxExemption
                                           }: EditTaxExemptionFormProps) {
    const [primaryMemberUsedExemption, setPrimaryMemberUsedExemption] = useState<number>(formData.primaryMemberExemption.usedTaxExemption);
    const [partnerMemberUsedExemption, setPartnerMemberUsedExemption] = useState<number | null>(formData.partnerMemberExemption.usedTaxExemption);
    const [showAlertBannerForPrimary, setAlertBannerForPrimary] = useState<boolean>(false);
    const [showAlertBannerForPartner, setAlertBannerForPartner] = useState<boolean>(false);
    const [showAlertBannerForDeceasedDate, setAlertBannerForDeceasedDate] = useState<boolean>(false);

    const [deceasedDate, setDeceasedDate] = useState<string | null>(formData.partnerMemberExemption.deceasedDate);
    const [includeDeceasedPartnerChecked, setIncludeDeceasedPartnerChecked] = useState(
        formData.partnerMemberExemption.member === null
        && formData.partnerMemberExemption.deceasedDate !== null
    );
    useEffect(() => {
        const partnerMemberMaximumAllowedExemption = formData.partnerMemberExemption.maximumAllowedTaxExemption ?? 0;
        const invalidPartnerUsedExemption = (partnerMemberUsedExemption ?? 0) > partnerMemberMaximumAllowedExemption;
        const invalidPrimaryUsedExemption = primaryMemberUsedExemption > formData.primaryMemberExemption.maximumAllowedTaxExemption;
        setAlertBannerForPrimary(invalidPrimaryUsedExemption)
        setAlertBannerForPartner(invalidPartnerUsedExemption)
        const deceasedDateIsValid = validateDeceasedDate();
        if (!deceasedDateIsValid) {
            setAlertBannerForDeceasedDate(deceasedDateIsValid);
        }
    }, [
        formData.partnerMemberExemption,
        formData.primaryMemberExemption.maximumAllowedTaxExemption,
        partnerMemberUsedExemption,
        primaryMemberUsedExemption,
        deceasedDate,
        includeDeceasedPartnerChecked
    ]);

    const buildTaxExemptionWriteModel = () => ({
        primaryMemberExemption: {
            usedTaxExemption: primaryMemberUsedExemption
        },
        partnerMemberExemption: {
            usedTaxExemption: partnerMemberUsedExemption,
            deceasedDate: deceasedDate
        }
    });

    const onSaveClick = () => {
        if (!showAlertBannerForPrimary && !showAlertBannerForPartner && validateDeceasedDate()) {
            onSave(buildTaxExemptionWriteModel());
        }
    }

    const validateDeceasedDate = () => {
        const isInvalid = includeDeceasedPartnerChecked && !deceasedDate;
        setAlertBannerForDeceasedDate(isInvalid);
        return !isInvalid;
    }

    const onDateChange = (date: Moment) => {
        setDeceasedDate(date ? momentToISO(date) : null);
        getMaxExemption(date ? date.year() : null)
        onEvaluate(({
            primaryMemberExemption: {
                usedTaxExemption: primaryMemberUsedExemption
            },
            partnerMemberExemption: {
                usedTaxExemption: partnerMemberUsedExemption,
                deceasedDate: date ? momentToISO(date) : null
            }
        }));
    }


    const onBlur = () => {
        if (!showAlertBannerForPrimary && !showAlertBannerForPartner) {
            onEvaluate(buildTaxExemptionWriteModel());
        }
    }

    const primaryMemberExemptionAvailable = formData.primaryMemberExemption.maximumAllowedTaxExemption - primaryMemberUsedExemption;
    const dataEntryItems = [{
        label: `${formData.primaryMemberExemption.member.firstName}'s Exemption Available`,
        value: formatCurrency(primaryMemberExemptionAvailable),
        testId: 'primary-member-exemption-available'
    }];

    if (formData.partnerMemberExemption.maximumAllowedTaxExemption !== null) {
        const partnerMemberExemptionAvailable = formData.partnerMemberExemption.maximumAllowedTaxExemption - (partnerMemberUsedExemption ?? 0);
        const partnerItem = {
            label: `${formData.partnerMemberExemption.member !== null ?
                formData.partnerMemberExemption.member.firstName : "Spouse"}'s Exemption Available`,
            value: formatCurrency(partnerMemberExemptionAvailable),
            testId: 'partner-member-exemption-available'
        };
        const totalItem = {
            label: 'Total Exemption Available',
            testId: 'total-exemption-available',
            value: formatCurrency(primaryMemberExemptionAvailable + partnerMemberExemptionAvailable)
        };
        dataEntryItems.push(partnerItem, totalItem);
    }

    return <div className="side-drawer-form">
        <DataEntryHeader
            className='dataEntryHeader'
            title='Edit Estate Tax Exemption'
            primaryButtonText='Save'
            onPrimaryButtonClick={onSaveClick}
            secondaryButtonText='Cancel'
            onSecondaryButtonClick={onCancel}
        />
        <div className="side-drawer-form-content">
            <article>
                <section>
                    <UnderlinedHeader
                        primaryText={`${fullName(formData.primaryMemberExemption.member)}'s Exemption Details`}/>
                    <CurrencyInputWithLabel
                        label='Exemption Used'
                        aria-label='Primary Client Exemption Used'
                        value={primaryMemberUsedExemption}
                        emptyValue='0'
                        onChangeValue={(_e, value) => setPrimaryMemberUsedExemption(value as number)}
                        onBlur={onBlur}
                        error={showAlertBannerForPrimary ? "Cannot exceed federal exemption limit." : undefined}
                    />
                    {formData.partnerMemberExemption.member === null && <Checkbox
                        className="layout-data-entry-form__field"
                        name='include-a-deceased-spouse'
                        value='Include a deceased spouse'
                        checked={includeDeceasedPartnerChecked}
                        aria-label='Include a deceased spouse'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setIncludeDeceasedPartnerChecked(e.target.checked);
                            setDeceasedDate(null);
                            setPartnerMemberUsedExemption(null);
                            getMaxExemption(null);
                            onEvaluate(({
                                primaryMemberExemption: {
                                    usedTaxExemption: primaryMemberUsedExemption
                                },
                                partnerMemberExemption: {
                                    usedTaxExemption: null,
                                    deceasedDate: null
                                }
                            }));
                        }}
                    />}
                </section>
                {includeDeceasedPartnerChecked &&
                    <section>
                        <UnderlinedHeader primaryText={`Spouse's Exemption Details`}
                                          secondaryContent={<RequiredFieldsSubheader/>}/>
                        <div className="layout-data-entry-form__field">
                            <label> <b>Deceased as of <RedAsterisk/> </b></label>
                            <DatePicker date={deceasedDate ? moment(deceasedDate) : undefined}
                                        className=""
                                        displayFormat={DISPLAY_DATE_FORMAT}
                                        isOutsideRange={(date: Moment) => date.isAfter(moment())}
                                        hideKeyboardShortcutsPanel
                                        id="deceasedDateInput"
                                        onDateChange={(date: Moment) => {
                                            let validDeceasedDate = date;
                                            const currentDate = moment();
                                            if (date !== undefined && date !== null && date.diff(currentDate) > 0) {
                                                validDeceasedDate = moment.min(date, currentDate)
                                                    .subtract(Math.floor(29 * Math.random()) + 1, 'days')
                                            }
                                            onDateChange(validDeceasedDate);
                                        }}
                                        error={showAlertBannerForDeceasedDate ? "This field is required." : undefined}/>
                        </div>
                        <CurrencyInputWithLabel
                            label='Exemption Used'
                            aria-label='Partner Exemption Used'
                            value={partnerMemberUsedExemption}
                            onChangeValue={(_e, value) => setPartnerMemberUsedExemption(value as number)}
                            emptyValue='0'
                            onBlur={onBlur}
                            error={showAlertBannerForPartner ? "Cannot exceed federal exemption limit." : undefined}
                        />
                    </section>
                }
                {formData.partnerMemberExemption.member &&
                    <section>
                        <UnderlinedHeader
                            primaryText={`${fullName(formData.partnerMemberExemption.member)}'s Exemption Details`}/>
                        {formData.partnerMemberExemption?.member?.lifeStatus === LifeStatus.Deceased && deceasedDate &&
                            <div className="layout-data-entry-form__field">
                                <label> <b>Deceased as of </b></label>
                                <DatePicker disabled={true}
                                            date={moment(deceasedDate)}
                                            className=""
                                            displayFormat={DISPLAY_DATE_FORMAT}
                                            hideKeyboardShortcutsPanel
                                            id="deceasedDateInput"
                                            onDateChange={NO_OP}/>
                            </div>
                        }
                        <CurrencyInputWithLabel
                            label='Exemption Used'
                            aria-label='Partner Exemption Used'
                            value={partnerMemberUsedExemption}
                            emptyValue='0'
                            onChangeValue={(_e, value) => setPartnerMemberUsedExemption(value as number)}
                            onBlur={onBlur}
                            error={showAlertBannerForPartner ? "Cannot exceed federal exemption limit." : undefined}
                        />
                    </section>
                }
            </article>
            <aside>
                <DataEntrySummary
                    title='Exemption Available'
                    items={dataEntryItems}
                />
            </aside>
        </div>
    </div>;
}
