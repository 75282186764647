export interface EquityCompensationFormData {
    id: string,
    profileId: string,
    entityOrAccountName: string,
    ticker: string,
    asOfDate: string,
    companyOrStockName: string,
    stockPrice: number,
    taxRate: number,
    owner: string,
    doesPermitBeneficiary: boolean,
    
    preTaxUnvestedValue: number | null,
    preTaxVestedValue: number | null,
    afterTaxUnvestedValue: number | null,
    afterTaxVestedValue: number | null,

    stockOptionPreTaxUnvestedValue: number | null,
    stockOptionPreTaxVestedValue: number | null,
    stockOptionAfterTaxUnvestedValue: number | null,
    stockOptionAfterTaxVestedValue: number | null,

    stockGrantPreTaxUnvestedValue: number | null,
    stockGrantAfterTaxUnvestedValue: number | null,

    stockOptionsAsOfDate: string | null,
    stockGrantsAsOfDate: string | null
}

export interface StockOptionsAndGrantsRequest {
    stockOptions: StockOptionWriteModel[],
    stockGrants: StockGrantWriteModel[],
    deletedStockOptions: string[],
    deletedStockGrants: string[]
}

export interface StockOptionTotals {
    totalGrossPotentialValue: number
    totalGrossExercisedValue: number
    totalAfterTaxPotentialValue: number
    totalAfterTaxUnvestedMarketValue: number
    totalAfterTaxExercisedValue: number
}

export interface StockGrantsTotal {
    totalUnvestedMarketValue: number
    totalAfterTaxUnvestedMarketValue: number
}

export interface StockOptionsAndGrantsResponse {
    asOfDate: string,
    entityOrAccountName: string,
    stockPrice: number,
    effectiveTaxRate: number,
    capitalGainsTaxRate: number,
    stockOptions: StockOption[],
    stockGrants: StockGrant[]
}

export enum StockOptionType {
    NQSO = "Non-Qualified Stock Options",
    ISO = "ISO"
}

export enum StockGrantType {
    RSU = "RSU",
    PSU = "PSU"
}

export enum EquityCompensationStockType {
    STOCK_OPTION = "stockOption",
    STOCK_GRANT = "stockGrant"
}

export interface StockOption {
    id: string | null,
    type: StockOptionType.NQSO | StockOptionType.ISO,
    grantDate: string,
    expirationDate: string,
    grantDescription: string,
    exercisePrice: number,
    sharesGranted: number,
    sharesExercised: number,
    sharesVested: number,
    sharesUnvested: number,
    grossPotentialValue: number,
    grossExercisedValue: number,
    afterTaxPotentialValue: number,
    afterTaxUnvestedMarketValue: number,
    afterTaxExercisedValue: number,
    vested: boolean,
    createdAt: string | null,
    updatedAt: string | null
}

export interface CalculateStockOptionRequest {
    id: string | null,
    type: StockOptionType.NQSO | StockOptionType.ISO,
    grantDate: string,
    expirationDate: string,
    grantDescription: string,
    exercisePrice: number,
    sharesGranted: number,
    sharesExercised: number,
    sharesVested: number,
    stockPrice: number,
    effectiveTaxRate: number,
    capitalGainsTaxRate: number,
    vested: boolean,
    createdAt: string | null,
    updatedAt: string | null
}

export interface CalculateStockGrantRequest {
    id: string | null,
    type: StockGrantType,
    equityCompensationId: string | undefined,
    grantDate: string,
    expirationDate: string,
    grantDescription: string,
    sharesGranted: number,
    sharesVested: number,
    stockPrice: number,
    effectiveTaxRate: number,
    vested: boolean,
    createdAt: string | null,
    updatedAt: string | null
}

export interface StockOptionWriteModel {
    id: string | null,
    type: StockOptionType.NQSO | StockOptionType.ISO,
    grantDate: string,
    expirationDate: string,
    grantDescription: string,
    exercisePrice: number,
    sharesGranted: number,
    sharesExercised: number,
    createdAt: string | null,
    updatedAt: string | null
}

export interface StockGrantWriteModel {
    id: string | null,
    type: StockGrantType.RSU | StockGrantType.PSU,
    grantDate: string,
    expirationDate: string,
    grantDescription: string,
    sharesGranted: number,
    createdAt: string | null,
    updatedAt: string | null
}

export interface VestingScheduleResponse {
    id: string,
    type: StockOptionType | StockGrantType,
    grantDate: string,
    expirationDate: string,
    grantDescription: string,
    sharesGranted: number,
    grossPotentialValue: number,
    afterTaxPotentialValue: number,
    unvestedMarketValue: number,
    afterTaxUnvestedMarketValue: number,
    vestingPeriods: VestingPeriod[]
}

export interface VestingPeriod {
    id: string,
    nextVestingDate: string,
    percentage: number,
    sharesVestingPerPeriod: number,
    isVested: boolean
}

export interface VestingPeriodWriteModel {
    nextVestingDate: string,
    percentage: number,
    sharesVestingPerPeriod: number
}

export interface StockGrant extends StockGrantWriteModel {
    assetId?: string,
    sharesVested: number,
    sharesUnvested: number,
    unvestedMarketValue: number,
    afterTaxUnvestedMarketValue: number,
    vested: boolean
}

export interface AutoCalculateVestingPeriodRequest {
    frequency: string,
    sharesGranted: number,
    grantDate: string,
    expirationDate: string
}

export interface AutoCalculateVestingPeriodResponse extends AutoCalculateVestingPeriodRequest{
    vestingPeriods: VestingPeriod[]
}

export interface CalculateVestingInfoRequest {
    equityCompensationId: string,
    stockId: string,
    stockType: EquityCompensationStockType,
    vestingPeriods: VestingPeriodWriteModel[]
}

export interface CalculateVestingInfoResponse {
    grossPotentialValue: number,
    afterTaxPotentialValue: number,
    unvestedMarketValue: number,
    afterTaxUnvestedMarketValue: number
}

export const SPOUSE_EQUITY = "SPOUSE"