import {formatCurrency} from "../utils/format";
import {Accordion, AccordionItem, BarChartSidebar, Dropdown, DropdownItem, PageActionMenu} from "../components";
import React, {useState} from "react";
import DataEntrySideDrawer from "../components/DataEntry/DataEntrySideDrawer";
import {Route, Switch, useHistory} from "react-router-dom";
import {EditBeneficiariesContainer} from "./EditBeneficiaries";
import {AccordionItemListHeader} from "../components/Accordion";
import {GroupedTable} from "../components/Table/GroupedTable";
import classNames from "classnames";
import {EditTaxExemptionContainer} from "./EditTaxExemption";
import {mapEstateSummaryToGroupedTableData} from "./models/mappers";
import {AccordionItemPresentValueHeader} from "../components/Accordion/AccordionItemPresentValueHeader";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {EstateSummary, EstateType} from "./models/api";
import {COLOR_ESTATE_BENEFICIARIES, COLOR_TAXES} from "../constants/colors";
import {EstateTaxRunningTotal} from "./components/EstateTaxRunningTotal";
import {DropdownGroup} from "xps-react";
import {selectReleaseToggles} from "src/ReleaseToggles/releaseTogglesSlice";
import {useAppSelector} from "../store/hooks";
import OutOfEstateContainer from "../Assets/common/OutOfEstate/OutOfEstateContainer";
import {LegalEntityReadModel} from "../Assets/models/Ownership";
import {AssetsSummary} from "../Assets/models/Assets";
import {NO_OP} from "../constants/common";

type WealthTransferPlanningProps = {
    profileOrProposalId: string,
    profileName: string,
    estateSummary: EstateSummary,
    assets?: AssetsSummary,
    legalEntities?: LegalEntityReadModel[],
    openDrawer: boolean,
    onToggleEstateType: (estateType: EstateType) => void,
    estateType: EstateType,
}

const WealthTransfer = ({
                            profileOrProposalId,
                            profileName,
                            estateSummary,
                            assets,
                            legalEntities,
                            openDrawer,
                            onToggleEstateType,
                            estateType
                        }: WealthTransferPlanningProps) => {

    const history = useHistory();
    const groupedTableData = mapEstateSummaryToGroupedTableData(estateSummary);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [showOutOfEstateAssets, setShowOutOfEstateAssets] = useState(false);
    const onEditBeneficiaries = () => {
        history.push(`/Profile/${profileOrProposalId}/ClientProfile/WealthTransferPlanning/Edit/Beneficiaries`);
    };
    const onEditEstateTaxExemption = () => {
        history.push(`/Profile/${profileOrProposalId}/ClientProfile/WealthTransferPlanning/Edit/TaxExemptions`);
    };

    const doBeneficiariesExist = estateSummary.memberBeneficiaries.length > 0 || estateSummary.philanthropicBeneficiaries.length > 0;
    const stateEstateTaxDeduction = estateSummary.stateTaxSummary.estimatedEstateTax > 0 ? [{
        label: "State Estate Tax Deduction", amount: estateSummary.stateTaxSummary.estimatedEstateTax
    }] : [];


    return <article className="summary-page layout-split-left">
        <BarChartSidebar
            data={[{
                label: 'Beneficiaries', className: '', color: '#66AA93', total: estateSummary.beneficiaryTotalValue,
            }, {
                label: 'Estimated Estate Tax', className: '', color: '#FFDF77', total: estateSummary.estimatedEstateTax,
            }]}
            noDataText=""
            displayName={profileName}
            title='Wealth Transfer'
            subtitle='Explore opportunities to transfer your wealth, fulfill goals, and reduce your estate tax exposure.'>

            <div>
                <span className="condensed-subtitle margintop-xxxl">
                    {estateType === EstateType.CURRENT ? "CURRENT ESTATE VALUE" : "FUTURE ESTATE VALUE"}
                </span>
                <div className="h1 margintop-sm marginbottom-xxxl" aria-label={'Current Estate Value'}>
                    {formatCurrency(estateSummary.estateValue)}
                </div>
            </div>
        </BarChartSidebar>
        <section>
            <ScrollableContainer>
                <div className="summary-page-content">
                    <div style={{marginRight: "40px"}} className="section-header">
                        <WealthTransferHeader
                            atAge={estateSummary.atAge}
                            estateType={estateType}
                            onToggleEstateType={onToggleEstateType}
                            estateValue={estateSummary.estateValue}
                            beneficiaryTotalValue={estateSummary.beneficiaryTotalValue}
                            estimatedEstateTax={estateSummary.estimatedEstateTax}
                            enableWealthTransferMenu={releaseToggles?.enableWealthTransferMenu ?? false}/>
                        <NavigationButton
                            onEditBeneficiariesClick={onEditBeneficiaries}
                            onEditEstateTaxExemption={onEditEstateTaxExemption}
                        />
                    </div>
                    <section>
                        <AccordionItemListHeader
                            testId={'wealth-transfer-accordion-header'}
                            firstColumnTitle="Transferred To"
                            lastColumnTitle={estateType === EstateType.CURRENT ? "Present Value" : "Future Value"}
                            className={classNames('estate-summary-grid', 'estate-grid-table')}/>
                        <Accordion>
                            <AccordionItem
                                uuid="estate-summary-beneficiaries"
                                primaryText="Beneficiaries"
                                accentColor={COLOR_ESTATE_BENEFICIARIES}
                                rightAlignedContent={<AccordionItemPresentValueHeader
                                    value={estateSummary.beneficiaryTotalValue}/>}
                                expandable={doBeneficiariesExist}>
                                {doBeneficiariesExist && <GroupedTable
                                    columnHeaders={["Name", "Present Value"]}
                                    tableData={groupedTableData}/>}
                            </AccordionItem>
                            <AccordionItem
                                uuid='estate-summary-estimated-estate-tax'
                                primaryText='Estimated Estate Tax'
                                accentColor={COLOR_TAXES}
                                rightAlignedContent={<AccordionItemPresentValueHeader
                                    value={estateSummary.estimatedEstateTax}/>}>
                                <div role="table" className="grouped-table-grid grouped-table">
                                    <span role="columnheader" className="grid-span-4 justify-content-start">Type</span>
                                    <span role="columnheader" className="justify-content-end">Present Value</span>
                                </div>
                                <EstateTaxRunningTotal
                                    taxType={'State'}
                                    estateSummary={estateSummary}
                                    effectiveTaxRate={estateSummary.stateTaxSummary.effectiveTaxRate}
                                    estimatedEstateTax={estateSummary.stateTaxSummary.estimatedEstateTax}
                                    additionalExemptionsOrDeductions={[{
                                        label: "State Exemption",
                                        amount: estateSummary.stateTaxSummary.totalAvailableExemption
                                    }]}
                                    taxableEstateValue={estateSummary.stateTaxSummary.taxableEstateValue}
                                />
                                <EstateTaxRunningTotal
                                    taxType={'Federal'}
                                    estateSummary={estateSummary}
                                    effectiveTaxRate={estateSummary.federalTaxSummary.effectiveTaxRate}
                                    estimatedEstateTax={estateSummary.federalTaxSummary.estimatedEstateTax}
                                    additionalExemptionsOrDeductions={stateEstateTaxDeduction.concat([{
                                        label: "Available Exemption",
                                        amount: estateSummary.federalTaxSummary.totalAvailableExemption
                                    }])}
                                    taxableEstateValue={estateSummary.federalTaxSummary.taxableEstateValue}
                                />
                            </AccordionItem>
                        </Accordion>
                    </section>

                    {estateType === EstateType.CURRENT && assets && legalEntities
                        &&
                        <div className="holisticAdvice-summary-page">
                            <OutOfEstateContainer showOutOfEstate={showOutOfEstateAssets}
                                                  onClickShowOutOfEstate={(showOutOfEstate) => {
                                                      setShowOutOfEstateAssets(showOutOfEstate)
                                                  }}
                                                  assets={assets}
                                                  onDeleteLegalEntity={NO_OP}
                                                  legalEntities={legalEntities}
                                                  showDeathBenefit={true}
                            />
                        </div>
                    }
                </div>
                <DataEntrySideDrawer isOpen={openDrawer} size="full" formLayout="split">
                    <Switch>
                        <Route path={"/Profile/:profileId/ClientProfile/WealthTransferPlanning/Edit/Beneficiaries"}
                               component={EditBeneficiariesContainer}/>
                        <Route path={"/Profile/:profileId/ClientProfile/WealthTransferPlanning/Edit/TaxExemptions"}
                               component={EditTaxExemptionContainer}/>
                    </Switch>
                </DataEntrySideDrawer>
            </ScrollableContainer>
        </section>
    </article>
}

function NavigationButton(props: {
    onEditBeneficiariesClick: () => void, onEditEstateTaxExemption: () => void
}) {
    return <Dropdown
        alignRight
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Edit"
        iconNameOpen="edit"
        disabled={false}
        iconNameClose="edit"
        panelWidth={240}
        rounded
    >
        <DropdownItem itemText="Beneficiaries"
                      value="goToBeneficiaries"
                      onClick={props.onEditBeneficiariesClick}/>
        <DropdownItem itemText="Estate Tax Exemption"
                      value="goToEstateTaxExemption"
                      onClick={props.onEditEstateTaxExemption}/>
    </Dropdown>;
}

interface WealthTransferHeaderProps {
    estateValue: EstateSummary['estateValue'],
    beneficiaryTotalValue: EstateSummary['beneficiaryTotalValue'],
    estimatedEstateTax: EstateSummary['estimatedEstateTax'],
    enableWealthTransferMenu: boolean,
    onToggleEstateType: (estateType: EstateType) => void,
    estateType: EstateType,
    atAge: number
}

function WealthTransferHeader({
                                  estateValue,
                                  enableWealthTransferMenu,
                                  beneficiaryTotalValue,
                                  estimatedEstateTax,
                                  onToggleEstateType,
                                  estateType,
                                  atAge
                              }: WealthTransferHeaderProps) {

    const text = estateType === EstateType.CURRENT ? 'Your current estate value is ' : `Your estimated estate value at age ${atAge} is `;
    return (<>
        <h3>{text} <b>{formatCurrency(estateValue)}</b>.
            Under your current
            plan, <b>{formatCurrency(beneficiaryTotalValue)}</b> would be
            distributed to beneficiaries
            and <b>{formatCurrency(estimatedEstateTax)}</b> to estimated estate taxes.
        </h3>
        {enableWealthTransferMenu && <div>
            <PageActionMenu
                className="marginright-xs"
                panelHeight="auto"
                panelWidth={240}
                aria-label="more-options-dropdown"
                key={"page-action-menu"}>

                <DropdownGroup groupName="Value" key={"dr-dropdown-group"}>
                    <DropdownItem key={"current"} value="" itemText=""
                                  onClick={() => onToggleEstateType(EstateType.CURRENT)}>

                        <div aria-label="wealth-transfer-menu-present-value"
                             style={{width: "100%"}}
                             className="page-action-menu-options">
                            Present Value
                            <input name="wealth-transfer-group"
                                   type="radio" value='current'
                                   aria-label="present-value-option"
                                   checked={estateType === EstateType.CURRENT}
                                   onChange={() => onToggleEstateType(EstateType.CURRENT)}
                            />
                        </div>
                    </DropdownItem>
                    <DropdownItem className="page-action-menu-options" key={"future"} value=""
                                  itemText="" onClick={() => onToggleEstateType(EstateType.FUTURE)}>
                        <div aria-label="wealth-transfer-menu-future-value"
                             style={{width: "100%"}}
                             className="page-action-menu-options">
                            Future Value <input name="wealth-transfer-group" type="radio"
                                                aria-label="future-value-option" value='future'
                                                checked={estateType === EstateType.FUTURE}
                                                onChange={() => onToggleEstateType(EstateType.FUTURE)}/>
                        </div>
                    </DropdownItem>
                </DropdownGroup>
            </PageActionMenu>
        </div>}
    </>);
}

export default WealthTransfer;