import {
    CameraButton,
    ControlBar,
    DEFAULT_COMPONENT_ICONS,
    MicrophoneButton,
    usePropsFor,
    VideoGallery,
} from "@azure/communication-react";
import {Icon, initializeIcons, registerIcons, Stack} from "@fluentui/react";
import React from "react";
import {msalUtils} from "../../MsalUtils";
import {useMsal} from "@azure/msal-react";

initializeIcons();
registerIcons({icons: DEFAULT_COMPONENT_ICONS});

const ParticipantAudioVideo: React.FC = () => {
    const videoGalleryProps = usePropsFor(VideoGallery);
    const microphoneProps = usePropsFor(MicrophoneButton);
    const cameraProps = usePropsFor(CameraButton);
    const msal = useMsal();

    const customMicOnIcon = (): JSX.Element => {
        return (
            <Icon
                key={'micCustomIconOn'}
                iconName={'ControlButtonMicOn'}
                style={{color: 'white'}}
            />
        );
    };

    const customMicOffIcon = (): JSX.Element => {
        return (
            <Icon
                key={'micCustomIconOff'}
                iconName={'ControlButtonMicOff'}
                style={{color: 'white'}}
            />
        );
    };

    const customCameraOnIcon = (): JSX.Element => {
        return (
            <Icon
                key={'cameraCustomIconOn'}
                iconName={'ControlButtonCameraOn'}
                style={{color: 'white'}}
            />
        );
    };

    const customCameraOffIcon = (): JSX.Element => {
        return (
            <Icon
                key={'cameraCustomIconOff'}
                iconName={'ControlButtonCameraOff'}
                style={{color: 'white'}}
            />
        );
    };

    const controlBarStyles = {
        root: {
            justifyContent: 'flex-end',
            backgroundColor: 'black',
            '& button': {
                color: 'white',
            }
        }
    };

    return (
        <div className='meeting-participants'>
            <Stack className='participant-audio-video-container'>
                <div className='participant-control-bar'>
                    <ControlBar styles={controlBarStyles} layout='horizontal'>
                        {cameraProps && <CameraButton  {...{
                            ...cameraProps,
                            showLabel: true,
                            strings: {
                                onLabel: 'Camera',
                                offLabel: 'Camera'
                            },
                            onRenderOnIcon: () => {
                                return customCameraOnIcon()
                            },
                            onRenderOffIcon: () => {
                                return customCameraOffIcon()
                            }
                        }} />}
                        {microphoneProps && <MicrophoneButton {...
                            {
                                ...microphoneProps,
                                showLabel: true,
                                strings: {
                                    onLabel: 'Mic',
                                    offLabel: 'Mic'
                                },
                                onRenderOnIcon: () => {
                                    return customMicOnIcon()
                                },
                                onRenderOffIcon: () => {
                                    return customMicOffIcon()
                                },
                            }}
                        />}
                    </ControlBar>
                </div>
                <div className='participant-video-gallery'>
                    {videoGalleryProps && <VideoGallery
                        {...
                            {
                                ...videoGalleryProps,
                                showMuteIndicator: true,
                                remoteParticipants: [],
                                localParticipant: {
                                    ...videoGalleryProps.localParticipant,
                                    displayName: msalUtils.getAccountName(msal),
                                },
                            }
                        }/>
                    }
                </div>
            </Stack>
        </div>
    )
};

export default ParticipantAudioVideo;