import React, {ReactElement, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {formatCurrency, formatNumberRoundedToWholeNumber} from "../../utils/format";
import ExcessAssets from "./ExcessAssets";
import {useHistory, useParams} from "react-router-dom";
import {kebabCase} from "../../utils/stringUtils";
import {getPlanSummary, selectIsPlanSummaryLoading, selectPlanSummary} from "./planSummarySlice";
import LoadingIndicator from "../../pages/LoadingIndicator";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {RouteWithId} from "../../routes/types";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {Col, Container, Row} from "react-grid-system";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {PresentationPaneHeader} from "../../components";
import {NO_OP} from "../../constants/common";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

const PlanSummary = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const planSummary = useAppSelector(selectPlanSummary)!;
    const isPlanSummaryLoading = useAppSelector(selectIsPlanSummaryLoading)!;
    const {enableFutureValueOfExcessAssets} = useAppSelector(selectReleaseToggles)!;

    usePageViewTimer('Plan Summary Page Load Timer (milliseconds)', isPlanSummaryLoading);

    const hasAssets = planSummary.totalGrossValue > 0;

    const goalsTotalPresentValue = planSummary.goalsTotalPresentValue;
    const hasGoals = planSummary.goalsTotalPresentValue > 0;

    const {
        approvedProfile: {displayName},
        profile: {portfolioReserveTargetLength}
    } = useProfileAndProposals(id);

    useEffect(() => {
        dispatch(getPlanSummary(id))
    }, [id]);

    if (isPlanSummaryLoading) {
        return <LoadingIndicator/>
    }

    return (
        <div className="plan-summary-page">
            <PresentationPaneHeader
                displayName={displayName}
                title="Plan Summary"
            />
            <article className="plan-summary-page__body">
                {/*this is the left column of the page*/}
                <ExcessAssets hasAssetsAndGoals={hasAssets && hasGoals} totalGoals={goalsTotalPresentValue}/>
                {/*this is the right column of the page*/}
                <div className="flex-grow-1">
                    <div className={"net-assets-goals-card"}>
                        <MonetaryValueCard value={hasAssets ? planSummary.totalNetValue : '--'}
                                           heading={"Net Assets"}
                                           onClick={() =>
                                               history.push({
                                                   pathname: `/Profile/${id}/ClientProfile/AssetSummary`
                                               })}/>
                        <MonetaryValueCard value={hasGoals ? goalsTotalPresentValue : '--'}
                                           heading={"Goals"}
                                           onClick={() =>
                                               history.push({
                                                   pathname: `/Profile/${id}/ClientProfile/Goals/Summary`
                                               })}/>
                    </div>
                    <div
                        className={"proposed-asset-allocation-card"}
                        data-testid={"proposed-asset-allocation"}
                        onClick={() =>
                            history.push({
                                pathname: `/Profile/${id}/ClientProfile/AssetAllocation/CurrentVsProposed`
                            })}
                    >
                        <div
                            className={"proposed-asset-allocation-text"}
                            data-testid={"proposed-asset-allocation-text"}
                            tabIndex={0}>
                            <div className={"proposed-asset-allocation"}>Proposed Asset Allocation</div>
                            <div className={"total-portfolio"}>Total Portfolio</div>
                        </div>
                        <div className={"no-hover-donut"}>
                            <RiskDonut
                                donutSize={'sm'}
                                data={planSummary.proposedAllocation}
                            />
                        </div>
                        <div className={"risk-assets-and-risk-control"}>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'Risk Assets'}/>
                                </div>
                                <span
                                    className={"risk-percentage"}>{` ${formatNumberRoundedToWholeNumber(
                                    planSummary.proposedAllocation.totalRiskAssetsPercent
                                )}%`}</span>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                label={'Risk Control Assets'}/>
                                </div>
                                <span
                                    className={"risk-percentage"}>{` ${formatNumberRoundedToWholeNumber(
                                    planSummary.proposedAllocation.totalRiskControlAssetsPercent
                                )}%`}</span>
                            </div>
                        </div>
                    </div>
                    <PlanSummaryCard
                        onClick={() => {
                            portfolioReserveTargetLength == undefined ?
                                history.push({pathname: `/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve`}) :
                                history.push({pathname: `/Profile/${id}/ClientProfile/PortfolioReserve/ReviewPortfolioReserve`})
                        }}
                        header='Portfolio Reserve'
                        subhead={<span
                            className='plan-summary-card-subhead'>Your Reserve Target Length is <strong>{portfolioReserveTargetLength ?? 0} years</strong></span>}
                    />
                    {enableFutureValueOfExcessAssets && <PlanSummaryCard
                        onClick={NO_OP}
                        header='Net Worth Over Time'
                        subhead={<span
                            className='plan-summary-card-subhead'>Your expected excess at the end of the planning period is <strong>{formatCurrency(planSummary.futureValueOfExcessAssets)}</strong></span>}
                    />}
                </div>
            </article>
        </div>
    );
};

type MonetaryValueCardProps = {
    value: number | string;
    onClick: () => void;
    heading: string;
}

const MonetaryValueCard: React.FC<MonetaryValueCardProps> = ({value, onClick, heading}) => {
    const kebabHeading = kebabCase(heading);
    return (
        <div className={`monetary-value-card ${kebabHeading}`} aria-label={heading} role='button' onClick={onClick}
             tabIndex={0}>
            <div className="sub-header" data-testid={`${kebabHeading}-label`}>{heading}</div>
            <span className="dollar-value" data-testid={`${kebabHeading}-value`}>
                    {typeof value === 'string' ? value : formatCurrency(value)}
            </span>
        </div>
    )
}

type PlanSummaryCardProps = {
    header: string;
    subhead: ReactElement
    onClick: () => void
}

const PlanSummaryCard: React.FC<PlanSummaryCardProps> = ({header, subhead, onClick}) => {
    return (
        <Container tabIndex={0} style={{paddingLeft: '40px', marginRight: '32px'}} className='plan-summary-card'
                   aria-label={header} onClick={onClick}>
            <div>
                <Row>
                    <Col>
                        <h4 className='header'>{header}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {subhead}
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default PlanSummary;
