import { EquityCompensationFormData } from "src/Assets/models/EquityCompensation";
import { EquityCompensationTableRow } from "./EquityCompensationTableRow";
import { Accordion } from "src/components/Accordion";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";

interface EquityCompensationTableProps {
  inEstateEquityCompensations: EquityCompensationFormData[];
  editEquity: (equityCompId: string | undefined) => any;
  deleteEquity: (equityComp: EquityCompensationFormData) => void;
  memberGroup: MemberGroup | undefined;
}

function EquityCompensationTable({
  inEstateEquityCompensations,
  editEquity,
  deleteEquity,
  memberGroup,
}: EquityCompensationTableProps) {
  return (
    <div
      role="table"
      className="assets-grid-with-actionmenu assets-grid-table equity-compensation-table"
      aria-label="equity-compensation-table"
    >
      <div
        role="row"
        className="assets-grid-with-actionmenu assets-grid-table-header"
        tabIndex={-1}
      >
        <span role="columnheader" className="textalign-left">
          Name & Owner
        </span>
        <span role="columnheader" className="textalign-right">
          Pre-Tax Unvested
        </span>
        <span role="columnheader" className="textalign-right">
          Pre-Tax Vested
        </span>
        <span role="columnheader" className="textalign-right">
          After-Tax Unvested
        </span>
        <span role="columnheader" className="textalign-right">
          After-Tax Vested
        </span>
      </div>
      <Accordion allowMultipleExpanded={true} className="grid-span-all equity-compensation-summary-accordion">
        {inEstateEquityCompensations.map(
          (inEstateEquity: EquityCompensationFormData, index) => {
            const uuid = `${inEstateEquity.entityOrAccountName}-${index}`;
            return (
              <EquityCompensationTableRow
                equityComp={inEstateEquity}
                key={inEstateEquity.id}
                gridClassName={"assets-grid-with-actionmenu"}
                actionsDisabled={false}
                onClickEdit={editEquity}
                onClickDelete={deleteEquity}
                uuid={uuid}
                memberGroup={memberGroup}
              />
            );
          }
        )}
      </Accordion>
    </div>
  );
}

export default EquityCompensationTable;
