export const counterDeviceScaling = () => {
    if (window.devicePixelRatio === 1.5 || window.devicePixelRatio === 1.25) {
        return {
            transform: `scale(${1 / window.devicePixelRatio})`,
            transformOrigin: 'left top',
            height: `${window.innerHeight * window.devicePixelRatio}px`,
            width: `${window.innerWidth * window.devicePixelRatio}px`,
        }
    }

    return {};
}