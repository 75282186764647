import {useLocation} from "react-router-dom";
import {useMemo} from "react";
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";
import {useAppSelector} from "../../store/hooks";
import {selectActiveMeeting} from "./meetingSlice";
import {Meeting} from "./Meeting";

const useMeetingUtils = () => {
    // Get current location and check if it's under the Client View component
    const location = useLocation();
    const isInsideMeetingPortal = useMemo(() => {
        return location.pathname.startsWith('/meetings');
    }, [location]);

    const msal = useMsal();
    let currentUser: string | null;
    if (isInsideMeetingPortal) {
        currentUser = null;
    } else {
        // Get current user details and check if lanId matches presenter
        currentUser = msalUtils.getLanId(msal);
    }

    const {presenter} = useAppSelector(selectActiveMeeting) as Meeting;
    const isCurrentUserPresenting = useMemo(() => {
        return currentUser?.toLowerCase() === presenter?.toLowerCase();
    }, [presenter, currentUser]);

    return {isCurrentUserPresenting};
};

export default useMeetingUtils;