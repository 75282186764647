import {AgendaItem} from "./models/AgendaDetails";
import React from "react";
import {Button} from "../components";

type AgendaEditDeleteProps = {
    agendaEntryData: AgendaItem,
    dataIndex: number,
    onClickEditEntryItem: (dataIndex: number, agendaEntry: AgendaItem) => void
    onDeleteEntryItem: (dataIndex: number, agendaEntry: AgendaItem) => void
}

const AgendaEditDelete: React.FC<AgendaEditDeleteProps> = ({
                                                             agendaEntryData,
                                                             dataIndex,
                                                             onClickEditEntryItem,
                                                             onDeleteEntryItem
                                                         }: AgendaEditDeleteProps) => {


    return (
        <><Button
            icon="only"
            ariaLabel={`Edit ${agendaEntryData.title}`}
            iconName='pencil'
            size="small"
            kind="borderless"
            onClick={() => onClickEditEntryItem(dataIndex, {
                ...agendaEntryData,
                mode: 'edit'
            })}/><Button
            icon="only"
            ariaLabel={`Delete ${agendaEntryData.title}`}
            iconName='delete'
            size="small"
            kind="borderless"
            className="delete-agenda-entry"
            onClick={() => onDeleteEntryItem(dataIndex, agendaEntryData)}/></>
    );
}

export default AgendaEditDelete;