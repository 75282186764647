import {useHistory} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Location} from "history";
import ModalWrapper, {ModalButton} from "../Modal/ModalWrapper/ModalWrapper";

export type HistoryBlockModalProps = {
    when: boolean,
    itemType: string,
    onSave: Function,
    onDiscard: Function,
    saveEnabled?: boolean
};

export function HistoryBlockModal({
                                      itemType,
                                      onDiscard: onDiscardCallback,
                                      onSave: onSaveCallback,
                                      when,
                                      saveEnabled = true
                                  }: HistoryBlockModalProps) {
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [navLocation, setNavLocation] = useState<Location | null>(null);

    const unblockRef = useRef();

    useEffect(() => {
        unblockRef.current = history.block((newLocation: Location) => {
            // @ts-ignore
            const forceNavigate = newLocation.state?.forceNavigate ? newLocation.state.forceNavigate : false
            if (when && !forceNavigate) {
                setNavLocation(newLocation);
                setShowModal(true);
                return false;
            } else if (forceNavigate) {
                return true;
            }
            return true;
        });
        return () => {
            // @ts-ignore -- this ref is callable
            unblockRef.current && unblockRef.current();
        };
    }, [when]);

    function navigateAway(newLocation: Location | null = navLocation) {
        if (unblockRef && unblockRef.current) {
            // @ts-ignore TS2349 -- this ref is callable
            unblockRef.current();
        }
        setShowModal(false);
        history.push(newLocation);
    }

    function onSave() {
        onSaveCallback().then((areRequiredFieldsFilled: boolean = true) => {
            areRequiredFieldsFilled ? navigateAway() : setShowModal(false);
        });
    }

    function onDiscard() {
        onDiscardCallback()
        navigateAway();
    }


    function onKeepEditing() {
        setShowModal(false);
    }


    let modalCopy = `Do you want to discard what you've entered so far or keep editing this ${itemType}?`

    const buttons: ModalButton[] = [
        {
            text: 'Keep Editing',
            onClick: onKeepEditing,
        },
        {
            text: 'Discard Changes',
            onClick: onDiscard,
            destructive: true,
            primary: true,
        }
    ];
    if (saveEnabled) {
        modalCopy = `Do you want to save your changes, discard what you’ve entered so far, or keep editing this ${itemType}?`
        buttons.push({
            text: 'Save Changes',
            onClick: onSave,
            primary: true,
        });
    }

    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={showModal}
            headerText={'Leave this page?'}
            buttons={buttons}
        >
            <div className="font-md">{modalCopy}
            </div>
        </ModalWrapper>
    );
}
