import {PresentationPaneHeader} from "../../components";
import React, {useEffect, useState} from "react";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {formatNumberRoundedToOneDecimal, formatYear} from "../../utils/format";
import {calculateYearsBetween, getTodayDate} from "../../utils/dateUtils";
import {Container} from "react-grid-system";
import {useAppSelector} from "../../store/hooks";
import {selectPortfolioReserveModel} from "./PortfolioReserveSlice";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {
    CurrentAllocationResponse,
    EMPTY_CURRENT_ALLOCATION_RESPONSE
} from "../../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import {roundPercentagesToHundred} from "../../utils/roundPercentagesToHundred";

const MonitorPortfolioReserve = () => {
    const {id} = useParams<RouteWithId>();
    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {portfolioReserve} = portfolioReserveModel;
    const profile = useProfileAndProposals(id).approvedProfile;
    const displayName = profile.displayName;
    const reserveTargetLength = profile.portfolioReserveTargetLength ? profile.portfolioReserveTargetLength : 0;
    const portfolioReserveActivationDate = profile ? profile.portfolioReserveActivationDate : null;
    const yearsFromPortfolioReserveActivationDate = portfolioReserveActivationDate ? calculateYearsBetween(portfolioReserveActivationDate, getTodayDate()) : 0
    const currentSupportYears = portfolioReserve.initialPortfolioReserveTargetLength;

    const [currentAllocation, setCurrentAllocation] = useState<CurrentAllocationResponse>(EMPTY_CURRENT_ALLOCATION_RESPONSE);
    const {
        percentage1: roundedRiskAssetsPercent,
        percentage2: roundedRiskControlAssetsPercent
    } = roundPercentagesToHundred(currentAllocation.totalRiskAssetsPercent, currentAllocation.totalRiskControlAssetsPercent);

    useEffect(() => {
        (async () => {
            if (id) {
                const currentAllocationResponse = await wealthManagementApiClient.getCurrentAllocation(id);
                setCurrentAllocation(currentAllocationResponse);
            }
        })();
    }, [id]);

    return (
        <div className="monitor-portfolio-reserve-page">
            <PresentationPaneHeader displayName={displayName}
                                    title="The Portfolio Reserve is safeguarding your lifestyle spending during this market downturn"/>
            <div className="monitor-portfolio-reserve-page__body">
                <div className="monitor-portfolio-reserve-page__body__text font-xl">
                    <div className={"monitor-portfolio-reserve-page__body__text__title-card"}>
                        {"Reserve Target: "}
                    </div>
                    <div className="monitor-portfolio-reserve-page__body__text__title-card__year">
                        {formatYear(reserveTargetLength)}
                    </div>
                </div>
                {
                    portfolioReserveActivationDate &&
                    <div className="monitor-portfolio-reserve-page__body__text font-xl">
                        <div className={"monitor-portfolio-reserve-page__body__text__title-card"}>
                            {"Your reserve has been funding your Lifestyle Spending for "}
                        </div>
                        <div className="monitor-portfolio-reserve-page__body__text__title-card__year">
                            {formatNumberRoundedToOneDecimal(yearsFromPortfolioReserveActivationDate)} Years
                        </div>
                    </div>
                }
                <div className={"monitor-portfolio-reserve-page__body__cards"}>
                    {/*this is the left column of the page*/}
                    <Container data-testid={"drawdown-card"} className='current-drawdown-card'>
                        <div className={"section"}>
                            <span className={"current-drawdown-card__header"}>{"Current Drawdown"}</span>
                            <span className='portfolio-reserve-subhead'>
                                Your current Asset Allocation supports <b> {formatNumberRoundedToOneDecimal(currentSupportYears)} years </b> of your Lifestyle Spending
                            </span>
                        </div>
                        <div className={"risk-assets-and-risk-control"}>
                            <div
                                className={"current-asset-allocation-text"}
                                data-testid={"current-asset-allocation-text"}
                                tabIndex={0}>
                                <div className={"current-asset-allocation-text"}>Proposed Asset Allocation</div>
                                <div className={"total-portfolio"}>Total Portfolio</div>
                            </div>
                            <div className={"no-hover-donut"} data-testid={'current-allocation-donut'}>
                                <RiskDonut
                                    donutSize={'sm'}
                                    data={currentAllocation}
                                />
                            </div>
                            <div className={"risk-legend"}>
                                <div className={"risk"} data-testid={"risk-assets"}>
                                    <div className={"risk-legend-label"}>
                                        <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'Risk Assets'}/>
                                    </div>
                                    <span
                                        className={"risk-percentage"}>{` ${(roundedRiskAssetsPercent)}%`}</span>
                                </div>
                                <div className={"risk"} data-testid={"risk-control"}>
                                    <div className={"risk-legend-label"}>
                                        <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                    label={'Risk Control Assets'}/>
                                    </div>
                                    <span
                                        className={"risk-percentage"}>{` ${(roundedRiskControlAssetsPercent)}%`}</span>
                                </div>
                            </div>
                        </div>

                    </Container>

                    {/*this is the right column of the page*/}
                    <div className="flex-grow-1"></div>
                </div>
            </div>
        </div>
    );
}

export default MonitorPortfolioReserve;