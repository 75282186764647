import React from 'react';
import {formatCurrency} from "../../utils/format";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveModel,
    selectPortfolioReserveUserPreferences,
    setPortfolioReserveButtonStates
} from "./PortfolioReserveSlice";
import {convertDecimalToPercentage} from "../GoalsParsers";
import {Button} from "../../components";

export type PortfolioRiskProps = {
    cvar: number;
    totalInvestableAssets: number;
}

const PortfolioRisk: React.FC<PortfolioRiskProps> = ({
                                                         cvar,
                                                         totalInvestableAssets
                                                     }) => {
    const dispatch = useAppDispatch();
    const {portfolioReserveOptions} = useAppSelector(selectPortfolioReserveUserPreferences);

    const portfolioReserveButtonStates = useAppSelector(selectPortfolioReserveButtonStates);
    const {profile} = useAppSelector(selectPortfolioReserveModel);
    const {portfolioRisk} = portfolioReserveButtonStates;

    const portfolioRiskClickHandler = () => {
        dispatch(setPortfolioReserveButtonStates({
            ...portfolioReserveButtonStates,
            portfolioRisk: true
        }))
    };

    if (portfolioRisk || profile.portfolioReserveTargetLength) {
        return (
            <div aria-label="PortfolioRisk" className="edit-portfolio-reserve-page__table__portfolio-risk">
                <div
                    className={portfolioReserveOptions.showRiskImpact ? "edit-portfolio-reserve-page__table__portfolio-risk__expanded-risk-row" : "edit-portfolio-reserve-page__table__portfolio-risk__cvar"}>
                    This asset allocation could experience a <span
                    style={{color: cvar < 0 ? '#AC5300' : '#05676E'}}>{convertDecimalToPercentage(cvar, 2).toFixed(2)}%</span> return.
                </div>

                {portfolioReserveOptions.showRiskImpact &&
                    <div className="edit-portfolio-reserve-page__table__portfolio-risk__excess-assets-impact">
                        <div>
                            <span>
                                Impact on Excess Assets
                            </span>
                        </div>
                        <div>
                            <span style={{
                                color: cvar < 0 ? '#AC5300' : '#05676E',
                                textAlign: "right"
                            }}>{formatCurrency(cvar * totalInvestableAssets)}
                            </span>
                        </div>
                    </div>
                }
            </div>
        );
    } else {
        return (
            <div
                data-testid="portfolio-risk-plus-button"
                className="edit-portfolio-reserve-page__centered" style={{width: "100%", height: "100%"}}>
                <Button
                    aria-label="PortfolioRiskButton"
                    icon="only"
                    kind="borderless"
                    iconName="add_circle_outline"
                    size="large"
                    type="button"
                    className="edit-portfolio-reserve-page__button"
                    onClick={portfolioRiskClickHandler}
                />
            </div>
        );
    }
}

export default PortfolioRisk;
