import {Icon} from "../../components";

type AccordionHeaderProps = {
    columnTitles: string[],
    className?: string,
    gridSpanClass?: string,
    testId?: string,
    isExpanded?: boolean,
    onClick: () => void,
    showIcon?: boolean,
}

export function AccordionHeader({
                                    columnTitles,
                                    className = 'assets-header-grid-with-actionmenu assets-grid-table',
                                    gridSpanClass = 'grid-span-3',
                                    testId,
                                    isExpanded = false,
                                    onClick,
                                    showIcon = true
                                }: AccordionHeaderProps) {

    return (
        <div className={`${className} paddingleft-12`.trim()} data-testid={testId}>
            <span
                className={`"display-flex align-items-center paddingleft-md marginbottom-md cursor-pointer ${gridSpanClass}`.trim()}
                onClick={onClick}
                role="button"
                aria-pressed={isExpanded ? 'true' : 'false'}
            >
                {showIcon && <Icon name={isExpanded ? "chevron_double_down" : "chevron_double_right"}/>}
                <span className="condensed-subtitle paddingleft-md">{columnTitles[0]}</span>
            </span>
            {
                columnTitles.slice(1).map((columnTitle, index) => {
                    return <span key={`${className}-col-${index}`} role="cell"
                                 className="condensed-subtitle textalign-right">{columnTitle}</span>
                })
            }
        </div>
    );
}
