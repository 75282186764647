import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectGoalModel, setGoalModel} from "../../../Goals/controller/GoalsModelSlice";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import SummaryBarChartSideBar from "../../../Goals/BarChartSidebar/SummaryBarChartSideBar";
import NoGoalsLandingPage from "../../../Goals/Summary/NoGoalsLandingPage";
import GoalListReport from "./GoalListReport";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import GoalsService from "../../../Goals/controller/GoalsService";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {splitGoalsSummaryData} from "./reportUtil";
import PrintViewWrapper from "../PrintViewWrapper";
import {genericEmptyErrorModalData, GenericErrorModalData} from "../../../components/Modal/Error/GenericErrorModal";

interface GoalsSummaryWrapperProps {
    goalsSummary: GoalModelType,
    displayName: string
}

const GoalsSummaryWrapper = ({goalsSummary, displayName}: GoalsSummaryWrapperProps) => {
    const {
        lifestyleSpendingGoal,
        familyGoals,
        discretionaryGoals,
        philanthropicGoals
    } = goalsSummary

    const clientHasFamilyGoals = !!familyGoals && Object.values(familyGoals).length > 0;
    const clientHasLifestyleGoals = !!lifestyleSpendingGoal.id;
    const clientHasNonLifestyleGoals = [...discretionaryGoals, ...philanthropicGoals].some(nonLifestyleGoal => !!nonLifestyleGoal.id);

    const clientHasSavedGoals = clientHasLifestyleGoals || clientHasFamilyGoals || clientHasNonLifestyleGoals;

    const [splittedGoalsSummary, setSplittedGoalsSummary] = useState<Array<GoalModelType>>([]);

    useEffect(() => {
        const defaultData = {
            ...goalsSummary,
            discretionaryGoals: [],
            philanthropicGoals: [],
            lifestyleSpendingGoal: {},
            familyGoals: {},
            taxLiabilities: {}
        }
        splitGoalsSummaryData(goalsSummary, defaultData).then((res: Array<GoalModelType>) => {
            setSplittedGoalsSummary(res);
        });
    }, []);

    return (<>
            {splittedGoalsSummary.map((goalsData, index) => {
                return (
                    <PrintViewWrapper displayName={displayName} key={index}>
                        <div className="goals-summary-container layout-split-sidebar">
                            <SummaryBarChartSideBar/>
                            {clientHasSavedGoals ? <GoalListReport goalModel={goalsData} pageNo={index}
                                                                   goalsSummaryList={splittedGoalsSummary}/> :
                                <NoGoalsLandingPage/>}
                        </div>
                    </PrintViewWrapper>
                )
            })}
        </>
    )
}

type goalsSummaryContainerProps = {
    displayName: string
}

const GoalsSummaryContainer = ({displayName}: goalsSummaryContainerProps) => {
    const {id} = useParams<RouteWithId>();
    const previousGoalModel = useAppSelector<GoalModelType>(selectGoalModel);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [goalsSummary, setGoalsSummary] = useState<any>({});
    const [, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const handleError = ((traceId: string) => {
        setGenericError({
            isOpen: true,
            header: 'Communication Failure',
            message: 'There has been a communication failure.  The data you see may not be accurate, please refresh your browser.  If this error continues, please contact the support team.',
            operationId: traceId
        });
    })
    useEffect(() => {
        setIsLoading(true);
        GoalsService.getGoalsModel(id, previousGoalModel, handleError)
            .then((goalResponse: GoalModelType) => {
                setGoalsSummary(goalResponse);
                dispatch(setGoalModel(goalResponse));
                setIsLoading(false);
            })
    }, [id]);
    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <GoalsSummaryWrapper goalsSummary={goalsSummary} displayName={displayName}/>
}

export default GoalsSummaryContainer;
