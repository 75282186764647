import React from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectDisplayName} from "../ClientProfile/approvedProfileSlice";
import {useHistory} from "react-router-dom";
import {DropdownItem, PageActionMenu, PresentationPaneButton, PresentationPaneHeader} from "../../components";
import {DropdownGroup} from "xps-react";
import {setAssetReliancePageActionMenu} from "./AssetRelianceSlice";
import {AssetRelianceButtonState} from "./AssetRelianceButtonState";
import {PrimaryContact} from "../models/PrimaryContact";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

interface AssetRelianceHeaderProps {
    id: string;
    title: string;
    primaryContact: PrimaryContact;
    headerOptions: AssetRelianceButtonState;
    showPageActionMenu: boolean;
}

const AssetRelianceHeader: React.FC<AssetRelianceHeaderProps> = ({
                                                                     id,
                                                                     title,
                                                                     primaryContact,
                                                                     headerOptions,
                                                                     showPageActionMenu,
                                                                 }) => {
    const displayName = useAppSelector(selectDisplayName) ?? "";
    const {enableFutureValueOfExcessAssets} = useAppSelector(selectReleaseToggles)!;
    const dispatch = useAppDispatch();
    const history = useHistory();

    const titleRowChildren = () => (
        <div>
            {enableFutureValueOfExcessAssets && showPageActionMenu && <PageActionMenu
                className="asset-reliance-header-container three-dots-menu"
                panelHeight="auto"
                panelWidth={280}
                aria-label="show-asset-reliance-options"
                key={"page-action-menu"}
            >
                <DropdownGroup groupName=" " key={"ls-dropdown-group"} show={true}>
                    <DropdownItem className="asset-reliance-page-action-menu-options" value="" itemText="">
                        Show Expected Excess at Age {primaryContact?.personalPlanningHorizon}
                        <input
                            id="showExpectedExcessAtAge"
                            name="show-expected-excess-at-age"
                            type="checkbox"
                            aria-label="show-expected-excess-at-age"
                            key={"show-expected-excess-at-age"}
                            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                dispatch(setAssetReliancePageActionMenu({
                                    ...headerOptions,
                                    showExpectedExcessAsset: !headerOptions.showExpectedExcessAsset
                                }));
                                e.stopPropagation();
                            }}
                            defaultChecked={headerOptions.showExpectedExcessAsset}
                        />
                    </DropdownItem>
                </DropdownGroup>
            </PageActionMenu>}
            <span className="paddingleft-12"></span>
            <PresentationPaneButton
                iconName="edit"
                kind="borderless"
                onClick={() => history.push(`/Profile/${id}/ClientProfile/EditAssetReliance`)}>
                Edit
            </PresentationPaneButton>
        </div>
    );

    return (
        <PresentationPaneHeader
            className="asset-reliance-header-container"
            displayName={displayName}
            title={title}
            titleRow={titleRowChildren()}
        />
    )
}

export default AssetRelianceHeader;
