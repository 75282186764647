import React, {useMemo} from "react";
import {formatCurrency} from "../../utils/format";
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {Accordion, AccordionItem, Icon, UnderlinedHeader} from "../../components";
import {Table} from "xps-react";
import {TaxLiabilities} from "../models/GoalModelType";

export const TaxLiabilitiesListHeader = (props: { taxLiabilities: TaxLiabilities }) => {
    const {taxLiabilities} = props;

    return (
        <div className="section-header">
            <h3>
                You have <b>{formatCurrency(taxLiabilities.totalTaxLiabilities)}</b> in tax liabilities:
            </h3>
        </div>);
}

export type SummaryTaxLiabilitiesRow = {
    description: string,
    presentValue: string,
}

const SummaryTaxLiabilitiesList = () => {
    const {taxLiabilities} = useAppSelector(selectGoalModel);

    const memoizedTable = useMemo(() => <Table
        resizable={false}
        showPagination={false}
        sortable={false}
        data={
            [
                {
                    description: "Estimated Deferred Tax Liability",
                    presentValue: `${formatCurrency(taxLiabilities.estimatedDeferredTaxLiability)}`,
                },
                {
                    description: "Estimated Unrealized Capital Gains Liability",
                    presentValue: `${formatCurrency(taxLiabilities.estimatedUnrealizedCapitalGainsLiability)}`,
                },
            ]
        }
        columns={
            [
                {
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    accessor: 'description',
                    Cell: ({original}: { original: SummaryTaxLiabilitiesRow }) => {
                        return (
                            <div
                                className="display-flex flex-column justify-content-center height-100p">
                            <span
                                className="table-label-15px">{original.description}</span>
                            </div>
                        );
                    },
                    Header: 'Description'
                },
                {
                    accessor: 'presentValue',
                    Cell: ({original}: { original: SummaryTaxLiabilitiesRow }) => {
                        return (
                            <div
                                className="display-flex flex-column justify-content-center align-items-end height-100p">
                            <span
                                className="table-label-15px">{original.presentValue}</span>
                            </div>
                        );
                    },
                    Header: 'Present Value',
                    headerClassName: 'display-flex justify-content-end',
                },
                {
                    accessor: 'rowActionMenu',
                    className: "overflow-visible",
                    minWidth: 32,
                    maxWidth: 32,
                }
            ]
        }
    />, [taxLiabilities]);
    return (
        <>
            <section>
                <div className="goal-list">
                    <div className="accordion-tables">
                        <Accordion
                            accordionId="SummaryLifestyleGoalListAccordion"
                            allowMultipleExpanded
                            allowZeroExpanded
                        >
                            <div className="sticky-header">
                                <div data-testid="tax-liabilities-underlined-header">
                                    <TaxLiabilitiesListHeader taxLiabilities={taxLiabilities}/>
                                    <UnderlinedHeader
                                        leftAlignedContent={
                                            <span className="display-flex align-items-center">
                                                    <Icon name="chevron_double_right"/>
                                                <span
                                                    className="condensed-subtitle paddingleft-md">Liability Type</span>
                                            </span>
                                        }
                                        rightAlignedContent={
                                            (
                                                <>
                                                    <span className="condensed-subtitle value-label marginright-sm">
                                                        Present Value
                                                    </span>
                                                </>
                                            )
                                        }
                                    />
                                </div>
                                <AccordionItem
                                    uuid="TaxLiabilities"
                                    accentColor="#FFDF77"
                                    primaryText="Tax Liabilities"
                                    rightAlignedContent={
                                        <div className="display-flex align-items-center"
                                             data-testid="tax-liabilities-accordion-item">
                                            <span className="font-lg paddingright-xl">
                                                {formatCurrency(taxLiabilities.totalTaxLiabilities)}
                                            </span>
                                        </div>
                                    }
                                >
                                    <div data-testid="tax-liabilities-table">{memoizedTable}</div>
                                </AccordionItem>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SummaryTaxLiabilitiesList;