import React, {ReactNode, useContext, useState} from "react";
import DiscardAssetModal from "./DiscardAssetModal";
import {useAppSelector} from "../store/hooks";
import {selectProfile} from "../ClientManagement/ClientProfile/activeProfileSlice";
import {useHistory} from "react-router-dom";
import DataEntryFooter from "../components/DataEntry/DataEntryFooter";
import CustomModal from "../components/Modal/Custom/CustomModal";
import AssetsViewContext from "./common/AssetsViewContext";

type EditFormContainerProps = {
    form: (handleCancel: (isFormChanged: boolean) => void) => ReactNode,
    handleDelete: () => void,
    modalTitle: "Asset" | "Liability",
    assetType: string,
    assetDescription: string,
    liabilities?: string[]
}

export default function EditFormContainer(props: EditFormContainerProps) {
    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);

    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const handleCancel = (isFormChanged: boolean) => {
        if (isFormChanged) {
            setShowDiscardChangesModal(true);
            return;
        }
        navigateToAssetsView();
    };

    return <>
        {props.form(handleCancel)}
        <DataEntryFooter
            hideDuplicate={true}
            hideAddNew={true}
            onDelete={() => setShowDeleteModal(true)}
            sticky={true}
        />
        <CustomModal
            isOpen={showDeleteModal}
            title={`Delete this ${props.modalTitle}?`}
            content={
                <>
                    <div className="font-md">
                        {`The ${props.assetDescription} ${props.assetType.toLowerCase()} and all associated data will be deleted permanently.`}
                    </div>
                    {props.liabilities && props.liabilities.length > 0 && (
                        <>
                            <div className="font-md">
                                This asset is serving as collateral for the following liabilities:
                                {props.liabilities.map(liability => (<span key={liability}><br/>&nbsp;&#8226;&nbsp;&nbsp;{liability}</span>))}
                            </div>
                            <div className="font-md">Deleting this asset will also delete the associated liabilities.</div>
                        </>
                    )}
                </>
            }
            onClickConfirm={props.handleDelete}
            onClickCancel={() => setShowDeleteModal(false)}
            confirmText={`Delete ${props.modalTitle}`}
            cancelText={`Keep Editing`}
        />
        <DiscardAssetModal
            isOpen={showDiscardChangesModal}
            title={`Discard changes to this ${props.modalTitle}?`}
            content={`Any data entered for this ${props.modalTitle.toLowerCase()} will not be saved.`}
            onClickKeepEditing={() => setShowDiscardChangesModal(false)}
            onClickDiscardChanges={navigateToAssetsView}
        />
    </>;
}