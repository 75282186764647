import {TableCell} from "../../../components/Table/TableCell";
import {
    formatCurrencyToNumber,
    formatNumberRoundedToTwoDecimals} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {CurrencyInput, DatePicker, Dropdown, DropdownItem, Icon, Input, NumberInput} from "../../../components";
import {DISPLAY_DATE_FORMAT, ISO8601_DATE_FORMAT} from "../../../constants/common";
import React, {ChangeEvent, useEffect, useState} from "react";
import moment, {Moment} from "moment/moment";
import {StockOption, StockOptionType} from "../../models/EquityCompensation";
import { formatOptionalAmount } from "../AddOptionsAndGrants";
import CustomModal from "../../../components/Modal/Custom/CustomModal";

const stockOptionTypeOptions = Object.freeze([
    {
        itemText: "NQSO",
        value: StockOptionType.NQSO
    },
    {
        itemText: "ISO",
        value: StockOptionType.ISO
    }
]);

const stockOptionTypeDropdownItems = stockOptionTypeOptions.map(typeOption => (<DropdownItem key={typeOption.value}
                                                                                             itemText={typeOption.itemText}
                                                                                             value={typeOption.value}/>));

type StockOptionTableRowProps = {
    stockOption: StockOption,
    index: number,
    deleteStockOption: (stockIndex: number) => void;
    handleEditVesting: (stockIndex: number) => void;
    updateStockOption: (stockOption: StockOption, stockIndex: number, refreshCalculations: boolean) => void;
    isColumnsHidden: boolean;
}

const StockOptionTableRow: React.FC<StockOptionTableRowProps> = ({
                                                                     stockOption,
                                                                     index,
                                                                     updateStockOption,
                                                                     handleEditVesting,
                                                                     deleteStockOption,
                                                                     isColumnsHidden,
                                                                 }) => {
    const [stockOptionFormData, setStockOptionFormData] = useState<StockOption>(stockOption);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        setStockOptionFormData(stockOption);
    }, [stockOption]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setStockOptionFormData({
            ...stockOptionFormData,
            [name]: value
        })
    }

    const handleBlur = (name: string, value: string | number, refreshCalculations: boolean) => {
        updateStockOption({
            ...stockOptionFormData,
            [name]: value
        }, index, refreshCalculations);
    }

    return <div
        role="row"
        className={`${isColumnsHidden? 'stock-options-grid-column-hidden': 'stock-options-grid'} stock-options-grid-table-row`}
        tabIndex={0}
        aria-label={`${stockOptionFormData.grantDescription} Row`}
    >
        <div role="cell">
            <Input
                name="grantDescription"
                aria-label={`${stockOptionFormData.grantDescription} Grant Description/ID`}
                value={stockOptionFormData.grantDescription}
                onChange={handleInputChange}
                onBlur={() => handleBlur("grantDescription", stockOptionFormData.grantDescription, false)}
            />
        </div>
        <div role="cell" aria-label={`${stockOptionFormData.grantDescription} Grant date`}>
            <DatePicker
                className="grantDateInput"
                id="grantDateInput"
                size="small"
                displayFormat={DISPLAY_DATE_FORMAT}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel
                date={stockOptionFormData.grantDate ? moment(stockOptionFormData.grantDate) : undefined}
                onDateChange={(date: Moment) => {
                    const grantDate = date?.utc().format(ISO8601_DATE_FORMAT);
                    updateStockOption({
                        ...stockOptionFormData,
                        grantDate
                    }, index, false);
                }}
                disabled={stockOption.vested}
            />
        </div>
        <div role="cell">
            <Dropdown
                name="type"
                aria-label={`${stockOptionFormData.grantDescription} Type`}
                size="small"
                value={stockOptionFormData.type}
                onChange={(data: any) => {
                    updateStockOption({
                        ...stockOptionFormData,
                        type: data?.value as StockOptionType
                    }, index, true)
                }}
            >
                {stockOptionTypeDropdownItems}
            </Dropdown>
        </div>
        <div role="cell" aria-label={`${stockOptionFormData.grantDescription} Expiration date`}>
            <DatePicker
                aria-label="Expiration date"
                className="expirationDateInput"
                id="expirationDateInput"
                size="small"
                displayFormat={DISPLAY_DATE_FORMAT}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel
                date={stockOptionFormData.grantDate ? moment(stockOptionFormData.expirationDate) : undefined}
                onDateChange={(date: Moment) => {
                    const expirationDate = date?.utc().format(ISO8601_DATE_FORMAT);
                    updateStockOption({
                        ...stockOptionFormData,
                        expirationDate
                    }, index, false);
                }}
                disabled={stockOption.vested}
            />
        </div>
        <div role="cell" className="input-right-aligned">
            <CurrencyInput
                aria-label={`${stockOptionFormData.grantDescription} Exercise Price`}
                name="exercisePrice"
                value={stockOptionFormData.exercisePrice}
                onChangeValue={handleInputChange}
                onBlur={(e) => handleBlur(e.target.name, formatCurrencyToNumber(e.target.value), true)}
            />
        </div>
        <div role="cell" className="input-right-aligned">
            <NumberInput
                aria-label={`${stockOptionFormData.grantDescription} Shares Granted`}
                name="sharesGranted"
                value={stockOptionFormData.sharesGranted}
                onChangeValue={handleInputChange}
                onBlur={(e) => handleBlur(e.target.name, formatCurrencyToNumber(e.target.value), true)}
                disabled={stockOptionFormData.vested}
            />
        </div>
        <div role="cell" className="label-13px textalign-right" aria-label={`${stockOptionFormData.grantDescription} Shares Vested`}>
            {stockOptionFormData.vested ? 
                formatNumberRoundedToTwoDecimals(stockOptionFormData.sharesVested) :
                <a role="button" className="edit-vesting-button" onClick={() => handleEditVesting(index)}>Edit Vesting</a>
            }
        </div>
        <div role="cell" className="input-right-aligned">
            <NumberInput
                aria-label={`${stockOptionFormData.grantDescription} Shares Exercised`}
                name="sharesExercised"
                value={stockOptionFormData.sharesExercised}
                onChangeValue={handleInputChange}
                onBlur={(e) => handleBlur(e.target.name, formatCurrencyToNumber(e.target.value), true)}
            />
        </div>
        <TableCell
            textSize="small" 
            text={formatNumberRoundedToTwoDecimals(stockOptionFormData.sharesUnvested)}
            className="textalign-right"
        />

        {!isColumnsHidden && (<><TableCell
            textSize="small"
            text={formatOptionalAmount(stockOptionFormData.grossPotentialValue)}
            className="textalign-right"
            ariaLabel={`${stockOptionFormData.grantDescription} Gross Potential Value`}
        />
        <TableCell
            textSize="small"
            text={formatOptionalAmount(stockOptionFormData.grossExercisedValue)}
            className="textalign-right"
            ariaLabel={`${stockOptionFormData.grantDescription} Gross Exercised Value`}
        />
        <TableCell
            textSize="small"
            text={formatOptionalAmount(stockOptionFormData.afterTaxPotentialValue)}
            className="textalign-right"
            ariaLabel={`${stockOptionFormData.grantDescription} After Tax Potential Value`}
        />
        </>)}
        <TableCell
            textSize="small"
            text={formatOptionalAmount(stockOptionFormData.afterTaxUnvestedMarketValue)}
            className="textalign-right"
            ariaLabel={`${stockOptionFormData.grantDescription} After Tax Unvested Value`}
        />
        <TableCell
            textSize="small"
            text={formatOptionalAmount(stockOptionFormData.afterTaxExercisedValue)}
            className="textalign-right"
            ariaLabel={`${stockOptionFormData.grantDescription} After Tax Exercised Value`}
        />
        <TableActionDropdownMenu
            ariaLabel={`Stock Option Menu`}>
            <DropdownItem
                itemText="Edit Vesting Schedule"
                value="Edit Vesting Schedule"
                onClick={() => handleEditVesting(index)}
            />
            <DropdownItem
                itemText="Delete Stock Option"
                value="Delete Stock Option"
                onClick={() => setShowConfirmModal(true)}
            >
                <Icon name="delete"
                      className="asset-table-delete-icon"/>
            </DropdownItem>
        </TableActionDropdownMenu>
        <CustomModal
            isOpen={showConfirmModal}
            title={`Delete this Stock Option?`}
            content={
                <div>
                    {stockOptionFormData.grantDescription ?`The ${stockOptionFormData.grantDescription} and all associated data will be deleted permanently.` : `The stock option and all associated data will be deleted permanently.`}
                </div>
            }
            onClickConfirm={() => {
                setShowConfirmModal(false);
                deleteStockOption(index);

            }}
            onClickCancel={() => {
                setShowConfirmModal(false);
            }}
            confirmText={`DELETE OPTION`}
            cancelText={`CANCEL`}
        />
    </div>;
}

export default StockOptionTableRow;