import * as go from 'gojs';
import {Diagram, DiagramEvent, Link, Point, Spot} from 'gojs';
import {
    FamilyTreeChartData,
    FamilyTreeLink,
    FamilyTreeMemberNodeType,
    FamilyTreeNode,
    FamilyTreeNodeStyle,
    FamilyTreePartnerRelationshipGroupNodeDataType,
    FamilyTreeType,
    FamilyTreeViewportBounds,
    instanceOfFamilyTreeNodeType,
    NodeOnClickType,
    TemplateCategory
} from "../models/FamilyTreeType";
import {FamilyRelationshipType, RelationshipStatusType} from "../models/FamilyRelationshipType";
import {LifeStatus, MemberType} from "../models/MemberType";
import {findChildren, findExtendedParents, isChildOf} from "../FamilyRelationshipService";
import {RefObject, useEffect} from "react";
import {ReactDiagram} from "gojs-react";
import {NO_OP} from "../../constants/common";
import {FamilyMode} from "./AddFamilyMember/FamilyMode";

// Member style
export const generateMemberRectangleFigure = () => {
    go.Shape.defineFigureGenerator("MemberRectangle", function (shape, w, h) {
        // this figure takes one parameter, the size of the corner
        let p1 = 30;  // default corner size
        if (shape !== null) {
            const param1 = shape.parameter1;
            if (!isNaN(param1) && param1 >= 0) p1 = param1;  // can't be negative or NaN
        }
        p1 = Math.min(p1, w / 2);
        p1 = Math.min(p1, h / 2);  // limit by whole height or by half height?
        let geo = new go.Geometry();
        // a single figure consisting of straight lines and quarter-circle arcs
        geo.add(new go.PathFigure(0, p1)
            .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Line, w - p1, 0))
            .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - p1, p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Arc, 360, 90, w - p1, p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Line, w - p1, h))
            .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, p1, h - p1, p1, p1).close()));
        // don't intersect with two top corners when used in an "Auto" Panel
        geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
        geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);
        return geo;
    });
}

const compareMembers = (partA: go.Part | null, partB: go.Part | null) => {
    const dataA = partA?.data;
    const dataB = partB?.data;

    if (dataA?.siblingGroup < dataB?.siblingGroup) return 1;
    if (dataA?.siblingGroup > dataB?.siblingGroup) return -1;
    if (dataA?.style === FamilyTreeNodeStyle.PRIMARY_CONTACT) return 1;
    if (dataB?.style === FamilyTreeNodeStyle.PRIMARY_CONTACT) return -1;
    if (dataA?.relationshipType === FamilyRelationshipType.CHILD && (
        dataB?.relationshipType === FamilyRelationshipType.SPOUSE ||
        dataB?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
        dataB?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
        dataB?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
    ) return 1;
    if ((
            dataA?.relationshipType === FamilyRelationshipType.SPOUSE ||
            dataA?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
            dataA?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
            dataA?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
        && dataB?.relationshipType === FamilyRelationshipType.CHILD) return -1;
    if (dataA?.relationshipType === FamilyRelationshipType.PARENT && (
        dataB?.relationshipType === FamilyRelationshipType.SPOUSE ||
        dataB?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
        dataB?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
        dataB?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
    ) return 1;
    if ((
            dataA?.relationshipType === FamilyRelationshipType.SPOUSE ||
            dataA?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
            dataA?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
            dataA?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
        && dataB?.relationshipType === FamilyRelationshipType.PARENT) return -1;
    if (dataA?.age < dataB?.age) return -1;
    if (dataA?.age > dataB?.age) return 1;
    if (dataA?.name < dataB?.name) return 1;
    if (dataA?.name > dataB?.name) return -1;
    if (dataA?.key < dataB?.key) return 1;
    if (dataA?.key > dataB?.key) return -1;
    return 0;
};

export enum SiblingGroupPosition {
    LEFT = 0,
    CENTER = 1,
    RIGHT = 2
}

const handleSingleParent = (parent: MemberType, partnerOfParent: MemberType | undefined) => {
    // To determine the position of children, we need to know if the parent has a partner relationship
    if (partnerOfParent) {
        if (parent.age > partnerOfParent.age) return SiblingGroupPosition.LEFT;
        if (parent.age < partnerOfParent.age) return SiblingGroupPosition.RIGHT;
        if (parent.firstName > partnerOfParent.firstName) return SiblingGroupPosition.RIGHT;
        if (parent.firstName < partnerOfParent.firstName) return SiblingGroupPosition.LEFT;
        if (parent.id > partnerOfParent.id) return SiblingGroupPosition.RIGHT;
        if (parent.id < partnerOfParent.id) return SiblingGroupPosition.LEFT;
    }
    return SiblingGroupPosition.LEFT;
}

export const getSiblingGroup = (parents: MemberType[], partnerOfParent: MemberType | undefined) => {
    return parents.length === 1 ? handleSingleParent(parents[0], partnerOfParent) : SiblingGroupPosition.CENTER;
}

const treeLayoutSortComparerFn = (vertexA: go.TreeVertex, vertexB: go.TreeVertex) => {
    let nodeA: go.Part | null = vertexA.node;
    let nodeB: go.Part | null = vertexB.node;
    return compareMembers(nodeA, nodeB);
}

const makeVerticalGridLayoutDiagram = (graph: any): go.Diagram => {
    return graph(go.Diagram, {
        layout: graph(go.GridLayout, {
            wrappingColumn: 1, sorting: go.GridLayout.Ascending,
            comparer: (pa: go.Part, pb: go.Part) => {
                const da = pa.data;
                const db = pb.data;
                if (da.key < db.key) return -1;
                if (da.key > db.key) return 1;
                return 0;
            }
        }),
        model: graph(go.GraphLinksModel,
            {
                linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
            }),
        allowSelect: false,
        allowZoom: true,
        maxScale: 2,
        minScale: 0.3,
    });
}

function checkIfOnlyOneOtherNode(targetLink: Link, sourceNode: go.Node | null, comparison: number) {
    const nodes = targetLink?.containingGroup?.memberParts
        .filter((part) => part instanceof go.Node && part !== sourceNode);
    if (nodes?.count === 1) {
        return compareMembers(sourceNode, nodes.first());
    }
    return comparison;
}

// Style and settings for links / edges on a graph
export const createLinkTemplate = ($: any, diagram: go.Diagram,) => {
    return $(go.Link,
        {
            routing: go.Link.Orthogonal, // the kind of line to draw
            corner: 15,
            fromEndSegmentLength: 32,
            toEndSegmentLength: 18,
            fromSpot: go.Spot.BottomCenter, // default, left/right calculated based on order in group
            toSpot: go.Spot.TopCenter,
            fromPortId: 'out',
            toPortId: 'in'
        },
        $(go.Shape, {stroke: "#CECECE", strokeWidth: 2}),
        new go.Binding('fromSpot', 'to', (_toMemberId: string, targetLink: go.Link) => {
            if (targetLink.containingGroup) {
                // If the link is in a group, there is a partner relationship
                const sourceNode = diagram.findNodeForKey(targetLink.data?.from);
                const targetNode = diagram.findNodeForKey(targetLink.data?.to);
                let comparison = 0;
                let isTargetGroup = false;
                // Determine which side the line should emit from the source node
                if (targetNode?.data?.isGroup) {
                    isTargetGroup = true;
                    targetLink.corner = 0;
                    targetLink.fromEndSegmentLength = 19;
                    // sets comparison to the comparison between this node and one other
                    // we should refactor this method
                    comparison = checkIfOnlyOneOtherNode(targetLink, sourceNode, comparison);
                } else {
                    comparison = compareMembers(sourceNode, targetNode);
                }
                if (comparison < 0) {
                    return drawLinkFromLeftCenterOfNode(isTargetGroup, targetLink);
                } else if (comparison > 0) {
                    return drawLinkFromRightCenterOfNode(isTargetGroup, targetLink);
                }
            }
            return go.Spot.BottomCenter;
        }),
    );
};

const drawLinkFromLeftCenterOfNode = (isTargetGroup: boolean, targetLink: go.Link) => {
    if (!isTargetGroup) {
        targetLink.toSpot = go.Spot.RightCenter;
    }
    return go.Spot.LeftCenter;
}

const drawLinkFromRightCenterOfNode = (isTargetGroup: boolean, targetLink: go.Link) => {
    if (!isTargetGroup) {
        targetLink.toSpot = go.Spot.LeftCenter;
    }
    return go.Spot.RightCenter;
}

// styles and settings for group (primary + partner)
export const createPartnerRelationshipGroupTemplate = ($: any) => {
    return $(go.Group, go.Group.Vertical,
        {
            layout: $(go.GridLayout, {
                    spacing: new go.Size(38, 0),
                    comparer: compareMembers,
                    sorting: go.GridLayout.Descending,

                },
            ),
            selectable: false
        },
        $(go.Panel, go.Panel.Auto, $(go.Placeholder)),
        $(go.Shape,
            {
                portId: '',
                alignment: go.Spot.BottomCenter,
                fromLinkable: false,
                toLinkable: false,
                stroke: null,
                fill: null,
                desiredSize: new go.Size(0, 0)
            }),
    );
};

const getFill = (nodeData: FamilyTreeNode) => {
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    return nodeData.style !== FamilyTreeNodeStyle.BUTTON ? '#FFFFFF' : '#F6F6F6';
};

const getStroke = (nodeData: FamilyTreeNode) => {
    let stroke;
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    switch (nodeData?.style) {
        case FamilyTreeNodeStyle.PRIMARY_CONTACT:
        case FamilyTreeNodeStyle.PRIMARY_LEGAL_PARTNER:
            stroke = '#37A085';
            break;
        case FamilyTreeNodeStyle.DECEASED:
            stroke = '#CECECE';
            break;
        case FamilyTreeNodeStyle.BUTTON:
            stroke = '#F6F6F6';
            break;
        default:
            stroke = '#8AD2C6';
    }
    return stroke;
};

const getStrokeDashArray = (nodeData: FamilyTreeNode) => {
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    let strokeDashArray;
    if (nodeData?.style === FamilyTreeNodeStyle.DOTTED) {
        strokeDashArray = [3, 4];
    } else {
        strokeDashArray = [0, 0]
    }
    return strokeDashArray;
};

const getFont = (nodeData: FamilyTreeNode) => {
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    let fontStyle: string;
    switch (nodeData?.style) {
        case FamilyTreeNodeStyle.DECEASED:
        case FamilyTreeNodeStyle.DOTTED:
            fontStyle = 'italic';
            break;
        default:
            fontStyle = 'normal';
    }
    return `${fontStyle} normal 400 15px roboto`;
}

// Style for the node & node settings
export const createNodeTemplate = (graph: any) => {
    return graph(go.Node,
        go.Node.Auto,
        graph(go.Shape, "MemberRectangle", {
                width: 215, height: 60, strokeWidth: 2,
            },
            new go.Binding('margin', '', (nodeData: FamilyTreeNode) => {
                if (instanceOfFamilyTreeNodeType(nodeData) && nodeData.relationshipType === FamilyRelationshipType.OTHER) {
                    return new go.Margin(30, 0, 0, 0);
                }
                return new go.Margin(0, 0, 0, 0);
            }),
            new go.Binding('fill', '', (nodeData: FamilyTreeNode) => getFill(nodeData)),
            new go.Binding('stroke', '', (nodeData: FamilyTreeNode) => getStroke(nodeData)),
            new go.Binding('strokeDashArray', '', (nodeData: FamilyTreeNode) => getStrokeDashArray(nodeData)),
        ),
        graph(go.Panel, "Table",
            graph(go.RowColumnDefinition, {column: 1, width: 4}),
            graph(go.TextBlock,
                {row: 0, column: 0, columnSpan: 3, alignment: go.Spot.Center},
                {font: "normal normal 500 18px roboto"},
                new go.Binding('stroke', '', (nodeData: FamilyTreeNode) => {
                    if (!instanceOfFamilyTreeNodeType(nodeData)) {
                        return null;
                    }
                    return nodeData.style !== FamilyTreeNodeStyle.BUTTON ? '#3D4042' : '#05676E';
                }),
                {spacingBelow: 2},
                new go.Binding("text", "name")),
            graph(go.TextBlock,
                {row: 1, column: 0, alignment: go.Spot.Center, stroke: "#6B6E6F"},
                {spacingBelow: 7},
                new go.Binding("text", "subtitle"),
                new go.Binding("font", "", (nodeData: FamilyTreeNode) => getFont(nodeData))
            ),
        ),
        new go.Binding('click', 'onClick'),
        new go.Binding('cursor', 'onClick', (onClick) => !!onClick ? 'pointer' : ''));
};


export const createAddOtherMembersButtonTemplate = (graph: any, diagram: go.Diagram) => {
    return graph(go.Node,
        go.Node.Auto,
        graph(go.Shape, "MemberRectangle", {
                width: 215, height: 60, strokeWidth: 2, stroke: '#F6F6F6'
            },
            new go.Binding('fill', '', (nodeData: FamilyTreeNode) => getFill(nodeData)),
            new go.Binding('stroke', '', (nodeData: FamilyTreeNode) => getStroke(nodeData)),
        ),
        graph(go.Panel, "Horizontal",
            graph(go.TextBlock,
                {
                    text: 'add_circle_outline',
                    font: "normal 400 18px nt-dds-icons",
                    stroke: '#05676E',
                    margin: new go.Margin(3, 0, 0, 0)
                }
            ),
            graph(go.Shape, {width: 3, opacity: 0}),
            graph(go.TextBlock,
                {font: "normal normal 500 12px roboto", stroke: '#05676E'},
                new go.Binding("text", "name"),
            )
        ),
        new go.Binding("location", '', NO_OP, locationCenterBelow(diagram)),
        new go.Binding('click', 'onClick'),
        new go.Binding('cursor', 'onClick', (onClick) => !!onClick ? 'pointer' : ''));
};

const createNodeClickHandler = (familyMember: MemberType, nodeOnClick: NodeOnClickType, familyMode: FamilyMode = FamilyMode.IMMEDIATE) => () => nodeOnClick(familyMember, familyMode);

type FamilyTreeNodeOptions = {
    nodeOnClick: NodeOnClickType,
    groupId?: string,
    generation: number
    relationshipType: FamilyRelationshipType | undefined,
    parentRelationshipType?: RelationshipStatusType,
    siblingGroup: number,
};

const determineFamilyMode = (relationshipType: FamilyRelationshipType | undefined): FamilyMode => {
    switch (relationshipType) {
        case FamilyRelationshipType.PARENT:
            return FamilyMode.EXTENDED;
        case FamilyRelationshipType.OTHER:
            return FamilyMode.OTHER;
        default:
            return FamilyMode.IMMEDIATE;
    }
}

export const createFamilyTreeNode = (familyMember: MemberType, {
    nodeOnClick,
    groupId,
    generation,
    relationshipType,
    parentRelationshipType,
    siblingGroup
}: FamilyTreeNodeOptions): FamilyTreeMemberNodeType => {
    const isGenerationZero = generation === 0;
    const isPrimary = isGenerationZero && !relationshipType;
    const nodeData: FamilyTreeMemberNodeType = {
        key: familyMember.id,
        name: familyMember.firstName + " " + familyMember.lastName,
        subtitle: familyMember.age + (familyMember.stateAbbr ? ", " + familyMember.stateAbbr : ''),
        age: familyMember.age,
        primary: isPrimary,
        style: FamilyTreeNodeStyle.DEFAULT,
        group: groupId,
        relationshipType: relationshipType,
        parentRelationshipType: parentRelationshipType,
        siblingGroup: siblingGroup,
        generation: generation,
        onClick: createNodeClickHandler(familyMember, nodeOnClick, determineFamilyMode(relationshipType))
    };

    if (isPrimary) {
        nodeData.style = FamilyTreeNodeStyle.PRIMARY_CONTACT;
    } else {
        if (isGenerationZero && (
            nodeData.relationshipType === FamilyRelationshipType.SPOUSE
            || nodeData.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER
        )) {
            nodeData.style = FamilyTreeNodeStyle.PRIMARY_LEGAL_PARTNER;
        } else if (nodeData.relationshipType === FamilyRelationshipType.EX_SPOUSE) {
            nodeData.style = FamilyTreeNodeStyle.DOTTED;
            nodeData.subtitle = nodeData.subtitle + " Ex-Spouse";
        }
        if (familyMember.lifeStatus === LifeStatus.Deceased) {
            nodeData.style = FamilyTreeNodeStyle.DECEASED;
            nodeData.subtitle = 'Deceased';
        }
    }

    return nodeData;
};

const createFamilyTreeLateralRelationshipGroup = (
    relationshipId: string,
    familyMemberNodeData: FamilyTreeMemberNodeType,
    siblingGroup: number,
    generation: number,
    groupTemplateId: TemplateCategory | string,
): FamilyTreePartnerRelationshipGroupNodeDataType => ({
    key: relationshipId,
    isGroup: true,
    name: familyMemberNodeData.name,
    age: familyMemberNodeData.age,
    siblingGroup: siblingGroup,
    generation: generation,
    category: TemplateCategory.PARTNER_RELATIONSHIP,
    group: groupTemplateId,
});

const createFamilyTreeLink = (sourceNodeKey: string, targetNodeKey: string): FamilyTreeLink => ({
    from: sourceNodeKey,
    to: targetNodeKey,
});

const createLateralRelationshipGroupId = (...familyMembers: (MemberType | undefined)[]): string | undefined => {
    return createLateralRelationshipGroupIdWithPrefix('', familyMembers);
};

const createLateralRelationshipGroupIdWithPrefix = (prefix: string, familyMembers: (MemberType | undefined)[]): string | undefined => {
    const filteredMembers = familyMembers.filter((familyMember): familyMember is MemberType =>
        !!familyMember && !!familyMember.id);
    return filteredMembers.length > 1
        ? prefix + filteredMembers.map((familyMember) => familyMember.id).sort().join(':')
        : undefined;
};

/**
 * Generates family tree chart data for the current node. Recursively processes all family members for the current node.
 *
 * Algorithm:
 *  Create node for current family member
 *  Process partner if exists
 *  Process current family member's children (joint or otherwise)
 *  Process partner's single children (if partner exists)
 *
 * @param familyMember The current member to process.
 * @param nodeOnClick A callback for when any node is clicked.
 * @param showExtendedFamily A boolean to show/hide the extended family.
 * @param generation The generation of the current family member. Will be 0 for the root node.
 * @param groupTemplateId The group id for which to add any generated nodes.
 * @param siblingGroup Nodes of the same level are grouped according to common parents.
 * @param extendedFamilyCoParentGroupId groupId for extended family co-parent group.
 * @param shouldAddCoParentGroupNode A boolean to control whether a group node should be added for co-parents.
 * @param parents The immediate parents of this member.
 */
const generateFamilyTreeChartData = (
    familyMember: MemberType,
    nodeOnClick: NodeOnClickType,
    generation = 0,
    groupTemplateId: TemplateCategory | string = TemplateCategory.IMMEDIATE_FAMILY,
    siblingGroup = SiblingGroupPosition.LEFT,
    {showExtendedFamily, extendedFamilyCoParentGroupId, shouldAddCoParentGroupNode}: {
        showExtendedFamily: boolean;
        extendedFamilyCoParentGroupId: string | null;
        shouldAddCoParentGroupNode: boolean;
    } = {
        showExtendedFamily: false,
        extendedFamilyCoParentGroupId: null,
        shouldAddCoParentGroupNode: false,
    },
    ...parents: MemberType[]
): FamilyTreeChartData => {
    // Initialize the family tree chart data (nodes & links)
    let familyTreeChartData: FamilyTreeChartData = {
        familyTreeNodeData: [],
        familyTreeLinkData: []
    };

    // Base Case
    // If familyMember.id is null, this is the end of the recursion
    if (!familyMember.id) {
        return familyTreeChartData;
    }
    let {familyTreeNodeData, familyTreeLinkData} = familyTreeChartData;

    const accumulate = (chartData: FamilyTreeChartData) => {
        ({familyTreeNodeData, familyTreeLinkData} = familyTreeChartData = {
            familyTreeNodeData: familyTreeNodeData.concat(chartData.familyTreeNodeData),
            familyTreeLinkData: familyTreeLinkData.concat(chartData.familyTreeLinkData)
        });
    }

    // Create the node data representing this family member
    const familyMemberNodeData: FamilyTreeMemberNodeType = createFamilyTreeNode(familyMember, {
        nodeOnClick,
        relationshipType: getGenerationalRelationshipType(generation),
        generation: generation,
        parentRelationshipType: getParentRelationshipType(...parents),
        siblingGroup: siblingGroup,
        groupId: groupTemplateId
    });
    familyTreeNodeData.push(familyMemberNodeData);

    // Lookup the partner of this family member
    const partnerRelation = getMemberPartnerRelation(familyMember);

    const partner: MemberType | undefined = partnerRelation?.fromMember;

    const lateralRelationshipGroupId = createLateralRelationshipGroupId(familyMember, partnerRelation?.fromMember);

    if (partnerRelation) {
        // If the family member has a partner, add both members to a group
        familyMemberNodeData.group = lateralRelationshipGroupId;
        // Create the node data representing this family member's partner
        const partnerNodeData: FamilyTreeMemberNodeType = createFamilyTreeNode(partnerRelation.fromMember, {
            nodeOnClick,
            groupId: lateralRelationshipGroupId,
            generation: generation,
            relationshipType: partnerRelation.type,
            siblingGroup: siblingGroup,
        });
        familyTreeNodeData.push(partnerNodeData);
        // Add the lateral link between the family member and their partner
        familyTreeLinkData.push(createFamilyTreeLink(familyMemberNodeData.key, partnerNodeData.key));
        if (lateralRelationshipGroupId) {
            // Add the group node in order to present the family member and their partner at the same level in the tree
            familyTreeNodeData.push(createFamilyTreeLateralRelationshipGroup(
                lateralRelationshipGroupId,
                familyMemberNodeData,
                siblingGroup,
                generation,
                extendedFamilyCoParentGroupId || groupTemplateId,
            ));
        }
    } else if (extendedFamilyCoParentGroupId) {
        familyMemberNodeData.group = extendedFamilyCoParentGroupId;
    }

    if (extendedFamilyCoParentGroupId && shouldAddCoParentGroupNode) {
        familyTreeNodeData.push(createFamilyTreeLateralRelationshipGroup(
            extendedFamilyCoParentGroupId,
            familyMemberNodeData,
            siblingGroup,
            generation,
            groupTemplateId,
        ));
    }

    // All children of the current family member, single or joint
    const children: MemberType[] = findChildren(familyMember);
    for (const child of children) {
        const parentsOfChild = getParentsOfChild(child, familyMember, partnerRelation?.fromMember);

        const childFamilyTreeChartData = generateFamilyTreeChartData(
            child,
            nodeOnClick,
            generation + 1,
            groupTemplateId,
            getSiblingGroup(parentsOfChild, partner),
            {
                showExtendedFamily,
                extendedFamilyCoParentGroupId: null,
                shouldAddCoParentGroupNode: false,
            },
            ...parentsOfChild
        );

        accumulate(childFamilyTreeChartData);

        if (partner && isChildOf(child.id, partner)) { // Dual Parent
            familyTreeLinkData.push(
                ...parentsOfChild.map((parent) =>
                    // groupId cannot be undefined because we know we have two parents
                    createFamilyTreeLink(parent.id, lateralRelationshipGroupId!)),
                createFamilyTreeLink(lateralRelationshipGroupId!, child.id),
            );
        } else { // Single Parent
            familyTreeLinkData.push(...parentsOfChild.map((parent) => createFamilyTreeLink(parent.id, child.id)));
        }
    }

    // Children of the family member's partner
    findChildren(partner)
        .filter(child => !isChildOf(child.id, familyMember))
        .forEach(child => {
                const childFamilyTreeChartData = generateFamilyTreeChartData(
                    child,
                    nodeOnClick,
                    generation + 1,
                    groupTemplateId,
                    getSiblingGroup(parents, partner),
                    {
                        showExtendedFamily,
                        extendedFamilyCoParentGroupId: null,
                        shouldAddCoParentGroupNode: false,
                    },
                    partner!
                );

                accumulate(childFamilyTreeChartData);
                familyTreeLinkData.push(createFamilyTreeLink(partner!.id, child.id));
            }
        );

    const buildFamilyTreeLinkForParentMembers = (member: MemberType, groupPosition: SiblingGroupPosition) => {
        const parentMembers: MemberType[] = findExtendedParents(member);

        const parentMemberKeys = parentMembers.map(parentMember => parentMember.id);

        let parentWithLateralRelationshipAlreadyProcessed = false;

        const parentMembersGroupId = createLateralRelationshipGroupIdWithPrefix('co-parent:', parentMembers);

        for (const parentMember of parentMembers) {
            if (parentWithLateralRelationshipAlreadyProcessed) {
                continue;
            }

            parentWithLateralRelationshipAlreadyProcessed = doesParentMemberFamilyIncludeAnotherParent(parentMember, parentMemberKeys);
            const parentMemberFamilyTreeChartData = generateFamilyTreeChartData(
                parentMember,
                nodeOnClick,
                generation - 1,
                TemplateCategory.EXTENDED_FAMILY,
                groupPosition,
                {
                    showExtendedFamily,
                    extendedFamilyCoParentGroupId: parentMembersGroupId || null,
                    shouldAddCoParentGroupNode: !familyTreeNodeData.some((nodeData) => nodeData.key === parentMembersGroupId),
                },
            );

            accumulate(parentMemberFamilyTreeChartData);

            let parentLateralRelationshipGroupId;
            if (parentWithLateralRelationshipAlreadyProcessed) {
                const parentMemberPartnerRelation = getMemberPartnerRelation(member);

                parentLateralRelationshipGroupId = createLateralRelationshipGroupId(parentMember, parentMemberPartnerRelation?.fromMember);
            }

            if (parentLateralRelationshipGroupId) {
                familyTreeLinkData.push(createFamilyTreeLink(parentLateralRelationshipGroupId, member.id));
            } else {
                familyTreeLinkData.push(createFamilyTreeLink(parentMember.id, member.id));
            }
        }
    };

    if (showExtendedFamily) {
        buildFamilyTreeLinkForParentMembers(familyMember, SiblingGroupPosition.LEFT);
        if (partner) {
            buildFamilyTreeLinkForParentMembers(partner, SiblingGroupPosition.RIGHT);
        }
    }

    return familyTreeChartData;
};

const getMemberPartnerRelation = (member: MemberType) => {
    return member.family.find((familyRelationship) =>
        familyRelationship.type !== FamilyRelationshipType.CHILD && familyRelationship.type !== FamilyRelationshipType.PARENT);
};

const doesParentMemberFamilyIncludeAnotherParent = (parentMember: MemberType, parentMemberKeys: string[]) => {
    return parentMember.family.some((parentFamilyMember) =>
        parentFamilyMember.id && parentMemberKeys.includes(parentFamilyMember.id));
};

const getGenerationalRelationshipType = (generation: number): FamilyRelationshipType | undefined => {
    let relationshipType: FamilyRelationshipType | undefined = undefined;
    // A positive generation is intended to represent descendants, while a negative generation would indicate ancestors
    if (generation > 0) {
        relationshipType = FamilyRelationshipType.CHILD;
    } else if (generation < 0) {
        relationshipType = FamilyRelationshipType.PARENT;
    }
    return relationshipType;
}

const getParentRelationshipType = (...parents: MemberType[]): RelationshipStatusType | undefined => {
    let relationshipType: RelationshipStatusType | undefined = undefined;
    if (parents.length === 1) {
        relationshipType = RelationshipStatusType.SINGLE_PARENT;
    } else if (parents.length > 1) {
        relationshipType = RelationshipStatusType.PARTNERED;
    }
    return relationshipType;
}

const isMemberParentOfChild = (member: MemberType, child: MemberType): boolean =>
    member.family.some((familyRelationship) =>
        familyRelationship.fromMember.id === child.id
        && familyRelationship.type === FamilyRelationshipType.CHILD
    );

const getParentsOfChild = (child: MemberType, ...potentialParents: (MemberType | undefined)[]): MemberType[] =>
    potentialParents
        .filter((potentialParent): potentialParent is MemberType => !!potentialParent?.id)
        .filter((potentialParent) => isMemberParentOfChild(potentialParent, child));

export const generateFamilyTreeNodeData = (
    familyTreeData: FamilyTreeType,
    nodeOnClick: NodeOnClickType,
    onAddOtherMembersClick: () => void,
    showOtherMembers: boolean,
    showAddOtherMembersButton: boolean,
    showExtendedFamily: boolean = false
): FamilyTreeChartData => {
    const familyTreeChartData = generateFamilyTreeChartData(
        familyTreeData.primaryContact,
        nodeOnClick,
        0,
        TemplateCategory.IMMEDIATE_FAMILY,
        SiblingGroupPosition.LEFT,
        {
            showExtendedFamily,
            extendedFamilyCoParentGroupId: null,
            shouldAddCoParentGroupNode: false,
        }
    );
    // Create group for Immediate Family
    familyTreeChartData.familyTreeNodeData.push({
        key: TemplateCategory.IMMEDIATE_FAMILY,
        isGroup: true,
        category: TemplateCategory.IMMEDIATE_FAMILY,
        centerRelativeToPartKey: TemplateCategory.EXTENDED_FAMILY,
    });
    if (showOtherMembers) {
        familyTreeData.primaryContact.otherMembers
            .forEach((relationship) => {
                familyTreeChartData.familyTreeNodeData.push(
                    createFamilyTreeNode(relationship.fromMember, {
                        nodeOnClick: nodeOnClick,
                        generation: 0,
                        relationshipType: FamilyRelationshipType.OTHER,
                        // sibling group is irrelevant for this scenario
                        siblingGroup: SiblingGroupPosition.CENTER,
                        groupId: TemplateCategory.OTHER_MEMBERS_CONTENT,
                    })
                );
            });
        // Create group for Other Members section
        familyTreeChartData.familyTreeNodeData.push({
            key: TemplateCategory.OTHER_MEMBERS_WRAPPER,
            isGroup: true,
            category: TemplateCategory.OTHER_MEMBERS_WRAPPER,
            centerRelativeToPartKey: TemplateCategory.IMMEDIATE_FAMILY,
        });
        // Create group for Other Members Content Area
        familyTreeChartData.familyTreeNodeData.push({
            key: TemplateCategory.OTHER_MEMBERS_CONTENT,
            isGroup: true,
            category: TemplateCategory.OTHER_MEMBERS_CONTENT,
            group: TemplateCategory.OTHER_MEMBERS_WRAPPER,
        });
        if (showAddOtherMembersButton) {
            familyTreeChartData.familyTreeNodeData.push({
                key: TemplateCategory.ADD_OTHER_MEMBERS_BUTTON,
                name: "ADD OTHER MEMBER",
                category: TemplateCategory.ADD_OTHER_MEMBERS_BUTTON,
                group: TemplateCategory.OTHER_MEMBERS_WRAPPER,
                style: FamilyTreeNodeStyle.BUTTON,
                onClick: onAddOtherMembersClick,
                centerRelativeToPartKey: TemplateCategory.OTHER_MEMBERS_CONTENT,
            });
        }
    }

    if (showExtendedFamily) {
        familyTreeChartData.familyTreeNodeData.push({
            key: TemplateCategory.EXTENDED_FAMILY,
            isGroup: true,
            category: TemplateCategory.EXTENDED_FAMILY,
        });
    }

    return familyTreeChartData;
};

const makeGraph = () => {
    generateMemberRectangleFigure();
    go.Diagram.licenseKey = "73f944e7bb6031b700ca0d2b113f69ee1bb37b369e821ff55d5641a7ef0a691c2bc9ec7e59db8e90d5f94ffd197bc28d8ec16d2d855c026bb465d6da17e3d5aab23073b61c09438eac0a26c39ffb2af2fb7d63e2c4e027a4da2adcf3f9b8c09d5febecd657cc";
    return go.GraphObject.make;
}

const getDiagramModel = (graph: any) => graph(go.GraphLinksModel, {
    linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
})

export const initializeTreeDiagramWithOtherMembers = (): Diagram => {
    const graph = makeGraph();
    const diagram: go.Diagram = makeVerticalGridLayoutDiagram(graph);

    diagram.animationManager.isInitial = false;
    diagram.animationManager.isEnabled = false;

    diagram.toolManager.standardPinchZoomStart = function () {
        go.ToolManager.prototype.standardPinchZoomStart.call(this);
    }

    diagram.toolManager.standardPinchZoomMove = function () {
        go.ToolManager.prototype.standardPinchZoomMove.call(this);
    }

    diagram.nodeTemplateMap.add(TemplateCategory.DEFAULT, createNodeTemplate(graph));
    diagram.nodeTemplateMap.add(TemplateCategory.ADD_OTHER_MEMBERS_BUTTON, createAddOtherMembersButtonTemplate(graph, diagram));

    diagram.linkTemplate = createLinkTemplate(graph, diagram);

    diagram.groupTemplateMap.add(TemplateCategory.PARTNER_RELATIONSHIP, createPartnerRelationshipGroupTemplate(graph));
    diagram.groupTemplateMap.add(TemplateCategory.IMMEDIATE_FAMILY, createImmediateFamilyGroupTemplate(graph, diagram));
    diagram.groupTemplateMap.add(TemplateCategory.EXTENDED_FAMILY, createExtendedFamilyGroupTemplate(graph));
    diagram.groupTemplateMap.add(TemplateCategory.OTHER_MEMBERS_WRAPPER, createOtherMembersWrapperGroupTemplate(graph, diagram));
    diagram.groupTemplateMap.add(TemplateCategory.OTHER_MEMBERS_CONTENT, createOtherMembersContentGroupTemplate(graph));

    diagram.model = getDiagramModel(graph);

    return diagram;
};

const createImmediateFamilyGroupTemplate = (graph: any, diagram: go.Diagram) => {
    return graph(go.Group, go.Group.Auto,
        {
            layout: graph(go.TreeLayout,
                {
                    layerStyle: go.TreeLayout.LayerUniform,
                    treeStyle: go.TreeLayout.StyleLayered,
                    arrangement: go.TreeLayout.ArrangementFixedRoots,
                    angle: 90,
                    alternateAlignment: go.TreeLayout.AlignmentBus,
                    nodeSpacing: 38,
                    comparer: treeLayoutSortComparerFn,
                    sorting: go.TreeLayout.SortingDescending,
                    layerSpacing: 50,
                    alignment: go.TreeLayout.AlignmentCenterChildren,
                },
            ),
            selectable: false
        },
        graph(go.Panel, go.Panel.Auto, graph(go.Placeholder)),
        new go.Binding("location", '', NO_OP, locationCenterBelow(diagram)),
    );
};

const createExtendedFamilyGroupTemplate = (graph: any) => {
    return graph(go.Group, go.Group.Auto,
        {
            layout: graph(go.GridLayout, {
                    spacing: new go.Size(38, 0),
                    comparer: compareMembers,
                    sorting: go.GridLayout.Descending,
                    wrappingWidth: 10_000,
                    cellSize: new go.Size(217, 60),
                },
            ),
            selectable: false
        },
        graph(go.Panel, go.Panel.Auto, graph(go.Placeholder, {
            // Padding bottom to provide space between extended and immediate
            margin: new go.Margin(0, 0, 50, 0)
        })),
    );
};

const createOtherMembersWrapperGroupTemplate = (graph: any, diagram: go.Diagram) => {
    return graph(go.Group, go.Group.Auto, {layout: graph(go.GridLayout, {wrappingColumn: 1})},
        graph(go.Panel, go.Panel.Auto,
            graph(go.TextBlock, {
                stroke: '#3D4042',
                font: "normal normal 500 18px roboto",
                text: 'Other Members',
                textAlign: "center",
                name: 'OtherMembersLabel'
            }),
            graph(go.Placeholder),
        ),
        new go.Binding("location", '', NO_OP, locationCenterBelow(diagram, {marginTop: 110})),
    );
};

const createOtherMembersContentGroupTemplate = (graph: any) => {
    return graph(go.Group, go.Group.Auto, {
            layout: graph(go.GridLayout, {
                spacing: new go.Size(38, 0),
            })
        },
        graph(go.Panel, go.Panel.Auto,
            graph(go.Placeholder, {
                // Padding top needs to account for the height of 'Other Members' TextBlock (18px)f
                margin: new go.Margin(18, 0, 5, 0)
            })),
    );
};

const locationCenterBelow = (diagram: go.Diagram, {marginTop = 0}: { marginTop?: number } = {}) => {
    return (currentLocation: go.Point, targetPartData: FamilyTreeNode) => {
        let centerPoint = currentLocation;
        const targetPart: go.Part | null = diagram.findPartForKey(targetPartData.key);
        if (targetPart) {
            const relativePart: go.Part | null = diagram.findPartForKey(targetPartData.centerRelativeToPartKey);
            relativePart?.ensureBounds();
            targetPart.ensureBounds();
            const relativePartBounds = relativePart ? relativePart.actualBounds : {x: 0, y: 0, width: 0, height: 0};
            const targetPartBounds = targetPart ? targetPart.actualBounds : {width: 0};
            const relativePartCenterX = (relativePartBounds.width / 2) + relativePartBounds.x;
            const targetPartCenterX = targetPartBounds.width / 2;
            centerPoint = new go.Point(
                relativePartCenterX - targetPartCenterX,
                relativePartBounds.y + relativePartBounds.height + marginTop
            );
            targetPart.move(centerPoint, true);
        }
        return go.Point.stringify(centerPoint);
    };
}

export const useFamilyTreeViewportBoundsChangeHandler = (
    diagramReference: RefObject<ReactDiagram>,
    handleViewportBoundsChanged: (viewportBounds: FamilyTreeViewportBounds) => void,
) => {
    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        const viewportBoundsChangedHandler = (diagramEvent: DiagramEvent) => {
            const originalWidth = diagramEvent.diagram.viewportBounds.width;
            const updatedWidth = diagramEvent.parameter.width;
            const widthDifference = originalWidth - updatedWidth;
            const originalHeight = diagramEvent.diagram.viewportBounds.height;
            const updatedHeight = diagramEvent.parameter.height;
            const heightDifference = originalHeight - updatedHeight;
            if (heightDifference !== 0 || widthDifference !== 0) {
                diagramEvent.diagram.position = new Point(
                    diagramEvent.diagram.viewportBounds.x - (widthDifference / 2),
                    diagramEvent.diagram.viewportBounds.y - (heightDifference / 2),
                );
            }

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                const viewportBounds: FamilyTreeViewportBounds = {
                    scale: diagramEvent.diagram.scale,
                    position: {
                        x: diagramEvent.diagram.position.x,
                        y: diagramEvent.diagram.position.y,
                    }
                };
                handleViewportBoundsChanged(viewportBounds);
            }, 200);
        };
        const diagram = diagramReference.current?.getDiagram();
        diagram?.addDiagramListener('ViewportBoundsChanged', viewportBoundsChangedHandler);
        return () => {
            clearTimeout(timeoutId);
            diagram?.removeDiagramListener('ViewportBoundsChanged', viewportBoundsChangedHandler);
        };
    }, [diagramReference.current, handleViewportBoundsChanged]);
};

export const useDocumentBoundsChangeHandler = (
    diagramReference: RefObject<ReactDiagram>,
    shouldReFitTreeWhenDocumentBoundsChange: boolean,
    handleViewportBoundsChanged: (viewportBounds: FamilyTreeViewportBounds) => void,
) => {
    const handleDocumentBoundsChanged = (diagramEvent: DiagramEvent) => {
        if (shouldReFitTreeWhenDocumentBoundsChange) {
            diagramEvent.diagram.scale = 0;

            const handleLayoutCompleted = () => {
                handleViewportBoundsChanged({
                    scale: diagramEvent.diagram.scale,
                    position: {
                        x: diagramEvent.diagram.position.x,
                        y: diagramEvent.diagram.position.y,
                    }
                });
                diagramEvent.diagram.removeDiagramListener('LayoutCompleted', handleLayoutCompleted);
            }
            diagramEvent.diagram.addDiagramListener('LayoutCompleted', handleLayoutCompleted);

            diagramEvent.diagram.zoomToFit();
            diagramEvent.diagram.alignDocument(Spot.Center, Spot.Center);
        }
    };
    useEffect(() => {
        const diagramRef = diagramReference.current?.getDiagram();
        diagramRef?.addDiagramListener("DocumentBoundsChanged", handleDocumentBoundsChanged);

        return () => {
            diagramRef?.removeDiagramListener("DocumentBoundsChanged", handleDocumentBoundsChanged);
        };
    }, [diagramReference.current]);
}

export const useToResetFamilyTreeToDefault = (
    diagramReference: RefObject<ReactDiagram>,
    viewportBounds: FamilyTreeViewportBounds | null,
) => {
    useEffect(() => {
        const diagram = diagramReference.current?.getDiagram();
        if (null === viewportBounds && diagram) {
            diagram.raiseDiagramEvent('DocumentBoundsChanged');
        }
    }, [diagramReference.current, viewportBounds]);
}