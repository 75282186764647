import React, {useContext} from 'react';
import {SharedMap} from "fluid-framework";
import {defaultSharedObjectMutators, SharedObjectMutators} from "./SharedObjectMutators";
import {NO_OP} from "../../../../constants/common";

export type MeetingContainerSchema = {
    meetingControlDDS: SharedMap;
    domContentDDS: SharedMap;
    canvasContentDDS: SharedMap;
    meetingPortalParticipantJoinDDS: SharedMap;
};

type RelayContextValue = {
    containerId: string;
    createMeetingContainer: (userId: string, userName: string) => Promise<{ containerId: string, documentId: string }>;
    connectToMeetingContainer: (userId: string, userName: string, containerId: string) => Promise<void>;
    connectToMeetingContainerFromMeetingPortal: (containerId: string, fluidToken: string, joinMeetingId: string, joinMeetingPasscode: string, user: {
        id: string,
        name: string,
    }) => Promise<void>;
    unloadMeetingContainer: () => void;
    disconnectFromMeetingContainer: () => void;
    sharedObjectMutators: SharedObjectMutators;
    sharedObjects: MeetingContainerSchema | null;
}

const defaultValue: RelayContextValue = {
    containerId: '',
    createMeetingContainer: () => Promise.resolve({containerId: '', documentId: ''}),
    connectToMeetingContainer: () => Promise.resolve(),
    connectToMeetingContainerFromMeetingPortal: () => Promise.resolve(),
    unloadMeetingContainer: NO_OP,
    disconnectFromMeetingContainer: NO_OP,
    sharedObjectMutators: defaultSharedObjectMutators,
    sharedObjects: null,
};

const relayContext = React.createContext(defaultValue);

export const useRelayContext = () => {
    return useContext(relayContext);
};

export default relayContext;