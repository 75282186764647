import React from 'react';
import {LegalAgreement} from "../../models/InvestmentProgram";
import {formatCurrency} from "../../../utils/format";

type InvestmentProgramTableProps = {
    accounts: LegalAgreement[]
}

const noValue = <span className="no-value">&ndash;&ndash;</span>;

const InvestmentProgramTable = ({accounts}: InvestmentProgramTableProps) => {
    return (
        <div className="investment-program-table">
            <div className="investment-program-table-header investment-program-grid">
                <span>Legal Agreement Name</span>
                <span
                    className="textalign-right">Legal Agreement #</span>
                <span className="marginleft-xl textalign-left">Institution</span>
                <span className="textalign-right">As of Date</span>
                <span className="textalign-right">Market Value</span>
            </div>
            {accounts.map((account: LegalAgreement) => (
                <div
                    className="investment-program-table-row investment-program-grid"
                    key={account.name}
                >
                    <span className="truncate">{account.name}</span>
                    <span className="textalign-right">{account.legalAgreementNumber}</span>
                    <span className="marginleft-xl textalign-left">{account.institution}</span>
                    <span className="textalign-right">{account.asOfDate}</span>
                    <span className="textalign-right">
                        {account.marketValue !== null ? `${formatCurrency(account.marketValue)}` : noValue}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default InvestmentProgramTable;
