import React from "react";
import {EventHandler} from "../../constants/common";
import {Label} from "../Label/Label";
import {Gender} from "../../ClientManagement/models/MemberType";
import {Dropdown, DropdownItem} from "../Dropdown";

type GenderInputProps = {
    onChange: EventHandler,
    className?: string,
    value?: string,
    panelWidth?: string
}


const dropdownItems = Object.values(Gender).map(gender => (<DropdownItem key={gender}
                                                                         itemText={gender}
                                                                         value={gender}/>));

export const GenderInput: React.FC<GenderInputProps> = ({
                                                            onChange,
                                                            className = "",
                                                            panelWidth = "300px",
                                                            value = Gender.Undisclosed
                                                        }) => {
    return (
        <div className={`h4 parent ${className}`}>
            <Label label={"Gender"}/>
            <Dropdown
                className="genderField"
                name="gender"
                id="genderInput"
                aria-label="gender"
                aria-labelledby="gender-label"
                alignRight={false}
                buttonIcon="right"
                buttonKind="secondary"
                defaultText="Select..."
                defaultPageSize={15}
                dropdownKind="select"
                dynamicDropAlign={false}
                dynamicDropDirection={false}
                iconNameClose="arrow_drop_up"
                iconNameOpen="arrow_drop_down"
                labelWidth={panelWidth}
                nativeOnMobile={false}
                panelHeight="auto"
                panelWidth={panelWidth}
                onChange={onChange}
                size="medium"
                value={value}
                virtualScroll={false}
            >
                {dropdownItems}
            </Dropdown>
        </div>
    );
}
