import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {ActiveFormAsset, defaultAssetsState, InEstateAssetTotals} from "../../../Assets/clientAssetsSlice";
import {AssetsSummary} from "../../../Assets/models/Assets";
import React, {useEffect, useState} from "react";
import {formatCurrency} from "../../../utils/format";
import {
    calculateCNWInEstateTotalValue,
    calculateOutOfEstateTotalValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {hasAssets, hasInEstateAssets, hasLiabilities} from "../../../Assets/AssetSummary/common/utils";
import {BarChartSidebar} from "../../../components";
import {createAssetBarChartData} from "../../../Assets/AssetSummary/BarChart/AssetBarChartUtils";
import {AssetBarChartFooter} from "../../../Assets/AssetSummary/BarChart/AssetBarChartFooter";
import CurrentNetWorthAssetListReport from "./CurrentNetWorthAssetListReport";
import {InvestmentProgram, LegalAgreement} from "../../../Assets/models/InvestmentProgram";
import {PersonalLiabilitySummary} from "../../../Assets/models/PersonalLiability";
import {
    hasOutOfEstateLegalEntities, hasOutOfEstateNonClientSummaries,
    hasSufficientSpace,
    InAndOutEstateAssets, mergeAssetSummariesAndLegalEntitiesPages, mergeLegalEntitiesAndNonClientPages,
    PAGE_CONSTANTS, splitNonClientMemberSummaries,
    splitOutOfEstateLegalEntities
} from "../AssetSummaryDetailReport/AssetsReportUtils";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {PersonalAsset} from "../../../Assets/models/PersonalAsset";
import {GeneralInflow} from "../../../Assets/models/GeneralInflow";
import {SocialSecurity} from "../../../Assets/models/SocialSecurity";
import {LifeInsurance} from "../../../Assets/models/LifeInsurance";
import PrintViewWrapper from "../PrintViewWrapper";
import {useAppDispatch} from "../../../store/hooks";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {
    createLegalEntityTypeSummaries,
    createNonClientMemberSummaries,
    LegalEntityTypeSummary,
    NonClientMemberSummary
} from "../../../Assets/mappers";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import OutOfEstateLegalEntityReportView from "../AssetSummaryDetailReport/OutOfEstateLegalEntityReportView";
import OutOfEstateNonClientSummariesView from "../AssetSummaryDetailReport/OutOfEstateNonClientSummariesView";


export type CurrentNetWorthReportViewProps = {
    investorGroup?: InvestorGroupType,
    clientAssets: AssetsSummary,
    showDeathBenefit: boolean,
    displayName: string,
    inEstateTotals: InEstateAssetTotals,
    activeFormAsset: ActiveFormAsset,
    legalEntities: LegalEntityReadModel[],
    isDetailed: boolean
}

export function CurrentNetWorthReportView({
                                              investorGroup,
                                              clientAssets,
                                              showDeathBenefit,
                                              displayName,
                                              inEstateTotals,
                                              legalEntities,
                                              isDetailed
                                          }: CurrentNetWorthReportViewProps) {

    const dispatch = useAppDispatch();
    const [CurrentNetWorthReportPages, setCurrentNetWorthReportPages] = useState<InAndOutEstateAssets[]>([]);


    type CNWAssetTypeList =
        StandaloneAccount[]
        | PersonalAsset[]
        | GeneralInflow[]
        | SocialSecurity[]
        | LegalAgreement[]
        | PersonalLiabilitySummary[]
        | LifeInsurance[]

    const hasAccountsForCNW = (clientAssetsData: AssetsSummary) => {
        return clientAssets && clientAssetsData.accounts?.data?.length > 0;
    }

    const hasInvestmentProgramsForCNW = (clientAssetsData: AssetsSummary) => {
        return clientAssets && clientAssetsData.investmentProgram && clientAssetsData.investmentProgram.legalAgreements.length > 0
    }

    const hasGeneralInflowsForCNW = (clientAssetsData: AssetsSummary) => {
        return clientAssets && clientAssetsData.generalInflows?.data?.length > 0;
    }

    const hasSocialSecurity = (clientAssetsData: AssetsSummary) => {
        return clientAssets && clientAssetsData.socialSecurities?.data?.length > 0;
    }

    const populateCNWAssetsList = (assetType: keyof AssetsSummary, data: CNWAssetTypeList): Partial<AssetsSummary> => {

        switch (assetType) {
            case 'investmentProgram':
                return {
                    investmentProgram: {
                        ...clientAssets.investmentProgram,
                        legalAgreements: data as LegalAgreement[]
                    } as InvestmentProgram
                }
            case 'personalLiabilities':
                return {
                    personalLiabilities: data as PersonalLiabilitySummary[]
                }
            default:
                const assetTypeData = clientAssets[assetType];
                if (typeof assetTypeData != 'number') {
                    return {
                        [assetType]: {
                            ...assetTypeData,
                            data
                        }
                    }
                } else {
                    return {}
                }
        }
    };

    const setPageCNWAssetData = (assetType: keyof AssetsSummary, pageAssets: AssetsSummary, pageScore: number, data: CNWAssetTypeList, calculatedAssetsPages: AssetsSummary[], isNestedAssetType: boolean) => {

        const nestedAssetsHeaderLength = isNestedAssetType ? 1 : 0;
        let updatedPageScore = pageScore + (data.length + nestedAssetsHeaderLength) * PAGE_CONSTANTS.TABLE_ROW_HEIGHT;

        if (updatedPageScore < PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT) {
            pageAssets = {
                ...pageAssets,
                ...populateCNWAssetsList(assetType, data)
            }
        } else {
            const remainingPageSpace = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
            let noOfAssetsPageCanFit = 0;
            if (remainingPageSpace > 0) {
                // If there is enough space to fit 3 assets, slice the 3 and push them to current page
                noOfAssetsPageCanFit = Math.floor(remainingPageSpace / PAGE_CONSTANTS.TABLE_ROW_HEIGHT);
                if (noOfAssetsPageCanFit > 0) {
                    const assetsThatCanFitInCurrentPage = data.slice(0, noOfAssetsPageCanFit);
                    pageAssets = {
                        ...pageAssets,
                        ...populateCNWAssetsList(assetType, assetsThatCanFitInCurrentPage)
                    };
                }
            }
            // Push Current Page
            calculatedAssetsPages.push(pageAssets);

            // Creating New Page
            updatedPageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT + PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
            pageAssets = defaultAssetsState;

            // For rest of the assets, split the remaining data recursively until all assets are fit into 1 or more pages
            const remainingAssets = data.slice(noOfAssetsPageCanFit);

            if (remainingAssets.length > 0) {

                const result = setPageCNWAssetData(assetType, pageAssets, updatedPageScore, remainingAssets, calculatedAssetsPages, isNestedAssetType);
                updatedPageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
        }

        return {pageScore: updatedPageScore, pageAssets};
    }

    const getEstimatesForCNWInvestmentsAndAccounts = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {

        if (hasAccountsForCNW(assetsData) || hasInvestmentProgramsForCNW(assetsData)) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            if (hasAccountsForCNW(assetsData)) {
                const result = setPageCNWAssetData('accounts', pageAssets, pageScore, assetsData.accounts.data, calculatedAssets, true);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }

            if (hasInvestmentProgramsForCNW(assetsData)) {
                const result = setPageCNWAssetData('investmentProgram', pageAssets, pageScore, (assetsData.investmentProgram?.legalAgreements || []), calculatedAssets, true);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
        }
        return {pageScore, pageAssets};
    }

    const getEstimatesForCNWAssets = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {
        if (hasAssets(assetsData)) {
            const __ret = getEstimatesForCNWInvestmentsAndAccounts(assetsData, pageScore, pageAssets, calculatedAssets);
            pageScore = __ret.pageScore;
            pageAssets = __ret.pageAssets;

            if (hasGeneralInflowsForCNW(assetsData) || hasSocialSecurity(assetsData)) {
                pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

                if (hasSocialSecurity(assetsData)) {
                    const result = setPageCNWAssetData('socialSecurities', pageAssets, pageScore, assetsData.socialSecurities.data, calculatedAssets, true);
                    pageScore = result.pageScore;
                    pageAssets = result.pageAssets;
                }
            }

            if (assetsData.personalAssets.data.length > 0) {
                pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
                const result = setPageCNWAssetData('personalAssets', pageAssets, pageScore, assetsData.personalAssets.data, calculatedAssets, false);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
            if (assetsData.lifeInsurances.data.length > 0) {
                pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
                const result = setPageCNWAssetData('lifeInsurances', pageAssets, pageScore, assetsData.lifeInsurances.data, calculatedAssets, false);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
        }
        return {pageScore, pageAssets};
    }

    const splitCNWAssets = (assetsData: AssetsSummary) => {
        const calculatedAssets: AssetsSummary[] = [];
        let pageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
        let pageAssets: AssetsSummary = {...defaultAssetsState};

        const __ret = getEstimatesForCNWAssets(assetsData, pageScore, pageAssets, calculatedAssets);
        pageScore = __ret.pageScore;
        pageAssets = __ret.pageAssets;

        if (hasLiabilities(assetsData)) {
            if (hasAssets(assetsData)) {
                pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
            }
            pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
            pageScore += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

            const result = setPageCNWAssetData('personalLiabilities', pageAssets, pageScore, assetsData.personalLiabilities, calculatedAssets, false);
            pageAssets = result.pageAssets;
        }

        if (pageAssets !== defaultAssetsState) {
            calculatedAssets.push(pageAssets);
        }

        let spaceLeft: number ;
        if (PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT < pageScore) spaceLeft = 2 * PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
        else spaceLeft = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
        return {assetSummariesData: calculatedAssets, remainingSpaceAfterSplittingAssets: spaceLeft};
    }


    const [legalEntityTypeSummaries, setLegalEntityTypeSummaries] = useState<LegalEntityTypeSummary[]>([]);
    const [nonClientMemberSummaries, setNonClientMemberSummaries] = useState<NonClientMemberSummary[]>([]);
    const [allItems, setAllItems] = useState<any[]>([]);
    const [firstLegalEntityPageIndex, setFirstLegalEntityPageIndex] = useState<number>();

    function expandNonClientAccordions(nonClientNamesToExpand: string[]) {
        const nonClientAccordionNames = nonClientNamesToExpand.map((accordionName) => accordionName + "-report");
        dispatch(setAccordionPreferencesState({
            accordionId: "CurrentNetWorthOutOfEstateNonClientAccordionReport",
            state: {
                expandedItems: [...nonClientAccordionNames]
            }
        }));
    }

    useEffect(() => {
        if(!isDetailed) {
            setCurrentNetWorthReportPages([{
                assetsSummary: clientAssets,
                legalEntitySummary: undefined,
                nonClientMemberSummary: undefined
            }]);
        } else {
            //assetSumaries pages
            const {assetSummariesData, remainingSpaceAfterSplittingAssets} = splitCNWAssets(clientAssets);
            const assetSummariesPages: InAndOutEstateAssets[] = [];
            assetSummariesData.forEach(summary => {
                assetSummariesPages.push({
                    assetsSummary: summary,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: undefined
                });
            });
            if (hasSufficientSpace(remainingSpaceAfterSplittingAssets)) {
                setFirstLegalEntityPageIndex(assetSummariesPages.length - 1);
            } else {
                setFirstLegalEntityPageIndex(assetSummariesPages.length);
            }
            //legal pages
            let tempLegalEntityTypeSummaries = createLegalEntityTypeSummaries('CurrentNetWorth', clientAssets, legalEntities);
            const {legalEntityTypeSummariesArray, remainingSpaceAfterLegalEntities} =  splitOutOfEstateLegalEntities(tempLegalEntityTypeSummaries, remainingSpaceAfterSplittingAssets);
            const legalEntitySummariesPages: InAndOutEstateAssets[] = [];
            legalEntityTypeSummariesArray.forEach((summary) => {
                legalEntitySummariesPages.push({
                    assetsSummary: undefined,
                    legalEntitySummary: summary,
                    nonClientMemberSummary: undefined
                })
            })
            const legalEntityNames = [...tempLegalEntityTypeSummaries.map((summary: { entityType: any; }) => summary.entityType)];
            setLegalEntityTypeSummaries(tempLegalEntityTypeSummaries);
            //non client pages
            let tempNonClientMemberSummaries = createNonClientMemberSummaries('CurrentNetWorth', clientAssets);
            const  nonClientMemberSummariesArray: Array<NonClientMemberSummary[]> = splitNonClientMemberSummaries(tempNonClientMemberSummaries, remainingSpaceAfterLegalEntities);
            const nonClientMemberSummariesPages: InAndOutEstateAssets[] = [];
            nonClientMemberSummariesArray.forEach((summary) => {
                nonClientMemberSummariesPages.push({
                    assetsSummary: undefined,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: summary
                })
            })
            const nonClientNames = [
                ...tempNonClientMemberSummaries.map((summary: { memberName: any; }) => `${summary.memberName}-assets-summary`)
            ];
            setNonClientMemberSummaries(tempNonClientMemberSummaries);
            setAllItems([...legalEntityNames, ...nonClientNames]);

            const allAssetsPages: InAndOutEstateAssets[] = [...assetSummariesPages, ...legalEntitySummariesPages, ...nonClientMemberSummariesPages];

            if (assetSummariesPages.length > 0 && legalEntitySummariesPages.length > 0 && hasSufficientSpace(remainingSpaceAfterSplittingAssets)) {
                mergeAssetSummariesAndLegalEntitiesPages(assetSummariesPages, legalEntitySummariesPages,allAssetsPages);
            }
            if (legalEntitySummariesPages.length > 0 && nonClientMemberSummariesPages.length > 0 && hasSufficientSpace(remainingSpaceAfterLegalEntities)) {
                mergeLegalEntitiesAndNonClientPages(legalEntitySummariesPages, nonClientMemberSummariesPages, allAssetsPages , assetSummariesPages.length);
            }

            if (CurrentNetWorthReportPages.length === 0) {
                setCurrentNetWorthReportPages(allAssetsPages);
                expandNonClientAccordions(nonClientNames);
            }
        }
    },[])

    return <>
        {CurrentNetWorthReportPages.map((clientAssetsPage, pageIndex) =>
            <PrintViewWrapper pageNumber={pageIndex} displayName={displayName} key={pageIndex}>
                <article className="asset-summary-page layout-split-left">
                    <BarChartSidebar
                        data={createAssetBarChartData(inEstateTotals, null)}
                        noDataText='No assets have been captured yet.'
                        displayName={displayName}
                        title={'Current Net Worth'}>
                        <AssetBarChartFooter
                            clientAssets={clientAssets}
                            inEstateAssetsTotals={inEstateTotals}
                            activeFormAsset={null}
                            investorGroup={investorGroup!}
                            title={'Current Net Worth'}
                        />
                    </BarChartSidebar>
                    <section>
                        <div data-testid="current-networth-content">

                            {clientAssetsPage && <div className="asset-summary-content">
                                {clientAssetsPage.assetsSummary && (hasAssets(clientAssetsPage.assetsSummary) || hasLiabilities(clientAssetsPage.assetsSummary)) &&
                                    <>
                                        <div className="section-header">
                                            <h3>
                                                {pageIndex === 0 && <div>Your current net worth
                                                    is <b>{formatCurrency(calculateCNWInEstateTotalValue(clientAssets, showDeathBenefit))}</b>:
                                                </div>
                                                }
                                                {!hasInEstateAssets(clientAssetsPage.assetsSummary) &&
                                                    <div className="condensed-subtitle">Begin by entering assets or
                                                        entities</div>}
                                            </h3>
                                        </div>
                                        <CurrentNetWorthAssetListReport
                                            isDetailed={isDetailed}
                                            assetsDataForPage={clientAssetsPage.assetsSummary}
                                            investorGroup={investorGroup!}
                                            showDeathBenefit={showDeathBenefit}
                                            allAssets={clientAssets}
                                        />
                                    </>
                                }
                                {
                                    hasOutOfEstateLegalEntities(clientAssetsPage) &&
                                    <>
                                        {
                                            pageIndex === firstLegalEntityPageIndex &&
                                            <div className="section-header">
                                                <h3>
                                                    Out of estate assets
                                                    total <b>{formatCurrency(calculateOutOfEstateTotalValue(clientAssets)).trim()}</b>:
                                                </h3>
                                            </div>
                                        }

                                        <OutOfEstateLegalEntityReportView
                                            allItems={allItems}
                                            legalEntitiesSummariesForPage={clientAssetsPage.legalEntitySummary!}
                                            allLegalEntityTypeSummaries={legalEntityTypeSummaries}
                                            accordionIdForOutOfEstate={isDetailed ? "CurrentNetWorthOutOfEstateAccordionReport" : "CurrentNetWorthOutOfEstateCollapsedReport"}

                                        />
                                    </>
                                }
                                {
                                    hasOutOfEstateNonClientSummaries(clientAssetsPage) &&
                                    <OutOfEstateNonClientSummariesView
                                        allItems={allItems}
                                        nonClientMemberSummariesForPage={clientAssetsPage.nonClientMemberSummary!}
                                        allNonClientMemberSummaries={nonClientMemberSummaries}
                                        accordionIdForView={isDetailed ? "CurrentNetWorthOutOfEstateNonClientAccordionReport" : "CurrentNetWorthOutOfEstateNonClientCollapsedReport"}
                                    />
                                }
                            </div>}
                        </div>
                    </section>
                </article>
            </PrintViewWrapper>
        )}
    </>

}