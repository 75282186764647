import React, {useState} from 'react'
import {Button} from "../components";
import {Logo, Switch} from "xps-react";

type AudioVideoPreviewProps = {
    onJoinNow: () => void
}

const AudioVideoPreview: React.FC<AudioVideoPreviewProps> = ({ onJoinNow }) => {
    const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);

    return <>
        <Logo
            alt="Single Line Anchor Logo"
            ariaLabel="Northern Trust Logo"
            color="black"
            logoType="single"
            width="200px"
        />
        <div className="audio-video-controls-container">
            <h3>Choose your Video and Audio options</h3>
            <div data-testid="video-preview" className="video-preview"></div>
            <div data-testid="video-controls" className="video-controls"></div>
            <div data-testid="audio-controls" className="audio-controls">
                <Button icon="only" iconName="volume_up" className="volume-up-icon" size="large"/>
                <div onClick={() => setIsAudioMuted(!isAudioMuted)}>
                    <Switch
                        ariaLabelledBy="audio-switch"
                        id="audio-switch"
                        isOn={!isAudioMuted}
                        offText=""
                        onText=""
                        stateTextPosition="right"
                    />
                </div>
            </div>
            <div className="audio-video-button-container">
                <Button kind="primary" className="join-now" onClick={() => onJoinNow()}>JOIN NOW</Button>
            </div>
        </div>
    </>
}

export default AudioVideoPreview;