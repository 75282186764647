import {
    emptyLifestyleGoalForm,
    LifestyleSpendingGoal,
    LifestyleSpendingGoalResponse
} from "./models/LifestyleSpendingGoal";
import {PlanningPeriodType} from "../ClientManagement/models/InvestorGroupType";

export const parseLifestyleGoalResponse = (
    response: LifestyleSpendingGoalResponse | undefined,
    planningPeriod: PlanningPeriodType
): LifestyleSpendingGoal => {
    if (response) {
        return response;
    }
    return emptyLifestyleGoalForm(planningPeriod);
}