import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {QuickSlides} from "./QuickSlides";
import ConnectionFailed from "../ErrorPage/ConnectionFailed";
import {useHistory} from "react-router-dom";
import {useAppDispatch} from "../../store/hooks";
import {clearDocumentInfo, setDocumentInfo} from "../../Resources/resourcesSlice";
import {Button} from "../Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type QuickSlidesPDFProps = {
    pdf: string,
    compId: string,
    isOpen: boolean,
    parentElement?: string,
    redirectUrl?: string,
    currPage?: number,
    getDocument?: () => void,
    setIsPageLoading?: (isPageLoading: boolean) => void
}

export const QuickSlidesPDF: React.FC<QuickSlidesPDFProps> = ({
                                                                  pdf,
                                                                  compId,
                                                                  isOpen,
                                                                  parentElement = '[data-testid="scrollable-container"]',
                                                                  redirectUrl = '',
                                                                  currPage = 1,
                                                                  getDocument,
                                                                  setIsPageLoading
                                                              }) => {

    const [failed, setFailed] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(currPage);
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const history = useHistory();
    const dispatch = useAppDispatch();

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        if (setIsPageLoading) {
            setIsPageLoading(false);
        }
        setNumberOfPages(numPages);
    };

    useEffect(() => {
        if (numberOfPages > 1) {
            dispatch(setDocumentInfo({
                currentPage: currPage,
                pdfUrl: pdf,
                redirectUrl
            }));
        }
        return () => {
            dispatch(clearDocumentInfo());
        }
    }, [currPage, pdf, numberOfPages]);

    useEffect(() => {
        setFailed(!pdf);
    }, [pdf]);

    useEffect(() => {
        setCurrentPage(currPage);
    }, [currPage])

    const refreshPage = () => {
        if (!pdf && getDocument) {
            getDocument();
        } else {
            setFailed(false);
        }
    }

    const handleLoadingFailed = () => {
        setFailed(true);
        dispatch(clearDocumentInfo());
    }

    const handlePreviousPage = () => {
        const activePage = Math.max(currentPage - 1, 1);
        if (redirectUrl) {
            history.push(`${redirectUrl}${activePage}`);
        } else {
            setCurrentPage(activePage);
        }
    }

    const handleNextPage = () => {
        const activePage = Math.min(currentPage + 1, numberOfPages);
        if (redirectUrl) {
            history.push(`${redirectUrl}${activePage}`);
        } else {
            setCurrentPage(activePage);
        }
    }

    if (failed) {
        return <ConnectionFailed onRefresh={refreshPage}/>
    }

    return (
        <QuickSlides
            id={compId}
            isOpen={isOpen}
            parentElement={parentElement}
            footer={numberOfPages > 1 && <div className="display-flex align-items-end justify-content-end">
                <Button
                    className="marginright-12 page-btn"
                    onClick={handlePreviousPage}
                    icon="only"
                    iconName="chevron_left"
                    kind="primary"
                    size="large"
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <Button
                    className="marginright-12 page-btn"
                    onClick={handleNextPage}
                    kind="primary"
                    icon="only"
                    iconName="chevron_right"
                    size="large"
                    disabled={currentPage === numberOfPages}
                >
                    Next
                </Button>
            </div>}
        >
            <Document
                file={pdf}
                error={"PDF is missing"}
                onLoadError={handleLoadingFailed}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page height={window.innerHeight - (numberOfPages > 1 ? 164 : 64)} pageNumber={currentPage}/>
            </Document>
        </QuickSlides>
    );
};
