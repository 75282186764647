import React, {useState} from "react";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import NonLifestyleGoalForm from "../components/NonLifestyleGoalForm";
import GoalUtils from "../Summary/GoalUtils";
import NonlifestyleGoalLivePreview from "../NonLifestyleGoals/NonlifestyleGoalLivePreview";
import DataEntryFooter from "../../components/DataEntry/DataEntryFooter";
import {GoalType, NonLifestyleGoalType, PhilanthropicGoalsType} from "../models/GoalType";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {
    addPhilanthropicGoal,
    deleteNonLifestyleGoal,
    selectGoalModel,
    updateLifestyleSpendingGoal, updateNonLifestyleGoalReducer
} from "../controller/GoalsModelSlice";
import AddNewPhilanthropicGoalBarChartSideBar from "../BarChartSidebar/AddNewPhilanthropicGoalBarChartSideBar";
import {goalsApiClient} from "../GoalsApiClient";
import DeleteGoalModal from "../components/DeleteGoalModal";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import DiscardModal from "../../components/DiscardModal/DiscardModal";
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";

const AddPhilanthropicGoal = ({isEditing = false}: { isEditing?: boolean }) => {
    const history = useHistory();
    const {
        investorGroup,
        discountRateSelection,
        proposal,
        lifestyleSpendingGoal
    } = useAppSelector<GoalModelType>(selectGoalModel)
    const dispatch = useAppDispatch();
    let philanthropicGoalState:NonLifestyleGoalType;
    let lifestyleGoalState: LifestyleSpendingGoal = lifestyleSpendingGoal;
    const savedGoalInHistory:boolean = (history.location.state  && !!history.location.state.savedGoal);
    if(savedGoalInHistory){
        philanthropicGoalState = history.location.state.savedGoal;
    } else{
        philanthropicGoalState = GoalUtils.initializeNonLifestyleGoal(proposal.id, GoalType.PHILANTHROPIC, PhilanthropicGoalsType.ANNUAL_GIFT, investorGroup);
    }
    const [philanthropicGoal, replacePhilanthropicGoal] = useState<NonLifestyleGoalType>(philanthropicGoalState)
    const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
    const [lifestyleGoal, replaceLifestyleGoal] = useState<LifestyleSpendingGoal>(lifestyleGoalState)
    const portfolioReserveIsSet = proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null;

    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [originalGoalState] = useState(philanthropicGoalState);
    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        dispatch(updateNonLifestyleGoalReducer(originalGoalState));
        history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`, {forceNavigate: true});
    };

    const handleCancelButtonClick = () => {
        if (philanthropicGoal === philanthropicGoalState) {
            history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`, {forceNavigate: true});
        } else {
            setShowCancelModal(true);
        }
    }

    const handleSaveButtonClick = async () => {
        const savedGoal = await goalsApiClient.postNonLifestyleGoal(proposal.id, GoalUtils.toNonLifestyleGoalRequest(philanthropicGoal))
        dispatch(addPhilanthropicGoal({goal: savedGoal}))
        dispatch(updateLifestyleSpendingGoal(lifestyleGoal));
        history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`);
    }
    const handleOnDeleteClick = async () => {
        const response = await goalsApiClient.deleteNonLifestyleGoal(philanthropicGoal.id!);
        if (response.status === 204) {
            dispatch(deleteNonLifestyleGoal({goal: philanthropicGoal}))
            history.goBack()
        }
    }
    return (
        <>
            <div className="layout-split-sidebar">
                <DiscardModal
                    itemType={"Goal"}
                    isOpen={showCancelModal}
                    onClickKeepEditing={() => setShowCancelModal(false)}
                    onClickDiscardChanges={handleModalDiscardButton}
                    isEditing={isEditing}/>
                <HistoryBlockModal
                    when={
                        JSON.stringify(originalGoalState) !== JSON.stringify(philanthropicGoalState)
                    }
                    itemType="goal"
                    onSave={handleSaveButtonClick}
                    onDiscard={handleCancelButtonClick}
                />

                <AddNewPhilanthropicGoalBarChartSideBar nonLifestyleGoalFromForm={philanthropicGoal} lifestyleSpendingGoalFromForm={lifestyleGoal}/>
                <div className='layout-data-entry-form add-new-goal-entry-form'>
                    <DataEntryHeader
                        className="add-philanthropic-goals_title"
                        title={`${savedGoalInHistory ? 'Edit ' : 'Add '}` + philanthropicGoal.userInputs.selectedGoalSubType}
                        primaryButtonText="Save"
                        onPrimaryButtonClick={handleSaveButtonClick}
                        secondaryButtonText="Cancel"
                        onSecondaryButtonClick={handleCancelButtonClick}
                    />
                    <div className="family-goal-container">
                        <NonLifestyleGoalForm
                            nonLifestyleGoal={philanthropicGoal}
                            replaceNonLifestyleGoal={replacePhilanthropicGoal}
                            typeDropdownItems={GoalUtils.getGoalSubTypeDropdownItems(Object.values(PhilanthropicGoalsType))}
                            discountRateSelection={discountRateSelection}
                            lifestyleGoal={lifestyleGoal}
                            replaceLifestyleGoal={replaceLifestyleGoal}
                            portfolioReserveIsSet={portfolioReserveIsSet}
                        />
                        <NonlifestyleGoalLivePreview nonLifestyleGoal={philanthropicGoal}/>
                    </div>

                    {
                        savedGoalInHistory &&
                        <DataEntryFooter
                            className="color-text--error"
                            hideDuplicate={true}
                            hideAddNew={true}
                            sticky={false}
                            onDelete={() => setShowDeleteGoalModal(true)}
                        />
                    }
                    <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                                     onCancel={() => setShowDeleteGoalModal(false)}
                                     onDelete={handleOnDeleteClick}
                                     goalType={GoalType.PHILANTHROPIC}
                                     description={philanthropicGoal.userInputs.description}/>
                </div>
            </div>
        </>
    )
}


export default AddPhilanthropicGoal;
