import FamilyGoalBarChartSideBar from "../BarChartSidebar/FamilyGoalBarChartSideBar";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import React from "react";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {ReleaseTogglesType} from "../../ReleaseToggles/ReleaseToggles";
import {BeneficiaryGoals as BeneficiaryGoalsType} from "../models/GoalModelType";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {NoGoalBeneficiaryDetail} from "./NoGoalBeneficiaryDetail";
import FamilyAssetsDetails from "./FamilyAssetsDetails";
import BeneficiaryGoals from "./BeneficiaryGoals";

export type FamilyGoalProps = {
    memberSelected: MemberType,
    memberName: string,
    onDone: () => void,
    releaseToggles?: ReleaseTogglesType,
    beneficiaryGoals?: BeneficiaryGoalsType,
    addNewGoalDropdownItems: JSX.Element[],
    investorGroup: InvestorGroupType,
    familyAssets: FamilyAssets,
    onChangeFamilyAssets: (familyAssets: FamilyAssets) => void,
    onSaveFamilyAssets: (familyAssets: FamilyAssets) => void,
    onApplyFamilyAssets: (goal: FamilyGoalType, assetAmountToApply: number) => void,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void
}

const FamilyGoal = ({
                        memberSelected,
                        memberName,
                        onDone,
                        releaseToggles = {},
                        beneficiaryGoals,
                        addNewGoalDropdownItems,
                        investorGroup,
                        familyAssets,
                        onChangeFamilyAssets,
                        onSaveFamilyAssets,
                        onEditGoal,
                        onApplyFamilyAssets
                    }: FamilyGoalProps) => {

    const handleChangeTotalAssets = (totalAssets: number) => {
        onChangeFamilyAssets({
            memberId: memberSelected.id,
            totalAssets: totalAssets,
        })
    };

    const memberHasSavedFamilyGoal = beneficiaryGoals && Object.keys(beneficiaryGoals).length > 0

    const familyGoalHash = beneficiaryGoals?.goals || {};

    const appliedAssets =  Object.keys(familyGoalHash)
        .map(key => familyGoalHash[key])
        .reduce((prev, familyGoal) => prev + (familyGoal.familyAssetsAmount || 0), 0);
    const availableAssets = familyAssets.totalAssets - appliedAssets;
    const hasFamilyAssets = availableAssets > 0;

    return (
        <>
            <div className="goals-summary-container layout-split-sidebar">
                <FamilyGoalBarChartSideBar memberSelected={memberSelected}/>
                <div className='layout-data-entry-form'>
                    <DataEntryHeader
                        className="add-family-goals_title"
                        title={`${memberName}'s Goals`}
                        primaryButtonText="Done"
                        onPrimaryButtonClick={onDone}
                        hideSecondaryButton={true}
                    />
                    <div>
                        {
                            memberHasSavedFamilyGoal ?
                                <BeneficiaryGoals
                                    memberSelected={memberSelected}
                                    dropdownItems={addNewGoalDropdownItems}
                                    beneficiaryGoals={beneficiaryGoals}
                                    investorGroup={investorGroup}
                                    onEditGoal={onEditGoal}
                                    onApplyFamilyAssets={onApplyFamilyAssets}
                                    hasFamilyAssets={hasFamilyAssets}
                                    familyAssets={familyAssets}
                                    availableAssets={availableAssets}
                                />
                                : <NoGoalBeneficiaryDetail
                                    memberSelected={memberSelected}
                                    dropdownItems={addNewGoalDropdownItems}/>
                        }
                        {
                            releaseToggles.enableFamilyAssetDetails &&
                            <FamilyAssetsDetails
                                memberSelected={memberSelected}
                                familyAssets={familyAssets}
                                appliedAssets={appliedAssets}
                                onChangeTotalAssets={handleChangeTotalAssets}
                                onBlur={onSaveFamilyAssets}
                            />
                        }
                    </div>
                </div>
            </div>
        </>

    )
};

export default FamilyGoal;