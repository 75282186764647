import React from "react";
import {GoalType} from "../models/GoalType";
import {Table} from "xps-react";
import {
    gePresentValueCell,
    getNameCell,
    getYearsOfFlowCell,
    getYearsUntilFlowCell
} from "../components/NonLifestyleGoalList";
import {NonLifestyleGoalRow} from "./Prioritization";
import {formatCurrency} from "../../utils/format";


type NonLifeStyleGoalsFBNITableProps = {
    goalsForDisplay: NonLifestyleGoalRow[];
    tableClassName: string | undefined,
    displayProgressBar?: boolean
}

const NonLifeStyleGoalsFBNITable = ({
                                        goalsForDisplay,
                                        tableClassName,
                                        displayProgressBar,
                                    }: NonLifeStyleGoalsFBNITableProps) => {

    return (

        <div data-testid={`non-lifestyle-goal-fbni-table`} className={tableClassName}>
            <Table
                resizable={false}
                showPagination={false}
                sortable={false}
                defaultPageSize={9999}
                data={
                    goalsForDisplay
                }
                columns={
                    [
                        {
                            minWidth: 5,
                            maxWidth: 12,
                        },
                        {
                            minWidth: 25,
                            maxWidth: 25,
                        },
                        {
                            accessor: 'name',
                            Cell: getNameCell(),
                            Header: 'NAME',
                        },
                        goalTypeColumn,
                        {
                            minWidth: 230,
                            maxWidth: 230,
                            show: displayProgressBar ? 1 : 0
                        },
                        {
                            accessor: 'annual-flow',
                            Cell: getAnnualFlowCellForFBNI(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'ANNUAL FLOW',
                            maxWidth: 128
                        },
                        {
                            accessor: 'years-until-flow',
                            Cell: getYearsUntilFlowCell(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'YEARS UNTIL FLOW',
                            maxWidth: 128
                        },
                        {
                            accessor: 'years-of-flow',
                            Cell: getYearsOfFlowCell(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'YEARS OF FLOW',
                            maxWidth: 128
                        },
                        {
                            accessor: 'present-value',
                            Cell: gePresentValueCell(),
                            headerClassName: 'display-flex justify-content-end',
                            Header: 'PRESENT VALUE',
                            maxWidth: 128
                        },
                        {
                            minWidth: 32,
                            maxWidth: 32,
                        }
                    ]
                }

            />
        </div>
    )
}

export const goalTypeColumn = {
    accessor: 'goalType',
    Cell: ({original}: { original: NonLifestyleGoalRow }) => {
        let goalTypeDescription: string;
        let beneficiaryName: string;
        switch (original.goalType) {
            case GoalType.DISCRETIONARY:
                goalTypeDescription = "Discretionary Goal";
                beneficiaryName = "";
                break;
            case GoalType.PHILANTHROPIC:
                goalTypeDescription = "Philanthropic Goal";
                beneficiaryName = "";
                break;
            case GoalType.FAMILY:
                goalTypeDescription = "Family Goal";
                beneficiaryName = original.beneficiaryName;
                break;
            default:
                goalTypeDescription = "";
                beneficiaryName = "";
                break;
        }
        return (
            <div className="display-flex flex-column justify-content-center height-100p">
            <span className="table-sublabel-12px">
                {goalTypeDescription}
            </span>
                {beneficiaryName &&
                    <span className="table-sublabel-12px">
                    {beneficiaryName}
                </span>
                }
            </div>
        );
    },
    Header: 'GOAL TYPE',
};

function getAnnualFlowCellForFBNI() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        const annualFlow = original.annualFlow ? formatCurrency(original.annualFlow) : ""
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{annualFlow}</span>
            </div>
        );
    };
}

export default NonLifeStyleGoalsFBNITable;



