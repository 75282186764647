import moment from 'moment';
import './cover-page-background.png';
import {Logo} from "xps-react";

type CoverPageProps = {
    displayName: string,
    creatorName: string,
    creatorEmail: string
}
const CoverPage = ({
    displayName,
    creatorName,
    creatorEmail
}: CoverPageProps) => {
    return (
        <div className="cover-page">
            <div className="cover-page__content">
                <Logo
                    alt="Stacked Centered Default Anchor Logo"
                    aria-label="Northern Trust Logo"
                    ariaLabel="Northern Trust Logo"
                    color="green"
                    logoType="centered"
                    width={183}
                />
                <div className='cover-page__content__title h1'>Goals Driven Wealth Management</div>

                <div className='cover-page__content__info'>
                    <span className='profile-display-name'>{displayName}</span>
                    <span className='report-date'>{moment().format('DD MMMM YYYY')}</span>
                </div>

                <div className='cover-page__content__footer'>
                    <span>Prepared by</span>
                    <span>{creatorName}</span>
                    <span>{creatorEmail}</span>
                    <span className='report-credits'>© 2023 Northern Trust Corporation</span>
                </div>
            </div>
            <div className="cover-page__border"/>
        </div>
    );
};

export default CoverPage;