import React from "react";
import {OwnershipDetailsFormData} from '../../models/Ownership';
import {MemberOwnershipRow} from "./MemberOwnershipRow";
import {MemberGroupMember} from "../../../ClientManagement/models/InvestorGroupType";

export type SoleNonClientMemberOwnershipTableProps = {
    selectedMember: MemberGroupMember;
    ownershipDetails: OwnershipDetailsFormData,
    onUpdate: (ownershipDetails: OwnershipDetailsFormData) => void,
    totalAssetValue?: number | null,
}

const SoleNonClientMemberOwnershipTable: React.FC<SoleNonClientMemberOwnershipTableProps> = ({
     selectedMember,
     onUpdate,
     ownershipDetails,
     totalAssetValue,
 }: SoleNonClientMemberOwnershipTableProps) => {


    return (
        <div className="ownership">
            <div className="h5">Non-Client Ownership</div>
            <div className="ownership-table">
                <div role="4-column-row">
                    <div>OWNER</div>
                    <div className="center-align">AGE</div>
                    <div className="center-align">% OWNED</div>
                    <div className="right-align">TOTAL OWNED</div>
                </div>
                <MemberOwnershipRow
                    showStaticPercentage
                    onChange={newMemberOwnership => {
                        console.dir(newMemberOwnership)
                        onUpdate({
                            ...ownershipDetails,
                            nonClientMemberOwnerships: [newMemberOwnership]
                        });
                    }}
                    totalAssetValue={totalAssetValue}
                    inputLabel="Non-Client Owner Percentage 1"
                    memberOwnerships={ownershipDetails.nonClientMemberOwnerships}
                    member={selectedMember}
                    showIrrevocableTrust={false}
                />
            </div>
        </div>
    )
};

export default SoleNonClientMemberOwnershipTable;
