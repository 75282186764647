import {LegalEntityFormData, LegalEntityOwnershipFormData, LegalEntityType} from "../../models/Ownership";
import {EditableDropdown} from "../../../components/Dropdown";
import SoleLegalEntityOwnershipTable from "./SoleLegalEntityOwnershipTable";
import {RedAsterisk} from "../../../components";
import {useState} from "react";
import {DEFAULT_SOLE_OWNERSHIP_PERCENTAGE} from "./SoleOwnership";

type LegalEntityOwnerDropdownProps = {
    onChange: (legalEntityOwnership: LegalEntityOwnershipFormData) => void,
    totalAssetValue?: number | null,
    legalEntities: LegalEntityFormData[],
    legalEntityType: LegalEntityType
    legalEntityOwnerships: LegalEntityOwnershipFormData[],
    onAddLegalEntity: (ownerEntity: LegalEntityFormData) => void,
}
export const LegalEntityOwnerDropdown = ({
                                             onChange,
                                             legalEntities,
                                             legalEntityType,
                                             legalEntityOwnerships,
                                             onAddLegalEntity,
                                             totalAssetValue
                                         }: LegalEntityOwnerDropdownProps) => {
    const [showNameEmptyError, setShowNameEmptyError] = useState(false);

    const currentLegalEntityOwnership = legalEntityOwnerships[0];

    const handleOnAddLegalEntity = (legalEntityName: string) => {
        onAddLegalEntity({name: legalEntityName, type: legalEntityType});
    };

    const handleOnChangeLegalEntityName = (newLegalEntityName: string) => {
        const existingType = legalEntities.find(legalEntity => {
            return legalEntity.name === newLegalEntityName;
        })?.type;

        const typeToUse = existingType ?? legalEntityType;
        const newLegalEntityOwnership = createDefaultLegalEntityOwnership(newLegalEntityName, typeToUse);
        onChange(newLegalEntityOwnership);
    };

    const onChangeOutOfEstateReport = (checked: boolean) => {
        const updatedLegalEntityOwnership = {...currentLegalEntityOwnership, showWithinOutOfEstateReport: checked}
        onChange(updatedLegalEntityOwnership);
    };

    return (
        <>
            <div className="layout-data-entry-form__field">
                <label htmlFor="entity-DropdownField">
                    <b>Entity<RedAsterisk/></b>
                </label>
                <EditableDropdown label="entity"
                                  value={currentLegalEntityOwnership?.name}
                                  items={legalEntities.map(owner => owner.name)}
                                  onAdd={handleOnAddLegalEntity}
                                  onChange={handleOnChangeLegalEntityName}
                                  onBlur={() => {
                                      const isNameEmpty = !currentLegalEntityOwnership?.name;
                                      setShowNameEmptyError(isNameEmpty);
                                  }}
                                  error={showNameEmptyError ? "This field is required" : undefined}
                />
            </div>
            {currentLegalEntityOwnership?.name && <SoleLegalEntityOwnershipTable
                name={currentLegalEntityOwnership.name}
                isOutOfEstateReport={currentLegalEntityOwnership?.showWithinOutOfEstateReport}
                totalOwned={totalAssetValue}
                onChangeOutOfEstateReport={(checked) => onChangeOutOfEstateReport(checked)}/>}
        </>
    );
};

function createDefaultLegalEntityOwnership(name: string, type?: LegalEntityType): LegalEntityOwnershipFormData {
    return {
        legalEntityId: undefined,
        name: name,
        type: type,
        percentage: DEFAULT_SOLE_OWNERSHIP_PERCENTAGE,
        showWithinOutOfEstateReport: true
    };
}
