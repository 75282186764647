import {AssetsSummary} from "../../../Assets/models/Assets";
import {COLOR_LIABILITIES} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../Assets/../components";
import React from "react";
import {formatCurrency} from "../../../utils/format";
import {PersonalLiabilitySummary} from "../../../Assets/models/PersonalLiability";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../../Assets/AssetSummary/common/AssetAccordionContentHeader";
import {NO_OP} from "../../../constants/common";
import {TableCell} from "../../../components/Table/TableCell";
import {PersonalLiabilityRow} from "../../../Assets/AssetSummary/InEstate/PersonalLiabilityRow";

export interface PersonalLiabilitiesProps {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    caption: string,
    allAssetsCNW: AssetsSummary
}

const PersonalLiabilitiesCNWReport = ({assetsDataForPage, investorGroup, caption, allAssetsCNW }: PersonalLiabilitiesProps) => {

    function getFormattedTotalPresentValue(totalPresentValue: number) {
        return totalPresentValue !== undefined ? `-${formatCurrency(allAssetsCNW.totalLiabilitiesValue)}` : '';
    }

    return (
        <>
            {assetsDataForPage.personalLiabilities.length > 0 &&
                <AccordionItemWithActionMenu
                    uuid="PersonalLiabilitiesCNWReport"
                    accentColor={COLOR_LIABILITIES}
                    HeaderComponent={({expanded}) =>
                        <AssetAccordionHeader
                            caption={caption}
                            expanded={expanded}
                            title="Personal Liabilities"
                            formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(allAssetsCNW.totalLiabilitiesValue) : undefined}
                            formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? "--" : undefined}
                            formattedJointTotalPresentValue={investorGroup.partnerMember ? "--" : undefined}
                            formattedTotalPresentValue={getFormattedTotalPresentValue(allAssetsCNW.totalLiabilitiesValue)}
                            gridClassName={"current-net-worth-grid"}/>
                    }>

                    <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                         aria-label="personal-liabilities-table">
                        <AssetAccordionContentHeader investorGroup={investorGroup}/>

                        {assetsDataForPage.personalLiabilities.map((personalLiability: PersonalLiabilitySummary) => {
                            return <PersonalLiabilityRow personalLiability={personalLiability}
                                                         actionsDisabled={false}
                                                         onClickEdit={NO_OP}
                                                         onClickDelete={NO_OP}
                                                         key={personalLiability.id}
                                                         gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                         renderPersonalLiabilityDetails={(liability: PersonalLiabilitySummary) => {
                                                             return <>
                                                                 <TableCell text={liability.description}
                                                                            className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}/>
                                                                 {investorGroup.partnerMember && <>
                                                                     <TableCell
                                                                         text={formatCurrency(-1 * liability.loanBalance)}
                                                                         className="textalign-right"/>
                                                                     <TableCell
                                                                         text="--"
                                                                         className="textalign-right"/>
                                                                     <TableCell
                                                                         text="--"
                                                                         className="textalign-right"/>
                                                                 </>
                                                                 }
                                                             </>
                                                         }}/>;
                        })}
                    </div>

                </AccordionItemWithActionMenu>}
        </>
    );
}

export default PersonalLiabilitiesCNWReport;
