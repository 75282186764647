import {useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {Accordion, AccordionItem,} from "../../components";
import {formatCurrency} from "../../utils/format";
import React from "react";
import FamilyGoalUtils from '../Summary/FamilyGoalUtils'
import FamilyGoalsSummaryItems from "./FamilyGoalsSummaryItems";
import {GoalType} from "../models/GoalType";
import NonLifestyleGoalListHeader from "./NonLifestyleGoalListHeader";


export function FamilySummaryColumnHeader() {
    return <div className="ReactTable table" style={{height: "auto"}}
                data-testid="static-family-goal-summary-tab-header">
        <div className="rt-table" role="table">
            <div className="rt-thead -header">
                <div className="rt-tr table__row--large" role="row">
                    <div role="cell" className="rt-th"
                         style={{flex: "30 0 auto", width: "30px", maxWidth: "30px"}}>
                        <div className=""></div>
                    </div>
                    <div role="cell" className="rt-th"
                         style={{flex: "100 0 auto", width: "100px"}}>
                        <div className="">Name</div>
                    </div>
                    <div role="cell" className="rt-th display-flex justify-content-end"
                         style={{
                             flex: "100 0 auto",
                             width: "100px",
                             maxWidth: "128px"
                         }}>
                        <div className="">My Responsibility</div>
                    </div>
                    <div role="cell" className="rt-th"
                         style={{flex: "30 0 auto", width: "30px", maxWidth: "30px"}}>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

const SummaryFamilyGoalList = () => {
    const {
        familyGoals,
        discretionaryGoals,
        philanthropicGoals,
        investorGroup
    } = useAppSelector<GoalModelType>(selectGoalModel);

    return (
        <>
            <section>
                <div className="accordion-tables" data-testid={`${GoalType.FAMILY}-goal-list`}>
                    <Accordion
                        accordionId="SummaryFamilyGoalListAccordion"
                        allowMultipleExpanded
                        allowZeroExpanded
                    >
                        <div>
                            {(discretionaryGoals.length == 0 && philanthropicGoals.length == 0) &&
                                <NonLifestyleGoalListHeader/>}
                            <AccordionItem
                                uuid="FamilyGoal"
                                accentColor="#FFC24B"
                                primaryText="Family Goal"
                                rightAlignedContent={
                                    <div className="display-flex align-items-center"
                                         data-testid="family-goal-accordion-item">
                                            <span className="font-lg paddingright-xl">
                                                {formatCurrency(FamilyGoalUtils.getCumulativeTotalPresentValue(familyGoals))}
                                            </span>
                                    </div>
                                }
                            >
                                <FamilySummaryColumnHeader/>
                                <FamilyGoalsSummaryItems familyGoals={familyGoals} investorGroup={investorGroup}/>
                            </AccordionItem>
                        </div>
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default SummaryFamilyGoalList;
