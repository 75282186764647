import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store';
import {Meeting, MeetingType, Presenter} from "./Meeting";
import {MeetingControlRequest} from "./Relay/types/MeetingControlRequest";
import {AudienceType} from "./Relay/types/Audience";
import {AzureMember} from "@fluidframework/azure-client";

export type MeetingModalVisibility = {
    meetingInfo: boolean;
};

export type MeetingState = {
    activeMeeting: Meeting;
    showMeetingControls: boolean;
    meetingControlRequest?: MeetingControlRequest;
    audience: AudienceType;
    participatingInMeeting: boolean;
    modalVisibility: MeetingModalVisibility;
}

export const initialMeetingState: MeetingState = {
    activeMeeting: {
        id: '',
        relayContainerId: '',
        fluidRelayDocumentId: '',
        title: '',
        type: MeetingType.TRAINING_MEETING,
        profileId: '',
        profileIdToPresent: '',
        createdByName: '',
        onlineMeetingId: '',
        onlineMeetingCode: '',
        onlineMeetingJoinUrl: '',
        remoteEnabled: true
    },
    showMeetingControls: false,
    meetingControlRequest: undefined,
    audience: {
        size: 0, members: []
    },
    participatingInMeeting: false,
    modalVisibility: {
        meetingInfo: false,
    },
}

// The meetingSlice represents the meeting that the spa is currently viewing
export const meetingSlice = createSlice({
    initialState: initialMeetingState,
    name: 'meeting',
    reducers: {
        setActiveMeeting: (state, action: PayloadAction<Meeting>) => {
            state.activeMeeting = action.payload;
        },
        resetMeeting: () => ({...initialMeetingState}),
        setShowMeetingControls: (state, action: PayloadAction<boolean>) => {
            state.showMeetingControls = action.payload;
        },
        setPresenter: (state, action: PayloadAction<Presenter>) => {
            state.activeMeeting = {
                ...state.activeMeeting,
                presenter: action.payload.presenter,
                presenterName: action.payload.presenterName
            };
        },
        setMeetingControlRequest: (state, action: PayloadAction<MeetingControlRequest>) => {
            state.meetingControlRequest = action.payload;
        },
        resetMeetingControlRequest: (state) => {
            state.meetingControlRequest = undefined;
        },
        setAudienceSize: (state, action: PayloadAction<number>) => {
            state.audience = {...state.audience, size: action.payload};
        },
        setAudienceMembers: (state, action: PayloadAction<AzureMember[]>) => {
            state.audience = {...state.audience, members: action.payload};
        },
        resetAudience: (state) => {
            state.audience = {...initialMeetingState.audience}
        },
        setParticipatingInMeeting: (state, action: PayloadAction<boolean>) => {
            state.participatingInMeeting = action.payload;
        },
        setMeetingModalVisibility: (state, action: PayloadAction<MeetingModalVisibility>) => {
            state.modalVisibility = action.payload;
        },
    }
});

export const {
    setActiveMeeting,
    resetMeeting,
    setShowMeetingControls,
    setPresenter,
    setMeetingControlRequest,
    resetMeetingControlRequest,
    setAudienceSize,
    setAudienceMembers,
    resetAudience,
    setParticipatingInMeeting,
    setMeetingModalVisibility,
} = meetingSlice.actions;

export const selectActiveMeeting = (state: RootState) => state.client.meeting?.activeMeeting;
export const selectShowMeetingControls = (state: RootState) => state.client.meeting?.showMeetingControls;
export const selectMeetingControlRequest = (state: RootState) => state.client.meeting?.meetingControlRequest;
export const selectAudienceSize = (state: RootState) => state.client.meeting?.audience.size;
export const selectAudienceMembers = (state: RootState) => state.client.meeting?.audience.members;
export const selectParticipatingInMeeting = (state: RootState) => state.client.meeting?.participatingInMeeting;
export const selectMeetingModalVisibility = (state: RootState) => state.client.meeting?.modalVisibility;

export default meetingSlice.reducer;
