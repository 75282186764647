import React from "react";
import {OwnershipDetailsFormData} from "../../models/Ownership";
import {MemberOwnershipRow} from "./MemberOwnershipRow";
import {MemberGroupMember} from "../../../ClientManagement/models/InvestorGroupType";

export type ClientMemberOwnershipTableProps = {
    primaryMember: MemberGroupMember;
    partnerMember?: MemberGroupMember,
    ownershipDetails: OwnershipDetailsFormData,
    onUpdate: (ownershipDetails: OwnershipDetailsFormData) => void,
    totalAssetValue?: number | null,
}
const ClientMemberOwnershipTable: React.FC<ClientMemberOwnershipTableProps> = ({
                                                                                   primaryMember,
                                                                                   partnerMember,
                                                                                   onUpdate,
                                                                                   ownershipDetails,
                                                                                   totalAssetValue,
                                                                               }: ClientMemberOwnershipTableProps) => {

    const memberGroupMembers = [primaryMember];
    if (partnerMember) {
        memberGroupMembers.push(partnerMember);
    }

    const showStaticPercentage = ownershipDetails.ownershipCategory === "Sole" || ownershipDetails.ownershipCategory === "JTWROS (Joint Tenants with Rights of Survivorship)"
                              || ownershipDetails.ownershipCategory === "Community Property";

    return (
        <div className="ownership">
            <div className="h5">Client Ownership</div>
            <div className="ownership-table">
                <div role="5-column-row">
                    <div>OWNER</div>
                    <div className="center-align">AGE</div>
                    <div className="center-align">% OWNED</div>
                    <div className="center-align">REV TRUST</div>
                    <div className="right-align">TOTAL OWNED</div>
                </div>

                {
                    memberGroupMembers.map((member: MemberGroupMember, index) => {
                        return (
                            <MemberOwnershipRow
                                key={index}
                                showStaticPercentage={showStaticPercentage}
                                onChange={updatedClientOwnership => {
                                    const otherClientOwnerships = ownershipDetails.memberOwnerships.filter(co => co.memberId !== member.id);
                                    const updatedClientOwnerships = [
                                        ...otherClientOwnerships,
                                        updatedClientOwnership
                                    ];
                                    onUpdate({
                                        ...ownershipDetails,
                                        memberOwnerships: updatedClientOwnerships
                                    });
                                }}
                                totalAssetValue={totalAssetValue}
                                inputLabel={`Client Owner Percentage ${index + 1}`}
                                memberOwnerships={ownershipDetails.memberOwnerships}
                                member={member}
                                showIrrevocableTrust={true}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
};

export default ClientMemberOwnershipTable;
