type App_Conf_Data = {
    API_URL: string;
    APIM_URL: string;
    APP_URL: string;
    CLIENT_ID: string;
    SCOPES: string;
    INSIGHT: string;
    RELAY_ENDPOINT_URL: string;
    RELAY_TENANT_ID: string;
    RELAY_FUNCTION_URL: string;
    MEETING_PORTAL_AUTHENTICATOR_FN_URL: string;
    ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: string;
    AUTHORITY: string;
};

enum Authorities {
    AZURE_QA1 = "https://login.microsoftonline.com/2434528d-4270-4977-81dd-a6308c1761a3",
    // AZURE_QA1 = "https://login.microsoftonline.com/f247e029-3824-4e7d-b7f4-dae4a37f9bb6",
    DEFAULT = "https://login.microsoftonline.com/2434528d-4270-4977-81dd-a6308c1761a3"
}

export enum LocationURIs {
    AZURE_DEV = "gpi.dev.northerntrust.com",
    AZURE_QA = "gpi.qa.northerntrust.com",
    AZURE_QA1 = "gpi.qa1.dev.northerntrust.com",
    AZURE_UAT = "gpi.uat.northerntrust.com",
    AZURE_UATAZ = "gpi.uataz.northerntrust.com",
    AZURE_PROD = "gpi.northerntrust.com",
    AZURE_PROD_EU2 = "gpi.eu2.northerntrust.com",
    AZURE_APIM_DEV = "ambitious-flower-097227d10.1.azurestaticapps.net",
    AZURE_APIM_UAT = "happy-bush-0adb25110.1.azurestaticapps.net", // TODO
    AZURE_APIM_PROD = "gpi.northerntrust.com",  // TODO
    UAT2 = "gpi-spa-ui.apps.uat-2.pcf.ntrs.com",
    UAT = "gpi-spa-ui.apps.uat.pcf.ntrs.com",
    SYSTEM = "gpi-spa-ui.apps.system-1.pcf.ntrs.com",
    LOCAL = "localhost:8080",
    LOCAL_TEST = "localhost",
}

enum ClientIDs {
    PROD = "c7cf1e68-d9fd-454b-9a6d-79190953fb3a",
    PROD_EU2 = "c7cf1e68-d9fd-454b-9a6d-79190953fb3a",
    UAT = "15ee2361-cbe8-4cf7-a3c6-18ee0513aaf9",
    QA = "15ee2361-cbe8-4cf7-a3c6-18ee0513aaf9",
    QA1 = "6cc1ddca-2ff5-4bc6-88ae-249705014436",
    // QA1 = "3fff510c-5154-4ff8-93ee-e76a77246632",
    SYSTEM = "ecfc1937-ac34-4882-8e1d-86d3d790eb2e",
    LOCAL = "ecfc1937-ac34-4882-8e1d-86d3d790eb2e",
}

enum Scopes {
    PROD = "api://gpi.ntrs.com/GPI.Read openid profile OnlineMeetings.ReadWrite",
    PROD_EU2 = "api://gpi.ntrs.com/GPI.Read openid profile OnlineMeetings.ReadWrite",
    UAT = "api://gpiuat.ntrs.com/GPI.Read openid profile OnlineMeetings.ReadWrite",
    QA = "api://gpiuat.ntrs.com/GPI.Read openid profile OnlineMeetings.ReadWrite",
    QA1 = "api://6cc1ddca-2ff5-4bc6-88ae-249705014436/Gpi.Read openid profile OnlineMeetings.ReadWrite",
    // QA1 = "api://3fff510c-5154-4ff8-93ee-e76a77246632/gpi.Read openid profile OnlineMeetings.ReadWrite",
    SYSTEM = "api://gpidev.ntrs.com/GPI.Read openid profile OnlineMeetings.ReadWrite",
    LOCAL = "api://gpidev.ntrs.com/GPI.Read openid profile OnlineMeetings.ReadWrite",
}

enum InstrumentationKey {
    UAT = "ebe3dcb5-eedd-447f-a213-ac2e513704d6",
    SYSTEM = "a06cd072-96cd-4c52-8c7e-f3d14025081d",
    QA = "e471b89e-2583-4667-89c4-f73012786aba",
    QA1 = "a06cd072-96cd-4c52-8c7e-f3d14025081d",
    PROD = "4c620de8-b3a7-47f3-bd8f-4f55e439666f",
    PROD_EU2 = "4c620de8-b3a7-47f3-bd8f-4f55e439666f",
    LOCAL = ""
}

const appUrl = window.location.protocol + '//' + window.location.host;
const apiUrl = appUrl.replace('gpi-spa-ui', "gpi-api-gateway");
const apimUrl = `${apiUrl}/apim`;

let app_conf: App_Conf_Data = {
    API_URL: apiUrl,
    APIM_URL: apimUrl,
    APP_URL: appUrl,
    CLIENT_ID: ClientIDs.LOCAL,
    SCOPES: Scopes.LOCAL,
    INSIGHT: InstrumentationKey.LOCAL,
    RELAY_ENDPOINT_URL: 'https://us.fluidrelay.azure.com',
    RELAY_TENANT_ID: '3e294157-39b4-4c59-85e8-fe5b76bda5db',
    RELAY_FUNCTION_URL: 'https://gpi-fluid-token-provider-cus-d-funcapp.azurewebsites.net/api/FluidTokenProviderHttpTrigger?code=PUmXpCOIrELZ1PwC49rXeR7Javy0kyAPMS2OL9-LxKnqAzFusLsAIQ==',
    MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${apimUrl}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
    ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: 'https://gpi-fluid-token-provider-cus-d-funcapp.azurewebsites.net/api/ACSTeamsUserTokenProviderHttpTrigger',
    AUTHORITY: Authorities.DEFAULT,
}

switch (window.location.host) {
    case LocationURIs.AZURE_PROD:
        console.log('Configuring Azure PROD environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.PROD};
        app_conf = {...app_conf, APIM_URL: "https://gpi.northerntrust.com/apim"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`};
        app_conf = {
            ...app_conf,
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`
        };
        app_conf = {...app_conf, RELAY_TENANT_ID: '992bd068-357a-4af8-933a-d28d669284b9'};
        app_conf = {
            ...app_conf,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`
        };
        app_conf = {...app_conf, SCOPES: Scopes.PROD};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.PROD};
        break;
    case LocationURIs.AZURE_PROD_EU2:
        console.log('Configuring Azure PROD_EU2 environment')
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.PROD_EU2};
        app_conf = {...app_conf, APIM_URL: "https://gpi.eu2.northerntrust.com/apim"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`};
        app_conf = {
            ...app_conf,
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`
        };
        app_conf = {...app_conf, RELAY_TENANT_ID: '992bd068-357a-4af8-933a-d28d669284b9'};
        app_conf = {
            ...app_conf,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`
        };
        app_conf = {...app_conf, SCOPES: Scopes.PROD_EU2};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.PROD_EU2};
        break;
    case LocationURIs.AZURE_QA1:
        console.log('Configuring Azure AKS SYSTEM environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.SYSTEM};
        app_conf = {...app_conf, APIM_URL: "https://gpi.qa1.dev.northerntrust.com/apim"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`};
        app_conf = {...app_conf, RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`};
        app_conf = {
            ...app_conf,
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`
        };
        app_conf = {...app_conf, RELAY_TENANT_ID: '3e294157-39b4-4c59-85e8-fe5b76bda5db'};
        app_conf = {
            ...app_conf,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`
        };
        app_conf = {...app_conf, SCOPES: Scopes.SYSTEM};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.SYSTEM};
        app_conf = {...app_conf, AUTHORITY: Authorities.DEFAULT};
        break;
    case LocationURIs.AZURE_DEV:
        console.log('Configuring Azure DEV environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.SYSTEM};
        app_conf = {...app_conf, APIM_URL: "https://gpi.dev.northerntrust.com/apim"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`};
        app_conf = {
            ...app_conf,
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`
        };
        app_conf = {...app_conf, RELAY_TENANT_ID: '3e294157-39b4-4c59-85e8-fe5b76bda5db'};
        app_conf = {
            ...app_conf,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`
        };
        app_conf = {...app_conf, SCOPES: Scopes.SYSTEM};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.SYSTEM};
        break;
    case LocationURIs.AZURE_QA:
        console.log('Configuring Azure QA environment')
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.QA};
        app_conf = {...app_conf, APIM_URL: "https://gpi.qa.northerntrust.com/apim"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`};
        app_conf = {
            ...app_conf,
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`
        };
        app_conf = {...app_conf, RELAY_TENANT_ID: '077676ed-4ce9-4989-8429-6b362c522e77'};
        app_conf = {
            ...app_conf,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`
        };
        app_conf = {...app_conf, SCOPES: Scopes.QA};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.QA};
        break;
    case LocationURIs.AZURE_UAT:
        console.log('Configuring Azure UAT environment')
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.UAT};
        app_conf = {...app_conf, APIM_URL: "https://gpi.uat.northerntrust.com/apim"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`};
        app_conf = {
            ...app_conf,
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`
        };
        app_conf = {...app_conf, RELAY_TENANT_ID: '41b0a9b0-dc0c-44dd-a125-ec9503941579'};
        app_conf = {
            ...app_conf,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`
        };
        app_conf = {...app_conf, SCOPES: Scopes.UAT};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.UAT};
        break;
    case LocationURIs.AZURE_UATAZ:
        console.log('Configuring Azure UATAZ environment')
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.UAT};
        app_conf = {...app_conf, APIM_URL: "https://gpi.uataz.northerntrust.com/apim"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`};
        app_conf = {...app_conf, RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`};
        app_conf = {
            ...app_conf,
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`
        };
        app_conf = {...app_conf, RELAY_TENANT_ID: '41b0a9b0-dc0c-44dd-a125-ec9503941579'};
        app_conf = {
            ...app_conf,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`
        };
        app_conf = {...app_conf, SCOPES: Scopes.UAT};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.UAT};
        break;
    case LocationURIs.AZURE_APIM_PROD:
        console.log('Configuring Azure PROD non akamai environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.PROD};
        app_conf = {...app_conf, APIM_URL: "https://gpi-cus-p-001.gpi-dev-apim.northerntrust.com"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, SCOPES: Scopes.PROD};
        break;
    case LocationURIs.AZURE_APIM_DEV:
        console.log('Configuring Azure DEV non akamai environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.SYSTEM};
        app_conf = {...app_conf, APIM_URL: "https://gpi-cus-d-001.gpi-dev-apim.northerntrust.com"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, SCOPES: Scopes.SYSTEM};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.SYSTEM};
        break;
    case LocationURIs.AZURE_APIM_UAT:
        console.log('Configuring Azure UAT non akamai environment')
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.UAT};
        app_conf = {...app_conf, APIM_URL: "https://gpi-cus-u-001.gpi-dev-apim.northerntrust.com"};
        app_conf = {...app_conf, API_URL: `${app_conf.APIM_URL}/gateway/v1/api`};
        app_conf = {...app_conf, SCOPES: Scopes.UAT};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.UAT};
        break;
    case LocationURIs.UAT2:
    // fall-though
    case LocationURIs.UAT:
        console.log('Configuring UAT environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.UAT};
        app_conf = {...app_conf, SCOPES: Scopes.UAT};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.UAT};
        break;
    case LocationURIs.SYSTEM:
        console.log('Configuring SYSTEM environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.SYSTEM};
        app_conf = {...app_conf, SCOPES: Scopes.SYSTEM};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.SYSTEM};
        break;
    case LocationURIs.LOCAL:
        console.log('Configuring local environment');
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.LOCAL};
        app_conf = {...app_conf, API_URL: 'http://localhost:8081'};
        app_conf = {...app_conf, SCOPES: Scopes.LOCAL};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.LOCAL};
        break;
    default:
        app_conf = {...app_conf, CLIENT_ID: ClientIDs.LOCAL};
        app_conf = {...app_conf, API_URL: 'http://localhost:8081'};
        app_conf = {...app_conf, SCOPES: Scopes.LOCAL};
        app_conf = {...app_conf, INSIGHT: InstrumentationKey.LOCAL};
        break;
}

if (process.env.REACT_APP_API_URL !== undefined) {
    console.log('Reconfiguring API_URL to:');
    console.log(process.env.REACT_APP_API_URL);
    app_conf = {...app_conf, API_URL: process.env.REACT_APP_API_URL};
}

export const isLocalGateway = () => {
    return app_conf.API_URL === 'http://localhost:8081';
};

export const App_Conf = app_conf;
