import {post} from "../core/api";
import {ConditionResultResponse} from "./ConditionResultResponse";

const extractBody = (res: Response) => res.json();

export const getConditions = (conditionResultRequest: string[]): Promise<ConditionResultResponse[]> => {
    return post('/monitoring/conditions', conditionResultRequest)
        .then(extractBody);
}

export const monitoringApiClient = {
    getConditions: getConditions,
}