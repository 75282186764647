import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ReleaseTogglesType} from './ReleaseToggles';
import type {RootState} from 'src/store/store';

const initialState: ReleaseTogglesType = {
    enableGoalsPrioritization: true,
    enableHolisticAdvice: false,
    enableLifestyleGoalFunding: false,
    enableLifeInsuranceAsset: false,
    enableEquityCompensationAsset: false,
    enableCurrentNetWorth: false,
    enableReviseAssetAllocation: false,
    enableToonimo: false,
    enableProfileAssistant: false,
    enableMultipleSpendingPeriods: false,
    enableInflowReserve: true,
    enableQuickSlides: false,
    enableActivePortfolioReserve: false,
    enableProspectProfiles: false,
    enableWealthTransferMenu: false,
    enableFutureValueOfExcessAssets: false,
    enableMeetingManagement: false,
    enableMeetingManagementRemote: false,
    enableClientDocument: false,
    enableTrustInflows: false,
    enableProposalApproval: false,
    enableAssetPurchase: false
};

export const releaseTogglesSlice = createSlice({
    name: 'releaseToggles',
    initialState,
    reducers: {
        setReleaseToggles: (_state, action: PayloadAction<ReleaseTogglesType>) => action.payload,
    }
});

export const {setReleaseToggles} = releaseTogglesSlice.actions;
export const selectReleaseToggles = (state: RootState) => state.releaseToggles;
export default releaseTogglesSlice.reducer;