import React, {ChangeEvent, Fragment, MouseEventHandler} from "react";
import {Button} from "../index";
import {YearsInput} from "./YearsInput"; // todo: use a different YearsInput component instead

export interface ClickableYearsInputProps {
    name: string
    onClick?: MouseEventHandler
    onUpArrowClick: MouseEventHandler
    onDownArrowClick: MouseEventHandler
    onBlur: (e: ChangeEvent<HTMLInputElement>) => void
    onChangeValue: (e: ChangeEvent<HTMLInputElement>, value: number) => void
    inputClassName: string
    inputStyle: React.CSSProperties
    upArrowClassName: string
    downArrowClassName: string
    error?: string
    value: number | undefined
    disabledAndReadOnly: boolean
    iconState: IconState,
    inlineLabel?: string,
    inlineLabelAlign?: "left" | "right"
}

export enum IconState {
    PENCIL = 'edit',
    ARROWS = 'arrows',
    NONE = 'none'
}

const ClickableYearsInput = (props: ClickableYearsInputProps) => {
    const {
        name,
        onClick,
        onUpArrowClick,
        onDownArrowClick,
        onBlur,
        onChangeValue,
        inputStyle,
        error,
        disabledAndReadOnly,
        upArrowClassName,
        downArrowClassName,
        inputClassName,
        iconState,
        value,
        inlineLabel,
        inlineLabelAlign
    } = props

    const displayEditIcons = () => {
        if (iconState === IconState.PENCIL) {
            return <i data-testid="pencil_icon" className="dds-icons icon  input__icon input__icon input__icon--large">edit</i>;
        } else if (iconState === IconState.ARROWS) {
            return <>
                <Button
                    data-testid="arrow_up_icon"
                    className={upArrowClassName}
                    icon="only"
                    iconName="arrow_up"
                    includeRef={false}
                    kind="borderless"
                    onClick={onUpArrowClick}
                    size="small"
                    tabIndex={-1}
                    type="button"
                    padding={false}
                >
                </Button>
                <Button
                    data-testid="arrow_down_icon"
                    className={downArrowClassName}
                    icon="only"
                    iconName="arrow_down"
                    includeRef={false}
                    kind="borderless"
                    onClick={onDownArrowClick}
                    size="small"
                    tabIndex={-1}
                    type="button"
                    padding={false}
                >
                </Button>
            </>
        } else {
            return <Fragment/>
        }
    }

    return <div className="years-input-container-with-icons">
        <YearsInput
            data-testid={name}
            aria-label={name}
            size="large"
            className={inputClassName}
            name={name}
            style={inputStyle}
            onClick={onClick}
            onBlur={onBlur}
            readOnly={disabledAndReadOnly}
            disabled={disabledAndReadOnly}
            value={(value || value === 0) ? value : null}
            onChangeValue={onChangeValue}
            error={error}
            inlineLabel={inlineLabel}
            inlineLabelAlign={inlineLabelAlign}
        />
        {displayEditIcons()}
    </div>
}

export default ClickableYearsInput