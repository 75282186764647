import {useAppSelector} from "../../store/hooks";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {Label, Switch} from "xps-react";
import React from "react";

export enum LabelPosition {
    Top = "Top",
    Left = "Left",
    None = "None"
}

export type ActivatePortfolioReserveSwitchProps = {
    profileOrProposalId: string,
    isActivePortfolioReserve: boolean,
    className?: string;
    labelPosition: LabelPosition;
    postToggleCallback?: (isActivePortfolioReserve: boolean) => void
};

export const ActivatePortfolioReserveSwitch = ({
                                                   className,
                                                   labelPosition,
                                                   profileOrProposalId,
                                                   isActivePortfolioReserve,
                                                   postToggleCallback,
                                               }: ActivatePortfolioReserveSwitchProps) => {
    const releaseToggles = useAppSelector(selectReleaseToggles);

    if (releaseToggles?.enableActivePortfolioReserve !== true) {
        return null;
    }

    const toggleActivatePortfolioReserve = async () => {
        const newIsActivePortfolioReserve = !isActivePortfolioReserve;
        await clientManagementApiClient.putPortfolioReserve(
            profileOrProposalId,
            newIsActivePortfolioReserve
        );
        if (postToggleCallback) {
            postToggleCallback(newIsActivePortfolioReserve);
        }
    }

    return <div className={className}>
        {labelPosition !== LabelPosition.None &&
            <Label
                style={{fontSize: "13px", paddingRight: "15px", paddingTop: '15px'}}
                id="switch_01_Label"
                name="switch_01"
                text="Portfolio Reserve"
            />}
        <span
            data-testid="portfolio-reserve-toggle-slider"
            onClick={() => toggleActivatePortfolioReserve()}
        >
                <div
                    className={switchDivClass(labelPosition)}
                    style={{paddingTop: switchPaddingTop(labelPosition)}}>
                <Switch
                    ariaLabelledBy="switch_01_Label"
                    id="switch_01"
                    isOn={isActivePortfolioReserve}
                    offText="OFF"
                    onText="ON"
                    stateTextPosition="right"
                />
                </div>
            </span>
    </div>
}

const switchDivClass = (labelPosition: LabelPosition) => labelPosition === LabelPosition.Left ? "float-right" : "";

const switchPaddingTop = (labelPosition: LabelPosition) => labelPosition === LabelPosition.None ? "0px" : "15px";