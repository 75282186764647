import React from "react";
import {soleIndividualOwnerType, SoleOwnerType, soleOwnerTypes} from "../../models/Ownership";
import {Dropdown, DropdownItem} from "../../../components/Dropdown";
import {DropdownOnChangeData} from "src/components/Dropdown/Dropdown";

type SoleOwnerTypeDropdownProps = {
    value: SoleOwnerType,
    onChangeSoleOwnerType: (data: DropdownOnChangeData<SoleOwnerType>) => void,
    disableOutOfEstate?: boolean
};

const mapOwnerTypesToDropdownItem = (ownerTypes: readonly string[]) => ownerTypes.map((ownerType) => {
    return <DropdownItem
        itemText={ownerType}
        key={ownerType}
        value={ownerType}
    />
});

const soleOwnerEntityTypeItems = mapOwnerTypesToDropdownItem(soleOwnerTypes);
const soleIndividualOwnerEntityTypeItem = mapOwnerTypesToDropdownItem(soleIndividualOwnerType);

export function SoleOwnerTypeDropdown({value, onChangeSoleOwnerType, disableOutOfEstate = false}: SoleOwnerTypeDropdownProps) {
    return <div className="layout-data-entry-form__field">
        <label id="ownerType">
            <b>Owner Type</b>
        </label>
        <Dropdown
            aria-labelledby="ownerType"
            size="medium"
            id="ownershipDropdown"
            value={value}
            onChange={(data) => onChangeSoleOwnerType(data)}
        >
            {disableOutOfEstate ? soleIndividualOwnerEntityTypeItem : soleOwnerEntityTypeItems}
        </Dropdown>
    </div>;
}

