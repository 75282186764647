import {HoldingAssetSubclassDetails} from "../../models/Holding";
import React from "react";
import {formatNumber} from "../../../utils/format";
import {sumByField} from "./holdingUtils";
import {AssetSubclass} from "../../models/AssetClassifications";
import {AccordionItem} from "../../../components";
import AssetSubclassHeader from "./AssetSubclassHeader";

export const HoldingSummaryAssetSubclass = ({
                                                assetSubclass,
                                                holdingsWithIndex,
                                                subclassIndex,
                                                assetSubclassDetailsRowRenderer,
                                            }: {
    assetSubclass: AssetSubclass,
    holdingsWithIndex: { holding: HoldingAssetSubclassDetails; index: number }[],
    subclassIndex: number,
    assetSubclassDetailsRowRenderer: (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) => React.ReactNode,
}) => {
    const assetSubclassDetailsIds = assetSubclass.assetSubclassDetails
        .map(assetSubclassDetails => assetSubclassDetails.assetSubclassDetailsId);
    const holdingsInSubclass = holdingsWithIndex
        .filter(({holding}) => assetSubclassDetailsIds.includes(holding.assetSubclassDetailsId))
        .map(({holding}) => holding);
    const taxCost = formatNumber(sumByField(holdingsInSubclass, holding => holding.taxCost))
    const marketValue = formatNumber(sumByField(holdingsInSubclass, holding => holding.marketValue))
    const investableValue = formatNumber(sumByField(holdingsInSubclass, holding => holding.investableValue))

    return (
        <AccordionItem
            uuid={assetSubclass.assetSubclassName}
            key={subclassIndex}
            HeaderComponent={({expanded}) => (
                <AssetSubclassHeader
                    expanded={expanded}
                    assetSubclassName={assetSubclass.assetSubclassName}
                    taxCost={taxCost}
                    marketValue={marketValue}
                    investableValue={investableValue}
                />
            )}
            className="holding-grid-accordion-item"
        >
            <div className="grid-display-contents">
                {
                    assetSubclass.assetSubclassDetails
                        .flatMap((assetSubclassDetail) =>
                            holdingsWithIndex.filter(({holding}) =>
                                holding.assetSubclassDetailsId === assetSubclassDetail.assetSubclassDetailsId))
                        .map(({holding, index}) =>
                            assetSubclassDetailsRowRenderer(holding, index))
                }
            </div>
        </AccordionItem>
    )
};
