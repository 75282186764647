import React, {useEffect, useState} from "react";
import WealthTransfer from "./WealthTransfer";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import useProfileAndProposals from "../hooks/useProfileAndProposals";
import {wealthTransferApiClient} from "./WealthTransferApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {isLoaded, isNotLoaded, resetEstateSummary, selectEstateSummary, setEstateSummary} from "./EstateSummarySlice";
import usePageViewTimer from "../hooks/usePageViewTimer";
import {EstateSummary, EstateType} from "./models/api";
import {selectClientAssets, setClientAssets} from "../Assets/clientAssetsSlice";
import {LegalEntityReadModel} from "../Assets/models/Ownership";
import {assetsApiClient} from "../Assets/AssetsApiClient";
import AssetsViewContext from "src/Assets/common/AssetsViewContext";

function pageName(pathname: string) {
    const parts = pathname.split('/');
    return parts[parts.length - 1];
}

const WealthTransferPlanningContainer = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const {profile: profileOrProposal, approvedProfile} = useProfileAndProposals(id);
    const estateSummaryState = useAppSelector(selectEstateSummary);
    const [openDrawer, updateOpenDrawer] = useState(false);
    const [estateType, setEstateType] = useState<EstateType>(EstateType.CURRENT);
    const [futureEstateSummary, setFutureEstateSummary] = useState<EstateSummary>();
    const clientAssets = useAppSelector(selectClientAssets);
    const [legalEntities, setLegalEntities] = useState<LegalEntityReadModel[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    usePageViewTimer('Wealth Transfer Page Load Timer (milliseconds)', isLoading);

    useEffect(() => {
            const drawerOpening = openDrawer && isLoaded(estateSummaryState);
            if (!profileOrProposal.id || drawerOpening) {
                return;
            }

            switch (estateType) {
                case EstateType.CURRENT:
                    dispatch(resetEstateSummary());
                    setIsLoading(true);
                    Promise.all([
                        assetsApiClient.getAssetsSummary(profileOrProposal.id),
                        assetsApiClient.getLegalEntities(profileOrProposal.id),
                        wealthTransferApiClient.getEstateSummary(profileOrProposal.id, estateType)
                    ]).then(([assetsResponse, legalEntitiesResponse, estateSummary]) => {
                        setIsLoading(false);
                        dispatch(setClientAssets(assetsResponse));
                        setLegalEntities(legalEntitiesResponse);
                        dispatch(setEstateSummary(estateSummary));
                    })
                    break;
                case EstateType.FUTURE:
                    setFutureEstateSummary(undefined);
                    wealthTransferApiClient.getEstateSummary(profileOrProposal.id, estateType)
                        .then(estateSummary => setFutureEstateSummary(estateSummary));
                    break;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profileOrProposal.id, openDrawer, dispatch, estateType]
    );

    useEffect(() => {
        const shouldOpenEditDrawer = ['Beneficiaries', 'TaxExemptions'].includes(pageName(history.location.pathname));
        updateOpenDrawer(shouldOpenEditDrawer);
    }, [history.location.pathname]);

    const onToggleEstateType = async (newEstateType: EstateType) => {
        setEstateType(newEstateType);
    }

    if (openDrawer && isLoaded(estateSummaryState)) {
        return (
            <AssetsViewContext.Provider value="CurrentNetWorth">
                <WealthTransfer
                    profileOrProposalId={profileOrProposal.id}
                    profileName={approvedProfile.displayName}
                    estateSummary={estateSummaryState.estateSummary}
                    assets={clientAssets}
                    legalEntities={legalEntities}
                    openDrawer={openDrawer}
                    estateType={EstateType.CURRENT}
                    onToggleEstateType={onToggleEstateType}
                />
            </AssetsViewContext.Provider>
        );
    }

    if (estateType === EstateType.CURRENT) {
        if (isNotLoaded(estateSummaryState) || !clientAssets || !legalEntities) {
            return <LoadingIndicator/>;
        } else {
            return (
                <AssetsViewContext.Provider value="CurrentNetWorth">
                    <WealthTransfer
                        profileOrProposalId={profileOrProposal.id}
                        profileName={approvedProfile.displayName}
                        estateSummary={estateSummaryState.estateSummary}
                        assets={clientAssets}
                        legalEntities={legalEntities}
                        openDrawer={openDrawer}
                        estateType={estateType}
                        onToggleEstateType={onToggleEstateType}
                    />
                </AssetsViewContext.Provider>
            )
        }
    }

    if (futureEstateSummary === undefined) {
        return <LoadingIndicator/>;
    }

    return (
        <AssetsViewContext.Provider value="CurrentNetWorth">
            <WealthTransfer
                profileOrProposalId={profileOrProposal.id}
                profileName={approvedProfile.displayName}
                estateSummary={futureEstateSummary}
                assets={clientAssets}
                legalEntities={legalEntities}
                openDrawer={openDrawer}
                estateType={estateType}
                onToggleEstateType={onToggleEstateType}
            />
        </AssetsViewContext.Provider>
    )
}

export default WealthTransferPlanningContainer;
